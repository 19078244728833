import ApiUtils from "../../../../../services/ApiUtils";

const apiName = {
  VOUCHER: "/voucher/",
  LIST_PRODUCTS: "/products/list-products",
  CREATE_VOUCHER: "/admin/managerVoucher/create",
  UPDATE_VOUCHER: "/admin/managerVoucher/update/",
};

export const apiGetListProducts = async (params) => {
  const res = await ApiUtils.fetch(apiName.LIST_PRODUCTS, params);
  return res.data || [];
};

export const apiVoucherDetail = async (id) => {
  const res = await ApiUtils.fetch(apiName.VOUCHER + id, undefined, {
    isAuth: true,
  });
  return res.data || undefined;
};

export const apiCreateVoucher = async (params) => {
  const res = await ApiUtils.post(apiName.CREATE_VOUCHER, params, {
    isAuth: true,
  });
  return res;
};

export const apiUpdateVoucher = async (voucherId, params) => {
  const res = await ApiUtils.put(apiName.UPDATE_VOUCHER + voucherId, params, {
    isAuth: true,
  });
  return res;
};
