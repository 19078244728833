import { all, fork, put, select, takeEvery } from "redux-saga/effects";
import Router_Links from "../../../const/router_link";
import customerGroupServices from "../../../services/api/customer_group";
import GroupApiServices from "../../../services/api/group_user";
import { getListLevel } from "../../../services/api/level_user";
import { uploadImage } from "../../../services/api/upload";
import {
  activeUser,
  blockUser,
  createUser,
  getDetailUser,
  getListUser,
  updateUser,
} from "../../../services/api/user";
import NotifycationServices from "../../../services/utils/notifycationHelper";
import ValidateUserService from "../../../services/utils/validate/validate_user";
import authActions from "../auth/actions";
import { history } from "../store";
import actions from "./actions";
import { SORT_MODE_CONST } from "./reducer";

function* saga_LoadListUser(action) {
  try {
    let params = action.payload.params;

    let _state = yield select((state) => state.admin_listuser);
    let { pageSize, pageIndex, textSearch, sortMode } = _state;
    if (params.pageSize) pageSize = params.pageSize;
    if (params.pageIndex) pageIndex = params.pageIndex;

    if (params.textSearch) textSearch = params.textSearch;
    if (params.sortMode) sortMode = params.sortMode;

    let params_request = [
      {
        name: "keyword",
        value: textSearch,
      },
    ];

    // sort mode
    switch (sortMode) {
      case SORT_MODE_CONST.SORT_ASC_NAME:
        params_request = [
          ...params_request,
          {
            name: "sorts[createdat]",
            value: 1,
          },
        ];
        break;

      case SORT_MODE_CONST.SORT_DESC_NAME:
        params_request = [
          ...params_request,
          {
            name: "sorts[createdat]",
            value: -1,
          },
        ];
        break;

      default:
        params_request = params_request;
    }

    // call api
    const res = yield getListUser(pageSize, pageIndex, params_request);

    let { items, total } = res.data;

    if (res.code === 200) {
      yield put(actions.action.loadListUserSuccess(items));
      yield put(
        actions.action.updateState({
          total,
        })
      );
    }

    // NotifycationServices.success('Đăng nhập thành công', "Thông báo")
  } catch (ex) {
    console.log("[Admin View Saga] saga_LoadListUser Error : ", ex.message);
  }
}

function* saga_UpdatePageIndex(action) {
  try {
    let pageIndex = action.payload.pageIndex;

    yield put(
      actions.action.loadListUser({
        pageIndex,
      })
    );
  } catch (ex) {
    console.log("[Admin View Saga] saga_UpdatePageIndex Error : ", ex.message);
  }
}

function* saga_UpdatePageSize(action) {
  try {
    let pageSize = action.payload.pageSize;

    yield put(
      actions.action.loadListUser({
        pageSize,
        pageIndex: 1,
      })
    );
  } catch (ex) {
    console.log("[Admin View Saga] saga_UpdatePageSize Error : ", ex.message);
  }
}

function* saga_UpdateSortMode(action) {
  try {
    let mode = yield action.payload.mode;

    yield put(
      actions.action.loadListUser({
        sortMode: mode,
      })
    );
  } catch (ex) {
    console.log("[Admin View Saga] saga_UpdateSortMode : ", ex.message);
  }
}

function* saga_SearchUser(action) {
  try {
    let textSearch = yield action.payload.keyword;

    yield put(
      actions.action.loadListUser({
        textSearch: textSearch.trim(),
      })
    );
  } catch (ex) {
    console.log("[Admin View Saga] saga_SearchUser : ", ex.message);
  }
}

function* saga_LoadUserDetail(action) {
  try {
    let id = action.payload.id;
    let res = yield getDetailUser(id);

    if (res.code === 200) {
      let user = res.data;
      yield put(actions.action.loadUserDetailSuccess(user));
    }
  } catch (ex) {
    console.log("[Admin View Saga] saga_LoadUserDetail : ", ex.message);
  }
}

function* saga_SaveCurrentUser(action) {
  let user = yield select((state) => state.admin_listuser.user);
  try {
    // upload avatar
    let avatar = user.avatar;
    if (user.file) {
      try {
        let reqq = yield uploadImage(user.file);
        if (reqq.path) avatar = reqq.path;
      } catch {}
    }

    user = {
      ...user,
      ...{
        avatar,
      },
    };

    user.address = user.address.trim();

    let validateRes = ValidateUserService.validateUserAdmin(user);
    if (!validateRes.isValidate) {
      NotifycationServices.error(validateRes.message);
      return;
    }

    // console.log("After upload avatar: ", avatar);

    if (user._id === 0) {
      // add product
      user = {
        ...user,
        ...{
          _id: null,
        },
      };

      let req = yield createUser(user);
      if (req.code === 200) {
        yield put(actions.action.loadListUser());
        NotifycationServices.success("Thêm người dùng thành công");

        yield history.push(Router_Links.A_LIST_USER);
      } else {
        NotifycationServices.error("Thêm người dùng thất bại");
      }
    } else {
      // update product
      let req = yield updateUser(user._id, user);
      if (req.code === 200) {
        yield put(actions.action.loadListUser());
        NotifycationServices.success("Cập nhật người dùng thành công");

        yield history.push(Router_Links.A_LIST_USER);

        let userInfo = yield select((state) => state.auth.current_user_info);
        if (user._id === userInfo._id)
          yield put(authActions.action.getUserInfo());
      } else {
        NotifycationServices.error("Cập nhật người dùng thất bại");
      }
    }
  } catch (ex) {
    console.log("[Admin View Saga] saga_SaveCurrentUser error : ", ex.message);

    try {
      if (!user._id || user._id === 0)
        NotifycationServices.error(
          `Thêm người dùng thất bại: ${ex.message.message}`
        );
      else
        NotifycationServices.error(
          `Cập nhật thông tin người dùng thất bại: ${ex.message.message}`
        );
    } catch {}
  }
}

function* saga_LoadListLevelUser(action) {
  try {
    let req = yield getListLevel();

    if (req.code === 200) {
      yield put(actions.action.loadListLevelUserSuccess(req.data));
    }
  } catch (ex) {
    console.log(
      "[Admin View Saga] saga_LoadListLevelUser error : ",
      ex.message
    );
  }
}

function* saga_LoadListGroupUser(action) {
  try {
    let req = yield GroupApiServices.getListGroup("", 1, 100);

    if (req.code === 200) {
      yield put(actions.action.loadListGroupSuccess(req.data.items));
    }
  } catch (ex) {
    console.log(
      "[Admin View Saga] saga_LoadListGroupUser error : ",
      ex.message
    );
  }
}

function* saga_BlockUser(action) {
  try {
    let id = action.payload.id;

    let req = yield blockUser(id);
    if (req.code === 200) {
      NotifycationServices.success("Khoá tài khoản thành công");
      yield put(actions.action.loadListUser());
    } else NotifycationServices.error("Khoá tài khoản thất bại");
  } catch (ex) {
    console.log("[Admin View Saga] saga_BlockUser error : ", ex.message);
    NotifycationServices.error("Khoá tài khoản thất bại");
  }
}

function* saga_ActiveUser(action) {
  try {
    let id = action.payload.id;

    let req = yield activeUser(id);
    if (req.code === 200) {
      NotifycationServices.success("Kích hoạt tài khoản thành công");
      yield put(actions.action.loadListUser());
    } else NotifycationServices.error("Kích hoạt tài khoản thất bại");
  } catch (ex) {
    console.log("[Admin View Saga] saga_ActiveUser error : ", ex.message);
    NotifycationServices.error("Kích hoạt tài khoản thất bại");
  }
}

function* saga_LoadListGroupCustomers() {
  try {
    const res = yield customerGroupServices.getAllListCustomerGroup();
    if (res.code === 200) {
      const groupCustomers = res.data;
      yield put(actions.action.loadListGroupCustomersSuccess(groupCustomers));
    }
  } catch (ex) {
    console.log(
      "[Admin View Saga] saga_LoadListGroupCustomers error : ",
      ex.message
    );
  }
}

function* listen() {
  yield takeEvery(actions.type.LOAD_LIST_USER, saga_LoadListUser);
  yield takeEvery(actions.type.LOAD_USER_DETAIL, saga_LoadUserDetail);
  yield takeEvery(actions.type.UPDATE_PAGE_INDEX, saga_UpdatePageIndex);
  yield takeEvery(actions.type.UPDATE_PAGE_SIZE, saga_UpdatePageSize);
  yield takeEvery(actions.type.UPDATE_SORT_MODE, saga_UpdateSortMode);
  yield takeEvery(actions.type.SEARCH_USER, saga_SearchUser);
  yield takeEvery(actions.type.SAVE_CURRENT_USER, saga_SaveCurrentUser);

  // danh muc
  yield takeEvery(actions.type.LOAD_LIST_LEVEL_USER, saga_LoadListLevelUser);
  yield takeEvery(actions.type.LOAD_LIST_GROUP_USER, saga_LoadListGroupUser);

  // action
  yield takeEvery(actions.type.BLOCK_USER, saga_BlockUser);
  yield takeEvery(actions.type.ACTIVE_USER, saga_ActiveUser);

  // groups customer
  yield takeEvery(
    actions.type.LOAD_LIST_GROUP_CUSTOMERS,
    saga_LoadListGroupCustomers
  );
}

export default function* mainSaga() {
  yield all([fork(listen)]);
}
