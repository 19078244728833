const validate = (entity) => {
    // function
    const errorResult = (message) => {
        return {
            isValidate: false,
            message,
        };
    };

    // validate
    if (!entity.code)
        return errorResult("Mã code không được để trống")
    
    if (!entity.description){
        return errorResult("Mô tả không được để trống")
    }

    return {
        isValidate: true,
        message: "success",
    };
}

export default {
    validate
}