import createApiServices from './createApiServices'

const api = createApiServices()

const getListGroupCustomer = (pageIndex = 1, pageSize = 10, textSearch = "") => {
    return api.makeAuthRequest({
        url: `groupCustomer?pageIndex=${pageIndex}&pageSize=${pageSize}&keyword=${textSearch}`,
        method: 'GET'
    })
}

const getAllListCustomerGroup = () => {
    return api.makeAuthRequest({
        url: `groupCustomer/all`,
        method: 'GET'
    })
}

const getDetailGroupCustomer = (id) => {
    return api.makeAuthRequest({
        url: `groupCustomer/${id}`,
        method: 'GET'
    })
}

const insertCustomerGroup = (customerGroup = {}) => {
    return api.makeAuthRequest({
        url: `admin/managerGroupCustomer/create`,
        method: 'POST',
        data: customerGroup
    })
}

const updateCustomerGroup = (id = "", customerGroup = {}) => {
    return api.makeAuthRequest({
        url: `admin/managerGroupCustomer/update/${id}`,
        method: 'PUT',
        data: customerGroup
    })
}

const deleteCustomerGroup = (id = "") => {
    return api.makeAuthRequest({
        url: `admin/managerGroupCustomer/remove/${id}`,
        method: 'DELETE'
    })
}

const activeCustomerGroup = (id = "") => {
    return api.makeAuthRequest({
        url: `admin/managerGroupCustomer/active/${id}`,
        method: 'PUT'
    })
}

export default {
    getListGroupCustomer,
    getAllListCustomerGroup,
    getDetailGroupCustomer,
    insertCustomerGroup,
    updateCustomerGroup,
    deleteCustomerGroup,
    activeCustomerGroup
}
