import React, { Component } from "react";

// redux
import { connect } from "react-redux";


// icon
import { FaTimesCircle } from "react-icons/fa";

import "./index.scss";

// router
import { Link } from "react-router-dom";
import router_links from "../../../../../../const/router_link";

class PaymentFail extends Component {
    render() {
        return (
            <div className="PaymentFailPage">
                <div className="PaymentResultForm">
                    <FaTimesCircle className="Icon" />
                    <div className="Title">Thanh toán đơn hàng thất bại</div>
                    <div className="Action">
                        <Link
                            className="PFP_Fail"
                            to={router_links.U_HOME_PAGE}
                        >
                            Trang chủ
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = () => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentFail);
