import React, { Component } from 'react';
// redux
import { connect } from 'react-redux';
import UserProfileHistoryVoucherAction from '../../../../redux/user_profile_history_voucher/actions';
// component
import Pagination from '../../../../../components/paginations/paginations';
import Router_Links from "../../../../../const/router_link";
// css
import './index.scss';
import { convertIsoDateToString, numberDisplayThousand } from '../../../../../services/utils/helper';
import { Link } from 'react-router-dom';

class HistoryVoucher extends Component {
    componentDidMount() {
        this.props.init();
    }

    render() {
        const { historyVouchers, pageSize, pageIndex, total } = this.props;
        return (
            <div className="history-voucher">
                <div className="history-voucher-header">Danh sách mã khuyến mãi đã sử dụng</div>
                <div className="table-history">
                    <table className="table-history-table">
                        <thead>
                            <tr>
                                <th>Mã khuyến mại</th>
                                <th>Ngày bắt đầu</th>
                                <th>Ngày kết thúc</th>
                                <th>Mô tả</th>
                                <th>Số cadoxu được cộng</th>
                                <th>Đã sử dụng</th>
                                <th>Trạng thái</th>
                            </tr>
                        </thead>
                        <tbody>
                            {historyVouchers.map(voucher => (
                                <tr key={voucher._id}>
                                    <td>
                                        <Link
                                            to={`${Router_Links.U_PROFILE_HISTORY_VOUCHER}/${voucher._id}`}
                                            style={{
                                                fontWeight: "bold",
                                                textDecoration: "underline",
                                                color: "black",
                                            }}
                                        >
                                            {voucher.code}
                                        </Link>
                                    </td>
                                    <td>{convertIsoDateToString(voucher.startTime)}</td>
                                    <td>{convertIsoDateToString(voucher.stopTime)}</td>
                                    <td>{voucher.description}</td>
                                    <td>{numberDisplayThousand(voucher.maximumCoins)}</td>
                                    <td>{numberDisplayThousand(voucher.totalDiscountUsage)}</td>
                                    <td>{voucher.status}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div>
                    <Pagination
                        onChange={(index) => {
                            this.props.updatePageIndex(index)
                        }}
                        pageSize={pageSize}
                        pageIndex={pageIndex}
                        total={total}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const _state = state.user_profile_history_voucher
    return {
        historyVouchers: _state.historyVouchers,
        pageSize: _state.pageSize,
        pageIndex: _state.pageIndex,
        total: _state.total
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        init: () => {
            dispatch(UserProfileHistoryVoucherAction.action.loadListHistoryVoucher())
        },
        updatePageIndex: (index) => {
            dispatch(UserProfileHistoryVoucherAction.action.updatePageHistoryVoucherIndex(index))
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HistoryVoucher)