import actions from './actions';

const defaultCategory = {
    _id: "",
    code: "",
    description: "",
    status: true,
    parentId: null,
}

const defaultNewCategory = {
    code: "",
    description: "",
    status: 1,
    parentId: null
}

const initState = {
    categoriesPost: [],
    categoryPost: defaultCategory,
    allCategoryPost: [],
    mdAddCategoryPostState: false,
    newCategoryPost: defaultNewCategory
}

const reducer = (state = initState, action) => {
    let payload = action.payload;

    switch (action.type) {
        case actions.type.UPDATE_STATE:
            return {
                ...state,
                ...payload.state
            }
        case actions.type.LOAD_LIST_CATEGORY_POST:
            return state
        case actions.type.LOAD_LIST_CATEGORY_POST_SUCCESS:
            return {
                ...state,
                ...{
                    categoriesPost: payload.categoriesPost
                }
            }
        case actions.type.LOAD_DETAIL_CATEGORY_POST:
            return state
        case actions.type.LOAD_DETAIL_CATEGORY_POST_SUCCESS:
            return {
                ...state,
                ...{
                    categoryPost: payload.categoryPost
                }
            }
        case actions.type.UPDATE_CATEGORY_POST:
            return {
                ...state,
                categoryPost: {
                    ...state.categoryPost,
                    ...payload.categoryPost
                }
            }
        case actions.type.SAVE_CATEGORY_POST:
            return state
        case actions.type.REMOVE_CATEGORY_POST:
            return state
        case actions.type.LOAD_ALL_CATEGORY_POST:
            return state
        case actions.type.LOAD_ALL_CATEGORY_POST_SUCCESS:
            return {
                ...state,
                ...{
                    allCategoryPost: payload.allCategoryPost
                } 
            }
        case actions.type.SET_MODAL_ADD_CATEGORY_POST_STATE:
            return {
                ...state,
                ...{
                    mdAddCategoryPostState: payload.state,
                    newCategoryPost: {
                        ...defaultNewCategory,
                        parentId: payload.parentId
                    }
                }
            }
        case actions.type.UPDATE_NEW_CATEGORY_POST:
            return {
                ...state,
                newCategoryPost: {
                    ...state.newCategoryPost,
                    ...payload.newCategoryPost
                }
            }
        case actions.type.CREATE_NEW_CATEGORY_POST:
            return state
        case actions.type.UPDATE_PAGE_INDEX:
            return {
                ...state,
                ...{
                    pageIndex: payload.pageIndex
                }
            }
        default:
            return state
    }
}

export default reducer;