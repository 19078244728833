const prefix = 'user_list_post';

const type = {
    UPDATE_STATE: prefix + 'update_state',
    LOAD_LIST_POST_BY_TAG: prefix + 'load_list_post_by_tag',
    LOAD_LIST_POST_BY_TAG_SUCCESS: prefix + 'load_list_post_by_tag_success',

    UPDATE_PAGE_INDEX: prefix + 'update_page_index',
    SET_CURRENT_TAG: prefix + 'set_current_tag'
}

const action = {
    updateState: (state) => {
        return {
            type: type.UPDATE_STATE,
            payload: {
                state
            }
        }
    },
    loadListPostByTag: (params = {}) => {
        return {
            type: type.LOAD_LIST_POST_BY_TAG,
            payload: {
                params
            }
        }
    },
    loadListPostByTagSuccess: (listPosts = []) => {
        return {
            type: type.LOAD_LIST_POST_BY_TAG_SUCCESS,
            payload: {
                listPosts
            }
        }
    },
    updatePageIndex: (pageIndex) => {
        return {
            type: type.UPDATE_PAGE_INDEX,
            payload: {
                pageIndex
            }
        }
    },
    setCurrentTag: (tag) => {
        return {
            type: type.SET_CURRENT_TAG,
            payload: {
                tag
            }
        }
    }
}

export default {
    type,
    action
}