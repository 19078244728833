import actions from './actions';

const initState = {
    historyVouchers: [],
    pageSize: 10,
    pageIndex: 1, 
    total: 0,

    // detail 
    orderApplyVouchers: [],
    pageDetailSize: 10,
    pageDetailIndex: 1,
    pageDetailTotal: 0,

}

const reducer = (state = initState, action) => {
    const payload = action.payload;
    switch(action.type) {
        case actions.type.UPDATE_STATE:
            return {
                ...state,
                ...payload.state
            }
        case actions.type.LOAD_LIST_HISTORY_VOUCHER:
            return state
        case actions.type.LOAD_LIST_HISTORY_VOUCHER_SUCCESS:
            return {
                ...state,
                ...{
                    historyVouchers: payload.historyVouchers
                }
            }
        case actions.type.UPDATE_PAGE_HISTORY_VOUCHER_INDEX:
            return {
                ...state,
                ...{
                    pageIndex: payload.pageIndex
                }
            }
        case actions.type.LOAD_LIST_ORDER_APPLY_VOUCHER:
            return state
        case actions.type.LOAD_LIST_ORDER_APPLY_VOUCHER_SUCCESS:
            return {
                ...state,
                ...{
                    orderApplyVouchers: payload.orderApplyVouchers
                }
            }
        case actions.type.UPDATE_PAGE_DETAIL_INDEX:
            return {
                ...state,
                ...{
                    pageDetailIndex: payload.pageIndex
                }
            }
        default:
            return state
    }
}

export default reducer;