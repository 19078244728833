import React, { Component } from 'react'
import { FaSave, FaTimes } from 'react-icons/fa'
import { connect } from 'react-redux'

import './index.scss'

import Button from '../../../../../components/button'
import Modal from "../../../../../components/modal"
import AdminFlashSaleActions from '../../../../redux/admin_flash_sale/actions'


class ModalUpdateProductFlashSale extends Component {
    onClickButtonCancel = () => {
        if (typeof this.props.setModalUpdateProductFlashSaleState === 'function') {
            this.props.setModalUpdateProductFlashSaleState(false)
        }
    }

    onChangeTotalQuantity = (evt) => {
        if (typeof this.props.updateDataProductFlashSale === 'function') {
            const totalQuantity = evt.target.value
            if (totalQuantity >= 0) {
                this.props.updateDataProductFlashSale({ totalQuantity })
            }
        }
    }

    onChangeDiscount = (evt) => {
        if (typeof this.props.updateDataProductFlashSale === 'function') {
            const discount = evt.target.value
            if (discount >= 0) {
                this.props.updateDataProductFlashSale({ discount })
            }

        }
    }

    onClickButtonSave = () => {
        if (typeof this.props.doUpdateProductFlashSale === 'function') {
            this.props.doUpdateProductFlashSale()
        }
    }

    render() {
        const { mdUpdateProductFlashSaleState, currentProductName, setModalUpdateProductFlashSaleState, dataUpdateProduct } = this.props
        return (
            <Modal
                isOpen={mdUpdateProductFlashSaleState}
                displayCloseIcon={false}
                setIsOpen={setModalUpdateProductFlashSaleState}
            >

                <div className="ModalUpdateProductFlashSale">
                    <div className="MUPFS_Label">Chỉnh sửa sản phẩm flash sale</div>
                    <div className="text-center">{currentProductName}</div>
                    <div>
                        <label className="MUPFSF_Label">Số lượng sản phẩm</label>
                        <input
                            type="number"
                            className="MUPFSF_Value"
                            value={dataUpdateProduct.totalQuantity}
                            onChange={this.onChangeTotalQuantity}
                            min="0"
                        />
                    </div>
                    <div>
                        <label className="MUPFSF_Label">Giả giá (%)</label>
                        <input
                            type="number"
                            className="MUPFSF_Value"
                            value={dataUpdateProduct.discount}
                            onChange={this.onChangeDiscount}
                            min="0"
                        />
                    </div>
                    <div className="d-flex justify-content-center MUPFSF_Action">
                        <Button
                            label="Lưu"
                            icon={<FaSave style={{ marginRight: '4px' }} />}
                            className="MUPFSF_Button_Save"
                            onClick={this.onClickButtonSave}
                        />
                        <Button
                            label="Hủy"
                            icon={<FaTimes style={{ marginRight: '4px' }} />}
                            className="MUPFSF_Button_Cancel"
                            onClick={this.onClickButtonCancel}
                        />
                    </div>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    const _state = state.admin_flash_sale
    return {
        mdUpdateProductFlashSaleState: _state.mdUpdateProductFlashSaleState,
        currentProductName: _state.currentProductName,
        dataUpdateProduct: _state.dataUpdateProduct
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setModalUpdateProductFlashSaleState: (state) => {
            dispatch(AdminFlashSaleActions.action.setModalUpdateProductFlashSaleState(state))
        },
        updateDataProductFlashSale: (data) => {
            dispatch(AdminFlashSaleActions.action.updateDataProductFlashSale(data))
        },
        doUpdateProductFlashSale: () => {
            dispatch(AdminFlashSaleActions.action.doUpdateProductFlashSale())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalUpdateProductFlashSale)