import React, { Component } from "react";
// redux
import { connect } from "react-redux";
// component
import Pagination from "../../../../../components/paginations/paginations";
import actions from "../../../../redux/admin_post/actions";
// sub component
import HeaderBar from "./headerbar";
import "./index.scss";
import ListPosts from "./list_post";

class PostPage extends Component {
  componentDidMount = () => {
    this.props.init();
  };

  renderPaging = () => {
    return (
      <Pagination
        pageSize={this.props.pageSize}
        pageIndex={this.props.pageIndex}
        total={this.props.total}
        onChange={(value) => {
          window.scrollTo(0, 0);
          this.props.updatePageIndex(value);
        }}
      />
    );
  };

  render() {
    return (
      <div className="AdminPostsPage">
        <HeaderBar />
        <ListPosts />
        {this.renderPaging()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let _state = state.admin_post;

  let { pageSize, pageIndex, total } = _state;

  return {
    pageSize,
    pageIndex,
    total,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    init: () => {
      dispatch(actions.action.loadListPosts());
      dispatch(actions.action.loadListCategoryPost());
    },
    updatePageIndex: (pageIndex) => {
      dispatch(actions.action.updatePageIndex(pageIndex));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PostPage);
