import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// route
import { Redirect, Route, Switch } from "react-router-dom";
// redux
import authActions from "../../App/redux/auth/actions";
import AdminCheckPermissionComponent from "../../App/screens/admin_views/components/admin-check-permission";

class LoginRequireComponent extends Component {
  render() {
    if (!this.props.isLoggedIn) {
      return (
        <Redirect
          to={{
            pathname: this.props.redirect_link ? this.props.redirect_link : "",
            state: {
              from: this.props.location,
            },
          }}
        />
      );
    }

    return (
      <AdminCheckPermissionComponent {...this.props}>
        <Switch>
          <Route {...this.props} onEnter={this.props.checkSession()} />
        </Switch>
      </AdminCheckPermissionComponent>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    checkSession: () => {
      dispatch(authActions.action.checkSession());
    },
  };
};

LoginRequireComponent.propTypes = {
  redirect_link: PropTypes.string,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginRequireComponent);
