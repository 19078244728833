import createApiServices from "./createApiServices";

const api = createApiServices();

export const getListLevel = () => {
    return api.makeAuthRequest({
        url: `levels`,
        method: "GET",
    });
};


export const removeLevelUser = (id) => {
    return api.makeAuthRequest({
        url: `admin/managerLevels/remove/${id}`,
        method: 'DELETE'
    })
}

export const createLevelUser = (newLevelUser) => {
    return api.makeAuthRequest({
        url: `admin/managerLevels/create`,
        method: 'POST',
        data: newLevelUser
    })
}

export const updateLevelUser = (id, levelUser) => {
    return api.makeAuthRequest({
        url: `admin/managerLevels/update/${id}`,
        method: 'PUT',
        data: levelUser
    })
}

export const getLevelUser = (id) => {
    return api.makeAuthRequest({
        url: `levels/${id}`,
        method: 'GET'
    })
}