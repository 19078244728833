import actions from "./actions";
import { StaticRouter } from "react-router-dom/cjs/react-router-dom.min";

const currentDate = new Date();
const default_voucher = {
    _id: 0,
    code: "",
    description: "",
    maximumCoins: 0,
    discount: 0,
    status: true,
    startTime: new Date(currentDate.setDate(currentDate.getDate() - 1)),
    stopTime: new Date(),
    groupCustomerId: null,
};

const initState = {
    customer_groups: [],

    // voucher
    list_vouchers: [],
    pageIndex: 1,
    pageSize: 10,
    textSearch: "",
    total: 10,
    lastTextSearch: "",

    // current voucher
    current_voucher: default_voucher,
    mdCurrentVoucherState: false
};

const types = actions.type;

const reducer = (state = initState, action) => {
    let payload = action.payload;

    switch (action.type) {
        case types.UPDATE_STATE:
            return {
                ...state,
                ...payload.state,
            };

        // list customer group
        case types.LOAD_LIST_CUSTOMER_GROUP:
            return state;

        case types.LOAD_LIST_CUSTOMER_GROUP_SUCCESS:
            return {
                ...state,
                ...{
                    customer_groups: payload.customer_groups,
                },
            };

        // list voucher
        case types.LOAD_LIST_VOUCHER:
            return state;

        case types.LOAD_LIST_VOUCHER_SUCCESS:
            return {
                ...state,
                ...{
                    list_vouchers: payload.vouchers,
                },
            };

        case types.UPDATE_PAGE_INDEX:
            return {
                ...state,
                ...{
                    pageIndex: payload.pageIndex,
                },
            };

        case types.UPDATE_TEXT_SEARCH:
            return {
                ...state,
                ...{
                    textSearch: payload.textSearch,
                },
            };

        case types.SEARCH_VOUCHER:
            return {
                ...state,
                ...{
                    pageIndex: 1,
                    lastTextSearch: state.textSearch,
                },
            };

        // detail customer group
        case types.SET_CURRENT_VOUCHER:
            return {
                ...state,
                ...{
                    current_voucher: payload.voucher,
                },
            };

        case types.SET_DEFAULT_CURRENT_VOUCHER:
            return {
                ...state,
                ...{
                    current_voucher: default_voucher,
                },
            };

        case types.UPDATE_CURRENT_VOUCHER:
            return {
                ...state,
                ...{
                    current_voucher: {
                        ...state.current_voucher,
                        ...payload.info,
                    },
                },
            };

        case types.SAVE_CURRENT_VOUCHER:
            return state;

        case types.ACTIVE_VOUCHER:
            return state;

        case types.DEACTIVE_VOUCHER:
            return state;
        case types.SET_MD_VOUCHER_STATE:
            return {
                ...state,
                ...{
                    mdCurrentVoucherState: payload.state
                }
            }
        default:
            return state;
    }
};

export default reducer;
