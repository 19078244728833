import React, { Component } from "react";

// redux
import { connect } from "react-redux";
import actions from '../../../redux/user_global/actions'

// router
import { Link } from 'react-router-dom'

// helper
import router_links from "../../../../const/router_link";
import { getImageUrl } from "../../../../services/api/upload";
import { convertIsoDateToString } from "../../../../services/utils/helper";


class StoryOfShop extends Component {

    componentDidMount = () => {
        this.props.loadListPopularPost()
    }

    render() {
        let popular_posts = this.props.popular_posts ? this.props.popular_posts : []

        return (
            <div className="StoryOfShop">
                <div className="TitlePanel">
                    <div className="Title">CÂU CHUYỆN CỦA CADOSA</div>
                    <div className="SubTitle">
                        ----&#9632;&#9632;&#9632;&#9632;----
                    </div>
                </div>

                <div className="container">
                    <div className="SOS_ImagePanel row">
                        {
                            popular_posts.map((post, index) => {
                                return (
                                    <div className="col-md-3 col-sm-12 col-6 pr-0 pl-0">
                                        <Link
                                            className="SOS_Panel"
                                            to={`${router_links.U_DETAIL_POST}/${post._id}`}
                                        >
                                            <img
                                                src={getImageUrl(post.thumbnailUrl)}
                                                className="SOS_Panel_Image"
                                                alt=""
                                            />
                                            <div className="SOS_Panel_Time">
                                                {
                                                    convertIsoDateToString(post.createdAt)
                                                }
                                            </div>
                                            <div className="SOS_Panel_Title">
                                                {
                                                    post.title
                                                }
                                            </div>
                                            <div className="SOS_Panel_Line">
                                                -----------------
                                    </div>
                                            <div className="SOS_Panel_Author">
                                                Bởi: Quản trị viên
                                    </div>
                                        </Link>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    let _state = state.user_global
    return {
        popular_posts: _state.popular_posts
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        loadListPopularPost: () => {
            dispatch(actions.action.loadListPopularPost())
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StoryOfShop);
