import { all, fork, put, select, takeEvery } from "redux-saga/effects";
// const
import Router_Links from "../../../const/router_link";
import { getListAddress } from "../../../services/api/address_user";
import {
  getCartOfCurrentUser,
  insertProductToCart,
  removeCartDetail,
  updateQuantity,
} from "../../../services/api/cart_user";
import { getArrayListCategories } from "../../../services/api/category";
import { getListLevel } from "../../../services/api/level_user";
import {
  createOder,
  getAhaShipPrice,
  getDiscountInfoByCode,
} from "../../../services/api/order";
import PostApiServices from "../../../services/api/post";
// api
import {
  getDetailProduct,
  getListProduct,
  getPopularCategorys,
  getProductCurrentFlashSale,
  getRelateProducts,
} from "../../../services/api/product";
import VoucherApiServices from "../../../services/api/voucher";
import CartGuestItemHelperServices from "../../../services/utils/model_process_helper/guest_cart_product";
// helper
import NotificationsService from "../../../services/utils/notifycationHelper";
import AuthActions from "../../redux/auth/actions";
import { history } from "../store";
import actions from "./actions";
import { SORT_MODE_CONST } from "./reducer";

function* saga_LoadListProduct(action) {
  try {
    let params = action.payload.params;

    let _state = yield select((state) => state.user_global);
    let { pageSize, pageIndex, textSearch, sortMode } = _state;

    if (params.pageIndex) pageIndex = params.pageIndex;
    if (params.pageSize) pageSize = params.pageSize;
    if (params.textSearch) textSearch = params.textSearch;
    if (params.sortMode) sortMode = params.sortMode;

    let params_request = [
      {
        name: "keyword",
        value: textSearch,
      },
    ];

    // sort mode
    switch (sortMode) {
      case SORT_MODE_CONST.SORT_ASC_NAME:
        params_request = [
          ...params_request,
          {
            name: "sorts[name]",
            value: 1,
          },
        ];
        break;

      case SORT_MODE_CONST.SORT_DESC_NAME:
        params_request = [
          ...params_request,
          {
            name: "sorts[name]",
            value: -1,
          },
        ];
        break;

      case SORT_MODE_CONST.SORT_ASC_PRICE:
        params_request = [
          ...params_request,
          {
            name: "sorts[price]",
            value: 1,
          },
        ];
        break;

      case SORT_MODE_CONST.SORT_DESC_PRICE:
        params_request = [
          ...params_request,
          {
            name: "sorts[price]",
            value: -1,
          },
        ];
        break;

      default:
        params_request = params_request;
    }

    // call api
    const res = yield getListProduct(pageIndex, pageSize, params_request);

    let { items } = res.data;

    if (res.code === 200) {
      yield put(actions.action.loadListHomeProductSuccess(items));
    }
  } catch (ex) {
    console.log("[User Global Saga] saga_LoadListProduct Error : ", ex.message);
    yield put(actions.action.loadListHomeProductFailed());
  }
}

function* saga_LoadDetailProduct(action) {
  try {
    const id = action.payload.id;

    let req = yield getDetailProduct(id);

    if (req.code === 200) {
      let product = req.data;
      yield put(actions.action.loadDetailProductSuccess(product));
    }
  } catch (ex) {
    console.log(
      "[User Global Saga] saga_LoadDetailProduct Error : ",
      ex.message
    );
  }
}

function* saga_LoadFlashSaleProduct(action) {
  try {
    let req = yield getProductCurrentFlashSale();

    if (req.code === 200) {
      let products = [];
      try {
        products = req.data[0].productsFlashSales;

        yield put(actions.action.loadProductCurrentFlashSaleSuccess(products));

        yield put(
          actions.action.updateState({
            flashsale: req.data[0].flashSales,
          })
        );
      } catch (ex) {
        console.log("[lala] products : ", products);
        yield put(actions.action.loadProductCurrentFlashSaleFailed());
      }
    }
  } catch (ex) {
    console.log(
      "[User Global Saga] saga_LoadFlashSaleProduct Error : ",
      ex.message
    );
    yield put(actions.action.loadProductCurrentFlashSaleFailed());
  }
}

function* saga_LoadRelateProduct(action) {
  try {
    let id = action.payload.id;

    let req = yield getRelateProducts(id, 12, 1);
    if (req.code === 200) {
      let products = req.data.items;

      yield put(actions.action.loadRelateProductSuccess(products));
    }
  } catch (ex) {
    console.log(
      "[User Global Saga] saga_LoadFlashSaleProduct Error : ",
      ex.message
    );
  }
}

function* saga_LoadListCategory(action) {
  try {
    let req = yield getArrayListCategories();
    if (req.code === 200) {
      let categorys = req.data;

      yield put(actions.action.loadListCategorySuccess(categorys));
    }
  } catch (ex) {
    console.log(
      "[User Global Saga] saga_LoadListCategory Error : ",
      ex.message
    );
  }
}

// cart
function* saga_LoadListCartItem(action) {
  try {
    let req = yield getCartOfCurrentUser();

    if (req.code === 200) {
      let carts = req.data.cartDetails;
      yield put(actions.action.loadListCartItemSuccess(carts));

      yield put(actions.action.getDiscountPriceOfCart());
    }
  } catch (ex) {
    console.log(
      "[User Global Saga] saga_LoadListCartItem Error : ",
      ex.message
    );
  }
}

function* saga_AddCartItem(action) {
  try {
    let isLoggedIn = yield select((state) => state.auth.isLoggedIn);
    let { productId, quantity } = action.payload;

    if (!isLoggedIn) {
      let item = yield CartGuestItemHelperServices.genGuestCartItem(
        productId,
        Number(quantity)
      );

      yield put(actions.action.guestAddCartItem(item));
    } else {
      if (quantity <= 0) {
        NotificationsService.error("Số lượng sản phẩm thêm vào phải lớn hơn 0");
        return;
      }

      let req = yield insertProductToCart(productId, Number(quantity));

      if (req.code === 200) {
        yield put(actions.action.loadListCartItem());
      }
    }
  } catch (ex) {
    console.log("[User Global Saga] saga_AddCartItem Error : ", ex.message);
  }
}

function* saga_UpdateCartItem(action) {
  try {
    let isLoggedIn = yield select((state) => state.auth.isLoggedIn);
    const _state = yield select((state) => state.user_global);
    let carts = [..._state.carts];

    let { id, quantity } = action.payload;

    for (const cart of carts) {
      if (cart._id === id) {
        cart.quantity = quantity;
      }
    }

    yield put(actions.action.updateState({ carts }));
    if (!/^\d+(\.)$/.test(quantity)) {
      if (isLoggedIn) {
        let req = yield updateQuantity(id, Number(quantity));

        if (req.code === 200) {
          yield put(actions.action.loadListCartItem());
        }
      } else {
        yield put(actions.action.guestUpdateCartItem(id, Number(quantity)));
      }
    }
  } catch (ex) {
    console.log("[User Global Saga] saga_UpdateCartItem Error : ", ex.message);
  }
}

function* saga_RemoveCartItem(action) {
  try {
    let isLoggedIn = yield select((state) => state.auth.isLoggedIn);
    let id = action.payload.id;

    if (isLoggedIn) {
      let req = yield removeCartDetail(id);

      if (req.code === 200) {
        yield put(actions.action.loadListCartItem());
      }
    } else {
      yield put(actions.action.guestRemoveCartItem(id));
    }
  } catch (ex) {
    console.log("[User Global Saga] saga_RemoveCartItem Error : ", ex.message);
  }
}

function* saga_AsyncCartGuest(action) {
  try {
    let isLoggedIn = yield select((state) => state.auth.isLoggedIn);
    let carts = yield select((state) => state.user_global.carts);

    if (isLoggedIn) {
      for (let index = 0; index < carts.length; index++) {
        let cart_item = carts[index];

        if (CartGuestItemHelperServices.checkIsGuestCartId(cart_item._id)) {
          try {
            let productId = cart_item.product._id;
            let quantity = cart_item.quantity;

            yield put(actions.action.addCartItem(productId, quantity));
          } catch {}
        }
      }

      yield put(actions.action.loadListCartItem());
    }
  } catch (ex) {
    console.log("[User Global Saga] saga_AsyncCartGuest Error : ", ex.message);
  }
}

function* saga_GetShippingFee(action) {
  try {
    let { addressId, serviceId } = action.payload;

    let req = yield getAhaShipPrice(addressId, serviceId);

    if (req.code === 200) {
      let shipping_fee = req.data.total_price || 0;
      yield put(actions.action.getShippingFeeSuccess(shipping_fee));
      return;
    }

    yield put(actions.action.getShippingFeeSuccess(0));
  } catch (ex) {
    if (ex.message) {
      NotificationsService.error(ex.message.message.error);
    }
    console.log("[User Global Saga] saga_GetShippingFee Error : ", ex.message);
  }
}

function* saga_CheckDiscountCode(action) {
  try {
    let { code } = action.payload;

    let req = yield getDiscountInfoByCode(code);

    if (req.code === 200) {
      let info = req.data;
      yield put(actions.action.useDiscountCode(info));
      NotificationsService.success("Nhập mã khuyến mại thành công");

      yield put(actions.action.getDiscountPriceOfCart(info.code));
    } else {
      NotificationsService.error("Mã khuyến mại không đúng");
    }
  } catch (ex) {
    console.log(
      "[User Global Saga] saga_CheckDiscountCode Error : ",
      ex.message
    );
    NotificationsService.error("Mã khuyến mại không đúng");
  }
}

function* saga_GetCurrentVoucherApply(action) {
  try {
    let req = yield VoucherApiServices.getCurrentVoucherApply();

    if (req.code === 200) {
      let voucher = req.data;

      if (voucher)
        yield put(actions.action.getCurrentVoucherApplySuccess(voucher));

      if (voucher)
        yield put(actions.action.getDiscountPriceOfCart(voucher.code));
      else yield put(actions.action.getDiscountPriceOfCartSuccess(0));
    }
  } catch (ex) {
    console.log("[User Global Saga] saga_GetCurrentVoucherApply error : ", ex);
  }
}

function* saga_GetDiscountPriceOfCart(action) {
  try {
    let code = action.payload.voucher_code;

    if (!code) {
      let discount_info = yield select(
        (state) => state.user_global.discount_info
      );
      code = discount_info.code;
    }

    let req = yield VoucherApiServices.getPriceDiscountOfCart(code);

    if (req.code === 200) {
      let totalPriceDiscountOfCart = req.data.totalPriceDiscountOfCart;
      yield put(
        actions.action.getDiscountPriceOfCartSuccess(totalPriceDiscountOfCart)
      );
    } else yield put(actions.action.getDiscountPriceOfCartSuccess(0));
  } catch (ex) {
    console.log("[User Global Saga] saga_GetCurrentVoucherApply error : ", ex);
    yield put(actions.action.getDiscountPriceOfCartFailed());
  }
}

// address
function* saga_LoadListAddress(action) {
  try {
    let params = action.payload.params;

    let pageSize = 10,
      pageIndex = 1;
    if (params.pageSize) pageSize = params.pageSize;
    if (params.pageIndex) pageIndex = params.pageIndex;

    let req = yield getListAddress(pageSize, pageIndex);
    if (req.code === 200) {
      let list_address = req.data.items;

      yield put(actions.action.loadListAddressSuccess(list_address));
    }
  } catch (ex) {
    console.log("[User Global Saga] saga_LoadListAddress error : ", ex.message);
  }
}

function* saga_CheckoutCart(action) {
  try {
    let _state = yield select((state) => state.user_global);
    let { select_address, discount_info } = _state;

    if (!select_address) {
      NotificationsService.error("Chưa chọn địa chỉ");
      return;
    }

    let { paymentType, shippingType, point, voucherCode } = action.payload;

    let order = {
      addressId: select_address._id,
      voucherCode,
      points: point,
      paymentMethod: paymentType,
      serviceId: shippingType,
    };

    // call api
    let req = yield createOder(order);
    if (req.code === 200) {
      //
      if (paymentType === "ONLINE") {
        let direct_url = req.data;
        window.location = direct_url;
      } else {
        yield put(actions.action.loadListCartItem());
        yield put(AuthActions.action.getUserInfo());

        NotificationsService.success("Tạo đơn hàng thành công");

        // clear coupon
        yield put(actions.action.removeDiscountCode());
        yield history.push(Router_Links.U_HOME_PAGE);
      }
    } else {
      yield NotificationsService.error("Tạo đơn hàng thất bại");
    }
  } catch (ex) {
    console.log("[User Global Saga] saga_CheckoutCart error : ", ex.message);

    yield NotificationsService.error("Tạo đơn hàng thất bại");
  }
}

// popular category
function* saga_LoadListPopularCategory() {
  try {
    let req = yield getPopularCategorys();

    if (req.code === 200) {
      let categorys = req.data.items;

      yield put(actions.action.loadListPopularCategorySuccess(categorys));
    }
  } catch (ex) {
    console.log("[User Global Saga] saga_CheckoutCart error : ", ex.message);
  }
}

// level
function* saga_LoadListLevel() {
  try {
    let req = yield getListLevel();
    if (req.code === 200) {
      let levels = req.data;

      yield put(actions.action.loadListLevelSuccess(levels));
    }
  } catch (ex) {
    console.log("[User Global Saga] saga_LoadListLevel error : ", ex.message);
  }
}

// post
function* saga_LoadListPopularPost() {
  try {
    let req = yield PostApiServices.GetListPost("", 4, 1);
    if (req.code === 200) {
      let items = req.data.items;

      yield put(actions.action.loadListPopularPostSuccess(items));
    }
  } catch (ex) {
    console.log(
      "[User Global Saga] saga_LoadListPopularPost error : ",
      ex.message
    );
  }
}

function* listen() {
  yield takeEvery(actions.type.LOAD_LIST_HOME_PRODUCT, saga_LoadListProduct);
  yield takeEvery(actions.type.LOAD_DETAIL_PRODUCT, saga_LoadDetailProduct);
  yield takeEvery(
    actions.type.LOAD_PRODUCT_CURRENT_FLASH_SALE,
    saga_LoadFlashSaleProduct
  );
  yield takeEvery(actions.type.LOAD_RELATE_PRODUCT, saga_LoadRelateProduct);
  yield takeEvery(actions.type.LOAD_LIST_CATEGORY, saga_LoadListCategory);

  // cart detail
  yield takeEvery(actions.type.LOAD_LIST_CART_ITEM, saga_LoadListCartItem);
  yield takeEvery(actions.type.ADD_CART_ITEM, saga_AddCartItem);
  yield takeEvery(actions.type.UPDATE_CART_ITEM, saga_UpdateCartItem);
  yield takeEvery(actions.type.REMOVE_CART_ITEM, saga_RemoveCartItem);
  yield takeEvery(actions.type.GET_SHIPPING_FEE, saga_GetShippingFee);
  yield takeEvery(actions.type.CHECK_DISCOUNT_CODE, saga_CheckDiscountCode);
  yield takeEvery(
    actions.type.GET_CURRENT_VOUCHER_APPLY,
    saga_GetCurrentVoucherApply
  );

  yield takeEvery(
    actions.type.GET_DISCOUNT_PRICE_OF_CART,
    saga_GetDiscountPriceOfCart
  );

  // cart async
  yield takeEvery(actions.type.ASYNC_GUEST_CART, saga_AsyncCartGuest);

  // address
  yield takeEvery(actions.type.LOAD_LIST_ADDRESS, saga_LoadListAddress);

  // checkout
  yield takeEvery(actions.type.CHECK_OUT_CART, saga_CheckoutCart);

  // popular category
  yield takeEvery(
    actions.type.LOAD_LIST_POPULAR_CATEGORY,
    saga_LoadListPopularCategory
  );

  // level
  yield takeEvery(actions.type.LOAD_LIST_LEVEL, saga_LoadListLevel);

  // post
  yield takeEvery(
    actions.type.LOAD_LIST_POPULAR_POST,
    saga_LoadListPopularPost
  );
}

export default function* mainSaga() {
  yield all([fork(listen)]);
}
