import React, { Component, Fragment } from "react";

// redux
import { connect } from "react-redux";
import UserGlobalActions from '../../../redux/user_global/actions'

// icon
import { FaTimes, FaTrash } from "react-icons/fa";

// react router dom
import { Link } from "react-router-dom";
import Router_Links from "../../../../const/router_link";
import { getImageUrl } from "../../../../services/api/upload";
import { numberDisplayThousand } from "../../../../services/utils/helper";
import router_links from "../../../../const/router_link";
import { regexDecimal, regexNumber } from "../../../../const/regex_consts";
import { productUnit } from "../../../../const/product_unit";

class CartPopUp extends Component {

    onChangeQuantity = (evt, cartItem) => {
        const value = evt.target.value;
        const isNumber = regexNumber.test(value);
        const isDecimal = regexDecimal.test(value);
        const isWholePart = /^\d+(\.)$/.test(value);

        if (cartItem.product.productUnit.name === productUnit.KG && (isNumber || isDecimal || isWholePart)) {
            this.props.updateCartItem(
                cartItem._id,
                value
            );
        } else if (isNumber) {
            this.props.updateCartItem(
                cartItem._id,
                value
            );
        }
    }
    
    render() {
        let total = this.props.total ? this.props.total : 0
        let carts = this.props.carts ? this.props.carts : []

        return (
            <div className="CartPopUp" {...this.props}>
                <div className="CP_Title_Row">
                    <div className="CPTR_Left">Sản phẩm ({carts.length})</div>
                    <div className="CPTR_Left-mobile">Giỏ hàng</div>
                    <div
                        className="CPTR_Right"
                        onClick={() => {
                            if (this.props.hideCartPopup)
                                this.props.hideCartPopup();
                        }}
                    >
                        <FaTimes className="CPTR_Icon" />
                    </div>
                </div>

                <div className="CP_ListProducts">
                    {carts.map((cart_item, index) => {
                        let _total = cart_item.quantity * cart_item.price

                        let product = cart_item.product ? cart_item.product : { thumbnail: "" }

                        return (
                            <Fragment>
                                <div className="CPLP_Item">
                                    <Link
                                        to={`${router_links.U_DETAIL_PRODUCT}/${product._id}`}
                                    >
                                        <img
                                            className="CPLP_Image"
                                            src={getImageUrl(product.thumbnail)}
                                            alt=""
                                        />
                                    </Link>

                                    <div className="CPLP_InfoPanel">
                                        <Link
                                            to={`${router_links.U_DETAIL_PRODUCT}/${product._id}`}
                                            className="CPLPI_Name"
                                        >
                                            {product.name}
                                        </Link>
                                        <div className="CPLPI_Quantity">
                                            <span className="quantity-title">SL</span>
                                            <div className="cplpi-input-quatity">
                                                <div
                                                    className="CPLPI_Button"
                                                    onClick={() => {
                                                        if (cart_item.quantity > 1)
                                                            this.props.updateCartItem(cart_item._id, cart_item.quantity - 1)
                                                        else
                                                            this.props.removeCartDetail(cart_item._id)
                                                    }}
                                                >
                                                    -
                                        </div>
                                                <input
                                                    className="CPLPI_QtyText"
                                                    value={cart_item.quantity}
                                                    type="input"
                                                    onChange={(evt) => this.onChangeQuantity(evt, cart_item)}
                                                />
                                                <div
                                                    className="CPLPI_Button"
                                                    onClick={() => {
                                                        this.props.updateCartItem(cart_item._id, cart_item.quantity + 1)
                                                    }}
                                                >
                                                    +
                                            </div>
                                            </div>
                                        </div>
                                        <div className="mobile-total">{numberDisplayThousand(_total)}đ/ {product.productUnit.description}</div>

                                    </div>

                                    <div className="CPLP_Total">
                                        <div className="CPLPT_Sum">
                                            {numberDisplayThousand(_total)}đ
                                    </div>
                                        <div className="CPLPT_RemoveButton"
                                            onClick={() => {
                                                this.props.removeCartDetail(cart_item._id)
                                            }}
                                        >
                                            <FaTimes className="CPLPT_Icon" />
                                        </div>
                                    </div>
                                </div>
                                {index !== carts.length - 1 && <div className="border-item" />}
                                
                            </Fragment>
                        );
                    })}

                </div>

                <div className="CP_Total">
                    <div className="CPTT_Title">Tổng</div>
                    <div className="CPTT_Money">{numberDisplayThousand(total)} đ</div>
                </div>

                <div className="CP_ButtonCheckOut">
                    <Link to={Router_Links.U_CART_DETAIL} className="CP_Button">
                        Thanh toán
                    </Link>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    let _state = state.user_global

    return {
        total: _state.total,
        carts: _state.carts
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        removeCartDetail: (id) => {
            dispatch(UserGlobalActions.action.removeCartDetail(id))
        },
        updateCartItem: (id = null, quantity = 1) => {
            dispatch(UserGlobalActions.action.updateCartItem(id, quantity))
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CartPopUp);
