import actions from './actions'

const initState = {
    sidebar_status: false
}

const reducer = (state = initState, action) => {

    switch (action.type) {
        case actions.type.CHANGE_SIDE_BAR_DISPLAY:
            return {
                ...state,
                ...{
                    sidebar_status: !state.sidebar_status
                }
            }

        case actions.type.SET_SIDE_BAR_DISPLAY:
            return {
                ...state,
                ...{
                    sidebar_status: action.payload.status
                }
            }

        default:
            return state
    }
}

export default reducer
