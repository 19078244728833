const prefix = "admin_category_post"

const type = {
    UPDATE_STATE: prefix + "update_state",
    LOAD_LIST_CATEGORY_POST: prefix + "load_list_category",
    LOAD_LIST_CATEGORY_POST_SUCCESS: prefix + "load_list_category_success",

    LOAD_DETAIL_CATEGORY_POST: prefix + "load_detail_category_post",
    LOAD_DETAIL_CATEGORY_POST_SUCCESS: prefix + "load_detail_category_post_success",

    UPDATE_CATEGORY_POST: prefix + "update_category_post",
    SAVE_CATEGORY_POST: prefix + "save_category_post",

    REMOVE_CATEGORY_POST: prefix + "delete_category_post",
    LOAD_ALL_CATEGORY_POST: prefix + "load_all_category_post",
    LOAD_ALL_CATEGORY_POST_SUCCESS: prefix + "load_all_category_post_success",

    SET_MODAL_ADD_CATEGORY_POST_STATE: prefix + "set_modal_add_category_post_state",

    UPDATE_NEW_CATEGORY_POST: prefix + "update_new_category_post",

    CREATE_NEW_CATEGORY_POST: prefix + "create_new_category_post",

    UPDATE_PAGE_INDEX: prefix + "update_page_index",
}

const action = {
    updateState: (state) => {
        return {
            type: type.UPDATE_STATE,
            payload: {
                state
            }
        }
    },
    loadListCategoryPost: (params = {}) => {
        return {
            type: type.LOAD_LIST_CATEGORY_POST,
            payload: {
                params
            }
        }
    },

    loadListCategoryPostSuccess: (categoriesPost = []) => {
        return {
            type: type.LOAD_LIST_CATEGORY_POST_SUCCESS,
            payload: {  
                categoriesPost
            }
        }
    },

    loadDetailCategoryPost: (id) => {
        return {
            type: type.LOAD_DETAIL_CATEGORY_POST,
            payload: {
                id
            }
        }
    },

    loadDetailCategoryPostSuccess: (categoryPost) => {
        return {
            type: type.LOAD_DETAIL_CATEGORY_POST_SUCCESS,
            payload: {
                categoryPost
            }
        }
    },

    updateCategoryPost: (categoryPost) => {
        return {
            type: type.UPDATE_CATEGORY_POST,
            payload: {
                categoryPost
            }
        }
    },

    saveCategoryPost: () => {
        return {
            type: type.SAVE_CATEGORY_POST,
            payload: {

            }
        }
    },

    removeCategoryPost: () => {
        return {
            type: type.REMOVE_CATEGORY_POST,
            payload: {

            }
        }
    },

    loadAllCategoryPost: () => {
        return {
            type: type.LOAD_ALL_CATEGORY_POST,
            payload: {

            }
        }
    },

    loadAllCategoryPostSuccess: (allCategoryPost = []) => {
        return {
            type: type.LOAD_ALL_CATEGORY_POST_SUCCESS,
            payload: {
                allCategoryPost
            }
        }
    },

    setModalAddCategoryPostState: (state, parentId = null) => {
        return {
            type: type.SET_MODAL_ADD_CATEGORY_POST_STATE,
            payload: {
                state,
                parentId
            }
        }
    },
    updateNewCategoryPost: (newCategoryPost) => {
        return {
            type: type.UPDATE_NEW_CATEGORY_POST,
            payload: {
                newCategoryPost
            }
        }
    },
    createNewCategoryPost: () => {
        return {
            type: type.CREATE_NEW_CATEGORY_POST,
            payload: {

            }
        }
    },
    updatePageIndex: (pageIndex = 1) => {
        return {
            type: type.UPDATE_PAGE_INDEX,
            payload: {
                pageIndex
            }
        }
    }
}

export default {
    type,
    action
}