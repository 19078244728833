import React from 'react';
import { FaAngleLeft, FaEdit, FaEllipsisV, FaTrash } from 'react-icons/fa';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Button from '../../../../../components/button';
import RichTextBoxDisplayValue from '../../../../../components/richTextBoxEditor/displayRichTextBoxValue';
import router_links from '../../../../../const/router_link';
import { convertIsoDateToString } from '../../../../../services/utils/helper';
import actions from '../../../../redux/admin_post/actions'

import ToolTip from '../../../../../components/tooltip'

import './index.scss'
import ModalConfirmAction from '../../../../../components/modal/modal_confirm_action';
class PostDetailPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            isOpenConfirm: false
        }
    }

    componentDidMount() {
        let postId = this.props.match.params.id ? this.props.match.params.id : ""
        this.props.loadPostDetail(postId)
    }

    render() {
        const { post } = this.props
        return (
            <div className="detail-post-admin">
                <div>
                    <div className='d-flex justify-content-between'>
                        <Button
                            className='button-back'
                            label="Quay lại"
                            icon={<FaAngleLeft />}
                            onClick={this.props.history.goBack}
                        />

                        <div className="tooltip-detail-post">
                            <ToolTip
                            visible={this.state.visible}
                            setIsVisible={(state) => {
                                this.setState({ visible: state })
                            }}
                            component={
                                <div className="Control">
                                    <FaEllipsisV
                                        className="Icon"
                                    />
                                </div>
                            }
                        >
                            <div className="ToolTipControlItem">
                                <Link className="Row"
                                    to={`${router_links.A_EDIT_POST}/${post._id}`}
                                >
                                    <FaEdit className="Icon" />
                                    <div style={{ marginTop: 3 }}>
                                        Chỉnh sửa
                                </div>
                                </Link>
                                <div className="Row"
                                    onClick={() => {
                                        this.setState({isOpenConfirm: true})
                                    }}
                                >
                                    <FaTrash className="Icon" />
                                    <div style={{ marginTop: 3 }}>
                                        Xóa
                                </div>
                                </div>
                            </div>
                        </ToolTip>
                        </div>
                    </div>
                    <h1 className="detail-title">{post.title}</h1>
                    <div className='detail-time'>{post.createdAt && convertIsoDateToString(post.createdAt)}</div>
                </div>
                <RichTextBoxDisplayValue
                    value={post.content}
                />
                <div className="ql-tags">
                    <div className="d-inline-block tag-header">Tags:</div>
                    {post.tags.map((tag, index) => (
                        <Link
                            to={router_links.A_LIST_POST_TAGS + `?name=${tag}`}
                        >
                            <div
                                className="chip-tag"
                                key={index}
                            >
                                <span className="chip-tag-label tag-client">{tag}</span>
                            </div>
                        </Link>

                    ))}
                </div>

                <ModalConfirmAction
                    isOpen={
                        this.state.isOpenConfirm
                    }
                    setIsOpen={(value) => {
                        this.setState({ isOpenConfirm: value })
                    }}
                    onOk={() => {
                        this.props.removePost(this.props.post._id)
                        this.setState({ isOpenConfirm: false })
                        this.props.history.push(router_links.A_LIST_POST)
                    }}
                    onCancel={() => {
                        this.setState({ isOpenConfirm: false })
                    }}
                    text="Bạn chắc chắn xóa bài viết này?"
                    displayCloseIcon={false}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const _state = state.admin_post
    return {
        post: _state.detail_post
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loadPostDetail: postId => {
            dispatch(actions.action.loadDetailPost(postId))
        },
        removePost: postId => {
            dispatch(actions.action.removePost(postId))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PostDetailPage);