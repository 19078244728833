const compareEqualBase = (a, b) => {
    return a._id === b._id;
};

const checkItemInArray = (
    arr = [],
    item = {},
    compareEqual = compareEqualBase
) => {
    if (!Array.isArray(arr)) return false;

    for (let index = 0; index < arr.length; index++)
        if (compareEqual(item, arr[index])) return true;

    return false;
};

const addItemToArray = (
    arr = [],
    item = {},
    compareEqual = compareEqualBase
) => {
    if (!Array.isArray(arr)) return [];

    for (let index = 0; index < arr.length; index++)
        if (compareEqual(item, arr[index])) return arr;

    // if not exist then add item
    arr.push(item);

    return arr;
};

const removeItemFromArray = (
    arr = [],
    item = {},
    compareEqual = compareEqualBase
) => {
    if (!Array.isArray(arr)) return [];

    let new_arr = [];
    for (let index = 0; index < arr.length; index++)
        if (!compareEqual(item, arr[index])) new_arr.push(arr[index]);

    return new_arr;
};

const convertListIdToString = (arr = []) => {
    let ans = "";

    for (let index = 0; index < arr.length - 1; index++)
        ans = ans + arr[index]._id + ",";
    ans = ans + arr[arr.length - 1]._id;

    return ans;
};

const getItemInArray = (
    arr = [],
    item = {},
    compareEqual = compareEqualBase
) => {
    if (!Array.isArray(arr)) return null;

    for (let index = 0; index < arr.length; index++)
        if (compareEqual(item, arr[index])) 
            return arr[index]

    return null
};

const updateItemInArray = (
    arr =[],
    item = {},
    compareEqual = compareEqualBase
) => {
    if (!Array.isArray(arr)) return []

    let temp = []
    for(let index = 0; index < arr.length; index++)
        if (compareEqual(item, arr[index]))
            temp.push(item)
        else 
            temp.push(arr[index])

    return temp
}

export default {
    checkItemInArray,
    addItemToArray,
    removeItemFromArray,
    convertListIdToString,
    getItemInArray,
    updateItemInArray
};
