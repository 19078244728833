import actions from "./actions";

// helper
import ListHelperServices from "../../../services/utils/listHelper";

const defaultOrder = {
    userId: null,
    addressId: null,
    products: [],
    paymentMethod: "DIRECT",
    serviceId: "HAN-EXPRESS",
    voucherCode: "",
};

const default_product_item = {
    _id: "",
    productId: "5f7ae1d8ee54364dcef75e41",
    quantity: 1,
};

const initState = {
    // modal
    modalSelectProductState: false,
    modalSelectUserState: false,
    modalSelectAddressState: false,

    // modal product
    list_products: [],
    md_product_total: 0,
    md_product_page_size: 8,
    md_product_page_index: 1,
    md_product_text_search: "",
    md_product_last_search: "",
    md_product_is_loading: false,

    // modal user
    list_users: [],
    md_user_total: 0,
    md_user_page_size: 5,
    md_user_page_index: 1,
    md_user_text_search: "",
    md_user_last_search: "",
    md_user_is_loading: false,
    select_user: null,

    // modal address
    list_address: [],
    md_address_total: 0,
    md_address_page_size: 5,
    md_address_page_index: 1,
    md_address_text_search: "",
    md_address_last_search: "",
    md_address_is_loading: false,
    select_address: null,

    // list product
    products: [],
    total: 0,

    // current order
    order: defaultOrder,

    discount_code: "",
    discount_info: null,
    shipping_info: null,
    is_loading_shipping_info: false,
};

// helper
const calSumCart = (carts) => {
    try {
        let total = 0;

        for (let index = 0; index < carts.length; index++) {
            let cart = carts[index];

            total = cart.quantity * cart.price + total;
        }

        return total;
    } catch (ex) {
        return 0;
    }
};

// const
const compareProduct = (a, b) => {
    return a.product && b.product && a.product._id === b.product._id;
};

// handle
const _handleInsertProductItem = (state = {}, item = {}) => {
    let products = state.products;

    let cart_item = ListHelperServices.getItemInArray(
        products,
        item,
        compareProduct
    );
    if (cart_item !== null) {
        return _handleUpdateProductItem(
            state,
            cart_item._id,
            parseInt(cart_item.quantity) + parseInt(item.quantity)
        );
    }

    products = ListHelperServices.addItemToArray(products, item);
    let total = calSumCart(products);

    return {
        ...state,
        ...{
            products,
            total,
        },
    };
};

const _handleUpdateProductItem = (state = {}, id = "", quantity = 1) => {
    let carts = state.products;

    let cart_item = ListHelperServices.getItemInArray(carts, { _id: id });
    cart_item.quantity = quantity;
    cart_item.total = cart_item.price * cart_item.quantity;
    carts = ListHelperServices.updateItemInArray(carts, cart_item);

    let total = calSumCart(carts);

    return {
        ...state,
        ...{
            products: carts,
            total,
        },
    };
};

const _handleRemoveProductItem = (state = {}, id = "") => {
    let products = state.products;

    products = ListHelperServices.removeItemFromArray(products, { _id: id });
    let total = calSumCart(products);

    return {
        ...state,
        ...{
            products,
            total,
        },
    };
};

const reducer = (state = initState, action) => {
    let payload = action.payload;

    switch (action.type) {
        case actions.type.UPDATE_STATE:
            return {
                ...state,
                ...payload.state,
            };

        // modal
        case actions.type.SET_MODAL_SELECT_ADDRESS_STATE:
            return {
                ...state,
                ...{
                    modalSelectAddressState: payload.state,
                },
            };

        case actions.type.SET_MODAL_SELECT_PRODUCT_STATE:
            return {
                ...state,
                ...{
                    modalSelectProductState: payload.state,
                },
            };

        case actions.type.SET_MODAL_SELECT_USER_STATE:
            return {
                ...state,
                ...{
                    modalSelectUserState: payload.state,
                },
            };

        // modal product
        case actions.type.LOAD_LIST_PRODUCT:
            return {
                ...state,
                ...{},
            };

        case actions.type.LOAD_LIST_PRODUCT_SUCCESS:
            return {
                ...state,
                ...{
                    list_products: payload.products,
                },
            };

        case actions.type.MD_PRODUCT_UPDATE_PAGE_INDEX:
            return {
                ...state,
                ...{
                    md_product_page_index: payload.pageIndex,
                },
            };

        case actions.type.MD_PRODUCT_UPDATE_TEXT_SEARCH:
            return {
                ...state,
                ...{
                    md_product_text_search: payload.textSearch,
                },
            };

        case actions.type.MD_PRODUCT_SEARCH_PRODUCT:
            return {
                ...state,
                ...{
                    md_product_last_search: state.md_product_text_search,
                    md_product_page_index: 1,
                },
            };

        // modal user
        case actions.type.LOAD_LIST_USER:
            return state;

        case actions.type.LOAD_LIST_USER_SUCCESS:
            return {
                ...state,
                ...{
                    list_users: payload.users,
                },
            };

        case actions.type.MD_USER_UPDATE_PAGE_INDEX:
            return {
                ...state,
                ...{
                    md_user_page_index: payload.pageIndex,
                },
            };

        case actions.type.MD_USER_UPDATE_TEXT_SEARCH:
            return {
                ...state,
                ...{
                    md_user_text_search: payload.textSearch,
                },
            };

        case actions.type.MD_USER_SEARCH_USER:
            return {
                ...state,
                ...{
                    md_user_last_search: state.md_user_text_search,
                    md_user_page_index: 1,
                },
            };

        case actions.type.SELECT_USER:
            return {
                ...state,
                ...{
                    select_user: payload.user,
                    select_address: null,
                },
            };

        // modal address
        case actions.type.LOAD_LIST_ADDRESS:
            return {
                ...state,
                ...{},
            };

        case actions.type.LOAD_LIST_ADDRESS_SUCCESS:
            return {
                ...state,
                ...{
                    list_address: payload.list_address,
                },
            };

        case actions.type.MD_ADDRESS_UPDATE_PAGE_INDEX:
            return {
                ...state,
                ...{
                    md_address_page_index: payload.pageIndex,
                },
            };

        case actions.type.MD_USER_UPDATE_TEXT_SEARCH:
            return {
                ...state,
                ...{
                    md_address_text_search: payload.textSearch,
                },
            };

        case actions.type.MD_ADDRESS_SEARCH_ADDRESS:
            return {
                ...state,
                ...{
                    md_address_last_search: state.md_address_text_search,
                    md_address_page_index: 1,
                },
            };

        case actions.type.SELECT_ADDRESS:
            return {
                ...state,
                ...{
                    select_address: payload.address,
                },
            };

        // current order
        case actions.type.SET_DEFAULT_CURRENT_ORDER_INFO:
            return {
                ...state,
                ...{
                    order: defaultOrder,
                    select_user: null,
                    select_address: null,
                    total: 0,
                    products: [],
                    shipping_info: null,
                    discount_info: null
                }
            }

        case actions.type.UPDATE_CURRENT_ORDER_INFO:
            return {
                ...state,
                ...{
                    order: {
                        ...state.order,
                        ...payload.info,
                    },
                },
            };

        case actions.type.GET_CURRENT_VOUCHER_APPLY:
            return state 

        case actions.type.GET_CURRENT_VOUCHER_APPLY_SUCCESS:
            return {
                discount_info: payload.voucher
            }

        case actions.type.GET_SHIPPING_INFO:
            return state;

        case actions.type.GET_SHIPPING_INFO_SUCCESS:
            return {
                ...state,
                ...{
                    shipping_info: payload.shippingInfo,
                },
            };

        case actions.type.GET_DISCOUNT_INFO:
            return state;

        case actions.type.GET_DISCOUNT_INFO_SUCCESS:
            return {
                ...state,
                ...{
                    discount_info: payload.discountInfo,
                },
            };

        case actions.type.SET_DEFAULT_DISCOUNT_INFO:
            return {
                ...state,
                ...{
                    discount_info: null,
                },
            };

        // list product order
        case actions.type.INSERT_PRODUCT_ITEM_PREPARE:
            return state;

        case actions.type.INSERT_PRODUCT_ITEM:
            return _handleInsertProductItem(state, payload.productItem);

        case actions.type.UPDATE_PRODUCT_ITEM:
            return _handleUpdateProductItem(
                state,
                payload.id,
                payload.quantity
            );

        case actions.type.REMOVE_PRODUCT_ITEM:
            return _handleRemoveProductItem(
                state,
                payload.id,
                payload.quantity
            );

        // order save
        case actions.type.SAVE_CURRENT_ORDER:
            return state;

        default:
            return state;
    }
};

export default reducer;
