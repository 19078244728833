import { all, takeEvery, fork, put, select, take } from "redux-saga/effects";
import PostApiServices from "../../../services/api/post";
import actions from "./actions";

function* saga_LoadListPostByTag(action) {
    try {
        let params = action.payload.params

        let _state = yield select(state => state.user_list_post)
        let { pageIndex, pageSize, textSearch } = _state;

        if (params.pageIndex) pageIndex = params.pageIndex
        if (params.pageSize) pageSize = params.pageSize
        if (params.textSearch) textSearch = params.textSearch

        let param_query = [
            {
                name: "filters[tags]",
                value: _state.tag,
            },
        ];

        let req = yield PostApiServices.GetListPost(textSearch, pageSize, pageIndex, param_query)
        if (req.code === 200) {
            let total = req.data.total
            yield put(
                actions.action.updateState({
                    total
                })
            )

            let posts = req.data.items
            yield put(
                actions.action.loadListPostByTagSuccess(posts)
            )
        }
    } catch (ex) {
        console.log(
            "[User List Post] saga_LoadListPostByTag error : ",
            ex.message
        )
    }
}

function* saga_UpdatePageIndex(action) {
    try {
        const { pageIndex } = action.payload;
        yield put(actions.action.loadListPostByTag({ pageIndex }));
    } catch (ex) {
        console.log(
            "[User List Post] saga_UpdatePageIndex error : ",
            ex.message
        )
    }
}

function* listen() {
    yield takeEvery(actions.type.LOAD_LIST_POST_BY_TAG, saga_LoadListPostByTag);
    yield takeEvery(actions.type.UPDATE_PAGE_INDEX, saga_UpdatePageIndex);

}

export default function* mainSaga() {
    yield all([fork(listen)]);
}
