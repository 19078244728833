import createApiServices from './createApiServices'

const api = createApiServices()

const getListOrderNeedConfirm = (pageSize = 10, pageIndex = 1, textSearch = "", fromDate = "", toDate = "") => {
    return api.makeAuthRequest({
        url: `admin/dashboard/getApproveOrders?pageIndex=${pageIndex}&pageSize=${pageSize}&fitlters[toDate]=${toDate}&filters[fromDate]=${fromDate}`,
        method: "GET"
    })
}

const getChartLevelUser = (month = "", year = "") => {
    return api.makeAuthRequest({
        url: `admin/dashboard/getChartLevels?month=${month}&year=${year}`,
        method: 'GET'
    })
}

const getChartAggregate = (startMonth = "", startYear = "", endMonth = "", endYear = "") => {
    return api.makeAuthRequest({
        url: `admin/dashboard/getChartAggregate?startMonth=${startMonth}&endMonth=${endMonth}&startYear=${startYear}&endYear=${endYear}`,
        method: 'GET'
    })
}

const getTotalRevenue = (startDate = "", toDate= "") => {
    return api.makeAuthRequest({
        url: `admin/dashboard/getTotalRevenues?startDate=${startDate}&toDate=${toDate}`,
        method: 'GET'
    })
}

const getTotalOrder = (startDate = "", toDate = "") => {
    return api.makeAuthRequest({
        url: `admin/dashboard/getTotalOrders?startDate=${startDate}&toDate=${toDate}`,
        method: 'GET'
    })
}

const getTotalProduct = (startDate = "", toDate = "") => {
    return api.makeAuthRequest({
        url: `admin/dashboard/getTotalProducts?startDate=${startDate}&toDate=${toDate}`,
        method: 'GET'
    })
}

const getTotalMember = (startDate = "", toDate = "") => {
    return api.makeAuthRequest({
        url: `admin/dashboard/getTotalMembers?startDate=${startDate}&toDate=${toDate}`,
        method: 'GET'
    })
}

export default {
    getListOrderNeedConfirm,
    getChartLevelUser,
    getChartAggregate,
    getTotalRevenue,
    getTotalOrder,
    getTotalProduct,
    getTotalMember
}