import React, { Component } from "react";
// redux
import { connect } from "react-redux";
// router
import { Link } from "react-router-dom";
// component
import Pagination from "../../../../../components/paginations/paginations";
import Router_Links from "../../../../../const/router_link";
// helper
import {
  convertIsoDateToString,
  numberDisplayThousand,
} from "../../../../../services/utils/helper";
import {
  getTypeOrderStatus,
  OrderStatus,
} from "../../../../../types/order.type";
import UserOrderAction from "../../../../redux/user_profile_history_order/actions";
// scss
import "./index.scss";

const list_flexs = [20, 10, 10, 10, 10];

class UserHistoryBillPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageIndex: 5,
      pageSize: 10,
      total: 100,
    };
  }

  // life cycle
  componentDidMount = () => {
    this.props.init();
  };

  renderStatusOrder = (status) => {
    return (
      <span
        style={{ color: getTypeOrderStatus(status).color, fontWeight: 600 }}
      >
        {getTypeOrderStatus(status).name}
      </span>
    );
  };

  render() {
    let orders = this.props.orders ? this.props.orders : [];
    let { pageSize, pageIndex, total } = this.props;

    return (
      <div className="UserHistoryBillPage">
        <div className="UHB_Title">Đơn hàng của tôi</div>

        <table className="CDS_Table">
          <tr className="CDS_TableRowHeader">
            <th
              className="CDS_HeaderText"
              style={{
                // flex: list_flexs[0],
                width: 180,
                justifyContent: "left",
              }}
            >
              Mã đơn hàng
            </th>
            <th
              className="CDS_HeaderText miniHide"
              style={{ flex: list_flexs[1] }}
            >
              Ngày tạo
            </th>
            <th className="CDS_HeaderText" style={{ flex: list_flexs[2] }}>
              Tổng tiền
            </th>
            <th
              className="CDS_HeaderText miniHide"
              style={{ flex: list_flexs[3] }}
            >
              Thanh toán
            </th>
            <th className="CDS_HeaderText" style={{ flex: list_flexs[4] }}>
              Trạng thái
            </th>
          </tr>
          {orders.map((order, index) => {
            return (
              <tr
                className="CDS_TableRow"
                style={{
                  borderBottom:
                    index === orders.length - 1 ? "none" : "1px solid #e2e2e2",
                }}
              >
                <td
                  className="CDS_TableCell"
                  style={{
                    // flex: list_flexs[0],
                    width: 180,
                    justifyContent: "left",
                  }}
                >
                  <Link
                    to={`${Router_Links.U_PROFILE_DETAIL_BILL}/${order._id}`}
                    style={{
                      fontWeight: "bold",
                      textDecoration: "underline",
                      color: "black",
                    }}
                  >
                    {order.code}
                  </Link>
                </td>
                <td
                  className="CDS_TableCell miniHide"
                  style={{ flex: list_flexs[1] }}
                >
                  {convertIsoDateToString(new Date(order.createdAt))}
                </td>
                <td className="CDS_TableCell" style={{ flex: list_flexs[2] }}>
                  {numberDisplayThousand(order.totalPrice)}đ
                </td>
                <td
                  className="CDS_TableCell miniHide"
                  style={{ flex: list_flexs[3] }}
                >
                  {order.paid === 1 ? "Đã thanh toán" : "Chưa thanh toán"}
                </td>
                <td
                  className="CDS_TableCell"
                  style={{
                    flex: list_flexs[4],
                  }}
                >
                  {this.renderStatusOrder(order.status)}
                </td>
              </tr>
            );
          })}
        </table>

        <div className="UHB_PaginateSection">
          <Pagination
            onChange={(index) => {
              this.props.updatePageIndex(index);
            }}
            pageSize={pageSize}
            pageIndex={pageIndex}
            total={total}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let _state = state.user_profile_history_order;
  return {
    orders: _state.orders,
    pageSize: _state.pageSize,
    pageIndex: _state.pageIndex,
    total: _state.total,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    init: () => {
      dispatch(UserOrderAction.action.loadListHistoryOrder());
    },
    updatePageIndex: (pageIndex) => {
      dispatch(UserOrderAction.action.updatePageIndex(pageIndex));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserHistoryBillPage);
