import actions from "./actions";

// get local data
import { getLocalData } from "../../../services/utils/storageHelper";

const _getSessionKey = () => {
  const session_key = getLocalData("session_key", null);
  return session_key ? session_key : null;
};

const default_user = {
  fullName: "",
  phone: "",
  sex: 1,
  dob: new Date(),
  address: "",
  email: "",
  file: null,
  groupCustomerId: null,
  groupPermissions: [],
};

const initState = {
  session_key: _getSessionKey(),
  isLoggedIn: _getSessionKey() != null,
  userInfo: null,
  isLoading: false,

  message: "",
  isChangePasswordOk: false,
  showMessageChangePassword: false,

  current_user_info: default_user,

  groups: [],
  modalChangePasswordAdmin: false,
  groupPermissions: [],
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actions.type.LOGIN:
      return {
        ...state,
        ...{
          isLoading: true,
        },
      };

    case actions.type.LOGIN_ADMIN:
      return {
        ...state,
        ...{
          isLoading: true,
        },
      };

    case actions.type.LOGOUT:
      return {
        ...state,
        ...{
          isLoggedIn: false,
          session_key: null,
          userInfo: null,
          message: "",
          isLoading: false,
        },
      };

    case actions.type.GET_USER_INFO:
      return state;

    case actions.type.CHECK_SESSION:
      return state;

    case actions.type.CHECK_SESSION_ADMIN:
      return state;

    case actions.type.UPDATE_STATE:
      return {
        ...state,
        ...action.payload.state,
      };

    case actions.type.CHANGE_PASSWORD:
      return {
        ...state,
        ...{
          showMessageChangePassword: false,
        },
      };

    case actions.type.UPDATE_CURRENT_USER_INFO:
      return {
        ...state,
        ...{
          current_user_info: {
            ...state.current_user_info,
            ...action.payload.userInfo,
          },
        },
      };

    case actions.type.SET_CURRENT_USER_INFO:
      return {
        ...state,
        ...{
          current_user_info: state.userInfo,
        },
      };

    case actions.type.SAVE_CURRENT_USER_INFO:
      return state;

    case actions.type.LOAD_LIST_CUSTOMER_GROUP:
      return state;

    case actions.type.LOAD_LIST_CUSTOMER_GROUP_SUCCESS:
      return {
        ...state,
        ...{
          groups: action.payload.groups,
        },
      };
    case actions.type.SET_MODAL_CHANGE_PASSWORD_ADMIN:
      return {
        ...state,
        ...{
          modalChangePasswordAdmin: action.payload.state,
        },
      };
    default:
      return state;
  }
};

export default reducer;
