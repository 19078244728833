// query
import queryString from "query-string";
import React, { Component } from "react";
// redux
import { connect } from "react-redux";
// route
import { Link, Redirect, withRouter } from "react-router-dom";
import { MAX_LENGTH_EMAIL } from "../../../../const/email_consts";
import { MAX_PASSWORD } from "../../../../const/password_consts";
import { MAX_LENGTH_PHONE } from "../../../../const/phone_consts";
import { regexNumber } from "../../../../const/regex_consts";
import { regexPassword } from "../../../../const/regex_password";
import Router_Links from "../../../../const/router_link";
//images
import UserImage from "../../../../resource/images/user.jpg";
// api
import { registerUser } from "../../../../services/api/auth";
import NotificationsService from "../../../../services/utils/notifycationHelper";
// validate
import ValidateUserInfoService from "../../../../services/utils/validate/validate_user_info";
import AuthAction from "../../../redux/auth/actions";
import { history } from "../../../redux/store";
// css
import "./index.scss";

// data
const default_user_info = {
  fullName: "",
  email: "",
  phone: "",
  password: "",
  confirmPassword: "",
  groupCustomerId: null,
};

class UserView_RegisterPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      linkAffiliate: "",
      userInfo: default_user_info,
    };
  }

  // life cycle
  componentDidMount = () => {
    this.props.init();

    let params = queryString.parse(this.props.location.search);
    let affiliate = params.affiliate ? params.affiliate : "";

    if (affiliate !== "") {
      this.setState({
        linkAffiliate: affiliate,
      });
    } else {
    }
  };

  // handle
  _onSubmit = async () => {
    let req;
    try {
      let userInfo = { ...this.state.userInfo };

      userInfo.email = userInfo.email.trim();
      userInfo.fullName = userInfo.fullName.trim();

      let validate = ValidateUserInfoService.validate(userInfo);
      if (!validate.isValidate) {
        NotificationsService.error(validate.message);
        return;
      }

      if (!this.state.linkAffiliate || this.state.linkAffiliate.trim() === "") {
        NotificationsService.error("Mã giới thiệu không được để trống");
        return;
      }

      // send api
      req = await registerUser(userInfo, this.state.linkAffiliate);

      if (req.code === 200) {
        NotificationsService.success(
          "Tài khoản của bạn đã đăng ký thành công, vui lòng đăng nhập lại để sử dụng hệ thống"
        );
        history.push(Router_Links.U_AUTH_LOGIN);
      } else {
        NotificationsService.error(
          "Đăng ký tài khoản thất bại : " + (req && req.message)
            ? req.message
            : ""
        );
      }
    } catch (ex) {
      let message = ex.message ? `: ${ex.message}` : "";
      NotificationsService.error(`Đăng ký tài khoản thất bại ${message}`);
      console.log("[register user] error: ", ex.message);
    }
  };

  // action
  _updateUserInfo = (info) => {
    this.setState({
      userInfo: {
        ...this.state.userInfo,
        ...info,
      },
    });
  };

  onChangePassword = (evt) => {
    const password = evt.target.value;
    if (!regexPassword.test(password) && password.length <= MAX_PASSWORD) {
      this._updateUserInfo({
        password,
      });
    }
  };

  onChangeConfirmPassword = (evt) => {
    const confirmPassword = evt.target.value;
    if (
      !regexPassword.test(confirmPassword) &&
      confirmPassword.length <= MAX_PASSWORD
    ) {
      this._updateUserInfo({
        confirmPassword,
      });
    }
  };

  onChangeEmail = (evt) => {
    const email = evt.target.value;
    if (email.length <= MAX_LENGTH_EMAIL) {
      this._updateUserInfo({ email });
    }
  };

  onChangePhoneNumber = (evt) => {
    const phone = evt.target.value;
    if (regexNumber.test(phone) && phone.length <= MAX_LENGTH_PHONE) {
      this._updateUserInfo({ phone });
    }
  };

  onChangeLinkAffiliate = (evt) => {
    const linkAffiliate = evt.target.value;
    if (
      regexNumber.test(linkAffiliate) &&
      linkAffiliate.length <= MAX_LENGTH_PHONE
    ) {
      this.setState({
        linkAffiliate,
      });
    }
  };

  onSubmitFormByEnterKey = (evt) => {
    evt.preventDefault();
    this._onSubmit();
  };

  // render
  render() {
    let from = null;
    if (this.props.isLoggedIn) {
      if (from) return <Redirect to={from} />;

      return <Redirect to={Router_Links.U_PROFILE_INFO} />;
    }

    let listCustomerGroups = this.props.groups;

    return (
      <div className="UserView_RegisterPage">
        <div className="UserView_ForgotPasswordPanel">
          <form onSubmit={this.onSubmitFormByEnterKey}>
            <div className="UFG_InfoPanel">
              <div className="LgP_TopPanel">
                <img className="LPT_Image" src={UserImage} />
                <div className="LPT_Title">Đăng ký</div>
              </div>
              <div className="UFIF_TitleRow">Họ và tên</div>
              <input
                type="text"
                placeholder=""
                className="UFIF_Input"
                value={this.state.userInfo.fullName}
                onChange={(e) => {
                  this._updateUserInfo({
                    fullName: e.target.value,
                  });
                }}
              />

              <div className="UFIF_TitleRow">SĐT</div>
              <input
                type="text"
                placeholder=""
                className="UFIF_Input"
                value={this.state.userInfo.phone}
                onChange={this.onChangePhoneNumber}
              />

              <div className="UFIF_TitleRow">Email</div>
              <input
                type="text"
                placeholder=""
                className="UFIF_Input"
                value={this.state.userInfo.email}
                onChange={this.onChangeEmail}
              />

              <div className="UFIF_TitleRow">Mật khẩu</div>
              <input
                type="password"
                placeholder=""
                className="UFIF_Input"
                value={this.state.userInfo.password}
                onChange={this.onChangePassword}
              />

              <div className="UFIF_TitleRow">Nhập lại mật khẩu</div>
              <input
                type="password"
                placeholder=""
                className="UFIF_Input"
                value={this.state.userInfo.confirmPassword}
                onChange={this.onChangeConfirmPassword}
              />

              <div className="UFIF_TitleRow">Mã người dùng giới thiệu</div>
              <input
                type="text"
                placeholder=""
                className="UFIF_Input"
                value={this.state.linkAffiliate}
                onChange={this.onChangeLinkAffiliate}
              />

              {/* <div className="UFIF_TitleRow">Nhóm khách hàng</div>
              <Select
                value={this.state.userInfo.groupCustomerId}
                onChange={(value) => {
                  this.setState({
                    userInfo: {
                      ...this.state.userInfo,
                      ...{
                        groupCustomerId: value,
                      },
                    },
                  });
                }}
                style={{
                  width: "100%",
                }}
              >
                {listCustomerGroups.map((group, index) => {
                  return (
                    <Select.Option value={group._id}>
                      {group.name}
                    </Select.Option>
                  );
                })}
              </Select> */}
              <input type="submit" className="d-none" />

              <div
                className="UFIF_BtnResetPassword"
                onClick={() => {
                  this._onSubmit();
                }}
              >
                Đăng ký
              </div>

              <div className="UFIF_More">
                Bạn đã có tài khoản?
                <Link to="/auth/login" className="UFIF_Title_MORE">
                  {" "}
                  Đăng nhập
                </Link>
              </div>
            </div>
          </form>

          <div className="UFG_ImagePanel">
            <img
              className="UFGI_Image"
              src="https://www.incimages.com/uploaded_files/image/1920x1080/GettyImages-1218524083_435245.jpg"
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    groups: state.auth.groups,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    init: () => {
      dispatch(AuthAction.action.loadListCustomerGroup());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserView_RegisterPage));
