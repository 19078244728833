import React, { Component } from "react";
// icon
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
// redux
import { connect } from "react-redux";
// router
import { Link } from "react-router-dom";
import Router_Link from "../../../../const/router_link";
// banner
import banner0 from "../../../../resource/images/banner_0.png";
import banner1 from "../../../../resource/images/banner_1.png";
import banner2 from "../../../../resource/images/banner_2.png";
import { getImageUrl } from "../../../../services/api/upload";
import { numberDisplayThousand } from "../../../../services/utils/helper";

let images = [
  banner0,
  banner1,
  banner2,
  // banner3,
  // banner4
];

class TopMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentImage: 0,
    };
  }

  render() {
    let menus = this.props.categorys ? this.props.categorys : [];
    let flashsale_products = this.props.flashsale_products
      ? this.props.flashsale_products
      : [];
    let flashsale_product =
      flashsale_products.length > 0 ? flashsale_products[0] : {};
    let flashsale = this.props.flashsale ? this.props.flashsale : {};

    var options = {
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
    };
    let date = new Date(flashsale.stopTime);

    return (
      <div className="TopMenu">
        <div className="LeftMenu">
          <div className="MenuT">
            {menus.map((item, index) => {
              return (
                <Link
                  to={`${Router_Link.U_FILTER_PRODUCT}?category=${item._id}`}
                  className="MenuItem"
                  style={{
                    borderBottom:
                      index === menus.length - 1
                        ? "0px solid #e2e2e2"
                        : "1px solid #e2e2e2",
                  }}
                >
                  {/* {item.icon} */}
                  <span className="MenuItem_Title">{item.description}</span>
                </Link>
              );
            })}
          </div>
        </div>
        <div className="PanelCenter">
          <img src={images[this.state.currentImage]} className="Center_Image" />
          <div
            className="Center_ArrowLeft"
            onClick={() => {
              let total = images.length;
              let newIndex = (this.state.currentImage - 1 + total) % total;
              this.setState({ currentImage: newIndex });
            }}
          >
            <FaAngleLeft className="Icon" />
          </div>
          <div
            className="Center_ArrowRight"
            onClick={() => {
              let total = images.length;
              let newIndex = (this.state.currentImage + 1 + total) % total;
              this.setState({ currentImage: newIndex });
            }}
          >
            <FaAngleRight className="Icon" />
          </div>
        </div>

        {flashsale_products.length > 0 && (
          <Link
            to={`${Router_Link.U_DETAIL_PRODUCT}/${flashsale_product._id}`}
            className="RightMenu"
          >
            <div className="RightMenu_Title">Flash sales</div>
            <div className="RightMenu_flashsale-name">{flashsale.name}</div>

            <img
              className="RightMenu_ImageProduct"
              src={getImageUrl(flashsale_product.thumbnail)}
            />
            <div className="RightMenu_ProductName">
              {flashsale_product.name}
            </div>
            <div className="RightMenu_OldPrice">
              {numberDisplayThousand(flashsale_product.price)}
            </div>
            <div className="RightMenu_NewPrice">
              {numberDisplayThousand(flashsale_product.flashSalePrice)}
            </div>
            <div className="RightMenu_Info">
              {/* Kết thúc lúc 12:00 ngày 01/09/2020 */}
              {`Kết thúc vào lúc ${date.toLocaleTimeString(
                "vi-vn",
                options
              )} ngày ${date.toLocaleDateString()}`}
            </div>
          </Link>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categorys: state.user_global.categorys,
    flashsale_products: state.user_global.flashsale_products,
    flashsale: state.user_global.flashsale,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TopMenu);
