import React, { Component } from "react";
// icon
import {
  FaAngleDown,
  FaEdit,
  FaEye,
  FaEyeSlash,
  FaInfo,
  FaList,
  FaPlusCircle,
  FaSearch,
  FaSortAlphaDown,
  FaSortAlphaDownAlt,
  FaSortNumericDown,
  FaSortNumericDownAlt,
  FaTh,
  FaTrash,
} from "react-icons/fa";
// redux
import { connect } from "react-redux";
// react-router-dom
import { Link, withRouter } from "react-router-dom";
import EmptySearch from "../../../../components/empty_search";
import ModalConfirmAction from "../../../../components/modal/modal_confirm_action";
// component
import Pagination from "../../../../components/paginations/paginations";
import module_app from "../../../../const/module_app";
import permission_action from "../../../../const/permission_action";
import Router_Links from "../../../../const/router_link";
import { getImageUrl } from "../../../../services/api/upload";
// helper
import { numberDisplayThousand } from "../../../../services/utils/helper";
import ProductActions from "../../../redux/admin_product/actions";
import { SORT_MODE_CONST } from "../../../redux/admin_product/reducer";
import { paramsPermisson } from "../../../routers/admin_site";
import AdminCheckPermissionComponent from "../components/admin-check-permission";
// scss
import "./index.scss";
import { apiUpdateHideProduct } from "./product-list.service";

const list_flexs = [5, 25, 10, 15, 30];

class AdminProductList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalConfirmDeleteState: false,
      currentProduct: 0,
    };

    this.searchFlag = false;
  }

  componentDidMount = () => {
    this.props.loadListProduct();
  };

  onUpdateHideProduct = async (productId, isHide) => {
    await apiUpdateHideProduct(productId, isHide);
    this.props.loadListProduct();
  };

  // render
  renderDisplayTableProduct = () => {
    let products = this.props.products ? this.props.products : [];
    let { pageSize, pageIndex } = this.props;

    if ((Array.isArray(products) && products.length) || !this.searchFlag) {
      return (
        <table className="CDS_Table" style={{}}>
          <tr className="CDS_TableRowHeader">
            <th
              className="CDS_HeaderText"
              style={{
                flex: list_flexs[0],
                // justifyContent: "left",
                marginRight: 10,
              }}
            >
              STT
            </th>
            <th
              className="CDS_HeaderText"
              style={{
                flex: list_flexs[1],
                justifyContent: "left",
              }}
            >
              Sản phẩm
            </th>
            <th
              className="CDS_HeaderText superMiniHide"
              style={{ flex: list_flexs[2] }}
            >
              Giá
            </th>

            <th
              className="CDS_HeaderText miniHide"
              style={{ flex: list_flexs[3] }}
            >
              Đơn vị
            </th>

            <th
              className="CDS_HeaderText miniHide"
              style={{ flex: list_flexs[3] }}
            >
              Số lượng
            </th>

            <th
              className="CDS_HeaderText miniHide"
              style={{ flex: list_flexs[3] }}
            >
              {/* Loại sản phẩm */}
              Điểm thưởng
            </th>
            <th className="CDS_HeaderText" style={{ flex: list_flexs[4] }}>
              Hành động
            </th>
          </tr>
          {products.map((product, index) => {
            return (
              <tr
                className="CDS_TableRow"
                style={{
                  borderBottom:
                    index === products.length - 1
                      ? "none"
                      : "1px solid #e2e2e2",
                }}
              >
                <td
                  className="CDS_TableCell"
                  style={{
                    flex: list_flexs[0],
                    // justifyContent: "left",
                    marginRight: 10,
                  }}
                >
                  {index + 1 + pageSize * (pageIndex - 1)}
                </td>
                <td
                  className="CDS_TableCell"
                  style={{
                    flex: list_flexs[1],
                    justifyContent: "left",
                  }}
                >
                  <img
                    // className="APL_ProductImage"
                    style={{
                      height: 60,
                      width: 60,
                      objectFit: "contain",
                      marginRight: 10,
                      border: "1px solid #f2f2f2",
                    }}
                    src={getImageUrl(product.thumbnail)}
                    alt="thumbnail"
                  />
                  {product.name}
                </td>
                <td
                  className="CDS_TableCell superMiniHide"
                  style={{ flex: list_flexs[2] }}
                >
                  {numberDisplayThousand(product.price)}
                </td>

                <td
                  className="CDS_TableCell miniHide"
                  style={{ flex: list_flexs[3] }}
                >
                  {product.productUnit && product.productUnit.description}
                </td>

                <td
                  className="CDS_TableCell miniHide"
                  style={{ flex: list_flexs[3] }}
                >
                  {product.quantity}
                </td>

                <td
                  className="CDS_TableCell miniHide"
                  style={{ flex: list_flexs[3] }}
                >
                  {numberDisplayThousand(product.point)} điểm
                  {/* {product.isValueAddedProduct
                    ? "Sản phầm tích điểm"
                    : "Sản phẩm giá trị cốt lõi"} */}
                </td>

                <td className="CDS_TableCell" style={{ flex: list_flexs[4] }}>
                  <AdminCheckPermissionComponent
                    paramsPermission={paramsPermisson(
                      module_app.MODULE_PRODUCT_MANAGEMENT,
                      permission_action.UPDATE
                    )}
                    noContent
                  >
                    <Link
                      to={`${Router_Links.A_DETAIL_PRODUCT}?id=${product._id}`}
                      className="APL_Table_Action"
                      style={{
                        backgroundColor: "#00b33c",
                        color: "white",
                      }}
                    >
                      <FaEdit className="APL_Table_Icon" />
                      <span className="superMiniHide">Sửa</span>
                    </Link>
                  </AdminCheckPermissionComponent>

                  <AdminCheckPermissionComponent
                    paramsPermission={paramsPermisson(
                      module_app.MODULE_PRODUCT_MANAGEMENT,
                      permission_action.REMOVE
                    )}
                    noContent
                  >
                    <div
                      onClick={() => {
                        this.setState({
                          modalConfirmDeleteState: true,
                          currentProduct: product._id,
                        });
                      }}
                      className="APL_Table_Action"
                      style={{
                        backgroundColor: "orange",
                        color: "white",
                      }}
                    >
                      <FaTrash className="APL_Table_Icon" />
                      <span className="superMiniHide">Xoá</span>
                    </div>
                  </AdminCheckPermissionComponent>

                  <AdminCheckPermissionComponent
                    paramsPermission={paramsPermisson(
                      module_app.MODULE_PRODUCT_MANAGEMENT,
                      permission_action.UPDATE
                    )}
                    noContent
                  >
                    <div
                      className="APL_Table_Action"
                      style={{
                        backgroundColor: !product?.isHide
                          ? "#919592"
                          : "#a120b1",
                        color: "white",
                      }}
                      onClick={() => {
                        this.onUpdateHideProduct(product._id, !product?.isHide);
                      }}
                    >
                      {product?.isHide ? (
                        <FaEye className="APL_Table_Icon" />
                      ) : (
                        <FaEyeSlash className="APL_Table_Icon" />
                      )}
                      <span className="superMiniHide">
                        {!product?.isHide ? "Ẩn" : "Hiện"}
                      </span>
                    </div>
                  </AdminCheckPermissionComponent>
                </td>
              </tr>
            );
          })}
        </table>
      );
    } else {
      return <EmptySearch />;
    }
  };

  renderDisplayGridProduct = () => {
    let products = this.props.products ? this.props.products : [];

    if ((Array.isArray(products) && products.length) || !this.searchFlag) {
      return products.map((product, index) => {
        return (
          <div
            className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-12 col-6"
            style={{
              paddingLeft: 0,
              paddingRight: 0,
            }}
          >
            <div className="APL_ProductItem">
              <img
                className="APL_ProductImage"
                src={getImageUrl(product.thumbnail)}
                alt="thumbnail"
              />

              <div className="APL_ProductAction">
                <Link
                  to={`${Router_Links.A_DETAIL_PRODUCT}?id=${product._id}`}
                  className="APL_ProductActionPanel"
                >
                  <FaInfo className="APL_ProductActionIcon" />
                </Link>
                <div
                  onClick={() => {
                    this.setState({
                      modalConfirmDeleteState: true,
                      currentProduct: product._id,
                    });
                  }}
                  className="APL_ProductActionPanel"
                >
                  <FaTrash className="APL_ProductActionIcon" />
                </div>
              </div>

              <div className="APL_ProductContent">
                <div className="APL_ProductName">{product.name}</div>
                <div className="APL_ProductPrice">
                  {numberDisplayThousand(product.price)}
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else {
      return <EmptySearch />;
    }
  };

  render() {
    let { pageSize, total, pageIndex, displayProductGrid } = this.props;

    return (
      <div className="AdminProductList">
        <div className="APL_FilterPanel">
          <div className="filterPanel">
            <div
              className={
                "APL_ButtonDisplay " + (displayProductGrid ? "active" : "")
              }
              onClick={() => this.props.setDisplayProductGridState(true)}
            >
              <FaTh className="_Icon" />
            </div>

            <div
              className={
                "APL_ButtonDisplay " + (displayProductGrid ? "" : "active")
              }
              onClick={() => this.props.setDisplayProductGridState(false)}
            >
              <FaList className="_Icon" />
            </div>
          </div>

          <div className="FilterSearchAndSort">
            <div className="APL_SearchBoxPanel">
              <input
                type="text"
                placeholder="Tìm kiếm"
                aria-label="Tim kiem"
                name="keyword"
                value={this.props.textSearch}
                onChange={(e) => {
                  this.props.updateTextSearch(e.target.value);
                }}
                className="APL_SearchBox"
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    this.searchFlag = true;
                    this.props.searchProduct(this.props.textSearch);
                  }
                }}
              />
              <FaSearch
                className="APL_SearchBoxIcon"
                onClick={() => {
                  this.searchFlag = true;
                  this.props.searchProduct(this.props.textSearch);
                }}
              />
            </div>

            <div className="dropdown">
              <div className="APL_ButtonAction">
                Sắp xếp
                <FaAngleDown style={{ marginLeft: 5 }} />
              </div>
              <div className="dropdown-content">
                <div
                  className="APL_DropDownItem"
                  onClick={() => {
                    this.props.updateSortMode(SORT_MODE_CONST.SORT_ASC_PRICE);
                  }}
                >
                  <FaSortNumericDown className="APL_Icon" />
                  Tăng dần theo giá sản phẩm
                </div>
                <div
                  className="APL_DropDownItem"
                  onClick={() => {
                    this.props.updateSortMode(SORT_MODE_CONST.SORT_DESC_PRICE);
                  }}
                >
                  <FaSortNumericDownAlt className="APL_Icon" />
                  Giảm dần theo giá sản phẩm
                </div>
                <div
                  className="APL_DropDownItem"
                  onClick={() => {
                    this.props.updateSortMode(SORT_MODE_CONST.SORT_ASC_NAME);
                  }}
                >
                  <FaSortAlphaDown className="APL_Icon" />
                  Tăng dần theo tên sản phẩm
                </div>
                <div
                  className="APL_DropDownItem"
                  onClick={() => {
                    this.props.updateSortMode(SORT_MODE_CONST.SORT_DESC_NAME);
                  }}
                >
                  <FaSortAlphaDownAlt className="APL_Icon" />
                  Giảm dần theo tên sản phẩm
                </div>
              </div>
            </div>

            <div className="dropdown _display_page">
              <div className="APL_ButtonAction">
                {this.props.pageSize} sản phẩm / trang
                <FaAngleDown style={{ marginLeft: 5 }} />
              </div>
              <div className="dropdown-content">
                <div
                  className="APL_DropDownItem"
                  onClick={() => {
                    this.props.updatePageSize(4);
                  }}
                >
                  4 sản phẩm / trang
                </div>
                <div
                  className="APL_DropDownItem"
                  onClick={() => {
                    this.props.updatePageSize(8);
                  }}
                >
                  8 sản phẩm / trang
                </div>
                <div
                  className="APL_DropDownItem"
                  onClick={() => {
                    this.props.updatePageSize(12);
                  }}
                >
                  12 sản phẩm / trang
                </div>
                <div
                  className="APL_DropDownItem"
                  onClick={() => {
                    this.props.updatePageSize(24);
                  }}
                >
                  24 sản phẩm / trang
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="APL_FilterPanel">
          <AdminCheckPermissionComponent
            paramsPermission={paramsPermisson(
              module_app.MODULE_PRODUCT_MANAGEMENT,
              permission_action.INSERT
            )}
            noContent
          >
            <div
              className="APL_ButtonAdd"
              onClick={() => {
                this.props.setDefaultCurrentProduct();
                this.props.history.push(Router_Links.A_CREATE_PRODUCT);
              }}
            >
              <FaPlusCircle className="APL_ButtonAddIcon" />
              Thêm sản phẩm
            </div>
          </AdminCheckPermissionComponent>
        </div>

        {displayProductGrid ? (
          <div className="APL_ProductPanel row">
            {this.renderDisplayGridProduct()}
          </div>
        ) : (
          <div
            style={{
              marginTop: 10,
              marginLeft: 10,
              marginRight: 10,
            }}
          >
            {this.renderDisplayTableProduct()}
          </div>
        )}

        <div className="UHB_PaginateSection">
          <Pagination
            onChange={(index) => {
              window.scrollTo(0, 0);
              this.props.updatePageIndex(index);
            }}
            pageSize={pageSize}
            pageIndex={pageIndex}
            total={total}
          />
        </div>

        <ModalConfirmAction
          isOpen={this.state.modalConfirmDeleteState}
          setIsOpen={(value) =>
            this.setState({ modalConfirmDeleteState: value })
          }
          onOk={() => {
            this.setState({ modalConfirmDeleteState: false });
            this.props.deleteProduct(this.state.currentProduct);
          }}
          onCancel={() => this.setState({ modalConfirmDeleteState: false })}
          text="Bạn chắc chắn xoá sản phẩm này?"
          displayCloseIcon={true}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    products: state.admin_product.products,
    total: state.admin_product.total,
    pageSize: state.admin_product.pageSize,
    pageIndex: state.admin_product.pageIndex,
    textSearch: state.admin_product.textSearch,
    displayProductGrid: state.admin_product.displayProductGrid,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadListProduct: () => {
      dispatch(ProductActions.action.loadListProduct({ isCms: true }));
    },
    updatePageIndex: (pageIndex) => {
      dispatch(ProductActions.action.updatePageIndex(pageIndex));
    },
    updatePageSize: (pageSize) => {
      dispatch(ProductActions.action.updatePageSize(pageSize));
    },
    deleteProduct: (id) => {
      dispatch(ProductActions.action.deleteProduct(id));
    },
    searchProduct: (keyword) => {
      dispatch(ProductActions.action.searchProduct(keyword));
    },
    updateTextSearch: (textSearch) => {
      dispatch(ProductActions.action.updateTextSearch(textSearch));
    },
    updateSortMode: (mode) => {
      dispatch(ProductActions.action.updateSortMode(mode));
    },
    setDefaultCurrentProduct: () => {
      dispatch(ProductActions.action.setDefaultCurrentProduct());
    },
    setDisplayProductGridState: (state) => {
      dispatch(ProductActions.action.setDisplayProductGridState(state));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AdminProductList));
