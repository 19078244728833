import React, { Component } from "react";
import { Tree, Switch } from "antd";
import { CarryOutOutlined, FormOutlined } from "@ant-design/icons";

// icons
import { FaFacebook } from "react-icons/fa";

// protypes
import PropTypes from "prop-types";

const COLOR = {
  ACTIVE: "#000000",
  UNACTIVE: "#ff0000",
};

class TreeViewComponent extends Component {
  render() {
    let showLine = {
      showLeafIcon: false,
    };

    const { activeIcon, unActiveIcon, useCustom, colorUnactive, colorActive } =
      this.props;
    return (
      <div>
        <Tree
          titleRender={
            useCustom &&
            ((item) => (
              <TreeNodeCustom
                title={`${item.title} - Vị trí: ${item.indexNumber}`}
                status={item.status}
                activeIcon={activeIcon}
                unActiveIcon={unActiveIcon}
                colorUnactive={colorUnactive}
                colorActive={colorActive}
              />
            ))
          }
          showLine={showLine}
          showIcon={false}
          selectedKeys={this.props.value}
          onSelect={(value) => this.props.onSelect(value)}
          treeData={this.props.data_source}
          multiple={this.props.multiple ? this.props.multiple : false}
          defaultExpandAll={true}
        />
      </div>
    );
  }
}

function TreeNodeCustom({
  title,
  status,
  activeIcon,
  unActiveIcon,
  colorUnactive,
  colorActive,
}) {
  const active = colorActive ? colorActive : COLOR.ACTIVE;
  const unActive = colorUnactive ? colorUnactive : COLOR.UNACTIVE;
  return (
    <span
      style={{
        color: status === true ? active : unActive,
      }}
    >
      {status === true ? activeIcon : unActiveIcon}
      {title}
    </span>
  );
}

TreeViewComponent.defaultProps = {
  data_source: [],
};

TreeViewComponent.propTypes = {
  data_source: PropTypes.array,
  value: PropTypes.string,

  onSelect: PropTypes.func,
};

export default TreeViewComponent;
