import actions from "./actions";

const initState = {
    transactions: [],

    pageIndex: 1,
    pageSize: 6,
    total: 0
};

const reducer = (state = initState, action) => {
    let payload = action.payload;

    switch (action.type) {
        case actions.type.UPDATE_STATE:
            return {
                ...state,
                ...payload.state,
            };

        case actions.type.LOAD_LIST_HISTORY_TRANSACTION:
            return state 

        case actions.type.LOAD_LIST_HISTORY_TRANSACTION_SUCCESS:
            return {
                ...state,
                ...{
                    transactions: payload.transactions
                }
            }

        case actions.type.UPDATE_PAGE_INDEX:
            return {
                ...state,
                ...{
                    pageIndex: payload.pageIndex
                }
            }
        
        default:
            return state;
    }
};

export default reducer;
