const validate = (entity) => {
  // function
  const errorResult = (message) => {
    return {
      isValidate: false,
      message,
    };
  };

  // validate
  if (entity.fullName === null || entity.fullName.trim() === "")
    return errorResult("Họ và tên không được để trống");

  if (entity.phone === null || entity.phone.trim() === "")
    return errorResult("SĐT không được để trống");

  // if (entity.email === null || entity.email === "")
  //     return errorResult("Email không được để trống");

  if (entity.address.trim() === "")
    return errorResult("Địa chỉ không được để trống");

  return {
    isValidate: true,
    message: "success",
  };
};

const validateUserAdmin = (entity) => {
  // function
  const errorResult = (message) => {
    return {
      isValidate: false,
      message,
    };
  };

  // validate
  if (entity.fullName === null || entity.fullName.trim() === "")
    return errorResult("Họ và tên không được để trống");

  if (entity.phone === null || entity.phone.trim() === "")
    return errorResult("SĐT không được để trống");

  // if (entity.email === null || entity.email === "")
  //     return errorResult("Email không được để trống");

  if (entity.address.trim() === "")
    return errorResult("Địa chỉ không được để trống");

  if (entity.groupId === null || entity.groupId === "")
    return errorResult("Nhóm người dùng không được để trống");

  if (entity.levelId === null || entity.levelId === "")
    return errorResult("Cấp độ người dùng không được để trống");

  return {
    isValidate: true,
    message: "success",
  };
};

export default {
  validate,
  validateUserAdmin,
};
