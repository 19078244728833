import createApiServices from './createApiServices'

const api = createApiServices()

export const getListProduct = (pageIndex = 1, pageSize = 8, params = []) => {
    let url = `products?pageIndex=${pageIndex}&pageSize=${pageSize}`

    for(let index = 0; index < params.length; index++){
        url = url + `&${params[index].name}=${params[index].value}`
    
    }
    return api.makeAuthRequest({
        url: url,
        method: 'GET',
    })
}

export const getDetailProduct = (id) => {
    return api.makeAuthRequest({
        url: `products/${id}`,
        method: 'GET',
    })
}

export const removeProduct = (id) => {
    return api.makeAuthRequest({
        url: `admin/managerProduct/${id}`,
        method: 'DELETE',
    })
}

export const updateProduct = (id = 0, product = {}) => {
    let headers = {
        "Content-Type": "application/json"
    }

    return api.makeAuthRequest({
        url: `admin/managerProduct/updateJson/${id}`,
        method: 'PUT',
        data: JSON.stringify(product),
        headers
    })
}

export const insertProduct = (product) => {
    let headers = {
        "Content-Type": "application/json"
    }

    return api.makeAuthRequest({
        url: `admin/managerProduct/createJson`,
        method: 'POST',
        data: JSON.stringify(product),
        headers
    })
}

export const getProductCurrentFlashSale = (pageSize = 13, pageIndex = 1) => {
    return api.makeAuthRequest({
        url: `flashSales/getProductsOnGoingFlashSales?pageSize=${pageSize}&pageIndex=${pageIndex}`,
        method: 'GET',    
    })
}

export const getRelateProducts = (id = 0, pageSize = 6, pageIndex = 1) => {
    return api.makeAuthRequest({
        url: `products/getRelatedProducts?pageIndex=${pageIndex}&pageSize=${pageSize}&filters[productId]=${id}`,
        method: 'GET'
    })
}

export const getListProductPerUnit = () => {
    return api.makeAuthRequest({
        url: `productPriceUnits`,
        method: 'GET'
    })
}

export const getListProductHot = (pageSize = 10, pageIndex = 1) => {
    return api.makeAuthRequest({
        url: `products/getHotProducts?pageIndex=${pageIndex}&pageSize=${pageSize}`,
        method: 'GET'
    })
}

export const getListProductBestSell = (pageSize = 10, pageIndex = 1) => {
    return api.makeAuthRequest({
        url: `products/getBestSellProduct?pageIndex=${pageIndex}&pageSize=${pageSize}`,
        method: 'GET'
    })
}

export const getPopularCategorys = (pageIndex = 1, pageSize = 12, quantityCategories = 3) => {
    return api.makeAuthRequest({
        url: `products/getMostCategories?pageIndex=${pageIndex}&pageSize=${pageSize}&filters[quantityCategories]=${quantityCategories}`,
        method: 'GET'
    })
}

export const searchProduct = (keyword) => {
    return api.makeAuthRequest({
        url: `products?keyword=${keyword}&sorts[name]=1&sorts[isCms]=1`,
        method: 'GET',
    })
}
