import { all, delay, fork, put, select, takeEvery } from "redux-saga/effects";
// const
import Router_Links from "../../../const/router_link";
// api
import {
  cancelOrder,
  GenPDFBill,
  GetBillPDFUrl,
  getDetailOrder,
  getListHistoryOrder,
} from "../../../services/api/order";
// helper
import NotifycationServices from "../../../services/utils/notifycationHelper";
import { history } from "../store";
import actions from "./actions";

function* saga_LoadListHistoryOrder(action) {
  try {
    let params = action.payload.params;

    let _state = yield select((state) => state.user_profile_history_order);
    let { pageSize, pageIndex } = _state;

    if (params.pageIndex) pageIndex = params.pageIndex;
    if (params.pageSize) pageSize = params.pageSize;

    let res = yield getListHistoryOrder(pageSize, pageIndex);
    let { items, total } = res.data;

    if (res.code === 200) {
      yield put(actions.action.loadListHistoryOrderSuccess(items));
      yield put(
        actions.action.updateState({
          total,
        })
      );
    }
  } catch (ex) {
    console.log(
      "[user_profile_history_order Saga] saga_LoadListHistoryOrder Error : ",
      ex.message
    );
  }
}

function* saga_UpdatePageIndex(action) {
  try {
    let pageIndex = action.payload.pageIndex;

    yield put(
      actions.action.loadListHistoryOrder({
        pageIndex,
      })
    );
  } catch (ex) {
    console.log(
      "[user_profile_history_order Saga] saga_UpdatePageIndex Error : ",
      ex.message
    );
  }
}

function* saga_LoadDetailOrder(action) {
  try {
    let id = action.payload.id;

    let req = yield getDetailOrder(id);
    if (req.code === 200) {
      let order = req.data;
      yield put(actions.action.loadDetailOrderSuccess(order));
    }
  } catch (ex) {
    console.log(
      "[user_profile_history_order Saga] saga_LoadDetailOrder Error : ",
      ex.message
    );
  }
}

function* saga_CancelOrder(action) {
  try {
    let id = action.payload.id;

    let req = yield cancelOrder(id);
    if (req.code === 200) {
      yield put(actions.action.loadListHistoryOrder());

      yield history.push(Router_Links.U_PROFILE_HISTORY_BILL);
      NotifycationServices.success("Huỷ đơn hàng thành công");
    } else {
      NotifycationServices.error("Huỷ đơn hàng thất bại");
    }
  } catch (ex) {
    console.log(
      "[user_profile_history_order Saga] saga_CancelOrder Error : ",
      ex.message
    );

    NotifycationServices.error("Huỷ đơn hàng thất bại");
  }
}

function* saga_GenBillPDF(action) {
  try {
    const id = action.payload.id;

    let req = yield GenPDFBill(id);
    if (req.code === 200) {
      let url = req.data;
      url = GetBillPDFUrl(url);

      NotifycationServices.success("Tạo hoá đơn thành công");

      yield delay(1000);
      window.open(url, "_blank");
    } else NotifycationServices.error("Tạo hoá đơn thất bại");
  } catch (ex) {
    console.log(
      "[user_profile_history_order Saga] saga_CancelOrder Error : ",
      ex.message
    );

    NotifycationServices.error("Tạo hoá đơn thất bại");
  }
}

function* listen() {
  yield takeEvery(
    actions.type.LOAD_LIST_HISTORY_ORDER,
    saga_LoadListHistoryOrder
  );
  yield takeEvery(actions.type.UPDATE_PAGE_INDEX, saga_UpdatePageIndex);
  yield takeEvery(actions.type.LOAD_DETAIL_ORDER, saga_LoadDetailOrder);
  yield takeEvery(actions.type.CANCEL_ORDER, saga_CancelOrder);
  yield takeEvery(actions.type.GEN_BILL_PDF, saga_GenBillPDF);
}

export default function* mainSaga() {
  yield all([fork(listen)]);
}
