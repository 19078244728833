import React, { Component } from "react";

// redux
import { connect } from "react-redux";
import StatisticalActions from "../../../../redux/user_profile_statistical/actions";

// component
import Pagination from "../../../../../components/paginations/paginations";

// helper
import { convertIsoDateToString, numberDisplayThousand } from "../../../../../services/utils/helper";

class URP_ListUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageIndex: 1,
            pageSize: 10,
            total: 30,
        };
    }

    render() {
        let users = this.props.users ? this.props.users : [];

        return (
            <div className="URP_ListUser">
                <table className="table-cds">
                    <thead>
                        <tr>
                            <th>STT</th>
                            <th className="text-left">Họ và tên</th>
                            <th className="text-left">Email</th>
                            <th>Ngày tham gia</th>
                            <th>Doanh thu</th>
                            <th>Hoa hồng</th>
                            <th>Cấp bậc</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user, index) => {
                            let pre =
                            this.props.pageSize * (this.props.pageIndex - 1);
                            return (
                                <tr>
                                    <td>{pre + index + 1}</td>
                                    <td className="text-left">{user.fullName}</td>
                                    <td className="text-left">{user.email}</td>
                                    <td>{convertIsoDateToString(
                                        new Date(user.createdAt)
                                    )}</td>
                                    <td>{numberDisplayThousand(user.revenue)}</td>
                                    <td>{numberDisplayThousand(user.commission)}</td>
                                    <td>{user.levelName}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>

                <div className="UHP_PaginateSection">
                    <Pagination
                        onChange={(index) => {
                            this.props.updatePageIndex(index);
                        }}
                        pageSize={this.props.pageSize}
                        pageIndex={this.props.pageIndex}
                        total={this.props.total}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    let _state = state.user_profile_statistical;
    return {
        users: _state.user_childs,
        pageIndex: _state.user_child_pageIndex,
        pageSize: _state.user_child_pageSize,
        total: _state.user_child_total,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updatePageIndex: (pageIndex) => {
            dispatch(
                StatisticalActions.action.updatePageIndexUserChild(pageIndex)
            );
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(URP_ListUser);
