import { Button, Input, Modal } from "antd";
import React, { PureComponent } from "react";
import { CompactPicker } from "react-color";
import { isEmpty } from "../../../../../../helpers/text.helper";
import {
  convertDateToDatetime,
  convertIsoDateToString,
} from "../../../../../../services/utils/helper";
import NotificationsService from "../../../../../../services/utils/notifycationHelper";
import { apiUpdateNoteOrder } from "../../service/list-order.service";

export default class PopupNoteOrder extends PureComponent {
  order = undefined;

  state = {
    visible: false,
    color: "black",
    pickerVisible: false,
  };

  onShow = (order) => {
    this.order = order;
    this.setState({ visible: true });
  };

  onHide = () => {
    this.order = undefined;
    this.setState({ color: "black", visible: false });
  };

  onChangeColor = (res) => {
    this.setState({ color: res.hex });
  };

  onYes = async () => {
    const { color } = this.state;

    const params = {
      orderId: this.order._id,
      color,
      note: this.refInput.state.value || "",
    };

    if (isEmpty(params.note)) {
      NotificationsService.error("Vui lòng nhập ghi chú");
      return;
    }

    const order = await apiUpdateNoteOrder(params);

    this.props.onUpdateNote && this.props.onUpdateNote(order);

    this.onHide();
  };

  render() {
    const { order } = this;
    if (!order) return null;
    console.log("order: ", order);
    const { color, visible, pickerVisible } = this.state;

    const notes = order.notes && order.notes.length > 0 ? order.notes : [];

    return (
      <Modal
        title={`Ghi chú đơn hàng ${order.orderCode || ""}`}
        centered
        visible={visible}
        onOk={this.onYes}
        onCancel={this.onHide}
        width={700}
      >
        {notes.map((e) => {
          return (
            <div style={{ marginBottom: 10 }}>
              {e.userInfo.fullName}
              {` - ${convertDateToDatetime(e.createdAt)}: `}
              <span style={{ color: e.color || "black", fontWeight: "600" }}>
                {e.note}
              </span>
            </div>
          );
        })}

        <Input
          placeholder="Nhập ghi chú"
          style={{ color, fontWeight: "600", fontSize: 15, marginBottom: 10 }}
          ref={(ref) => (this.refInput = ref)}
        />

        <Button
          type="primary"
          onClick={() => {
            this.setState({ pickerVisible: !pickerVisible });
          }}
        >
          Chọn màu sắc
        </Button>

        {pickerVisible && (
          <div style={{ position: "absolute" }}>
            <CompactPicker
              color={color}
              onChangeComplete={this.onChangeColor}
            />
          </div>
        )}
      </Modal>
    );
  }
}
