import React, { Component } from "react";

// component
import { DatePicker, Radio, Select } from "antd";
import moment from "moment";

// redux
import { connect } from "react-redux";
import AuthAction from "../../../../redux/auth/actions";

// scss
import "./index.scss";
import { FaCheckCircle, FaPlusCircle, FaSave, FaTimes, FaUpload } from "react-icons/fa";
import { getImageUrl } from "../../../../../services/api/upload";
import { convertIsoDateToDatetime } from "../../../../../services/utils/helper";
import { getRenderPropValue } from "antd/lib/_util/getRenderPropValue";

// component
import Modal from '../../../../../components/modal'

// api
import { RequestChangeCustomerGroupAPIServices } from '../../../../../services/api/admin_request_change_customer_group'
import { uploadImage } from '../../../../../services/api/upload'
import NotificationsService from "../../../../../services/utils/notifycationHelper";
import { regexNumber } from "../../../../../const/regex_consts";
import { MAX_LENGTH_PHONE } from "../../../../../const/phone_consts";
import { isValidFullName } from "../../../../../services/utils/validate/validateFullName";

class InfoPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            modalMakeRequestChangeCustomerGroup: false,

            // customer group request change info
            images: [
            ],
            images_temp: [],
            description: ""
        }
    }

    // lifecycle
    componentDidMount = () => {
        this.props.init();
    };

    // handle
    handleImageChange(e) {
        e.preventDefault();

        try {
            let file = e.target.files[0];
            let reader = new FileReader();

            reader.readAsDataURL(file);
            reader.onloadend = () => {
                this.props.updateCurrentUserInfo({
                    avatar: reader.result,
                    file,
                });
            };
        } catch { }
    }

    _handleModalCreateRequestChangeCustomerGroup_SelectImage = (e) => {
        e.preventDefault();

        try {
            let file = e.target.files[0];
            let reader = new FileReader();

            reader.readAsDataURL(file);
            
        
            reader.onloadend = () => {
                let images = this.state.images
                images.push(reader.result)
                let images_temp = this.state.images_temp 
                images_temp.push(file)

                this.setState({
                    images
                });
            };
        } catch { }
    }

    _handleCreateRequestChangeCustomerGroup = async () => {
        let images = this.state.images
        let images_temp = this.state.images_temp

        if (images.length === 0){
            NotificationsService.error("Chưa có ảnh nào được chọn")
            return
        }

        let description = this.state.description 
        if (description === ""){
            NotificationsService.error("Mô tả không được để trống")
            return
        }

        // call api 
        let object = {
            images: [],
            description
        }

        for(let index = 0; index < images_temp.length; index++){
            let image = images_temp[index]
            try {
                let req = await uploadImage(image)
                let path = req.path 
                object.images.push(path)
            }
            catch (ex){

            }
        }

        // call api 
        try {
            let req = await RequestChangeCustomerGroupAPIServices.CreateRequestChangeCustomerGroup(object)
            if (req.code === 200){
                NotificationsService.success("Gửi yêu cầu xác thực thành công")
            }
            else 
                NotificationsService.error("Gửi yêu cầu xác thực thất bại")
        }
        catch (ex){
            NotificationsService.error("Gửi yêu cầu xác thực thất bại")
        }

        this.setState({
            images: [],
            images_temp: [],
            modalMakeRequestChangeCustomerGroup: false,
            description: ""
        })
    }

    onChangePhoneNumber = (evt) => {
        const phone = evt.target.value;
        if (regexNumber.test(phone) && phone.length <= MAX_LENGTH_PHONE) {
            this.props.updateCurrentUserInfo({
                phone
            });
        }
    }

    onChangeFullName = (evt) => {
        const fullName = evt.target.value;
        if (isValidFullName(fullName) || fullName === '') {
            this.props.updateCurrentUserInfo({
                fullName
            });
        }
    }

    // render
    renderModalMakeRequestChangeCustomerGroup = () => {
        let images = this.state.images

        return (
            <div className="UModalRequestChangeCustomerGroup">
                <div className="UMRG_Title">
                    Xác thực nhóm khách hàng
                </div>
                <div className="UMRG_RowInfo">
                    <div className="UMRG_RowTitle">
                        Hình Ảnh
                    </div>
                    <div
                        className="UMRG_RowContent"
                    >
                        {
                            images.map((image, index) => {
                                return (
                                    <div
                                        style={{
                                            position: "relative"
                                        }}
                                    >
                                        <img
                                            className="UMRG_Image"
                                            src={getImageUrl(image)}
                                            key={index}
                                        />
                                        <FaTimes
                                            style={{
                                                color: 'red',
                                                position: 'absolute',
                                                top: 10,
                                                right: 10,
                                                cursor: "pointer"
                                            }}
                                            onClick={(e) => {
                                                let zimages = this.state.images
                                                let new_images = []
                                                
                                                for(let index = 0; index < zimages.length; index++)
                                                    if (zimages[index] !== image)
                                                        new_images.push(zimages[index])

                                                this.setState({
                                                    images: new_images
                                                })
                                            }}
                                        />
                                    </div>
                                )
                            })
                        }

                        <input
                            type="file"
                            name="thumb"
                            id="thumb"
                            style={{ display: "none" }}
                            onChange={(e) => this._handleModalCreateRequestChangeCustomerGroup_SelectImage(e)}
                        />

                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <label for="thumb">
                                <div className="UMRG_Button">
                                    <FaPlusCircle className="UMRG_Icon" />
                                Thêm ảnh
                            </div>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="UMRG_RowInfo">
                    <div className="UMRG_RowTitle">
                        Nội dung
                    </div>
                    <div
                        className="UMRG_RowContent"
                    >
                        <textarea
                            className="UMRG_TextInput"
                            value={this.state.description}
                            onChange={(e) => {
                                this.setState({
                                    description: e.target.value
                                })
                            }}
                        />
                    </div>
                </div>

                <div className="UMRG_Action">
                    <div className="UMRG_Button"
                        style={{
                            marginRight: 5
                        }}
                        onClick={() => {
                            this._handleCreateRequestChangeCustomerGroup()
                        }}
                    >
                        <FaCheckCircle className="UMRG_Icon" />
                        Gửi yêu cầu
                    </div>
                    <div
                        className="UMRG_Button"
                        style={{
                            backgroundColor: "#e2e2e2",
                            color: "black"
                        }}
                        onClick={() => {
                            this.setState({
                                modalMakeRequestChangeCustomerGroup: false
                            })
                        }}
                    >
                        <FaTimes className="UMRG_Icon" />
                        Hủy
                    </div>
                </div>
            </div>
        )
    }

    render() {
        // data
        let userInfo = this.props.userInfo
            ? this.props.userInfo
            : { fullName: "", avatar: "" };

        let listCustomerGroups = this.props.groups ? this.props.groups : [];

        // style
        const radioStyle = {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            fontSize: 13,
            lineHeight: 17,
            height: "30px",
            lineHeight: "30px",
            color: "black",
        };

        return (
            <div className="InfoPage">
                <div className="UIF_Title">Thông tin tài khoản</div>

                <div className="UIF_Row">
                    <div className="UIF_RowTitle">Ảnh đại diện</div>
                    <div className="UIF_InputPanel">
                        <div>
                            <input
                                type="file"
                                name="file"
                                id="file"
                                style={{ display: "none" }}
                                onChange={(e) => this.handleImageChange(e)}
                            />
                            <img
                                src={getImageUrl(userInfo.avatar)}
                                alt="avatar"
                                className="UIF_Avatar"
                            />
                            <label for="file">
                                <div className="UIF_ButtonUpload">
                                    <FaUpload className="UIF_Icon" />
                                    Chọn
                                </div>
                            </label>
                        </div>
                    </div>
                </div>

                <div className="UIF_Row">
                    <div className="UIF_RowTitle">Họ tên</div>
                    <div className="UIF_InputPanel">
                        <input
                            type="text"
                            className="UIF_Input"
                            value={userInfo.fullName}
                            onChange={this.onChangeFullName}
                        />
                    </div>
                </div>

                <div className="UIF_Row">
                    <div className="UIF_RowTitle">SĐT</div>
                    <div className="UIF_InputPanel">
                        <input
                            type="text"
                            className="UIF_Input"
                            value={userInfo.phone}
                            onChange={this.onChangePhoneNumber}
                        />
                    </div>
                </div>

                <div className="UIF_Row">
                    <div className="UIF_RowTitle">Email</div>
                    <div className="UIF_InputPanel">
                        <div style={{ color: "black", fontWeight: 600 }}>
                            {userInfo.email}
                        </div>
                    </div>
                </div>

                <div className="UIF_Row">
                    <div className="UIF_RowTitle">Giới tính</div>
                    <div className="UIF_InputPanel">
                        <Radio.Group
                            style={{
                                display: "flex",
                                flexDirection: "row",
                            }}
                            value={userInfo.sex}
                            onChange={(e) => {
                                this.props.updateCurrentUserInfo({
                                    sex: e.target.value,
                                });
                            }}
                        >
                            <Radio style={radioStyle} value={1}>
                                Nam
                            </Radio>

                            <Radio style={radioStyle} value={2}>
                                Nữ
                            </Radio>
                        </Radio.Group>
                    </div>
                </div>

                <div className="UIF_Row">
                    <div className="UIF_RowTitle">Ngày sinh</div>
                    <div className="UIF_InputPanel">
                        <DatePicker
                            allowClear={false}
                            format={"DD/MM/YYYY"}
                            value={moment(new Date(userInfo.dob), "YYYY-MM-DD h:mm:ss a")}
                            onChange={(date, dateString) => {
                                this.props.updateCurrentUserInfo({
                                    dob: date,
                                });
                            }}
                        />
                    </div>
                </div>

                <div className="UIF_Row">
                    <div className="UIF_RowTitle">Địa chỉ</div>
                    <div className="UIF_InputPanel">
                        <textarea
                            type="text"
                            className="UIF_Input"
                            value={userInfo.address}
                            onChange={(e) => {
                                this.props.updateCurrentUserInfo({
                                    address: e.target.value,
                                });
                            }}
                        />
                    </div>
                </div>

                <div className="UIF_Row">
                    <div className="UIF_RowTitle">Nhóm khách hàng</div>
                    <div className="UIF_InputPanel"
                        style={{
                            display: 'flex',
                            flexDirection: "column",
                            // jus: 'center'
                        }}
                    >
                        <Select
                            value={userInfo.groupCustomerId}
                            onChange={(value) => {
                                this.props.updateCurrentUserInfo({
                                    groupCustomerId: value,
                                });
                            }}
                            style={{
                                flex: 1,
                                marginRight: 5
                            }}
                        >
                            {listCustomerGroups.map((group, index) => {
                                return (
                                    <Select.Option value={group._id}>
                                        {group.name}
                                    </Select.Option>
                                );
                            })}
                        </Select>

                    </div>
                </div>

                <div className="UIF_Row">
                    <div className="UIF_RowTitle"></div>
                    <div
                        className="UIF_InputPanel"
                    >
                        {
                            userInfo.approveGroupCustomer
                                ?
                                <div>
                                    <FaCheckCircle
                                        style={{
                                            marginRight: 5,
                                            color: "green"
                                        }}
                                        className="_IconVerify"
                                    />
                                    Nhóm khách hàng đã được xác thực
                                </div>
                                :
                                <div
                                    style={{
                                        color: "red"
                                    }}
                                >
                                    <FaTimes
                                        style={{
                                            marginRight: 5,
                                            color: "red"
                                        }}
                                        className="_IconVerify"
                                    />
                                    Chưa xác thực nhóm khách hàng. Vui lòng {" "}
                                    <span
                                        className="UIP_VerifyButton"
                                        style={{
                                            cursor: "pointer",
                                            textDecoration: "underline"
                                        }}
                                        onClick={(e) => {
                                            this.setState({
                                                modalMakeRequestChangeCustomerGroup: true
                                            })
                                        }}
                                    >
                                        {`xác thực`}
                                    </span>
                                    {" "}
                                    để được hưởng những ưu đãi của nhóm khách hàng
                                </div>
                        }
                    </div>
                </div>

                <div className="UIF_Row">
                    <div className="UIF_RowTitle"></div>
                    <div className="UIF_InputPanel">
                        <div
                            className="UFI_Button"
                            onClick={(e) => {
                                this.props.saveCurrentUserInfo();
                            }}
                        >
                            <FaSave className="UIF_Icon" />
                            Cập nhật
                        </div>
                    </div>
                </div>

                {/* Modal */}
                <Modal
                    isOpen={this.state.modalMakeRequestChangeCustomerGroup}
                    setIsOpen={(value) => {
                        this.setState({
                            modalMakeRequestChangeCustomerGroup: value
                        })
                    }}
                    displayCloseIcon={false}
                >
                    {
                        this.renderModalMakeRequestChangeCustomerGroup()
                    }
                </Modal>

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.auth.current_user_info,
        groups: state.auth.groups
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        init: () => {
            dispatch(AuthAction.action.setCurrentUserInfo());
            dispatch(AuthAction.action.loadListCustomerGroup());
        },
        updateCurrentUserInfo: (info) => {
            dispatch(AuthAction.action.updateCurrentUserInfo(info));
        },
        saveCurrentUserInfo: () => {
            dispatch(AuthAction.action.saveCurrentUserInfo());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(InfoPage);
