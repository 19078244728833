import React, { Component } from "react";

// redux
import { connect } from "react-redux";

class ServicePanel extends Component {
    render() {
        return (
            <section className="service-section">
                <div className="col-md-6 col-xl-3">
                    <div className="service-widget">
                        <i className="service-icon icon-shipping"></i>
                        <div className="service-content">
                            <h3 className="service-title">
                                Vận chuyển và trả lại miễn phí
                            </h3>
                            {/* <p>Miễn phí giao hàng từ 200.000</p> */}
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-xl-3">
                    <div className="service-widget">
                        <i className="service-icon icon-money"></i>
                        <div className="service-content">
                            <h3 className="service-title">
                                Đảm bảo giá trị hoàn tiền
                            </h3>
                            {/* <p>Giá trị hoàn tiền 100%</p> */}
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-xl-3">
                    <div className="service-widget">
                        <i className="service-icon icon-support"></i>
                        <div className="service-content">
                            <h3 className="service-title">
                                Hỗ trợ trực tuyến 24/7
                            </h3>
                            <p></p>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-xl-3">
                    <div className="service-widget">
                        <i className="service-icon icon-secure-payment"></i>
                        <div className="service-content">
                            <h3 className="service-title">Thanh trực tuyến toán bảo mật</h3>
                            {/* <p>Thanh toán online với momo</p> */}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ServicePanel);
