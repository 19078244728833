const prefix = 'auth/'

const type = {
    LOGIN: prefix + 'login',
    LOGIN_ADMIN: prefix + 'login_admin',

    LOGOUT: prefix + 'logout',
    GET_USER_INFO: prefix + 'get_user_info',

    CHECK_SESSION: prefix + 'check_session',
    CHECK_SESSION_ADMIN: prefix + 'check_session_admin',

    UPDATE_STATE: prefix + 'update_state',
    UPDATE_USERINFO: prefix + "update",
    CHANGE_PASSWORD: prefix + "change_password",

    UPDATE_CURRENT_USER_INFO: prefix + 'update_current_user_info',
    SET_CURRENT_USER_INFO: prefix + 'set_current_user_info',
    SAVE_CURRENT_USER_INFO: prefix + 'save_current_user_info',

    LOAD_LIST_CUSTOMER_GROUP: prefix + 'load_list_customer_group',
    LOAD_LIST_CUSTOMER_GROUP_SUCCESS: prefix + 'load_list_customer_group_success',

    // modal change password admin
    SET_MODAL_CHANGE_PASSWORD_ADMIN: prefix + 'set_modal_change_password_admin',
}

const action = {
    login: (username, password) => {
        return {
            type: type.LOGIN,
            payload: {
                username,
                password,
            },
        }
    },
    loginAdmin: (username, password) => {
        return {
            type: type.LOGIN_ADMIN,
            payload: {
                username,
                password
            }
        }
    },

    logout: () => {
        return {
            type: type.LOGOUT,
            payload: {},
        }
    },
    getUserInfo: () => {
        return {
            type: type.GET_USER_INFO,
            payload: {},
        }
    },
    updateState: state => {
        return {
            type: type.UPDATE_STATE,
            payload: {
                state,
            },
        }
    },

    checkSession: () => {
        return {
            type: type.CHECK_SESSION,
            payload: {},
        }
    },
    checkSessionAdmin: () => {
        return {
            type: type.CHECK_SESSION_ADMIN,
            payload: {
            }
        }
    },

    updateUserInfo: (uid, body) => {
        return {
            type: type.UPDATE_USERINFO,
            payload: {
                uid, body
            }
        };
    },
    changePassword: (oldPassword, newPassword, confirmPassword) => {
        return {
            type: type.CHANGE_PASSWORD,
            payload: {
                oldPassword,
                newPassword,
                confirmPassword
            }
        };
    },

    updateCurrentUserInfo: userInfo => {
        return {
            type: type.UPDATE_CURRENT_USER_INFO,
            payload: {
                userInfo
            }
        }
    },
    setCurrentUserInfo: () => {
        return {
            type: type.SET_CURRENT_USER_INFO,
            payload: {
            }
        }
    },
    saveCurrentUserInfo: () => {
        return {
            type: type.SAVE_CURRENT_USER_INFO,
            payload: {

            }
        }
    },
    loadListCustomerGroup: () => {
        return {
            type: type.LOAD_LIST_CUSTOMER_GROUP,
            payload: {

            }
        }
    },
    loadListCustomerGroupSuccess: (groups = []) => {
        return {
            type: type.LOAD_LIST_CUSTOMER_GROUP_SUCCESS,
            payload: {
                groups
            }
        }
    },
    setModalChangePasswordAdmin: (state) => {
        return {
            type: type.SET_MODAL_CHANGE_PASSWORD_ADMIN,
            payload: {
                state
            }
        }
    }
}

export default {
    type,
    action,
}
