const prefix = 'admin_post/'

const type = {
    UPDATE_STATE: prefix + 'update_state',

    LOAD_LIST_CATEGORY_POST: prefix + 'load_list_category_post',
    LOAD_LIST_CATEGORY_POST_SUCCESS: prefix + 'load_list_category_post_success',

    // List post
    LOAD_LIST_POSTS: prefix + 'load_list_posts',
    LOAD_LIST_POSTS_SUCCESS: prefix + 'load_list_post_success',
    UPDATE_PAGE_INDEX: prefix + 'update_page_index',
    UPDATE_TEXT_SEARCH: prefix + 'update_text_searct',
    SEARCH_POST: prefix + 'search_post',

    // detail post
    LOAD_DETAIL_POST: prefix + 'load_detail_post',
    LOAD_DETAIL_POST_SUCCESS: prefix + 'load_detail_post_success',
    SET_DEFAULT_CURRENT_POST: prefix + 'set_default_current_post',
    UPDATE_CURRENT_POST: prefix + 'update_current_post',
    SET_CURRENT_POST: prefix + 'set_current_post',

    REMOVE_POST: prefix + 'remove_post',
    SAVE_CURRENT_POST: prefix + 'save_current_post',

    SET_CURRENT_CATEGORY_ID: prefix + 'set_current_category_id',

    // list post tags
    LOAD_LIST_POST_BY_TAGS: prefix + 'load_list_post_by_tags',
    LOAD_LIST_POST_BY_TAGS_SUCCESS: prefix + 'load_list_post_by_tags_success',
    SET_CURRENT_TAGS: prefix + 'set_current_tags'
}

const action = {
    updateState: state => {
        return {
            type: type.UPDATE_STATE,
            payload: {
                state
            }
        }
    },

    loadListCategoryPost: () => {
        return {
            type: type.LOAD_LIST_CATEGORY_POST,
            payload: {

            }
        }
    },
    loadListCategoryPostSuccess: categorys => {
        return {
            type: type.LOAD_LIST_CATEGORY_POST_SUCCESS,
            payload: {
                categorys
            }
        }
    },
    // list post 
    loadListPosts: (params = []) => {
        return {
            type: type.LOAD_LIST_POSTS,
            payload: {
                params
            }
        }
    },
    loadListPostsSuccess: (posts =[]) => {
        return {
            type: type.LOAD_LIST_POSTS_SUCCESS,
            payload: {
                posts
            }
        }
    },
    updatePageIndex: (pageIndex = 1) => {
        return {
            type: type.UPDATE_PAGE_INDEX,
            payload: {
                pageIndex
            }
        }
    },
    updateTextSearch: (textSearch = "") => {
        return {
            type: type.UPDATE_TEXT_SEARCH,
            payload: {
                textSearch
            }
        }
    },
    searchPost: () => {
        return {
            type: type.SEARCH_POST,
            payload: {

            }
        }
    },

    // detail post 
    loadDetailPost: (id = "") => {
        return {
            type: type.LOAD_DETAIL_POST,
            payload: {
                id
            }
        }
    },
    loadDetailPostSuccess: (post = {}) => {
        return {
            type: type.LOAD_DETAIL_POST_SUCCESS,
            payload: {
                post
            }
        }
    },
    setDefaultCurrentPost: () => {
        return {
            type: type.SET_DEFAULT_CURRENT_POST,
            payload: {

            }
        }
    },
    setCurrentPost: post => {
        return {
            type: type.SET_CURRENT_POST,
            payload: {
                post
            }
        }
    },
    updateCurrentPost: (info = {}) => {
        return {
            type: type.UPDATE_CURRENT_POST,
            payload: {
                info
            }
        }
    },
    
    removePost: (id = "") => {
        return {
            type: type.REMOVE_POST,
            payload: {
                id
            }
        }
    },
    saveCurrentPost: () => {
        return {
            type: type.SAVE_CURRENT_POST,
            payload: {
                
            }
        }
    },
    setCurrentCategoryId: (id) => {
        return {
            type: type.SET_CURRENT_CATEGORY_ID,
            payload: {
                id
            }
        }
    },
    loadListPostByTags: (params = {}) => {
        return {
            type: type.LOAD_LIST_POST_BY_TAGS,
            payload: {
                params
            }
        }
    },
    loadListPostByTagsSuccess: (posts) => {
        return {
            type: type.LOAD_LIST_POST_BY_TAGS_SUCCESS,
            payload: {
                posts
            }
        }
    },
    setCurrentTags: (tags) => {
        return {
            type: type.SET_CURRENT_TAGS,
            payload: {
                tags
            }
        }
    }
}

export default {
    type,
    action
}