const prefix = 'admin_product/'

const type = {
    UPDATE_STATE: prefix + 'update_state',
    LOAD_LIST_PRODUCT: prefix + 'load_list_product',
    LOAD_LIST_PRODUCT_SUCCESS: prefix + 'load_list_product_success',

    DELETE_PRODUCT: prefix + 'delete_product',

    UPDATE_PAGE_INDEX: prefix + 'update_page_index',
    UPDATE_PAGE_SIZE: prefix + 'update_page_size',
    UPDATE_TEXT_SEARCH: prefix + 'update_text_search',

    SEARCH_PRODUCT: prefix + 'search_product',
    UPDATE_SORT_MODE: prefix + 'update_sort_mode',

    // product detail
    LOAD_PRODUCT_DETAIL: prefix + 'load_product_detail',
    LOAD_PRODUCT_DETAIL_SUCCESS: prefix + 'load_product_detail_success',

    UPDATE_CURRENT_PRODUCT: prefix + 'update_current_product',
    SET_DEFAULT_CURRENT_PRODUCT: prefix + 'set_default_current_product',

    SAVE_CURRENT_PRODUCT: prefix + 'save_current_product',

    LOAD_LIST_PRODUCT_LIST_PER_UNIT: prefix + 'load_list_product_per_unit',
    LOAD_LIST_PRODUCT_LIST_PER_UNIT_SUCCESS: prefix + 'load_list_product_per_unit_success',

    // category 
    LOAD_LIST_CATEGORY: prefix + 'load_list_category',
    LOAD_LIST_CATEGORY_SUCCESS: prefix + 'load_list_category_success',

    SET_DISPLAY_PRODUCT_GRID_STATE: prefix + 'set_display_product_grid_state'
}

const action = {
    updateState: state => {
        return {
            type: type.UPDATE_STATE,
            payload: {
                state
            }
        }
    },

    loadListProduct: (params = {}) => {
        return {
            type: type.LOAD_LIST_PRODUCT,
            payload: {
                params
            }
        }
    },
    loadListProductSuccess: (products = []) => {
        return {
            type: type.LOAD_LIST_PRODUCT_SUCCESS,
            payload: {
                products
            }
        }
    },
    deleteProduct: id => {
        return {
            type: type.DELETE_PRODUCT,
            payload: {
                id
            }
        }
    },

    
    updateTextSearch: textSearch => {
        return {
            type: type.UPDATE_TEXT_SEARCH,
            payload: {
                textSearch
            }
        }
    },
    searchProduct : keyword => {
        return {
            type: type.SEARCH_PRODUCT,
            payload: {
                keyword
            }
        }
    },

    updatePageIndex: pageIndex => {
        return {
            type: type.UPDATE_PAGE_INDEX,
            payload: {
                pageIndex
            }
        }
    },
    updatePageSize: pageSize => {
        return {
            type: type.UPDATE_PAGE_SIZE,
            payload: {
                pageSize
            }
        }
    },
    updateSortMode: mode => {
        return {
            type: type.UPDATE_SORT_MODE,
            payload: {
                mode
            }
        }
    },

    // detail
    loadProductDetail: (id) => {
        return {
            type: type.LOAD_PRODUCT_DETAIL,
            payload: {
                id
            }
        }
    },
    loadProductDetailSuccess: product => {
        return {
            type: type.LOAD_PRODUCT_DETAIL_SUCCESS,
            payload: {
                product
            }
        }
    },

    updateCurrentProduct: product => {
        return {
            type: type.UPDATE_CURRENT_PRODUCT,
            payload: {
                product
            }
        }
    },
    setDefaultCurrentProduct: () => {
        return {
            type: type.SET_DEFAULT_CURRENT_PRODUCT,
            payload: {

            }
        }
    },
    saveCurrentProduct: () => {
        return {
            type: type.SAVE_CURRENT_PRODUCT,
            payload: {

            }
        }
    },

    // category 
    loadListCategory: () => {
        return {
            type: type.LOAD_LIST_CATEGORY,
            payload: {

            }
        }
    },
    loadListCategorySuccess: categories => {
        return {
            type: type.LOAD_LIST_CATEGORY_SUCCESS,
            payload: {
                categories
            }
        }
    },

    loadListProductListPerUnit: () => {
        return {
            type: type.LOAD_LIST_PRODUCT_LIST_PER_UNIT,
            payload: {

            }
        }
    },
    loadListProductListPerUnitSuccess: units => {
        return {
            type: type.LOAD_LIST_PRODUCT_LIST_PER_UNIT_SUCCESS,
            payload: {
                units
            }
        }
    },
    setDisplayProductGridState: (state) => {
        return {
            type: type.SET_DISPLAY_PRODUCT_GRID_STATE,
            payload: {
                state
            }
        }
    }
}

export default {
    type,
    action,
}
