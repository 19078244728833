import React, { Component } from 'react'
import { FaAngleLeft, FaPlusCircle, FaSearch } from 'react-icons/fa'
// scss
import './index.scss'

// redux
import { connect } from 'react-redux'
import AdminFlashSaleActions from '../../../../redux/admin_flash_sale/actions'

import Button from '../../../../../components/button'
import RowAddProduct from './row_add_product'
import Pagination from '../../../../../components/paginations/paginations'

const list_flexs = [5, 10, 15, 20, 20];

class AddProductFlashSalePage extends Component {
    componentDidMount() {
        this.props.init()
    }

    onClickButtonAdd = () => {
        this.props.doAddProductFlashSale()
    }

    searchProduct = () => {
        this.props.init()
    }

    render() {
        const { products, updatePageIndex, pageSize, pageIndex, total } = this.props
        return (
            <div className="AddProductFlashSalePage">
                <div className="d-flex APFSP_Actions">
                    <Button
                        className="APFSP_ButtonBack"
                        label="Quay lại"
                        icon={<FaAngleLeft style={{ marginRight: 4 }} />}
                        onClick={this.props.history.goBack}
                    />
                </div>
                <div className="d-flex APFSP_Actions justify-content-between">
                    <Button
                        className="APFSP_ButtonAdd"
                        label="Thêm sản phẩm"
                        icon={<FaPlusCircle style={{ marginRight: 4 }} />}
                        onClick={this.onClickButtonAdd}
                    />

                    <div className="FilterSearchAndSort">
                        <div className="APL_SearchBoxPanel">
                            <input
                                type="text"
                                placeholder="Tìm kiếm"
                                aria-label="Tim kiem"
                                name="keyword"
                                value={this.props.textSearch}
                                onChange={(e) => {
                                    this.props.updateTextSearch(e.target.value);
                                }}
                                className="APL_SearchBox"
                                onKeyPress={(event) => {
                                    if (event.key === "Enter") {
                                        this.searchProduct();
                                    }
                                }}
                            />
                            <FaSearch
                                className="APL_SearchBoxIcon"
                                onClick={this.searchProduct}
                            />
                        </div>
                    </div>
                </div>
                <table className="CDS_Table" style={{}}>
                    <tr className="CDS_TableRowHeader">
                        <th
                            className="CDS_HeaderText"
                            style={{
                                flex: list_flexs[0],
                                marginRight: 10,
                            }}
                        >

                        </th>
                        <th
                            className="CDS_HeaderText"
                            style={{
                                flex: list_flexs[1],
                                justifyContent: "left",
                            }}
                        >
                            Sản phẩm
                    </th>
                        <th
                            className="CDS_HeaderText"
                            style={{
                                flex: list_flexs[2],
                                justifyContent: "left",
                            }}
                        >
                            Tên
                    </th>
                        <th
                            className="CDS_HeaderText superMiniHide"
                            style={{ flex: list_flexs[3] }}
                        >
                            Số lượng
                    </th>
                        <th
                            className="CDS_HeaderText superMiniHide"
                            style={{ flex: list_flexs[4] }}
                        >
                            Giảm giá (%)
                    </th>
                    </tr>
                    {products.map((product, index) => (
                        <RowAddProduct 
                            key={product._id}
                            products={products}
                            product={product}
                            index={index}
                        />
                    ))}

                </table>

                <div className="APFSP_Pagination">
                    <Pagination
                        onChange={(index) => {
                            updatePageIndex(index);
                        }}
                        pageSize={pageSize}
                        pageIndex={pageIndex}
                        total={total}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const _state = state.admin_flash_sale
    return {
        products: _state.products,
        pageSize: _state.pageSize_AddProductPage,
        pageIndex: _state.pageIndex_AddProductPage,
        total: _state.total_AddProductPage
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        init: () => {
            dispatch(AdminFlashSaleActions.action.loadListProduct())
        },
        updatePageIndex: (pageIndex) => {
            dispatch(AdminFlashSaleActions.action.updatePageIndexAddProductPage(pageIndex))
        },
        doAddProductFlashSale: () => {
            dispatch(AdminFlashSaleActions.action.doAddProductFlashSale())
        },
        updateTextSearch: (textSearch) => {
            dispatch(AdminFlashSaleActions.action.updateTextSearchProduct(textSearch))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddProductFlashSalePage)