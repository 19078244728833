import React, { Component } from 'react';
import './index.scss';
// redux
import { connect } from 'react-redux';
import UserProfileVoucherActions from '../../../../redux/user_profile_voucher/actions'

// component
import Button from '../../../../../components/button';
import Pagination from '../../../../../components/paginations/paginations';
import { FaSearch } from 'react-icons/fa';
import { convertIsoDateToString, numberDisplayThousand } from '../../../../../services/utils/helper';
const fakeData = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
class Voucher extends Component {
    componentDidMount() {
        this.props.init();
    }
    render() {
        const { vouchers, pageSize, pageIndex, total, currentVoucher } = this.props
        return (
            <div className="user-voucher">
                {currentVoucher && <div>
                    <div className="user-voucher-header">Ví CadoXu</div>
                    <div className="container">
                        <div className="row user-voucher-row">
                            <div className="col-sm-3 user-voucher-coin text-center">
                                <div className="font-weight-bold">{currentVoucher.code}</div>
                                <div className="font-weight-bold">{numberDisplayThousand(currentVoucher.maximumCoins)} CadoXu</div>
                            </div>
                            <div className="col-sm-9 voucher-current-detail">
                                <div>Giảm giá {currentVoucher.discount}%</div>
                                <div>HSD: {convertIsoDateToString(currentVoucher.startTime)} - {convertIsoDateToString(currentVoucher.stopTime)}</div>
                                <div>{currentVoucher.description}</div>
                            </div>
                        </div>
                    </div>
                </div>}

                <div className="row">
                    <div className="col-sm-8 user-voucher-header">Danh mục mã khuyến mại</div>
                    <div className="col-sm-4">
                        <div className="search-area">
                            <input
                                type="text"
                                placeholder="Tìm kiếm"
                                aria-label="Tim kiem"
                                name="keyword"
                                // value={this.props.textSearch}
                                onChange={(e) => {
                                    this.props.updateTextSearch(e.target.value);
                                }}
                                className="voucher-search"
                                onKeyPress={(event) => {
                                    if (event.key === "Enter") {
                                        this.props.searchCustomerGroup(
                                            this.props.textSearch
                                        );
                                    }
                                }}
                            />
                            <FaSearch
                                className="voucher-search-icon"
                                onClick={() => {
                                    this.props.searchVoucher(
                                        this.props.textSearch
                                    );
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className="container">
                    {vouchers.map((voucher) => (
                        <div
                            key={voucher._id}
                            className="row user-voucher-row">
                            <div className="col-4 col-sm-2 user-voucher-coin">
                                <div className="font-weight-bold">{voucher.code}</div>
                            </div>
                            <div className="col-8 col-sm-8 align-self-center voucher-detail">
                                <div>Giảm giá {voucher.discount}%</div>
                                <div>HSD: {convertIsoDateToString(voucher.startTime)} - {convertIsoDateToString(voucher.stopTime)}</div>
                                <div>{voucher.description}</div>
                                <div>Trị giá: {numberDisplayThousand(voucher.maximumCoins)} CadoXu</div>
                            </div>
                            <div className="col-sm-2 align-self-center voucher-btn-area">
                                <Button
                                    className="voucher-btn-apply"
                                    label="Áp dụng"
                                    onClick={() => this.props.applyVoucher(voucher._id)}
                                />
                            </div>
                        </div>
                    ))}
                </div>
                <div>
                    <Pagination
                        onChange={(index) => {
                            this.props.updatePageIndex(index)
                        }}
                        pageSize={pageSize}
                        pageIndex={pageIndex}
                        total={total}
                    />
                </div>

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const _state = state.user_profile_voucher
    return {
        vouchers: _state.vouchers,
        pageSize: _state.pageSize,
        pageIndex: _state.pageIndex,
        total: _state.total,
        currentVoucher: _state.currentVoucher,
        textSearch: _state.textSearch
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        init: () => {
            dispatch(UserProfileVoucherActions.action.loadListVoucher())
            dispatch(UserProfileVoucherActions.action.loadCurrentVoucherApply())
        },
        updatePageIndex: () => {
            dispatch(UserProfileVoucherActions.action.updatePageIndex())
        },
        updateTextSearch: (textSearch) => {
            dispatch(UserProfileVoucherActions.action.updateTextSearch(textSearch))
        },
        searchVoucher: () => {
            dispatch(UserProfileVoucherActions.action.loadListVoucher())
        },
        applyVoucher: (id) => {
            dispatch(UserProfileVoucherActions.action.applyVoucher(id))
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Voucher);