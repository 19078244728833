import { all, fork, put, select, takeEvery } from "redux-saga/effects";
import Router_Links from "../../../const/router_link";
import { getListCategories } from "../../../services/api/category";
import {
  getDetailProduct,
  getListProduct,
  getListProductPerUnit,
  insertProduct,
  removeProduct,
  searchProduct,
  updateProduct,
} from "../../../services/api/product";
import { uploadImage } from "../../../services/api/upload";
import NotifycationServices from "../../../services/utils/notifycationHelper";
import validate_product from "../../../services/utils/validate/validate_product";
import { history } from "../store";
import actions from "./actions";
import { SORT_MODE_CONST } from "./reducer";

function* saga_LoadListProduct(action) {
  try {
    let params = action.payload.params;

    let _state = yield select((state) => state.admin_product);
    let { pageSize, pageIndex, textSearch, sortMode, isCms } = _state;

    if (params.pageIndex) pageIndex = params.pageIndex;
    if (params.pageSize) pageSize = params.pageSize;
    if (params.textSearch) textSearch = params.textSearch;
    if (params.sortMode) sortMode = params.sortMode;

    let params_request = [
      {
        name: "keyword",
        value: textSearch,
      },
    ];

    // sort mode
    switch (sortMode) {
      case SORT_MODE_CONST.SORT_ASC_NAME:
        params_request = [
          ...params_request,
          {
            name: "sorts[name]",
            value: 1,
          },
        ];
        break;

      case SORT_MODE_CONST.SORT_DESC_NAME:
        params_request = [
          ...params_request,
          {
            name: "sorts[name]",
            value: -1,
          },
        ];
        break;

      case SORT_MODE_CONST.SORT_ASC_PRICE:
        params_request = [
          ...params_request,
          {
            name: "sorts[price]",
            value: 1,
          },
        ];
        break;

      case SORT_MODE_CONST.SORT_DESC_PRICE:
        params_request = [
          ...params_request,
          {
            name: "sorts[price]",
            value: -1,
          },
        ];
        break;

      default:
        params_request = params_request;
    }

    if (isCms) {
      params_request = [
        ...params_request,
        {
          name: "sorts[isCms]",
          value: 1,
        },
      ];
    }

    // call api
    const res = yield getListProduct(pageIndex, pageSize, params_request);

    let { items, total } = res.data;

    if (res.code === 200) {
      yield put(actions.action.loadListProductSuccess(items));
      yield put(
        actions.action.updateState({
          total,
        })
      );
    }

    // NotifycationServices.success('Đăng nhập thành công', "Thông báo")
  } catch (ex) {
    console.log("[Admin View Saga] saga_LoadListProduct Error : ", ex.message);
  }
}

function* saga_UpdatePageIndex(action) {
  try {
    let pageIndex = action.payload.pageIndex;

    yield put(
      actions.action.loadListProduct({
        pageIndex,
      })
    );
  } catch (ex) {
    console.log("[Admin View Saga] saga_UpdatePageIndex Error : ", ex.message);
  }
}

function* saga_UpdatePageSize(action) {
  try {
    let pageSize = action.payload.pageSize;

    yield put(
      actions.action.loadListProduct({
        pageIndex: 1,
        pageSize,
      })
    );
  } catch (ex) {
    console.log("[Admin View Saga] saga_UpdatePageSize Error : ", ex.message);
  }
}

function* saga_DeleteProduct(action) {
  try {
    let id = action.payload.id;
    let res = yield removeProduct(id);

    if (res.code === 200) {
      yield put(actions.action.loadListProduct({}));

      NotifycationServices.success("Xoá sản phầm thành công", "Thông báo");
    } else {
      NotifycationServices.error("Xoá sản phầm thất bại", "Thông báo");
    }
  } catch (ex) {
    console.log("[Admin View Saga] saga_DeleteProduct : ", ex.message);
    NotifycationServices.error("Xoá sản phầm thất bại", "Thông báo");
  }
}

function* saga_UpdateSortMode(action) {
  try {
    let mode = yield action.payload.mode;

    yield put(
      actions.action.loadListProduct({
        sortMode: mode,
      })
    );
  } catch (ex) {
    console.log("[Admin View Saga] saga_UpdateSortMode : ", ex.message);
  }
}

function* saga_SearchProduct(action) {
  try {
    let textSearch = yield action.payload.keyword;

    const res = yield searchProduct(textSearch.trim());
    if (res.code === 200) {
      const { page, total } = res.data;
      const productList = res.data.items;
      yield put(
        actions.action.updateState({
          total,
          pageIndex: page,
        })
      );
      yield put(actions.action.loadListProductSuccess(productList));
    }
  } catch (ex) {
    console.log("[Admin View Saga] saga_SearchProduct : ", ex.message);
  }
}

// detail product
function* saga_LoadProductDetail(action) {
  try {
    let id = action.payload.id;
    let res = yield getDetailProduct(id);

    if (res.code === 200) {
      let product = res.data;
      yield put(actions.action.loadProductDetailSuccess(product));
    }
  } catch (ex) {
    console.log("[Admin View Saga] saga_LoadProductDetail : ", ex.message);
  }
}

function* saga_SaveCurrentProduct(action) {
  let product = yield select((state) => state.admin_product.product);

  try {
    // validate
    let validate_res = yield validate_product.validate(product);
    if (!validate_res.isValidate) {
      NotifycationServices.error(validate_res.message);
      return;
    }

    // upload images
    let images = [];
    for (let index = 0; index < product.images.length; index++) {
      if (product.images[index].file) {
        try {
          let reqq = yield uploadImage(product.images[index].file);
          if (reqq.path) images.push(reqq.path);
        } catch {}
      } else images.push(product.images[index].url);
    }
    product = {
      ...product,
      ...{
        images,
      },
    };

    if (product._id === 0) {
      // add product
      product = {
        ...product,
        ...{
          _id: null,
        },
      };
      let req = yield insertProduct(product);
      if (req.code === 200) {
        yield put(actions.action.loadListProduct());
        NotifycationServices.success("Thêm sản phẩm thành công");

        yield history.push(Router_Links.A_MANAGER_PRODUCT);
      } else {
        NotifycationServices.error("Thêm sản phẩm thất bại");
      }
    } else {
      // update product
      let req = yield updateProduct(product._id, product);

      if (req.code === 200) {
        yield put(actions.action.loadListProduct());
        NotifycationServices.success("Cập nhật sản phẩm thành công");

        yield history.push(Router_Links.A_MANAGER_PRODUCT);
      } else {
        NotifycationServices.error("Cập nhật sản phẩm thất bại");
      }
    }
  } catch (ex) {
    console.log(
      "[Admin View Saga] saga_SaveCurrentProduct error : ",
      ex.message
    );

    if (ex.message) {
      NotifycationServices.error(ex.message.message);
      return;
    }

    if (product._id === 0) NotifycationServices.error("Thêm sản phẩm thất bại");
    else NotifycationServices.error("Cập nhật thông tin sản phẩm thất bại");
  }
}

function* saga_LoadListProductListPerUnit(action) {
  try {
    let req = yield getListProductPerUnit();

    if (req.code === 200) {
      let units = req.data;

      yield put(actions.action.loadListProductListPerUnitSuccess(units));
    }
  } catch (ex) {
    console.log(
      "[Admin View Saga] saga_LoadListProductListPerUnit error : ",
      ex.message
    );
  }
}

// category
function* saga_LoadListCategory() {
  try {
    let req = yield getListCategories();

    if (req.code === 200) {
      yield put(actions.action.loadListCategorySuccess(req.data));
    }
  } catch (ex) {
    console.log("[Admin View Saga] loadListCategory error : ", ex.message);
  }
}

function* listen() {
  yield takeEvery(actions.type.LOAD_LIST_PRODUCT, saga_LoadListProduct);
  yield takeEvery(actions.type.UPDATE_PAGE_INDEX, saga_UpdatePageIndex);
  yield takeEvery(actions.type.UPDATE_PAGE_SIZE, saga_UpdatePageSize);
  yield takeEvery(actions.type.UPDATE_SORT_MODE, saga_UpdateSortMode);

  yield takeEvery(actions.type.DELETE_PRODUCT, saga_DeleteProduct);
  yield takeEvery(actions.type.SEARCH_PRODUCT, saga_SearchProduct);

  // detail product
  yield takeEvery(actions.type.LOAD_PRODUCT_DETAIL, saga_LoadProductDetail);
  yield takeEvery(actions.type.SAVE_CURRENT_PRODUCT, saga_SaveCurrentProduct);
  yield takeEvery(
    actions.type.LOAD_LIST_PRODUCT_LIST_PER_UNIT,
    saga_LoadListProductListPerUnit
  );

  // category
  yield takeEvery(actions.type.LOAD_LIST_CATEGORY, saga_LoadListCategory);
}

export default function* mainSaga() {
  yield all([fork(listen)]);
}
