import React, { Component } from "react";
// icon
import { FaCartPlus } from "react-icons/fa";
// redux
import { connect } from "react-redux";
// router
import Router_Links from "../../../../const/router_link";
// helper
import { getImageUrl } from "../../../../services/api/upload";
import { numberDisplayThousand } from "../../../../services/utils/helper";
import { history } from "../../../redux/store";
import UserGlobalActions from "../../../redux/user_global/actions";
// scss
import "./index.scss";

class ProductPanel extends Component {
  // render
  renderProduct = (product) => {
    let discount = product.discount;
    if (discount < 0) discount = 0;
    if (discount > 100) discount = 100;

    let point = product.point;

    let newPrice = (product.price / 100.0) * (100 - discount);

    return (
      <div
        className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-6"
        style={{
          paddingLeft: 0,
          paddingRight: 0,
        }}
      >
        <div
          onClick={() => {
            history.push(`${Router_Links.U_DETAIL_PRODUCT}/${product._id}`);
          }}
          className="APL_ProductItem"
        >
          <img
            className="APL_ProductImage"
            src={getImageUrl(product.thumbnail)}
            alt="thumbnail"
          />

          <div className="APL_ProductAction">
            <div
              onClick={(e) => {
                e.stopPropagation();
                this.props.addCartItem(product._id, 1);
              }}
              className="APL_ProductActionPanel"
            >
              <FaCartPlus className="APL_ProductActionIcon" />
            </div>
          </div>

          <div className="APL_ProductContent">
            <div className="APL_ProductName">{product.name}</div>
            <div className="APL_ProductPrice">
              {discount > 0 ? (
                <span>
                  <span className="APL_OldPrice">
                    {numberDisplayThousand(product.price)}đ
                  </span>
                  <span className="APL_NewPrice">
                    {numberDisplayThousand(newPrice)}đ/{" "}
                    {product.productUnit.description}
                  </span>
                </span>
              ) : (
                `${numberDisplayThousand(newPrice)}đ/ ${
                  product.productUnit.description
                }`
              )}
            </div>
          </div>

          {discount > 0 && (
            <div className="APL_ProdcutDiscount">{"-" + discount + "%"}</div>
          )}

          {point > 0 && (
            <div className="APL_ProdcutPoint">{"+" + point + "P"}</div>
          )}
        </div>
      </div>
    );
  };

  render() {
    let products = this.props.products ? this.props.products : [];

    return (
      <div className="UFP_ProductPanel">
        <div className="product-panel row">
          {products.map((product, index) => {
            return this.renderProduct(product);
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    products: state.user_filter_product.products,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addCartItem: (productId, quantity) => {
      dispatch(UserGlobalActions.action.addCartItem(productId, quantity));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductPanel);
