import actions from './actions'
import { all, takeEvery, fork, put, select } from "redux-saga/effects"

import { createFlashSale, getListFlashSaleDetail, getListFlashSale, removeFlashSale, removeProductFlashSale, updateProductFlashSale, addProductFlashSale, removeAllProductFlashSale, getListProductNotExistsFlashSale, updateFlashSale } from '../../../services/api/admin_flash_sale'
import ValidateFlashSale from '../../../services/utils/validate/validate_flash_sale'
import NotificationsService from '../../../services/utils/notifycationHelper'
import ValidateProductFlashSale from '../../../services/utils/validate/validate_product_flash_sale'
import { history } from '../store'

function* saga_LoadListFlashSale(action) {
    try {
        const params = action.payload.params
        const _state = yield select(state => state.admin_flash_sale)
        let { pageSize, pageIndex } = _state
        if (pageSize) pageSize = params.pageSize
        if (pageIndex) pageIndex = params.pageIndex

        let res = yield getListFlashSale(pageSize, pageIndex)
        if (res.code === 200) {
            let flashSales = res.data.items
            let total = res.data.total
            yield put(actions.action.loadListFlashSaleSuccess(flashSales))
            yield put(actions.action.updateState(total))
        }

    } catch (ex) {
        console.log(
            "[admin_flash_sale Saga] saga_LoadListFlashSale Error : ",
            ex.message
        );
    }
}

function* saga_UpdatePageIndex(action) {
    try {
        const pageIndex = action.payload.pageIndex
        yield put(actions.action.loadListFlashSale({ pageIndex }))
    } catch (ex) {
        console.log(
            "[admin_flash_sale Saga] saga_UpdatePageIndex Error : ",
            ex.message
        );
    }
}

function* saga_CreateNewFlashSale() {
    try {
        let _state = yield select(state => state.admin_flash_sale)
        const newFlashSale = _state.newFlashSale
        const validateRes = ValidateFlashSale.validate(newFlashSale)
        if (!validateRes.isValidate) {
            NotificationsService.error(validateRes.message)
            return
        }
        let res = yield createFlashSale(newFlashSale)
        if (res.code === 200) {
            yield put(actions.action.setModalAddFlashSaleState(false))
            yield put(actions.action.loadListFlashSale())
            NotificationsService.success("Thêm mới flash sale thành công")
        }
    } catch (ex) {
        console.log(
            "[admin_flash_sale Saga] saga_CreateNewFlashSale Error : ",
            ex.message
        );
        NotificationsService.error("Thêm mới flash sale thất bại")
    }
}

function* saga_RemoveFlashSale(action) {
    try {
        const id = action.payload.id
        let res = yield removeFlashSale(id)
        if (res.code === 200) {
            yield put(actions.action.loadListFlashSale())
            NotificationsService.success("Xóa flash sale thành công")
        }
    } catch (ex) {
        console.log(
            "[admin_flash_sale Saga] saga_RemoveFlashSale Error : ",
            ex.message
        );
        NotificationsService.error("Xóa flash sale thất bại")
    }
}

function* saga_LoadListFlashSaleDetail(action) {
    try {
        const id = action.payload.id
        let res = yield getListFlashSaleDetail(id)
        if (res.code === 200) {
            const data = res.data
            yield put(actions.action.loadListFlashSaleDetailSuccess(data))
        }
    } catch (ex) {
        console.log(
            "[admin_flash_sale Saga] saga_LoadFlashSaleDetail Error : ",
            ex.message
        );
    }
}

function* saga_RemoveProductFlashSale(action) {
    try {
        const id = action.payload.id
        const _state = yield select(state => state.admin_flash_sale)
        const res = yield removeProductFlashSale(id)
        if (res.code === 200) {
            yield put(actions.action.loadListFlashSaleDetail(_state.currentIdFlashSale))
            NotificationsService.success("Xóa sản phẩm thành công")
        }
    } catch (ex) {
        console.log(
            "[admin_flash_sale Saga] saga_RemoveProductFlashSale Error : ",
            ex.message
        );
        NotificationsService.error("Xóa sản phẩm thất bại")
    }
}

function* saga_UpdateProductFlashSale() {
    try {
        const _state = yield select(state => state.admin_flash_sale)
        const data = _state.dataUpdateProduct
        const validateRes = ValidateProductFlashSale.validate(data)
        if (!validateRes.isValidate) {
            NotificationsService.error(validateRes.message)
            return
        }
        const res = yield updateProductFlashSale(_state.currentProductId, data)
        if (res.code === 200) {
            NotificationsService.success("Cập nhật sản phẩm thành công")
            yield put(actions.action.setModalUpdateProductFlashSaleState(false))
            yield put(actions.action.loadListFlashSaleDetail(_state.currentIdFlashSale))
        }
    } catch (ex) {
        console.log(
            "[admin_flash_sale Saga] saga_UpdateProductFlashSale Error : ",
            ex.message
        );
        NotificationsService.error("Cập nhật sản phẩm thất bại")
    }
}

function* saga_LoadListProduct(action) {
    try {
        let params = action.payload.params;

        let _state = yield select((state) => state.admin_flash_sale);
        let pageIndex = _state.pageIndex_AddProductPage
        let pageSize = _state.pageSize_AddProductPage
        let textSearch = _state.textSearchProduct

        if (params.pageIndex) pageIndex = params.pageIndex;
        if (params.pageSize) pageSize = params.pageSize;
        if (params.textSearch) textSearch = params.textSearch;

        const res = yield getListProductNotExistsFlashSale(_state.currentIdFlashSale, pageSize, pageIndex, textSearch)
        if (res.code === 200) {
            const products = res.data.items
            const total = res.data.total
            yield put(actions.action.updateState(
                { total_AddProductPage: total }
            ))
            yield put(actions.action.loadListProductSuccess(products))
        }
    } catch (ex) {
        console.log(
            "[admin_flash_sale Saga] saga_LoadListProduct Error : ",
            ex.message
        );
    }
}

function* saga_UpdatePageIndexAddProductPage(action) {
    try {
        const pageIndex = action.payload.pageIndex
        yield put(actions.action.loadListProduct({ pageIndex }))
    } catch (ex) {
        console.log(
            "[admin_flash_sale Saga] saga_UpdatePageIndex Error : ",
            ex.message
        );
    }
}


function* saga_PushProductFlashSale(action) {
    try {
        const productId = action.payload.productId
        const _state = yield select(state => state.admin_flash_sale)
        let productFlashSales = _state.productFlashSales;
        const data = {
            productId: productId,
            totalQuantity: 0,
            discount: 0
        }
        productFlashSales.push(data)

        yield put(actions.action.updateArrayProductFlashSale(productFlashSales))
    } catch (ex) {
        console.log(
            "[admin_flash_sale Saga] saga_PushProductFlashSale Error : ",
            ex.message
        );
    }
}

function* saga_UpdateNewProductFlashSale(action) {
    try {
        const _state = yield select(state => state.admin_flash_sale)
        let productFlashSales = [..._state.productFlashSales];

        const productId = action.payload.productId
        const productFlashSale = action.payload.productFlashSale
        for (let i = 0; i < productFlashSales.length; i++) {
            if (productFlashSales[i].productId === productId) {
                let productFlashSaleData = {
                    ...productFlashSales[i],
                    ...productFlashSale
                }
                productFlashSales[i] = productFlashSaleData
                break
            }
        }

        yield put(actions.action.updateArrayProductFlashSale(productFlashSales))

    } catch (ex) {
        console.log(
            "[admin_flash_sale Saga] saga_UpdateNewProductFlashSale Error : ",
            ex.message
        );
    }
}

function* saga_RemoveProductFlashSaleOutArray(action) {
    try {
        const productId = action.payload.productId
        const _state = yield select(state => state.admin_flash_sale)
        let productFlashSales = [..._state.productFlashSales];
        for (let i = 0; i < productFlashSales.length; i++) {
            if (productFlashSales[i].productId === productId) {
                productFlashSales.splice(i, 1)
                break
            }
        }

        yield put(actions.action.updateArrayProductFlashSale(productFlashSales))
    } catch (ex) {
        console.log(
            "[admin_flash_sale Saga] saga_RemoveProductFlashSaleOutArray Error : ",
            ex.message
        );
    }
}

function* saga_DoAddProductFlashSale() {
    try {
        const _state = yield select(state => state.admin_flash_sale)
        const productFlashSales = _state.productFlashSales
        const currentIdFlashSale = _state.currentIdFlashSale

        if (Array.isArray(productFlashSales) && !productFlashSales.length) {
            NotificationsService.error("Chưa có sản phẩm nào được chọn")
            return;
        }

        const res = yield addProductFlashSale(currentIdFlashSale, {productFlashSales})
        if (res.code === 200) {
            NotificationsService.success("Thêm sản phẩm thành công")
            yield put(actions.action.updateArrayProductFlashSale([]))
            yield history.goBack()
        }
    } catch(ex) {
        console.log(
            "[admin_flash_sale Saga] saga_DoAddProductFlashSale Error : ",
            ex.message
        );
        NotificationsService.error("Thêm sản phẩm thất bại")
    }
}

function* saga_DoRemoveAllProductFlashSale(action) {
    try {
        const id = action.payload.id
        const res = yield removeAllProductFlashSale(id)
        if (res.code === 200) {
            yield put(actions.action.loadListFlashSaleDetail(id))
            NotificationsService.success("Xóa tất cả sản phẩm thành công")
        }
    } catch(ex) {
        console.log(
            "[admin_flash_sale Saga] saga_DoRemoveAllProductFlashSale Error : ",
            ex.message
        );
        NotificationsService.error("Xóa tất cả sản phẩm thất bại")
    }
}

function* saga_SaveCurrentFlashSale(action) {
    try {
        const _state = yield select(state => state.admin_flash_sale)
        const currentFlashSale = _state.currentFlashSale;
        const res = yield updateFlashSale(currentFlashSale._id, currentFlashSale);
        if (res.code === 200) {
            yield put(actions.action.loadListFlashSale())
            yield put(actions.action.setModalEditFlashSaleSate(false))
            NotificationsService.success("Cập nhật flash sale thành công")
        }
    } catch(ex) {
        console.log(
            "[admin_flash_sale Saga] saga_SaveCurrentFlashSale Error : ",
            ex.message
        );
        NotificationsService.error("Cập nhật flash sale thất bại")
    }
}

function* listen() {
    yield takeEvery(actions.type.LOAD_LIST_FLASH_SALE, saga_LoadListFlashSale)
    yield takeEvery(actions.type.UPDATE_PAGE_INDEX, saga_UpdatePageIndex)
    yield takeEvery(actions.type.CREATE_NEW_FLASH_SALE, saga_CreateNewFlashSale)
    yield takeEvery(actions.type.REMOVE_FLASH_SALE, saga_RemoveFlashSale)
    yield takeEvery(actions.type.LOAD_LIST_FLASH_SALE_DETAIL, saga_LoadListFlashSaleDetail)
    yield takeEvery(actions.type.REMOVE_PRODUCT_FLASH_SALE, saga_RemoveProductFlashSale)
    yield takeEvery(actions.type.DO_UPDATE_PRODUCT_FLASH_SALE, saga_UpdateProductFlashSale)
    yield takeEvery(actions.type.LOAD_LIST_PRODUCT, saga_LoadListProduct)
    yield takeEvery(actions.type.PUSH_PRODUCT_FLASH_SALE_TO_ARRAY, saga_PushProductFlashSale)
    yield takeEvery(actions.type.UPDATE_NEW_PRODUCT_FLASH_SALE, saga_UpdateNewProductFlashSale)
    yield takeEvery(actions.type.REMOVE_PRODUCT_FLASH_SALE_OUT_ARRAY, saga_RemoveProductFlashSaleOutArray)
    yield takeEvery(actions.type.UPDATE_PAGE_INDEX_ADD_PRODUCT_PAGE, saga_UpdatePageIndexAddProductPage)
    yield takeEvery(actions.type.DO_ADD_PRODUCT_FLASH_SALE, saga_DoAddProductFlashSale)
    yield takeEvery(actions.type.DO_REMOVE_ALL_PRODUCT_FLASH_SALE, saga_DoRemoveAllProductFlashSale)
    yield takeEvery(actions.type.SAVE_CURRENT_FLASH_SALE, saga_SaveCurrentFlashSale)
}

export default function* mainSaga() {
    yield all([fork(listen)]);
}