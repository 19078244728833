import createApiServices from './createApiServices'

const api = createApiServices()

const getListGroup = (pageSize = 10, pageIndex = 1, params = []) => {
    let url = `admin/managerGroup?pageSize=${pageSize}&pageIndex=${pageIndex}`;
    for (let index = 0; index < params.length; index++) {
        url = url + `&${params[index].name}=${params[index].value}`

    }
    return api.makeAuthRequest({
        url,
        method: 'GET'
    })
}

const getDetailGroup = groupId => {
    return api.makeAuthRequest({
        url: `admin/managerGroup/detail/${groupId}`,
        method: 'GET'
    })
}

const updateGroup = (id = "", group = {}) => {
    return api.makeAuthRequest({
        url: `admin/managerGroup/update/${id}`,
        method: 'PUT',
        data: group
    })
}

const createGroup = (group = {}) => {
    return api.makeAuthRequest({
        url: `admin/managerGroup/create`,
        method: 'POST',
        data: group
    })
}

const inActiveGroup = (id = "") => {
    return api.makeAuthRequest({
        url: `admin/managerGroup/inactive/${id}`,
        method: 'PUT',
    })
}

const activeGroup = (id = "") => {
    return api.makeAuthRequest({
        url: `admin/managerGroup/active/${id}`,
        method: 'PUT'
    })
}

export default {
    getListGroup,
    getDetailGroup,
    updateGroup,
    createGroup,
    inActiveGroup,
    activeGroup
}