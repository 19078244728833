import React, { Component } from "react";
// redux
import { connect } from "react-redux";
import ProductSkeleton from "../../../../components/loading/product_skeleton";
// component
import Pagination from "../../../../components/paginations/paginations";
import ListHelperServices from "../../../../services/utils/listHelper";
// helper
import UrlHelperServices from "../../../../services/utils/urlHelper";
import UserFilterProductActions from "../../../redux/user_filter_product/actions";
// sub component
import FilterPanel from "./filter_panel";
// scss
import "./index.scss";
import ProductPanel from "./product_panel";

class UserFilterProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 10,
      pageIndex: 2,
      total: 30,
    };
  }

  // life circle
  componentDidMount = async () => {
    this.props.init();

    // alert(nextProps.match.params.search)
    let nextProps = this.props;
    let params = UrlHelperServices.parseParams(nextProps.location.search);

    if (typeof params.search === "string") {
      this.props.updateState({
        filter_categorys: [],
        filter_units: [],
      });
      await this.props.updateTextSearch(params.search);
      await this.props.searchProduct();
      return;
    }

    if (typeof params.category === "string" && params.category) {
      let item = {
        _id: params.category,
      };

      if (
        !ListHelperServices.checkItemInArray(this.props.filter_categorys, item)
      ) {
        this.props.updateState({
          filter_categorys: [],
          filter_units: [],
          textSearch: "",
        });
        await this.props.addFilterCategory(item);
        this.props.searchProduct();
      }

      return;
    }

    this.props.loadListProduct();
  };

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this.props.searchProduct();
    }
  }

  renderSkeletonProductList = () => {
    const skelectonProductList = [];
    for (let i = 0; i < 16; i++) {
      skelectonProductList.push(<ProductSkeleton />);
    }
    return <div className="row">{skelectonProductList}</div>;
  };

  // render
  render() {
    let { pageSize, pageIndex, total, isLoadingProductList } = this.props;

    return (
      <div className="UserFilterProductPage">
        <div>
          <FilterPanel totalProduct={total} />
        </div>

        <div className="UFP_MainContent">
          {isLoadingProductList ? (
            this.renderSkeletonProductList()
          ) : (
            <ProductPanel />
          )}

          <div className="UFP_PaginateSection">
            <Pagination
              onChange={(index) => {
                window.scrollTo(0, 0);
                this.props.updatePageIndex(index);
              }}
              pageSize={pageSize}
              pageIndex={pageIndex}
              total={total}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const _state = state.user_filter_product;

  return {
    pageSize: _state.pageSize,
    pageIndex: _state.pageIndex,
    total: _state.total,

    filter_categorys: _state.filter_categorys,
    filter_units: _state.filter_units,
    isLoadingProductList: _state.isLoadingProductList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    init: () => {
      dispatch(UserFilterProductActions.action.loadListCategory());
      dispatch(UserFilterProductActions.action.loadListUnit());
    },
    loadListProduct: () => {
      dispatch(UserFilterProductActions.action.loadListProduct());
    },
    updatePageIndex: (pageIndex) => {
      dispatch(UserFilterProductActions.action.updatePageIndex(pageIndex));
    },
    updateTextSearch: (textSearch) => {
      dispatch(UserFilterProductActions.action.updateTextSearch(textSearch));
    },
    searchProduct: () => {
      dispatch(UserFilterProductActions.action.searchProduct());
    },
    updateState: (state) => {
      dispatch(UserFilterProductActions.action.updateState(state));
    },

    // filter
    addFilterCategory: (category) => {
      dispatch(UserFilterProductActions.action.addFilterCategory(category));
    },
    removeFilterCategory: (category) => {
      dispatch(UserFilterProductActions.action.removeFilterCategory(category));
    },
    addFilterUnit: (unit) => {
      dispatch(UserFilterProductActions.action.addFilterUnit(unit));
    },
    removeFilterUnit: (unit) => {
      dispatch(UserFilterProductActions.action.removeFilterUnit(unit));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserFilterProduct);
