const prefix = 'admin_view/'

const type = {
    CHANGE_SIDE_BAR_DISPLAY: prefix + 'change_size_bar_display',
    SET_SIDE_BAR_DISPLAY: prefix + 'set_side_bar_display'
}

const action = {
    changeSideBarDisplay: () => {
        return {
            type: type.CHANGE_SIDE_BAR_DISPLAY,
            payload: {}
        }
    },
    setSideBarDisplay: (status = false) => {
        return {
            type: type.SET_SIDE_BAR_DISPLAY,
            payload: {
                status
            }
        }
    }
}

export default {
    type,
    action,
}
