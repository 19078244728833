const router_links = {
  // user view
  U_HOME_PAGE: "",
  U_HOT_DISCOUNT: "/hot-discount",
  U_BEST_SELL: "/best-sell",

  U_AUTH: "/auth",
  U_AUTH_REGISTER: "/auth/register",
  U_AUTH_FOGOT_PASSWORD: "/auth/forget-password",
  U_AUTH_CONFIRM_RESET_PASSWORD: "/auth/reset-password",
  U_AUTH_LOGIN: "/auth/login",

  U_PAYMENT_SUCCESS: "/payment/success",
  U_PAYMENT_FAIL: "/payment/fail",

  U_PROFILE: "/user",
  U_PROFILE_INFO: "/user/info",
  U_PROFILE_CHANGE_PASSWORD: "/user/change-password",
  U_PROFILE_LINK_AFFILIATE: "/user/link-affiliate",
  U_PROFILE_ADDRESS_SHIPING: "/user/address-shipping",
  U_PROFILE_HISTORY_BILL: "/user/history-bill",
  U_PROFILE_DETAIL_BILL: "/user/detail-bill",
  U_PROFILE_REVENUE: "/user/revenue",
  U_PROFILE_HISTORY_PAYMENT: "/user/history-payment",
  U_PROFILE_LEVEL_USER: "/user/level_user",
  U_PROFILE_DETAIL_ADDRESS_SHIPPING: "/user/address-shipping-detail",
  U_PROFILE_CREATE_ADDRESS_SHIPPING: "/user/create-address-shipping",
  U_PROFILE_VOUCHER: "/user/voucher",
  U_PROFILE_HISTORY_VOUCHER: "/user/history-voucher",
  U_PROFILE_WARE_HOUSE_ORDER: "/user/ware-house-order",
  U_PROFILE_WARE_HOUSE_ORDER_DETAIL: "/user/ware-house-order-detail",
  // U_PROFILE_GET_MONEY: '/user/get-money',
  // u_PROFILE_RECHARGE: '/user/recharge',

  U_CART: "/cart",
  U_CART_DETAIL: "/cart/detail",

  U_CHECKOUT: "/checkout",
  U_CHECKOUT_SELECT_ADDRESS: "/checkout/select-address",
  U_CHECKOUT_PAYMENT: "/checkout/pay-ment",

  U_FILTER_PRODUCT: "/filter-product",
  U_DETAIL_PRODUCT: "/detail-product",
  U_FLASH_SALE: "/flash-sale",
  U_DETAIL_POST: "/detail-post",
  U_LIST_POST_TAGS: "/tags",

  // admin
  ADMIN: "/admin",
  A_LOGIN: "/admin/login",
  A_APP: "/admin/app",
  A_HOME_PAGE: "/admin/app/home-page",

  A_MANAGER_LIST_USER_GROUP: "/admin/app/list-group",

  A_MANAGER_NOTIFY_SYSTEM: "/admin/app/notify-system",

  A_MANAGER_PRODUCT: "/admin/app/product-list",
  A_DETAIL_PRODUCT: "/admin/app/detail-product",
  A_CREATE_PRODUCT: "/admin/app/create-product",

  A_LIST_USER: "/admin/app/list-users",
  A_DETAIL_USER: "/admin/app/list-users-detail",
  A_CREATE_USER: "/admin/app/create-user",
  A_CATEGORY: "/admin/app/category-list",

  A_LIST_BILL: "/admin/app/list-bill",
  A_LIST_ORDER: "/admin/app/list-order",
  A_LIST_ORDER_DETAIl: "/admin/app/order-detail",

  A_LIST_WARE_HOUSE: "/admin/app/warehouse",
  A_WARE_HOUSE_CREATE: "/admin/app/warehouse-create",
  A_WARE_HOUSE_INSERT_PRODUCT: "/admin/app/warehouse-insert-product",

  A_CUSTOMER_GROUP: "/admin/app/customer_group",
  A_DETAIL_CUSTOMER_GROUP: "/admin/app/detail_customer_group",
  A_LIST_COUPON: "/admin/app/list-coupon",

  A_CREATE_VOUCHER: "/admin/app/create-voucher",

  A_CATEGORY_POST: "/admin/app/category_post",
  A_LIST_POST: "/admin/app/list-post",
  A_DETAIL_POST: "/admin/app/detail-post",
  A_CREATE_POST: "/admin/app/create-post",

  A_LEVEL_USER: "/admin/app/level_user",
  A_REQUEST_CHANGE_CUSTOMER_GROUP: "/admin/app/request_change_customer_group",

  A_CREATE_ORDER: "/admin/app/create-order",

  A_LIST_FLASH_SALE: "/admin/app/flash-sale",
  A_FLASH_SALE_DETAIL: "/admin/app/flash-sale-detail",
  A_FLASH_SALE_DETAIL_ADD_PRODUCT: "/admin/app/flash-sale-add",
  A_LEVEL_RELATIONSHIP: "/admin/app/level-relationship",
  A_EDIT_POST: "/admin/app/edit/posts",
  A_LIST_POST_TAGS: "/admin/app/posts/tags",
};

export default router_links;
