import createApiServices from "./createApiServices";

const api = createApiServices();

export const getListUser = (pageSize = 8, pageIndex = 1, params = []) => {
  let url = `admin/managerUser?pageSize=${pageSize}&pageIndex=${pageIndex}`;

  for (let index = 0; index < params.length; index++) {
    url = url + `&${params[index].name}=${params[index].value}`;
  }

  return api.makeAuthRequest({
    url: url,
    method: "GET",
  });
};

export const getDetailUser = (id) => {
  return api.makeAuthRequest({
    url: `admin/managerUser/detail/${id}`,
    method: "GET",
  });
};

export const createUser = (user) => {
  let headers = {
    "Content-Type": "application/json",
  };

  return api.makeAuthRequest({
    url: `admin/managerUser`,
    method: "POST",
    data: JSON.stringify(user),
    headers,
  });
};
export const updateUser = (id = 0, user = {}) => {
  let headers = {
    "Content-Type": "application/json",
  };
  return api.makeAuthRequest({
    url: `admin/managerUser/${id}`,
    method: "PUT",
    data: JSON.stringify(user),
    headers,
  });
};

export const despositOnline = (amount = 0) => {
  return api.makeAuthRequest({
    url: `depositHistory/paymentDepositOnline`,
    method: "POST",
    data: {
      amount,
    },
  });
};

export const blockUser = (id = "") => {
  return api.makeAuthRequest({
    url: `admin/managerUser/deActive/${id}`,
    method: "PUT",
  });
};

export const activeUser = (id = "") => {
  return api.makeAuthRequest({
    url: `admin/managerUser/active/${id}`,
    method: "PUT",
  });
};
