import React, { Component } from "react";

// redux
import { connect } from "react-redux";
import AuthActions from "../../../../redux/auth/actions";

// icons
import { FaLock } from "react-icons/fa";

// scss
import "./index.scss";

// service
import NotifycationServices from "../../../../../services/utils/notifycationHelper";
import { validatePassword } from "../../../../../services/utils/validate/validate_password";
import { MIN_PASSWORD } from "../../../../../const/password_consts";

class UChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
            errorNewPassword: {
                error: false,
                message: "" 
            },
            errorConfirmPassword: {
                error: false,
                message: ""
            }
        };
    }

    onChangeOldPassword = (evt) => {
        const oldPassword = evt.target.value;
        const isValidated = validatePassword(oldPassword);
        if (isValidated) {
            this.setState({
                oldPassword
            });
        }
    }

    onChangeNewPassword = (evt) => {
        const newPassword = evt.target.value;
        const isValidated = validatePassword(newPassword);
        if (isValidated) {
            this.setState({
                newPassword
            });
        }
    }

    onChangeConfirmPassword = (evt) => {
        const confirmPassword = evt.target.value;
        const isValidated = validatePassword(confirmPassword);
        if (isValidated) {
            this.setState({
                confirmPassword
            });
        }
    }

    onBlurNewPassword = (evt) => {
        const {newPassword, oldPassword} = this.state;
        if (newPassword.length < MIN_PASSWORD) {
            this.setState({
                errorNewPassword: {
                    error: true,
                    message: "Mật khẩu phải có ít nhất 8 ký tự"
                }
            });
            return;
        }
        if (newPassword === oldPassword) {
            this.setState({
                errorNewPassword: {
                    error: true,
                    message: "Mật khẩu mới trùng mật khẩu cũ"
                }
            })
            return;
        } else { 
            this.setState({
                errorNewPassword: {
                    error: false,
                    message: ""
                }
            })
            return;
        }
    }

    onBlurConfirmPassword = () => {
        const { newPassword, confirmPassword } = this.state;
        if (newPassword !== confirmPassword) {
            this.setState({
                errorConfirmPassword: {
                    error: true,
                    message: "Mật khẩu không khớp"
                }
            })
            return;
        } else { 
            this.setState({
                errorConfirmPassword: {
                    error: false,
                    message: ""
                }
            })
            return;
        }
    }

    onChangePassword = () => {
        const { oldPassword, newPassword, confirmPassword, errorNewPassword, errorConfirmPassword } = this.state;
        if (errorNewPassword.error || errorConfirmPassword.error) {
            return;
        }
        this.props.changePassword(
            oldPassword,
            newPassword,
            confirmPassword
        );
    }

    render() {
        const { errorNewPassword, errorConfirmPassword } = this.state;
        let buttonClasses = ['UCP_Button'];
        if (errorNewPassword.error || errorConfirmPassword.error) {
            buttonClasses.push('disable-button-change');
        }
        return (
            <div className="UChangePassword">
                <div className="UCP_Row">
                    <div className="UCP_Title">Mật khẩu cũ</div>
                    <div className="UCP_InputPanel">
                        <input
                            type="password"
                            className="UCP_Text"
                            value={this.state.oldPassword}
                            onChange={this.onChangeOldPassword}
                        />
                    </div>
                </div>

                <div className="UCP_Row">
                    <div className="UCP_Title">Mật khẩu mới</div>
                    <div className="UCP_InputPanel">
                        <div className="inputPanel-group">
                            <input
                            type="password"
                            className="UCP_Text"
                            value={this.state.newPassword}
                            onChange={this.onChangeNewPassword}
                            onBlur={this.onBlurNewPassword}
                        />
                        {errorNewPassword.error && <div className="error-input">{errorNewPassword.message}</div>}
                        </div>
                    </div>
                </div>

                <div className="UCP_Row">
                    <div className="UCP_Title">Xác nhận mật khẩu</div>
                    <div className="UCP_InputPanel">
                        <div className="inputPanel-group">
                            <input
                                type="password"
                                className="UCP_Text"
                                value={this.state.confirmPassword}
                                onChange={this.onChangeConfirmPassword}
                                onBlur={this.onBlurConfirmPassword}
                            />
                            {errorConfirmPassword.error && <div className="error-input">{errorConfirmPassword.message}</div>}
                        </div>
                    </div>
                </div>

                <div className="UCP_Row">
                    <div className="UCP_Title"></div>
                    <div className="UCP_InputPanel">
                        <div
                            className={buttonClasses.join(' ')}
                            onClick={this.onChangePassword}
                        >
                            <FaLock className="UCP_Icon" />
                            Đổi mật khẩu
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        changePassword: (oldPassword, newPassword, confirmPassword) => {
            dispatch(
                AuthActions.action.changePassword(
                    oldPassword,
                    newPassword,
                    confirmPassword
                )
            );
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UChangePassword);
