import { regexPassword } from "../../../const/regex_password"

import { MAX_PASSWORD } from '../../../const/password_consts'

export const validatePassword = (password) => {
    if (password.length > MAX_PASSWORD) {
        return false
    }

    return !regexPassword.test(password)
}