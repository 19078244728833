import { format } from "date-fns";

import moment from "moment";

const DEFAULT_DATE_FORMAT = "YYYY-MM-DDTHH:mm:ss.SSSZ";

export const formatToYMDHM = (x) => {
  return moment(x, DEFAULT_DATE_FORMAT).format("HH:mm YYYY-MM-DD");
};

export const convertIsoDateToDatetime = (x) => {
  return format(new Date(x), "yyyy-MM-dd hh:mm:ss");
};

export const convertIsoDateToString = (x) => {
  return format(new Date(x), "dd/MM/yyyy");
};

export const numberDisplayThousand = (x) => {
  if (x === null) return "";

  let y = parseInt(x);
  if (x - y < 0.01) x = x.toFixed(0);
  // else x = x.toFixed(2);
  else x = x.toFixed(0);

  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const dateFormat = (x) => {
  return x
    .toString()
    .replace(/([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}/);
};

export const convertCategoryDataToTreeViewData = (items = []) => {
  let ans = [];

  if (Array.isArray(items))
    for (let index = 0; index < items.length; index++) {
      let item = items[index];

      let new_item = {
        title: item.description,
        key: item._id,
        status: item.status,
        icon: null,
        children: convertCategoryDataToTreeViewData(item.children),
        indexNumber: item.indexNumber,
      };

      ans.push(new_item);
    }

  return ans;
};

export const convertListObjectToListIds = (objects) => {
  let ans = [];

  if (!Array.isArray(objects)) return [];

  for (let index = 0; index < objects.length; index++)
    ans.push(objects[index]._id);

  return ans;
};

export const convertDateToDatetime = (x, formatType) => {
  return format(new Date(x), formatType || "yyyy-MM-dd HH:mm:ss");
};

export const dateFormatHMDYM = (date) => {
  return moment(date).format("HH:mm:ss DD/MM/YYYY");
};
