import actions from './actions'

const defaultNewFlashSale = {
    name: "",
    description: "",
    startTime: new Date(),
    stopTime: new Date()
}

const defaultCurrentFlashSale = {
    name: "",
    description: "",
    startTime: new Date(),
    stopTime: new Date()
}

const defaultUpdateProductData = {
    discount: 0,
    totalQuantity: 0
}

const initState = {
    flashSales: [],
    total: 0,
    pageIndex: 1,
    pageSize: 20,

    modalAddFlashSaleState: false,
    newFlashSale: defaultNewFlashSale,
    // flashSale detail
    flashSalesDetail: [],
    currentIdFlashSale: "",
    mdUpdateProductFlashSaleState: false,
    currentProductName: "",
    currentProductId: "",
    dataUpdateProduct: defaultUpdateProductData,

    // add product page
    products: [],
    productFlashSales: [],

    total_AddProductPage: 0,
    pageIndex_AddProductPage: 1,
    pageSize_AddProductPage: 20,
    textSearchProduct: '',
    // edit flash sale 
    modalEditFlashSaleState: false,
    currentFlashSale: defaultCurrentFlashSale
}

const reducer = (state = initState, action) => {
    let payload = action.payload

    switch (action.type) {
        case actions.type.UPDATE_STATE:
            return {
                ...state,
                ...payload.state
            }
        case actions.type.LOAD_LIST_FLASH_SALE:
            return state
        case actions.type.LOAD_LIST_FLASH_SALE_SUCCESS:
            return {
                ...state,
                ...{
                    flashSales: payload.flashSales
                }
            }
        case actions.type.UPDATE_PAGE_INDEX:
            return {
                ...state,
                ...{
                    pageIndex: payload.pageIndex
                }
            }
        case actions.type.SET_MODAL_ADD_FLASH_SALE_STATE:
            return {
                ...state,
                ...{
                    modalAddFlashSaleState: payload.state
                }
            }
        case actions.type.UPDATE_NEW_FLASH_SALE:
            return {
                ...state,
                ...{
                    newFlashSale: {
                        ...state.newFlashSale,
                        ...payload.newFlashSale
                    }
                }
            }
        case actions.type.CREATE_NEW_FLASH_SALE:
            return state
        case actions.type.REMOVE_FLASH_SALE:
            return state
        case actions.type.LOAD_LIST_FLASH_SALE_DETAIL:
            return {
                ...state,
                ...{
                    currentIdFlashSale: payload.id
                }
            }
        case actions.type.LOAD_LIST_FLASH_SALE_DETAIL_SUCCESS:
            return {
                ...state,
                ...{
                    flashSalesDetail: payload.flashSalesDetail
                }
            }
        case actions.type.REMOVE_PRODUCT_FLASH_SALE:
            return state
        case actions.type.SET_MODAL_UPDATE_PRODUCT_FLASH_SALE_STATE:
            return {
                ...state,
                ...{
                    mdUpdateProductFlashSaleState: payload.state,
                    currentProductName: payload.name,
                    currentProductId: payload.id,
                    dataUpdateProduct: {
                        ...state.dataUpdateProduct,
                        ...payload.currentProduct
                    }
                }
            }
        case actions.type.UPDATE_DATA_PRODUCT_FLASH_SALE:
            return {
                ...state,
                ...{
                    dataUpdateProduct: {
                        ...state.dataUpdateProduct,
                        ...payload.data
                    }
                }
            }
        case actions.type.DO_UPDATE_PRODUCT_FLASH_SALE:
            return state
        case actions.type.LOAD_LIST_PRODUCT:
            return state
        case actions.type.LOAD_LIST_PRODUCT_SUCCESS:
            return {
                ...state,
                ...{
                    products: payload.products
                }
            }
        case actions.type.PUSH_PRODUCT_FLASH_SALE_TO_ARRAY:
            return state
        case actions.type.UPDATE_NEW_PRODUCT_FLASH_SALE:
            return state
        case actions.type.REMOVE_PRODUCT_FLASH_SALE_OUT_ARRAY:
            return state
        case actions.type.UPDATE_ARRAY_PRODUCT_FLASH_SALE:
            return {
                ...state,
                ...{
                    productFlashSales: payload.productFlashSales
                }
            }
        case actions.type.UPDATE_PAGE_INDEX_ADD_PRODUCT_PAGE:
            return {
                ...state,
                ...{
                    pageIndex_AddProductPage: payload.pageIndex
                }
            }
        case actions.type.DO_ADD_PRODUCT_FLASH_SALE:
            return state
        case actions.type.DO_REMOVE_ALL_PRODUCT_FLASH_SALE:
            return state
        case actions.type.UPDATE_TEXT_SEARCH_PRODUCT:
            return {
                ...state,
                ...{
                    textSearchProduct: payload.textSearchProduct
                }
            }
        case actions.type.SET_MD_EDIT_FLASH_SALE_STATE:
            return {
                ...state,
                ...{
                    modalEditFlashSaleState: payload.state
                }
            }
        case actions.type.SET_CURRENT_FLASH_SALE:
            return {
                ...state,
                ...{
                    currentFlashSale: {
                        ...state.currentFlashSale,
                        ...payload.flashSale
                    }
                }
            }
        case actions.type.UPDATE_CURRENT_FLASH_SALE:
            return {
                ...state,
                ...{
                    currentFlashSale: {
                        ...state.currentFlashSale,
                        ...payload.flashSale
                    }
                }
            }
        case actions.type.SAVE_CURRENT_FLASH_SALE:
            return state
        default:
            return state
    }
}

export default reducer