import moment from "moment";
import React from "react";
import Modal from "../../../../components/modal";
import "./index.scss";
import { apiGetDiscountVoucher, apiGetVouchers } from "./service";

function formatDMYHS(date) {
  return moment(date).format("DD-MM-YYYY HH:mm");
}

export default class PopupSelectVoucher extends React.PureComponent {
  state = {
    vouchers: [],
    isShow: false,
  };

  productIds = [];

  onShow = ({ productIds }) => {
    this.productIds = productIds;
    this.setState({ isShow: true });
    this.onGetVoucher(productIds);
  };

  onGetVoucher = async (inProductIds) => {
    const vouchers = await apiGetVouchers({
      limit: 100,
      page: 1,
      inProductIds,
    });

    this.setState({ vouchers });
  };

  onSelectVoucher = (item) => async () => {
    const discountPrice = await apiGetDiscountVoucher(
      item.code,
      this.productIds
    );

    this.props?.onSelect?.({
      ...item,
      discountPrice,
    });

    this.setState({ isShow: false });
  };

  render() {
    const { vouchers, isShow } = this.state;

    return (
      <Modal
        isOpen={isShow}
        setIsOpen={(value) => {
          this.setState({
            isShow: value,
          });
        }}
        displayCloseIcon={false}
      >
        <div
          className="UCP_ModalSelectAddress"
          style={{ padding: 0, width: 300 }}
        >
          {vouchers.map((item, index) => {
            return (
              <div className="USA_Address" onClick={this.onSelectVoucher(item)}>
                <div className="USA_Address_Name">Mã: {item.code}</div>

                <div className="USA_Address_RowInfo">
                  <span className="USA_Address_Label">{item.description}</span>
                </div>
                <div className="USA_Address_RowInfo">
                  <span className="USA_Address_Label">Giảm giá:</span>
                  {`${item.discount}%`}
                </div>
                <div className="USA_Address_RowInfo">
                  <span className="USA_Address_Label">Thời gian diễn ra</span>
                </div>

                <div className="USA_Address_RowInfo">
                  {`${formatDMYHS(item.startDate)} - ${formatDMYHS(
                    item.endDate
                  )}`}
                </div>

                <div className="USA_Address_Action">
                  <div
                    onClick={this.onSelectVoucher(item)}
                    className="USA_Action_Edit"
                  >
                    Áp dụng
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </Modal>
    );
  }
}
