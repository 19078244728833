import actions from './actions'

const defaultNewLevelUser = {
    name : '',
    description : '',
    minimumRevenue : '',
    percentDiscountPerOrder: '',
    minimumDeposit : '',
    minimumChildren : '',
    levelBefore: '',
    childrenType : ''
}

const defaultCurrentLevelUser = {
    name : '',
    description : '',
    minimumRevenue : '',
    percentDiscountPerOrder: '',
    minimumDeposit : '',
    minimumChildren : '',
    levelBefore: '',
    childrenType : ''
}

const initState = {
    levelUsers: [],
    // modal add level user
    mdAddLevelUserState: false,
    newLevelUser: defaultNewLevelUser,
    // modal edit level user
    mdEditLevelUserState: false,
    currentLevelUser: defaultCurrentLevelUser,
    currentLevelUserId: ""
}

const reducer = (state = initState, action) => {
    const payload = action.payload
    switch(action.type) {
        case actions.type.LOAD_LIST_LEVEL_USER:
            return state
        case actions.type.LOAD_LIST_LEVEL_USER_SUCCESS:
            return {
                ...state,
                ...{
                    levelUsers: payload.levelUsers
                }
            }
        case actions.type.REMOVE_LEVEL_USER:
            return state
        case actions.type.SET_MODAL_ADD_LEVEL_USER_STATE:
            return {
                ...state,
                ...{
                    mdAddLevelUserState: payload.state,
                    newLevelUser: defaultNewLevelUser
                }
            }
        case actions.type.UPDATE_NEW_LEVEL_USER:
            return {
                ...state,
                ...{
                    newLevelUser: {
                        ...state.newLevelUser,
                        ...payload.newLevelUser
                    }
                }
            }
        case actions.type.ADD_NEW_LEVEL_USER:
            return state
        case actions.type.SET_MODAL_EDIT_LEVEL_USER_STATE:
            return {
                ...state,
                ...{
                    mdEditLevelUserState: payload.state,
                    currentLevelUserId: payload.id
                }
            }
        case actions.type.UPDATE_CURRENT_LEVEL_USER:
            return {
                ...state,
                ...{
                    currentLevelUser: {
                        ...state.currentLevelUser,
                        ...payload.currentLevelUser
                    }
                }
            }
        case actions.type.SAVE_CURRENT_LEVEL_USER:
            return state
        case actions.type.LOAD_DETAIL_LEVEL_USER:
            return state
        case actions.type.LOAD_DETAIL_LEVEL_USER_SUCCESS:
            return {
                ...state,
                ...{
                    currentLevelUser: payload.levelUser
                }
            }
        default:
            return state
    }
}

export default reducer