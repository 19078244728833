import React, { Component } from "react";

// redux
import { connect } from "react-redux";

// charts
import { Bar } from "react-chartjs-2";

const data = {
    labels: [
        "Tháng 2",
        "Tháng 3",
        "Tháng 4",
        "Tháng 5",
        "Tháng 6",
        "Tháng 7",
        "Tháng 8",
    ],
    datasets: [
        {
            type: "line",
            fill: false,
            label: "Thành viên hoạt động",
            backgroundColor: "orange",
            borderColor: "orange",
            borderWidth: 1,
            hoverBackgroundColor: "orange",
            hoverBorderColor: "white",
            data: [2, 2, 2, 2, 3, 3, 4],
            yAxisID: "y-axis-1",
        },
        {
            type: "bar",
            label: "Doanh thu",
            backgroundColor: "#3399ff",
            borderColor: "white",
            borderWidth: 0,
            hoverBackgroundColor: "#3399ff",
            hoverBorderColor: "white",
            data: [20, 16, 18, 23, 27, 10, 12],
            yAxisID: "y-axis-2",
        },
        {
            type: "bar",
            label: "Ký quỹ",
            backgroundColor: "#9999ff",
            borderColor: "white",
            borderWidth: 0,
            hoverBackgroundColor: "#9999ff",
            hoverBorderColor: "white",
            data: [10.5, 10.3, 15, 17, 16, 12, 10],
            yAxisID: "y-axis-2",
        },
    ],
};

class RevenueChart extends Component {
    _getData = () => {
        let statistical_data = this.props.statistical_data
            ? this.props.statistical_data
            : null;

        if (statistical_data === null) {
            return {
                labels: [],
                datasets: [
                ],
            };
        }

        let datasets = statistical_data.datasets
            ? statistical_data.datasets
            : [];
        if (Array.isArray(datasets))
            for (let index = 0; index < datasets.length; index++) {
                datasets[index] = {
                    ...data.datasets[index],
                    ...{
                        data: datasets[index].data,
                    },
                };
            }

        return {
            labels: statistical_data.labels,
            datasets: datasets,
        };
    };

    render() {
        const options = {
            responsive: true,
            // legend: {
            //     display: true,
            // },
            type: "bar",
            scales: {
                yAxes: [
                    {
                        type: "linear",
                        display: true,
                        position: "right",
                        id: "y-axis-1",
                    },
                    {
                        type: "linear",
                        display: true,
                        position: "left",
                        id: "y-axis-2",
                        gridLines: {
                            drawOnArea: false,
                        },
                    },
                ],
            },
        };
        let data = this._getData();

        return (
            <div className="RevenueChart" style={{ display: "flex" }}>
                <Bar data={data} options={options} />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    let _state = state.user_profile_statistical;

    return {
        statistical_data: _state.statistical_data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(RevenueChart);
