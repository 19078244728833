import React, { Component, Fragment } from "react";

// redux
import { connect } from "react-redux";
import UserGlobalActions from "../../../redux/user_global/actions";
import { history } from "../../../redux/store";

// router
import { Link } from "react-router-dom";
import Router_Links from "../../../../const/router_link";

// helper
import { getImageUrl } from "../../../../services/api/upload";
import { numberDisplayThousand } from "../../../../services/utils/helper";

// icon
import { FaCartPlus, FaAngleRight } from "react-icons/fa";
import ProductSkeleton from "../../../../components/loading/product_skeleton";

class ProductPanel extends Component {
  renderProduct = (product) => {
    let discount = product.discount;
    if (discount < 0) discount = 0;
    if (discount > 100) discount = 100;

    let point = product.point;

    let newPrice = (product.price / 100.0) * (100 - discount);

    return (
      <div
        className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-6"
        style={{
          paddingLeft: 0,
          paddingRight: 0,
        }}
      >
        <div
          onClick={() => {
            history.push(`${Router_Links.U_DETAIL_PRODUCT}/${product._id}`);
          }}
          className="APL_ProductItem"
        >
          <img
            className="APL_ProductImage"
            src={getImageUrl(product.thumbnail)}
            alt="thumbnail"
          />

          <div className="APL_ProductAction">
            <div
              onClick={(e) => {
                e.stopPropagation();
                this.props.addCartItem(product._id, 1);
              }}
              className="APL_ProductActionPanel"
            >
              <FaCartPlus className="APL_ProductActionIcon" />
            </div>
          </div>

          <div className="APL_ProductContent">
            <div className="APL_ProductName">{product.name}</div>
            <div className="APL_ProductPrice">
              {discount > 0 ? (
                <span>
                  <span className="APL_OldPrice">
                    {numberDisplayThousand(product.price)}đ
                  </span>
                  <span className="APL_NewPrice">
                    {numberDisplayThousand(newPrice)}đ/{" "}
                    {product.productUnit.description}
                  </span>
                </span>
              ) : (
                `${numberDisplayThousand(newPrice)}đ/ ${
                  product.productUnit.description
                }`
              )}
            </div>
          </div>

          {discount > 0 && (
            <div className="APL_ProdcutDiscount">{"-" + discount + "%"}</div>
          )}

          {point > 0 && (
            <div className="APL_ProdcutPoint">{"+" + point + "P"}</div>
          )}
        </div>
      </div>
    );
  };

  renderProductFlashSale = (product) => {
    let discount = product.flashSaleDiscount;
    if (discount < 0) discount = 0;
    if (discount > 100) discount = 100;

    let price = product.flashSalePrice;
    let newPrice = price;
    // let unitName = product.productUnit ? product.productUnit.description : ""

    let percent = 0;
    try {
      percent = (product.totalQuantitySold / product.totalNumberSale) * 100.0;
      if (percent < 0) percent = 0;
      if (percent > 100) percent = 100;
    } catch {}

    let flashsale = this.props.flashsale ? this.props.flashsale : new Date();
    var options = {
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
    };
    let date = new Date(flashsale.stopTime);

    return (
      <div
        className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12 col-6"
        style={{
          paddingLeft: 0,
          paddingRight: 0,
        }}
      >
        <div
          onClick={() => {
            history.push(`${Router_Links.U_DETAIL_PRODUCT}/${product._id}`);
          }}
          className="APL_ProductItem"
        >
          <img
            className="APL_ProductImage"
            src={getImageUrl(product.thumbnail)}
            alt="thumbnail"
          />

          <div className="APL_ProductAction">
            <div
              onClick={(e) => {
                e.stopPropagation();
                this.props.addCartItem(product._id, 1);
              }}
              className="APL_ProductActionPanel"
            >
              <FaCartPlus className="APL_ProductActionIcon" />
            </div>
          </div>

          <div className="APL_ProductContent">
            <div className="APL_ProductName">{product.name}</div>
            <div className="APL_ProductPrice">
              {discount > 0 ? (
                <span>
                  <span className="APL_OldPrice">
                    {numberDisplayThousand(product.price)}đ
                  </span>
                  <span className="APL_NewPrice">
                    {`${numberDisplayThousand(newPrice)}đ/ ${
                      product.productUnit.description
                    }`}
                  </span>
                </span>
              ) : (
                `${numberDisplayThousand(newPrice)}đ/ ${
                  product.productUnit.description
                }`
              )}
            </div>
            <div className="APL_ProgressFlashSale">
              <div className="APL_ProgreesBarPanel">
                <span className="APL_PercentLabel">
                  Đã bán {product.totalQuantitySold}
                </span>
                <div
                  className="APL_ProgreesBar"
                  style={{
                    width: percent + "%",
                  }}
                />
              </div>
            </div>
            <div className="APL_CountDown">
              {`Kết thúc ${date.toLocaleTimeString(
                "vi-vn",
                options
              )} ngày ${date.toLocaleDateString()}`}
            </div>
          </div>

          {discount > 0 && (
            <div className="APL_ProdcutDiscount">{"-" + discount + "%"}</div>
          )}
        </div>
      </div>
    );
  };

  renderSkeletonProductList = () => {
    const skelectonProductList = [];
    for (let i = 0; i < 10; i++) {
      skelectonProductList.push(<ProductSkeleton />);
    }
    return <div className="row">{skelectonProductList}</div>;
  };

  render() {
    let products = this.props.products ? this.props.products : [];
    let flashsale_products = this.props.flashsale_products
      ? this.props.flashsale_products
      : [];

    let popular_categorys = this.props.popular_categorys
      ? this.props.popular_categorys
      : [];
    const { isLoadingHomeProduct, isLoadingCurrentFlashSale } = this.props;
    return (
      <div>
        {/* flash sale */}
        {flashsale_products && flashsale_products.length > 0 ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: 20,
              marginBottom: 5,
            }}
          >
            <div className="HomePage_Title">
              <span className="d-flex mr-3">
                <span className="stripe-lines" />
                <span className="stripe-lines" />
                <span className="stripe-lines" />
              </span>
              Giá sốc hôm nay
              <span className="d-flex ml-3">
                <span className="stripe-lines" />
                <span className="stripe-lines" />
                <span className="stripe-lines" />
              </span>
            </div>
            <div
              className="title-line"
              style={{ height: 1, flex: 1, background: "#e2e2e2" }}
            />
            <Link to={Router_Links.U_FLASH_SALE}>
              <div className="btn-view-all">
                Xem thêm{" "}
                <div className="btn-view-all__icon">
                  <FaAngleRight />
                </div>
              </div>
            </Link>
          </div>
        ) : null}
        {isLoadingCurrentFlashSale ? (
          this.renderSkeletonProductList()
        ) : (
          <Fragment>
            <div className="product-panel row">
              {flashsale_products.map((product) => {
                return this.renderProductFlashSale(product);
              })}
            </div>
            <div className="load-more">
              <Link to={Router_Links.U_FLASH_SALE}>Xem thêm</Link>
            </div>
          </Fragment>
        )}

        {/* product noi bat */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: 10,
            marginBottom: 5,
          }}
        >
          <div className="HomePage_Title">
            <span className="d-flex mr-3">
              <span className="stripe-lines" />
              <span className="stripe-lines" />
              <span className="stripe-lines" />
            </span>
            Sản phẩm nổi bật
            <span className="d-flex ml-3">
              <span className="stripe-lines" />
              <span className="stripe-lines" />
              <span className="stripe-lines" />
            </span>
          </div>
          <div
            className="title-line"
            style={{ height: 1, flex: 1, background: "#e2e2e2" }}
          />
        </div>
        {isLoadingHomeProduct ? (
          this.renderSkeletonProductList()
        ) : (
          <div className="product-panel row">
            {products.map((product, index) => {
              return this.renderProduct(product);
            })}
          </div>
        )}

        {popular_categorys.map((category, index) => {
          let _products = category.product ? category.product : [];

          return (
            <React.Fragment>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 10,
                  marginBottom: 5,
                }}
              >
                <div className="HomePage_Title">
                  <span className="d-flex mr-3">
                    <span className="stripe-lines" />
                    <span className="stripe-lines" />
                    <span className="stripe-lines" />
                  </span>
                  {category.categoryName}
                  <span className="d-flex ml-3">
                    <span className="stripe-lines" />
                    <span className="stripe-lines" />
                    <span className="stripe-lines" />
                  </span>
                </div>
                <div
                  className="title-line"
                  style={{
                    height: 1,
                    flex: 1,
                    background: "#e2e2e2",
                  }}
                />
              </div>
              <div className="product-panel row">
                {_products.map((product, index) => {
                  return this.renderProduct(product);
                })}
              </div>
              <div className="load-more">
                <Link
                  to={`${Router_Links.U_FILTER_PRODUCT}?category=${category.categoryId}`}
                >
                  Xem thêm
                </Link>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    products: state.user_global.products,
    flashsale_products: state.user_global.flashsale_products,
    flashsale: state.user_global.flashsale,
    popular_categorys: state.user_global.popular_categorys,
    isLoadingHomeProduct: state.user_global.isLoadingHomeProduct,
    isLoadingCurrentFlashSale: state.user_global.isLoadingCurrentFlashSale,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addCartItem: (productId, quantity) => {
      dispatch(UserGlobalActions.action.addCartItem(productId, quantity));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductPanel);
