/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { Component } from "react";
// icon
import {
  FaAngleLeft,
  FaAngleRight,
  FaBolt,
  FaFileAlt,
  FaGifts,
  FaList,
  FaNetworkWired,
  FaRegChartBar,
  FaRegNewspaper,
  FaSeedling,
  FaSitemap,
  FaUserFriends,
  FaUsers,
} from "react-icons/fa";
// redux
import { connect } from "react-redux";
// react-router-dom
import { Link, withRouter } from "react-router-dom";
import module_app from "../../../../../const/module_app";
import permission_action from "../../../../../const/permission_action";
import Router_Links from "../../../../../const/router_link";
// helper
import { getImageUrl } from "../../../../../services/api/upload";
import AdminViewActions from "../../../../redux/admin_view/actions";
import AdminCheckPermissionComponent from "../../components/admin-check-permission";
import "./index.scss";

const menu_sidebar = [
  {
    title: "Trang chủ",
    icon: <FaRegChartBar className="Icon" />,
    url: Router_Links.A_HOME_PAGE,
  },
  {
    title: "Phân quyền nhóm người dùng",
    icon: <FaUsers className="Icon" />,
    url: Router_Links.A_MANAGER_LIST_USER_GROUP,
    paramsPer: {
      module: module_app.MODULE_PERMISSION_MANAGEMENT,
      action: permission_action.VIEW,
    },
  },
  {
    title: "Thông báo hệ thống",
    icon: <FaUsers className="Icon" />,
    url: Router_Links.A_MANAGER_NOTIFY_SYSTEM,
    paramsPer: {
      module: module_app.MODULE_NOTIFY_SYS_MANAGER,
      action: permission_action.VIEW,
    },
  },
  {
    title: "Danh sách người dùng",
    icon: <FaUserFriends className="Icon" />,
    url: Router_Links.A_LIST_USER,
    paramsPer: {
      module: module_app.MODULE_USER_MANAGEMENT,
      action: permission_action.VIEW,
    },
  },
  {
    title: "Danh mục cấp bậc người dùng",
    icon: <FaSitemap className="Icon" />,
    url: Router_Links.A_LEVEL_USER,
    paramsPer: {
      module: module_app.MODULE_LEVEL_MANAGEMENT,
      action: permission_action.VIEW,
    },
  },

  {
    title: "Danh sách sản phẩm",
    icon: <FaSeedling className="Icon" />,
    url: Router_Links.A_MANAGER_PRODUCT,
    paramsPer: {
      module: module_app.MODULE_PRODUCT_MANAGEMENT,
      action: permission_action.VIEW,
    },
  },
  {
    title: "Danh mục loại sản phẩm",
    icon: <FaList className="Icon" />,
    url: Router_Links.A_CATEGORY,
    paramsPer: {
      module: module_app.MODULE_CATEGORY_PRODUCT_MANAGEMENT,
      action: permission_action.VIEW,
    },
  },
  {
    title: "Danh sách đơn hàng",
    icon: <FaFileAlt className="Icon" />,
    url: Router_Links.A_LIST_ORDER,
    paramsPer: {
      module: module_app.MODULE_ORDER_MANAGEMENT,
      action: permission_action.VIEW,
    },
  },
  {
    title: "Danh sách kho hàng",
    icon: <FaFileAlt className="Icon" />,
    url: Router_Links.A_LIST_WARE_HOUSE,
    paramsPer: {
      module: module_app.MODULE_WARE_HOUSE_MANAGER,
      action: permission_action.VIEW,
    },
  },

  {
    title: "Danh mục nhóm khách hàng",
    icon: <FaNetworkWired className="Icon" />,
    url: Router_Links.A_CUSTOMER_GROUP,
    paramsPer: {
      module: module_app.MODULE_GROUP_CUSTOMER_MANAGEMENT,
      action: permission_action.VIEW,
    },
  },
  {
    title: "Danh sách flash sale",
    icon: <FaBolt className="Icon" />,
    url: Router_Links.A_LIST_FLASH_SALE,
    paramsPer: {
      module: module_app.MODULE_FLASH_SALES_MANAGEMENT,
      action: permission_action.VIEW,
    },
  },
  {
    title: "Danh sách mã khuyến mại",
    icon: <FaGifts className="Icon" />,
    url: Router_Links.A_LIST_COUPON,
    paramsPer: {
      module: module_app.MODULE_VOUCHER_MANAGEMENT,
      action: permission_action.VIEW,
    },
  },

  {
    title: "Danh mục bài viết",
    icon: <FaNetworkWired className="Icon" />,
    url: Router_Links.A_CATEGORY_POST,
    paramsPer: {
      module: module_app.MODULE_POST_MANAGEMENT,
      action: permission_action.VIEW,
    },
  },
  {
    title: "Quản lý danh sách bài viết",
    icon: <FaRegNewspaper className="Icon" />,
    url: Router_Links.A_LIST_POST,
    paramsPer: {
      module: module_app.MODULE_POST_MANAGEMENT,
      action: permission_action.VIEW,
    },
  },
  // {
  //   title: "Yêu cầu thay đổi nhóm khách hàng",
  //   icon: <FaUserSecret className="Icon" />,
  //   url: Router_Links.A_REQUEST_CHANGE_CUSTOMER_GROUP,
  // },
];

class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
    };
  }

  updateDimensions = () => {
    let width = window.innerWidth;
    // let height = window.innerHeight

    if (width <= 1365 && !this.props.isSmall) this.props.setSidebarStatus(true);
    if (width >= 1365) this.props.setSidebarStatus(false);
  };

  componentWillReceiveProps = (nextProps) => {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      const { pathname } = nextProps.location;

      let currentIndex = -1;
      for (let index = 0; index < menu_sidebar.length; index++)
        if (menu_sidebar[index].url === pathname) currentIndex = index;
      this.setState({ index: currentIndex });
    }
  };

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);

    this.props.setSidebarStatus(window.innerWidth <= 1365);

    const { pathname } = this.props.location;
    let currentIndex = -1;
    for (let index = 0; index < menu_sidebar.length; index++)
      if (menu_sidebar[index].url === pathname) currentIndex = index;
    this.setState({ index: currentIndex });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  render() {
    const menu = menu_sidebar;
    let userInfo = this.props.userInfo
      ? this.props.userInfo
      : { fullName: "", avatar: "" };
    let avatar = getImageUrl(userInfo.avatar);

    let _class = "AdminSideBar";
    if (this.props.isSmall) {
      _class = "AdminSideBarSmall";
    }

    return (
      <div className={_class} style={{ position: "relative" }}>
        <div className="ImageSideBar">
          <img className="Image" alt="image" src={avatar} />
          <div className="AdminSideBar_Title">{userInfo.fullName}</div>
        </div>

        {menu.map((item, index) => {
          let _class = "SidebarItem";
          if (index === this.state.index) _class += " SideBarActive";

          return (
            <AdminCheckPermissionComponent
              paramsPermission={item.paramsPer || undefined}
              noContent
            >
              <Link
                to={item.url}
                key={index}
                className={_class}
                // onClick={() => this.setState({ index })}
              >
                {item.icon}
                <div className="SidebarTitle">{item.title}</div>
              </Link>
            </AdminCheckPermissionComponent>
          );
        })}

        <div style={{ height: 500 }} />

        <div
          className="ButtonChangeState"
          onClick={() => this.props.changeSideBarDisplay()}
        >
          {this.props.isSmall ? (
            <FaAngleRight className="Icon" />
          ) : (
            <FaAngleLeft className="Icon" />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isSmall: state.admin_view.sidebar_status,

    userInfo: state.auth.userInfo ? state.auth.userInfo : null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSidebarStatus: (status) => {
      dispatch(AdminViewActions.action.setSideBarDisplay(status));
    },
    changeSideBarDisplay: () => {
      dispatch(AdminViewActions.action.changeSideBarDisplay());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SideBar));
