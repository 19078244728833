/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { Component } from "react";

import {
  FaSignOutAlt,
  FaInfoCircle,
  FaHome,
  FaFileAlt,
  FaDragon,
  FaBolt,
  FaFlag,
  FaUserLock,
  FaRoad,
  FaLink,
  FaHistory,
  FaMoneyBill,
  FaGifts,
  FaAddressBook,
  FaUser,
  FaTimes,
  FaSearch,
} from "react-icons/fa";

import { BsFillLightningFill } from "react-icons/bs";
// react-router-dom
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import Router_Links from "../../../../const/router_link";

// redux
import { connect } from "react-redux";
import AuthActions from "../../../redux/auth/actions";
import UserFilterProductActions from "../../../redux/user_filter_product/actions";
import "./top_bar.scss";

// images
import UserIcon from "../../../../resource/images/user.jpg";
import LogoIcon from "../../../../resource/images/cadosa-logo.png";
import { getImageUrl } from "../../../../services/api/upload";
import FilterPanel from "../filter_product_page/filter_panel";
import { Drawer } from "antd";
// menus
const menus = [
  {
    title: "Thông tin tài khoản",
    icon: <FaUser className="SPR_Icon" />,
    path: Router_Links.U_PROFILE_INFO,
  },
  {
    title: "Địa chỉ giao hàng",
    icon: <FaAddressBook className="SPR_Icon" />,
    path: Router_Links.U_PROFILE_ADDRESS_SHIPING,
  },
  {
    title: "Danh sách mã khuyến mại",
    icon: <FaGifts className="SPR_Icon" />,
    path: Router_Links.U_PROFILE_VOUCHER,
  },
  {
    title: "Lịch sử sử dụng mã khuyến mại",
    icon: <FaHistory className="SPR_Icon" />,
    path: Router_Links.U_PROFILE_HISTORY_VOUCHER,
  },
  {
    title: "Đơn hàng",
    icon: <FaFileAlt className="SPR_Icon" />,
    path: Router_Links.U_PROFILE_HISTORY_BILL,
  },
  {
    title: "Doanh thu",
    icon: <FaMoneyBill className="SPR_Icon" />,
    path: Router_Links.U_PROFILE_REVENUE,
  },
  {
    title: "Lịch sử giao dịch",
    icon: <FaHistory className="SPR_Icon" />,
    path: Router_Links.U_PROFILE_HISTORY_PAYMENT,
  },
  {
    title: "Liên kết bán hàng",
    icon: <FaLink className="SPR_Icon" />,
    path: Router_Links.U_PROFILE_LINK_AFFILIATE,
  },
  {
    title: "Cấp độ thành viên",
    icon: <FaRoad className="SPR_Icon" />,
    path: Router_Links.U_PROFILE_LEVEL_USER,
  },
  {
    title: "Đổi mật khẩu",
    icon: <FaUserLock className="SPR_Icon" />,
    path: Router_Links.U_PROFILE_CHANGE_PASSWORD,
  },
];

class TopBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle_mobile: false,
      togge_search_mobile: false,
      select_mobile: "",

      textSearch: "",
      isShowDrawerAccount: false,
      isShowDrawer: false,
    };
  }

  // lifecycle

  // handle
  handleTogleMobile = () => {
    this.setState({ isShowDrawer: true });
  };

  hideDrawerMobile = () => {
    this.setState({ isShowDrawer: false });
  };

  handleToggleSearchMobile = () => {
    this.setState({ togge_search_mobile: !this.state.togge_search_mobile });
  };

  // render
  renderFilterMobile = () => {
    return <FilterPanel />;
  };

  renderHeader = () => {
    const { isLoggedIn, userInfo } = this.props;
    const showDrawerAccount = () => {
      this.setState({ isShowDrawerAccount: true });
    };

    const hideDrawerAccount = () => {
      this.setState({ isShowDrawerAccount: false });
    };

    return (
      <div
        className="header-middle"
        style={
          {
            // backgroundColor: "#0088cc",
          }
        }
      >
        <div className="container">
          <div className="header-left">
            <button
              className="mobile-menu-toggler"
              type="button"
              onClick={() => this.handleTogleMobile()}
            >
              <i className="icon-menu"></i>
            </button>
            <Link
              to={Router_Links.U_HOME_PAGE}
              className="logo"
              style={{
                marginLeft: 20,
              }}
            >
              <img
                style={{
                  height: 60,
                }}
                src={LogoIcon}
                alt="CADOSA"
              />
              {/* <div className="NameBrand">CADOSA</div> */}
            </Link>
          </div>
          {/* <!-- End .header-left --> */}

          <div className="header-center">
            <div className="header-search">
              <div
                className="search-toggle"
                role="button"
                onClick={() => this.handleToggleSearchMobile()}
              >
                <i className="icon-magnifier"></i>
              </div>
              <div
                className={
                  "header-search-wrapper " +
                  (this.state.togge_search_mobile ? "show" : "")
                }
              >
                <input
                  type="search"
                  className="form-control"
                  name="q"
                  // id="q"
                  placeholder="Nhập để tìm kiếm ..."
                  required
                  value={this.state.textSearch}
                  onChange={(e) => {
                    this.setState({
                      textSearch: e.target.value,
                    });
                    this.props.updateTextSearch(e.target.value);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      this.props.searchProduct();
                      this.props.history.push(
                        `${Router_Links.U_FILTER_PRODUCT}?search=${this.state.textSearch}`
                      );
                    }
                  }}
                />

                <button
                  className="btn"
                  onClick={() => {
                    this.props.history.push(
                      `${Router_Links.U_FILTER_PRODUCT}?search=${this.state.textSearch}`
                    );
                    this.handleToggleSearchMobile();

                    this.setState({
                      textSearch: "",
                    });
                  }}
                >
                  <i className="icon-magnifier"></i>
                </button>
              </div>
              {/* <!-- End .header-search-wrapper --> */}
              {/* </form> */}
            </div>
            {/* <!-- End .header-search --> */}
          </div>
          {/* <!-- End .headeer-center --> */}

          <div className="header-right">
            {!isLoggedIn ? (
              <Link className="header-user" to={Router_Links.U_AUTH_LOGIN}>
                {/* <i className="icon-user-2"></i> */}
                <img
                  className="icon-user-2"
                  src={UserIcon}
                  style={{
                    height: 46,
                    width: 46,
                    borderRadius: 23,
                    marginRight: 10,
                    border: "1px solid white",
                    objectFit: "cover",
                  }}
                  alt=""
                />
                <div className="header-userinfo">
                  <h4
                    style={{
                      textDecoration: "underline",
                    }}
                  >
                    Đăng nhập
                  </h4>
                </div>
              </Link>
            ) : (
              <div className="xt_dropdown">
                <div className="header-user">
                  {/* <i className="icon-user-2"></i> */}
                  {userInfo && (
                    <img
                      className="icon-user-2"
                      src={getImageUrl(userInfo.avatar)}
                      style={{
                        height: 46,
                        width: 46,
                        borderRadius: 23,
                        marginRight: 10,
                        border: "1px solid white",
                      }}
                      alt=""
                      onClick={showDrawerAccount}
                    />
                  )}
                  <div className="header-userinfo">
                    <span>Xin chào!</span>
                    <h4>{userInfo && userInfo.fullName}</h4>
                  </div>
                </div>

                <div
                  className="xt_dropdown_content"
                  style={{
                    top: 50,
                    backgroundColor: "transparent",
                  }}
                >
                  {this.renderDropdownInfo()}
                </div>
                <Drawer
                  visible={this.state.isShowDrawerAccount}
                  className="header-user-drawer"
                  width={320}
                  title={
                    <div className="header-user-drawer_title">Cá nhân</div>
                  }
                  headerStyle={{
                    border: "none",
                  }}
                  closeIcon={
                    <FaTimes className="header-user-drawer_icon-close" />
                  }
                  bodyStyle={{
                    marginBottom: 20,
                    paddingTop: 0,
                  }}
                  onClose={hideDrawerAccount}
                >
                  {menus.map((menu_item) => (
                    <Link
                      onClick={hideDrawerAccount}
                      to={menu_item.path}
                      className="header-user-drawer_item"
                    >
                      {menu_item.icon ? menu_item.icon : null}
                      <div className="header-user-drawer_item_title">
                        {menu_item.title}
                      </div>
                    </Link>
                  ))}
                  <div
                    className="header-user-drawer_item"
                    onClick={this.props.logout}
                  >
                    <FaSignOutAlt className="SPR_Icon" />
                    <div className="header-user-drawer_item_title">
                      Đăng xuất
                    </div>
                  </div>
                </Drawer>
              </div>
            )}
            {/* <!-- End .dropdown --> */}
          </div>
          {/* <!-- End .header-right --> */}
        </div>
        {/* <!-- End .container --> */}
      </div>
    );
  };

  renderHeaderBottom = () => {
    const { pathname } = this.props.location;

    let list_links = [
      {
        title: "Trang chủ",
        icon: <FaHome />,
        link: Router_Links.U_HOME_PAGE,
      },
      {
        title: "Khuyến mại hot",
        icon: <FaBolt />,
        link: Router_Links.U_HOT_DISCOUNT,
      },
      {
        title: "Sản phẩm bán chạy",
        icon: <FaDragon />,
        link: Router_Links.U_BEST_SELL,
      },
      {
        title: "Đơn hàng của tôi",
        icon: <FaFileAlt />,
        link: Router_Links.U_PROFILE_HISTORY_BILL,
      },
    ];

    return (
      <div className="header-bottom">
        <div className="container">
          <nav className="main-nav">
            <ul className="menu">
              {list_links.map((link_item, index) => {
                return (
                  <li className="menu-item" key={index}>
                    <Link
                      to={link_item.link}
                      style={{
                        marginLeft: 0,
                        color: pathname == link_item.link ? "red" : "black",
                      }}
                      className="menu-item-main"
                    >
                      <div className="menu-item-icon">{link_item.icon}</div>
                      {link_item.title}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </nav>

          <div className="header-dropdowns">
            <div className="header-dropdown">
              <Link to="/auth/register" className="link-seller">
                Trở thành thành viên
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderHeaderMobile = () => {
    const { categorys } = this.props;
    let list_links = [
      {
        title: "Khuyến mại hot",
        icon: <BsFillLightningFill />,
        link: Router_Links.U_HOT_DISCOUNT,
      },
      {
        title: "Sản phẩm bán chạy",
        icon: <FaFlag />,
        link: Router_Links.U_BEST_SELL,
      },
      {
        title: "Đơn hàng của tôi",
        icon: <FaFileAlt />,
        link: Router_Links.U_PROFILE_HISTORY_BILL,
      },
    ];

    const isFilterPage =
      this.props.location.pathname === Router_Links.U_FILTER_PRODUCT;

    return (
      <React.Fragment>
        <div className="mobile-menu-overlay"></div>
        {/* <!-- End .mobil-menu-overlay --> */}

        <div className="mobile-menu-container">
          <div className="mobile-menu-wrapper">
            <span
              className="mobile-menu-close"
              onClick={() => this.handleTogleMobile()}
            >
              {/* mobile-menu-close */}
              <i className="icon-retweet"></i>
            </span>
            <Drawer
              visible={this.state.isShowDrawer}
              placement="left"
              bodyStyle={{
                marginTop: 40,
                paddingTop: 0,
              }}
              closeIcon={<FaTimes className="header-user-drawer_icon-close" />}
              width={320}
              onClose={this.hideDrawerMobile}
            >
              {isFilterPage ? (
                this.renderFilterMobile()
              ) : (
                <div className="category-drawer-container">
                  {list_links.map((linkItem) => (
                    <div
                      onClick={this.hideDrawerMobile}
                      className="category-drawer-item-top"
                    >
                      {linkItem.icon}
                      <Link to={linkItem.link}>{linkItem.title}</Link>
                    </div>
                  ))}
                  {categorys.map((category, index) => (
                    <Link
                      onClick={this.hideDrawerMobile}
                      className="category-drawer-item"
                      to={`${Router_Links.U_FILTER_PRODUCT}?category=${category._id}`}
                      style={{
                        borderBottom:
                          index === categorys.length - 1
                            ? "0px solid #e2e2e2"
                            : "1px solid #e2e2e2",
                      }}
                    >
                      {category.description}
                    </Link>
                  ))}
                </div>
              )}
            </Drawer>
          </div>
          {/* <!-- End .mobile-menu-wrapper --> */}
        </div>
        {/* <!-- End .mobile-menu-container --> */}
      </React.Fragment>
    );
  };

  renderDropdownInfo = () => {
    let userInfo = this.props.userInfo;

    return (
      <div className="Utop_DropDownContent">
        <div className="UTD_Info">
          {userInfo && (
            <img
              src={getImageUrl(userInfo.avatar)}
              className="UTD_Avatar"
              alt="image"
            />
          )}
          <div className="UTD_FullName">{userInfo && userInfo.fullName}</div>
        </div>

        <Link className="UTD_ActionRow" to={Router_Links.U_PROFILE_INFO}>
          <FaInfoCircle className="UTD_Icon" />
          Thông tin tài khoản
        </Link>
        <div
          className="UTD_ActionRow"
          onClick={() => {
            this.props.logout();
          }}
        >
          <FaSignOutAlt className="UTD_Icon" />
          Đăng xuất
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="header">
        {this.renderHeader()}
        {/* <!-- End .header-middle --> */}

        {this.renderHeaderBottom()}

        {this.renderHeaderMobile()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    userInfo: state.auth.userInfo ? state.auth.userInfo : null,
    categorys: state.user_global.categorys,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch(AuthActions.action.logout());
    },
    updateTextSearch: (textSearch) => {
      dispatch(UserFilterProductActions.action.updateTextSearch(textSearch));
    },
    searchProduct: () => {
      dispatch(UserFilterProductActions.action.searchProduct());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TopBar));
