import { Select } from "antd";
import React, { Component } from "react";
import { FaSave, FaTimes } from "react-icons/fa";
import { connect } from "react-redux";
import Button from "../../../../components/button";
import Modal from "../../../../components/modal";
import { getImageUrl } from "../../../../services/api/upload";
//redux
import CategoryActions from "../../../redux/admin_category/actions";
import "./index.scss";

class ModalAddCategory extends Component {
  onChangeCodeCategory = (evt) => {
    if (typeof this.props.updateNewCategory === "function") {
      let code = evt.target.value;
      this.props.updateNewCategory({ code: code });
    }
  };

  onChangeDescription = (evt) => {
    if (typeof this.props.updateNewCategory === "function") {
      let description = evt.target.value;
      this.props.updateNewCategory({ description: description });
    }
  };

  onChangeParentCategory = (value) => {
    if (typeof this.props.updateNewCategory === "function") {
      this.props.updateNewCategory({ parentId: value });
    }
  };

  onClickCreateCategory = () => {
    if (typeof this.props.createNewCategory === "function") {
      this.props.createNewCategory();
    }
  };

  handleImageChange(e) {
    e.preventDefault();

    for (let index = 0; index < e.target.files.length; index++) {
      let file = e.target.files[index];
      let reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const imageLocal = {
          _id: new Date().getTime(),
          url: reader.result,
          file,
        };
        if (typeof this.props.updateNewCategory === "function") {
          this.props.updateNewCategory({ imageLocal });
        }
      };
    }
  }

  render() {
    const {
      allCategory,
      newCategory,
      mdCreateNewCategoryState,
      setModalCreateNewCategoryState,
    } = this.props;
    return (
      <Modal
        isOpen={mdCreateNewCategoryState}
        displayCloseIcon={false}
        setIsOpen={setModalCreateNewCategoryState}
      >
        <div className="AdminAddCategoryModal">
          <div className="AACM_Label">Thêm mới danh mục sản phẩm</div>
          <form>
            <div>
              <label className="AACMF_Label">Mã code</label>
              <input
                type="text"
                className="AACMF_Value"
                value={newCategory.code}
                onChange={this.onChangeCodeCategory}
                placeholder="Nhập mã code danh mục"
              />
            </div>
            <div>
              <label className="AACMF_Label">Tên danh mục</label>
              <input
                className="AACMF_Value"
                type="text"
                value={newCategory.description}
                onChange={this.onChangeDescription}
                placeholder="Nhập tên danh mục"
              />
            </div>
            <div>
              <label className="AACMF_Label">Danh mục cha</label>
              <Select
                value={newCategory.parentId}
                className="AACMF_Select"
                onChange={this.onChangeParentCategory}
                placeholder="Lựa chọn danh mục cha"
              >
                <Select.Option value={null}>Danh mục gốc</Select.Option>
                {allCategory.map((category) => (
                  <Select.Option key={category._id} value={category._id}>
                    {category.description}
                  </Select.Option>
                ))}
              </Select>
            </div>

            <div>
              <label className="AACMF_Label">Ảnh danh mục</label>

              <div
                className="APD_InfoImageContent row"
                style={{ padding: 0, marginLeft: 8 }}
              >
                <div className="APD_ProductImageItem">
                  {newCategory.imageLocal && (
                    <img
                      src={getImageUrl(newCategory.imageLocal.url)}
                      className="APD_ProductImage"
                      alt="image"
                      width={200}
                      height={200}
                    />
                  )}
                </div>
                <input
                  type="file"
                  name="file2"
                  id="file2"
                  class="hide"
                  onChange={(e) => this.handleImageChange(e)}
                />
                <label for="file2">
                  <div className="APD_ProductImageItem">
                    <div
                      className="APD_ProductImage_Title"
                      style={{ textDecorationLine: "underline" }}
                    >
                      {newCategory.imageLocal ? "Chọn ảnh khác" : "Thêm ảnh"}
                    </div>
                  </div>
                </label>
              </div>
            </div>

            <div className="d-flex justify-content-center AACMF_Action">
              <Button
                label="Lưu"
                icon={<FaSave style={{ marginRight: "4px" }} />}
                className="AACMF_Button"
                onClick={this.onClickCreateCategory}
              />
              <Button
                label="Hủy"
                icon={<FaTimes style={{ marginRight: "4px" }} />}
                className="AACMF_Button_Cancel"
                onClick={() => this.props.setModalCreateNewCategoryState(false)}
              />
            </div>
          </form>
        </div>
      </Modal>
    );
  }
}

const mapStatetoProps = (state) => {
  let _state = state.admin_category;
  return {
    allCategory: _state.allCategory,
    newCategory: _state.newCategory,
    mdCreateNewCategoryState: _state.mdCreateNewCategoryState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setModalCreateNewCategoryState: (state) => {
      dispatch(CategoryActions.action.setModalCreateNewCategoryState(state));
    },
    updateNewCategory: (newCategory) => {
      dispatch(CategoryActions.action.updateNewCategory(newCategory));
    },
    createNewCategory: () => {
      dispatch(CategoryActions.action.createNewCategory());
    },
  };
};

export default connect(mapStatetoProps, mapDispatchToProps)(ModalAddCategory);
