import React, { Component } from "react";

// redux
import { connect } from "react-redux";

import { Link } from 'react-router-dom'

// scss
import "./index.scss";

// route
import { Redirect } from "react-router-dom";
import Router_Links from "../../../../const/router_link";

// helper
import { validateEmail } from "../../../../services/utils/validate/common";
import NotificationsService from '../../../../services/utils/notifycationHelper'

// api 
import { requestChangePassword } from '../../../../services/api/auth'
import { MAX_LENGTH_EMAIL } from "../../../../const/email_consts";

class UserView_ForgotPassword extends Component {

    constructor(props){
        super(props)
        this.state = {
            email: ""
        }
    }

    // handle 
    _handleResetPassword = async () => {
        let email = this.state.email 

        // validate 
        let validateRes = validateEmail(email)
        if (!validateRes.isValidate){
            NotificationsService.error(validateRes.message)
            return 
        }

        // call api 
        try {
            let host = "http://cadosa.vn"
            let req = await requestChangePassword(email, `${host}${Router_Links.U_AUTH_CONFIRM_RESET_PASSWORD}`)
            if (req.code === 200){
                NotificationsService.success("Gửi yêu cầu cập nhật mật khẩu thành công")
            }
            else 
                NotificationsService.error("Gửi yêu cầu cập nhật mật khẩu thất bại")
        }
        catch (ex){
            NotificationsService.error("Gửi yêu cầu cập nhật mật khẩu thất bại")
        }
    }

    onChangeEmail = (e) => {
        const email = (e.target.value).trim()
        if (email.length <=  MAX_LENGTH_EMAIL) {
            this.setState({ email })
        }
    }

    // render
    render() {
        let from = null;
        if (this.props.isLoggedIn) {
            if (from) return <Redirect to={from} />;

            return <Redirect to={Router_Links.U_PROFILE_INFO} />;
        }
        
        return (
            <div className="UserView_ForgotPassword">
                <div className="UserView_ForgotPasswordPanel">
                    <div className="UFG_InfoPanel">
                        <div className="UFIF_Title">
                            Quên mật khẩu
                        </div>

                        <div className="UFIF_SubTile">
                            Bạn vui lòng nhập email để reset mật khẩu
                        </div>

                        <input 
                            type="text"
                            placeholder="Nhập email của bạn"
                            className="UFIF_EmailInput"
                            style={{
                                color: 'black'
                            }}
                            value={this.state.email}
                            onChange={this.onChangeEmail}
                        />

                        <div 
                            className="UFIF_BtnResetPassword"
                            onClick={() => {
                                this._handleResetPassword()
                            }}
                        >
                            Reset mật khẩu
                        </div>

                        <div className="UFIF_More">
                            Nếu bạn chưa có tài khoản, xin mời 
                            <Link to="/auth/register">
                                {" "} Đăng ký
                            </Link>
                        </div>

                    </div>
                    <div className="UFG_ImagePanel">
                        <img
                            className="UFGI_Image"
                            src= "https://static.vecteezy.com/system/resources/previews/000/662/235/non_2x/vector-team-work-people-in-factory-scene.jpg"
                            // {require("../../../../resource/images/bg.jpg")}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.auth.isLoggedIn
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserView_ForgotPassword);
