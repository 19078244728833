export const isEmpty = (text) => {
  if (!text) return true;
  if (text.trim() === "") return true;
  return false;
};

export const formatPrice = (num) => {
  if (!num) return "0";
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
};
