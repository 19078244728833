import React, { Component } from 'react'

class DisplayRichTextBoxValue extends Component {

    render() {
        return (
            <div className="quill">
                <div className="ql-snow">
                    <div 
                        className="ql-editor" 
                        dangerouslySetInnerHTML={{ __html: this.props.value ? this.props.value : "" }}
                    >
                    
                    </div>
                </div>
            </div>
        )
    }
}


export default DisplayRichTextBoxValue
