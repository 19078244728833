import ApiUtils from "../../../../../services/ApiUtils";

const apiName = {
  LIST_ORDER_CMS: "/admin/managerOrder/getOrdersCms",
  UPDATE_NOTE_ORDER: "/admin/managerOrder/update-note-order",
  EXCEL_EXPORT: "/admin/managerOrder/order-excel",
};

export const apiGetListOrdersCms = async (params) => {
  const res = await ApiUtils.fetch(apiName.LIST_ORDER_CMS, params, {
    isAuth: true,
  });
  return res.data || undefined;
};

export const apiUpdateNoteOrder = async (params) => {
  const res = await ApiUtils.post(apiName.UPDATE_NOTE_ORDER, params, {
    isAuth: true,
  });
  return res.data || undefined;
};

export const apiExportExcelOrder = async (params) => {
  const res = await ApiUtils.post(apiName.EXCEL_EXPORT, params, {
    isAuth: true,
  });
  return (res.data && res.data.url) || undefined;
};
