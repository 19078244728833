import React, { Component } from "react";
import ReactQuill, { Quill } from "react-quill";
// api
import { getImageUrl, uploadImage } from "../../services/api/upload";
import { ImageResize } from "./imageResize";
// css
import "./index.scss";

Quill.register("modules/imageResize", ImageResize);

class RichTextBoxEditor extends Component {
  imageHandler() {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();
    input.onchange = async function () {
      const file = input.files[0];

      try {
        const res = await uploadImage(file); // I'm using react, so whatever upload function
        const range = this.quill.getSelection();

        const link = getImageUrl(res.path);

        // this part the image is inserted
        // by 'image' option below, you just have to put src(link) of img here.
        this.quill.insertEmbed(range.index, "image", link);
      } catch (ex) {}
    }.bind(this); // react thing
  }

  render() {
    const toolbarOptions = {
      toolbar: {
        handlers: {
          image: this.imageHandler,
        },
        container: [
          [
            // { header: [1, 2,3, 4, 5, 6, false] },
            // { font: [] },
            { size: ["small", false, "large", "huge"] },
          ],
          [{ color: [] }, { background: [] }],
          [
            "bold",
            "italic",
            "underline",
            "strike",
            // 'blockquote',
            "code-block",
          ],
          [
            { align: "" },
            { align: "center" },
            { align: "right" },
            { align: "justify" },
          ],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ script: "sub" }, { script: "super" }],
          ["link", "image", "video"],
          ["clean"],
        ],
      },
      imageResize: {
        displayStyles: {
          backgroundColor: "black",
          border: "none",
          color: "white",
        },
        modules: ["Resize", "DisplaySize", "Toolbar"],
      },
    };

    const options = {
      theme: "snow",
      // formats: Editor.formats,
      placeholder: "",
      value: this.props.value,
      onChange: (value) => this.props.onChange(value),
      modules: toolbarOptions,
    };

    return <ReactQuill {...options} />;
  }
}

// props( value, onChange)

export default RichTextBoxEditor;
