const type = {
  OFFLINE: "COD",
  ONLINE: "ONLINE",
  DIRECT: "DIRECT",
};

const ListPayments = [
  {
    type: type.OFFLINE,
    name: "Trả tiền khi nhận hàng",
  },
  // {
  //     type: type.ONLINE,
  //     name: "Thanh toán online"
  // }
];

export const AdminListPaymentTypes = [
  {
    type: type.OFFLINE,
    name: "Trả tiền khi nhận hàng",
  },
  {
    type: type.DIRECT,
    name: "Thanh toán trực tiếp",
  },
];

export default ListPayments;
