import React, { Component } from "react";

// component
import { CopyToClipboard } from 'react-copy-to-clipboard';

// redux
import { connect } from "react-redux";

// scss
import "./index.scss";

// icons
import { FaRegCopy, FaFacebookSquare, FaTwitterSquare } from "react-icons/fa";
import NotificationsService from "../../../../../services/utils/notifycationHelper";

class LinkAffiliatePage extends Component {
    render() {
        let userInfo =
            this.props.userInfo ?
                this.props.userInfo : {
                    linkAffiliate: ""
                }

        return (
            <div className="LinkAffiliatePage">
                <div className="LAF_Title">Bán hàng liên kết</div>
                <div className="LAF_Subtile">
                    Giới thiệu bạn bè của bạn: Tận hưởng đến 5 tầng thu nhập.
                </div>

                <div className="LAF_LinkPanel">
                    <div className="LAF_LinkG">
                        <div className="LAF_Link">
                            {`http://cadosa.vn/auth/register?affiliate=${userInfo.linkAffiliate}`}

                            <CopyToClipboard text={`http://cadosa.vn/auth/register?affiliate=${userInfo.linkAffiliate}`}
                                onCopy={() => NotificationsService.success("Sao chép thành công")}>
                                <FaRegCopy className="LAF_Icon" />
                            </CopyToClipboard>
                        </div>

                    </div>
                    <div className="LAF_Share">
                        <FaFacebookSquare className="LAF_Iconz" />
                        <FaTwitterSquare className="LAF_Iconz" />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.auth.current_user_info,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(LinkAffiliatePage);
