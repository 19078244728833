import React from 'react';
import './index.scss';
import queryString from 'query-string';

// redux
import { connect } from 'react-redux';
import UserListPostAction from '../../../redux/user_list_post/actions';
import { getImageUrl } from '../../../../services/api/upload';
import Pagination from '../../../../components/paginations/paginations';
import { Link } from 'react-router-dom';
import router_links from '../../../../const/router_link';
import Button from '../../../../components/button';
import { FaAngleLeft } from 'react-icons/fa';

class ListPostPage extends React.Component {
    componentDidMount() {
        const parsed = queryString.parse(this.props.location.search);
        if (Object.prototype.hasOwnProperty.call(parsed, 'name')) {
            this.props.setCurrentTag(parsed.name);
            this.props.loadListPostByTag({ tag: parsed.name });
        }
    }
    render() {
        const { listPosts, pageSize, pageIndex, total } = this.props;
        return (
            <div className='list-post-tags'>
                <div className='d-flex'>
                    <Button
                        className="list-post-tags-back"
                        label="Quay lại"
                        icon={<FaAngleLeft />}
                        onClick={this.props.history.goBack}
                    />
                </div>
                <div className='list-post-tags-header'>Tags</div>
                <h3>{queryString.parse(this.props.location.search).name}</h3>
                <hr className='hr-seperate' />
                <div className='container'>
                    {listPosts.map(post => (
                    <React.Fragment>
                        <div className='post-card row'>
                        <div className='col-9 col-md-7 col-lg-8 post-summary'>
                            <h2 className='title-post'>
                                <Link
                                    to={router_links.U_DETAIL_POST + `/${post._id}`}
                                >
                                    {post.title}
                                </Link>
                            </h2>
                            <p className='description truncate-overflow'>
                                {post.description}
                            </p>
                        </div>
                        <div className='thumb-post col-md-5 col-lg-4 '>
                            <img src={getImageUrl(post.thumbnailUrl)} />
                        </div>
                    </div>
                    <hr className='hr-seperate' />
                    </React.Fragment>
                ))}
                </div>
                <div>
                    <Pagination
                        onChange={(index) => {
                            this.props.updatePageIndex(index)
                        }}
                        pageSize={pageSize}
                        pageIndex={pageIndex}
                        total={total}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const _state = state.user_list_post;
    return {
        listPosts: _state.listPosts,
        pageSize: _state.pageSize,
        pageIndex: _state.pageIndex,
        total: _state.total
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentTag: (tag) => {
            dispatch(UserListPostAction.action.setCurrentTag(tag))
        },
        loadListPostByTag: (params) => {
            dispatch(UserListPostAction.action.loadListPostByTag(params))
        },
        updatePageIndex: (pageIndex) => {
            dispatch(UserListPostAction.action.updatePageIndex(pageIndex))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListPostPage);