const validate = (entity) => {
    // function
    const errorResult = (message) => {
        return {
            isValidate: false,
            message,
        };
    };

    // validate
    if (!entity.name || entity.name.trim() === "") {
        return errorResult("Tên không được để trống")
    }

    if (!entity.description || entity.description.trim() === "") {
        return errorResult("Mô tả không được để trống")
    }
    if (!entity.percentDiscountPerOrder) {
        return errorResult("Triết khấu / đơn hàng không được để trống")
    }

    if (!entity.minimumRevenue) {
        return errorResult("Doanh thu nhỏ nhất không được để trống")
    }

    if (!entity.minimumDeposit) {
        return errorResult("Ký quỹ nhỏ nhất không được để trống")
    }
    
    if (!entity.childrenType) {
        return errorResult("Loại cấp dưới phụ thuộc không được để trống")
    }

    if (!entity.minimumChildren) {
        return errorResult("Thành viên hoạt động nhỏ nhất không được để trống")
    }

    if (!entity.levelBefore) {
        return errorResult("Cấp bậc đứng trước không được để trống")
    }

    return {
        isValidate: true,
        message: "success",
    };
};

export default {
    validate,
};
