import { Checkbox, Image } from "antd";
import { truncate } from "lodash";
import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import router_links from "../../../../../const/router_link";
import { getImageUrl } from "../../../../../services/api/upload";
import { numberDisplayThousand } from "../../../../../services/utils/helper";

export default class CreateVoucherItem extends PureComponent {
  render() {
    const { item, onSelect, isChecked } = this.props;
    return (
      <div
        className="mt-5 mr-2"
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <Checkbox
          className="mr-3"
          onClick={() => {
            onSelect(!isChecked);
          }}
          checked={isChecked}
        />

        <Image
          style={{
            borderRadius: 5,
            width: 100,
            height: 80,
            objectFit: "cover",
            borderWidth: 0.5,
            borderColor: "red",
            cursor: "pointer",
          }}
          preview={false}
          src={item.thumbnail && getImageUrl(item.thumbnail)}
          onClick={() => {
            onSelect(!isChecked);
          }}
        />

        <div className="ml-3" style={{ flex: 1 }}>
          <Link
            to={`${router_links.U_DETAIL_PRODUCT}/${item._id}`}
            target="_blank"
          >
            <span style={{ fontWeight: "bold" }}>
              {truncate(item.name, { length: 35 })}
            </span>

            {/* quantity */}
          </Link>
          <br />
          <span>{numberDisplayThousand(item.price)} đ</span>
          <br />
          <span>Số lượng CL: {numberDisplayThousand(item.quantity)}</span>
        </div>
      </div>
    );
  }
}
