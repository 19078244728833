import { all, fork, put, select, takeEvery } from "redux-saga/effects";
// api
import GroupCustomerApiServices from "../../../services/api/customer_group";
// helper
import {
  default as NotificationsService,
  default as NotifycationServices,
} from "../../../services/utils/notifycationHelper";
import ValidateCustomerGroupService from "../../../services/utils/validate/validate_customer_group";
import actions from "./actions";

function* saga_LoadListCustomerGroup(action) {
  try {
    // params
    let params = action.payload.params;

    let _state = yield select((state) => state.admin_customer_group);
    let pageIndex = _state.pageIndex;
    let textSearch = _state.lastTextSearch;
    let pageSize = _state.pageSize;

    if (params.pageIndex) pageIndex = params.pageIndex;
    if (params.textSearch) textSearch = params.textSearch;
    if (params.pageSize) pageSize = params.pageSize;

    // call api
    let req = yield GroupCustomerApiServices.getListGroupCustomer(
      pageIndex,
      pageSize,
      textSearch
    );
    if (req.code === 200) {
      let customer_groups = req.data.items;
      let total = req.data.total;

      yield put(
        actions.action.updateState({
          total,
        })
      );
      yield put(actions.action.loadListCustomerGroupSuccess(customer_groups));
    }
  } catch (ex) {
    console.log(
      "[admin_customer_group] saga_LoadListCustomerGroup Error: ",
      ex
    );
  }
}

function* saga_LoadDetailCustomerGroup(action) {
  try {
    let id = yield action.payload.id;

    // call api
    let req = yield GroupCustomerApiServices.getDetailGroupCustomer(id);
    if (req.code === 200) {
      let customer_group = req.data;
      yield put(actions.action.loadDetailCustomerGroupSuccess(customer_group));
    }
  } catch (ex) {
    console.log(
      "[admin_customer_group] saga_LoadDetailCustomerGroup Error: ",
      ex
    );
  }
}

function* saga_UpdatePageIndex(action) {
  try {
    let pageIndex = action.payload.pageIndex;

    yield put(
      actions.action.loadListCustomerGroup({
        pageIndex,
      })
    );
  } catch (ex) {
    console.log("[admin_customer_group] saga_UpdatePageIndex Error: ", ex);
  }
}

function* saga_SearchCustomerGroup(action) {
  try {
    let textSearch = yield select(
      (state) => state.admin_customer_group.textSearch
    );

    yield put(
      actions.action.loadListCustomerGroup({
        textSearch: textSearch.trim(),
        pageIndex: 1,
      })
    );
  } catch (ex) {
    console.log("[admin_customer_group] saga_SearchCustomerGroup Error: ", ex);
  }
}

function* saga_SaveCurrentCustomerGroup(action) {
  let customer_group = {
    _id: 0,
  };

  try {
    let customer_group = yield select(
      (state) => state.admin_customer_group.customer_group
    );

    let validateRes = yield ValidateCustomerGroupService.validate(
      customer_group
    );
    if (!validateRes.isValidate) {
      NotifycationServices.error(validateRes.message);
      return;
    }

    if (customer_group._id === 0) {
      // thêm mới
      let req = yield GroupCustomerApiServices.insertCustomerGroup(
        customer_group
      );

      if (req.code === 200) {
        yield put(actions.action.loadListCustomerGroup({}));
        yield put(actions.action.setModalCurrentCustomerGroupState(false));
        NotificationsService.success("Thêm nhóm thành công");
      } else NotificationsService.error("Thêm nhóm thất bại");
    } else {
      // sửa
      let req = yield GroupCustomerApiServices.updateCustomerGroup(
        customer_group._id,
        customer_group
      );

      if (req.code === 200) {
        yield put(actions.action.loadListCustomerGroup({}));
        yield put(actions.action.setModalCurrentCustomerGroupState(false));
        NotificationsService.success("Cập nhật thông tin nhóm thành công");
      } else NotificationsService.error("Cập nhật thông tin nhóm thất bại");
    }
  } catch (ex) {
    console.log(
      "[admin_customer_group] saga_SaveCurrentCustomerGroup Error: ",
      ex
    );

    if (customer_group._id == 0)
      NotificationsService.error("Thêm nhóm thất bại");
    else NotificationsService.error("Cập nhật thông tin nhóm thất bại");
  }
}

function* saga_RemoveCustomerGroup(action) {
  try {
    let id = action.payload.id;

    let req = yield GroupCustomerApiServices.deleteCustomerGroup(id);
    if (req.code === 200) {
      yield put(actions.action.loadListCustomerGroup({}));
      NotificationsService.success("Xóa nhóm thành công");
    } else NotificationsService.error("Xóa nhóm thất bại");
  } catch (ex) {
    console.log("[admin_customer_group] saga_RemoveCustomerGroup Error: ", ex);
    NotificationsService.error("Xóa nhóm thất bại");
  }
}

function* listen() {
  yield takeEvery(
    actions.type.LOAD_LIST_CUSTOMER_GROUP,
    saga_LoadListCustomerGroup
  );
  yield takeEvery(
    actions.type.LOAD_DETAIL_CUSTOMER_GROUP,
    saga_LoadDetailCustomerGroup
  );
  yield takeEvery(actions.type.UPDATE_PAGE_INDEX, saga_UpdatePageIndex);
  yield takeEvery(actions.type.SEARCH_CUSTOMER_GROUP, saga_SearchCustomerGroup);

  yield takeEvery(
    actions.type.SAVE_CURRENT_CUSTOMER_GROUP,
    saga_SaveCurrentCustomerGroup
  );
  yield takeEvery(actions.type.REMOVE_CUSTOMER_GROUP, saga_RemoveCustomerGroup);
}

export default function* mainSaga() {
  yield all([fork(listen)]);
}
