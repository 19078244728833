const prefix = "user_global/";

const type = {
  UPDATE_STATE: prefix + "update_state",

  // home page
  LOAD_LIST_HOME_PRODUCT: prefix + "load_list_product",
  LOAD_LIST_PRODUCT_SUCCESS: prefix + "load_list_product_success",
  LOAD_LIST_HOME_PRODUCT_FAILED: prefix + "load_list_product_failed",

  LOAD_PRODUCT_CURRENT_FLASH_SALE: prefix + "load_product_current_flash_sale",
  LOAD_PRODUCT_CURRENT_FLASH_SALE_SUCCESS:
    prefix + "load_product_current_flash_sale_success",
  LOAD_PRODUCT_CURRENT_FLASH_SALE_FAILED:
    prefix + "load_product_current_flash_sale_failed",

  LOAD_LIST_CATEGORY: prefix + "load_list_category",
  LOAD_LIST_CATEGORY_SUCCESS: prefix + "load_list_category_success",

  // detail product
  LOAD_DETAIL_PRODUCT: prefix + "load_detail_product",
  LOAD_DETAIL_PRODUCT_SUCCESS: prefix + "load_detail_product_success",
  LOAD_RELATE_PRODUCT: prefix + "load_relate_product",
  LOAD_RELATE_PRODUCT_SUCCESS: prefix + "load_relate_product_success",

  // cart
  LOAD_LIST_CART_ITEM: prefix + "load_list_cart_item",
  LOAD_LIST_CART_ITEM_SUCCESS: prefix + "load_list_cart_item_success",
  ADD_CART_ITEM: prefix + "add_cart_item",
  UPDATE_CART_ITEM: prefix + "update_cart_item",
  REMOVE_CART_ITEM: prefix + "remove_cart_item",

  GUEST_ADD_CART_ITEM: prefix + "guest_add_cart_item",
  GUEST_UPDATE_CART_ITEM: prefix + "guest_update_cart_item",
  GUEST_REMOVE_CART_ITEM: prefix + "guest_remove_cart_item",
  ASYNC_GUEST_CART: prefix + "async_guest_cart",

  GET_SHIPPING_FEE: prefix + "get_shipping_fee",
  GET_SHIPPING_FEE_SUCCESS: prefix + "get_shipping_success",
  CHECK_DISCOUNT_CODE: prefix + "check_discount_code",
  USE_DISCOUNT_CODE: prefix + "use_discount_code",
  REMOVE_DISCOUNT_CODE: prefix + "remove_discount_code",
  GET_CURRENT_VOUCHER_APPLY: prefix + "get_current_voucher_apply",
  GET_CURRENT_VOUCHER_APPLY_SUCCESS:
    prefix + "get_current_voucher_apply_success",

  GET_DISCOUNT_PRICE_OF_CART: prefix + "get_discount_price_of_cart",
  GET_DISCOUNT_PRICE_OF_CART_SUCCESS:
    prefix + "get_discount_price_of_cart_success",
  GET_DISCOUNT_PRICE_OF_CART_FAILED:
    prefix + "get_discount_price_of_cart_failed",

  // check out
  CHECK_OUT_CART: prefix + "check_out_cart",

  // address
  LOAD_LIST_ADDRESS: prefix + "load_list_address",
  LOAD_LIST_ADDRESS_SUCCESS: prefix + "load_list_address_success",
  SET_SELECT_ADDRESS: prefix + "set_select_address",

  // popular category
  LOAD_LIST_POPULAR_CATEGORY: prefix + "load_list_populate_category",
  LOAD_LIST_POPULAR_CATEGORY_SUCCESS:
    prefix + "load_list_popular_category_success",

  // level
  LOAD_LIST_LEVEL: prefix + "load_list_level",
  LOAD_LIST_LEVEL_SUCCESS: prefix + "load_list_level_success",

  // post
  LOAD_LIST_POPULAR_POST: prefix + "load_list_popular_post",
  LOAD_LIST_POPULAR_POST_SUCCESS: prefix + "load_list_popular_post_success",
};

const action = {
  updateState: (state) => {
    return {
      type: type.UPDATE_STATE,
      payload: {
        state,
      },
    };
  },

  // home page
  loadListHomeProduct: (params = {}) => {
    return {
      type: type.LOAD_LIST_HOME_PRODUCT,
      payload: {
        params,
      },
    };
  },
  loadListHomeProductSuccess: (products) => {
    return {
      type: type.LOAD_LIST_PRODUCT_SUCCESS,
      payload: {
        products,
      },
    };
  },
  loadListHomeProductFailed: () => {
    return {
      type: type.LOAD_LIST_HOME_PRODUCT_FAILED,
      payload: {},
    };
  },

  loadProductCurrentFlashSale: () => {
    return {
      type: type.LOAD_PRODUCT_CURRENT_FLASH_SALE,
      payload: {},
    };
  },
  loadProductCurrentFlashSaleSuccess: (products) => {
    return {
      type: type.LOAD_PRODUCT_CURRENT_FLASH_SALE_SUCCESS,
      payload: {
        products,
      },
    };
  },
  loadProductCurrentFlashSaleFailed: () => {
    return {
      type: type.LOAD_PRODUCT_CURRENT_FLASH_SALE_FAILED,
      payload: {},
    };
  },

  loadListCategory: () => {
    return {
      type: type.LOAD_LIST_CATEGORY,
      payload: {},
    };
  },
  loadListCategorySuccess: (categorys) => {
    return {
      type: type.LOAD_LIST_CATEGORY_SUCCESS,
      payload: {
        categorys,
      },
    };
  },

  // detail product
  loadDetailProduct: (id) => {
    return {
      type: type.LOAD_DETAIL_PRODUCT,
      payload: {
        id,
      },
    };
  },
  loadDetailProductSuccess: (product) => {
    return {
      type: type.LOAD_DETAIL_PRODUCT_SUCCESS,
      payload: {
        product,
      },
    };
  },
  loadRelateProduct: (id) => {
    return {
      type: type.LOAD_RELATE_PRODUCT,
      payload: {
        id,
      },
    };
  },
  loadRelateProductSuccess: (products) => {
    return {
      type: type.LOAD_RELATE_PRODUCT_SUCCESS,
      payload: {
        products,
      },
    };
  },

  // cart
  loadListCartItem: () => {
    return {
      type: type.LOAD_LIST_CART_ITEM,
      payload: {},
    };
  },
  loadListCartItemSuccess: (carts) => {
    return {
      type: type.LOAD_LIST_CART_ITEM_SUCCESS,
      payload: {
        carts,
      },
    };
  },
  addCartItem: (productId = "", quantity = 1) => {
    return {
      type: type.ADD_CART_ITEM,
      payload: {
        productId,
        quantity,
      },
    };
  },
  updateCartItem: (id = "", quantity = 1) => {
    return {
      type: type.UPDATE_CART_ITEM,
      payload: {
        id,
        quantity,
      },
    };
  },
  removeCartDetail: (id = "") => {
    return {
      type: type.REMOVE_CART_ITEM,
      payload: {
        id,
      },
    };
  },

  guestAddCartItem: (item = {}) => {
    return {
      type: type.GUEST_ADD_CART_ITEM,
      payload: {
        item,
      },
    };
  },
  guestUpdateCartItem: (id = "", quantity = 1) => {
    return {
      type: type.GUEST_UPDATE_CART_ITEM,
      payload: {
        id,
        quantity,
      },
    };
  },
  guestRemoveCartItem: (id = "") => {
    return {
      type: type.GUEST_REMOVE_CART_ITEM,
      payload: {
        id,
      },
    };
  },
  asyncGuestCart: () => {
    return {
      type: type.ASYNC_GUEST_CART,
      payload: {},
    };
  },

  getShippingFee: (addressId, serviceId) => {
    return {
      type: type.GET_SHIPPING_FEE,
      payload: {
        addressId,
        serviceId,
      },
    };
  },
  getShippingFeeSuccess: (fee) => {
    return {
      type: type.GET_SHIPPING_FEE_SUCCESS,
      payload: {
        fee,
      },
    };
  },
  checkDiscountCode: (code) => {
    return {
      type: type.CHECK_DISCOUNT_CODE,
      payload: {
        code,
      },
    };
  },
  useDiscountCode: (info) => {
    return {
      type: type.USE_DISCOUNT_CODE,
      payload: {
        info,
      },
    };
  },
  removeDiscountCode: () => {
    return {
      type: type.REMOVE_DISCOUNT_CODE,
      payload: {},
    };
  },
  getCurrentVoucherApply: () => {
    return {
      type: type.GET_CURRENT_VOUCHER_APPLY,
      payload: {},
    };
  },
  getCurrentVoucherApplySuccess: (voucher = {}) => {
    return {
      type: type.GET_CURRENT_VOUCHER_APPLY_SUCCESS,
      payload: {
        voucher,
      },
    };
  },

  getDiscountPriceOfCart: (voucher_code = null) => {
    return {
      type: type.GET_DISCOUNT_PRICE_OF_CART,
      payload: {
        voucher_code,
      },
    };
  },
  getDiscountPriceOfCartSuccess: (discount_price) => {
    return {
      type: type.GET_DISCOUNT_PRICE_OF_CART_SUCCESS,
      payload: {
        discount_price,
      },
    };
  },
  getDiscountPriceOfCartFailed: (error = null) => {
    return {
      type: type.GET_DISCOUNT_PRICE_OF_CART_FAILED,
      payload: {
        error,
      },
    };
  },

  // address
  loadListAddress: (params = {}) => {
    return {
      type: type.LOAD_LIST_ADDRESS,
      payload: {
        params,
      },
    };
  },
  loadListAddressSuccess: (address) => {
    return {
      type: type.LOAD_LIST_ADDRESS_SUCCESS,
      payload: {
        address,
      },
    };
  },
  setSelectAddress: (address) => {
    return {
      type: type.SET_SELECT_ADDRESS,
      payload: {
        address,
      },
    };
  },

  // check out
  checkOutCart: (
    paymentType = "COD",
    shippingType = "HAN-EXPRESS",
    point = 0,
    voucherCode = ""
  ) => {
    return {
      type: type.CHECK_OUT_CART,
      payload: {
        paymentType,
        shippingType,
        point,
        voucherCode,
      },
    };
  },

  // popular
  loadListPopularCategory: () => {
    return {
      type: type.LOAD_LIST_POPULAR_CATEGORY,
      payload: {},
    };
  },
  loadListPopularCategorySuccess: (categorys) => {
    return {
      type: type.LOAD_LIST_POPULAR_CATEGORY_SUCCESS,
      payload: {
        categorys,
      },
    };
  },

  // level
  loadListLevel: () => {
    return {
      type: type.LOAD_LIST_LEVEL,
      payload: {},
    };
  },
  loadListLevelSuccess: (levels) => {
    return {
      type: type.LOAD_LIST_LEVEL_SUCCESS,
      payload: {
        levels,
      },
    };
  },

  // post
  loadListPopularPost: () => {
    return {
      type: type.LOAD_LIST_POPULAR_POST,
      payload: {},
    };
  },
  loadListPopularPostSuccess: (posts = []) => {
    return {
      type: type.LOAD_LIST_POPULAR_POST_SUCCESS,
      payload: {
        posts,
      },
    };
  },
};

export default {
  type,
  action,
};
