import actions from "./actions";

const default_customer_group = {
    _id: 0,
    name: "",
    description: "",
    status: true,
};

const initState = {
    customer_groups: [],

    pageIndex: 1,
    pageSize: 10,
    textSearch: "",
    total: 10,
    lastTextSearch: "",

    customer_group: default_customer_group
};

const types = actions.type;

const reducer = (state = initState, action) => {
    let payload = action.payload;

    switch (action.type) {
        case types.UPDATE_STATE:
            return {
                ...state,
                ...payload.state,
            };

        // list customer group
        case types.LOAD_LIST_CUSTOMER_GROUP:
            return state;

        case types.LOAD_LIST_CUSTOMER_GROUP_SUCCESS:
            return {
                ...state,
                ...{
                    customer_groups: payload.customer_groups
                }
            }

        case types.UPDATE_PAGE_INDEX:
            return {
                ...state,
                ...{
                    pageIndex: payload.pageIndex
                }
            };

        case types.REMOVE_CUSTOMER_GROUP:
            return state;

        case types.UPDATE_TEXT_SEARCH:
            return {
                ...state,
                ...{
                    textSearch: payload.textSearch
                }
            }

        case types.SEARCH_CUSTOMER_GROUP:
            return {
                ...state,
                ...{
                    pageIndex: 1,
                    lastTextSearch: state.textSearch
                }
            };

        // detail customer group
        case types.LOAD_DETAIL_CUSTOMER_GROUP:
            return state;

        case types.LOAD_DETAIL_CUSTOMER_GROUP_SUCCESS:
            return {
                ...state,
                ...{
                    detail_customer_group: payload.customer_group
                }
            };

        case types.SET_DEFAULT_CUSTOMER_GROUP:
            return {
                ...state,
                ...{
                    customer_group: default_customer_group
                }
            };

        case types.UPDATE_CURRENT_CUSTOMER_GROUP:
            return {
                ...state,
                ...{
                    customer_group: {
                        ...state.customer_group,
                        ...payload.customer_group
                    }
                }
            };

        case types.SET_CURRENT_CUSTOMER_GROUP:
            return {
                ...state,
                ...{
                    customer_group: payload.customer_group
                }
            };

        case types.SAVE_CURRENT_CUSTOMER_GROUP:
            return state;
        case types.SET_MD_CURRENT_CUSTOMER_GROUP_STATE:
            return {
                ...state,
                ...{
                    modalCurrentCustomerGroupState: payload.state
                }
            }
        default:
            return state;
    }
};

export default reducer;
