import { all, fork, put, select, takeEvery } from "redux-saga/effects";
// api
import {
  changeAvatar,
  changePassword,
  changeUserInfo,
  checkSession,
  checkSessionAdmin,
  getUserInfo,
  login,
  loginAdmin,
} from "../../../services/api/auth";
import CustomerGroupApiServices from "../../../services/api/customer_group";
import { convertIsoDateToDatetime } from "../../../services/utils/helper";
// helper
import NotifycationServices from "../../../services/utils/notifycationHelper";
// storage
import { setLocalData } from "../../../services/utils/storageHelper";
import ValidateUserService from "../../../services/utils/validate/validate_user";
import cartActions from "../user_global/actions";
import actions from "./actions";

function* saga_Login(action) {
  try {
    const { username, password } = action.payload;

    if (!username.trim()) {
      NotifycationServices.error("Vui lòng nhập số điện thoại hoặc email");
      return;
    }
    // const validEmail = validateEmail(username.trim());
    // if (!validEmail.isValidate) {
    //     NotifycationServices.error(validEmail.message);
    //     return;
    // }

    const LoginResponse = yield login(username.trim(), password);

    if (LoginResponse.code === 200) {
      const sessionKey = LoginResponse.data.token;
      setLocalData("session_key", sessionKey);

      const userInfo = LoginResponse.data.user;

      // login success
      yield put(
        actions.action.updateState({
          session_key: sessionKey,
          isLoggedIn: true,
          isLoading: false,
          userInfo,
          current_user_info: userInfo,
        })
      );

      NotifycationServices.success("Đăng nhập thành công", "Thông báo");

      yield put(cartActions.action.asyncGuestCart());
    } else {
      NotifycationServices.error(LoginResponse.message || "", "Thông báo");
    }
  } catch (ex) {
    console.log("[Auth] Login Error : ", ex.message);

    // login error
    yield put(
      actions.action.updateState({
        session_key: null,
        isLoggedIn: false,
        userInfo: null,
        isLoading: false,
      })
    );

    if (Object.prototype.hasOwnProperty.call(ex, "message")) {
      NotifycationServices.error(ex.message);
      return;
    }

    NotifycationServices.error(
      "Tài khoản chưa chính xác",
      "Đăng nhập thất bại"
    );
  }
}

function* saga_LoginAdmin(action) {
  try {
    const { username, password } = action.payload;
    const LoginResponse = yield loginAdmin(username, password);

    if (LoginResponse.code === 200) {
      const sessionKey = LoginResponse.data.token;
      setLocalData("session_key", sessionKey);

      const userInfo = LoginResponse.data.user;

      // login success
      yield put(
        actions.action.updateState({
          session_key: sessionKey,
          isLoggedIn: true,
          isLoading: false,
          userInfo,
          current_user_info: userInfo,
        })
      );

      NotifycationServices.success("Đăng nhập thành công", "Thông báo");

      yield put(cartActions.action.asyncGuestCart());
    }
  } catch (ex) {
    console.log("[Auth] Login Error : ", ex.message);

    // login error
    yield put(
      actions.action.updateState({
        session_key: null,
        isLoggedIn: false,
        userInfo: null,
        isLoading: false,
      })
    );

    if (Object.prototype.hasOwnProperty.call(ex, "message")) {
      NotifycationServices.error(ex.message);
      return;
    }

    NotifycationServices.error(
      "Tài khoản chưa chính xác",
      "Đăng nhập thất bại"
    );
  }
}

function* saga_Logout() {
  try {
    setLocalData("session_key", null);
    NotifycationServices.success(
      "Đăng xuất thành công",
      "Tạm biệt",
      "top-center"
    );

    yield put(
      actions.action.updateState({
        session_key: null,
        isLoggedIn: false,
        userInfo: null,
        isLoading: false,
      })
    );

    yield put(
      cartActions.action.updateState({
        carts: [],
        total: 0,
      })
    );
  } catch (ex) {
    console.log("[Auth] Logout Error : ", ex.message);
  }
}

function* saga_CheckSessionKey() {
  try {
    var request = yield checkSession();

    if (request.code === 200) {
      yield put(
        actions.action.updateState({
          groupPermissions: request?.data?.groupPermissions || [],
        })
      );
    }

    if (request.code !== 200) {
      yield put(
        actions.action.updateState({
          session_key: null,
          isLoggedIn: false,
          userInfo: null,
          isLoading: false,

          showMessageChangePassword: false,
          isChangePasswordOk: false,
          groupPermissions: [],
        })
      );
      setLocalData("session_key", null);
    }
  } catch (ex) {
    console.log("[Auth] Get user info error : ", ex.message);

    if (ex.message && ex.message.code) {
      yield put(
        actions.action.updateState({
          session_key: null,
          isLoggedIn: false,
          userInfo: null,
          isLoading: false,

          showMessageChangePassword: false,
          isChangePasswordOk: false,
          groupPermissions: [],
        })
      );
      setLocalData("session_key", null);
    }
  }
}

function* saga_CheckSessionKeyAdmin() {
  try {
    var request = yield checkSessionAdmin();

    if (request.code !== 200) {
      yield put(
        actions.action.updateState({
          session_key: null,
          isLoggedIn: false,
          userInfo: null,
          isLoading: false,

          showMessageChangePassword: false,
          isChangePasswordOk: false,
        })
      );
      setLocalData("session_key", null);
    }
  } catch (ex) {
    console.log("[Auth] saga_CheckSessionKeyAdmin error : ", ex.message);

    if (ex.message && ex.message.code) {
      yield put(
        actions.action.updateState({
          session_key: null,
          isLoggedIn: false,
          userInfo: null,
          isLoading: false,

          showMessageChangePassword: false,
          isChangePasswordOk: false,
        })
      );
      setLocalData("session_key", null);
    }
  }
}

function* saga_GetUserInfo() {
  try {
    var request = yield getUserInfo();

    if (request.code === 200) {
      let userInfo = request.data;

      yield put(
        actions.action.updateState({
          isLoggedIn: true,
          userInfo: userInfo,
          current_user_info: userInfo,
          isLoading: false,

          showMessageChangePassword: false,
          isChangePasswordOk: false,
        })
      );
    } else {
      yield put(
        actions.action.updateState({
          session_key: null,
          isLoggedIn: false,
          userInfo: null,
          isLoading: false,

          showMessageChangePassword: false,
          isChangePasswordOk: false,
        })
      );
      setLocalData("session_key", null);
    }
  } catch (ex) {
    console.log("[Auth] saga_GetUserInfo error : ", ex.message);
  }
}

function* saga_ChangePassword(action) {
  try {
    let { oldPassword, newPassword, confirmPassword } = action.payload;

    // validate
    let ok = true;
    let message = "";

    oldPassword = oldPassword.trim();
    newPassword = newPassword.trim();
    confirmPassword = confirmPassword.trim();

    if (confirmPassword !== newPassword) {
      ok = false;
      message = "Xác nhận mật khẩu không chính xác";
    }

    if (newPassword === "") {
      ok = false;
      message = "Mật khẩu mới không được để trống";
    }

    if (oldPassword === "") {
      ok = false;
      message = "Mật khẩu cũ không được để trống";
    }

    if (!ok) {
      yield put(
        actions.action.updateState({
          showMessageChangePassword: true,
          isChangePasswordOk: false,
          message: message,
        })
      );

      NotifycationServices.error(message);
      return;
    }

    // call api
    try {
      yield changePassword(oldPassword, newPassword);

      yield put(
        actions.action.updateState({
          showMessageChangePassword: true,
          isChangePasswordOk: true,
          message: "Đổi mật khẩu thành công",
        })
      );
      yield put(actions.action.setModalChangePasswordAdmin(false));

      NotifycationServices.success("Đổi mật khẩu thành công");
    } catch (ex) {
      yield put(
        actions.action.updateState({
          showMessageChangePassword: true,
          isChangePasswordOk: false,
          message: ex.data,
        })
      );

      NotifycationServices.error("Đổi mật khẩu thất bại");
    }
  } catch (ex) {
    console.log("[Auth] Change password error : ", ex.message);
    NotifycationServices.error("Đổi mật khẩu thất bại");
  }
}

function* saga_SaveCurrentUserInfo(action) {
  try {
    let userInfo = yield select((state) => state.auth.current_user_info);

    let va = yield ValidateUserService.validate(userInfo);

    if (!va.isValidate) {
      NotifycationServices.error(va.message);
      return;
    }

    let isUpdated = true;
    if (userInfo.file) {
      let req = yield changeAvatar(userInfo.file);

      if (!req.code === 200) isUpdated = false;
    }

    let info = {
      fullName: userInfo.fullName,
      phone: userInfo.phone,
      sex: userInfo.sex,
      dob: convertIsoDateToDatetime(userInfo.dob),
      address: userInfo.address.trim(),
      groupCustomerId: userInfo.groupCustomerId,
    };

    let req = yield changeUserInfo(info);
    if (!req.code === 200) isUpdated = false;

    if (isUpdated)
      NotifycationServices.success("Cập nhật thông tin cá nhân thành công");
    else NotifycationServices.error("Cập nhật thông tin cá nhân thất bại");

    yield put(actions.action.getUserInfo());
  } catch (ex) {
    console.log("[Auth] saga_SaveCurrentUserInfo error : ", ex.message);
  }
}

function* saga_LoadListCustomerGroup(action) {
  try {
    let req = yield CustomerGroupApiServices.getAllListCustomerGroup();
    if (req.code === 200) {
      let items = req.data;
      yield put(actions.action.loadListCustomerGroupSuccess(items));
    }
  } catch (ex) {
    console.log("[Auth] saga_LoadListCustomerGroup error : ", ex.message);
  }
}

function* listen() {
  yield takeEvery(actions.type.LOGIN, saga_Login);
  yield takeEvery(actions.type.LOGIN_ADMIN, saga_LoginAdmin);

  yield takeEvery(actions.type.CHECK_SESSION, saga_CheckSessionKey);
  yield takeEvery(actions.type.CHECK_SESSION_ADMIN, saga_CheckSessionKeyAdmin);
  yield takeEvery(actions.type.GET_USER_INFO, saga_GetUserInfo);
  yield takeEvery(actions.type.LOGOUT, saga_Logout);
  yield takeEvery(actions.type.CHANGE_PASSWORD, saga_ChangePassword);

  yield takeEvery(
    actions.type.SAVE_CURRENT_USER_INFO,
    saga_SaveCurrentUserInfo
  );

  yield takeEvery(
    actions.type.LOAD_LIST_CUSTOMER_GROUP,
    saga_LoadListCustomerGroup
  );
}

export default function* authSaga() {
  yield all([fork(listen)]);
}
