import React, { Component } from 'react';
import './index.scss';

class ProductSkeleton extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
    }

    render() {
        return (
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12 mt-18px col-6">
                <div className="product-skelecton product-skelecton-img"></div>
                <div>
                    <div className="product-skelecton product-skelecton-text mt-8px"></div>
                    <div className="product-skelecton product-skelecton-text mt-8px"></div>
                </div>
            </div>
        );
    }
}

export default ProductSkeleton;