import ApiUtils from "../../../../services/ApiUtils";

const apiName = {
  UPDATE_HIDE_PRODUCT: "/admin/managerProduct/updateHide/",
};

export const apiUpdateHideProduct = async (productId, isHide) => {
  const res = await ApiUtils.put(
    apiName.UPDATE_HIDE_PRODUCT + productId,
    { isHide },
    {
      isAuth: true,
    }
  );
  return res.data || undefined;
};
