import React, { Component } from 'react'
import './index.scss'
import { FaSave, FaTimes } from 'react-icons/fa';
import { connect } from 'react-redux'
import Button from '../../../../components/button';
import Modal from "../../../../components/modal";
import { DatePicker, TimePicker } from 'antd';

import AdminFlashSaleActions from '../../../redux/admin_flash_sale/actions'

import moment from "moment";

class ModalAddFlashSale extends Component {
    onClickButtonCancel = () => {
        if (typeof this.props.setModalAddFlashSaleState === 'function') {
            this.props.setModalAddFlashSaleState(false)
        }
    }

    onChangeNameFlashSale = (evt) => {
        if (typeof this.props.updateNewFlashSale === 'function') {
            const nameFlashSale = evt.target.value
            this.props.updateNewFlashSale({ name: nameFlashSale })
        }
    }

    onChangeDescriptionFlashSale = (evt) => {
        if (typeof this.props.updateNewFlashSale === 'function') {
            const descFlashSale = evt.target.value
            this.props.updateNewFlashSale({ description: descFlashSale })
        }
    }

    changeTimeHandler = (dateTarget, time) => {
        const timeSelect = new Date(time);
        dateTarget.setHours(timeSelect.getHours())
        dateTarget.setMinutes(timeSelect.getMinutes())
        dateTarget.setSeconds(timeSelect.getSeconds())
        return dateTarget;
    }

    changeDateHandler = (dateTarget, date) => {
        const dateSelect = new Date(date);
        dateTarget.setFullYear(dateSelect.getFullYear())
        dateTarget.setMonth(dateSelect.getMonth())
        dateTarget.setDate(dateSelect.getDate())
        return dateTarget;
    }

    onChangeStartDate = (date) => {
        if (typeof this.props.updateNewFlashSale === 'function') {
            const dateSelect = new Date(date);
            let newFlashSale = { ...this.props.newFlashSale }
            let startTime = newFlashSale.startTime
            startTime = this.changeDateHandler(startTime, dateSelect)
            this.props.updateNewFlashSale({ startTime })
        }
    }

    onChangeStartTime = (time) => {
        if (typeof this.props.updateNewFlashSale === 'function') {
            let newFlashSale = { ...this.props.newFlashSale }
            let startTime = newFlashSale.startTime
            startTime = this.changeTimeHandler(startTime, time)
            this.props.updateNewFlashSale({ startTime })
        }
    }


    onChangeStopDate = (date) => {
        if (typeof this.props.updateNewFlashSale === 'function') {
            const dateSelect = new Date(date);
            let newFlashSale = { ...this.props.newFlashSale }
            let stopTime = newFlashSale.stopTime
            stopTime = this.changeDateHandler(stopTime, dateSelect)
            this.props.updateNewFlashSale({ stopTime })
        }
    }

    onChangeStopTime = (time) => {
        if (typeof this.props.updateNewFlashSale === 'function') {
            let newFlashSale = { ...this.props.newFlashSale }
            let stopTime = newFlashSale.stopTime
            stopTime = this.changeTimeHandler(stopTime, time)
            this.props.updateNewFlashSale({ stopTime })
        }
    }

    onClickCreateNewFlashSale = () => {
        if (typeof this.props.createNewFlashSale === 'function') {
            this.props.createNewFlashSale()
        }
    }

    render() {
        const { modalAddFlashSaleState, setModalAddFlashSaleState, newFlashSale } = this.props
        return (
            <Modal
                isOpen={modalAddFlashSaleState}
                displayCloseIcon={false}
                setIsOpen={setModalAddFlashSaleState}
            >

                <div className="AdminModalAddFlashSale">
                    <div className="AMAFS_Label">Thêm mới flash sale</div>
                    <div>
                        <label className="AMAFSI_Label">Tên flash sale</label>
                        <input
                            type="text"
                            className="AMAFSI_Value"
                            placeholder="Nhập tên flash sale"
                            value={newFlashSale.name}
                            onChange={this.onChangeNameFlashSale}
                        />
                    </div>
                    <div>
                        <label className="AMAFSI_Label">Mô tả</label>
                        <textarea
                            type="text"
                            className="AMAFSI_Value"
                            placeholder="Mô tả chiến dịch flash sale"
                            rows="3"
                            value={newFlashSale.description}
                            onChange={this.onChangeDescriptionFlashSale}
                        />
                    </div>
                    <div>
                        <label className="AMAFSI_Label">Thời gian bắt đầu</label>
                        <div>
                            <DatePicker
                                format="DD/MM/YYYY"
                                className="AMAFSI_DateTime"
                                placeholder="Chọn ngày"
                                value={moment(newFlashSale.startTime, "yyyy-MM-dd")}
                                onChange={this.onChangeStartDate}
                                clearIcon={false}
                            />
                            <TimePicker
                                placeholder="Chọn giờ"
                                value={moment(newFlashSale.startTime, "HH:mm:ss")}
                                onChange={this.onChangeStartTime}
                                clearIcon={false}
                            />
                        </div>
                    </div>
                    <div>
                        <label className="AMAFSI_Label">Thời gian kết thúc</label>
                        <div>
                            <DatePicker
                                format="DD/MM/YYYY"
                                className="AMAFSI_DateTime"
                                placeholder="Chọn ngày"
                                value={moment(newFlashSale.stopTime, "yyyy-MM-dd")}
                                onChange={this.onChangeStopDate}
                                clearIcon={false}
                            />
                            <TimePicker
                                placeholder="Chọn giờ"
                                value={moment(newFlashSale.stopTime, "HH:mm:ss")}
                                onChange={this.onChangeStopTime}
                                clearIcon={false}
                            />
                        </div>
                    </div>
                    <div className="d-flex justify-content-center AMAFSF_Action">
                        <Button
                            label="Thêm mới"
                            icon={<FaSave style={{ marginRight: '4px' }} />}
                            className="AMAFSF_Button"
                            onClick={this.onClickCreateNewFlashSale} />
                        <Button
                            label="Hủy"
                            icon={<FaTimes style={{ marginRight: '4px' }} />}
                            className="AMAFSF_Button_Cancel"
                            onClick={this.onClickButtonCancel}
                        />
                    </div>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    let _state = state.admin_flash_sale
    return {
        modalAddFlashSaleState: _state.modalAddFlashSaleState,
        newFlashSale: _state.newFlashSale
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setModalAddFlashSaleState: (state) => {
            dispatch(AdminFlashSaleActions.action.setModalAddFlashSaleState(state))
        },
        updateNewFlashSale: (newFlashSale) => {
            dispatch(AdminFlashSaleActions.action.updateNewFlashSale(newFlashSale))
        },
        createNewFlashSale: () => {
            dispatch(AdminFlashSaleActions.action.createNewFlashSale())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalAddFlashSale)