/* eslint-disable jsx-a11y/alt-text */
import { Button, Col, List, Row, Select, Spin } from "antd";
import React, { PureComponent } from "react";
import colors from "../../../../const/colors";
import { getImageUrl } from "../../../../services/api/upload";
import NotificationsService from "../../../../services/utils/notifycationHelper";
import {
  getTypeWareHouseInsertStatus,
  WareHouseInsertStatus,
  WareHouseInsertStatusArr,
} from "../../../../types/warehouse.type";
import CreateVoucherPopupChooseProduct from "../create-voucher/components/create-voucher-popup-choose-product";
import { apiGetListProducts } from "../create-voucher/model/create-voucher.service";
import {
  apiGetDetailWareHouse,
  apiGetInsertProductDetail,
  apiWareHouseInsertProduct,
} from "../warehouse-create/service/warehouse-create.sevice";
import WareHouseInsertItem from "./components/warehouse-insert-item";
import WareHouseInsertPrice from "./components/warehouse-insert-price";
import "./index.scss";

const ITEM_LOAD = 1000;

export default class WarehouseInsertProductScreen extends PureComponent {
  wareHouseId = undefined;
  wareHouseInsertId = undefined;
  tempProducts = [];

  statusInsertWareHouse = WareHouseInsertStatus.PENDING;

  paramsNav = {
    wareHouseId: undefined,
    wareHouseInsertId: undefined,
  };

  constructor(props) {
    super(props);
    this.state = {
      products: [],
      productIds: [],
      loading: true,
      page: 1,
      wareHouse: {},
      wareHouseInsert: {},
    };

    if (
      props.location &&
      props.location.search &&
      typeof props.location.search === "string"
    ) {
      this.paramsNav = {
        ...JSON.parse(
          '{"' +
            decodeURI(props.location.search.slice(1))
              .replace(/"/g, '\\"')
              .replace(/&/g, '","')
              .replace(/=/g, '":"') +
            '"}'
        ),
      };
    }

    this.wareHouseId = this.paramsNav.wareHouseId || undefined;
    this.wareHouseInsertId = this.paramsNav.wareHouseInsertId || undefined;
  }

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    if (!this.wareHouseId) {
      this.setState({ loading: false });
      return;
    }
    const wareHouse = await apiGetDetailWareHouse(this.wareHouseId);

    let wareHouseInsert = {};

    if (this.wareHouseInsertId) {
      wareHouseInsert = await apiGetInsertProductDetail(
        this.wareHouseInsertId,
        { getListProduct: true }
      );

      if (!wareHouseInsert) wareHouseInsert = {};

      if (wareHouseInsert && wareHouseInsert._id) {
        this.statusInsertWareHouse = wareHouseInsert.status;
        this.tempProducts = wareHouseInsert.products;
        this.totalProduct = wareHouseInsert.totalProduct;
      }
    }

    this.setState({
      wareHouseInsert,
      wareHouse,
      products: this.tempProducts || [],
      loading: false,
    });
  };

  onSelectProductIds = (productIds) => {
    this.setState({ loading: true, productIds, page: 1 }, () => {
      this.onGetListProducts(1);
    });
  };

  onGetListProducts = async (pageIndex) => {
    const params = {
      pageIndex,
      pageSize: ITEM_LOAD,
      inIds: this.state.productIds,
      isGetAllProduct: true,
    };

    let { products, total } = await apiGetListProducts(params);

    if (this.tempProducts.length > 0) {
      const newProducts = [];
      for (const e of products) {
        const productTemp = this.tempProducts.find((j) => j._id === e._id);
        newProducts.push({
          ...e,
          ...productTemp,
        });
      }

      products = newProducts;
    }

    this.tempProducts = products;

    this.totalProduct = total;

    this.setState({
      products,
      loading: false,
    });
  };

  onUpdateItem = (item) => {
    let products = [...this.state.products];

    const index = products.findIndex((e) => e._id === item._id);
    if (index >= 0) {
      products[index] = {
        ...products[index],
        ...item,
      };
    }

    this.tempProducts = products;

    this.setState({ products });
  };

  onSave = async () => {
    const { products, wareHouse, wareHouseInsert } = this.state;

    if (!wareHouse) return;

    if (wareHouseInsert.status === WareHouseInsertStatus.CONFIRMED) {
      this.props.history.goBack();
      return;
    }

    const productsInsert = products.filter((e) => e.qtyWareHouse);

    if (productsInsert.length === 0) {
      NotificationsService.error("Vui lòng thêm sản phẩm");
      return;
    }

    const productIds = productsInsert.map((e) => e._id);

    const params = {
      userId: wareHouse.userId,
      wareHouseId: wareHouse._id,
      priceWholesale: this.getTotalPriceProducts().priceWholesale,
      priceProduct: this.getTotalPriceProducts().priceProduct,
      products: productsInsert,
      status: this.statusInsertWareHouse,
      note: "",
      productIds,
    };

    if (wareHouseInsert && wareHouseInsert._id) {
      params.wareHouseInsertProductId = wareHouseInsert._id;
    }

    const res = await apiWareHouseInsertProduct(params);

    if (res && res._id) {
      this.props.history.goBack();
      NotificationsService.success("Cập nhập kho hàng thành công");
      return;
    }

    NotificationsService.error("Cập nhập kho hàng thất bại");
  };

  getTotalPriceProducts() {
    let priceProduct = 0;
    let priceWholesale = 0;
    let priceDiscount = 0;
    const { products } = this.state;

    for (const e of products) {
      let totalPrice = 0;

      let totalPriceLast = 0;

      if (e.qtyWareHouse) {
        totalPrice = e.price * e.qtyWareHouse;
        totalPriceLast = totalPrice;
      }

      if (e.percentProduct) {
        priceDiscount += (totalPrice * e.percentProduct) / 100;
        totalPriceLast = totalPrice - (totalPrice * e.percentProduct) / 100;
      }

      priceProduct += totalPrice;
      priceWholesale += totalPriceLast;
    }

    return {
      priceProduct,
      priceWholesale,
      priceDiscount,
    };
  }

  render() {
    const { wareHouse, loading, productIds, products, wareHouseInsert } =
      this.state;

    if (loading) {
      return <Spin size="large" />;
    }

    if (!wareHouse) return null;

    return (
      <div className="warehouse-insert">
        <div className="viewHeader">
          <p className="nameHeader">Cập nhật sản phẩm vào kho hàng</p>
          <Button
            type="primary"
            style={{
              backgroundColor: colors.primaryColor,
              borderColor: colors.primaryColor,
              fontWeight: "600",
            }}
            onClick={this.onSave}
          >
            {wareHouseInsert.status !== WareHouseInsertStatus.CONFIRMED
              ? "Lưu"
              : "Trở lại"}
          </Button>
        </div>

        <Row>
          <Col span={12}>
            <p className="nameInput">Chủ kho hàng</p>

            {wareHouse && wareHouse.userInfo ? (
              <div className="headerUser">
                <div className="MSU_User_Row">
                  <div className="MSU_ImageView">
                    <img
                      src={getImageUrl(wareHouse.userInfo.avatar)}
                      className="MSU_UserAvatar"
                    />
                  </div>
                  <div style={{ marginLeft: 10 }}>
                    <div className="MSU_UserName">
                      {wareHouse.userInfo.fullName}
                    </div>

                    <div>Mã kho hàng: {wareHouse.code}</div>

                    <div>{wareHouse.userInfo.phone}</div>
                    {wareHouse.userInfo.email ? (
                      <div>{wareHouse.userInfo.email}</div>
                    ) : null}
                  </div>
                </div>
              </div>
            ) : null}

            <p className="nameInput">Trạng thái kho hàng</p>
            <Select
              placeholder="Trạng kho hàng"
              style={{ width: "100%" }}
              defaultValue={this.statusInsertWareHouse}
              onChange={(e) => {
                this.statusInsertWareHouse = e;
              }}
              disabled={
                wareHouseInsert.status === WareHouseInsertStatus.CONFIRMED
              }
            >
              {WareHouseInsertStatusArr.map((e) => (
                <Select.Option value={e}>
                  {getTypeWareHouseInsertStatus(e).name}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={1} />
          <Col span={11}>
            <div
              style={{ flexDirection: "column", float: "right", marginTop: 15 }}
            >
              <WareHouseInsertPrice
                title="Giá bán"
                price={this.getTotalPriceProducts().priceProduct}
                color="#a04087"
              />

              <WareHouseInsertPrice
                title="Chiết khấu"
                price={this.getTotalPriceProducts().priceDiscount}
                color="#8873bb"
              />

              <WareHouseInsertPrice
                title="Giá nhập kho"
                price={this.getTotalPriceProducts().priceWholesale}
                color="orange"
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <p className="nameInput">Sản phẩm trong kho hàng</p>
            {/* Header */}
            <div style={{ flexDirection: "row" }}>
              <Button
                type="primary"
                style={{ backgroundColor: "#a04087", borderColor: "#a04087" }}
              >
                {this.totalProduct || 0} sản phẩm
              </Button>

              {wareHouseInsert.status !== WareHouseInsertStatus.CONFIRMED ? (
                <Button
                  className="ml-2"
                  type="primary"
                  onClick={() => {
                    this.refModalChooseProduct.open(productIds || []);
                  }}
                >
                  Chọn sản phẩm
                </Button>
              ) : null}
            </div>

            {/* List Product */}
            {products.length > 0 ? (
              <div>
                <List
                  grid={{ gutter: 16, column: 2 }}
                  size="large"
                  style={{
                    flexDirection: "row",
                  }}
                  dataSource={products}
                  renderItem={(item, i) => {
                    return (
                      <Col span={22}>
                        <WareHouseInsertItem
                          item={item}
                          onUpdateItem={this.onUpdateItem}
                          disabled={
                            wareHouseInsert.status ===
                            WareHouseInsertStatus.CONFIRMED
                          }
                        />
                      </Col>
                    );
                  }}
                />
              </div>
            ) : null}
          </Col>
        </Row>

        <CreateVoucherPopupChooseProduct
          ref={(ref) => (this.refModalChooseProduct = ref)}
          onSelect={this.onSelectProductIds}
          paramsFilter={{ isGetAllProduct: true }}
        />
      </div>
    );
  }
}
