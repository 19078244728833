import React, { Component } from "react";
import "./index.scss";

// react-router-dom
import { Link } from "react-router-dom";

// redux
import { connect } from "react-redux";
import authActions from "../../../../redux/auth/actions";
import AdminViewActions from "../../../../redux/admin_view/actions";

// import './topbar.scss'
// import { IoIosNotifications } from 'react-icons/io'
import { FaLock, FaSignOutAlt, FaBars } from "react-icons/fa";
import { IoIosChatboxes, IoIosNotifications } from "react-icons/io";

// component
import ModalChangePassword from "./modal_change_password";

// helper
import { getImageUrl } from "../../../../../services/api/upload";

import LogoIcon from "../../../../../resource/images/cadosa-logo.png";
import router_links from "../../../../../const/router_link";

class TopBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isDisplayInfo: false,
            isDisplayMessagePreview: false,
            isDipslayNotifycationPreview: false,

            isOpenModalChangePassword: false,
        };

        this.avatarRef = React.createRef();
        this.messageRef = React.createRef();
        this.notifycationRef = React.createRef();
    }

    componentDidMount() {
        document.addEventListener("click", this.handleClick);
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.handleClick);
    }

    handleClick = (event) => {
        const { target } = event;

        // avatar
        if (!this.avatarRef.current.contains(target))
            this.setState({ isDisplayInfo: false });
        else this.setState({ isDisplayInfo: !this.state.isDisplayInfo });

        // message
        // if (!this.messageRef.current.contains(target))
        //     this.setState({ isDisplayMessagePreview: false });
        // else
        //     this.setState({
        //         isDisplayMessagePreview: !this.state.isDisplayMessagePreview,
        //     });

        // notify cation
        // if (!this.notifycationRef.current.contains(target))
        //     this.setState({ isDipslayNotifycationPreview: false });
        // else
        //     this.setState({
        //         isDipslayNotifycationPreview: !this.state
        //             .isDipslayNotifycationPreview,
        //     });
    };

    renderTopBarInfo = () => {
        let userInfo = this.props.userInfo
            ? this.props.userInfo
            : { fullname: "", avatar: "" };
        let avatar = getImageUrl(userInfo.avatar);

        let _class = "TopbarInfo";
        if (!this.state.isDisplayInfo) _class += " hide";

        return (
            <div className={_class}>
                <div className="info">
                    <img
                        alt="avatar"
                        src={avatar}
                        className="ZAvatar"
                        onClick={(e) =>
                            this.setState({
                                isDisplayInfo: !this.state.isDisplayInfo,
                            })
                        }
                    />
                    <div className="UserName">{userInfo.fullName}</div>
                    <div
                        className="rowFunction"
                        onClick={() =>
                            this.props.setModalChangePasswordAdmin(true)
                        }
                    >
                        <FaLock className="icon" />
                        Đổi mật khẩu
                    </div>
                    <div
                        className="rowFunction"
                        onClick={() => this.props.logout()}
                    >
                        <FaSignOutAlt className="icon" />
                        Đăng xuất
                    </div>
                </div>
            </div>
        );
    };

    renderMessagePreview = () => {
        let _class = "MessagePreview";
        if (!this.state.isDisplayMessagePreview) _class += " hide";

        return (
            <div className={_class}>
                <div className="info">
                    <div className="title">Tin nhắn</div>
                    <hr />
                </div>
            </div>
        );
    };

    renderNotifycationPreview = () => {
        let _class = "NotifycationPreview";
        if (!this.state.isDipslayNotifycationPreview) _class += " hide";

        return (
            <div className={_class}>
                <div className="info">Notifycation Preview</div>
            </div>
        );
    };

    render() {
        let userInfo = this.props.userInfo
            ? this.props.userInfo
            : { fullname: "", avatar: "" };
        let avatar = getImageUrl(userInfo.avatar);

        const { modalChangePasswordAdmin } = this.props;

        return (
            <div className="AdminTopBar">
                <div className="NameApplication">
                    <Link to={router_links.A_HOME_PAGE} className="Title">
                        <img
                            style={{
                                height: 50,
                            }}
                            src={LogoIcon}
                            alt="CADOSA"
                        />
                    </Link>
                    <div className="AdminTopBar_ShowDrawerBar">
                        <FaBars
                            className="ATS_Icon"
                            onClick={() => {
                                this.props.changeSideBarDisplay();
                            }}
                        />
                    </div>
                </div>

                <div className="AvatarView">
                    {/* <div ref={this.messageRef}>
                        <span className="badge">3</span>
                        <IoIosChatboxes className="Icon" />
                    </div>
                    <div ref={this.notifycationRef}>
                        <div className="badge">1</div>
                        <IoIosNotifications className="Icon" />
                    </div> */}
                    <img
                        alt="avatar"
                        src={avatar}
                        className="Avatar"
                        ref={this.avatarRef}
                    />
                    {this.renderTopBarInfo()}
                    {/* {this.renderMessagePreview()} */}
                    {/* {this.renderNotifycationPreview()} */}
                </div>

                <ModalChangePassword
                    isOpen={modalChangePasswordAdmin}
                    setIsOpen={this.props.setModalChangePasswordAdmin}
                    displayCloseIcon={false}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.auth.isLoggedIn,
        userInfo: state.auth.userInfo ? state.auth.userInfo : null,
        modalChangePasswordAdmin: state.auth.modalChangePasswordAdmin
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => {
            dispatch(authActions.action.logout());
        },
        changeSideBarDisplay: () => {
            dispatch(AdminViewActions.action.changeSideBarDisplay());
        },
        setModalChangePasswordAdmin: (state) => {
            dispatch(authActions.action.setModalChangePasswordAdmin(state))
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
