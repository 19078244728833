import React, { Component } from "react";
import { connect } from "react-redux";
import "./index.scss";

import Modal from "../../../../components/modal";
import { FaSave, FaTimes } from "react-icons/fa";
//redux
import AdminManagerListLevelUserActions from "../../../redux/admin_manager_list_level_user/actions";

import { Select } from "antd";
import Button from "../../../../components/button";

class ModalEditLevelUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      levelBefore: [],
    };
  }

  onChangeLevelUserName = (evt) => {
    const name = evt.target.value;
    this.props.updateCurrentLevelUser({ name });
  };

  onChangeLevelUserDesc = (evt) => {
    const description = evt.target.value;
    this.props.updateCurrentLevelUser({ description });
  };

  onChangePercentDiscountPerOrder = (evt) => {
    const percentDiscountPerOrder = Number(evt.target.value);
    if (isNaN(percentDiscountPerOrder)) {
      this.props.updateCurrentLevelUser({ percentDiscountPerOrder: "" });
    }
    if (percentDiscountPerOrder >= 0 && percentDiscountPerOrder <= 100) {
      this.props.updateCurrentLevelUser({ percentDiscountPerOrder });
    }
  };

  onChangeMinimumRevenue = (evt) => {
    const minimumRevenue = parseInt(evt.target.value);
    if (isNaN(minimumRevenue)) {
      this.props.updateCurrentLevelUser({ minimumRevenue: "" });
    }
    if (minimumRevenue >= 0) {
      this.props.updateCurrentLevelUser({ minimumRevenue });
    }
  };

  onChangeMinimumDeposit = (evt) => {
    const minimumDeposit = parseInt(evt.target.value);
    if (isNaN(minimumDeposit)) {
      this.props.updateCurrentLevelUser({ minimumDeposit: "" });
    }
    if (minimumDeposit >= 0) {
      this.props.updateCurrentLevelUser({ minimumDeposit });
    }
  };

  onChangeMinimumChildren = (evt) => {
    const minimumChildren = parseInt(evt.target.value);
    if (isNaN(minimumChildren)) {
      this.props.updateCurrentLevelUser({ minimumChildren: "" });
    }
    if (minimumChildren >= 0) {
      this.props.updateCurrentLevelUser({ minimumChildren });
    }
  };

  onChangeChildrenType = (value) => {
    this.props.updateCurrentLevelUser({ childrenType: value });
  };

  onChangeStatus = (value) => {
    this.props.updateCurrentLevelUser({ status: value });
  };

  onChangeLevelBefore = (value) => {
    this.props.updateCurrentLevelUser({ levelBefore: value });
  };

  componentDidUpdate(prevProps) {
    if (this.props.currentLevelUserId !== prevProps.currentLevelUserId) {
      const { currentLevelUserId } = this.props;
      let levelBefore = [];
      for (const level of this.props.levelUsers) {
        if (level._id !== currentLevelUserId) {
          levelBefore.push(level);
        }
      }

      this.setState({ levelBefore });
    }
  }

  render() {
    const {
      mdEditLevelUserState,
      setModalEditLevelUserState,
      currentLevelUser,
      levelUsers,
      saveCurrentLevelUser,
    } = this.props;
    const { levelBefore } = this.state;
    return (
      <Modal
        isOpen={mdEditLevelUserState}
        displayCloseIcon={false}
        setIsOpen={setModalEditLevelUserState}
      >
        <div className="ModalAddLevelUser">
          <div className="MALU_Label">Chỉnh sửa cấp bậc thành viên</div>
          <div>
            <label className="MALUF_Label">Tên cấp bậc</label>
            <input
              type="text"
              className="MALUF_Value"
              value={currentLevelUser.name}
              onChange={this.onChangeLevelUserName}
              placeholder="Nhập tên cấp bậc"
            />
          </div>
          <div>
            <label className="MALUF_Label">Mô tả</label>
            <textarea
              className="MALUF_Value"
              value={currentLevelUser.description}
              onChange={this.onChangeLevelUserDesc}
              placeholder="Nhập mô tả"
            />
          </div>
          <div>
            <label className="MALUF_Label">Điểm tích luỹ</label>
            <input
              type="number"
              className="MALUF_Value"
              value={currentLevelUser.percentDiscountPerOrder}
              onChange={this.onChangePercentDiscountPerOrder}
              placeholder="Nhập số % triết khấu /đơn hàng"
            />
          </div>
          <div>
            <label className="MALUF_Label">Doanh thu</label>
            <div>
              <input
                type="number"
                className="MALUF_Value"
                value={currentLevelUser.minimumRevenue}
                onChange={this.onChangeMinimumRevenue}
                placeholder="Giá trị nhỏ nhất"
              />
            </div>
          </div>
          <div>
            <label className="MALUF_Label">Ký quỹ</label>
            <div>
              <input
                type="number"
                className="MALUF_Value"
                value={currentLevelUser.minimumDeposit}
                onChange={this.onChangeMinimumDeposit}
                placeholder="Giá trị nhỏ nhất"
              />
            </div>
          </div>
          <div>
            <label className="MALUF_Label">Loại cấp dưới phụ thuộc</label>
            <Select
              value={currentLevelUser.childrenType}
              className="MALUF_Select"
              onChange={this.onChangeChildrenType}
              placeholder="Lựa chọn loại cấp dưới"
            >
              {levelUsers.map((levelUser, index) => (
                <Select.Option key={levelUser._id} value={levelUser._id}>
                  {levelUser.name}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div>
            <label className="MALUF_Label">Cấp dưới phụ thuộc</label>
            <div>
              <input
                type="number"
                className="MALUF_Value"
                value={currentLevelUser.minimumChildren}
                onChange={this.onChangeMinimumChildren}
                placeholder="Giá trị nhỏ nhất"
              />
            </div>
          </div>
          <div>
            <label className="MALUF_Label">Cấp bậc đứng trước</label>
            <Select
              value={currentLevelUser.levelBefore}
              className="MALUF_Select"
              onChange={this.onChangeLevelBefore}
              placeholder="Lựa chọn loại cấp dưới"
            >
              {levelBefore.map((levelUser, index) => (
                <Select.Option key={levelUser._id} value={levelUser._id}>
                  {levelUser.name}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="d-flex justify-content-center MALUF_Action">
            <Button
              label="Lưu"
              icon={<FaSave style={{ marginRight: "4px" }} />}
              className="MALUF_Button"
              onClick={saveCurrentLevelUser}
            />
            <Button
              label="Hủy"
              icon={<FaTimes style={{ marginRight: "4px" }} />}
              className="MALUF_Button_Cancel"
              onClick={() => setModalEditLevelUserState(false)}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStatetoProps = (state) => {
  const _state = state.admin_manager_list_level_user;
  return {
    mdEditLevelUserState: _state.mdEditLevelUserState,
    currentLevelUser: _state.currentLevelUser,
    levelUsers: _state.levelUsers,
    currentLevelUserId: _state.currentLevelUserId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setModalEditLevelUserState: (state) => {
      dispatch(
        AdminManagerListLevelUserActions.action.setModalEditLevelUserState(
          state
        )
      );
    },
    updateCurrentLevelUser: (currentLevelUser) => {
      dispatch(
        AdminManagerListLevelUserActions.action.updateCurrentLevelUser(
          currentLevelUser
        )
      );
    },
    saveCurrentLevelUser: () => {
      dispatch(AdminManagerListLevelUserActions.action.saveCurrentLevelUser());
    },
  };
};

export default connect(mapStatetoProps, mapDispatchToProps)(ModalEditLevelUser);
