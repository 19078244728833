import { all, fork, put, select, takeEvery } from 'redux-saga/effects'
import { addNewLevelRelationship, getLevelRelationship } from '../../../services/api/level_relationship'
import { getListLevel } from '../../../services/api/level_user'
import actions from './actions'

import ValidateLevelRelationship from '../../../services/utils/validate/validate_level_relationship'
import NotificationsService from '../../../services/utils/notifycationHelper'
function* saga_LoadLevelRelationship() {
    try {
        const res = yield getLevelRelationship()
        if (res.code === 200) {
            const data = res.data
            yield put(actions.action.loadLevelRelationshipSuccess(data))
        }
    } catch(ex) {
        console.log("[admin_dashboard Saga] saga_LoadLevelRelationship Error : ", ex.message)
    }
}

function* saga_LoadListLevelUser() {
    try {
        const res = yield getListLevel()
        if (res.code === 200) {
            let levelUsers = res.data
            yield put(actions.action.loadListLevelUserSuccess(levelUsers))
        }
    } catch(ex) {
        console.log("[admin_dashboard Saga] saga_LoadListLevelUser Error : ", ex.message)
    }
}

function* saga_AddNewLevelRelationship() {
    try {
        const _state = yield select(state => state.admin_level_relationship)
        const newLevelRelationship = _state.newLevelRelationship
        const validateRes = ValidateLevelRelationship.validate(newLevelRelationship)
        if (!validateRes.isValidate) {
            NotificationsService.error(validateRes.message)
            return
        }

        const res = yield addNewLevelRelationship(newLevelRelationship) 
        if (res.code === 200) {
            NotificationsService.success('Thêm mới mối quan hệ cấp bậc thành công')
            yield put(actions.action.setModalAddLevelRelationshipState(false))
            yield put(actions.action.loadLevelRelationship())
        }
    } catch(ex) {
        console.log("[admin_dashboard Saga] saga_AddNewLevelRelationship Error : ", ex.message)
        NotificationsService.error("Thêm mới mối quan hệ cấp bậc thất bại")
    }
}

function* listen() {
    yield takeEvery(actions.type.LOAD_LEVEL_RELATIONSHIP, saga_LoadLevelRelationship)
    yield takeEvery(actions.type.LOAD_LIST_LEVEL_USER, saga_LoadListLevelUser)
    yield takeEvery(actions.type.ADD_NEW_LEVEL_RELATIONSHIP, saga_AddNewLevelRelationship)
}

export default function* mainSaga() {
    yield all([fork(listen)])
}