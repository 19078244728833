import React, { Component } from 'react';
import { connect } from 'react-redux';
import './index.scss';

import Modal from "../../../../components/modal";
import { FaSave, FaTimes } from 'react-icons/fa';
//redux 
import AdminLevelRelationshipActions from '../../../redux/admin_level_relationship/actions'



import { Select } from 'antd';
import Button from '../../../../components/button';

class ModalEditLevelUser extends Component {
    constructor(props) {
        super(props)
        this.state = {
            levelUserDestination: [],
            levelUserSource: []
        }
    }

    componentDidMount() {
        this.props.init()
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.levelUsers !== prevProps.levelUsers) {
            this.setState({
                levelUserDestination: this.props.levelUsers,
                levelUserSource: this.props.levelUsers
            })
        }
    }

    onChangeLevelUserSource = (id) => {
        let levelUserDestination = [];
        let levelUserSourcePriority = 0

        for (const levelUser of this.props.levelUsers) {
            if (id === levelUser._id) {
                levelUserSourcePriority = levelUser.priority
            }
        }

        for (const levelUser of this.props.levelUsers) {
            if (levelUser.priority < levelUserSourcePriority) {
                levelUserDestination.push(levelUser)
            }
        }

        this.props.updateNewLevelRelationship({levelSource: id})
        this.setState({ levelUserDestination })
    }

    onChangeLevelUserDestination = (id) => {
        let levelUserSourcePriority = 0
        let levelUserSource = []

        for (const levelUser of this.props.levelUsers) {
            if (id === levelUser._id) {
                levelUserSourcePriority = levelUser.priority
            }
        }

        for (const levelUser of this.props.levelUsers) {
            if (levelUser.priority > levelUserSourcePriority) {
                levelUserSource.push(levelUser)
            }
        }
        this.props.updateNewLevelRelationship({levelDestination: id})
        this.setState({ levelUserSource })
    }

    onChangePercent = (evt) => {
        const percent = parseInt(evt.target.value)
        if (isNaN(percent)) {
            this.props.updateNewLevelRelationship({ percent: '' })
        }
        if (percent >= 0 && percent <= 100) {
            this.props.updateNewLevelRelationship({ percent })
        }
    }


    render() {
        const { mdAddLevelRelationshipState, setModalAddLevelRelationshipState, newLevelRelationship, addNewLevelRelationship } = this.props;
        const { levelUserDestination, levelUserSource } = this.state
        return (
            <Modal
                isOpen={mdAddLevelRelationshipState}
                displayCloseIcon={false}
                setIsOpen={setModalAddLevelRelationshipState}
            >

                <div className="ModalAddLevelUser">
                    <div className="MALU_Label">Thêm mối quan hệ giữa các cấp bậc</div>
                    <div>
                        <label className="MALUF_Label">Loại cấp bậc thành viên</label>
                        <Select
                            value={newLevelRelationship.levelSource}
                            className="MALUF_Select"
                            onChange={this.onChangeLevelUserSource}
                        >
                            {levelUserSource.map((levelUser, index) => (
                                <Select.Option
                                    key={levelUser._id}
                                    value={levelUser._id}>{levelUser.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                    <div>
                        <label className="MALUF_Label">Loại cấp bậc thành viên phụ thuộc</label>
                        <Select
                            value={newLevelRelationship.levelDestination}
                            className="MALUF_Select"
                            onChange={this.onChangeLevelUserDestination}
                        >
                            {levelUserDestination.map((levelUser, index) => (
                                <Select.Option
                                    key={levelUser._id}
                                    value={levelUser._id}>{levelUser.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                    <div>
                        <label className="MALUF_Label">Hoa hồng (%)</label>
                        <input
                            type="number"
                            className="MALUF_Value"
                            onChange={this.onChangePercent}
                            value={newLevelRelationship.percent}
                            placeholder="Nhập số % hoa hồng"
                        />
                    </div>
                    <div className="d-flex justify-content-center MALUF_Action">
                        <Button
                            label="Lưu"
                            icon={<FaSave style={{ marginRight: '4px' }} />}
                            className="MALUF_Button"
                            onClick={addNewLevelRelationship}
                        />
                        <Button
                            label="Hủy"
                            icon={<FaTimes style={{ marginRight: '4px' }} />}
                            className="MALUF_Button_Cancel"
                            onClick={() => setModalAddLevelRelationshipState(false)}
                        />
                    </div>
                </div>
            </Modal>
        )
    }
}

const mapStatetoProps = (state) => {
    const _state = state.admin_level_relationship
    return {
        mdAddLevelRelationshipState: _state.mdAddLevelRelationshipState,
        levelUsers: _state.levelUsers,
        newLevelRelationship: _state.newLevelRelationship
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        init: () => {
            dispatch(AdminLevelRelationshipActions.action.loadListLevelUser())
        },
        setModalAddLevelRelationshipState: (state) => {
            dispatch(AdminLevelRelationshipActions.action.setModalAddLevelRelationshipState(state))
        },
        updateNewLevelRelationship: (newLevelRelationship) => {
            dispatch(AdminLevelRelationshipActions.action.updateNewLevelRelationship(newLevelRelationship))
        },
        addNewLevelRelationship: () => {
            dispatch(AdminLevelRelationshipActions.action.addNewLevelRelationship())
        }
    }
}

export default connect(mapStatetoProps, mapDispatchToProps)(ModalEditLevelUser)