import React, { Component } from 'react';
import { connect } from 'react-redux';
import Pagination from '../../../../../../components/paginations/paginations';
import "./index.scss"

// redux
import UserProfileHistoryVoucherAction from '../../../../../redux/user_profile_history_voucher/actions';
import { convertIsoDateToString, numberDisplayThousand } from '../../../../../../services/utils/helper';
import Button from '../../../../../../components/button';
import { FaAngleLeft } from 'react-icons/fa';


class HistoryVoucherDetail extends Component {
    componentDidMount() {
        let id = this.props.match.params.id ? this.props.match.params.id : "";
        this.props.loadListOrderApplyVoucher({ id })
    }

    renderProducts = (productsDiscount) => {
        let products = [];
        for (const product of productsDiscount) {
            products.push(product.name)
        }

        return <td>
            {products.join(' + ')}
        </td>
    }

    render() {
        const { orderApplyVouchers, pageSize, pageIndex, total } = this.props;
        return (
            <div className="history-voucher">
                <div className="d-flex">
                    <Button
                    className="btn-back"
                    label="Quay lại"
                    icon={<FaAngleLeft style={{ marginRight: 4 }} />}
                    onClick={this.props.history.goBack}
                />
                </div>
                
                <div className="history-voucher-header">Lịch sử sử dụng mã giảm giá</div>
                <div>
                    <table className="table-history-table">
                        <thead>
                            <tr>
                                <th>Mã đơn hàng</th>
                                <th>Ngày áp dụng</th>
                                <th>Số tiền giảm giá</th>
                                <th>Sản phẩm được giảm giá</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orderApplyVouchers.map(order => (
                                <tr key={order.orderId}>
                                    <td>{order.orderId}</td>
                                    <td>{convertIsoDateToString(order.createdAt)}</td>
                                    <td>{numberDisplayThousand(order.totalPriceDiscount)}</td>
                                    {this.renderProducts(order.productsDiscount)}
                                </tr>
                            ))}

                        </tbody>
                    </table>
                </div>
                <div>
                    <Pagination
                        onChange={(index) => {
                            this.props.updatePageIndex(index)
                        }}
                        pageSize={pageSize}
                        pageIndex={pageIndex}
                        total={total}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const _state = state.user_profile_history_voucher
    return {
        orderApplyVouchers: _state.orderApplyVouchers,
        pageIndex: _state.pageDetailIndex,
        pageSize: _state.pageDetailSize,
        total: _state.pageDetailTotal
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        loadListOrderApplyVoucher: (params) => {
            dispatch(UserProfileHistoryVoucherAction.action.loadListOrderApplyVoucher(params))
        },
        updatePageIndex: (index) => {
            dispatch(UserProfileHistoryVoucherAction.action.updatePageDetailPageIndex(index))
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HistoryVoucherDetail);