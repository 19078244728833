import React, { Component } from "react";

import "./index.scss";

// redux
import { connect } from "react-redux";
import AdminCreateOrderActions from "../../../../redux/admin_create_order/actions";

// router
import { Link } from 'react-router-dom'

// sub component
import ListProducts from "./list_product";
import ModalSelectAddress from "./modal_select_address";
import ModalSelectProduct from "./modal_select_product";
import ModalSelectUser from "./modal_select_user";

// component
import { FaEllipsisH, FaTimes, FaCheck, FaTimesCircle } from "react-icons/fa";
import { Select, Radio, Spin } from "antd";

// shipping, payment
import ListShippingType from "../../../../../const/shipping_type";
import ListPaymentType, {
    AdminListPaymentTypes,
} from "../../../../../const/payment_type";

// helper
import { numberDisplayThousand } from "../../../../../services/utils/helper";
import router_links from "../../../../../const/router_link";

class CreateOrderPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            discount_code: "",
        };
    }

    // helper
    calculateTotal = (
        total = 0,
        shipping_info = {},
        discount_info = {},
        order = {}
    ) => {
        try {
            if (discount_info)
                total = total - (total / 100) * discount_info.discount;
        } catch (ex) {}

        try {
            if (order.paymentMethod !== "DIRECT" && shipping_info)
                total = total + shipping_info.total_price;
        } catch (ex) {}

        return total;
    };

    // life cycle
    componentDidMount = () => {
        this.props.init();
    };

    // render
    render() {
        // const discount_info = {
        //     discount: 50,
        //     code: "MUNG_KHAI_TRUONG",
        //     description: "Mã khuyến mại nhân dịp khai trương cửa hàng",
        // };

        const {
            shipping_info,
            discount_info,
            select_user,
            select_address,
            order,
        } = this.props;

        let total = this.props.total;
        total = this.calculateTotal(total, shipping_info, discount_info, order);

        return (
            <div className="CreateOrderPage">
                <div className="COP_Title">Tạo mới đơn hàng</div>
                <div className="COP_RowInfo">
                    <div className="COP_Row_Title">Người dùng</div>
                    <div className="COP_Row_Content">
                        {select_user
                            ? `${select_user.fullName} (${select_user._id})`
                            : "Chưa có người dùng nào được chọn"}
                        <div
                            className="COP_ROW_Action"
                            onClick={() => {
                                this.props.setModalSelectUserState(true);
                            }}
                        >
                            Chọn
                        </div>
                    </div>
                </div>

                {order.paymentMethod !== "DIRECT" && (
                    <div className="COP_RowInfo">
                        <div className="COP_Row_Title">Địa chỉ giao hàng</div>
                        <div className="COP_Row_Content">
                            {select_address
                                ? `${select_address.fullAddress}, ${select_address.wardName}, ${select_address.districtName}, ${select_address.provinceName}`
                                : "Chưa có địa chỉ nào được chọn"}
                            <div
                                className="COP_ROW_Action"
                                onClick={() => {
                                    this.props.setModalSelectAddressState(true);
                                }}
                            >
                                Chọn
                            </div>
                        </div>
                    </div>
                )}

                <div
                    className="COP_RowInfo"
                    style={{
                        alignItems: "flex-start",
                    }}
                >
                    <div
                        className="COP_Row_Title"
                        style={{
                            marginTop: 10,
                        }}
                    >
                        Danh sách sản phẩm
                    </div>
                    <div className="COP_Row_Content">
                        <ListProducts />
                    </div>
                </div>
                {order.paymentMethod !== "DIRECT" && (
                    <div className="COP_RowInfo">
                        <div className="COP_Row_Title">
                            Phương thức giao hàng
                        </div>
                        <div className="COP_Row_Content">
                            <Radio.Group
                                value={order.serviceId}
                                onChange={(e) => {
                                    this.props.updateCurrentOrderInfo({
                                        serviceId: e.target.value,
                                    });
                                    this.props.getShippingInfo();
                                }}
                            >
                                {ListShippingType.map((payment, index) => {
                                    return (
                                        <Radio value={payment.type}>
                                            {payment.name}
                                        </Radio>
                                    );
                                })}
                            </Radio.Group>
                        </div>
                    </div>
                )}
                {order.paymentMethod !== "DIRECT" && (
                    <div className="COP_RowInfo">
                        <div className="COP_Row_Title">Phí vận chuyển</div>
                        <div className="COP_Row_Content">
                            {this.props.is_loading_shipping_info ? (
                                <Spin />
                            ) : (
                                <>
                                    {shipping_info !== null
                                        ? `${numberDisplayThousand(
                                              shipping_info.total_price
                                          )}đ`
                                        : "Không xác định"}
                                </>
                            )}
                        </div>
                    </div>
                )}
                <div className="COP_RowInfo">
                    <div className="COP_Row_Title">Phương thức thanh toán</div>
                    <div className="COP_Row_Content">
                        <Radio.Group
                            value={order.paymentMethod}
                            onChange={(e) => {
                                this.props.updateCurrentOrderInfo({
                                    paymentMethod: e.target.value,
                                });
                                if (e.target.value !== "DIRECT")
                                    this.props.getShippingInfo();
                            }}
                        >
                            {AdminListPaymentTypes.map((payment, index) => {
                                return (
                                    <Radio value={payment.type}>
                                        {payment.name}
                                    </Radio>
                                );
                            })}
                        </Radio.Group>
                    </div>
                </div>
                <div className="COP_RowInfo">
                    <div className="COP_Row_Title">Mã khuyến mại</div>
                    <div className="COP_Row_Content">
                        {discount_info === null ? (
                            <>
                                <input
                                    className="COP_Row_DiscountCode"
                                    value={this.state.discount_code}
                                    onChange={(e) => {
                                        this.setState({
                                            discount_code: e.target.value,
                                        });
                                    }}
                                />
                                <div
                                    className="COP_ROW_Action"
                                    style={{
                                        backgroundColor: "#00b33c",
                                        color: "white",
                                    }}
                                    onClick={() => {
                                        this.props.getDiscountInfo(
                                            this.state.discount_code
                                        );
                                    }}
                                >
                                    Áp dụng
                                </div>
                            </>
                        ) : (
                            <div className="UCP_DiscountItem">
                                <div className="UCP_DiscountItem_PercentCol">
                                    {`${discount_info.discount}%`}

                                    <div className="UCP_Discount_Circle_Top" />
                                    <div className="UCP_Discount_Circle_Bottom" />
                                </div>
                                <div className="UCP_DiscountItem_InfoCol">
                                    <div className="UCP_DiscoutItem_Code">
                                        {discount_info.code}
                                    </div>
                                    <div className="UCP_Discountitem_Description">
                                        {discount_info.description}
                                    </div>
                                </div>
                                <div className="UCP_DiscountItem_Action">
                                    <FaTimes
                                        className="UCP_DiscountItem_Icon"
                                        onClick={() => {
                                            this.props.setDefaultDiscountInfo();
                                            this.setState({
                                                discount_code: "",
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="COP_RowInfo">
                    <div className="COP_Row_Title">Thành tiền</div>
                    <div className="COP_Row_Content">
                        {`${numberDisplayThousand(total)}đ`}
                    </div>
                </div>
                <div className="COP_Action">
                    <div
                        className="COP_Action_Btn"
                        style={{
                            backgroundColor: "#00b33c",
                            color: "white",
                        }}
                        onClick={() => {
                            this.props.saveCurrentOrder()
                        }}
                    >
                        <FaCheck className="COP_Row_Action_Icon" />
                        Tạo đơn hàng
                    </div>
                    <Link
                        to={router_links.A_LIST_ORDER}
                        className="COP_Action_Btn"
                    >
                        <FaTimesCircle className="COP_Row_Action_Icon" />
                        Huỷ
                    </Link>
                </div>
                <ModalSelectAddress />
                <ModalSelectProduct />
                <ModalSelectUser />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const _state = state.admin_create_order;

    return {
        select_user: _state.select_user,
        select_address: _state.select_address,
        order: _state.order,
        discount_info: _state.discount_info,
        shipping_info: _state.shipping_info,
        is_loading_shipping_info: _state.is_loading_shipping_info,
        total: _state.total,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        init: () => {
            dispatch(
                AdminCreateOrderActions.action.setDefaultCurrentOrderInfo()
            );
            dispatch(AdminCreateOrderActions.action.loadListProduct());
            dispatch(AdminCreateOrderActions.action.loadListUser());
        },

        // modal
        setModalSelectProductState: (state) => {
            dispatch(
                AdminCreateOrderActions.action.setModalSelectProductState(state)
            );
        },
        setModalSelectUserState: (state) => {
            dispatch(
                AdminCreateOrderActions.action.setModalSelectUserState(state)
            );
        },
        setModalSelectAddressState: (state) => {
            dispatch(
                AdminCreateOrderActions.action.setModalSelectAddressState(state)
            );
        },
        updateCurrentOrderInfo: (info) => {
            dispatch(
                AdminCreateOrderActions.action.updateCurrentOrderInfo(info)
            );
        },

        getDiscountInfo: (code = "") => {
            dispatch(AdminCreateOrderActions.action.getDiscountInfo(code));
        },
        setDefaultDiscountInfo: () => {
            dispatch(AdminCreateOrderActions.action.setDefaultDiscountInfo());
        },
        getShippingInfo: () => {
            dispatch(AdminCreateOrderActions.action.getShippingInfo());
        },

        saveCurrentOrder: () => {
            dispatch(AdminCreateOrderActions.action.saveCurrentOrder())
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrderPage);
