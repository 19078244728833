import createApiServices from "./createApiServices";

const api = createApiServices();

export const getLevelRelationship = () => {
    return api.makeAuthRequest({
        url: `levelRelationship`,
        method: "GET",
    });
};

export const addNewLevelRelationship = (newLevelRelationship) => {
    return api.makeAuthRequest({
        url: `admin/managerLevelRelationship/create`,
        method: 'POST',
        data: newLevelRelationship
    })
}