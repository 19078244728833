// Theme
window.theme = {};

// Theme Common Functions
window.theme.fn = {

  getOptions: function(opts) {

    if (typeof(opts) == 'object') {

      return opts;

    } else if (typeof(opts) == 'string') {

      try {
        return JSON.parse(opts.replace(/'/g,'"').replace(';',''));
      } catch(e) {
        return {};
      }

    } else {

      return {};

    }

  }

};

// Countdown
(function(theme, $) {

	theme = theme || {};

	var instanceName = '__countdown';

	var PluginCountdown = function($el, opts) {
		return this.initialize($el, opts);
	};

	PluginCountdown.defaults = {
		date: '2020/06/10 12:00:00',
		textDay: 'DAY',
		textHour: '',
		textMin: '',
		textSec: '',
		uppercase: true,
		numberClass: '',
		wrapperClass: '',
		insertHTMLbefore: '',
		insertHTMLafter: ''
	};

	PluginCountdown.prototype = {
		initialize: function($el, opts) {
			if ($el.data(instanceName)) {
				return this;
			}

			this.$el = $el;

			this
				.setData()
				.setOptions(opts)
				.build();

			return this;
		},

		setData: function() {
			this.$el.data(instanceName, this);

			return this;
		},

		setOptions: function(opts) {
			this.options = $.extend(true, {}, PluginCountdown.defaults, opts, {
				wrapper: this.$el
			});

			return this;
		},

		build: function() {
			if (!($.isFunction($.fn.countTo))) {
				return this;
			}

			var self = this,
				$el = this.options.wrapper,
				numberClass = ( self.options.numberClass ) ? ' ' + self.options.numberClass : '',
				wrapperClass = ( self.options.wrapperClass ) ? ' ' + self.options.wrapperClass : '';

			if( self.options.uppercase ) {
				$el.countdown(self.options.date).on('update.countdown', function(event) {
					var $this = $(this).html(event.strftime(self.options.insertHTMLbefore
						+ '<span class="days'+ wrapperClass +'"><span class="'+ numberClass +'">%D</span> '+ self.options.textDay +'<div class="d-inline text-uppercase">%!d,</div></span> '
						+ '<span class="hours'+ wrapperClass +'"><span class="'+ numberClass +'">%H:</span> '+ self.options.textHour +'</span> '
						+ '<span class="minutes'+ wrapperClass +'"><span class="'+ numberClass +'">%M:</span> '+ self.options.textMin +'</span> '
						+ '<span class="seconds'+ wrapperClass +'"><span class="'+ numberClass +'">%S</span> '+ self.options.textSec +'</span> '
						+ self.options.insertHTMLafter
					));
				});
			} else {
				$el.countdown(self.options.date).on('update.countdown', function(event) {
					var $this = $(this).html(event.strftime(self.options.insertHTMLbefore
						+ '<span class="days'+ wrapperClass +'"><span class="'+ numberClass +'">%D</span> '+ self.options.textDay +'%!d</span> '
						+ '<span class="hours'+ wrapperClass +'"><span class="'+ numberClass +'">%H</span> '+ self.options.textHour +'</span> '
						+ '<span class="minutes'+ wrapperClass +'"><span class="'+ numberClass +'">%M</span> '+ self.options.textMin +'</span> '
						+ '<span class="seconds'+ wrapperClass +'"><span class="'+ numberClass +'">%S</span> '+ self.options.textSec +'</span> '
						+ self.options.insertHTMLafter
					));
				});
			}

			return this;
		}
	};

	// expose to scope
	// $.extend(theme, {
	// 	PluginCountdown: PluginCountdown
	// });

	// jquery plugin
	// $.fn.themePluginCountdown = function(opts) {
	// 	return this.map(function() {
	// 		var $this = $(this);

	// 		if ($this.data(instanceName)) {
	// 			return $this.data(instanceName);
	// 		} else {
	// 			return new PluginCountdown($this, opts);
	// 		}

	// 	});
	// }

}).apply(this, [window.theme]);