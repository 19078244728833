import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { DatePicker, InputNumber, Select, Switch } from "antd";
import moment from "moment";
import queryString from "query-string";
import React, { Component } from "react";
import { FaSave, FaTimes, FaUpload, FaUsps } from "react-icons/fa";
// redux
import { connect } from "react-redux";
// query
import { withRouter } from "react-router-dom";
import { MAX_LENGTH_PHONE } from "../../../../const/phone_consts";
import { regexNumber } from "../../../../const/regex_consts";
import RouterLinks from "../../../../const/router_link";
// helper
import { getImageUrl } from "../../../../services/api/upload";
import NotificationsService from "../../../../services/utils/notifycationHelper";
import { isValidFullName } from "../../../../services/utils/validate/validateFullName";
import UserActions from "../../../redux/admin_listuser/actions";
// scss
import "./index.scss";
import ModalUpPointAndXuInUser from "./modal-up-point-and-xu";

class AdminUserDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
    };
  }

  // handle
  handleImageChange(e) {
    e.preventDefault();

    for (let index = 0; index < e.target.files.length; index++) {
      let file = e.target.files[index];
      let reader = new FileReader();

      reader.readAsDataURL(file);

      if (file.type.indexOf("image/") === 0) {
        reader.onloadend = () => {
          let avatar = this.props.user.avatar;

          avatar = reader.result;

          this.props.updateCurrentUser({
            avatar,
            file,
          });
        };
      } else {
        NotificationsService.error("File không đúng định dạng");
      }
    }
  }

  // event
  _eventClickButtonCancel = () => {
    this.props.history.push(RouterLinks.A_LIST_USER);
  };

  _eventClickButtonSave = () => {
    // validate
    let user = this.props;

    this.props.saveCurrentUser();
  };

  // life cycle
  componentDidMount = () => {
    this.props.init();

    let params = queryString.parse(this.props.location.search);
    let id = params.id ? params.id : "";

    if (id !== "") {
      this.props.setDefaultCurrentUser();
      this.props.loadUserDetail(id);
    } else {
      this.props.setDefaultCurrentUser();
    }

    this.setState({ id });
  };

  onChangeLinkAffiliateParent = (evt) => {
    const linkAffiliateParent = evt.target.value;
    this.props.updateCurrentUser({
      linkAffiliateParent,
    });
  };

  onChangeGroupCustomer = (groupId) => {
    this.props.updateCurrentUser({
      groupCustomerId: groupId,
    });
  };

  onChangePhoneNumber = (evt) => {
    const phone = evt.target.value;
    if (regexNumber.test(phone) && phone.length <= MAX_LENGTH_PHONE) {
      this.props.updateCurrentUser({
        phone,
      });
    }
  };

  onChangeFullName = (evt) => {
    const fullName = evt.target.value;
    if (isValidFullName(fullName) || fullName === "") {
      this.props.updateCurrentUser({
        fullName,
      });
    }
  };

  render() {
    let { user, groups, levels, groupCustomers } = this.props;

    const isAddNewUser = user._id === 0;

    return (
      <div className="AdminUserDetail">
        <div className="APD_MainContent">
          <div className="APD_InfoPanel">
            <div className="APD_InfoRow">
              <div className="APD_InfoTitle">Ảnh đại diện</div>
              <div
                className="row"
                style={{
                  padding: 0,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <img
                  src={user ? getImageUrl(user.avatar) : ""}
                  className="APD_ProductImage"
                  alt="image"
                  style={{
                    height: 70,
                    width: 70,
                    borderRadius: 35,
                    border: "1px solid #e2e2e2",
                    display:
                      user && user.avatar && user.avatar !== ""
                        ? "block"
                        : "none",
                  }}
                />

                <input
                  type="file"
                  name="file"
                  id="file"
                  class="hide"
                  onChange={(e) => this.handleImageChange(e)}
                />
                <label for="file">
                  <div className="APD_BtnUploadAvatar">
                    <FaUpload className="Icon" />
                    Chọn
                  </div>
                </label>
              </div>
            </div>

            <div className="APD_InfoRow">
              <div className="APD_InfoTitle">Tên người dùng</div>
              <div className="APD_InfoContent">
                <input
                  className="APD_Value"
                  type="text"
                  value={user.fullName}
                  onChange={this.onChangeFullName}
                />
              </div>
            </div>
            <div className="APD_InfoRow">
              <div className="APD_InfoTitle">Email</div>
              <div className="APD_InfoContent">
                {isAddNewUser ? (
                  <input
                    className="APD_Value"
                    type="text"
                    value={user.email}
                    onChange={(e) => {
                      this.props.updateCurrentUser({
                        email: e.target.value,
                        username: e.target.value,
                      });
                    }}
                  />
                ) : (
                  user.email
                )}
              </div>
            </div>

            {isAddNewUser && (
              <div className="APD_InfoRow">
                <div className="APD_InfoTitle">Mật khẩu</div>
                <div className="APD_InfoContent">
                  <input
                    className="APD_Value"
                    type="password"
                    value={user.password}
                    onChange={(e) => {
                      this.props.updateCurrentUser({
                        password: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            )}

            <div className="APD_InfoRow">
              <div className="APD_InfoTitle">Số điện thoại</div>
              <div className="APD_InfoContent">
                {isAddNewUser ? (
                  <input
                    className="APD_Value"
                    type="text"
                    value={user.phone}
                    onChange={this.onChangePhoneNumber}
                  />
                ) : (
                  user.phone
                )}
              </div>
            </div>

            <div className="APD_InfoRow">
              <div className="APD_InfoTitle">Mã người giới thiệu</div>
              <div className="APD_InfoContent">
                <input
                  className="APD_Value"
                  type="text"
                  value={user.linkAffiliateParent}
                  onChange={this.onChangeLinkAffiliateParent}
                />
                {/* {isAddNewUser ? (
                  <input
                    className="APD_Value"
                    type="text"
                    value={user.linkAffiliateParent}
                    onChange={this.onChangeLinkAffiliateParent}
                  />
                ) : (
                  user.linkAffiliateParent
                )} */}
              </div>
            </div>

            <div className="APD_InfoRow">
              <div className="APD_InfoTitle">Ngày sinh</div>
              <div className="APD_InfoContent">
                <DatePicker
                  format={"DD/MM/YYYY"}
                  value={moment(new Date(user.dob), "YYYY-MM-DD h:mm:ss a")}
                  allowClear={false}
                  onChange={(date, dateString) => {
                    this.props.updateCurrentUser({
                      dob: date,
                    });
                  }}
                />
              </div>
            </div>

            <div className="APD_InfoRow">
              <div className="APD_InfoTitle">Địa chỉ</div>
              <div className="APD_InfoContent">
                <textarea
                  className="APD_Value"
                  type="text"
                  style={{ minHeight: 100 }}
                  value={user.address}
                  onChange={(e) => {
                    this.props.updateCurrentUser({
                      address: e.target.value,
                    });
                  }}
                />
              </div>
            </div>

            <div className="APD_InfoRow">
              <div className="APD_InfoTitle">Số tiền ký quỹ</div>
              <div className="APD_InfoContent">
                <InputNumber
                  value={Number(user.deposit || 0)}
                  min={0}
                  formatter={(value) =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  style={{
                    width: "100%",
                    backgroundColor: "#f2f2f2",
                    borderRadius: 3,
                    borderWidth: 0,
                    paddingTop: 3,
                    paddingBottom: 3,
                  }}
                  onChange={(deposit) => {
                    this.props.updateCurrentUser({
                      deposit,
                    });
                  }}
                />
              </div>
            </div>

            <div className="APD_InfoRow">
              <div className="APD_InfoTitle">Nợ tiền ký quỹ</div>
              <div className="APD_InfoContent">
                {user._id ? (
                  <Switch
                    defaultChecked={user.statusDebtDeposit}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    style={{ marginRight: 3 }}
                    onChange={(statusDebtDeposit) => {
                      this.props.updateCurrentUser({
                        statusDebtDeposit,
                      });
                    }}
                  />
                ) : null}
                <span>
                  Số tiền nợ ký quỹ này sẽ được trừ vào tiền hoa hồng hàng tháng
                </span>
              </div>
            </div>

            {user.statusDebtDeposit ? (
              <div className="APD_InfoRow">
                <div className="APD_InfoTitle">Số tiền nợ ký quỹ</div>
                <div className="APD_InfoContent">
                  <InputNumber
                    value={Number(user.debtDeposit || 0)}
                    min={0}
                    formatter={(value) =>
                      `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    style={{
                      width: "100%",
                      backgroundColor: "#f2f2f2",
                      borderRadius: 3,
                      borderWidth: 0,
                      paddingTop: 3,
                      paddingBottom: 3,
                    }}
                    onChange={(debtDeposit) => {
                      this.props.updateCurrentUser({
                        debtDeposit,
                      });
                    }}
                  />
                </div>
              </div>
            ) : null}

            <div className="APD_InfoRow">
              <div className="APD_InfoTitle">Giới tính</div>
              <div className="APD_InfoContent">
                <Select
                  value={user.sex}
                  onChange={(value) => {
                    this.props.updateCurrentUser({
                      sex: value,
                    });
                  }}
                >
                  <Select.Option value={0}>Nữ</Select.Option>
                  <Select.Option value={1}>Nam</Select.Option>
                </Select>
              </div>
            </div>

            <div className="APD_InfoRow">
              <div className="APD_InfoTitle">Loại người dùng</div>
              <div className="APD_InfoContent">
                <Select
                  value={user.groupId}
                  onChange={(value) => {
                    this.props.updateCurrentUser({
                      groupId: value,
                    });
                  }}
                  style={{
                    minWidth: 150,
                  }}
                >
                  {groups.map((group, index) => {
                    return (
                      <Select.Option value={group._id} key={index}>
                        {group.description}
                      </Select.Option>
                    );
                  })}
                </Select>
              </div>
            </div>

            <div className="APD_InfoRow">
              <div className="APD_InfoTitle">Cấp độ người dùng</div>
              <div className="APD_InfoContent">
                <Select
                  value={user.levelId}
                  onChange={(value) => {
                    this.props.updateCurrentUser({
                      levelId: value,
                    });
                  }}
                  style={{
                    minWidth: 150,
                  }}
                >
                  {levels.map((level, index) => {
                    return (
                      <Select.Option value={level._id} key={index}>
                        {level.description}
                      </Select.Option>
                    );
                  })}
                </Select>
              </div>
            </div>

            <div className="APD_InfoRow">
              <div className="APD_InfoTitle">Nhóm khách hàng</div>
              <div className="APD_InfoContent">
                <Select
                  value={user.groupCustomerId}
                  style={{
                    minWidth: 150,
                  }}
                  onChange={this.onChangeGroupCustomer}
                >
                  {groupCustomers.map((group, index) => {
                    return (
                      <Select.Option key={group._id} value={group._id}>
                        {group.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </div>
            </div>
          </div>
        </div>

        <div className="APD_ActionPanel">
          <div
            className="APD_ActionButton"
            onClick={() => {
              this.refModalUpPointAndxu.open();
            }}
            style={{ backgroundColor: "orange" }}
          >
            <FaUsps className="APD_ActionButtonIcon" />
            Cộng xu, điểm
          </div>

          <div
            className="APD_ActionButton"
            onClick={() => {
              this._eventClickButtonSave();
            }}
          >
            <FaSave className="APD_ActionButtonIcon" />
            Lưu
          </div>
          <div
            className="APD_ActionButton"
            style={{ backgroundColor: "orange" }}
            onClick={() => {
              this._eventClickButtonCancel();
            }}
          >
            <FaTimes className="APD_ActionButtonIcon" />
            Huỷ
          </div>
        </div>

        <ModalUpPointAndXuInUser
          user={user}
          ref={(ref) => (this.refModalUpPointAndxu = ref)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.admin_listuser.user,
    levels: state.admin_listuser.levels,
    groups: state.admin_listuser.groups,
    groupCustomers: state.admin_listuser.groupCustomers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    init: () => {
      dispatch(UserActions.action.loadListGroupUser());
      dispatch(UserActions.action.loadListLevelUser());
      dispatch(UserActions.action.loadListGroupCustomers());
    },
    loadUserDetail: (id) => {
      dispatch(UserActions.action.loadUserDetail(id));
    },
    updateCurrentUser: (user) => {
      dispatch(UserActions.action.updateCurrentUser(user));
    },
    saveCurrentUser: () => {
      dispatch(UserActions.action.saveCurrentUser());
    },
    setDefaultCurrentUser: () => {
      dispatch(UserActions.action.setDefaultCurrentUser());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AdminUserDetail));
