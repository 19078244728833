/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { Component } from 'react'

// scss
import './modal_confirm_action.scss'

// component
import Modal from '../modal'
import PropTypes from 'prop-types'

// icons
import { FaCheck, FaTimesCircle } from 'react-icons/fa'

// image
import AlertImage from '../../resource/images/alert.png'

class ModalConfirmAction extends Component {

    render() {
        const { text, onOk, onCancel, title } = this.props

        return (

            <Modal
                {
                    ...this.props
                }
            >
                <div className="ModalConfirmAction">
                    <div className="ImagePanel">
                        <img
                            className="image"
                            src={AlertImage}
                            alt="image"
                        />
                    </div>
                    <div className="QuesionPanel">
                        <div className="title">
                            {title ? title : 'Xác nhận'}
                        </div>
                        {
                            text
                        }
                    </div>

                    <div className="ControlPanel">
                        <div
                            className="Button"
                            onClick={() => onCancel()}
                            style={{
                                backgroundColor: 'gray'
                            }}
                        >
                            <FaTimesCircle className="icon" />
                            Huỷ
                        </div>

                        <div
                            className="Button"
                            onClick={() => onOk()}
                        >
                            <FaCheck className="icon" />
                            Xác nhận
                        </div>
                    </div>

                </div>
            </Modal>
        )
    }

}

ModalConfirmAction.defaultProps = {

}

ModalConfirmAction.propTypes = {
    isOpen: PropTypes.bool,
    style: PropTypes.object,
    setIsOpen: PropTypes.func,

    text: PropTypes.string,
    onOk: PropTypes.func,
    onCancel: PropTypes.func
}


export default ModalConfirmAction