import actions from "./actions";

const default_address = {
    _id: null,
    name: "",
    phone: "",
    provinceCode: null,
    districtCode: null,
    wardCode: null,
    fullAddress: "",
    isDefault: false,
    note: "",
};

const initState = {
    // home
    list_address: [],
    detail_address: default_address,
    pageSize: 5,
    pageIndex: 1,
    total: 0,

    provines: [],
    districts: [],
    wards: []
};

const reducer = (state = initState, action) => {
    let payload = action.payload;

    switch (action.type) {
        case actions.type.UPDATE_STATE:
            return {
                ...state,
                ...action.payload.state
            }

        // list- address
        case actions.type.LOAD_LIST_ADDRESS:
            return state 

        case actions.type.LOAD_LIST_ADDRESS_SUCCESS:
            return {
                ...state,
                ...{
                    list_address: payload.address
                }
            }

        case actions.type.UPDATE_PAGE_INDEX:
            return {
                ...state,
                ...{
                    pageIndex: payload.pageIndex
                }
            }

        case actions.type.SET_DEFAULT_ADDRESS:
            return {
                ...state,
                ...{
                    detail_address: default_address
                }
            }

        case actions.type.SET_ADDRESS:
            return {
                ...state,
                ...{
                    detail_address: payload.address
                }
            }

        case actions.type.UPDATE_CURRENT_ADDRESS:
            return {
                ...state,
                ...{
                    detail_address: {
                        ...state.detail_address,
                        ...payload.address
                    }
                }
            }

        case actions.type.SAVE_CURRENT_ADDRESS:
            return state 

        case actions.type.REMOVE_ADDRESS:
            return state

        // address detail
        case actions.type.LOAD_DETAIL_ADDRESS:
            return state 

        case actions.type.LOAD_DETAIL_ADDRESS_SUCCESS:
            return {
                detail_address: payload.address
            }

        case actions.type.LOAD_LIST_PROVINE:
            return {
                ...state,
                ...{
                    provines: []
                }
            } 

        case actions.type.LOAD_LIST_PROVINE_SUCCESS:
            return {
                ...state,
                ...{
                    provines: payload.provines
                }
            }

        case actions.type.LOAD_LIST_DISTRICT:
            return {
                ...state,
                ...{
                    districts: []
                }
            } 

        case actions.type.LOAD_LIST_DISTRICT_SUCCESS:
            return {
                ...state,
                ...{
                    districts: payload.districts
                }
            }

        case actions.type.LOAD_LIST_WARD:
            return {
                ...state,
                ...{
                    wards: []
                }
            }

        case actions.type.LOAD_LIST_WARD_SUCCESS:
            return {
                ...state,
                ...{
                    wards: payload.wards
                }
            }

        default:
            return state;
    }
};

export default reducer;
