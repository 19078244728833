import { DatePicker, TimePicker } from "antd";
import moment from "moment";
import React from "react";
import { FaSave, FaTimes } from "react-icons/fa";
import Button from "../../../../components/button";
import Modal from "../../../../components/modal";
import RichTextBoxEditor from "../../../../components/richTextBoxEditor";
import { isEmpty } from "../../../../helpers/text.helper";
import NotificationsService from "../../../../services/utils/notifycationHelper";
import "./index.scss";
import { apiPostNotifySystem } from "./notify-system.service";

export default class ModalAddNotifySystem extends React.PureComponent {
  title = "";
  content = "";
  contentLong = "";
  dateSelect = "";
  timeSelect = "";

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  open = () => {
    this.setState({ isOpen: true });
  };

  onClose = () => {
    this.setState({ isOpen: false });
  };

  onChangeValue = (key) => (value) => {
    this[key] = value.target.value;
  };

  onChangeStartDate = (date) => {
    this.dateSelect = moment(date).format("YYYY/MM/DD");
  };

  onChangeStartTime = (time) => {
    this.timeSelect = moment(time).format("HH:mm");
  };

  onAdd = async () => {
    const params = {
      title: this.title,
      content: this.content,
      contentLong: this.contentLong,
    };

    if (isEmpty(params.title)) {
      NotificationsService.error("Tên thông báo không được để trống");
      return;
    }

    if (isEmpty(params.content)) {
      NotificationsService.error("Nội dung ngắn không được để trống");
      return;
    }

    if (isEmpty(params.contentLong)) {
      NotificationsService.error("Nội dung không được để trống");
      return;
    }

    if (this.dateSelect && this.timeSelect) {
      params.timeSend =
        moment(
          `${this.dateSelect} ${this.timeSelect}`,
          "YYYY/MM/DD HH:mm"
        ).unix() * 1000;
    }

    if (params.timeSend && params.timeSend < Date.now()) {
      NotificationsService.error(
        "Thời gian gửi thông báo phải lớn hơn thời gian hiện tại"
      );
      return;
    }

    const res = await apiPostNotifySystem(params);

    if (res.code === 200) {
      this.onClose();
      NotificationsService.success("Thêm mới thông báo hệ thống thành công");
    } else {
      NotificationsService.error("Thêm mới thông báo hệ thống thất bại");
    }
  };

  render() {
    const { isOpen } = this.state;

    if (!this.state.isOpen) return null;

    return (
      <Modal
        isOpen={isOpen}
        displayCloseIcon={false}
        setIsOpen={this.onClose}
        modalStyle={{
          width: "50%",
          maxHeight: "70%",
        }}
      >
        <div className="ModalAddLevelUser">
          <div className="MALU_Label">Thêm mới thông báo hệ thống</div>
          <div>
            <label className="MALUF_Label">Tên thông báo</label>
            <input
              type="text"
              className="MALUF_Value"
              // value={newLevelUser.name}
              onChange={this.onChangeValue("title")}
              placeholder="Nhập tên thông báo (tối đa 30 ký tự)"
              maxLength={30}
            />
          </div>
          <div>
            <label className="MALUF_Label">Nội dung ngắn</label>
            <input
              type="text"
              className="MALUF_Value"
              // value={newLevelUser.name}
              onChange={this.onChangeValue("content")}
              placeholder="Nhập nội dung (tối đa 50 ký tự)"
              maxLength={50}
            />
          </div>
          <div>
            <label className="MALUF_Label">Nội dung dài</label>

            <RichTextBoxEditor
              // value={post.content}
              onChange={(val) => {
                this.contentLong = val;
              }}
            />

            {/* <textarea
              className="MALUF_Value"
              // value={newLevelUser.description}
              onChange={this.onChangeValue("contentLong")}
              placeholder="Nhập nội dung"
              maxLength={500}
            /> */}
          </div>

          {/* <div>
            <label className="MALUF_Label">Hẹn giờ gửi</label>
            <div>
              <DatePicker
                format="DD/MM/YYYY"
                className="AMAFSI_DateTime"
                placeholder="Chọn ngày"
                // value={moment(newFlashSale.startTime, "yyyy-MM-dd")}
                onChange={this.onChangeStartDate}
                clearIcon={false}
              />
              <TimePicker
                placeholder="Chọn giờ"
                // value={moment(newFlashSale.startTime, "HH:mm:ss")}
                onChange={this.onChangeStartTime}
                clearIcon={false}
                format="HH:mm"
              />
            </div>
          </div> */}

          <div className="d-flex justify-content-center MALUF_Action">
            <Button
              label="Thêm"
              icon={<FaSave style={{ marginRight: "4px" }} />}
              className="MALUF_Button"
              onClick={this.onAdd}
            />
            <Button
              label="Hủy"
              icon={<FaTimes style={{ marginRight: "4px" }} />}
              className="MALUF_Button_Cancel"
              onClick={this.onClose}
            />
          </div>
        </div>
      </Modal>
    );
  }
}
