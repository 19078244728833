import { Button } from "antd";
import React, { PureComponent } from "react";
import { numberDisplayThousand } from "../../../../../services/utils/helper";

const WIDTH_TITLE_PRICE = 100;
const WIDTH_VALUE_PRICE = 175;

export default class WareHouseInsertPrice extends PureComponent {
  render() {
    const { color, price, title } = this.props;
    return (
      <p>
        <Button
          type="primary"
          style={{
            backgroundColor: color || "#8873bb",
            borderColor: color || "#8873bb",
            flex: 1,
            textAlign: "right",
            fontSize: 15,
            fontWeight: "600",
            height: 40,
          }}
        >
          <span style={{ width: WIDTH_TITLE_PRICE, textAlign: "left" }}>
            {title}
          </span>
          {": "}
          <span style={{ width: WIDTH_VALUE_PRICE, textAlign: "right" }}>
            {`${numberDisplayThousand(price || 0)} đ`}
          </span>
        </Button>
      </p>
    );
  }
}
