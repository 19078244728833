import React, { Component } from 'react'
import { connect } from 'react-redux'

import './index.scss'
// redux
import AdminLevelRelationshipActions from '../../../redux/admin_level_relationship/actions'
import { numberDisplayThousand } from '../../../../services/utils/helper'
import Button from '../../../../components/button'
import { FaAngleLeft, FaPlusCircle } from 'react-icons/fa'
import ModalAddLevelRelationship from './modal_add_level_relationship'

class ManagerLevelRelationship extends Component {

    componentDidMount() {
        this.props.init()
    }

    renderColumnNames = () => {
        const { levelRelationship } = this.props
        if (levelRelationship.columnNames) {
            let elements = levelRelationship.columnNames.data.map((column) => (
                <td>{column}</td>
            ))

            elements.unshift(<td>{levelRelationship.columnNames.title}</td>)
            return elements
        }
    }

    renderRevenue = () => {
        const { levelRelationship } = this.props
        if (levelRelationship.revenue) {
            let elements = levelRelationship.revenue.data.map((column) => (
                <td>{numberDisplayThousand(column)}</td>
            ))

            elements.unshift(<td>{levelRelationship.revenue.title}</td>)
            return elements
        }
    }

    renderDeposit = () => {
        const { levelRelationship } = this.props
        if (levelRelationship.deposit) {
            let elements = levelRelationship.deposit.data.map((column) => (
                <td>{numberDisplayThousand(column)}</td>
            ))

            elements.unshift(<td>{levelRelationship.deposit.title}</td>)
            return elements
        }
    }

    renderDiscount = () => {
        const { levelRelationship } = this.props
        if (levelRelationship.discount) {
            let elements = levelRelationship.discount.data.map((column) => (
                <td>{column}{column ? '%': ''}</td>
            ))

            elements.unshift(<td>{levelRelationship.discount.title}</td>)
            return elements
        }
    }

    renderChildren = () => {
        const { levelRelationship } = this.props
        if (levelRelationship.children) {
            let elements = levelRelationship.children.data.map((column) => (
                <td>{column.minimumChildren} {column.childrenName && `(${column.childrenName})`}</td>
            ))

            elements.unshift(<td>{levelRelationship.children.title}</td>)
            return elements
        }
    }

    renderLevelRelationshipTable = () => {
        const { levelRelationship } = this.props
        if (levelRelationship.levelRelationshipTable) {
            let elements = levelRelationship.levelRelationshipTable.map((relationship) => (
                (relationship &&
                <tr>
                    <td>{relationship.name}</td>
                    {relationship.percent.map((column) => (
                        <td>{column}{column ? '%': ''}</td>
                    ))}
                </tr>)
            ))

            return elements
        }
    }

    render() {
        return (
            <div className="ManagerLevelRelationship">
                <div className="d-flex MLRS_Actions">
                    <Button
                        label="Quay lại"
                        icon={<FaAngleLeft style={{ marginRight: '4px' }} />}
                        className="MLR_ButtonBack"
                        onClick={this.props.history.goBack}
                    />
                </div>
                <div className="MLRS_title">Chi tiết tiêu chuẩn và quyền lợi</div>
                <div className="d-flex MLRS_Actions">
                    <Button
                        label="Tạo mối quan hệ"
                        icon={<FaPlusCircle style={{ marginRight: '4px' }} />}
                        className="MLR_AddButton"
                        onClick={() => this.props.setModalAddLevelRelationshipState(true)}
                    />
                </div>
                
                <table className="CDS_Table MLRS_table">
                    <tbody>
                        <tr>
                            {this.renderColumnNames()}
                        </tr>
                        <tr>
                            {this.renderRevenue()}
                        </tr>
                        <tr>
                            {this.renderDeposit()}
                        </tr>
                        <tr>
                            {this.renderDiscount()}
                        </tr>
                        <tr>
                            {this.renderChildren()}
                        </tr>
                            {this.renderLevelRelationshipTable()}
                    </tbody>
                </table>

                <ModalAddLevelRelationship />
            </div>
        )
    }
}

const mapStateToProps = state => {
    const _state = state.admin_level_relationship
    return {
        levelRelationship: _state.levelRelationship
    }
}

const mapDispatchToProps = dispatch => {
    return {
        init: () => {
            dispatch(AdminLevelRelationshipActions.action.loadLevelRelationship())
        },
        setModalAddLevelRelationshipState: (state) => {
            dispatch(AdminLevelRelationshipActions.action.setModalAddLevelRelationshipState(state))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagerLevelRelationship)