const prefix = 'user_product_hot_discount/'

const type = {
    UPDATE_STATE: prefix + 'update_state',

    // home page
    LOAD_LIST_HOT_PRODUCT: prefix + 'load_list_hot_product',
    LOAD_LIST_HOT_PRODUCT_SUCCESS: prefix + 'load_list_hot_product_success',
    LOAD_LIST_HOT_PRODUCT_FAILED: prefix + 'load_list_hot_product_failed',

    UPDATE_PAGE_INDEX: prefix + 'update_page_index',

    // bestsell
    LOAD_LIST_PRODUCT_BEST_SELL: prefix + 'load_list_product_best_sell',
    LOAD_LIST_PRODUCT_BEST_SELL_SUCCESS: prefix + 'load_list_product_best_sell_success',
    LOAD_LIST_PRODUCT_BEST_SELL_FAILED: prefix + 'load_list_product_best_sell_failed',

    UPDATE_PAGE_INDEX_BEST_SELL: prefix + 'update_page_index_best_sell'
}   

const action = {
    updateState: state => {
        return {
            type: type.UPDATE_STATE,
            payload: {
                state
            }
        }
    },

    loadListHotProduct: (params = {}) => {
        return {
            type: type.LOAD_LIST_HOT_PRODUCT,
            payload: {
                params
            }
        }
    },
    loadListHotProductSuccess: products => {
        return {
            type: type.LOAD_LIST_HOT_PRODUCT_SUCCESS,
            payload: {
                products
            }
        }
    },
    loadListHotProductFailed: () => {
        return {
            type: type.LOAD_LIST_HOT_PRODUCT_FAILED,
            payload: {}
        }
    },

    updatePageIndex: pageIndex => {
        return {
            type: type.UPDATE_PAGE_INDEX,
            payload: {
                pageIndex
            }
        }
    },

    // bestsell 
    loadListProductBestSell: (params = {}) => {
        return {
            type: type.LOAD_LIST_PRODUCT_BEST_SELL,
            payload: {
                params
            }
        }
    },
    loadListProductBestSellSuccess: products => {
        return {
            type: type.LOAD_LIST_PRODUCT_BEST_SELL_SUCCESS,
            payload: {
                products
            }
        }
    },
    loadListProductBestSellFailed: () => {
        return {
            type: type.LOAD_LIST_PRODUCT_BEST_SELL_FAILED,
            payload: {}
        }
    },
    updatePageIndexBestSell: pageIndex => {
        return {
            type: type.UPDATE_PAGE_INDEX_BEST_SELL,
            payload: {
                pageIndex
            }
        }
    }
}

export default {
    type,
    action
}