const checkIsNumber = a => {
    try {
        let z = parseInt(a)
        return true
    }
    catch (ex) {
        return false
    }
}

const validate = (entity) => {
    // function
    const errorResult = (message) => {
        return {
            isValidate: false,
            message,
        };
    };

    // validate
    if (entity.name === null || entity.name.trim() === "")
        return errorResult("Tên không được để trống");

    if (entity.code === null || entity.code === "")
        return errorResult("Mã sản phẩm không được để trống");

    if (!checkIsNumber(entity.price) || entity.price <= 0)
        return errorResult("Giá phải là số nguyên dương");

    if (!checkIsNumber(entity.discount) || entity.discount < 0)
        return errorResult("Khuyến mại phải là số nguyên");

    if (entity.isValueAddedProduct && (!checkIsNumber(entity.point) || entity.point <= 0))
            return errorResult("Điểm thưởng phải là số nguyên dương");

    if (!checkIsNumber(entity.weight) || entity.weight <= 0)
        return errorResult("Khối lượng phải là số nguyên dương");

    if (entity.productPriceUnit === null)
        return errorResult("Đơn vị tính giá sản phẩm không để trống");



    return {
        isValidate: true,
        message: "success",
    };
};

export default {
    validate,
};
