import { Radio, Spin } from "antd";
import React, { Component } from "react";
import {
  FaMapMarkerAlt,
  FaMoneyBillAlt,
  FaPlus,
  FaShippingFast,
  FaTimes,
} from "react-icons/fa";
// redux
import { connect } from "react-redux";
// react router dom
import { Link } from "react-router-dom";
// component
import Modal from "../../../../components/modal";
import ListPaymentType from "../../../../const/payment_type";
import { productUnit } from "../../../../const/product_unit";
import { regexDecimal, regexNumber } from "../../../../const/regex_consts";
import Router_Links from "../../../../const/router_link";
// shipping, payment
import ListShippingType from "../../../../const/shipping_type";
import { getImageUrl } from "../../../../services/api/upload";
import { numberDisplayThousand } from "../../../../services/utils/helper";
import NotificationsService from "../../../../services/utils/notifycationHelper";
import AuthActions from "../../../redux/auth/actions";
import UserGlobalActions from "../../../redux/user_global/actions";
import UserProfileAction from "../../../redux/user_profile_address/actions";
// scss
import "./index.scss";
import PopupSelectVoucher from "./popup-select-voucher";

class UserCartPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalSelectAddressState: false,

      shipping_type: ListShippingType[0].type,
      payment_type: ListPaymentType[0].type,
      discount_code: "",

      point: 0,
      point_apply: 0,

      voucherInfo: "",
    };
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.select_address !== this.props.select_address) {
      if (nextProps.select_address && nextProps.select_address._id)
        this.props.getShippingFee(
          nextProps.select_address._id,
          this.state.shipping_type
        );
    }
  };

  componentDidMount = () => {
    this.props.getUserInfo();
    this.props.loadListAddress();
    this.props.getCurrentVoucherApply();

    if (this.props.select_address && this.props.select_address._id)
      this.props.getShippingFee(
        this.props.select_address._id,
        this.state.shipping_type
      );
  };

  onChangeQuantity = (evt, cartItem) => {
    const value = evt.target.value;
    const isNumber = regexNumber.test(value);
    const isDecimal = regexDecimal.test(value);
    const isWholePart = /^\d+(\.)$/.test(value);

    if (
      cartItem.product.productUnit.name === productUnit.KG &&
      (isNumber || isDecimal || isWholePart)
    ) {
      this.props.updateCartItem(cartItem._id, value);
    } else if (isNumber) {
      this.props.updateCartItem(cartItem._id, value);
    }
  };

  // render
  renderCartEmpty = () => {
    let empty_image =
      "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTEkWHW8CS62g5BiTbE5ls6Rfc7N_Q8dJl5RA&usqp=CAU";

    return (
      <div className="UCP_CartEmpty">
        <img className="UCP_ImageEmpty" alt="image" src={empty_image} />
        <div className="UCP_Empty_Label">
          Không có sản phẩm nào trong giỏ hàng của bạn
        </div>
        <Link to={Router_Links.U_HOME_PAGE} className="UCP_Empty_Button">
          Tiếp tục mua sắm
        </Link>
      </div>
    );
  };

  renderSelectAddress = () => {
    let select_address = this.props.select_address
      ? this.props.select_address
      : null;

    return (
      <div className="UCP_AddressShipping">
        <div className="UCP_AddressShipping_Title">
          <FaMapMarkerAlt className="UCP_Icon" />
          Địa chỉ nhận hàng
        </div>

        {select_address && (
          <div>
            <div className="USA_Address">
              <div className="USA_Address_Name">{select_address.name}</div>
              <div className="USA_Address_RowInfo">
                <span className="USA_Address_Label">Địa chỉ:</span>
                {`${select_address.fullAddress}, ${select_address.wardName}, ${select_address.districtName}, ${select_address.provinceName}`}
              </div>
              <div className="USA_Address_RowInfo">
                <span className="USA_Address_Label">Điện thoại:</span>
                {select_address.phone}
              </div>
            </div>
            <div className="USA_Address_Action">
              <Link
                to={`${Router_Links.U_PROFILE_DETAIL_ADDRESS_SHIPPING}/${select_address._id}`}
                className="USA_Action_Edit"
              >
                Chỉnh sửa
              </Link>
            </div>
          </div>
        )}

        {select_address && (
          <div className="UCP_Action">
            <div
              className="UCP_ChangeAddress"
              onClick={() => {
                this.setState({
                  modalSelectAddressState: true,
                });
              }}
            >
              Thay đổi
            </div>
          </div>
        )}

        {!select_address && <div>Vui lòng thêm địa chỉ nhận hàng</div>}
        {!select_address && (
          <Link
            className="UCP_Action"
            to={Router_Links.U_PROFILE_CREATE_ADDRESS_SHIPPING}
          >
            <div className="UCP_ChangeAddress">Thêm địa chỉ mới</div>
          </Link>
        )}
      </div>
    );
  };

  renderModalSelectAddressShipping = () => {
    let list_address = this.props.list_address ? this.props.list_address : [];

    return (
      <Modal
        isOpen={this.state.modalSelectAddressState}
        setIsOpen={(value) => {
          this.setState({
            modalSelectAddressState: value,
          });
        }}
        displayCloseIcon={false}
      >
        <div className="UCP_ModalSelectAddress" style={{ padding: 0 }}>
          {list_address.map((address_item, index) => {
            return (
              <div className="USA_Address">
                <div className="USA_Address_Name">{address_item.name}</div>
                <div className="USA_Address_RowInfo">
                  <span className="USA_Address_Label">Địa chỉ:</span>
                  {`${address_item.fullAddress}, ${address_item.wardName}, ${address_item.districtName}, ${address_item.provinceName}`}
                </div>
                <div className="USA_Address_RowInfo">
                  <span className="USA_Address_Label">Điện thoại:</span>
                  {address_item.phone}
                </div>
                <div className="USA_Address_Action">
                  <div
                    onClick={() => {
                      this.setState({
                        modalSelectAddressState: false,
                      });
                      this.props.setSelectAddress(address_item);
                    }}
                    className="USA_Action_Edit"
                  >
                    Chọn
                  </div>
                </div>
              </div>
            );
          })}

          <Link
            className="USA_ButtonZ"
            to={Router_Links.U_PROFILE_CREATE_ADDRESS_SHIPPING}
            onClick={() => {
              this.props.setDefaultAddress();
            }}
          >
            <FaPlus className="USA_Icon" />
            Thêm địa chỉ mới
          </Link>
        </div>
      </Modal>
    );
  };

  renderCartCheckOut = () => {
    const { voucherInfo } = this.state;

    let total = this.props.total ? this.props.total : 0;
    let carts = this.props.carts ? this.props.carts : [];

    let totalPoint = 0;

    carts.forEach((e) => {
      totalPoint += (e.product.point || 0) * e.quantity;
    });

    let shipping_fee = this.props.shipping_fee ? this.props.shipping_fee : 0;

    let discount_price = voucherInfo?.discountPrice || 0;

    let loading_ship = false;
    if (shipping_fee < 0) {
      loading_ship = true;
      shipping_fee = 0;
    }
    let total_price =
      total + shipping_fee - discount_price - this.state.point_apply * 1000;
    let max_price = total - discount_price;

    if (total_price < 0) total_price = 0;

    let userInfo = this.props.userInfo ? this.props.userInfo : { point: 0 };

    return (
      <div className="UCP_CartCheckOut">
        <div className="UCP_ListCartItems">
          <div className="UCP_Title">
            Giỏ hàng
            {/* <span className="UCP_Subtile">
                        ({num_products} sản phẩm)
                    </span> */}
          </div>
          {carts.map((cart_item, index) => {
            let product = cart_item.product
              ? cart_item.product
              : { thumbnail: "" };
            let _total = cart_item.quantity * cart_item.price;
            let unit_name = product.productUnit
              ? product.productUnit.description
              : "";

            return (
              <div
                className="UCP_CartItem"
                style={{
                  borderBottom:
                    index === carts.length - 1 ? "none" : "1px solid #e2e2e2",
                }}
              >
                <Link to={`${Router_Links.U_DETAIL_PRODUCT}/${product._id}`}>
                  <img
                    className="UCP_CartItem_Image"
                    alt="Image"
                    src={getImageUrl(product.thumbnail)}
                  />
                </Link>
                <div className="UCP_CartItem_Info">
                  <div className="UCP_CartItem_NamePanel">
                    <Link
                      to={`${Router_Links.U_DETAIL_PRODUCT}/${product._id}`}
                      className="UCP_CartItem_Name"
                    >
                      {product.name}
                    </Link>
                    <div className="UCP_CartItem_Price">
                      {numberDisplayThousand(cart_item.price)} đ/ {unit_name}
                      {product.isValueAddedProduct &&
                        ` - Sản phẩm tích điểm (${product.point} điểm)`}
                    </div>
                    <div className="UCP_CartItem_ActionPanel">
                      <div
                        className="UCP_CartItem_Action"
                        onClick={() => {
                          this.props.removeCartDetail(cart_item._id);
                        }}
                      >
                        Xoá
                      </div>
                    </div>
                    <div
                      className="cart-item-remove"
                      onClick={() => {
                        this.props.removeCartDetail(cart_item._id);
                      }}
                    >
                      <FaTimes />
                    </div>
                  </div>
                  <div className="UCP_CartItem_Total">
                    {numberDisplayThousand(_total)}đ
                  </div>
                  <div className="UCP_CartItem_Quantity">
                    <div
                      className="UCP_CartItem_ButtonDown"
                      onClick={() => {
                        if (cart_item.quantity > 1)
                          this.props.updateCartItem(
                            cart_item._id,
                            cart_item.quantity - 1
                          );
                        else this.props.removeCartDetail(cart_item._id);
                      }}
                    >
                      -
                    </div>

                    <input
                      value={cart_item.quantity}
                      className="UCP_CartItem_QuantityTextBox"
                      onChange={(evt) => this.onChangeQuantity(evt, cart_item)}
                    />

                    <div
                      className="UCP_CartItem_ButtonUp"
                      onClick={() => {
                        this.props.updateCartItem(
                          cart_item._id,
                          cart_item.quantity + 1
                        );
                      }}
                    >
                      +
                    </div>
                  </div>
                  <div className="cart-item-price-mobile">
                    {numberDisplayThousand(cart_item.price)} đ/ {unit_name}
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="UCP_CheckOutInfo">
          <div className="UCP_PointPanel">
            <div className="UCPP_Row">
              <div
                className="UCPP_Row_Title"
                style={{
                  marginBottom: 0,
                }}
              >
                Ví cadoxu
              </div>
              <div className="UCPP_RowContent">{`${userInfo.cadoxu} Xu`}</div>
            </div>
          </div>

          <div className="UCP_PointPanel">
            <div className="UCPP_Row">
              <div className="UCPP_Row_Title">Số dư điểm:</div>
              <div className="UCPP_RowContent">{` ${userInfo.point} điểm`}</div>
            </div>

            <div className="UCPP_Row">
              <div className="UCPP_Row_Title">Sử dụng điểm:</div>
              <div className="UCPP_RowContent">
                <input
                  type="number"
                  className="UCPP_RowText"
                  value={this.state.point}
                  onChange={(e) => {
                    let value = e.target.value;

                    try {
                      value = parseInt(value);

                      if (value < 0) return;

                      this.setState({
                        point: value,
                      });
                    } catch (ex) {}
                  }}
                />
              </div>
            </div>

            <div className="UCPP_Row">
              <div
                className="UCPP_Button"
                onClick={() => {
                  let { point } = { ...this.state };

                  if (point <= 0) {
                    NotificationsService.error(
                      "Số điểm áp dụng phải là số nguyên dương"
                    );
                    return;
                  }

                  if (point > userInfo.point) {
                    NotificationsService.error(
                      "Số điểm sử dụng phải không lớn hơn số dư điểm"
                    );
                    return;
                  }

                  // Số điểm khách hàng trả có thể lớn hơn 10 điểm
                  if (point * 1000 > max_price + 10000) {
                    point = parseInt(max_price / 1000) + 10000;
                    NotificationsService.success(
                      `Số điểm tối đa sử dụng được là ${point}`
                    );
                    this.setState({
                      point_apply: point,
                    });
                    return;
                  }

                  if (isNaN(point)) {
                    point = 0;
                  }

                  NotificationsService.success("Áp dụng thành công");
                  this.setState({
                    point_apply: point,
                    point: point,
                  });
                }}
              >
                Áp dụng
              </div>
            </div>
          </div>
          <div className="UCP_DiscountCodePanel">
            <div className="UCP_DiscountLabel">Mã giảm giá</div>

            {voucherInfo ? (
              <div className="UCP_DiscountItem" style={{ marginBottom: 15 }}>
                <div className="UCP_DiscountItem_PercentCol">
                  {`${voucherInfo.discount}%`}

                  <div className="UCP_Discount_Circle_Top" />
                  <div className="UCP_Discount_Circle_Bottom" />
                </div>
                <div className="UCP_DiscountItem_InfoCol">
                  <div className="UCP_DiscoutItem_Code">{voucherInfo.code}</div>
                  <div className="UCP_Discountitem_Description">
                    {voucherInfo.description}
                  </div>
                </div>
                <div className="UCP_DiscountItem_Action"></div>
              </div>
            ) : null}

            <div className="UCP_DiscountPanelContent">
              <div
                className="UCP_ApplyDiscountCode"
                onClick={() => {
                  this.refPopupVoucher?.onShow?.({
                    productIds: carts.map((e) => e.product._id),
                  });
                }}
              >
                Chọn mã giảm giá
              </div>
            </div>
          </div>

          <div className="UCP_InfoPanel">
            <div className="UCP_TotalLabel">
              <div className="UCP_LeftAlignText">Số điểm thưởng</div>
              <div className="UCP_RightAlignText">
                {numberDisplayThousand(totalPoint)} điểm
              </div>
            </div>

            <div className="UCP_TotalLabel">
              <div className="UCP_LeftAlignText">Tổng tiền hàng</div>
              <div className="UCP_RightAlignText">
                {numberDisplayThousand(total)} đ
              </div>
            </div>
            <div className="UCP_TotalLabel">
              <div className="UCP_LeftAlignText">Phí vận chuyển</div>
              <div className="UCP_RightAlignText">
                {loading_ship ? (
                  <Spin />
                ) : (
                  `${numberDisplayThousand(shipping_fee)} đ`
                )}
              </div>
            </div>
            <div className="UCP_TotalLabel">
              <div className="UCP_LeftAlignText">
                {`Số xu sử dụng (${
                  voucherInfo?.discountPrice / 1000 || 0
                } CADOXU)`}
              </div>
              <div className="UCP_RightAlignText">
                <React.Fragment>
                  {voucherInfo?.discountPrice > 0
                    ? `-${numberDisplayThousand(voucherInfo.discountPrice)} đ`
                    : `${numberDisplayThousand(discount_price)} đ`}
                </React.Fragment>
              </div>
            </div>
            <div className="UCP_TotalLabel">
              <div className="UCP_LeftAlignText">Số điểm sử dụng</div>
              <div className="UCP_RightAlignText">
                {`${this.state.point_apply} điểm`}
              </div>
            </div>
            <div className="UCP_TotalLabel">
              <div className="UCP_LeftAlignText">Thành tiền</div>
              <div
                className="UCP_RightAlignText"
                style={{ color: "red", fontSize: 18 }}
              >
                {numberDisplayThousand(total_price)} đ
                <div
                  style={{
                    marginTop: 10,
                    fontSize: 13,
                    // fontWeight: "bold",
                  }}
                >
                  (Đã bao gồm VAT)
                </div>
              </div>
            </div>
          </div>

          <div
            className="UCP_OrderButton"
            onClick={() => {
              this.props.checkOutCart(
                this.state.payment_type,
                this.state.shipping_type,
                this.state.point_apply,
                this.state.voucherInfo?.code || ""
              );
            }}
          >
            Tiến hành đặt hàng
          </div>
        </div>
      </div>
    );
  };

  renderOptionPaymentAndShip = () => {
    return (
      <div className="UCP_SelectContainer">
        <div className="UCP_SelectRow">
          <div className="UCP_SelectTitle">
            <FaMoneyBillAlt className="Icon" />
            Hình thức thanh toán
          </div>
          <div className="UCP_SelectContent">
            <Radio.Group
              onChange={(e) => {
                this.setState({
                  payment_type: e.target.value,
                });
              }}
              value={this.state.payment_type}
            >
              {ListPaymentType.map((payment, index) => {
                return <Radio value={payment.type}>{payment.name}</Radio>;
              })}
            </Radio.Group>
          </div>
        </div>
        <div className="UCP_SelectRow">
          <div className="UCP_SelectTitle">
            <FaShippingFast className="Icon" />
            Hình thức giao hàng
          </div>
          <div className="UCP_SelectContent">
            <Radio.Group
              onChange={(e) => {
                this.setState({
                  shipping_type: e.target.value,
                });
                if (this.props.select_address)
                  this.props.getShippingFee(
                    this.props.select_address._id,
                    e.target.value
                  );
              }}
              value={this.state.shipping_type}
            >
              {ListShippingType.map((shipping, index) => {
                return <Radio value={shipping.type}>{shipping.name}</Radio>;
              })}
            </Radio.Group>
          </div>
        </div>
      </div>
    );
  };

  render() {
    let carts = this.props.carts ? this.props.carts : [];

    let num_products = carts.length;

    return (
      <div className="UserCartPage">
        {this.renderOptionPaymentAndShip()}
        {this.renderSelectAddress()}
        {/* <div className="UCP_Title">
                    Giỏ hàng
                    <span className="UCP_Subtile">
                        ({num_products} sản phẩm)
                    </span>
                </div> */}

        {num_products === 0
          ? this.renderCartEmpty()
          : this.renderCartCheckOut()}

        {/* modal */}
        {this.renderModalSelectAddressShipping()}

        <PopupSelectVoucher
          ref={(r) => (this.refPopupVoucher = r)}
          onSelect={(val) => {
            this.setState({ voucherInfo: val });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let _state = state.user_global;

  return {
    total: _state.total,
    carts: _state.carts,
    shipping_fee: _state.shipping_fee,

    list_address: _state.list_address,
    select_address: _state.select_address,

    userInfo: state.auth.userInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeCartDetail: (id) => {
      dispatch(UserGlobalActions.action.removeCartDetail(id));
    },
    updateCartItem: (id = null, quantity = 1) => {
      dispatch(UserGlobalActions.action.updateCartItem(id, quantity));
    },
    loadListAddress: () => {
      dispatch(UserGlobalActions.action.loadListAddress());
    },

    setDefaultAddress: () => {
      dispatch(UserProfileAction.action.setDefaultAddress());
    },
    setSelectAddress: (address) => {
      dispatch(UserGlobalActions.action.setSelectAddress(address));
    },

    checkOutCart: (
      payment_type,
      shipping_type,
      point = 0,
      voucherCode = ""
    ) => {
      dispatch(
        UserGlobalActions.action.checkOutCart(
          payment_type,
          shipping_type,
          point,
          voucherCode
        )
      );
    },

    getShippingFee: (addressId, serviceId) => {
      dispatch(UserGlobalActions.action.getShippingFee(addressId, serviceId));
    },

    getCurrentVoucherApply: () => {
      dispatch(UserGlobalActions.action.getCurrentVoucherApply());
    },
    checkDiscountCode: (code) => {
      dispatch(UserGlobalActions.action.checkDiscountCode(code));
    },
    removeDiscountCode: () => {
      dispatch(UserGlobalActions.action.removeDiscountCode());
    },
    getUserInfo: () => {
      dispatch(AuthActions.action.getUserInfo());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserCartPage);
