import React, { Component } from 'react'
import './index.scss'
// redux
import { connect } from 'react-redux';
import { history } from "../../../redux/store";
import UserGlobalActions from "../../../redux/user_global/actions";
import UserFlashSaleActions from "../../../redux/user_flash_sale/actions";

import { getImageUrl } from '../../../../services/api/upload';
import { numberDisplayThousand } from '../../../../services/utils/helper';

import Router_Links from "../../../../const/router_link";
import { FaCartPlus } from "react-icons/fa";

// scss
import './index.scss'

class UserFlashSalePage extends Component {
    componentDidMount() {
        this.props.loadProductFlashSale();
    }

    renderProductFlashSale = (product) => {
        let discount = product.flashSaleDiscount;
        if (discount < 0) discount = 0;
        if (discount > 100) discount = 100;

        let price = product.flashSalePrice;
        let newPrice = price;

        let percent = 0;
        try {
            percent =
                (product.totalQuantitySold / product.totalNumberSale) * 100.0;
            if (percent < 0) percent = 0;
            if (percent > 100) percent = 100;
        } catch { }

        let flashsale = this.props.flashSale
            ? this.props.flashSale
            : new Date();
        var options = {
            hour12: false,
            hour: "2-digit",
            minute: "2-digit",
        };
        let date = new Date(flashsale.stopTime);

        return (
            <div
                className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12"
                style={{
                    paddingLeft: 0,
                    paddingRight: 0,
                }}
            >
                <div
                    onClick={() => {
                        history.push(
                            `${Router_Links.U_DETAIL_PRODUCT}/${product._id}`
                        );
                    }}
                    className="APL_ProductItem"
                >
                    <img
                        className="APL_ProductImage"
                        src={getImageUrl(product.thumbnail)}
                        alt="thumbnail"
                    />

                    <div className="APL_ProductAction">
                        <div
                            onClick={(e) => {
                                e.stopPropagation();
                                this.props.addCartItem(product._id, 1);
                            }}
                            className="APL_ProductActionPanel"
                        >
                            <FaCartPlus className="APL_ProductActionIcon" />
                        </div>
                    </div>

                    <div className="APL_ProductContent">
                        <div className="APL_ProductName">{product.name}</div>
                        <div className="APL_ProductPrice">
                            {discount > 0 ? (
                                <span>
                                    <span className="APL_OldPrice">
                                        {numberDisplayThousand(product.price)}đ
                                    </span>
                                    <span className="APL_NewPrice">
                                        {`${numberDisplayThousand(newPrice)}đ`}
                                    </span>
                                </span>
                            ) : (
                                    `${numberDisplayThousand(newPrice)}đ`
                                )}
                        </div>
                        <div className="APL_ProgressFlashSale">
                            <div className="APL_ProgreesBarPanel">
                                <span className="APL_PercentLabel">
                                    Đã bán {product.totalQuantitySold}
                                </span>
                                <div
                                    className="APL_ProgreesBar"
                                    style={{
                                        width: percent + "%",
                                    }}
                                />
                            </div>
                        </div>
                        <div className="APL_CountDown">
                            {`Kết thúc ${date.toLocaleTimeString(
                                "vi-vn",
                                options
                            )} ngày ${date.toLocaleDateString()}`}
                        </div>
                    </div>

                    {discount > 0 && (
                        <div className="APL_ProdcutDiscount">
                            {"-" + discount + "%"}
                        </div>
                    )}
                </div>
            </div>
        );
    };

    render() {
        const { productList } = this.props;
        return (
            <div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        paddingTop: 20,
                        marginBottom: 5,
                    }}
                >
                    <div className="HomePage_Title">Flash Sale</div>
                    <div
                        style={{ height: 1, flex: 1, background: "#e2e2e2" }}
                    />
                </div>
                <div className='product-panel row'>
                    {productList.map(product => (
                        this.renderProductFlashSale(product)
                    ))}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const _state = state.user_flash_sale;
    return {
        productList: _state.productList,
        flashSale: _state.flashSale

    }
}

const mapDispatchToProps = dispatch => {
    return {
        addCartItem: (productId, quantity) => {
            dispatch(UserGlobalActions.action.addCartItem(productId, quantity));
        },
        loadProductFlashSale() {
            dispatch(UserFlashSaleActions.action.loadProductFlashSaleRequest());
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserFlashSalePage)

