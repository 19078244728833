import React, { Component } from "react";
import { Pie } from "react-chartjs-2";

// redux
import { connect } from "react-redux";

const convertDataToChartData = (data) => {
    try {
        let labels = data.labels;
        let datasets = [
            {
                ...{
                    label: "Tỉ lệ cấp bậc thành viên",
                    data: [1, 0, 0, 2, 2, 3, 10, 15],
                    backgroundColor: [
                        "#cc6699",
                        "#00cc99",
                        "#ccccff",
                        "#ff6666",
                        "#9999ff",
                        "#33cc33",
                        "#ffb366",
                        "#66ccff",
                        "#cc6699",
                        "#00cc99",
                        "#ccccff",
                        "#ff6666",
                        "#9999ff",
                        "#33cc33",
                        "#ffb366",
                        "#66ccff",
                    ],
                    borderWidth: 0,
                },
                ...data.datasets[0],
            },
        ];

        return {
            labels,
            datasets,
        };
    } catch {
        return {};
    }
};

class PieChart extends Component {
    render() {
        let data = convertDataToChartData(this.props.user_type_data);

        return (
            <>
                <span
                    style={{
                        color: "black",
                        marginBottom: 10,
                        fontSize: 14,
                        lineHeight: 18,
                        fontWeight: 600,
                        textTransform: "uppercase",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: 50,
                    }}
                >
                    Tỉ lệ cấp bậc thành viên
                </span>
                <div>
                    <Pie data={data} />
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    let _state = state.admin_dashboard;
    return {
        user_type_data: _state.user_type_data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PieChart);
