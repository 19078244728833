import { all, takeEvery, fork, put, select } from "redux-saga/effects";
import actions from "./actions";
import { SORT_MODE_CONST } from "./reducer";
import { history } from '../store'

// api
import {
    getListAddress,
    getDetailAddress,
    insertAddress,
    updateAddress,
    removeAddress
} from "../../../services/api/address_user";
import {
    getListProvines,
    getListDistrictByProvine,
    getListWardByDistrict
} from "../../../services/api/administrative_unit";

// helper
import NotifycationServices from '../../../services/utils/notifycationHelper'
import ValidateUserAddressServices from '../../../services/utils/validate/validate_user_address'

// const 
import Router_Links from '../../../const/router_link'

function* saga_LoadListAddress(action){
    try {
        let params = action.payload.params
        let _state = yield select(state => state.user_profile_address)

        let pageSize = _state.pageSize, pageIndex = _state.pageIndex
        if (params.pageSize) pageSize = params.pageSize
        if (params.pageIndex) pageIndex = params.pageIndex

        let req = yield getListAddress(pageSize, pageIndex)
        if (req.code === 200){
            let list_address = req.data.items 
            let total = req.data.total 
            
            yield put(
                actions.action.updateState({
                    total: total
                })
            )

            yield put(
                actions.action.loadListAddressSuccess(list_address)
            )
        }
    }
    catch (ex){
        console.log("[user_profile] saga_LoadListAddress error : ", ex.message)
    }
}

function* saga_LoadListProvine(action){
    try {
        let req = yield getListProvines()

        if (req.code === 200){
            let provines = req.data 
            yield put (
                actions.action.loadListProvineSuccess(provines)
            )
        }
    }
    catch (ex){
        console.log("[user_profile] saga_LoadListProvine error : ", ex.message)
    }
}

function* saga_LoadListDistrict(action){
    try {
        let provineId = action.payload.provineId

        let req = yield getListDistrictByProvine(provineId)
        if (req.code === 200){
            let districts = req.data
            yield put(
                actions.action.loadListDistrictSuccess(districts)
            )
        }
    }
    catch (ex){
        console.log("[user_profile] saga_LoadListDistrict error : ", ex.message)
    }
}

function* saga_LoadListWard(action){
    try {
        let districtId = action.payload.districtId

        let req = yield getListWardByDistrict(districtId)
        if (req.code === 200){
            let wards = req.data
            yield put(
                actions.action.loadListWardSuccess(wards)
            )
        }
    }
    catch (ex){
        console.log("[user_profile] saga_LoadListWard error : ", ex.message)
    }
}

function* saga_UpdatePageIndex(action){
    try {
        let pageIndex = action.payload.pageIndex 

        yield put(
            actions.action.loadListAddress({
                pageIndex
            })
        )
    }
    catch (ex){
        console.log("[user_profile_address] saga_UpdatePageIndex error : ", ex.message)
    }
}

function* saga_SaveCurrentAddress(action){
    try {
        let address = yield select(state => state.user_profile_address.detail_address)

        // validate
        address.name = address.name.trim();

        let k = yield ValidateUserAddressServices.validate(address)
        if (!k.isValidate){
            NotifycationServices.error(k.message)
            return
        }

        // save 
        try {
            if (address._id){
                // update
                let req = yield updateAddress(address._id, address)
                if (req.code === 200){
                    yield put(
                        actions.action.loadListAddress()
                    )

                    NotifycationServices.success("Cập nhật địa chỉ thành công")
                    yield history.push(Router_Links.U_PROFILE_ADDRESS_SHIPING)
                }
                else 
                    NotifycationServices.error("Cập nhật địa chỉ thất bại")
            }
            else {
                // create
                let req = yield insertAddress(address)
                if (req.code === 200){
                    yield put(
                        actions.action.loadListAddress()
                    )

                    NotifycationServices.success("Thêm địa chỉ thành công")
                    yield history.push(Router_Links.U_PROFILE_ADDRESS_SHIPING)
                }
                else 
                    NotifycationServices.error("Thêm địa chỉ thất bại")
                    
            }
        }
        catch (ez){
            if (address._id){
                // update
                NotifycationServices.error("Cập nhập địa chỉ thất bại")
            }
            else {
                // create
                NotifycationServices.error("Thêm địa chỉ thất bại")
            }
        }

    }
    catch (ex){
        console.log("[user_profile_address] saga_SaveCurrentAddress error : ", ex.message)
    }
}

function* saga_RemoveAddress(action){
    try {
        let id = action.payload.id 
        const _state = yield select(state => state.user_profile_address)
        const listAddress = _state.list_address;
        const pageIndex = _state.pageIndex;

        let req = yield removeAddress(id)
        if (req.code === 200){
            if (Array.isArray(listAddress) && listAddress.length === 1 && pageIndex > 1) {
                yield put(actions.action.updatePageIndex(pageIndex - 1))
            }
            
            yield put(
                actions.action.loadListAddress()
            )

            NotifycationServices.success("Xoá địa chỉ thành công")
        }
        else 
            NotifycationServices.error("Xoá địa chỉ thất bại")
            
    }
    catch (ex){
        console.log("[user_profile_address] saga_RemoveAddress error : ", ex.message)

        NotifycationServices.error("Xoá địa chỉ thất bại")
    }
}

function* saga_LoadDetailAddress(action){
    try {
        let id = action.payload.id

        let req = yield getDetailAddress(id)
        if (req.code === 200){
            let address = req.data

            yield put(
                actions.action.loadDetailAddressSuccess(address)
            )
        }
    }
    catch (ex){
        console.log("[user_profile_address] saga_LoadDetailAddress error : ", ex.message)
    }
}

function* listen() {
    yield takeEvery(actions.type.LOAD_LIST_ADDRESS, saga_LoadListAddress)
    yield takeEvery(actions.type.LOAD_LIST_PROVINE, saga_LoadListProvine)
    yield takeEvery(actions.type.LOAD_LIST_DISTRICT, saga_LoadListDistrict)
    yield takeEvery(actions.type.LOAD_LIST_WARD, saga_LoadListWard)
    yield takeEvery(actions.type.UPDATE_PAGE_INDEX, saga_UpdatePageIndex)
    yield takeEvery(actions.type.SAVE_CURRENT_ADDRESS, saga_SaveCurrentAddress)
    yield takeEvery(actions.type.REMOVE_ADDRESS, saga_RemoveAddress)
    yield takeEvery(actions.type.LOAD_DETAIL_ADDRESS, saga_LoadDetailAddress)
}

export default function* mainSaga() {
    yield all([fork(listen)]);
}
