import createApiServices from './createApiServices'

const api = createApiServices()

export const getListCategories = () => {
    return api.makeAuthRequest({
        url: `categories`,
        method: 'GET',
    })
}

export const getArrayListCategories = () => {
    return api.makeAuthRequest({
        url: `categories/all`,
        method: 'GET'
    })
}

export const getDetailCategory = (id) => {
    return api.makeAuthRequest({
        url: `categories/${id}`,
        method: 'GET'
    })
}

export const getAllCategory = () => {
    return api.makeAuthRequest({
        url: `categories/all`,
        method: 'GET'
    })
}

export const updateCategory = (id, category) => {
    return api.makeAuthRequest({
        url: `admin/managerProductCategory/${id}`,
        method: 'PUT',
        data: category
    })
}

export const removeCategory = (id) => {
    return api.makeAuthRequest({
        url: `admin/managerProductCategory/${id}`,
        method: 'DELETE',
    })
}

export const createNewCategory = (category) => {
    return api.makeAuthRequest({
        url: `admin/managerProductCategory`,
        method: 'POST',
        data: category
    })
}