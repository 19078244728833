const prefix = "admin_order/";

const type = {
  UPDATE_STATE: prefix + "update_state",

  // order
  LOAD_LIST_ORDER: prefix + "load_list_history_order",
  LOAD_LIST_ORDER_SUCCESS: prefix + "load_list_history_order_success",
  UPDATE_PAGE_INDEX: prefix + "update_page_index",

  // detail order
  LOAD_DETAIL_ORDER: prefix + "load_detail_order",
  LOAD_DETAIL_ORDER_SUCCESS: prefix + "load_detail_order_success",
  UPDATE_ORDER_STATUS: prefix + "update_order_status",

  // modal cancel order
  SET_MODAL_CANCEL_ORDER_STATE: prefix + "set_modal_cancel_order_state",
  CANCEL_ORDER: prefix + "cancel_order",
  UPDATE_PAID_ODER: prefix + "update_paid_order",
};

const action = {
  updateState: (state) => {
    return {
      type: type.UPDATE_STATE,
      payload: {
        state,
      },
    };
  },

  // order
  loadListOrder: (params = {}) => {
    return {
      type: type.LOAD_LIST_ORDER,
      payload: {
        params,
      },
    };
  },
  loadListOrderSuccerss: (orders = []) => {
    return {
      type: type.LOAD_LIST_ORDER_SUCCESS,
      payload: {
        orders,
      },
    };
  },
  updatePageIndex: (pageIndex, params = {}) => {
    return {
      type: type.UPDATE_PAGE_INDEX,
      payload: {
        params,
        pageIndex,
      },
    };
  },

  // detail order
  loadDetailOrder: (id) => {
    return {
      type: type.LOAD_DETAIL_ORDER,
      payload: {
        id,
      },
    };
  },
  loadDetailOrderSuccess: (order) => {
    return {
      type: type.LOAD_DETAIL_ORDER_SUCCESS,
      payload: {
        order,
      },
    };
  },
  updateOrderStatus: (
    id = "",
    status = 1,
    estimatedDeliveryTime = undefined
  ) => {
    return {
      type: type.UPDATE_ORDER_STATUS,
      payload: {
        id,
        status,
        estimatedDeliveryTime,
      },
    };
  },
  setModalCancelOrderState: (state) => {
    return {
      type: type.SET_MODAL_CANCEL_ORDER_STATE,
      payload: {
        state,
      },
    };
  },
  cancelOrder: (orderId) => {
    return {
      type: type.CANCEL_ORDER,
      payload: {
        orderId,
      },
    };
  },

  updatePaidOrder: (id = "", paid = 0) => {
    // 0 là chưa thanh toán, 1 là đã thanh toán
    return {
      type: type.UPDATE_PAID_ODER,
      payload: {
        id,
        paid,
      },
    };
  },
};

export default {
  type,
  action,
};
