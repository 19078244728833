const prefix = 'admin_group_user/'

const type = {
    UPDATE_STATE: prefix + 'update_state',

    // list group
    LOAD_LIST_GROUP_USER: prefix + 'load_list_group_user',
    LOAD_LIST_GROUP_USER_SUCCESS: prefix + 'load_list_group_user_success',

    LOAD_LIST_MODULE: prefix + 'load_list_module',
    LOAD_LIST_MODULE_SUCCESS: prefix + 'load_list_module_success',

    UPDATE_PAGE_INDEX: prefix + 'update_page_index',
    SEARCH_GROUP: prefix + 'search_group',
    UPDATE_TEXT_SEARCH: prefix + 'update_text_search',

    // current group
    ACTIVE_PERMISSION: prefix + 'active_permission',
    INACTIVE_PERMISSION: prefix + 'inactive_permission',

    SET_CURRENT_GROUP: prefix + 'set_current_group',
    SET_DEFAULT_CURRENT_GROUP: prefix + 'set_default_current_group',
    UPDATE_CURRENT_GROUP: prefix + 'update_current_group',

    REMOVE_GROUP: prefix + 'remove_group',
    ACTIVE_GROUP: prefix + 'active_group',
    IN_ACTIVE_GROUP: prefix + 'in_active_group',
    SAVE_CURRENT_GROUP: prefix + 'save_current_group',

    // permission
    LOAD_LIST_PERMISSION_OF_GROUP: prefix + 'load_list_permission_of_group',
    LOAD_LIST_PERMISSION_OF_GROUP_SUCCESS: prefix + 'load_list_permission_of_group_success',

    UPDATE_PERMISSION: prefix + 'update_permission',

    // modal add user group
    SET_MD_ADD_USER_GROUP_STATE: prefix + 'set_md_add_user_group_state',
    UPDATE_NEW_GROUP: prefix + 'update_new_group',
    CREATE_NEW_GROUP: prefix + 'create_new_group'
}

const action = {
    updateState: state => {
        return {
            type: type.UPDATE_STATE,
            payload: {
                state
            }
        }
    },

    loadListGroupUser: (params = {}) => {
        return {
            type: type.LOAD_LIST_GROUP_USER,
            payload: {
                params
            }   
        }
    },
    loadListGroupUserSuccess: (groups = []) => {
        return {
            type: type.LOAD_LIST_GROUP_USER_SUCCESS,
            payload: {
                groups
            }
        }
    },
    loadListModule: () => {
        return {
            type: type.LOAD_LIST_MODULE,
            payload: {

            }
        }
    },
    loadListModuleSuccess: (modules = []) => {
        return {
            type: type.LOAD_LIST_MODULE_SUCCESS,
            payload: {
                modules
            }
        }
    },

    updatePageIndex: (pageIndex = 1) => {
        return {
            type: type.UPDATE_PAGE_INDEX,
            payload: {
                pageIndex
            }
        }
    },
    searchGroup: (textSearch = "") => {
        return {
            type: type.SEARCH_GROUP,
            payload: {
                textSearch
            }
        }
    },

    // detail user
    setCurrentGroup: (group) => {
        return {
            type: type.SET_CURRENT_GROUP,
            payload: {
                group
            }
        }
    },
    setDefaultCurrentGroup: () => {
        return {
            type: type.SET_DEFAULT_CURRENT_GROUP,
            payload: {

            }
        }
    },
    updateCurrentGroup: (group) => {
        return {
            type: type.UPDATE_CURRENT_GROUP,
            payload: {
                group
            }
        }
    },

    removeGroup: (id = "") => {
        return {
            type: type.REMOVE_GROUP,
            payload: {
                id
            }
        }
    },
    saveCurrentGroup: (id = "", group = {}) => {
        return {
            type: type.SAVE_CURRENT_GROUP,
            payload: {
                id,
                group
            }
        }
    },
    activeGroup: (id = "") => {
        return {
            type: type.ACTIVE_GROUP,
            payload: {
                id
            }
        }
    },
    inActiveGroup: (id = "") => {
        return {
            type: type.IN_ACTIVE_GROUP,
            payload: {
                id
            }
        }
    },

    // permission
    loadListPermissionOfGroup: (id = "") => {
        return {
            type: type.LOAD_LIST_PERMISSION_OF_GROUP,
            payload: {
                id
            }
        }
    },
    loadListPermissionOfGroupSuccess: (permissions = []) => {
        return {
            type: type.LOAD_LIST_PERMISSION_OF_GROUP_SUCCESS,
            payload: {
                permissions
            }
        }
    },   
    updatePermission: (id = "", status = false) => {
        return {
            type: type.UPDATE_PERMISSION,
            payload: {
                id,
                status
            }
        }
    },
    
    //
    updateTextSearch: (textSearch = "") => {
        return {
            type: type.UPDATE_TEXT_SEARCH,
            payload: {
                textSearch
            }
        }
    },
    setModalUserGroupState: (state) => {
        return {
            type: type.SET_MD_ADD_USER_GROUP_STATE,
            payload: {
                state
            }
        }
    },
    updateNewGroup: (newGroup) => {
        return {
            type: type.UPDATE_NEW_GROUP,
            payload: {
                newGroup
            }
        }
    },
    createNewGroup: () => {
        return {
            type: type.CREATE_NEW_GROUP,
            payload: {
                
            }
        }
    }
}

export default {
    type,
    action
}