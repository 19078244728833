import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import { connect } from "react-redux";
import { formatPrice } from "../../../../helpers/text.helper";

const options = {
  tooltips: {
    callbacks: {
      label: (tooltipItem, data) => {
        let label = data.datasets[tooltipItem.datasetIndex].label || "";

        const isRevenue = label == "Doanh thu";

        if (label) {
          label += ": ";
        }
        label += formatPrice(Number(tooltipItem.value).toFixed(0));
        return `${label} ${isRevenue ? "VNĐ" : "Đơn"}`;
      },
    },
  },

  scales: {
    yAxes: [
      // {
      //     ticks: {
      //         beginAtZero: true,
      //     },
      // },
      {
        type: "linear",
        display: true,
        position: "right",
        id: "y-axis-1",
        ticks: {
          callback: (value) => {
            return formatPrice(value);
          },
        },
      },
      {
        type: "linear",
        display: true,
        position: "left",
        id: "y-axis-2",
        gridLines: {
          drawOnArea: false,
        },
        ticks: {
          callback: (value) => {
            return formatPrice(value);
          },
        },
      },
    ],
  },
};

const convertDataToChartData = (data) => {
  try {
    let labels = data.labels;
    let datasets = [
      {
        ...{
          type: "line",
          label: "Đơn hàng",
          borderColor: `rgba(255, 0, 0, 1)`,
          borderWidth: 0,
          fill: false,
          data: [],
          yAxisID: "y-axis-1",
        },
        ...data.datasets[0],
      },
      {
        ...{
          type: "bar",
          label: "Doanh thu",
          backgroundColor: `rgba(54, 162, 235, 0.8)`,
          data: [],
          borderColor: "white",
          borderWidth: 0,
          yAxisID: "y-axis-2",
        },
        ...data.datasets[1],
      },
    ];

    return {
      labels,
      datasets,
    };
  } catch {
    return {};
  }
};

class RevenueChart extends Component {
  render() {
    const data = convertDataToChartData(this.props.revenue_data);

    return (
      <>
        <span
          style={{
            color: "black",
            marginBottom: 10,
            fontSize: 14,
            lineHeight: 18,
            fontWeight: 600,
            textTransform: "uppercase",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 50,
          }}
        >
          Thống kê doanh thu bán hàng
        </span>
        <Bar data={data} options={options} />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  let _state = state.admin_dashboard;
  return {
    revenue_data: _state.revenue_data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(RevenueChart);
