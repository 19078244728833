import React, { Component } from 'react'
import { FaEdit, FaPlusCircle, FaTrash } from 'react-icons/fa';

// redux
import { connect } from 'react-redux'
import AdminManagerListLevelUserActions from '../../../redux/admin_manager_list_level_user/actions'
// scss
import './index.scss'
// component
import Button from '../../../../components/button'
import ModalAddLevelUser from './modal_add_level_user'
import ModalEditLevelUser from './modal_edit_level_user'
import Router_Links from '../../../../const/router_link';
import { numberDisplayThousand } from '../../../../services/utils/helper';
import ModalConfirmDelete from '../../../../components/modal/modal_confirm_action'

const list_flexs = [5, 10, 10, 10, 10, 10, 10, 20];

class ManagerListLevelUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showConfirmMd: false,
            removeId: ''
        }
    }

    componentDidMount() {
        this.props.init()
    }

    onClickButtonRemove = (id) => {
        this.setState({showConfirmMd: true, removeId: id});
    }

    onClickEditLevelUser = (id) => {
        this.props.loadDetailLevelUser(id)
        this.props.setModalEditLevelUserState(true, id)
    }

    onCancelMdConfirm = () => {
        this.setState({showConfirmMd: false, removeId: ''});
    }

    confirmRemoveCategory = () => {
        this.props.removeLevelUser(this.state.removeId)
        this.setState({showConfirmMd: false, removeId: ''});
    }

    render() {
        const { levelUsers } = this.props
        const { showConfirmMd } = this.state;
        return (
            <div className="ManagerListLevelUser">
                <div className="MLLU_Actions">
                    <Button
                        label="Thêm mới cấp bậc"
                        icon={<FaPlusCircle style={{ marginRight: '4px' }} />}
                        className="MLLU_AddButton"
                        onClick={() => this.props.setModalAddLevelUserState(true)}
                    />
                    <Button
                        label="Mối quan hệ giữa các cấp bậc"
                        className="MLLU_RelationshipButton"
                        onClick={() => {
                            this.props.history.push(
                                Router_Links.A_LEVEL_RELATIONSHIP
                            );
                        }}
                    />
                </div>
                <table className="CDS_Table" style={{}}>
                    <tr className="CDS_TableRowHeader">
                        <th
                            className="CDS_HeaderText"
                            style={{
                                flex: list_flexs[0],
                            }}
                        >
                            Vị trí cấp bậc
                        </th>
                        <th
                            className="CDS_HeaderText"
                            style={{
                                flex: list_flexs[1],
                            }}
                        >
                            Tên
                        </th>
                        <th
                            className="CDS_HeaderText"
                            style={{
                                flex: list_flexs[2]
                            }}
                        >
                            Mô tả
                        </th>
                        <th
                            className="CDS_HeaderText"
                            style={{
                                flex: list_flexs[3],
                            }}
                        >
                            Doanh thu
                        </th>
                        <th
                            className="CDS_HeaderText"
                            style={{
                                flex: list_flexs[4],
                            }}
                        >
                            Ký quỹ
                        </th>
                        <th
                            className="CDS_HeaderText"
                            style={{
                                flex: list_flexs[5],
                            }}
                        >
                            Thành viên hoạt động
                        </th>
                        <th
                            className="CDS_HeaderText"
                            style={{
                                flex: list_flexs[6],
                            }}
                        >
                            Điểm tích luỹ
                        </th>
                        <th
                            className="CDS_HeaderText"
                            style={{ flex: list_flexs[7] }}
                        >
                            Hành động
                        </th>
                    </tr>
                    {levelUsers.map((levelUser, index) => (
                        <tr
                            key={levelUser._id}
                            className="CDS_TableRow"
                            style={{
                                borderBottom:
                                    index === levelUsers.length - 1
                                        ? "none"
                                        : "1px solid #e2e2e2",
                            }}
                        >
                            <td
                                className="CDS_TableCell"
                                style={{
                                    flex: list_flexs[0],
                                }}
                            >
                                {levelUser.priority}
                        </td>
                            <td
                                className="CDS_TableCell"
                                style={{
                                    flex: list_flexs[1]
                                }}
                            >
                                {levelUser.name}
                        </td>
                            <td
                                className="CDS_TableCell superMiniHide"
                                style={{ flex: list_flexs[2] }}
                            >
                                {levelUser.description}
                        </td>

                            <td
                                className="CDS_TableCell"
                                style={{ flex: list_flexs[3] }}
                            >
                                {numberDisplayThousand(levelUser.minimumRevenue)}
                        </td>
                            <td
                                className="CDS_TableCell"
                                style={{ flex: list_flexs[4] }}
                            >
                                {numberDisplayThousand(levelUser.minimumDeposit)}
                        </td>
                        
                            <td
                                className="CDS_TableCell"
                                style={{ flex: list_flexs[5] }}
                            >
                                {levelUser.minimumChildren}
                        </td>
                        <td
                                className="CDS_TableCell"
                                style={{ flex: list_flexs[6] }}
                            >
                                {levelUser.percentDiscountPerOrder}
                        </td>
                            <td
                                className="CDS_TableCell"
                                style={{ flex: list_flexs[7] }}
                            >
                                <Button
                                    label="Sửa"
                                    icon={<FaEdit style={{ marginRight: '4px' }} />}
                                    className="MLLU_Button_Edit"
                                    onClick={() => this.onClickEditLevelUser(levelUser._id)}
                                />
                                <Button
                                    label="Xóa"
                                    icon={<FaTrash style={{ marginRight: '4px' }} />}
                                    className="MLLU_Button_Delete"
                                    onClick={() => this.onClickButtonRemove(levelUser._id)}
                                />
                            </td>
                        </tr>
                    ))}
                </table>
                <ModalConfirmDelete
                    text="Bạn có chắc chắn muốn xóa cấp bậc thành viên vừa chọn không?"
                    isOpen={showConfirmMd}
                    onCancel={this.onCancelMdConfirm}
                    setIsOpen={this.onCancelMdConfirm}
                    onOk={this.confirmRemoveCategory}
                />
                <ModalAddLevelUser />
                <ModalEditLevelUser />
            </div>
        )
    }
}

const mapStateToProps = state => {
    const _state = state.admin_manager_list_level_user
    return {
        levelUsers: _state.levelUsers
    }
}

const mapDispatchToProps = dispatch => {
    return {
        init: () => {
            dispatch(AdminManagerListLevelUserActions.action.loadListLevelUser())
        },
        removeLevelUser: (id) => {
            dispatch(AdminManagerListLevelUserActions.action.removeLevelUser(id))
        },
        setModalAddLevelUserState: (state) => {
            dispatch(AdminManagerListLevelUserActions.action.setModalAddLevelUserState(state))
        },
        setModalEditLevelUserState: (state, id) => {
            dispatch(AdminManagerListLevelUserActions.action.setModalEditLevelUserState(state, id))
        },
        loadDetailLevelUser: (id) => {
            dispatch(AdminManagerListLevelUserActions.action.loadDetailLevelUser(id))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagerListLevelUser)

