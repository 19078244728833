import createApiServices from './createApiServices'

const api = createApiServices()

const GetListPost = (text_search = "", pageSize = 12, pageIndex = 1, params = []) => {
    let url = `posts?pageSize=${pageSize}&pageIndex=${pageIndex}&keyword=${text_search}&filters[status]=true`

    for(let index = 0; index < params.length; index++)
        url = url + `&${params[index].name}=${params[index].value}`

    return api.makeAuthRequest({
        url,
        method: 'GET'
    })
}

const GetDetailPost = (id = "") => {
    return api.makeAuthRequest({
        url: `posts/${id}`,
        method: 'GET'
    })
}

const CreatePost = (post = {}) => {
    return api.makeAuthRequest({
        url: `admin/managerPost/create`,
        method: 'POST',
        data: post
    })
}

const RemovePost = (id = "") => {
    return api.makeAuthRequest({
        url: `admin/managerPost/remove/${id}`,
        method: "DELETE"
    })
}

const UpdatePost = (id = "", post = {}) => {
    return api.makeAuthRequest({
        url: `admin/managerPost/update/${id}`,
        method: 'PUT',
        data: post
    })
}

const PostApiServices = {
    GetListPost,
    GetDetailPost,
    CreatePost,
    RemovePost,
    UpdatePost
}

export default PostApiServices