import React, { Component } from "react";

// redux
import { connect } from "react-redux";
import UserProfileAction from "../../../../redux/user_profile_address/actions";

// scss
import "./index.scss";
import { FaPlus, FaCheckCircle } from "react-icons/fa";

// router
import { Link } from "react-router-dom";
import RouterLinks from "../../../../../const/router_link";

// modal
import ModalConfirmAction from "../../../../../components/modal/modal_confirm_action";
import Pagination from "../../../../../components/paginations/paginations";

class UserAddressShippingPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalConfirmDeleteState: false,
            currentAddress: 0
        };
    }

    // lifecycle
    componentDidMount = () => {
        this.props.init();
    };

    // render
    render() {
        let list_address = this.props.list_address
            ? this.props.list_address
            : [];

        let { pageSize, pageIndex, total } = this.props;

        return (
            <div className="UserAddressShippingPage">
                <div className="UAS_Title">Địa chỉ giao hàng</div>

                <Link
                    to={RouterLinks.U_PROFILE_CREATE_ADDRESS_SHIPPING}
                    className="USA_Button"
                    onClick={() => {
                        this.props.setDefaultAddress()
                    }}
                >
                    <FaPlus className="USA_Icon" />
                    Thêm địa chỉ mới
                </Link>

                <div className="USA_ListAddresss">
                    {list_address.map((address_item, index) => {
                        return (
                            <div className="USA_Address">
                                <div className="USA_Address_Name">
                                    {address_item.name}
                                    {address_item.isDefault && (
                                        <div className="USA_DefaultAddress">
                                            <FaCheckCircle className="USA_Address_Icon" />
                                            Địa chỉ mặc định
                                        </div>
                                    )}
                                </div>
                                <div className="USA_Address_RowInfo">
                                    <span className="USA_Address_Label">
                                        Địa chỉ:
                                    </span>
                                    {`${address_item.fullAddress}, ${address_item.wardName}, ${address_item.districtName}, ${address_item.provinceName}`}
                                </div>
                                <div className="USA_Address_RowInfo">
                                    <span className="USA_Address_Label">
                                        Điện thoại:
                                    </span>
                                    {address_item.phone}
                                </div>
                                <div className="USA_Address_Action">
                                    <Link
                                        to={`${RouterLinks.U_PROFILE_DETAIL_ADDRESS_SHIPPING}/${address_item._id}`}
                                        className="USA_Action_Edit"
                                        onClick={() => {
                                            this.props.setAddress(address_item)
                                        }}
                                    >
                                        Chỉnh sửa
                                    </Link>
                                    {!address_item.isDefault && (
                                        <div
                                            className="USA_Action_Remove"
                                            onClick={() =>
                                                this.setState({
                                                    modalConfirmDeleteState: true,
                                                    currentAddress: address_item._id
                                                })
                                            }
                                        >
                                            Xoá
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>

                <div className="UFP_PaginateSection">
                    <Pagination
                        onChange={(index) => {
                            this.props.updatePageIndex(index);
                        }}
                        pageSize={pageSize}
                        pageIndex={pageIndex}
                        total={total}
                    />
                </div>

                <ModalConfirmAction
                    isOpen={this.state.modalConfirmDeleteState}
                    setIsOpen={(value) =>
                        this.setState({ modalConfirmDeleteState: value })
                    }
                    onOk={() => {
                        this.setState({ modalConfirmDeleteState: false });
                        this.props.removeAddress(this.state.currentAddress)
                    }}
                    onCancel={() =>
                        this.setState({ modalConfirmDeleteState: false })
                    }
                    text="Bạn chắc chắn xoá địa chỉ này?"
                    displayCloseIcon={false}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    let _state = state.user_profile_address;

    return {
        list_address: _state.list_address,
        pageSize: _state.pageSize,
        pageIndex: _state.pageIndex,
        total: _state.total,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        init: () => {
            dispatch(UserProfileAction.action.loadListAddress());
        },
        updatePageIndex: pageIndex => {
            dispatch(UserProfileAction.action.updatePageIndex(pageIndex))
        },
        removeAddress: id => {
            dispatch(UserProfileAction.action.removeAddress(id))
        },
        setDefaultAddress: () => {
            dispatch(UserProfileAction.action.setDefaultAddress())
        },
        setAddress: address => {
            dispatch(UserProfileAction.action.setAddress(address))
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserAddressShippingPage);
