import { all, takeEvery, fork, put, select } from "redux-saga/effects";
import actions from "./actions";

// api
import {
    getListProductHot,
    getListProductBestSell
} from "../../../services/api/product";

// helper
import NotifycationServices from "../../../services/utils/notifycationHelper";
import validate_product from "../../../services/utils/validate/validate_product";

function* saga_LoadListProduct(action) {
    try {
        let params = action.payload.params;

        let _state = yield select((state) => state.user_product_hot_discount);
        let { pageSize, pageIndex } = _state;

        if (params.pageIndex) pageIndex = params.pageIndex;
        if (params.pageSize) pageSize = params.pageSize;

        let res = yield getListProductHot(pageSize, pageIndex)
        let { items, total } = res.data;

        if (res.code === 200) {
            yield put(actions.action.loadListHotProductSuccess(items));
            yield put(
                actions.action.updateState({
                    total,
                })
            );
        }

        // NotifycationServices.success('Đăng nhập thành công', "Thông báo")
    } catch (ex) {
        console.log(
            "[user_product_hot_discount Saga] saga_LoadListProduct Error : ",
            ex.message
        );
        yield put(actions.action.loadListHotProductFailed());
    }
}

function* saga_UpdatePageIndex(action) {
    try {
        let pageIndex = action.payload.pageIndex;

        yield put(
            actions.action.loadListHotProduct({
                pageIndex,
            })
        );
    } catch (ex) {
        console.log(
            "[user_product_hot_discount Saga] saga_UpdatePageIndex Error : ",
            ex.message
        );
    }
}

function* saga_LoadListProductBestSell(action) {
    try {
        let params = action.payload.params;

        let _state = yield select((state) => state.user_product_hot_discount);
        let { bs_pageSize, bs_pageIndex } = _state;

        if (params.pageIndex) bs_pageIndex = params.pageIndex;
        if (params.pageSize) bs_pageSize = params.pageSize;

        let res = yield getListProductBestSell(bs_pageSize, bs_pageIndex)
        let { items, total } = res.data;

        if (res.code === 200) {
            yield put(actions.action.loadListProductBestSellSuccess(items));
            yield put(
                actions.action.updateState({
                    bs_total: total,
                })
            );
        }
    } catch (ex) {
        console.log(
            "[user_product_hot_discount Saga] saga_LoadListProductBestSell Error : ",
            ex.message
        );
        yield put(actions.action.loadListProductBestSellFailed());
    }
}

function* saga_UpdatePageIndexBestSell(action) {
    try {
        let pageIndex = action.payload.pageIndex;

        yield put(
            actions.action.loadListProductBestSell({
                pageIndex,
            })
        );
    } catch (ex) {
        console.log(
            "[user_product_hot_discount Saga] saga_UpdatePageIndexBestSell Error : ",
            ex.message
        );
    }
}

function* listen() {
    yield takeEvery(actions.type.LOAD_LIST_HOT_PRODUCT, saga_LoadListProduct);
    yield takeEvery(actions.type.UPDATE_PAGE_INDEX, saga_UpdatePageIndex);
    yield takeEvery(actions.type.LOAD_LIST_PRODUCT_BEST_SELL, saga_LoadListProductBestSell);
    yield takeEvery(actions.type.UPDATE_PAGE_INDEX_BEST_SELL, saga_UpdatePageIndexBestSell);
}

export default function* mainSaga() {
    yield all([fork(listen)]);
}
