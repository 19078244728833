import actions from "./actions";

const initState = {
    // overview
    totalRevenue: 0,
    totalOrder: 0,
    totalProduct: 0,
    totalUser: 0,

    // chart 
    user_type_data: {},
    revenue_data: {},

    // list order
    orders: [],
    total: 0,
    pageIndex: 1,
    pageSize: 5,
    textSearch: "",
    lastSearch: "",

    // detail order
    detail_order: null,
    is_loadding_detail_order: false,
};

const reducer = (state = initState, action) => {
    let payload = action.payload;

    switch (action.type) {
        case actions.type.UPDATE_STATE:
            return {
                ...state,
                ...payload.state,
            };

        /// overview
        case actions.type.GET_OVER_VIEW_INFO:
            return state;

        case actions.type.GET_TOTAL_REVENUE_SUCCESS:
            return {
                ...state,
                ...{
                    totalRevenue: payload.totalRevenue,
                },
            };

        case actions.type.GET_TOTAL_ORDER_SUCCESS:
            return {
                ...state,
                ...{
                    totalOrder: payload.totalOrder,
                },
            };

        case actions.type.GET_TOTAL_PRODUCT_SUCCESS:
            return {
                ...state,
                ...{
                    totalProduct: payload.totalProduct,
                },
            };

        case actions.type.GET_TOTAL_NUMBER_USER_SUCCESS:
            return {
                ...state,
                ...{
                    totalUser: payload.totalUser,
                },
            };

        // order
        case actions.type.LOAD_LIST_ORDER:
            return {
                ...state,
                ...{
                    orders: [],
                },
            };

        case actions.type.LOAD_LIST_ORDER_SUCCESS:
            return {
                ...state,
                ...{
                    orders: payload.orders,
                },
            };

        case actions.type.UPDATE_PAGE_INDEX:
            return {
                ...state,
                ...{
                    pageIndex: payload.pageIndex,
                },
            };

        case actions.type.UPDATE_TEXT_SEACRH:
            return {
                ...state,
                ...{
                    textSearch: payload.textSearch
                }
            }

        case actions.type.SEARCH_ORDER:
            return {
                ...state,
                ...{
                    lastSearch: state.textSearch
                }
            }

        // chart 
        case actions.type.GET_USER_TYPE_CHART:
            return state 

        case actions.type.GET_USER_TYPE_CHART_SUCCESS:
            return {
                ...state,
                ...{
                    user_type_data: payload.user_type_data
                }
            }

        case actions.type.GET_REVENUE_CHART:
            return state 

        case actions.type.GET_REVENUE_CHART_SUCCESS:
            return {
                ...state,
                ...{
                    revenue_data: payload.revenue_data
                }
            }

        // detail order
        case actions.type.LOAD_DETAIL_ORDER:
            return {
                ...state,
                ...{
                    detail_order: null,
                    is_loadding_detail_order: true,
                },
            };

        case actions.type.LOAD_DETAIL_ORDER_SUCCESS:
            return {
                ...state,
                ...{
                    detail_order: payload.order,
                },
            };

        case actions.type.UPDATE_ORDER_STATUS:
            return state;

        default:
            return state;
    }
};

export default reducer;
