import axios from "axios";
import { server } from "./api/configs";
import { getSessionKey } from "./utils/storageHelper";

const REQ_TIMEOUT = 15 * 1000;

const instance = axios.create({
  baseURL: server,
  timeout: REQ_TIMEOUT,
});

instance.interceptors.request.use((_config) => requestHandler(_config));

export const __DEV__ =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development";

const requestHandler = (request) => {
  if (__DEV__) {
    console.log(`Request API: ${request.url}`, request.params, request.data);
    // console.log(`Request API: ${request.url}`, request);
  }
  return request;
};

instance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);

const errorHandler = (error) => {
  if (__DEV__) {
    console.log(error);
  }
  return Promise.reject({ ...error });
};

const successHandler = async (response) => {
  if (__DEV__) {
    console.log(`Response API: ${response.config.url}`, response.data);
  }

  const data = response.data;

  return data;
};

async function fetch(url, params, customHeaders) {
  const headers = getHeader(customHeaders);
  return instance.get(url, { params, headers });
}

async function post(url, data, customHeaders) {
  const headers = getHeader(customHeaders);
  return instance.post(url, { ...data }, { headers });
}

async function postForm(url, data, customHeaders) {
  const headers = getHeader(customHeaders);
  return instance.post(url, data, { headers });
}

async function put(url, data, customHeaders) {
  const headers = getHeader(customHeaders);
  return instance.put(url, { ...data }, { headers });
}

async function remove(url, data, customHeaders) {
  const headers = getHeader(customHeaders);
  return instance.delete(url, { data: { ...data }, headers: { ...headers } });
}

function getHeader(customHeaders) {
  const header = customHeaders || {};
  if (!customHeaders) return header;

  const accessToken = getSessionKey();

  if (customHeaders.isAuth && accessToken) {
    header.Authorization = `Bearer ${accessToken}`;
  }
  return { ...header };
}

function processRequestRespository(
  reqPromise,
  onSuccess,
  onfail,
  isShowAlert,
  isShowLoading
) {
  //   isShowLoading && showLoading();

  reqPromise
    .then(({ code, message, data = null }) => {
      // hideLoading();
      // switch (code) {
      //   case 200:
      //     isShowAlert && showAlertSuccess(message, true);
      //     onSuccess && onSuccess(data);
      //     break;
      //   default:
      //     console.log("message: ", message);
      //     const mgs = message?.description || message?.title || message || "";
      //     isShowAlert && showAlertError(mgs, true);
      //     onfail && onfail(code, mgs || "");
      //     break;
      // }
    })
    .catch((e) => {
      //   hideLoading();
      //   console.log("processRequestRespository: ", reqPromise, e);
      //   isShowAlert &&
      //     showAlertError(
      //       e?.message?.description || e?.message?.title || message || "",
      //       true
      //     );
      //   onfail && onfail(e);
    });
}

const ApiUtils = {
  fetch,
  post,
  put,
  postForm,
  remove,
  processRequestRespository,
};

export default ApiUtils;
