import createApiServices from './createApiServices'

const api = createApiServices()

export const getCartOfCurrentUser = () => {
    return api.makeAuthRequest({
        url: `cart`,
        method: 'GET',
    })
}

export const insertProductToCart = (productId = "", quantity = 1) => {
    return api.makeAuthRequest({
        url: `cart`,
        method: 'POST',
        data: {
            productId,
            quantity
        }
    })
}

export const updateQuantity = (id = "", quantity = 1) => {
    return api.makeAuthRequest({
        url: `cart/updateQuantity/${id}`,
        method: 'PUT',
        data: {
            quantity
        }
    })
}

export const removeCartDetail = (id) => {
    return api.makeAuthRequest({
        url: `cart/${id}`,
        method: 'DELETE'
    })
}

