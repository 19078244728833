import { all, fork, put, select, takeEvery } from 'redux-saga/effects'
import { createLevelUser, getLevelUser, getListLevel, removeLevelUser, updateLevelUser } from '../../../services/api/level_user';
import actions from './actions'
import NotifycationServices from "../../../services/utils/notifycationHelper";

import ValidateLevelUser from '../../../services/utils/validate/validate_level_user'

function* saga_LoadListLevelUser(aciton) {
    try {
        const res = yield getListLevel()
        if (res.code === 200) {
            let levelUsers = res.data
            yield put(actions.action.loadListLevelUserSuccess(levelUsers))
        }
    } catch(ex) {
        console.log("[Admin Manager List Level User Saga] saga_LoadListLevelUser error : ", ex.message);
    }
}

function* saga_RemoveLevelUser(action) {
    try {
        const id = action.payload.id
        const res = yield removeLevelUser(id)

        if (res.code === 200) {
            NotifycationServices.success("Xóa cấp bậc thành công")
            yield put(actions.action.loadListLevelUser())
        }
    } catch(ex) {
        console.log("[Admin Manager List Level User Saga] saga_RemoveLevelUser error : ", ex.message);
        NotifycationServices.error("Xóa cấp bậc thất bại")
    }
}

function* saga_AddNewLevelUser() {
    try {
        const _state = yield select(state => state.admin_manager_list_level_user)
        const newLevelUser = _state.newLevelUser
        const validateRes = ValidateLevelUser.validate(newLevelUser)
        if (!validateRes.isValidate){
            NotifycationServices.error(validateRes.message)
            return
        }

        const res = yield createLevelUser(newLevelUser)
        if (res.code === 200) {
            NotifycationServices.success("Thêm mới cấp bậc thành công")
            yield put(actions.action.setModalAddLevelUserState(false))
            yield put(actions.action.loadListLevelUser())
        }
    } catch(ex) {
        console.log("[Admin Manager List Level User Saga] saga_AddNewLevelUser error : ", ex.message);
        NotifycationServices.error("Thêm mới cấp bậc thất bại")
    }
}

function* saga_SaveCurrentLevelUser() {
    try {
        const _state = yield select(state => state.admin_manager_list_level_user)
        const currentLevelUser = _state.currentLevelUser
        const id = _state.currentLevelUserId

        const validateRes = ValidateLevelUser.validate(currentLevelUser)
        if (!validateRes.isValidate){
            NotifycationServices.error(validateRes.message)
            return
        }

        const res = yield updateLevelUser(id, currentLevelUser)
        if (res.code === 200) {
            NotifycationServices.success("Cập nhật cấp bậc thành công")
            yield put(actions.action.setModalEditLevelUserState(false))
            yield put(actions.action.loadListLevelUser())
        }
    } catch(ex) {
        console.log("[Admin Manager List Level User Saga] saga_SaveCurrentLevelUser error : ", ex.message);
        NotifycationServices.error("Cập nhật cấp bậc thất bại")
    }
}

function* saga_LoadDetailLevelUser(action) {
    try {
        const id = action.payload.id

        const res = yield getLevelUser(id)
        if (res.code === 200) {
            const levelUser = res.data
            yield put(actions.action.loadDetailLevelUserSuccess(levelUser))
        }
    } catch(ex) {
        console.log("[Admin Manager List Level User Saga] saga_LoadDetailLevelUser error : ", ex.message);
    }
}

function* listen() {
    yield takeEvery(actions.type.LOAD_LIST_LEVEL_USER, saga_LoadListLevelUser)
    yield takeEvery(actions.type.REMOVE_LEVEL_USER, saga_RemoveLevelUser)
    yield takeEvery(actions.type.ADD_NEW_LEVEL_USER, saga_AddNewLevelUser)
    yield takeEvery(actions.type.SAVE_CURRENT_LEVEL_USER, saga_SaveCurrentLevelUser)
    yield takeEvery(actions.type.LOAD_DETAIL_LEVEL_USER, saga_LoadDetailLevelUser)
}

export default function* mainSaga() {
    yield all([fork(listen)])
}