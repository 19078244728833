import createApiServices from "../../../../services/api/createApiServices";

const api = createApiServices();

export const apiUpdateCadoxuOrPointUser = (id, data) => {
  return api.makeAuthRequest({
    url: `/admin/managerUser/update-cadoxu-point/${id}`,
    method: "PUT",
    data,
  });
};
