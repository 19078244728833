const prefix = 'user_profile_address/'

const type = {
    UPDATE_STATE: prefix + 'update_state',

    // list address 
    LOAD_LIST_ADDRESS: prefix + 'load_list_address',
    LOAD_LIST_ADDRESS_SUCCESS: prefix + 'load_list_address_success',
    UPDATE_PAGE_INDEX: prefix + 'update_page_index',

    SET_DEFAULT_ADDRESS: prefix  + 'save_default_address',
    SET_ADDRESS: prefix + 'set_address',
    UPDATE_CURRENT_ADDRESS: prefix + 'update_current_address',
    REMOVE_ADDRESS: prefix + 'remove_address',
    SAVE_CURRENT_ADDRESS: prefix + 'save_current_address',

    // address detail
    LOAD_DETAIL_ADDRESS: prefix + 'load_detail_address',
    LOAD_DETAIL_ADDRESS_SUCCESS: prefix + 'load_detail_address_success',

    LOAD_LIST_PROVINE: prefix + 'load_list_provine',
    LOAD_LIST_PROVINE_SUCCESS: prefix + 'load_list_provine_success',
    LOAD_LIST_DISTRICT: prefix + 'load_list_district',
    LOAD_LIST_DISTRICT_SUCCESS: prefix + 'load_list_district_success',
    LOAD_LIST_WARD: prefix + 'load_list_ward',
    LOAD_LIST_WARD_SUCCESS: prefix + 'load_list_ward_success',
}

const action = {
    updateState: state => {
        return {
            type: type.UPDATE_STATE,
            payload: {
                state
            }
        }
    },

    // list-address 
    loadListAddress: (params = {}) => {
        return {
            type: type.LOAD_LIST_ADDRESS,
            payload: {
                params
            }
        }
    },
    loadListAddressSuccess: address => {
        return {
            type: type.LOAD_LIST_ADDRESS_SUCCESS,
            payload: {
                address
            }
        }
    },
    updatePageIndex: pageIndex => {
        return {
            type: type.UPDATE_PAGE_INDEX,
            payload: {
                pageIndex
            }
        }
    },
    setDefaultAddress: () => {
        return {
            type: type.SET_DEFAULT_ADDRESS,
            payload: {

            }
        }
    },
    setAddress: address => {
        return {
            type: type.SET_ADDRESS,
            payload: {
                address
            }
        }
    },
    updateCurrentAddress: address => {
        return {
            type: type.UPDATE_CURRENT_ADDRESS,
            payload: {
                address
            }
        }
    },
    saveCurrentAddress: () => {
        return {
            type: type.SAVE_CURRENT_ADDRESS,
            payload: {

            }
        }
    },
    removeAddress: id => {
        return {
            type: type.REMOVE_ADDRESS,
            payload: {
                id
            }
        }
    },


    // address detail 
    loadDetailAddress: (id) => {
        return {
            type: type.LOAD_DETAIL_ADDRESS,
            payload: {
                id
            }
        }
    },
    loadDetailAddressSuccess: address => {
        return {
            type: type.LOAD_DETAIL_ADDRESS_SUCCESS,
            payload: {
                address
            }
        }
    },
    loadListProvine: () => {
        return {
            type: type.LOAD_LIST_PROVINE,
            payload: {

            }
        }
    },
    loadListProvineSuccess: provines => {
        return {
            type: type.LOAD_LIST_PROVINE_SUCCESS,
            payload: {
                provines
            }
        }
    },
    loadListDistrict: provineId => {
        return {
            type: type.LOAD_LIST_DISTRICT,
            payload: {
                provineId
            }
        }
    },
    loadListDistrictSuccess: districts => {
        return {
            type: type.LOAD_LIST_DISTRICT_SUCCESS,
            payload: {
                districts
            }
        }
    },
    loadListWard: districtId => {
        return {
            type: type.LOAD_LIST_WARD,
            payload: {
                districtId
            }
        }
    },
    loadListWardSuccess: wards => {
        return {
            type: type.LOAD_LIST_WARD_SUCCESS,
            payload: {
                wards
            }
        }
    }
}

export default {
    type,
    action
}