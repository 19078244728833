import React, { Component } from "react";
// redux
import { connect } from "react-redux";
// route
import { Route, Switch } from "react-router-dom";
// component
import LoginRequireContainer from "../../components/login_require_component";
// const
import RouterLinks from "../../const/router_link";
import authActions from "../redux/auth/actions";
// cart component
import CartDetailPage from "../screens/user_views/detai_cart_page";
import ForgetPasswordPage from "../screens/user_views/forgot_password_page";
import ConfirmResetPasswordPage from "../screens/user_views/forgot_password_page/confirm_reset_password";
// auth component
import LoginPage from "../screens/user_views/login_page";
import RegisterPage from "../screens/user_views/register_page";
import CartPanel from "../screens/user_views/shared/cart_panel";
import Footer from "../screens/user_views/shared/footer";
// shared component
import TopBar from "../screens/user_views/shared/top_bar";
import UserManagerWareHouse from "../screens/user_views/user-manager-ware-house";
import UserManagerWareHouseDetailOrder from "../screens/user_views/user-manager-ware-house/detail";
import UserAddressShipping from "../screens/user_views/user_profile_page/address_ship_page";
import UserAddressShippingDetail from "../screens/user_views/user_profile_page/address_ship_page/address_detail_page";
import ChangePasswordPage from "../screens/user_views/user_profile_page/change_password_page";
import UserHistoryBillPage from "../screens/user_views/user_profile_page/history_bill_page";
import DetailBillPage from "../screens/user_views/user_profile_page/history_bill_page/bill_detail_page";
import HistoryPaymentPage from "../screens/user_views/user_profile_page/history_payment_page";
import UserHistoryVoucher from "../screens/user_views/user_profile_page/history_voucher";
import UserHistoryVoucherDetail from "../screens/user_views/user_profile_page/history_voucher/history_voucher_detail";
import "../screens/user_views/user_profile_page/index.scss";
import UserInfoPage from "../screens/user_views/user_profile_page/info_page";
import UserLevelPage from "../screens/user_views/user_profile_page/level_user_page";
import LinkAffiliatePage from "../screens/user_views/user_profile_page/link_affiliate_page";
import UserRevenuePage from "../screens/user_views/user_profile_page/revenue_page";
// profile component
import SideBarProfile from "../screens/user_views/user_profile_page/sidebar";
import UserVoucher from "../screens/user_views/user_profile_page/voucher";

class UserLayoutAuth extends Component {
  render() {
    return (
      <Route path={RouterLinks.U_AUTH}>
        <div
          style={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <TopBar />

          <Switch>
            <Route component={LoginPage} path={RouterLinks.U_AUTH_LOGIN} />
            <Route
              component={RegisterPage}
              path={RouterLinks.U_AUTH_REGISTER}
            />

            <Route
              component={ForgetPasswordPage}
              path={RouterLinks.U_AUTH_FOGOT_PASSWORD}
            />

            <Route
              component={ConfirmResetPasswordPage}
              path={RouterLinks.U_AUTH_CONFIRM_RESET_PASSWORD}
            />
          </Switch>

          <div className="page-wrapper">
            <Footer />
          </div>
          {/* <CartPanel /> */}
        </div>
      </Route>
    );
  }
}

class UserProfile extends Component {
  render() {
    return (
      <Route path={RouterLinks.U_PROFILE} onEnter={this.props.checkSession()}>
        <div
          style={{
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#f2f2f2",
          }}
        >
          <TopBar />

          <div className="container container_user_profile">
            <div className="u_profile_container">
              <SideBarProfile />

              <div className="u_profile_content">
                <Switch>
                  <LoginRequireContainer
                    exact
                    component={ChangePasswordPage}
                    path={RouterLinks.U_PROFILE_CHANGE_PASSWORD}
                    redirect_link={RouterLinks.U_AUTH_LOGIN}
                  />

                  <LoginRequireContainer
                    exact
                    component={LinkAffiliatePage}
                    path={RouterLinks.U_PROFILE_LINK_AFFILIATE}
                    redirect_link={RouterLinks.U_AUTH_LOGIN}
                  />

                  <LoginRequireContainer
                    exact
                    component={UserInfoPage}
                    path={RouterLinks.U_PROFILE_INFO}
                    redirect_link={RouterLinks.U_AUTH_LOGIN}
                  />

                  <LoginRequireContainer
                    exact
                    component={UserAddressShippingDetail}
                    path={
                      RouterLinks.U_PROFILE_DETAIL_ADDRESS_SHIPPING + "/:id"
                    }
                    redirect_link={RouterLinks.U_AUTH_LOGIN}
                  />

                  <LoginRequireContainer
                    exact
                    component={UserAddressShippingDetail}
                    path={RouterLinks.U_PROFILE_CREATE_ADDRESS_SHIPPING}
                    redirect_link={RouterLinks.U_AUTH_LOGIN}
                  />

                  <LoginRequireContainer
                    exact
                    component={UserAddressShipping}
                    path={RouterLinks.U_PROFILE_ADDRESS_SHIPING}
                    redirect_link={RouterLinks.U_AUTH_LOGIN}
                  />

                  <LoginRequireContainer
                    exact
                    component={UserHistoryBillPage}
                    path={RouterLinks.U_PROFILE_HISTORY_BILL}
                    redirect_link={RouterLinks.U_AUTH_LOGIN}
                  />

                  <LoginRequireContainer
                    exact
                    component={DetailBillPage}
                    path={RouterLinks.U_PROFILE_DETAIL_BILL + "/:id"}
                    redirect_link={RouterLinks.U_AUTH_LOGIN}
                  />

                  <LoginRequireContainer
                    exact
                    component={HistoryPaymentPage}
                    path={RouterLinks.U_PROFILE_HISTORY_PAYMENT}
                    redirect_link={RouterLinks.U_AUTH_LOGIN}
                  />

                  <LoginRequireContainer
                    exact
                    component={UserManagerWareHouse}
                    path={RouterLinks.U_PROFILE_WARE_HOUSE_ORDER}
                    redirect_link={RouterLinks.U_AUTH_LOGIN}
                  />

                  <LoginRequireContainer
                    exact
                    component={UserManagerWareHouseDetailOrder}
                    path={
                      RouterLinks.U_PROFILE_WARE_HOUSE_ORDER_DETAIL + "/:id"
                    }
                    redirect_link={RouterLinks.U_AUTH_LOGIN}
                  />

                  <LoginRequireContainer
                    exact
                    component={UserRevenuePage}
                    path={RouterLinks.U_PROFILE_REVENUE}
                    redirect_link={RouterLinks.U_AUTH_LOGIN}
                  />

                  <LoginRequireContainer
                    exact
                    component={UserLevelPage}
                    path={RouterLinks.U_PROFILE_LEVEL_USER}
                    redirect_link={RouterLinks.U_AUTH_LOGIN}
                  />

                  <LoginRequireContainer
                    exact
                    component={UserVoucher}
                    path={RouterLinks.U_PROFILE_VOUCHER}
                    redirect_link={RouterLinks.U_AUTH_LOGIN}
                  />

                  <LoginRequireContainer
                    exact
                    component={UserHistoryVoucher}
                    path={RouterLinks.U_PROFILE_HISTORY_VOUCHER}
                    redirect_link={RouterLinks.U_AUTH_LOGIN}
                  />
                  <LoginRequireContainer
                    exact
                    component={UserHistoryVoucherDetail}
                    path={RouterLinks.U_PROFILE_HISTORY_VOUCHER + "/:id"}
                    redirect_link={RouterLinks.U_AUTH_LOGIN}
                  />
                </Switch>
              </div>
            </div>
          </div>

          <Footer />

          {/* <CartPanel /> */}
        </div>
      </Route>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    checkSession: () => {
      dispatch(authActions.action.checkSession());
    },
  };
};

const UserProfileContainser = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfile);

class UserCart extends Component {
  render() {
    return (
      <Route path={RouterLinks.U_CART}>
        <div
          style={{
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#f2f2f2",
          }}
        >
          <TopBar />

          <div
            style={{
              minHeight: 500,
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#f2f2f2",
            }}
          >
            <div className="container">
              <Switch>
                <LoginRequireContainer
                  exact
                  component={CartDetailPage}
                  path={RouterLinks.U_CART_DETAIL}
                  redirect_link={RouterLinks.U_AUTH_LOGIN}
                />
              </Switch>
            </div>
          </div>

          <Footer />

          {/* <CartPanel /> */}
        </div>
      </Route>
    );
  }
}

export { UserLayoutAuth, UserProfileContainser, UserCart };
