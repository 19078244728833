export const validateEmail = (email) => {
    // function
    const errorResult = (message) => {
        return {
            isValidate: false,
            message,
        };
    };

    // validate
    if (
        !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(
            email
        )
    )
        return errorResult("Email không đúng định dạng");

    return {
        isValidate: true,
        message: "success",
    };
};
