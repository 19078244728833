import actions from './actions'

const defaultNewLevelRelationship = {
    levelSource: "",
    levelDestination: "",
    percent: ''
}

const initState = {
    levelRelationship: {},
    // modal add level relationship
    mdAddLevelRelationshipState: false,
    levelUsers: [],
    newLevelRelationship: defaultNewLevelRelationship
}

const reducer = (state = initState, action) => {
    const payload = action.payload;
    switch(action.type) {
        case actions.type.LOAD_LEVEL_RELATIONSHIP:
            return state
        case actions.type.LOAD_LEVEL_RELATIONSHIP_SUCCESS:
            return {
                ...state,
                ...{
                    levelRelationship: payload.levelRelationship
                }
            }
        case actions.type.SET_MODAL_ADD_LEVEL_RELATIONSHIP_STATE:
            return {
                ...state, 
                ...{
                    mdAddLevelRelationshipState: payload.state
                }
            }
        case actions.type.LOAD_LIST_LEVEL_USER:
            return state
        case actions.type.LOAD_LIST_LEVEL_USER_SUCCESS:
            return {
                ...state,
                ...{
                    levelUsers: payload.levelUsers
                }
            }
        case actions.type.UPDATE_NEW_LEVEL_RELATIONSHIP:
            return {
                ...state,
                ...{
                    newLevelRelationship: {
                        ...state.newLevelRelationship,
                        ...payload.newLevelRelationship
                    }
                }
            }
        case actions.type.ADD_NEW_LEVEL_RELATIONSHIP:
            return state
        default:
            return state
    }
}

export default reducer