import Axios from "axios";
import { getSessionKey } from "../../services/utils/storageHelper";

import { server } from "./configs";

import https from "https";

const _makeRequest = (instantAxios) => async (args) => {
    const _headers = args.headers ? args.headers : {};
    const body = args.body ? args.body : {};
    const defaultHeaders = {};
    args = {
        ...args,
        headers: {
            ...defaultHeaders,
            ..._headers,
        },
        body,
    };

    const request = instantAxios(args);

    return request
        .then((response) => response.data)
        .catch((error) => {
            throw error.response.data ? error.response.data : error.response
        });
};

const _makeAuthRequest = (instantAxios) => async (args) => {
    const requestHeaders = args.headers ? args.headers : {};
    const accessToken = getSessionKey();

    let headers = {
        Authorization: `Bearer ${accessToken}`,
    };

    args = {
        ...args,
        headers: {
            ...requestHeaders,
            ...headers,
        },
    };

    const request = instantAxios(args);

    return request
        .then((response) => response.data)
        .catch((error) => {
            throw {
                message: error.response.data ? error.response.data : error.response,
            };
        });
};

// const _wrapCatchError = (request) => {
//     return request.catch(error => {
//         _catchError(error)

//         throw error
//     })
// }

// const _catchError = (error) => {

// }

export default (options = {}) => {
    let BaseURL = server;

    if (options.BaseURL) BaseURL = options.BaseURL;

    //const baseUrlValidated = options.baseUrl || getEnv('baseAPIUrl')
    const instance = Axios.create({
        httpsAgent: new https.Agent({
            rejectUnauthorized: false,
        }),
        baseURL: BaseURL,
        timeout: 30000,
    });

    return {
        makeRequest: _makeRequest(instance),
        makeAuthRequest: _makeAuthRequest(instance),
    };
};
