import createApiServices from './createApiServices'

const api = createApiServices()

export const getListCategoryPost = () => {
    return api.makeAuthRequest({
        url: 'newsCategory',
        method: 'GET'
    })
}

export const getDetailCategoryPost = (id) => {
    return api.makeAuthRequest({
        url: `newsCategory/${id}`,
        method: 'GET'
    })
}

export const updateCategoryPost = (id, categoryPost) => {
    return api.makeAuthRequest({
        url: `admin/managerNewsCategory/${id}`,
        method: 'PUT',
        data: categoryPost
    })
}

export const removeCategoryPost = (id) => {
    return api.makeAuthRequest({
        url: `admin/managerNewsCategory/${id}`,
        method: 'DELETE'
    })
}

export const getAllCategoryPost = () => {
    return api.makeAuthRequest({
        url: `newsCategory/all`,
        method: 'GET'
    })
}

export const createNewCategoryPost = (newCategoryPost) => {
    return api.makeAuthRequest({
        url: `admin/managerNewsCategory`,
        method: 'POST',
        data: newCategoryPost
    })
}