import React, { Component } from "react";

// redux
import { connect } from "react-redux";
import UserGlobalAction from "../../../../redux/user_global/actions";

// scss
import "./index.scss";
import { numberDisplayThousand } from "../../../../../services/utils/helper";

const list_flexs = [10, 15, 30, 30, 30];

class UserLevelPage extends Component {
    componentDidMount = () => {
        this.props.loadListLevel();
    };

    render() {
        let userInfo = this.props.userInfo ? this.props.userInfo : {}

        let current_level = userInfo.levelId ? userInfo.levelId : ""
        let levels = this.props.levels ? this.props.levels : [];

        return (
            <div className="UserLevelPage">
                <div className="ULP_Title">Cấp độ thành viên</div>

                <table className="CDS_Table" style={{ paddingBottom: 10 }}>
                    <tr className="CDS_TableRowHeader">
                        <th
                            className="CDS_HeaderText miniHide"
                            style={{
                                flex: list_flexs[0],
                                marginRight: 10,
                            }}
                        >
                            STT
                        </th>
                        <th
                            className="CDS_HeaderText"
                            style={{ flex: list_flexs[1] }}
                        >
                            Tên cấp độ
                        </th>
                        <th
                            className="CDS_HeaderText"
                            style={{ flex: list_flexs[2] }}
                        >
                            Ký quỹ tối thiều
                        </th>
                        <th
                            className="CDS_HeaderText superMiniHide"
                            style={{ flex: list_flexs[3] }}
                        >
                            Doanh thu tối thiểu
                        </th>
                        <th
                            className="CDS_HeaderText superMiniHide"
                            style={{ flex: list_flexs[4] }}
                        >
                            Số nhân viên cấp dưới tối thiểu
                        </th>
                    </tr>
                    {levels.map((level, index) => {
                        return (
                            <tr
                                className="CDS_TableRow"
                                style={{
                                    borderBottom:
                                        index === levels.length - 1
                                            ? "none"
                                            : "1px solid #e2e2e2",
                                    backgroundColor:
                                        level._id === current_level
                                            ? "#ffa500"
                                            : "white",
                                }}
                            >
                                <td
                                    className="CDS_TableCell miniHide"
                                    style={{
                                        flex: list_flexs[0],
                                        marginRight: 10,
                                    }}
                                >
                                    {index + 1}
                                </td>
                                <td
                                    className="CDS_TableCell"
                                    style={{
                                        flex: list_flexs[1],
                                        marginRight: 10,
                                    }}
                                >
                                    {level.name}
                                </td>
                                <td
                                    className="CDS_TableCell"
                                    style={{
                                        flex: list_flexs[2],
                                    }}
                                >
                                    {numberDisplayThousand(
                                        level.minimumDeposit
                                    )}
                                    đ
                                </td>
                                <td
                                    className="CDS_TableCell superMiniHide"
                                    style={{ flex: list_flexs[3] }}
                                >
                                    {numberDisplayThousand(
                                        level.minimumRevenue
                                    )}
                                    đ
                                </td>
                                <td
                                    className="CDS_TableCell superMiniHide"
                                    style={{ flex: list_flexs[4] }}
                                >
                                    {numberDisplayThousand(
                                        level.minimumChildren
                                    )}
                                </td>
                            </tr>
                        );
                    })}
                </table>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    let _state = state.user_global;
    return {
        levels: _state.levels,
        userInfo: state.auth.current_user_info
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        loadListLevel: () => {
            dispatch(UserGlobalAction.action.loadListLevel());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserLevelPage);
