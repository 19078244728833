const prefix = 'admin_voucher/'

const type = {
    UPDATE_STATE: prefix + 'update_state',

    // list customer group
    LOAD_LIST_CUSTOMER_GROUP: prefix + 'load_list_customer_group',
    LOAD_LIST_CUSTOMER_GROUP_SUCCESS: prefix + 'load_list_customer_group_success',

    // voucher
    LOAD_LIST_VOUCHER: prefix + 'load_list_voucher',
    LOAD_LIST_VOUCHER_SUCCESS: prefix + 'load_list_voucher_success',
    UPDATE_PAGE_INDEX: prefix + 'update_page_index',
    UPDATE_TEXT_SEARCH:  prefix + 'update_text_search',
    SEARCH_VOUCHER: prefix + 'search_voucher',

    // current voucher
    SET_CURRENT_VOUCHER: prefix + 'set_current_voucher',
    SET_DEFAULT_CURRENT_VOUCHER: prefix + 'set_default_current_voucher',
    SAVE_CURRENT_VOUCHER: prefix + 'save_current_voucher',
    UPDATE_CURRENT_VOUCHER: prefix + 'update_current_voucher',

    ACTIVE_VOUCHER: prefix + 'active_voucher',
    DEACTIVE_VOUCHER: prefix + 'deactive_voucher',

    SET_MD_VOUCHER_STATE: prefix + 'set_md_voucher_state'
}

const action = {
    updateState: (state = {}) => {
        return {
            type: type.UPDATE_STATE,
            payload: {
                state 
            }
        }
    },

    // list customer group
    loadListCustomerGroup: (params = {}) => {
        return {
            type: type.LOAD_LIST_CUSTOMER_GROUP,
            payload: {
                params
            }
        }
    },
    loadListCustomerGroupSuccess: (customer_groups = []) => {
        return {
            type: type.LOAD_LIST_CUSTOMER_GROUP_SUCCESS,
            payload: {
                customer_groups
            }
        }
    },
    
    // voucher
    loadListVoucher: (params = {}) => {
        return {
            type: type.LOAD_LIST_VOUCHER,
            payload: {
                params
            }
        }
    },
    loadListVoucherSuccess: (vouchers = []) => {
        return {
            type: type.LOAD_LIST_VOUCHER_SUCCESS,
            payload: {
                vouchers
            }
        }
    },
    updatePageIndex: (pageIndex = 1) => {
        return {
            type: type.UPDATE_PAGE_INDEX,
            payload: {
                pageIndex
            }
        }
    },
    updateTextSearch: (textSearch = "") => {
        return {
            type: type.UPDATE_TEXT_SEARCH,
            payload: {
                textSearch
            }
        }
    },
    searchVoucher: () => {
        return {
            type: type.SEARCH_VOUCHER,
            payload: {

            }
        }
    },

    // current voucher 
    setCurrentVoucher: voucher => {
        return {
            type: type.SET_CURRENT_VOUCHER,
            payload: {
                voucher
            }
        }
    },
    setDefaultCurrentVoucher: () => {
        return {
            type: type.SET_DEFAULT_CURRENT_VOUCHER,
            payload: {

            }
        }
    },
    updateCurrentVoucher: info => {
        return {
            type: type.UPDATE_CURRENT_VOUCHER,
            payload: {
                info
            }
        }
    },
    saveCurrentVoucher: () => {
        return {
            type: type.SAVE_CURRENT_VOUCHER,
            payload: {

            }
        }
    },

    activeVoucher: (id = "") => {
        return {
            type: type.ACTIVE_VOUCHER,
            payload: {
                id
            }
        }
    },
    deactiveVoucher: (id = "")=>{
        return {
            type: type.DEACTIVE_VOUCHER,
            payload: {
                id
            }
        }
    },
    setModalVoucherState: (state) => {
        return {
            type: type.SET_MD_VOUCHER_STATE,
            payload: {
                state
            }
        }
    }
}

export default {
    type,
    action
}