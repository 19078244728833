import {
  Button,
  ConfigProvider,
  DatePicker,
  InputNumber,
  List,
  Select,
  Spin,
} from "antd";
import locale from "antd/lib/locale/vi_VN";
import moment from "moment";
import "moment/locale/vi";
import React, { PureComponent } from "react";
import { FaSave, FaTimes } from "react-icons/fa";
import { connect } from "react-redux";
import NotificationsService from "../../../../services/utils/notifycationHelper";
import AdminVoucherActions from "../../../redux/admin_voucher/actions";
import CreateVoucherItem from "./components/create-voucher-item";
import CreateVoucherPopupChooseProduct from "./components/create-voucher-popup-choose-product";
import "./index.scss";
import {
  apiCreateVoucher,
  apiGetListProducts,
  apiUpdateVoucher,
  apiVoucherDetail,
} from "./model/create-voucher.service";

const { RangePicker } = DatePicker;

const ITEM_LOAD = 12;

class CreateVoucherScreen extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      code: "",
      description: "",
      groupCustomerId: undefined,
      discount: "",
      minCadoxu: "",
      maxCadoxu: "",
      startDate: "",
      endDate: "",
      products: [],
      productIds: [],
      loading: false,
      page: 1,
      loadingPage: true,
    };
    this.voucherId =
      (props.location.search && props.location.search.replace("?", "")) ||
      undefined;
  }

  componentDidMount() {
    this.props.init();
    this.onSetValueDefault();
  }

  onSetValueDefault = async () => {
    if (!this.voucherId) {
      this.setState({ loadingPage: false });
      return;
    }

    const voucher = await apiVoucherDetail(this.voucherId);

    if (!voucher) {
      this.setState({ loadingPage: false });
      return;
    }

    let {
      code,
      description,
      groupCustomerId,
      discount,
      minCadoxu,
      maxCadoxu,
      productIds,
      startDate,
      endDate,
    } = this.state;

    code = voucher.code || "";
    description = voucher.description || "";
    groupCustomerId = voucher.groupCustomerId || undefined;
    discount = voucher.discount || "";
    minCadoxu = voucher.minCadoxu || "";
    maxCadoxu = voucher.maxCadoxu || "";
    productIds = voucher.productIds || [];
    startDate = voucher.startDate || "";
    endDate = voucher.endDate || "";

    this.setState(
      {
        code,
        description,
        groupCustomerId,
        discount,
        minCadoxu,
        maxCadoxu,
        productIds,
        startDate,
        endDate,
        loadingPage: false,
      },
      () => {
        if (productIds.length > 0) {
          this.onGetListProducts(1);
        }
      }
    );
  };

  onChangeValue = (key) => (val) => {
    this.setState({ [key]: val });
  };

  onChangeValueInput = (key) => (val) => {
    this.setState({ [key]: val.target.value });
  };

  onSelectProductIds = (productIds) => {
    this.setState({ loading: true, productIds, page: 1 }, () => {
      this.onGetListProducts(1);
    });
  };

  onGetListProducts = async (pageIndex) => {
    const params = {
      pageIndex,
      pageSize: ITEM_LOAD,
      inIds: this.state.productIds,
      // keyword: this.keyword,
    };

    const { products, total } = await apiGetListProducts(params);

    this.totalProduct = total;

    this.setState({
      products,
      loading: false,
    });
  };

  onChangePage = (page) => {
    this.setState(
      {
        loading: true,
        page,
      },
      () => {
        this.onGetListProducts(page);
      }
    );
  };

  onSelectItem = (item) => (checked) => {
    let productIds = [...this.state.productIds];

    if (!checked) {
      productIds = productIds.filter((j) => j !== item._id);
    } else {
      productIds.push(item._id);
    }

    this.setState({ productIds });
  };

  onSave = async () => {
    const params = { ...this.state };

    if (params.startDate) params.startDate = new Date(params.startDate);
    if (params.endDate) params.endDate = new Date(params.endDate);

    if (!params.code) {
      NotificationsService.error("Mã khuyến mại không được để trống");
      return;
    }

    if (!params.description) {
      NotificationsService.error("Mô tả không được để trống");
      return;
    }

    if (!params.discount) {
      NotificationsService.error("Vui lòng nhập phần trăm giảm giá");
      return;
    }

    if (!params.startDate) {
      NotificationsService.error("Vui lòng chọn thời gian diễn ra khuyến mãi");
      return;
    }

    if (this.voucherId) {
      const res = await apiUpdateVoucher(this.voucherId, params);

      if (res.code === 200) {
        NotificationsService.success("Cập nhật thành công");
        this.props.history.goBack();

        return;
      }

      NotificationsService.error(res.message || "Cập nhật thất bại");

      return;
    }

    const res = await apiCreateVoucher(params);

    if (res.code === 200) {
      NotificationsService.success("Thêm mới thành công");
      this.props.history.goBack();

      return;
    }

    NotificationsService.error(res.message || "Thêm mới thất bại");
  };

  render() {
    const { customer_groups } = this.props;
    const {
      code,
      description,
      groupCustomerId,
      discount,
      minCadoxu,
      maxCadoxu,
      loading,
      productIds,
      products,
      page,
      startDate,
      endDate,
      loadingPage,
    } = this.state;

    if (loadingPage) {
      return <Spin size="large" />;
    }

    return (
      <div className="ACG_CreateVoucher">
        <div className="ACGM_Title">CHI TIẾT MÃ KHUYẾN MẠI</div>

        <div className="ACGM_RowInfo">
          <div className="ACGM_RowTitle">Mã khuyến mại</div>
          <div className="ACGM_RowContent">
            <input
              className="ACGM_RowContent_Input"
              value={code}
              onChange={this.onChangeValueInput("code")}
            />
          </div>
        </div>

        <div className="ACGM_RowInfo">
          <div className="ACGM_RowTitle">Mô tả</div>
          <div className="ACGM_RowContent">
            <textarea
              className="ACGM_RowContent_Input"
              value={description}
              onChange={this.onChangeValueInput("description")}
            />
          </div>
        </div>

        <div className="ACGM_RowInfo">
          <div className="ACGM_RowTitle">Giảm giá(%)</div>
          <div className="ACGM_RowContent">
            <InputNumber
              min={3}
              max={99}
              className="ACGM_RowContent_Input"
              style={{ width: "100%" }}
              value={discount}
              onChange={this.onChangeValue("discount")}
            />
          </div>
        </div>

        <div className="ACGM_RowInfo">
          <div className="ACGM_RowTitle">Số xu tối thiểu</div>
          <div className="ACGM_RowContent">
            <InputNumber
              min={1}
              className="ACGM_RowContent_Input"
              style={{ width: "100%" }}
              value={minCadoxu}
              onChange={this.onChangeValue("minCadoxu")}
            />
          </div>
        </div>

        <div className="ACGM_RowInfo">
          <div className="ACGM_RowTitle">Số xu tối đa</div>
          <div className="ACGM_RowContent">
            <InputNumber
              min={1}
              className="ACGM_RowContent_Input"
              style={{ width: "100%" }}
              value={maxCadoxu}
              onChange={this.onChangeValue("maxCadoxu")}
            />
          </div>
        </div>

        <div className="ACGM_RowInfo">
          <div className="ACGM_RowTitle">Thời gian khuyến mãi</div>
          <ConfigProvider locale={locale}>
            <RangePicker
              defaultValue={
                (startDate && [moment(startDate), moment(endDate)]) || []
              }
              style={{ flex: 1 }}
              showTime={{ format: "HH:mm" }}
              placeholder={["Bắt đầu", "Kết thúc"]}
              format="HH:mm YYYY-MM-DD"
              onChange={(value, dateString) => {
                this.setState({
                  startDate: dateString[0],
                  endDate: dateString[1],
                });
              }}
            />
          </ConfigProvider>
        </div>

        <div className="ACGM_RowInfo">
          <div className="ACGM_RowTitle">Nhóm khách hàng</div>
          <div className="ACGM_RowContent">
            <Select
              ref={(r) => (this.groupCustomerRef = r)}
              value={groupCustomerId}
              onChange={this.onChangeValue("groupCustomerId")}
              allowClear
            >
              {customer_groups.map((customer_group, index) => {
                return (
                  <Select.Option value={customer_group._id}>
                    {customer_group.name}
                  </Select.Option>
                );
              })}
            </Select>
          </div>
        </div>

        <div className="ACGM_RowInfo">
          <div className="ACGM_RowTitle">Danh sách sản phẩm khuyến mãi</div>
        </div>

        <Button
          htmlType="button"
          type="primary"
          onClick={() => {
            this.refModal.open(productIds || []);
          }}
        >
          Chọn sản phẩm
        </Button>

        {products.length > 0 ? (
          <div>
            <List
              grid={{ gutter: 16, column: 4 }}
              size="large"
              loading={loading}
              pagination={{
                onChange: this.onChangePage,
                current: page,
                pageSize: ITEM_LOAD,
                total: this.totalProduct,
              }}
              style={{ marginLeft: 8, marginRight: 8, flexDirection: "row" }}
              dataSource={products}
              renderItem={(item, i) => {
                return (
                  <CreateVoucherItem
                    item={item}
                    isChecked={productIds.includes(item._id)}
                    onSelect={this.onSelectItem(item)}
                  />
                );
              }}
            />
          </div>
        ) : null}

        <div className="ACGM_RowInfo_Action">
          <div className="ACGM_Buttonz" onClick={this.onSave}>
            <FaSave className="ACGM_Buttonz_Icon" />
            Lưu
          </div>
          <div
            className="ACGM_Buttonz"
            onClick={() => {
              this.resetErrorState();
              this.props.setModalCurrentVoucherState(false);
            }}
            style={{
              backgroundColor: "red",
            }}
          >
            <FaTimes className="ACGM_Buttonz_Icon" />
            Hủy
          </div>
        </div>

        <CreateVoucherPopupChooseProduct
          ref={(ref) => (this.refModal = ref)}
          onSelect={this.onSelectProductIds}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const _state = state.admin_voucher;

  return {
    customer_groups: _state.customer_groups,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    init: () => {
      dispatch(AdminVoucherActions.action.loadListCustomerGroup({}));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateVoucherScreen);
