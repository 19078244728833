// MY = month-year

const getMYFromTime = (time = new Date()) => {
    var mm = String(time.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = time.getFullYear();

    return `${mm}-${yyyy}`;
};

const getCurrentMY = () => {
    let today = new Date();

    return getMYFromTime(today);
};

const getOnYearAgoMY = () => {
    let ans = []
    
    for (let index = 0; index <= 11; index++) {
        var d = new Date();
        d.setMonth(d.getMonth() - index);
        ans.push(getMYFromTime(d))
    }

    return ans;
};

const getObjectFromMY = (MY_str = "") => {
    try {
        let s = MY_str.split("-");
        return {
            month: parseInt(s[0]),
            year: parseInt(s[1]),
        };
    } catch {
        return {
            month: "",
            year: "",
        };
    }
};

const getObjectFromTime = (da = new Date()) => {
    try {
        let MY_str = getMYFromTime(da)
        let s = MY_str.split("-");
        return {
            month: parseInt(s[0]),
            year: parseInt(s[1]),
        };
    } catch {
        return {
            month: "",
            year: "",
        };
    }
};

const getDateFromMy = (MY_str = "") => {
    let ans = new Date()

    try {
        let s = MY_str.split("-");
        ans.setMonth(s[0])
        ans.setFullYear(s[1])
        return ans
    }catch {
        return ans
    }
}

export default {
    getCurrentMY,
    getOnYearAgoMY,
    getObjectFromMY,
    getObjectFromTime,
    getDateFromMy,
    getMYFromTime
};
