import createApiServices from './createApiServices'

const api = createApiServices()

export const getListFlashSale = (pageSize = 20, pageIndex = 1) => {
    return api.makeAuthRequest({
        url: `flashSales?pageSize=${pageSize}&pageIndex=${pageIndex}`,
        method: 'GET'
    })
}

export const createFlashSale = (newFlashSale) => {
    return api.makeAuthRequest({
        url: `admin/managerFlashSales/createCampaignFlashSales`,
        method: 'POST',
        data: newFlashSale
    })
}

export const removeFlashSale = (id) => {
    return api.makeAuthRequest({
        url: `admin/managerFlashSales/removeFlashSales/${id}`,
        method: 'DELETE'
    })
}

export const getListFlashSaleDetail = (id) => {
    return api.makeAuthRequest({
        url: `flashSales/getDetailCampaignFlashSales/${id}`,
        method: 'GET'
    })
}

export const removeProductFlashSale = (id) => { 
    return api.makeAuthRequest({
        url: `admin/managerFlashSales/removeFlashSale/${id}`,
        method: 'DELETE'
    })
}

export const updateProductFlashSale = (id, productUpdateData) => {
    return api.makeAuthRequest({
        url: `admin/managerFlashSales/updateFlashSalesDetail/${id}`,
        method: 'PUT',
        data: productUpdateData
    })
}

export const addProductFlashSale = (id, productFlashSales) => {
    return api.makeAuthRequest({
        url: `admin/managerFlashSales/createDetailFlashSales/${id}`,
        method: 'POST',
        data: productFlashSales
    })
}

export const removeAllProductFlashSale = (id) => {
    return api.makeAuthRequest({
        url: `admin/managerFlashSales/removeAllFlashSalesDetail/${id}`,
        method: 'DELETE'
    })
}

export const getListProductNotExistsFlashSale = (id, pageSize, pageIndex, keyword) => {
    return api.makeAuthRequest({
        url: `flashSales/getProductsNotExistFlashSales/${id}?pageSize=${pageSize}&pageIndex=${pageIndex}&keyword=${keyword}`,
        method: 'GET'
    })
}

export const updateFlashSale = (id, flashSale) => {
    return api.makeAuthRequest({
        url: `admin/managerFlashSales/updateCampaignFlashSales/${id}`,
        method: 'PUT',
        data: flashSale
    })
}