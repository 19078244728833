import React from 'react';
import './index.scss';

class EmptySearch extends React.Component {
    render() {
        return (
            <div className='d-flex justify-content-center align-items-center empty-search'>Không có kết quả tìm kiếm</div>
        )
    }
}

export default EmptySearch;