import React, { Component } from "react";
// icons
import { FaSearch } from "react-icons/fa";
// redux
import { connect } from "react-redux";
// component
import Modal from "../../../../../components/modal";
import Pagination from "../../../../../components/paginations/paginations";
import { getImageUrl } from "../../../../../services/api/upload";
import AdminCreateOrderActions from "../../../../redux/admin_create_order/actions";
import "./index.scss";

class ModalSelectUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 10,
      pageIndex: 1,
      total: 30,
    };
  }

  renderUser = (user, index) => {
    return (
      <div class="MSU_User_Row">
        <div className="MSU_ImageView">
          <img src={getImageUrl(user.avatar)} className="MSU_UserAvatar" />
        </div>
        <div style={{ marginLeft: 10 }}>
          <div className="MSU_UserName">{user.fullName}</div>
          <div>{user.phone}</div>
          {user.email ? <div>{user.email}</div> : null}
        </div>
        <div
          className="MSU_Action"
          onClick={() => {
            if (this.props.onSelectUser) {
              this.props.onSelectUser && this.props.onSelectUser(user);
              this.props.setModalSelectUserState(false);
              return;
            }

            this.props.setModalSelectUserState(false);
            this.props.selectUser(user);
          }}
        >
          Chọn
        </div>
      </div>
    );
  };

  render() {
    let { modalSelectUserState } = this.props;
    let { pageSize, pageIndex, total } = this.props;
    const list_users = this.props.list_users;

    return (
      <Modal
        isOpen={modalSelectUserState}
        setIsOpen={(value) => {
          this.props.setModalSelectUserState(value);
        }}
        displayCloseIcon={false}
      >
        <div className="ModalSelectUser_COP">
          {/* ModalSelectAddress */}
          <div className="MSU_Action">
            <div className="MSU_Title">Chọn người dùng</div>
            <div className="APL_SearchBoxPanel">
              <input
                type="text"
                placeholder="Tìm kiếm"
                aria-label="Tim kiem"
                name="keyword"
                value={this.props.textSearch}
                onChange={(e) => {
                  this.props.updateTextSearch(e.target.value);
                }}
                className="APL_SearchBox"
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    this.props.searchUser(this.props.textSearch);
                  }
                }}
              />
              <FaSearch
                className="APL_SearchBoxIcon"
                onClick={() => {
                  this.props.searchUser(this.props.textSearch);
                }}
              />
            </div>
          </div>

          <div className="MSU_ListUsers">
            {list_users.map((user, index) => {
              return this.renderUser(user, index);
            })}
          </div>

          <div className="UFP_PaginateSection">
            <Pagination
              onChange={(index) => {
                this.props.updatePageIndex(index);
                // this.setState({ pageIndex: index });
              }}
              pageSize={pageSize}
              pageIndex={pageIndex}
              total={total}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const _state = state.admin_create_order;

  return {
    isLoading: _state.md_user_is_loading,
    modalSelectUserState: _state.modalSelectUserState,
    list_users: _state.list_users,
    total: _state.md_user_total,
    pageSize: _state.md_user_page_size,
    pageIndex: _state.md_user_page_index,
    textSearch: _state.md_user_text_search,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setModalSelectUserState: (state) => {
      dispatch(AdminCreateOrderActions.action.setModalSelectUserState(state));
    },

    // user
    updatePageIndex: (pageIndex) => {
      dispatch(AdminCreateOrderActions.action.mdUserUpdatePageIndex(pageIndex));
    },
    updateTextSearch: (textSearch) => {
      dispatch(
        AdminCreateOrderActions.action.mdUserUpdateTextSearch(textSearch)
      );
    },
    searchUser: () => {
      dispatch(AdminCreateOrderActions.action.mdUserSearchUser());
    },
    selectUser: (user = {}) => {
      dispatch(AdminCreateOrderActions.action.selectUser(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalSelectUser);
