import React, { Component } from "react";

// redux
import { connect } from "react-redux";
// icon 
import { FaCheckCircle } from 'react-icons/fa'
// router
import { Link } from "react-router-dom";
import router_links from "../../../../../../const/router_link";

import './index.scss'

class PaymentSuccess extends Component {
    render() {
        return (
            <div className="PaymentFailPage">
                <div className="PaymentResultForm">
                    <FaCheckCircle className="Icon" style={{color: 'green'}} />
                    <div className="Title">Thanh toán đơn hàng thành công</div>
                    <div className="Action">
                        <Link
                            className="PFP_Fail"
                            to={router_links.U_HOME_PAGE}
                        >
                            Trang chủ
                        </Link>
                        <Link
                            className="PFP_Fail"
                            to={router_links.U_PROFILE_HISTORY_BILL}
                        >
                            Đơn hàng của tôi
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = () => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSuccess);
