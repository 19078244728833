import createApiServices from './createApiServices';

const api = createApiServices();

const CreateRequestChangeCustomerGroup = (info = {}) => {
    return api.makeAuthRequest({
        url: `requestGroupCustomer/sendRequestApprove`,
        method: 'POST',
        data: info
    })
}

const getListRequestChangeCustomerGroup = (pageIndex = 1, pageSize = 10, params) => {
    const textParams = []
    for (const param of params) {
        const textParam = param.name + '=' + param.value;
        textParams.push(textParam);
    }
    let paramRequest = '';
    if (textParams.length) {
        paramRequest = '&' + textParams.join('&')
    }
    return api.makeAuthRequest({
        url: `admin/managerRequestApproveGroupCustomer?pageIndex=${pageIndex}&pageSize=${pageSize}` + paramRequest,
        method: 'GET'
    })
}

const updateStatusRequestChangeCustomerGroup = (id = '') => {
    return api.makeAuthRequest({
        url: `admin/managerRequestApproveGroupCustomer/approve/${id}`,
        method: 'GET'
    })
}

const cancelRequestChangeCustomerGroup = (id = '') => {
    return api.makeAuthRequest({
        url: `admin/managerRequestApproveGroupCustomer/cancel/${id}`,
        method: 'GET'
    })
}

const loadDetailRequestChangeCustomerGroup = (id = '') => {
    return api.makeAuthRequest({
        url: `admin/managerRequestApproveGroupCustomer/getDetail/${id}`,
        method: 'GET'
    })
}

export const RequestChangeCustomerGroupAPIServices = {
    CreateRequestChangeCustomerGroup
}

export default {
    getListRequestChangeCustomerGroup,
    updateStatusRequestChangeCustomerGroup,
    cancelRequestChangeCustomerGroup,
    loadDetailRequestChangeCustomerGroup
}