import { getRelateProducts } from "../../api/product";

const validate = (entity) => {
    // function
    const errorResult = (message) => {
        return {
            isValidate: false,
            message,
        };
    };

    // validate
    if (!entity.userId)
        return errorResult("Chưa có người dùng nào được chọn")
    
    if (entity.paymentMethod !== "DIRECT" && !entity.addressId){
        return errorResult("Chưa có địa chỉ nhận hàng được chọn")
    }

    if (entity.products.length <= 0) 
        return errorResult("Chưa có sản phẩm nào được chọn")

    return {
        isValidate: true,
        message: "success",
    };
};

export default {
    validate,
};
