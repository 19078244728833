import ApiUtils from "../../../../services/ApiUtils";

const apiName = {
  ME: "/ware-house/me",
  WARE_HOUSE: "/ware-house",
};

export const apiGetWareHouseMe = async (params) => {
  const res = await ApiUtils.fetch(apiName.ME, params, {
    isAuth: true,
  });

  return res?.data || [];
};
