import React, { Component } from 'react'
import { FaEdit, FaPlusCircle, FaTrash } from 'react-icons/fa';
// scss
import './index.scss'
// redux
import { connect } from 'react-redux'
import AdminFlashSaleActions from '../../../redux/admin_flash_sale/actions'

//component 
import Button from '../../../../components/button'
import Pagination from '../../../../components/paginations/paginations';
import ModalAddFlashSale from './modal_add_flash_sale'

import { Link } from 'react-router-dom';
import { convertDateToDatetime, convertIsoDateToString, dateFormat } from '../../../../services/utils/helper';
import Router_Links from '../../../../const/router_link'
import ModalConfirmDelete from '../../../../components/modal/modal_confirm_action'
import ModalEditFlashSale from './modal_edit_flash_sale'

const list_flexs = [15, 15, 15, 15, 15, 10];

class ListFlashSale extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showConfirmMd: false,
            removeId: ''
        }
    }

    componentDidMount() {
        this.props.init()
    }

    onCancelMdConfirm = () => {
        this.setState({ showConfirmMd: false, removeId: '' });
    }

    confirmRemoveCategory = () => {
        const { removeId } = this.state;
        if (typeof this.props.removeFlashSale === 'function') {
            this.props.removeFlashSale(removeId)
        }
        this.onCancelMdConfirm();
    }

    onClickButtonAddFlashSale = () => {
        if (typeof this.props.setModalAddFlashSaleState === 'function') {
            this.props.setModalAddFlashSaleState(true)
        }
    }

    onClickButtonRemoveFlashSale = (id) => {
        this.setState({ showConfirmMd: true, removeId: id });
    }

    onClickButtonEditFlashSale = (flashSale) => {
        this.props.setCurrentFlashSale(flashSale);
        this.props.setModalEditFlashSaleState(true)
    }

    render() {
        const { flashSales, pageSize, pageIndex, total, updatePageIndex } = this.props
        const { showConfirmMd } = this.state;
        return (
            <div className="ListFlashSale">
                <div className="d-flex LFS_Actions">
                    <Button
                        className="LFS_ButtonAdd"
                        label="Tạo chiến dịch mới"
                        icon={<FaPlusCircle style={{ marginRight: 4 }} />}
                        onClick={this.onClickButtonAddFlashSale}
                    />
                </div>
                <table className="CDS_Table">
                    <tr className="CDS_TableRowHeader">
                        <th
                            className="CDS_HeaderText"
                            style={{
                                flex: list_flexs[0],
                                justifyContent: "left",
                            }}
                        >
                            Mã chiến dịch
                        </th>
                        <th
                            className="CDS_HeaderText"
                            style={{
                                flex: list_flexs[1],
                                justifyContent: "left",
                            }}
                        >
                            Tên chiến dịch
                        </th>
                        <th
                            className="CDS_HeaderText"
                            style={{ flex: list_flexs[2] }}
                        >
                            Mô tả
                        </th>
                        <th
                            className="CDS_HeaderText"
                            style={{ flex: list_flexs[3] }}
                        >
                            Thời gian
                        </th>
                        <th
                            className="CDS_HeaderText"
                            style={{ flex: list_flexs[4] }}
                        >
                            Ngày tạo
                        </th>
                        <th
                            className="CDS_HeaderText"
                            style={{ flex: list_flexs[5] }}
                        >
                            Hành động
                        </th>
                    </tr>
                    {flashSales.map((flashSale, index) => (

                        <tr
                            key={flashSale._id}
                            className="CDS_TableRow"
                            style={{
                                borderBottom:
                                    index === flashSales.length - 1
                                        ? "none"
                                        : "1px solid #e2e2e2",
                            }}
                        >
                            <td
                                className="CDS_TableCell"
                                style={{
                                    flex: list_flexs[0],
                                    justifyContent: "left",
                                }}
                            >
                                <Link
                                    to={`${Router_Links.A_FLASH_SALE_DETAIL}/${flashSale._id}`}
                                    style={{
                                        fontWeight: "bold",
                                        textDecoration: "underline",
                                        color: "black",
                                    }}
                                >
                                    {flashSale._id}
                                </Link>
                            </td>
                            <td
                                className="CDS_TableCell"
                                style={{
                                    flex: list_flexs[1],
                                    justifyContent: "left",
                                }}
                            >
                                {flashSale.name}
                            </td>
                            <td
                                className="CDS_TableCell"
                                style={{ flex: list_flexs[2] }}
                            >
                                {flashSale.description}
                            </td>
                            <td
                                className="CDS_TableCell d-block text-center"
                                style={{ flex: list_flexs[3] }}
                            >
                                <div>{convertDateToDatetime(flashSale.startTime)}</div>
                                &mdash;
                                <div>{convertDateToDatetime(flashSale.stopTime)}</div>
                            </td>
                            <td
                                className="CDS_TableCell"
                                style={{ flex: list_flexs[4] }}
                            >
                                {convertIsoDateToString(flashSale.createdTime)}
                            </td>
                            <td
                                className="CDS_TableCell"
                                style={{
                                    flex: list_flexs[5],
                                }}
                            >
                                <Button
                                    className="LFS_Table_Action_Edit"
                                    label="Sửa"
                                    icon={<FaEdit style={{ marginRight: 4 }} />}
                                    onClick={() => this.onClickButtonEditFlashSale(flashSale)}
                                />
                                <Button
                                    className="LFS_Table_Action_Delete"
                                    label="Xóa"
                                    icon={<FaTrash style={{ marginRight: 4 }} />}
                                    onClick={() => this.onClickButtonRemoveFlashSale(flashSale._id)}
                                />
                            </td>
                        </tr>
                    ))}
                </table>

                <div className="LFS_Pagination">
                    <Pagination
                        onChange={(index) => {
                            updatePageIndex(index);
                        }}
                        pageSize={pageSize}
                        pageIndex={pageIndex}
                        total={total}
                    />
                </div>

                <ModalAddFlashSale />
                <ModalEditFlashSale />
                <ModalConfirmDelete
                    isOpen={showConfirmMd}
                    onCancel={this.onCancelMdConfirm}
                    setIsOpen={this.onCancelMdConfirm}
                    onOk={this.confirmRemoveCategory}
                    text="Bạn có chắc chắn muốn xóa flash sale vừa chọn không?"
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    let _state = state.admin_flash_sale
    return {
        flashSales: _state.flashSales,
        pageSize: _state.pageSize,
        pageIndex: _state.pageIndex,
        total: _state.total
    }
}

const mapDispatchToProps = dispatch => {
    return {
        init: () => {
            dispatch(AdminFlashSaleActions.action.loadListFlashSale({}))
        },
        updatePageIndex: (pageIndex) => {
            dispatch(AdminFlashSaleActions.action.updatePageIndex(pageIndex))
        },
        setModalAddFlashSaleState: (state) => {
            dispatch(AdminFlashSaleActions.action.setModalAddFlashSaleState(state))
        },
        removeFlashSale: (id) => {
            dispatch(AdminFlashSaleActions.action.removeFlashSale(id))
        },
        setCurrentFlashSale: (flashSale) => {
            dispatch(AdminFlashSaleActions.action.setCurrentFlashSale(flashSale))
        },
        setModalEditFlashSaleState: (state) => {
            dispatch(AdminFlashSaleActions.action.setModalEditFlashSaleSate(state))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListFlashSale)