const prefix = "admin_create_order/"

const type = {
    UPDATE_STATE: prefix + 'update_state',

    // modal 
    SET_MODAL_SELECT_PRODUCT_STATE: prefix + 'set_modal_select_product_state',
    SET_MODAL_SELECT_USER_STATE: prefix + 'set_modal_select_user_state',
    SET_MODAL_SELECT_ADDRESS_STATE: prefix + 'set_modal_select_address_state',
    
    // modal product 
    LOAD_LIST_PRODUCT: prefix + 'load_list_product',
    LOAD_LIST_PRODUCT_SUCCESS: prefix + 'load_list_product_success',
    MD_PRODUCT_UPDATE_PAGE_INDEX: prefix + 'md_product_update_page_index',
    MD_PRODUCT_UPDATE_TEXT_SEARCH: prefix + 'md_product_update_text_search',
    MD_PRODUCT_SEARCH_PRODUCT: prefix + 'md_product_search_product',

    // modal user 
    LOAD_LIST_USER: prefix + 'load_list_user',
    LOAD_LIST_USER_SUCCESS: prefix + 'load_list_user_success',
    MD_USER_UPDATE_PAGE_INDEX: prefix + 'md_user_update_page_index',
    MD_USER_UPDATE_TEXT_SEARCH: prefix + 'md_user_update_text_search',
    MD_USER_SEARCH_USER: prefix + 'md_user_search_user',
    SELECT_USER: prefix + 'select_user',

    // modal address shipping 
    LOAD_LIST_ADDRESS: prefix + 'load_list_address',
    LOAD_LIST_ADDRESS_SUCCESS: prefix + 'load_list_address_success',
    MD_ADDRESS_UPDATE_PAGE_INDEX: prefix + 'md_address_update_page_index',
    MD_ADDRESS_UPDATE_TEXT_SEARCH: prefix + 'md_address_update_text_search',
    MD_ADDRESS_SEARCH_ADDRESS: prefix + 'md_address_search_address',
    SELECT_ADDRESS: prefix + 'select_address',

    // current order 
    SET_DEFAULT_CURRENT_ORDER_INFO: prefix + 'set_default_current_order_info',
    UPDATE_CURRENT_ORDER_INFO: prefix + 'update_current_order_info',

    GET_CURRENT_VOUCHER_APPLY: prefix + 'get_current_voucher_apply',
    GET_CURRENT_VOUCHER_APPLY_SUCCESS: prefix + 'get_current_voucher_apply_success',
    
    GET_SHIPPING_INFO: prefix + 'get_shipping_info',
    GET_SHIPPING_INFO_SUCCESS: prefix + 'get_shipping_info_success',
    GET_DISCOUNT_INFO: prefix + 'get_discount_info',
    GET_DISCOUNT_INFO_SUCCESS: prefix + 'get_discount_info_success',
    SET_DEFAULT_DISCOUNT_INFO: prefix + 'set_default_discount_info',

    // list product 
    INSERT_PRODUCT_ITEM_PREPARE: prefix + 'insert_product_item_prepare',
    INSERT_PRODUCT_ITEM: prefix + 'insert_product_item',
    UPDATE_PRODUCT_ITEM: prefix + 'update_product_item',
    REMOVE_PRODUCT_ITEM: prefix + 'remove_product_item',

    // order save 
    SAVE_CURRENT_ORDER: prefix + 'save_current_order'
}

const action = {
    updateState: state => {
        return {
            type: type.UPDATE_STATE,
            payload: {
                state
            }
        }
    },

    // modal
    setModalSelectProductState: state => {
        return {
            type: type.SET_MODAL_SELECT_PRODUCT_STATE,
            payload: {
                state
            }
        }
    },
    setModalSelectUserState: state => {
        return {
            type: type.SET_MODAL_SELECT_USER_STATE,
            payload: {
                state
            }
        }
    },
    setModalSelectAddressState: state => {
        return {
            type: type.SET_MODAL_SELECT_ADDRESS_STATE,
            payload: {
                state
            }
        }
    },

    // modal product 
    loadListProduct: (params = {}) => {
        return {
            type: type.LOAD_LIST_PRODUCT,
            payload: {
                params
            }
        }
    },
    loadListProductSuccess: (products = []) => {
        return {
            type: type.LOAD_LIST_PRODUCT_SUCCESS,
            payload: {
                products
            }
        }
    },
    mdProductUpdatePageIndex: (pageIndex = 1) => {
        return {
            type: type.MD_PRODUCT_UPDATE_PAGE_INDEX,
            payload: {
                pageIndex
            }
        }
    },
    mdProductUpdateTextSearch: (textSearch = "") => {
        return {
            type: type.MD_PRODUCT_UPDATE_TEXT_SEARCH,
            payload: {
                textSearch
            }
        }
    },
    mdProductSearchProduct: () => {
        return {
            type: type.MD_PRODUCT_SEARCH_PRODUCT,
            payload: {

            }
        }
    },

    // modal user
    loadListUser: (params = {}) => {
        return {
            type: type.LOAD_LIST_USER,
            payload: {
                params
            }
        }
    },
    loadListUserSuccess: (users = []) => {
        return {
            type: type.LOAD_LIST_USER_SUCCESS,
            payload: {
                users
            }
        }
    },
    mdUserUpdatePageIndex: (pageIndex = 1) => {
        return {
            type: type.MD_USER_UPDATE_PAGE_INDEX,
            payload: {
                pageIndex
            }
        }
    },
    mdUserUpdateTextSearch: (textSearch = "") => {
        return {
            type: type.MD_USER_UPDATE_TEXT_SEARCH,
            payload: {
                textSearch
            }
        }
    },
    mdUserSearchUser: () => {
        return {
            type: type.MD_USER_SEARCH_USER,
            payload: {

            }
        }
    },
    selectUser: (user = {}) => {
        return {
            type: type.SELECT_USER,
            payload: {
                user
            }
        }
    },

    // modal address shipping 
    loadListAddress: (userId = "", params = {}) => {
        return {
            type: type.LOAD_LIST_ADDRESS,
            payload: {
                userId,
                params
            }
        }
    },
    loadListAddressSuccess: (list_address = []) => {
        return {
            type: type.LOAD_LIST_ADDRESS_SUCCESS,
            payload: {
                list_address
            }
        }
    },
    mdAddressUpdatePageIndex: (pageIndex = 1) => {
        return {
            type: type.MD_ADDRESS_UPDATE_PAGE_INDEX,
            payload: {
                pageIndex
            }
        }
    },
    mdAddressUpdateTextSearch: (textSearch = "") => {
        return {
            type: type.MD_ADDRESS_UPDATE_TEXT_SEARCH,
            payload: {
                textSearch
            }
        }
    },
    mdAddressSearchAddress: () => {
        return {
            type: type.MD_ADDRESS_SEARCH_ADDRESS,
            payload: {

            }
        }
    },
    selectAddress: (address = {}) => {
        return {
            type: type.SELECT_ADDRESS,
            payload: {
                address
            }
        }
    },

    // current order
    setDefaultCurrentOrderInfo: () => {
        return {
            type: type.SET_DEFAULT_CURRENT_ORDER_INFO,
            payload: {
                
            }
        }
    },
    updateCurrentOrderInfo: (info = {}) => {
        return {
            type: type.UPDATE_CURRENT_ORDER_INFO,
            payload: {
                info
            }
        }
    },

    getCurrentVoucherApply: () => {
        return {
            type: type.GET_CURRENT_VOUCHER_APPLY,
            payload: {

            }
        }
    },
    getCurrentVoucherApplySuccess: (voucher = {}) => {
        return {
            type: type.GET_CURRENT_VOUCHER_APPLY_SUCCESS,
            payload: {
                voucher
            }
        }
    },

    getShippingInfo: (shippingType = "", addressId = "") => {
        return {
            type: type.GET_SHIPPING_INFO,
            payload: {
            }
        }
    },
    getShippingInfoSuccess: (shippingInfo = {}) => {
        return {
            type: type.GET_SHIPPING_INFO_SUCCESS,
            payload: {
                shippingInfo
            }
        }
    },
    getDiscountInfo: (code = "") => {
        return {
            type: type.GET_DISCOUNT_INFO,
            payload: {
                code
            }
        }
    },
    getDiscountInfoSuccess: (discountInfo = {}) => {
        return {
            type: type.GET_DISCOUNT_INFO_SUCCESS,
            payload: {
                discountInfo
            }
        }
    },
    setDefaultDiscountInfo: () => {
        return {
            type: type.SET_DEFAULT_DISCOUNT_INFO,
            payload: {

            }
        }
    },

    // list product
    insertProductItemPrepare: (productId = "", quantity = 0)  => {
        return {
            type: type.INSERT_PRODUCT_ITEM_PREPARE,
            payload: {
                productId,
                quantity
            }
        }
    },
    insertProductItem: (productItem = {}) => {
        return {
            type: type.INSERT_PRODUCT_ITEM,
            payload: {
                productItem
            }
        }
    },
    updateProductItem: (id = "", quantity = 0) => {
        return {
            type: type.UPDATE_PRODUCT_ITEM,
            payload: {
                id,
                quantity
            }
        }
    },
    removeProductItem: (id = "") => {
        return {
            type: type.REMOVE_PRODUCT_ITEM,
            payload: {
                id
            }
        }
    },

    // order save
    saveCurrentOrder: () => {
        return {
            type: type.SAVE_CURRENT_ORDER,
            payload: {

            }
        }
    }
}

export default {
    type,
    action
}