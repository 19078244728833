import React, { Component } from "react";

import "./cart_panel.scss";

// icons
import { FaShoppingCart } from "react-icons/fa";

// redux
import { connect } from "react-redux";

import CartPopUp from "./cart_popup";
import { numberDisplayThousand } from "../../../../services/utils/helper";

class CardPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showCartPopup: false,
        };
    }

    _hideCartPopup = () => {
        this.setState({ showCartPopup: false })
    }

    render() {
        let total = this.props.total ? this.props.total : 0
        let carts = this.props.carts ? this.props.carts : []

        return (
            <div>
                <div
                    className="CardPanel"
                    style={{
                        display: this.state.showCartPopup ? "none" : "flex",
                    }}
                    onClick={() => this.setState({ showCartPopup: true })}
                >
                    <div className="RowItem">
                        <div style={{position: "relative"}}>
                            <FaShoppingCart className="Icon" />

                            <div
                                className="NumberItem"
                                style={{ display: carts.length === 0 ? "none" : "flex" }}
                            >
                                {
                                    carts.length
                                }
                            </div>
                        </div>

                        <span className="Title">Giỏ hàng</span>
                    </div>
                    <div className="ColItem">
                        <span className="TotalPrice">
                            <FaShoppingCart className="Icon" />
                            {numberDisplayThousand(total)} đ
                        </span>
                    </div>
                </div>
                <CartPopUp
                    style={{
                        display: this.state.showCartPopup ? "flex" : "none",
                    }}
                    hideCartPopup={() => this._hideCartPopup()}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    let _state = state.user_global

    return {
        total: _state.total,
        carts: _state.carts
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CardPanel);
