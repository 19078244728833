import React, { Component } from 'react'

import './index.scss'

// component
import ListComments from './list_comments'
import InfoPanel from './info'
import BottomControlPanel from './bottom_control_panel'

// redux
import { connect } from 'react-redux'
import actions from '../../../../redux/admin_post/actions'

// ui component
import { Tabs } from 'antd'
const { TabPane } = Tabs;

class DetailPostPage extends Component {

    constructor(props){
        super(props)

        this.state = {
            title: ""
        }
    }

    componentDidMount = () => {
        this.props.init()

        // check create or add post 
        let postId = this.props.match.params.id ? this.props.match.params.id : ""

        if (postId === "") {
            // create post
            this.props.setDefaultPost()
            this.setState({ title: "Tạo bài viết" })
        }
        else {
            // post detail
            this.props.loadPostDetail(postId)
            this.setState({ title: "Chi tiết bài viết" })
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="ADP_Title_Page">
                    {
                        this.state.title
                    }
                </div>

                <div className="AdminDetailPostPage" style={{ paddingLeft: 10 }}>
                    <InfoPanel />
                    <BottomControlPanel />

                    {/* <Tabs defaultActiveKey="1" >
                    <TabPane tab="Thông tin" key="1">


                    </TabPane>
                    <TabPane tab="Danh sách bình luận" key="2">
                        <ListComments />
                    </TabPane>
                </Tabs> */}

                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {

    }
}

const mapDispatchToProps = dispatch => {
    return {
        init: () => {
            dispatch(actions.action.loadListCategoryPost())
        },
        loadPostDetail: postId => {
            dispatch(actions.action.loadDetailPost(postId))
        },
        setDefaultPost: () => {
            dispatch(actions.action.setDefaultCurrentPost())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailPostPage)