import React, { Component } from "react";
import { connect } from "react-redux";
import AdminDashboardAction from "../../../redux/admin_dashboard/actions";
import authActions from "../../../redux/auth/actions";
import ColumnChartSaleRevenue from "./column_chart_sale_revenue";
import DashboardInfo from "./dashboard_info";
import "./index.scss";
import PieChartLevelUser from "./pie_chart_level_user";

class HomePage extends Component {
  componentDidMount = () => {
    this.props.init();
  };

  render() {
    return (
      <div className="HomePageAdmin">
        <div className="HPA_RowInfo">
          <DashboardInfo />
        </div>

        <div className="HPA_RowInfo">
          <div className="HPA_PieChart">
            <PieChartLevelUser />
          </div>
          <div className="HPA_ColumnChart">
            <ColumnChartSaleRevenue />
          </div>
        </div>

        {/* <div className="HPA_RowInfo">
                    <ListOrderNeedConfirm />
                </div> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    userInfo: state.auth.userInfo ? state.auth.userInfo : null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch(authActions.action.logout());
    },
    init: () => {
      dispatch(AdminDashboardAction.action.getOverviewInfo());
    //   dispatch(AdminDashboardAction.action.loadListOrder());
      dispatch(AdminDashboardAction.action.getRevenueChart());
      dispatch(AdminDashboardAction.action.getUserTypeChart());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
