import { all, takeEvery, fork, put, select } from "redux-saga/effects";
import actions from "./actions";

import VoucherApiServices from "../../../services/api/voucher";
import NotificationsService from "../../../services/utils/notifycationHelper";
import { message } from "antd";


function* saga_LoadListVoucher(action) {
    try {
        let {params} = action.payload;

        let _state = yield select((state) => state.user_profile_voucher);
        let pageIndex = _state.pageIndex;
        let textSearch = _state.textSearch;
        let pageSize = _state.pageSize;

        if (params.pageIndex) pageIndex = params.pageIndex;
        if (params.textSearch) textSearch = params.textSearch;
        if (params.pageSize) pageSize = params.pageSize;

        // call api
        let res = yield VoucherApiServices.getListVoucherAvailable(
            pageSize,
            pageIndex,
            textSearch
        );

        if (res.code === 200) {
            let vouchers = res.data.items;
            let total = res.data.total;

            yield put(
                actions.action.updateState({
                    total,
                })
            );
            yield put(actions.action.loadListVoucherSuccess(vouchers));
        }
    } catch (ex) {
        console.log(
            "[user_profile_voucher] saga_LoadListVoucher error : ",
            ex.message
        );
    }
}

function* saga_UpdatePageIndex(action) {
    try {
        let pageIndex = action.payload.pageIndex;

        yield put(
            actions.action.loadListVoucher({
                pageIndex,
            })
        );
    } catch (ex) {
        console.log(
            "[user_profile_voucher] saga_UpdatePageIndex error : ",
            ex.message
        );
    }
}

function* saga_LoadCurrentVoucherApply(){
    try {
        const res = yield VoucherApiServices.getCurrentVoucherApply();
        if (res.code === 200) {
            const currentVoucher = res.data;
            yield put(actions.action.loadCurrentVoucherApplySuccess(currentVoucher))
        }
    } catch (ex) {
        console.log(
            "[user_profile_voucher] saga_LoadCurrentVoucherApply error : ",
            ex.message
        );
    }
}

function* saga_ApplyVoucher(action) {
    try {
        const id = action.payload.id;

        const res = yield VoucherApiServices.applyVoucher(id);
        console.log(res)
        if (res.code === 200) {
            const currentVoucher = res.data;
            yield put(actions.action.loadCurrentVoucherApply(currentVoucher))
            NotificationsService.success("Áp dụng mã khuyến mại thành công")
        } else {
            NotificationsService.success(res.message)
        }
    } catch (ex) {
        console.log(
            "[user_profile_voucher] saga_ApplyVoucher error : ",
            ex.message
        );
        if (ex.message.code === 500) {
            NotificationsService.error(ex.message.message)
        } else {
            NotificationsService.error("Áp dụng mã khuyến mại thất bại")
        }
    }
}

function* listen() {
    yield takeEvery(actions.type.LOAD_LIST_VOUCHER, saga_LoadListVoucher);
    yield takeEvery(actions.type.UPDATE_PAGE_INDEX, saga_UpdatePageIndex);
    yield takeEvery(actions.type.LOAD_CURRENT_VOUCHER_APPLY, saga_LoadCurrentVoucherApply);
    yield takeEvery(actions.type.APPLY_VOUCHER, saga_ApplyVoucher);
}

export default function* mainSaga() {
    yield all([fork(listen)]);
}
