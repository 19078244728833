import { all, takeEvery, fork, put, select } from "redux-saga/effects";
import actions from "./actions";
import { SORT_MODE_CONST } from "./reducer";

// api
import {
  getListProduct,
  getListProductPerUnit,
} from "../../../services/api/product";
import { getArrayListCategories } from "../../../services/api/category";

// helper
import ListHelperServices from "../../../services/utils/listHelper";

function* saga_LoadListProduct(action) {
  try {
    let params = action.payload.params;

    let _state = yield select((state) => state.user_filter_product);
    let { pageSize, pageIndex, lastTextSearch, sortMode } = _state;
    let textSearch = lastTextSearch;

    if (params.pageIndex) pageIndex = params.pageIndex;
    if (params.pageSize) pageSize = params.pageSize;
    if (params.textSearch) textSearch = params.textSearch;
    if (params.sortMode) sortMode = params.sortMode;

    let params_request = [
      {
        name: "keyword",
        value: textSearch,
      },
    ];

    // sort mode
    switch (sortMode) {
      case SORT_MODE_CONST.SORT_ASC_NAME:
        params_request = [
          ...params_request,
          {
            name: "sorts[name]",
            value: 1,
          },
        ];
        break;

      case SORT_MODE_CONST.SORT_DESC_NAME:
        params_request = [
          ...params_request,
          {
            name: "sorts[name]",
            value: -1,
          },
        ];
        break;

      case SORT_MODE_CONST.SORT_ASC_PRICE:
        params_request = [
          ...params_request,
          {
            name: "sorts[price]",
            value: 1,
          },
        ];
        break;

      case SORT_MODE_CONST.SORT_DESC_PRICE:
        params_request = [
          ...params_request,
          {
            name: "sorts[price]",
            value: -1,
          },
        ];
        break;

      default:
        params_request = params_request;
    }

    // filter category
    if (_state.filter_categorys.length > 0) {
      let value = ListHelperServices.convertListIdToString(
        _state.filter_categorys
      );
      params_request = [
        ...params_request,
        {
          name: "filters[categoryId]",
          value,
        },
      ];
    }

    // filter unit
    if (_state.filter_units.length > 0) {
      let value = ListHelperServices.convertListIdToString(_state.filter_units);
      params_request = [
        ...params_request,
        {
          name: "filters[unitId]",
          value,
        },
      ];
    }

    // price
    let fromPrice = _state.lowPrice;

    if (fromPrice) {
      params_request = [
        ...params_request,
        {
          name: "filters[fromPrice]",
          value: fromPrice,
        },
      ];
    }

    let toPrice = _state.highPrice;
    if (toPrice) {
      params_request = [
        ...params_request,
        {
          name: "filters[toPrice]",
          value: toPrice,
        },
      ];
    }

    // call api
    const res = yield getListProduct(pageIndex, pageSize, params_request);

    let { items, total } = res.data;

    if (res.code === 200) {
      yield put(actions.action.loadListProductSuccess(items));
      yield put(
        actions.action.updateState({
          total,
        })
      );
    }

    // NotifycationServices.success('Đăng nhập thành công', "Thông báo")
  } catch (ex) {
    console.log("[User Filter Saga] saga_LoadListProduct Error : ", ex.message);
    yield put(actions.action.loadListProductFailed());
  }
}

function* saga_SearchProduct(action) {
  try {
    let textSearch = yield select(
      (state) => state.user_filter_product.textSearch
    );

    yield put(
      actions.action.loadListProduct({
        textSearch,
        pageIndex: 1,
      })
    );
  } catch (ex) {
    console.log("[User Filter Saga] saga_SearchProduct Error : ", ex.message);
  }
}

function* saga_UpdatePageIndex(action) {
  try {
    let pageIndex = action.payload.pageIndex;

    yield put(
      actions.action.loadListProduct({
        pageIndex: pageIndex,
      })
    );
  } catch (ex) {
    console.log("[User Filter Saga] saga_SearchProduct Error : ", ex.message);
  }
}

function* saga_LoadListCategory(action) {
  try {
    let req = yield getArrayListCategories();
    if (req.code === 200) {
      let categorys = req.data;

      yield put(actions.action.loadListCategorySuccess(categorys));
    }
  } catch (ex) {
    console.log(
      "[User Filter Saga] saga_LoadListCategory Error : ",
      ex.message
    );
  }
}

function* saga_LoadUnits(action) {
  try {
    let req = yield getListProductPerUnit();

    if (req.code === 200) {
      let units = req.data;
      yield put(actions.action.loadListUnitSuccess(units));
    }
  } catch (ex) {
    console.log("[User Filter Saga] saga_LoadUnits Error : ", ex.message);
  }
}

function* listen() {
  yield takeEvery(actions.type.LOAD_LIST_PRODUCT, saga_LoadListProduct);
  yield takeEvery(actions.type.SEARCH_PRODUCT, saga_SearchProduct);
  yield takeEvery(actions.type.UPDATE_PAGE_INDEX, saga_UpdatePageIndex);
  yield takeEvery(actions.type.LOAD_LIST_CATEGORY, saga_LoadListCategory);
  yield takeEvery(actions.type.LOAD_LIST_UNIT, saga_LoadUnits);
}

export default function* mainSaga() {
  yield all([fork(listen)]);
}
