const validate = (entity) => {
    // function
    const errorResult = (message) => {
        return {
            isValidate: false,
            message,
        };
    };

    // validate
    if (entity.name === null || entity.name.trim() === "")
        return errorResult("Tên không được để trống");

    if (entity.phone === '')
        return errorResult("Số điện thoại không được để trống");

    if (entity.provinceCode === null)
        return errorResult("Tỉnh không được để trống");

    if (entity.districtCode === null)
        return errorResult("Huyện không được để trống");

    if (entity.wardCode === null) return errorResult("Xã không được để trống");

    if (entity.fullAddress === null || entity.fullAddress.trim() == "")
        return errorResult("Địa chỉ không được để trống");

    if (entity.note === null || entity.note.trim() == "")
        return errorResult("Ghi chú không được để trống");

    return {
        isValidate: true,
        message: "success",
    };
};

export default {
    validate,
};
