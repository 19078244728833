import { all } from 'redux-saga/effects'
import authSaga from './auth/saga'
import adminProductSaga from './admin_product/saga'
import adminListUserSaga from './admin_listuser/saga'
import adminOrderSaga from './admin_order/saga'
import adminCustomerGroupSaga from './admin_customer_group/saga'
import adminDashboardSaga from './admin_dashboard/saga'
import adminCreateOrderSaga from './admin_create_order/saga'
import adminVoucherSaga from './admin_voucher/saga'
import adminRequestChangeCustomerGroup from './admin_request_change_customer_group/saga'
import adminCategorySaga from './admin_category/saga'
import adminCategoryPostSaga from './admin_category_post/saga'
import adminFlashSale from './admin_flash_sale/saga'
import adminManagerListLevelUser from './admin_manager_list_level_user/saga'
import adminLevelRelationship from './admin_level_relationship/saga'
import adminPostSaga from './admin_post/saga'
import adminGroupUserSaga from './admin_group_user/saga'

// user
import userGlobalSaga from './user_global/saga'
import userFilterProductSaga from './user_filter_product/saga'
import userProfileAddressSaga from './user_profile_address/saga'
import userProductHotSaga from './user_product_hot_discount/saga'
import userProfileHistoryOrderSaga from './user_profile_history_order/saga'
import userProfileStatisticalSaga from './user_profile_statistical/saga'
import userProfileHistoryTransactionSaga from './user_profile_history_transaction/saga'
import userProfileVoucher from './user_profile_voucher/saga'
import userProfileHistoryVoucher from './user_profile_history_voucher/saga'
import userListPostSaga from './user_list_post/saga'
import userFlashSaleSaga from './user_flash_sale/saga'

export default function* rootSaga() {
    yield all([
        authSaga(),
        adminListUserSaga(),
        adminProductSaga(),
        adminOrderSaga(),
        adminCustomerGroupSaga(),
        adminDashboardSaga(),
        adminCreateOrderSaga(),
        adminVoucherSaga(),
        adminRequestChangeCustomerGroup(),
        adminCategorySaga(),
        adminCategoryPostSaga(),
        adminFlashSale(),
        adminManagerListLevelUser(),
        adminLevelRelationship(),
        adminPostSaga(),
        adminGroupUserSaga(),
        
        // user
        userGlobalSaga(),
        userFilterProductSaga(),
        userProfileAddressSaga(),
        userProductHotSaga(),
        userProfileHistoryOrderSaga(),
        userProfileStatisticalSaga(),
        userProfileHistoryTransactionSaga(),
        userProfileVoucher(),
        userProfileHistoryVoucher(),
        userListPostSaga(),
        userFlashSaleSaga()
    ])
}
