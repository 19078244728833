import actions from './actions';


const initState = {
    vouchers: [],
    pageSize: 10, 
    pageIndex: 1, 
    total: 0,
    textSearch: "",
    currentVoucher: null
}

const reducer = (state = initState, action) => {
    const payload = action.payload;
    switch(action.type) {
        case actions.type.UPDATE_STATE:
            return {
                ...state,
                ...payload.state
            }
        case actions.type.LOAD_LIST_VOUCHER:
            return state
        case actions.type.LOAD_LIST_VOUCHER_SUCCESS:
            return {
                ...state,
                ...{
                    vouchers: payload.vouchers
                }
            }
        case actions.type.UPDATE_PAGE_INDEX:
            return {
                ...state,
                ...{
                    pageIndex: payload.pageIndex
                }
            }
        case actions.type.LOAD_CURRENT_VOUCHER_APPLY:
            return state
        case actions.type.LOAD_CURRENT_VOUCHER_APPLY_SUCCESS:
            return {
                ...state,
                ...{
                    currentVoucher: payload.currentVoucher
                }
            }
        case actions.type.UPDATE_TEXT_SEARCH:
            return {
                ...state,
                ...{
                    textSearch: payload.textSearch
                }
            }
        case actions.type.APPLY_VOUCHER:
            return state
        default:
            return state
    }
}

export default reducer;