import React, { Component } from "react";
// redux
import { connect } from "react-redux";
// route
import { Redirect, Route, Switch } from "react-router-dom";
// component
import LoginRequireContainer from "../../components/login_require_component";
// const
import RouterLinks from "../../const/router_link";
import BestSellProductPage from "../screens/user_views/best_sell_product_page";
import DetailPostPage from "../screens/user_views/detail_post_page";
import DetailProductPage from "../screens/user_views/detail_product_page";
// page
import FilterProductPage from "../screens/user_views/filter_product_page";
import FlashSalePage from "../screens/user_views/flash_sale_page";
// sub component
import HomePage from "../screens/user_views/home";
import HotDiscountPage from "../screens/user_views/hot_discount_page";
import ListPostPage from "../screens/user_views/list_post_page";
import CartPanel from "../screens/user_views/shared/cart_panel";
import Footer from "../screens/user_views/shared/footer";
import TopBar from "../screens/user_views/shared/top_bar";
import PaymentFailPage from "../screens/user_views/user_profile_page/history_payment_page/payment_result/payment_fail";
import PaymentSuccessPage from "../screens/user_views/user_profile_page/history_payment_page/payment_result/payment_success";
// layout
import {
  UserCart,
  UserLayoutAuth,
  UserProfileContainser,
} from "./user_layouts";

class UserSite extends Component {
  render() {
    const path = this.props.path;

    return (
      <Route path={path + ""}>
        <Switch>
          <UserLayoutAuth path={RouterLinks.U_AUTH} />
          <UserProfileContainser path={RouterLinks.U_PROFILE} />
          <UserCart path={RouterLinks.U_CART} />

          <Route path={path}>
            <TopBar />

            <div className="page-wrapper">
              <div className="main home">
                <div className="container">
                  <Switch>
                    <Route
                      exact
                      path={RouterLinks.U_HOT_DISCOUNT}
                      component={HotDiscountPage}
                    />
                    <Route
                      exact
                      path={RouterLinks.U_BEST_SELL}
                      component={BestSellProductPage}
                    />
                    <Route
                      path={RouterLinks.U_FILTER_PRODUCT}
                      component={FilterProductPage}
                    />

                    <LoginRequireContainer
                      exact
                      path={RouterLinks.U_PAYMENT_SUCCESS}
                      component={PaymentSuccessPage}
                      redirect_link={RouterLinks.U_HOME_PAGE}
                    />

                    <LoginRequireContainer
                      exact
                      path={RouterLinks.U_PAYMENT_FAIL}
                      component={PaymentFailPage}
                      redirect_link={RouterLinks.U_HOME_PAGE}
                    />

                    {/* <Route
                                            path={RouterLinks.U_DETAIL_PRODUCT}
                                            component={DetailProductPage}
                                        /> */}
                    <Route
                      path={RouterLinks.U_DETAIL_PRODUCT + "/:id"}
                      component={DetailProductPage}
                    />
                    <Route
                      path={RouterLinks.U_FLASH_SALE}
                      component={FlashSalePage}
                    />

                    <Route
                      component={DetailPostPage}
                      path={`${RouterLinks.U_DETAIL_POST}/:id`}
                    />

                    <Route
                      component={ListPostPage}
                      path={`${RouterLinks.U_LIST_POST_TAGS}`}
                    />

                    <Route
                      exact
                      path={RouterLinks.U_HOME_PAGE}
                      component={HomePage}
                    />

                    <Redirect to={RouterLinks.U_HOME_PAGE} />
                  </Switch>
                </div>
              </div>

              <Footer />
            </div>

            <CartPanel />
          </Route>
        </Switch>
      </Route>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSite);
