import { all, takeEvery, fork, put, select } from "redux-saga/effects";
import { getProductCurrentFlashSale } from "../../../services/api/product";
import actions from "./actions";

function* loadProductFlashSale(action) {
    try {
        const res = yield getProductCurrentFlashSale();
        if (res.code === 200) {
            const flashSaleList = res.data;
            if (Array.isArray(flashSaleList) && flashSaleList.length) {
                const productList = flashSaleList[0].productsFlashSales;
                const flashSale = flashSaleList[0].flashSales;
                yield put(actions.action.loadProductFlashSaleSuccess(productList, flashSale));
            }
            
        }
    } catch(err) {
        console.log("[User Flash Sale] loadProductFlashSale Error : ", err);
        yield put(actions.action.loadProductFlashSaleFailed());
    }
}


function* listen() {
    yield takeEvery(actions.type.LOAD_PRODUCT_FLASH_SALE_REQUEST, loadProductFlashSale);
}

export default function* mainSaga() {
    yield all([fork(listen)]);
}