import React, { Component } from 'react'
import { connect } from 'react-redux'
import { regexNumber } from '../../../../../const/regex_consts';
import { getImageUrl } from '../../../../../services/api/upload';
import AdminFlashSaleActions from '../../../../redux/admin_flash_sale/actions'


const list_flexs = [5, 10, 15, 20, 20];

class RowAddProduct extends Component {
    constructor(props) {
        super(props)
        this.state = {
            disableInput: true,
        }
    }

    componentDidMount() {
        const {productFlashSales, product } = this.props
        const productFlashSale = productFlashSales.filter(element => element.productId === product._id)[0]
        if (productFlashSale) {
            this.setState({ disableInput: false })
        }
    }

    onChangeCheckBox = (evt) => {
        let isChecked = evt.target.checked;
        this.setState({ disableInput: !isChecked })
        const { product } = this.props
        if (isChecked) {
            this.props.pushProductFlashSaleToArray(product._id)
        } else {
            this.props.removeProductFlashSaleOutArray(product._id)
        }
    }

    onChangeTotalQuantity = (evt) => {
        const { product } = this.props
        const totalQuantity = evt.target.value;
        if (regexNumber.test(totalQuantity) || totalQuantity === '') {
            this.props.updateNewProductFlashSale(product._id, {totalQuantity}); 
        }
    }

    onBlurTotalQuantity = (productFlashSale) => {
        const { product } = this.props;
        if (productFlashSale.totalQuantity === '') {
            this.props.updateNewProductFlashSale(product._id, {totalQuantity: 0}); 
        }
    }

    onChangeDiscount = (evt) => {
        const { product } = this.props
        const discount = evt.target.value;
        if (regexNumber.test(discount) || discount === '') {
            this.props.updateNewProductFlashSale(product._id, {discount});
        }
    }

    onBlurDiscount = (productFlashSale) => {
        const { product } = this.props;
        if (productFlashSale.discount === '') {
            this.props.updateNewProductFlashSale(product._id, {discount: 0});
        }
    }

    render() {
        const { products, product, index, productFlashSales } = this.props
        const { disableInput } = this.state
        const productFlashSale = productFlashSales.filter(element => element.productId === product._id)[0]

        return (

            <tr
                className="CDS_TableRow"
                style={{
                    borderBottom:
                        index === products.length - 1
                            ? "none"
                            : "1px solid #e2e2e2",
                }}
            >
                <td
                    className="CDS_TableCell"
                    style={{
                        flex: list_flexs[0],
                        marginRight: 10,
                    }}
                >
                    <input
                        type="checkbox"
                        checked={!disableInput}
                        onChange={this.onChangeCheckBox}
                    />
                </td>
                <td
                    className="CDS_TableCell"
                    style={{
                        flex: list_flexs[1],
                        justifyContent: "left",
                    }}
                >
                    <img
                        style={{
                            height: 60,
                            width: 60,
                            objectFit: "contain",
                            marginRight: 10,
                            border: "1px solid #f2f2f2",
                        }}
                        src={getImageUrl(product.thumbnail)}
                        alt="thumbnail"
                    />
                </td>
                <td
                    className="CDS_TableCell superMiniHide"
                    style={{
                        flex: list_flexs[2],
                        justifyContent: 'left'
                    }}
                >
                    {product.name}
                </td>
                <td
                    className="CDS_TableCell superMiniHide"
                    style={{ flex: list_flexs[3] }}
                >
                    <input
                        type="text"
                        className="APFSP_Input"
                        disabled={disableInput}
                        value={productFlashSale ? productFlashSale.totalQuantity: ''}
                        onChange={this.onChangeTotalQuantity}
                        onBlur={() => this.onBlurTotalQuantity(productFlashSale)}
                    />
                </td>
                <td
                    className="CDS_TableCell superMiniHide"
                    style={{ flex: list_flexs[4] }}
                >
                    <input
                        type="text"
                        className="APFSP_Input"
                        disabled={disableInput}
                        value={productFlashSale ? productFlashSale.discount: ''}
                        onChange={this.onChangeDiscount}
                        onBlur={() => this.onBlurDiscount(productFlashSale)}
                    />
                </td>
            </tr>
        )
    }
}

const mapStateToProps = (state) => {
    const _state = state.admin_flash_sale
    return {
        productFlashSales: _state.productFlashSales
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        pushProductFlashSaleToArray: (id) => {
            dispatch(AdminFlashSaleActions.action.pushProductFlashSaleToArray(id))
        },
        updateNewProductFlashSale: (id, product) => {
            dispatch(AdminFlashSaleActions.action.updateNewProductFlashSale(id, product))
        },
        removeProductFlashSaleOutArray: (id) => {
            dispatch(AdminFlashSaleActions.action.removeProductFlashSaleOutArray(id))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RowAddProduct)