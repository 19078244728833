import { all, takeEvery, fork, put, select } from "redux-saga/effects";
import actions from "./actions";
import { history } from "../store";

// api
import {
  OrderAdminApiServices,
  getDetailOrder,
} from "../../../services/api/order";
import DashboardAdminApiServices from "../../../services/api/admin_dashboard";

// helper
import NotifycationServices from "../../../services/utils/notifycationHelper";

// const
import Router_Links from "../../../const/router_link";

// overview
function* saga_GetOverview(action) {
  try {
    // revenue
    let req = yield DashboardAdminApiServices.getTotalRevenue();
    if (req.code === 200)
      yield put(actions.action.getTotalRevenueSuccess(req.data.revenue));

    // order number
    req = yield DashboardAdminApiServices.getTotalOrder();
    if (req.code === 200)
      yield put(actions.action.getTotalOrderSuccess(req.data.totalOrders));

    // product
    req = yield DashboardAdminApiServices.getTotalProduct();
    if (req.code === 200)
      yield put(actions.action.getTotalProductSuccess(req.data.totalProducts));

    // number user
    req = yield DashboardAdminApiServices.getTotalMember();
    if (req.code === 200)
      yield put(
        actions.action.getTotalNumberUserSuccess(req.data.totalMembers)
      );
  } catch (ex) {
    console.log("[admin_dashboard Saga] saga_GetOverview Error : ", ex.message);
  }
}

// list order
function* saga_LoadListOrder(action) {
  try {
    let params = action.payload.params;

    let _state = yield select((state) => state.admin_dashboard);
    let { pageSize, pageIndex } = _state;
    let textSearch = _state.lastSearch;

    if (params.pageIndex) pageIndex = params.pageIndex;
    if (params.pageSize) pageSize = params.pageSize;
    if (params.textSearch) textSearch = params.textSearch;

    let res = yield DashboardAdminApiServices.getListOrderNeedConfirm(
      pageSize,
      pageIndex,
      textSearch
    );
    let { items, total } = res.data;

    if (res.code === 200) {
      yield put(actions.action.loadListOrderSuccerss(items));
      yield put(
        actions.action.updateState({
          total,
        })
      );
    }
  } catch (ex) {
    console.log(
      "[admin_dashboard Saga] saga_LoadListHistoryOrder Error : ",
      ex.message
    );
  }
}

function* saga_UpdatePageIndex(action) {
  try {
    let pageIndex = action.payload.pageIndex;

    yield put(
      actions.action.loadListOrder({
        pageIndex,
      })
    );
  } catch (ex) {
    console.log(
      "[admin_dashboard Saga] saga_UpdatePageIndex Error : ",
      ex.message
    );
  }
}

// detail order
function* saga_LoadDetailOrder(action) {
  try {
    let id = action.payload.id;

    let req = yield OrderAdminApiServices.GetDetailOrder(id);
    if (req.code === 200) {
      let order = req.data;
      yield put(actions.action.loadDetailOrderSuccess(order));
    }
  } catch (ex) {
    console.log(
      "[admin_dashboard Saga] saga_LoadDetailOrder Error : ",
      ex.message
    );
  }
}

function* saga_UpdateStatusOrder(action) {
  try {
    let id = action.payload.id;
    let status = action.payload.status;
    let estimatedDeliveryTime =
      action.payload?.estimatedDeliveryTime || undefined;

    let req = yield OrderAdminApiServices.UpdateStatusOrder(
      id,
      status,
      estimatedDeliveryTime
    );
    if (req.code === 200) {
      yield put(actions.action.loadListOrder());
      yield put(actions.action.getOverviewInfo());
      yield put(actions.action.getRevenueChart());
      yield put(actions.action.getUserTypeChart());

      NotifycationServices.success("Xác nhận đơn hàng thành công");
    } else {
      NotifycationServices.error("Xác nhận đơn hàng thất bại");
    }
  } catch (ex) {
    console.log("[admin_dashboard Saga] saga_CancelOrder Error : ", ex.message);

    NotifycationServices.error("Xác nhận đơn hàng thất bại");
  }
}

// chart
function* saga_GetUserTypeChart(action) {
  try {
    let time = new Date();
    let month = time.getMonth() + 1,
      year = time.getFullYear();

    let req = yield DashboardAdminApiServices.getChartLevelUser(month, year);
    if (req.code === 200) {
      let data = req.data;
      yield put(actions.action.getUserTypeChartSuccess(data));
    }
  } catch (ex) {
    console.log(
      "[admin_dashboard Saga] saga_GetUserTypeChart Error : ",
      ex.message
    );
  }
}

function* saga_GetRevenueChart(action) {
  try {
    let time = new Date();
    let month = time.getMonth() + 1,
      year = time.getFullYear();
    let month1 = month - 6,
      year1 = year;
    if (month1 < 0) {
      month1 += 12;
      year1 -= 1;
    }

    let req = yield DashboardAdminApiServices.getChartAggregate(
      month1,
      year1,
      month,
      year
    );
    if (req.code === 200) {
      let data = req.data;
      yield put(actions.action.getRevenueChartSuccess(data));
    }
  } catch (ex) {
    console.log(
      "[admin_dashboard Saga] saga_GetRevenueChart Error : ",
      ex.message
    );
  }
}

function* listen() {
  // overview
  yield takeEvery(actions.type.GET_OVER_VIEW_INFO, saga_GetOverview);

  // list order
  yield takeEvery(actions.type.LOAD_LIST_ORDER, saga_LoadListOrder);
  yield takeEvery(actions.type.UPDATE_PAGE_INDEX, saga_UpdatePageIndex);

  // detail order
  yield takeEvery(actions.type.LOAD_DETAIL_ORDER, saga_LoadDetailOrder);
  yield takeEvery(actions.type.UPDATE_ORDER_STATUS, saga_UpdateStatusOrder);

  // chart
  yield takeEvery(actions.type.GET_USER_TYPE_CHART, saga_GetUserTypeChart);
  yield takeEvery(actions.type.GET_REVENUE_CHART, saga_GetRevenueChart);
}

export default function* mainSaga() {
  yield all([fork(listen)]);
}
