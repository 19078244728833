import React, { Component } from 'react'
import { withRouter } from "react-router-dom"



class ScrollToTop extends Component {
    constructor(props) {
        super(props)
        this.myRef = React.createRef()   // Create a ref object 
    }

    componentDidUpdate(prevProps) {

        // console.log("zzz - ", this.props.location)

        if (this.props.location !== prevProps.location) {
            this._scrollToTop()
        }
    }


    componentDidMount() {
    }


    componentWillUnmount() {

    }

    _scrollToTop = () => {
        window.scroll(0, 0)
    }

    render() {
        // this._scrollToTop()

        return (
            <div ref={this.myRef}>
                {
                    this.props.children
                }
            </div>
        )
    }
}

export default withRouter(ScrollToTop)