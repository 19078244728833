import { all, takeEvery, fork, put, select } from "redux-saga/effects";
import actions from "./actions";
import { history } from "../store";

// api
import { getListProduct } from "../../../services/api/product";
import { getListUser } from "../../../services/api/user";
import { getListAddressOfUser } from "../../../services/api/address_user";
import {
    getAhaShipPrice,
    getDiscountInfoByCode,
} from "../../../services/api/order";
import { OrderAdminApiServices } from "../../../services/api/order";
import VoucherApiServices from "../../../services/api/voucher"

// helper
import CartGuestItemHelperServices from "../../../services/utils/model_process_helper/guest_cart_product";
import ValidateOrderServices from "../../../services/utils/validate/validate_order";
import NotificationsService from "../../../services/utils/notifycationHelper";
import Router_Links from "../../../const/router_link";

// modal product
function* saga_LoadListProduct(action) {
    try {
        // params
        let params = action.payload.params;

        let _state = yield select((state) => state.admin_create_order);
        let pageIndex = 0,
            pageSize = 0,
            textSearch = "";

        if (params.pageIndex) pageIndex = params.pageIndex;
        else pageIndex = _state.md_product_page_index;
        if (params.pageSize) pageSize = params.pageSize;
        else pageSize = _state.md_product_page_size;
        if (params.textSearch) textSearch = params.textSearch;
        else textSearch = _state.md_product_last_search;

        // call api
        let params_request = [
            {
                name: "keyword",
                value: textSearch,
            },
        ];

        // check is loading
        if (_state.md_product_is_loading) return;
        yield put(
            actions.action.updateState({
                md_product_is_loading: true,
            })
        );

        let req = yield getListProduct(pageIndex, pageSize, params_request);

        if (req.code === 200) {
            let items = req.data.items;
            yield put(actions.action.loadListProductSuccess(items));
            yield put(
                actions.action.updateState({
                    md_product_total: req.data.total,
                })
            );
        }

        yield put(
            actions.action.updateState({
                md_product_is_loading: false,
            })
        );
    } catch (ex) {
        console.log("[admin create order] saga_LoadListProduct error : ", ex);
        yield put(
            actions.action.updateState({
                md_product_is_loading: false,
            })
        );
    }
}

function* saga_MdProductUpdatePageIndex(action) {
    try {
        let pageIndex = action.payload.pageIndex;

        yield put(
            actions.action.loadListProduct({
                pageIndex,
            })
        );
    } catch (ex) {
        console.log(
            "[admin create order] saga_MdProductUpdatePageIndex error : ",
            ex
        );
    }
}

function* saga_MdProductSearchProduct() {
    try {
        let _state = yield select((state) => state.admin_create_order);
        let textSearch = _state.md_product_text_search;

        yield put(
            actions.action.loadListProduct({
                pageIndex: 1,
                textSearch,
            })
        );
    } catch (ex) {
        console.log(
            "[admin create order] saga_MdProductSearchProduct error : ",
            ex
        );
    }
}

// modal user
function* saga_LoadListUser(action) {
    try {
        // params
        let params = action.payload.params;

        let _state = yield select((state) => state.admin_create_order);
        let pageIndex = 0,
            pageSize = 0,
            textSearch = "";

        if (params.pageIndex) pageIndex = params.pageIndex;
        else pageIndex = _state.md_user_page_index;
        if (params.pageSize) pageSize = params.pageSize;
        else pageSize = _state.md_user_page_size;
        if (params.textSearch) textSearch = params.textSearch;
        else textSearch = _state.md_user_last_search;

        // call api
        let params_request = [
            {
                name: "keyword",
                value: textSearch,
            },
        ];

        // check is loading
        if (_state.md_user_is_loading) return;
        yield put(
            actions.action.updateState({
                md_user_is_loading: true,
            })
        );

        let req = yield getListUser(pageSize, pageIndex, params_request);

        if (req.code === 200) {
            let items = req.data.items;
            yield put(actions.action.loadListUserSuccess(items));
            yield put(
                actions.action.updateState({
                    md_user_total: req.data.total,
                })
            );
        }

        yield put(
            actions.action.updateState({
                md_user_is_loading: false,
            })
        );
    } catch (ex) {
        console.log("[admin create order] saga_LoadListUser error : ", ex);
        yield put(
            actions.action.updateState({
                md_user_is_loading: false,
            })
        );
    }
}

function* saga_MdUserUpdatePageIndex(action) {
    try {
        let pageIndex = action.payload.pageIndex;

        yield put(
            actions.action.loadListUser({
                pageIndex,
            })
        );
    } catch (ex) {
        console.log(
            "[admin create order] saga_MdUserUpdatePageIndex error : ",
            ex
        );
    }
}

function* saga_MdUserSearchUser() {
    try {
        let _state = yield select((state) => state.admin_create_order);
        let textSearch = _state.md_user_text_search;

        yield put(
            actions.action.loadListUser({
                pageIndex: 1,
                textSearch,
            })
        );
    } catch (ex) {
        console.log("[admin create order] saga_MdUserSearchUser error : ", ex);
    }
}

function* saga_SelectUser(action) {
    try {
        let user = action.payload.user;

        let id = "";
        if (user._id) id = user._id;

        yield put(actions.action.loadListAddress(id, {}));
    } catch (ex) {
        console.log("[admin create order] saga_SelectUser error : ", ex);
    }
}

// modal address
function* saga_LoadListAddress(action) {
    try {
        let userId = action.payload.userId;

        // call api
        let req = yield getListAddressOfUser(userId, 20, 1);
        if (req.code === 200) {
            let items = req.data;
            yield put(actions.action.loadListAddressSuccess(items));
        }
    } catch (ex) {
        console.log("[admin create order] saga_LoadListAddress error : ", ex);
    }
}

function* saga_SelectAddress(action) {
    try {
        yield put(actions.action.getShippingInfo());
    } catch (ex) {
        console.log("[admin create order] saga_SelectAddress error : ", ex);
    }
}

// shipping, discount info
function* saga_GetCurrentVoucherApply(action){
    try {
        let req = yield VoucherApiServices.getCurrentVoucherApply()
    
        if (req.code === 200){
            let voucher = req.data 
            yield put(
                actions.action.getCurrentVoucherApplySuccess(
                    voucher
                )
            )
        }
    }
    catch (ex){
        console.log("[admin create order] saga_GetCurrentVoucherApply error : ", ex);
    }
}

function* saga_GetShippingInfo(action) {
    try {
        let _state = yield select((state) => state.admin_create_order);
        let shippingType = _state.order.serviceId;
        let addressId =
            _state.select_address && _state.select_address._id
                ? _state.select_address._id
                : null;
        let paymentMethod = _state.order.paymentMethod;

        if (paymentMethod === "DIRECT" || !addressId) return;

        // call api
        yield put(
            actions.action.updateState({
                is_loading_shipping_info: true,
            })
        );

        let req = yield getAhaShipPrice(addressId, shippingType);
        if (req.code === 200) {
            yield put(actions.action.getShippingInfoSuccess(req.data));
        }

        yield put(
            actions.action.updateState({
                is_loading_shipping_info: false,
            })
        );
    } catch (ex) {
        console.log("[admin create order] saga_GetShippingInfo error : ", ex);

        yield put(
            actions.action.updateState({
                is_loading_shipping_info: false,
            })
        );
    }
}

function* saga_GetDiscountInfo(action) {
    try {
        let code = action.payload.code;

        // call api
        let req = yield getDiscountInfoByCode(code);
        if (req.code === 200) {
            yield put(actions.action.getDiscountInfoSuccess(req.data));
        }
    } catch (ex) {
        console.log("[admin create order] saga_GetShippingInfo error : ", ex);
    }
}

// list products
function* saga_InsertProductItemPrepare(action) {
    try {
        let { productId, quantity } = action.payload;

        let item = yield CartGuestItemHelperServices.genGuestCartItem(
            productId,
            quantity
        );

        yield put(actions.action.insertProductItem(item));
    } catch (ex) {
        console.log(
            "[admin create order] saga_InsertProductItemPrepare error : ",
            ex
        );
    }
}

// save order
function* saga_SaveCurrentOrder(action) {
    try {
        let _state = yield select((state) => state.admin_create_order);
        let order = _state.order;

        // order
        order.userId = _state.select_user ? _state.select_user._id : null;
        order.addressId = _state.select_address
            ? _state.select_address._id
            : null;
        order.voucherCode = _state.discount_info
            ? _state.discount_info.code
            : "";

        // append products
        let products = _state.products;
        let list_products = [];
        for (let index = 0; index < products.length; index++) {
            let product = {};
            try {
                product = {
                    productId: products[index].product._id,
                    quantity: products[index].quantity,
                };
                list_products.push(product);
            } catch (ex) {}
        }
        order.products = list_products;

        // validate
        let validateRes = ValidateOrderServices.validate(order);
        if (!validateRes.isValidate) {
            NotificationsService.error(validateRes.message);
            return;
        }

        // call api
        let req = yield OrderAdminApiServices.AdminCreateOrder(order);
        if (req.code === 200) {
            NotificationsService.success("Thêm đơn hàng thành công");
            yield history.push(Router_Links.A_LIST_ORDER);
        } else NotificationsService.error("Thêm đơn hàng thất bại");
    } catch (ex) {
        console.log("[admin create order] saga_SaveCurrentOrder error : ", ex);
        NotificationsService.error("Thêm đơn hàng thất bại");
    }
}

function* listen() {
    // modal product
    yield takeEvery(actions.type.LOAD_LIST_PRODUCT, saga_LoadListProduct);
    yield takeEvery(
        actions.type.MD_PRODUCT_UPDATE_PAGE_INDEX,
        saga_MdProductUpdatePageIndex
    );
    yield takeEvery(
        actions.type.MD_PRODUCT_SEARCH_PRODUCT,
        saga_MdProductSearchProduct
    );

    // modal user
    yield takeEvery(actions.type.LOAD_LIST_USER, saga_LoadListUser);
    yield takeEvery(
        actions.type.MD_USER_UPDATE_PAGE_INDEX,
        saga_MdUserUpdatePageIndex
    );
    yield takeEvery(actions.type.MD_USER_SEARCH_USER, saga_MdUserSearchUser);
    yield takeEvery(actions.type.SELECT_USER, saga_SelectUser);

    // modal select address
    yield takeEvery(actions.type.LOAD_LIST_ADDRESS, saga_LoadListAddress);
    yield takeEvery(actions.type.SELECT_ADDRESS, saga_SelectAddress);

    // shipping, discount info
    yield takeEvery(actions.type.GET_CURRENT_VOUCHER_APPLY, saga_GetCurrentVoucherApply);
    yield takeEvery(actions.type.GET_SHIPPING_INFO, saga_GetShippingInfo);
    yield takeEvery(actions.type.GET_DISCOUNT_INFO, saga_GetDiscountInfo);

    // list products
    yield takeEvery(
        actions.type.INSERT_PRODUCT_ITEM_PREPARE,
        saga_InsertProductItemPrepare
    );

    // save order
    yield takeEvery(actions.type.SAVE_CURRENT_ORDER, saga_SaveCurrentOrder);
}

export default function* mainSaga() {
    yield all([fork(listen)]);
}
