import React, { Component } from "react";
import { connect } from "react-redux";
import "./index.scss";

import Modal from "../../../../components/modal";
import { FaSave, FaTimes } from "react-icons/fa";
//redux
import AdminManagerListLevelUserActions from "../../../redux/admin_manager_list_level_user/actions";

import { Select } from "antd";
import Button from "../../../../components/button";

class ModalAddLevelUser extends Component {
  onChangeLevelUserName = (evt) => {
    const name = evt.target.value;
    this.props.updateNewLevelUser({ name });
  };

  onChangeLevelUserDesc = (evt) => {
    const description = evt.target.value;
    this.props.updateNewLevelUser({ description });
  };

  onChangePercentDiscountPerOrder = (evt) => {
    const percentDiscountPerOrder = Number(evt.target.value);
    if (isNaN(percentDiscountPerOrder)) {
      this.props.updateNewLevelUser({ percentDiscountPerOrder: "" });
    }
    if (percentDiscountPerOrder >= 0 && percentDiscountPerOrder <= 100) {
      this.props.updateNewLevelUser({ percentDiscountPerOrder });
    }
  };

  onChangeMinimumRevenue = (evt) => {
    const minimumRevenue = parseInt(evt.target.value);
    if (isNaN(minimumRevenue)) {
      this.props.updateNewLevelUser({ minimumRevenue: "" });
    }
    if (minimumRevenue >= 0) {
      this.props.updateNewLevelUser({ minimumRevenue });
    }
  };

  onChangeMinimumDeposit = (evt) => {
    const minimumDeposit = parseInt(evt.target.value);
    if (isNaN(minimumDeposit)) {
      this.props.updateNewLevelUser({ minimumDeposit: "" });
    }
    if (minimumDeposit >= 0) {
      this.props.updateNewLevelUser({ minimumDeposit });
    }
  };

  onChangeMinimumChildren = (evt) => {
    const minimumChildren = parseInt(evt.target.value);
    if (isNaN(minimumChildren)) {
      this.props.updateNewLevelUser({ minimumChildren: "" });
    }
    if (minimumChildren >= 0) {
      this.props.updateNewLevelUser({ minimumChildren });
    }
  };

  onChangeChildrenType = (value) => {
    this.props.updateNewLevelUser({ childrenType: value });
  };

  onChangeLevelBefore = (value) => {
    this.props.updateNewLevelUser({ levelBefore: value });
  };

  render() {
    const {
      mdAddLevelUserState,
      setModalAddLevelUserState,
      newLevelUser,
      levelUsers,
      addNewLevelUser,
    } = this.props;
    return (
      <Modal
        isOpen={mdAddLevelUserState}
        displayCloseIcon={false}
        setIsOpen={setModalAddLevelUserState}
      >
        <div className="ModalAddLevelUser">
          <div className="MALU_Label">Thêm mới cấp bậc thành viên</div>
          <div>
            <label className="MALUF_Label">Tên cấp bậc</label>
            <input
              type="text"
              className="MALUF_Value"
              value={newLevelUser.name}
              onChange={this.onChangeLevelUserName}
              placeholder="Nhập tên cấp bậc"
            />
          </div>
          <div>
            <label className="MALUF_Label">Mô tả</label>
            <textarea
              className="MALUF_Value"
              value={newLevelUser.description}
              onChange={this.onChangeLevelUserDesc}
              placeholder="Nhập mô tả"
            />
          </div>
          <div>
            <label className="MALUF_Label">Điểm tích luỹ</label>
            <input
              type="number"
              className="MALUF_Value"
              value={newLevelUser.percentDiscountPerOrder}
              onChange={this.onChangePercentDiscountPerOrder}
              placeholder="Nhập số % triết khấu /đơn hàng"
            />
          </div>
          <div>
            <label className="MALUF_Label">Doanh thu</label>
            <div>
              <input
                type="number"
                className="MALUF_Value"
                value={newLevelUser.minimumRevenue}
                onChange={this.onChangeMinimumRevenue}
                placeholder="Giá trị nhỏ nhất"
              />
            </div>
          </div>
          <div>
            <label className="MALUF_Label">Ký quỹ</label>
            <div>
              <input
                type="number"
                className="MALUF_Value"
                value={newLevelUser.minimumDeposit}
                onChange={this.onChangeMinimumDeposit}
                placeholder="Giá trị nhỏ nhất"
              />
            </div>
          </div>
          <div>
            <label className="MALUF_Label">Loại cấp dưới</label>
            <Select
              value={newLevelUser.childrenType}
              className="MALUF_Select"
              onChange={this.onChangeChildrenType}
              placeholder="Lựa chọn loại cấp dưới"
            >
              {levelUsers.map((levelUser, index) => (
                <Select.Option key={levelUser._id} value={levelUser._id}>
                  {levelUser.name}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div>
            <label className="MALUF_Label">Thành viên hoạt động</label>
            <div>
              <input
                type="number"
                className="MALUF_Value"
                value={newLevelUser.minimumChildren}
                onChange={this.onChangeMinimumChildren}
                placeholder="Giá trị nhỏ nhất"
              />
            </div>
          </div>
          <div>
            <label className="MALUF_Label">Cấp bậc đứng trước</label>
            <Select
              value={newLevelUser.levelBefore}
              className="MALUF_Select"
              onChange={this.onChangeLevelBefore}
            >
              {levelUsers.map((levelUser, index) => (
                <Select.Option key={levelUser._id} value={levelUser._id}>
                  {levelUser.name}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="d-flex justify-content-center MALUF_Action">
            <Button
              label="Thêm"
              icon={<FaSave style={{ marginRight: "4px" }} />}
              className="MALUF_Button"
              onClick={addNewLevelUser}
            />
            <Button
              label="Hủy"
              icon={<FaTimes style={{ marginRight: "4px" }} />}
              className="MALUF_Button_Cancel"
              onClick={() => setModalAddLevelUserState(false)}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStatetoProps = (state) => {
  const _state = state.admin_manager_list_level_user;
  return {
    mdAddLevelUserState: _state.mdAddLevelUserState,
    newLevelUser: _state.newLevelUser,
    levelUsers: _state.levelUsers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    init: () => {},
    setModalAddLevelUserState: (state) => {
      dispatch(
        AdminManagerListLevelUserActions.action.setModalAddLevelUserState(state)
      );
    },
    updateNewLevelUser: (newLevelUser) => {
      dispatch(
        AdminManagerListLevelUserActions.action.updateNewLevelUser(newLevelUser)
      );
    },
    addNewLevelUser: () => {
      dispatch(AdminManagerListLevelUserActions.action.addNewLevelUser());
    },
  };
};

export default connect(mapStatetoProps, mapDispatchToProps)(ModalAddLevelUser);
