import createApiServices from './createApiServices'

const api = createApiServices()

export const getListAddress = (pageSize = 10, pageIndex = 1) => {
    return api.makeAuthRequest({
        url: `address?pageSize=${pageSize}&pageIndex=${pageIndex}`,
        method: 'GET'
    })
}

export const getDetailAddress = id => {
    return api.makeAuthRequest({
        url: `address/getDetail/${id}`,
        method: 'GET'
    })
}

export const insertAddress = address => {
    return api.makeAuthRequest({
        url: 'address/create',
        method: 'POST',
        data: address
    })
}

export const updateAddress = (id, address) => {
    return api.makeAuthRequest({
        url: `address/update/${id}`,
        method: 'PUT',
        data: address
    })
}

export const removeAddress = id => {
    return api.makeAuthRequest({
        url: `address/deactivate/${id}`,
        method: 'DELETE'
    })
}

export const getListAddressOfUser = (id = "", pageSize = 10, pageIndex = 1) => {
    return api.makeAuthRequest({
        url: `address/getAddressByUser/${id}?pageSize=${pageSize}&pageIndex=${pageIndex}`,
        method: 'GET'
    })
}