const prefix = 'admin_customer_group/'

const type = {
    UPDATE_STATE: prefix + 'update_state',

    // list customer group
    LOAD_LIST_CUSTOMER_GROUP: prefix + 'load_list_customer_group',
    LOAD_LIST_CUSTOMER_GROUP_SUCCESS: prefix + 'load_list_customer_group_success',

    UPDATE_PAGE_INDEX: prefix + 'update_page_index',
    REMOVE_CUSTOMER_GROUP: prefix + 'remove_customer_group',

    UPDATE_TEXT_SEARCH: prefix + 'update_text_search',
    SEARCH_CUSTOMER_GROUP: prefix + 'search_customer_group',
    
    // detail customer group 
    LOAD_DETAIL_CUSTOMER_GROUP: prefix + 'load_detail_customer_group',
    LOAD_DETAIL_CUSTOMER_GROUP_SUCCESS: prefix + 'load_detail_customer_group_success',

    SET_DEFAULT_CUSTOMER_GROUP: prefix + 'set_default_customer_group',
    UPDATE_CURRENT_CUSTOMER_GROUP: prefix + 'update_current_customer_group',
    SET_CURRENT_CUSTOMER_GROUP: prefix + 'set_current_customer_group',

    SAVE_CURRENT_CUSTOMER_GROUP: prefix + 'save_current_customer_group',

    // modal add/edit customer group
    SET_MD_CURRENT_CUSTOMER_GROUP_STATE: prefix + 'set_md_current_customer_group_state'
}

const action = {
    updateState: (state = {}) => {
        return {
            type: type.UPDATE_STATE,
            payload: {
                state 
            }
        }
    },

    // list customer group
    loadListCustomerGroup: (params = {}) => {
        return {
            type: type.LOAD_LIST_CUSTOMER_GROUP,
            payload: {
                params
            }
        }
    },
    loadListCustomerGroupSuccess: (customer_groups = []) => {
        return {
            type: type.LOAD_LIST_CUSTOMER_GROUP_SUCCESS,
            payload: {
                customer_groups
            }
        }
    },
    updatePageIndex: (pageIndex = 1) => {
        return {
            type: type.UPDATE_PAGE_INDEX,
            payload: {
                pageIndex
            }
        }
    },
    removeCustomerGroup: (id = "") => {
        return {
            type: type.REMOVE_CUSTOMER_GROUP,
            payload: {
                id
            }
        }
    },
    
    updateTextSearch: (textSearch = "") => {
        return {
            type: type.UPDATE_TEXT_SEARCH,
            payload: {
                textSearch
            }
        }
    },
    searchCustomerGroup: () => {
        return {
            type: type.SEARCH_CUSTOMER_GROUP,
            payload: {

            }
        }
    },

    // detail customer group 
    loadDetailCustomerGroup: (id = "") => {
        return {
            type: type.LOAD_DETAIL_CUSTOMER_GROUP,
            payload: {
                id
            }
        }
    },
    loadDetailCustomerGroupSuccess: (customer_group = {}) => {
        return {
            type: type.LOAD_DETAIL_CUSTOMER_GROUP_SUCCESS,
            payload: {
                customer_group
            }
        }
    },

    setDefaultCustomerGroup: () => {
        return {
            type: type.SET_DEFAULT_CUSTOMER_GROUP,
            payload: {

            }
        }
    },
    updateCurrentCustomerGroup: (customer_group = {}) => {
        return {
            type: type.UPDATE_CURRENT_CUSTOMER_GROUP,
            payload: {
                customer_group
            }
        }
    },
    setCurrentCustomerGroup: (customer_group = {}) => {
        return {
            type: type.SET_CURRENT_CUSTOMER_GROUP,
            payload: {
                customer_group
            }
        }
    },

    saveCurrentCustomerGroup: ()=> {
        return {
            type: type.SAVE_CURRENT_CUSTOMER_GROUP,
            payload: {
                
            }
        }
    },
    setModalCurrentCustomerGroupState: (state) => {
        return {
            type: type.SET_MD_CURRENT_CUSTOMER_GROUP_STATE,
            payload: {
                state
            }
        }
    }
}

export default {
    type,
    action
}