import React, { Component } from "react";
// icons
import { FaAngleLeft, FaCheck, FaPrint, FaTimesCircle } from "react-icons/fa";
// redux
import { connect } from "react-redux";
// router
import { withRouter } from "react-router-dom";
// modal
import ModalConfirmAction from "../../../../../components/modal/modal_confirm_action";
import PopupConfirmAntd from "../../../../../components/modal/popup-confirm-antd";
// helper
import {
  convertIsoDateToString,
  numberDisplayThousand,
} from "../../../../../services/utils/helper";
import NotificationsService from "../../../../../services/utils/notifycationHelper";
import {
  getTypeOrderStatus,
  OrderStatus,
} from "../../../../../types/order.type";
import AdminOrderActions from "../../../../redux/admin_order/actions";
import UserOrderAction from "../../../../redux/user_profile_history_order/actions";
import OrderDetailBottom from "./components/order-detail-bottom";
// scss
import "./index.scss";

class DetailBillPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalConfirmCancelState: false,
    };
  }

  // life cycle
  componentDidMount = () => {
    let id = this.props.match.params.id
      ? this.props.match.params.id
      : this.props.orderId || "";

    if (id) {
      this.props.loadDetailOrder(id);
    }
  };

  // helper
  getShippingTypeText = (type) => {
    switch (type) {
      case "COD":
        return "Thanh toán khi nhận hàng";

      case "DIRECT":
        return "Thanh toán trực tiếp";

      case "ONLINE":
        return "Thanh toán online";

      default:
        return "Chưa rõ ";
    }
  };

  onClickConfirmOrder = ({ id, type, paid, status }) => {
    if (type === "ONLINE" && paid === 0) {
      // disable
    } else {
      const estimatedDeliveryTime =
        this.refOrderDetailBottom?.getEstimatedDeliveryTime?.() || undefined;
      let newStatus = OrderStatus.CONFIRM;
      if (status === OrderStatus.CONFIRM) newStatus = OrderStatus.CONFIRMED;

      if (newStatus === OrderStatus.CONFIRM && !estimatedDeliveryTime) {
        NotificationsService.error("Vui lòng chọn thời gian giao hàng dự kiến");
        return;
      }

      this.props.confirmOrder(id, newStatus, estimatedDeliveryTime);
    }
  };

  onClickCancelOrder = () => {
    if (typeof this.props.setModalCancelOrderState === "function") {
      this.props.setModalCancelOrderState(true);
    }
  };

  onUpdateStatusOrder = (id, status) => {
    this.props.onUpdateStatusOrder(id, status);
  };

  onUpdatePaidDone = (id) => {
    // Cập nhật đã thanh toán
    this.props.updatePaidOrder(id, 1);
  };

  renderStatusOrder = (status) => {
    return (
      <span
        style={{ color: getTypeOrderStatus(status).color, fontWeight: 600 }}
      >
        {getTypeOrderStatus(status).name}
      </span>
    );
  };

  // isCheckWareHouse = (order) => {
  //   let isCheck = true;

  //   const { filters } = this.props;

  //   if (filters && filters.inWareHouseIds) {
  //     if (!order.wareHouseId) isCheck = false;
  //   }

  //   return isCheck;
  // };

  // render

  renderInfoSipper = (order, shipping) => {
    if (order.paymentType === "DIRECT") {
      return (
        <>
          {order.estimatedDeliveryTime ? (
            <div className="DBP_Info_NormaText">
              {`Thời gian dự kiến giao hàng: ${convertIsoDateToString(
                new Date(order.estimatedDeliveryTime)
              )}`}
            </div>
          ) : null}
          Giao hàng trực tiếp
        </>
      );
    }

    return (
      <>
        {order.estimatedDeliveryTime ? (
          <div className="DBP_Info_NormaText">
            {`Thời gian dự kiến giao hàng: ${convertIsoDateToString(
              new Date(order.estimatedDeliveryTime)
            )}`}
          </div>
        ) : null}

        {shipping.shippingType && (
          <div className="DBP_Info_NormaText">
            {`Loại vận chuyển: ${shipping.shippingType}`}
          </div>
        )}
        {shipping.shippingCode && (
          <div className="DBP_Info_NormaText">
            {`Mã vận chuyển: ${shipping.shippingCode}`}
          </div>
        )}
        <div className="DBP_Info_NormaText">
          {shipping.shippingFee
            ? `Phí vận chuyển: ${numberDisplayThousand(shipping.shippingFee)}đ`
            : `Phí vận chuyển: Chưa xác định`}
        </div>
      </>
    );
  };

  render() {
    const { modalCancelOrderState } = this.props;
    let detail_order = this.props.detail_order ? this.props.detail_order : null;

    if (detail_order === null) return null;

    const address =
      detail_order.order && detail_order.order.address
        ? detail_order.order.address
        : {};
    const shipping = detail_order.shipping ? detail_order.shipping : {};
    const order = detail_order.order ? detail_order.order : {};

    const parentsPoint = order.parentsPoint || [];

    const items = detail_order.orderDetails ? detail_order.orderDetails : [];

    let confirmBtnClasses = ["DPB_BtnCancel"];
    if (order.paymentType === "ONLINE" && order.paid === 0) {
      confirmBtnClasses.push("disabled_btn");
    }

    // if (!this.isCheckWareHouse(order)) return null;

    return (
      <div className="DetailBillPage">
        <div className="DBP_BackLinkContainer">
          <span
            // to={RouterLinks.A_LIST_ORDER}
            className="DBP_BackLink"
            onClick={() => {
              this.props.history.goBack();
            }}
            style={{
              cursor: "pointer",
            }}
          >
            <FaAngleLeft className="DBP_BackLinkIcon" />
            Quay lại
          </span>
        </div>

        <div className="DBP_Title">
          {`Chi tiết đơn hàng: ${order.code} - `}
          {this.renderStatusOrder(order.status)}
        </div>
        <div className="DPB_Subtitle">
          {`Ngày đặt hàng: ${convertIsoDateToString(
            new Date(order.createdAt)
          )}`}
        </div>

        <div className="DBP_Info">
          {order.paymentType === "DIRECT" ? null : (
            <div className="DBP_Info_Container">
              <div className="DBP_Info_Panel ">
                <div className="DBP_Info_Title">Địa chỉ người nhận</div>
                <div className="DBP_Info_Content">
                  <div className="DBP_Info_MainText">{address.name}</div>

                  <div className="DBP_Info_NormaText">
                    {`Địa chỉ: ${address.fullAddress}, ${address.wardName}, ${address.districtName}, ${address.provinceName}`}
                  </div>
                  <div className="DBP_Info_NormaText">
                    {`Điện thoại: ${address.phone}`}
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="DBP_Info_Container">
            <div className="DBP_Info_Panel">
              <div className="DBP_Info_Title">Hình thức giao hàng</div>

              <div className="DBP_Info_Content">
                {this.renderInfoSipper(order, shipping)}
              </div>
            </div>
          </div>

          <div className="DBP_Info_Container">
            <div className="DBP_Info_Panel">
              <div className="DBP_Info_Title">Hình thức thanh toán</div>
              <div className="DBP_Info_Content">
                <div className="DBP_Info_NormaText">
                  {this.getShippingTypeText(order.paymentType)}
                </div>
              </div>
            </div>
          </div>

          <div className="DBP_Info_Container">
            <div className="DBP_Info_Panel">
              <div className="DBP_Info_Title">Trạng thái thanh toán</div>
              {order.paid === 1 ? (
                <div className="DBP_Info_Content">Đã thanh toán</div>
              ) : (
                <div className="DBP_Info_Content">Chưa thanh toán</div>
              )}
            </div>
          </div>

          <div className="DBP_Info_Container">
            <div className="DBP_Info_Panel" style={{ marginRight: 0 }}>
              <div className="DBP_Info_Title">Số điểm nhận được</div>
              <div className="DBP_Info_Content">
                <div className="DBP_Info_NormaText">
                  Người mua:{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {order.totalPointsFromOrder} điểm
                  </span>
                </div>

                <div className="DBP_Info_NormaText">
                  --- Người giới thiệu ---
                </div>
                {parentsPoint.map((e, i) => (
                  <div className="DBP_Info_NormaText">
                    {i + 1}. {e.fullName}:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {e.totalPoint} điểm
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="DBP_BillContent">
          {order._id ? (
            <OrderDetailBottom
              order={order}
              products={items}
              ref={(r) => (this.refOrderDetailBottom = r)}
            />
          ) : null}

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <div
              className="DPB_BtnCancel"
              onClick={() => {
                this.props.genBillPDF(order._id);
              }}
              style={{
                backgroundColor: "#00b33c",
                marginRight: 5,
              }}
            >
              <FaPrint className="DBP_BtnIcon" />
              In hoá đơn
            </div>
            {(order.status === OrderStatus.PENDING ||
              order.status === OrderStatus.CONFIRM) && (
              <React.Fragment>
                <div
                  className={confirmBtnClasses.join(" ")}
                  onClick={() => {
                    this.onClickConfirmOrder({
                      id: order._id,
                      type: order.paymentType,
                      paid: order.paid,
                      status: order.status,
                    });
                  }}
                  style={{
                    backgroundColor: "#00b33c",
                    marginRight: 5,
                  }}
                >
                  <FaCheck className="DBP_BtnIcon" />
                  Xác nhận đơn hàng
                </div>

                <div
                  className="DPB_BtnCancel"
                  onClick={this.onClickCancelOrder}
                  style={{
                    backgroundColor: "#ff0000",
                  }}
                >
                  <FaTimesCircle className="DBP_BtnIcon" />
                  Hủy đơn hàng
                </div>
              </React.Fragment>
            )}

            {(order.status === OrderStatus.CONFIRMED ||
              order.status === OrderStatus.SHIP_PENDING) && (
              <React.Fragment>
                {order.status === OrderStatus.CONFIRMED ? (
                  <>
                    <PopupConfirmAntd
                      ref={(r) => (this.refPopupConfirmAntd = r)}
                      onYes={() => {
                        this.onUpdateStatusOrder(
                          order._id,
                          OrderStatus.PENDING
                        );
                      }}
                    />

                    <div
                      className={confirmBtnClasses.join(" ")}
                      onClick={() => {
                        this.refPopupConfirmAntd.onShow({
                          title:
                            "Bạn có chắc muốn chuyển đơn hàng này về trạng thái chờ xác nhận không?",
                        });
                      }}
                      style={{
                        backgroundColor: "red",
                        marginRight: 5,
                      }}
                    >
                      Chờ xác nhận
                    </div>

                    <div
                      className={confirmBtnClasses.join(" ")}
                      onClick={() => {
                        this.onUpdateStatusOrder(order._id, 3);
                      }}
                      style={{
                        backgroundColor: "#00b33c",
                        marginRight: 5,
                      }}
                    >
                      <FaCheck className="DBP_BtnIcon" />
                      Đang giao
                    </div>
                  </>
                ) : null}

                <div
                  className="DPB_BtnCancel"
                  onClick={() => {
                    this.onUpdateStatusOrder(order._id, OrderStatus.COMPLETED);
                  }}
                  style={{
                    backgroundColor: "#00b33c",
                    marginRight: 5,
                  }}
                >
                  <FaCheck className="DBP_BtnIcon" />
                  Đã giao
                </div>
              </React.Fragment>
            )}

            {/* Todo */}
            {order.status === OrderStatus.SHIPPING && (
              <React.Fragment>
                <div
                  className="DPB_BtnCancel"
                  onClick={() => {
                    this.onUpdateStatusOrder(order._id, OrderStatus.COMPLETED);
                  }}
                  style={{
                    backgroundColor: "#00b33c",
                    marginRight: 5,
                  }}
                >
                  <FaCheck className="DBP_BtnIcon" />
                  Đã giao
                </div>
              </React.Fragment>
            )}

            {order.status === OrderStatus.COMPLETED && order.paid === 0 && (
              <React.Fragment>
                <div
                  className="DPB_BtnCancel"
                  onClick={() => {
                    this.onUpdatePaidDone(order._id);
                  }}
                  style={{
                    backgroundColor: "#00b33c",
                    marginRight: 5,
                  }}
                >
                  <FaCheck className="DBP_BtnIcon" />
                  Đã thanh toán
                </div>
              </React.Fragment>
            )}
          </div>

          <ModalConfirmAction
            isOpen={modalCancelOrderState}
            setIsOpen={this.props.setModalCancelOrderState}
            onOk={() => {
              this.props.cancelOrder(order._id);
            }}
            onCancel={() => this.props.setModalCancelOrderState(false)}
            text="Bạn chắc chắn huỷ đơn hàng này?"
            displayCloseIcon={false}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let _state = state.admin_order;

  return {
    detail_order: _state.detail_order,
    modalCancelOrderState: _state.modalCancelOrderState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadDetailOrder: (id) => {
      dispatch(AdminOrderActions.action.loadDetailOrder(id));
    },
    confirmOrder: (id, status, estimatedDeliveryTime) => {
      dispatch(
        AdminOrderActions.action.updateOrderStatus(
          id,
          status,
          estimatedDeliveryTime
        )
      );
    },
    onUpdateStatusOrder: (id, status) => {
      dispatch(AdminOrderActions.action.updateOrderStatus(id, status));
    },
    genBillPDF: (id) => {
      dispatch(UserOrderAction.action.genBillPDF(id));
    },
    setModalCancelOrderState: (state) => {
      dispatch(AdminOrderActions.action.setModalCancelOrderState(state));
    },
    cancelOrder: (orderId) => {
      dispatch(AdminOrderActions.action.cancelOrder(orderId));
    },
    updatePaidOrder: (orderId, paid) => {
      dispatch(AdminOrderActions.action.updatePaidOrder(orderId, paid));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DetailBillPage));
