import React from 'react';
import { FaTimes } from 'react-icons/fa';
import { connect } from 'react-redux';
import AdminPostActions from '../../../../redux/admin_post/actions';

class TagInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
        }
    }

    onChangeAddTagInput = (evt) => {
        const value = evt.target.value;
        this.setState({ value });
    }

    onSubmitTag = (evt) => {
        evt.preventDefault();
        const { detail_post } = this.props;
        let tags = [...detail_post.tags];

        tags.push(this.state.value);

        this.props.updateCurrentPost({tags});
        this.setState({value: ''});
    }

    removeTag = (index) => {
        const { detail_post } = this.props;
        let tags = [...detail_post.tags];

        tags.splice(index, 1);
        this.props.updateCurrentPost({tags});
    }

    render() {
        const { value } = this.state;
        const { detail_post } = this.props;
        return (
            <div className="adminContentComponent">
                <form onSubmit={this.onSubmitTag}>
                    <input
                        type="text"
                        value={value}
                        className="tag-input"
                        placeholder="Thêm tag mới..."
                        onChange={this.onChangeAddTagInput}
                        o
                    />
                    <div className="chip-tag-container">
                        {detail_post.tags.map((tag, index) => (
                            <div className="chip-tag" key={index}>
                                <span className="chip-tag-label">{tag}</span>
                                <FaTimes onClick={() => this.removeTag(index)} />
                            </div>
                        ))}
                    </div>
                </form>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const _state = state.admin_post
    return {
        detail_post: _state.detail_post
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateCurrentPost: info => {
            dispatch(AdminPostActions.action.updateCurrentPost(info))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TagInput);