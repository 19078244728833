import { Image, InputNumber } from "antd";
import { truncate } from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import router_links from "../../../../../const/router_link";
import { getImageUrl } from "../../../../../services/api/upload";
import { numberDisplayThousand } from "../../../../../services/utils/helper";

export default class WareHouseInsertItem extends React.Component {
  onUpdateItem = (key, val) => {
    const { item, onUpdateItem } = this.props;
    onUpdateItem &&
      onUpdateItem({
        ...item,
        [key]: Number(val || 0),
      });
  };

  render() {
    const { item, disabled } = this.props;

    let totalPrice = 0;

    let totalPriceLast = 0;

    if (item.qtyWareHouse) {
      totalPrice = item.price * item.qtyWareHouse;
      totalPriceLast = totalPrice;
    }

    if (item.percentProduct) {
      totalPriceLast = totalPrice - (totalPrice * item.percentProduct) / 100;
    }

    return (
      <div
        className="mt-2 mr-2"
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <Image
          style={{
            borderRadius: 5,
            width: 110,
            height: 120,
            objectFit: "cover",
            borderWidth: 0.5,
            borderColor: "red",
            cursor: "pointer",
          }}
          preview={false}
          src={item.thumbnail && getImageUrl(item.thumbnail)}
        />

        <div className="ml-3" style={{ flex: 1 }}>
          <Link
            to={`${router_links.U_DETAIL_PRODUCT}/${item._id}`}
            target="_blank"
          >
            <span style={{ fontWeight: "bold" }}>
              {truncate(item.name, { length: 30 })}
            </span>
          </Link>

          <br />
          <span>
            {/* {item.isValueAddedProduct
              ? "Sản phầm giá trị tích điểm"
              : "Sản phẩm giá trị cốt lõi"} */}
          </span>

          <br />
          <span>Giá gốc: {numberDisplayThousand(item.price)} đ</span>
          <br />
          <span>
            Số lượng trong kho: {numberDisplayThousand(item.quantity)}
          </span>

          {totalPrice ? (
            <>
              <br />
              <span
                style={{
                  color: "#a04087",
                  fontWeight: "700",
                }}
              >
                Tổng giá bán: {numberDisplayThousand(totalPrice)} đ
              </span>
            </>
          ) : null}

          {totalPriceLast ? (
            <>
              <br />
              <span
                style={{
                  color: "orange",
                  fontWeight: "700",
                }}
              >
                Tổng giá nhập: {numberDisplayThousand(totalPriceLast)} đ
              </span>
            </>
          ) : null}
        </div>

        <div className="ml-3" style={{ flex: 1, height: "100%" }}>
          <span>Số lượng nhập vào</span>
          <br />
          <InputNumber
            placeholder="Số lượng thêm vào kho hàng"
            max={item.quantity}
            style={{ width: "100%", marginBottom: 8 }}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            value={item.qtyWareHouse || ""}
            onChange={(val) => {
              this.onUpdateItem("qtyWareHouse", val);
            }}
            disabled={disabled}
          />

          <br />
          <span>Chiết khấu sản phẩm (%)</span>
          <br />
          <InputNumber
            placeholder="Chiết khấu sản phẩm (%)"
            max={99}
            min={0}
            style={{ width: "100%" }}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            value={item.percentProduct || 0}
            onChange={(val) => {
              this.onUpdateItem("percentProduct", val);
            }}
            disabled={disabled}
          />
        </div>
      </div>
    );
  }
}
