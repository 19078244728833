import actions from "./actions";

const default_product = {
  _id: 0,
  code: "",
  name: "",
  description: "",
  status: true,
  price: 0,
  discount: 0,
  thumbnail: "",
  isValueAddedProduct: false,
  productCategory: "",
  categoryIds: [],
  __v: 0,
  flashSalePrice: 0,
  flashSaleDiscount: 20,
  quantity: 0,
  images: [],
  weight: 0,
  productPriceUnit: "",
};
export const SORT_MODE_CONST = {
  NONE: 1,
  SORT_DESC_NAME: 2,
  SORT_ASC_NAME: 3,
  SORT_DESC_PRICE: 4,
  SORT_ASC_PRICE: 5,
};

const initState = {
  products: [],
  total: 0,
  pageIndex: 1,
  pageSize: 8,
  textSearch: "",
  sortMode: SORT_MODE_CONST.SORT_ASC_NAME,

  // detail product
  product: default_product,
  units: [],

  // category
  categories: [],
  isCms: false,
  displayProductGrid: false,
};

const reducer = (state = initState, action) => {
  let payload = action.payload;

  switch (action.type) {
    case actions.type.UPDATE_STATE:
      return {
        ...state,
        ...payload.state,
      };

    case actions.type.LOAD_LIST_PRODUCT:
      const newState = {
        ...state,
        ...{},
      };

      if (payload?.params?.isCms) {
        newState.isCms = true;
      }

      return newState;

    case actions.type.LOAD_LIST_PRODUCT_SUCCESS:
      return {
        ...state,
        ...{
          products: payload.products,
        },
      };

    case actions.type.DELETE_PRODUCT:
      return state;

    case actions.type.UPDATE_TEXT_SEARCH:
      return {
        ...state,
        ...{
          textSearch: payload.textSearch,
        },
      };

    case actions.type.SEARCH_PRODUCT:
      return state;

    case actions.type.UPDATE_PAGE_SIZE:
      return {
        ...state,
        ...{
          pageIndex: 1,
          pageSize: payload.pageSize,
        },
      };

    case actions.type.UPDATE_PAGE_INDEX:
      return {
        ...state,
        ...{
          pageIndex: payload.pageIndex,
        },
      };

    case actions.type.UPDATE_SORT_MODE:
      return {
        ...state,
        ...{
          sortMode: payload.mode,
        },
      };

    // detail
    case actions.type.LOAD_PRODUCT_DETAIL:
      return state;

    case actions.type.LOAD_PRODUCT_DETAIL_SUCCESS:
      return {
        ...state,
        ...{
          product: payload.product,
        },
      };

    case actions.type.UPDATE_CURRENT_PRODUCT:
      return {
        ...state,
        ...{
          product: {
            ...state.product,
            ...payload.product,
          },
        },
      };

    case actions.type.SET_DEFAULT_CURRENT_PRODUCT:
      return {
        ...state,
        ...{
          product: {
            ...default_product,
            ...{
              images: [],
            },
          },
        },
      };

    case actions.type.SAVE_CURRENT_PRODUCT:
      return state;

    case actions.type.LOAD_LIST_PRODUCT_LIST_PER_UNIT:
      return state;

    case actions.type.LOAD_LIST_PRODUCT_LIST_PER_UNIT_SUCCESS:
      return {
        ...state,
        ...{
          units: payload.units,
        },
      };

    // category
    case actions.type.LOAD_LIST_CATEGORY:
      return state;

    case actions.type.LOAD_LIST_CATEGORY_SUCCESS:
      return {
        ...state,
        ...{
          categories: payload.categories,
        },
      };

    case actions.type.SET_DISPLAY_PRODUCT_GRID_STATE:
      return {
        ...state,
        ...{
          displayProductGrid: payload.state,
        },
      };
    default:
      return state;
  }
};

export default reducer;
