import createApiServices from "../../../../services/api/createApiServices";

const api = createApiServices();

export const apiPostNotifySystem = (data) => {
  return api.makeAuthRequest({
    url: `/notification/notify-system`,
    method: "POST",
    data,
  });
};

export const apiGetNotifySystem = async (pageSize = 10, pageIndex = 1) => {
  const res = await api.makeAuthRequest({
    url: `/notification/me?pageSize=${pageSize}&pageIndex=${pageIndex}`,
    method: "GET",
  });
  if (res.data && res.data.data) return res.data.data;
  return [];
};
