import React, { Component } from "react";
// icon
import { FaCartPlus, FaChevronLeft, FaChevronRight } from "react-icons/fa";
// redux
import { connect } from "react-redux";
// router
import { withRouter } from "react-router-dom";
import { productUnit } from "../../../../const/product_unit";
// regex
import { regexDecimal, regexNumber } from "../../../../const/regex_consts";
import Router_Links from "../../../../const/router_link";
// helper
import { getImageUrl } from "../../../../services/api/upload";
import { numberDisplayThousand } from "../../../../services/utils/helper";
import { history } from "../../../redux/store";
import UserGlobalActions from "../../../redux/user_global/actions";
// scss
import "./index.scss";

class UserDetailProductPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mainImage: this.props.product.thumbnail,
      startIndex: 0,
      quantity: 1,
    };
  }

  // recycler
  componentWillReceiveProps = (nextProps) => {
    if (this.props.match.params.id !== nextProps.match.params.id) {
      let id = nextProps.match.params.id ? nextProps.match.params.id : "";
      if (id) {
        this.props.getDetailProduct(id);
      }
    }

    if (this.props.product.thumnbnail !== nextProps.product.thumbnail) {
      this.setState({
        mainImage: nextProps.product.thumbnail,
      });
    }
  };

  componentDidMount = () => {
    // this.props.setDefaultCurrentPost()

    // check create or add post
    let id = this.props.match.params.id ? this.props.match.params.id : "";

    if (id) {
      this.props.getDetailProduct(id);
    }
  };

  onChangeQuantity = (evt) => {
    const { product } = this.props;
    const value = evt.target.value;
    const isNumber = regexNumber.test(value);
    const isDecimal = regexDecimal.test(value);
    const isWholePart = /^\d+(\.)$/.test(value);

    if (
      product.productUnit.name === productUnit.KG &&
      (isNumber || isDecimal || isWholePart)
    ) {
      this.setState({
        quantity: evt.target.value,
      });
    } else if (isNumber) {
      this.setState({
        quantity: evt.target.value,
      });
    }
  };

  // render
  render() {
    let product = this.props.product;
    // let relation_product = this.props.products ? this.props.products : []

    let discount = product.discount;
    if (discount < 0) discount = 0;
    if (discount > 100) discount = 100;

    let newPrice = (product.price / 100.0) * (100 - discount);
    let relate_products = this.props.relate_products
      ? this.props.relate_products
      : [];
    let unit_name = product.productUnit ? product.productUnit.description : "";

    return (
      <div className="UserDetailProductPage">
        <div className="UDPP_ProductPanel">
          <div className="UDPP_ImagePanel">
            <div className="UDPP_MainImagePanel">
              <img
                className="UDDPP_MainImage"
                src={getImageUrl(this.state.mainImage)}
                alt=""
              />
            </div>
            {Array.isArray(product.images) && product.images.length > 1 && (
              <div className="UDPP_ListImage">
                <FaChevronLeft
                  className="UDPP_ListImageIconLeft"
                  onClick={() => {
                    let startIndex = this.state.startIndex;
                    if (startIndex > 0)
                      this.setState({
                        startIndex: this.state.startIndex - 1,
                      });
                  }}
                />
                {product.images.map((image, index) => {
                  let startIndex = this.state.startIndex;

                  if (index < startIndex) return null;
                  if (index > startIndex + 3) return null;

                  return (
                    <div
                      className="UDPP_ImageItemPanel"
                      onClick={() =>
                        this.setState({
                          mainImage: image.url,
                        })
                      }
                    >
                      <img
                        className="UDPP_ImageItem"
                        src={getImageUrl(image.url)}
                        alt="image"
                      />
                    </div>
                  );
                })}

                <FaChevronRight
                  className="UDPP_ListImageIconRight"
                  onClick={() => {
                    let startIndex = this.state.startIndex;
                    if (startIndex + 1 + 3 < product.images.length)
                      this.setState({
                        startIndex: this.state.startIndex + 1,
                      });
                  }}
                />
              </div>
            )}
            {/* <div
                            style={{
                                color: "black",
                                textAlign: "center", 
                                marginTop: 5
                            }}
                        >
                            {product.images.length} ảnh
                        </div> */}
          </div>
          <div className="UDPP_InfoPanel">
            <div className="UDPP_NameProduct">{product.name}</div>
            <div className="UDPP_PriceProductMobileView">
              <div style={{ marginBottom: 5 }}>
                {discount > 0 ? (
                  <span>
                    <span className="UDPP_OldPrice">
                      {numberDisplayThousand(product.price)}đ
                    </span>
                    <span className="UDPP_NewPrice">
                      {`${numberDisplayThousand(
                        newPrice
                      )}đ/${unit_name}. Tiết kiệm: ${numberDisplayThousand(
                        product.price - newPrice
                      )}đ/${unit_name}`}
                    </span>
                  </span>
                ) : (
                  `${numberDisplayThousand(newPrice)}đ/ ${unit_name}`
                )}
              </div>
            </div>
            <div className="UDPP_Subtite">
              Số lượng trong kho{" : "}
              <span style={{ color: "black", fontWeight: "bold" }}>
                {product.quantity} sản phẩm
              </span>
            </div>

            <div className="UDPP_PriceProduct">
              <div style={{ marginBottom: 5 }}>
                {discount > 0 ? (
                  <span>
                    <span className="UDPP_OldPrice">
                      {numberDisplayThousand(product.price)}đ
                    </span>
                    <span className="UDPP_NewPrice">
                      {`${numberDisplayThousand(
                        newPrice
                      )}đ/${unit_name}. Tiết kiệm: ${numberDisplayThousand(
                        product.price - newPrice
                      )}đ/${unit_name}`}
                    </span>
                  </span>
                ) : (
                  `${numberDisplayThousand(newPrice)}đ/ ${unit_name}`
                )}
              </div>

              {/* {product.flashSaleDiscount > 0 && (
                                <div className="UDPP_FlashsaleInfoProduct">
                                    <div className="UDPP_FlashSaleProduct_Info">
                                        Khuyến mãi kết thúc sau 0 ngày 10:05:00
                                    </div>
                                    <div className="UDPP_FlashSaleProduct">
                                        Đã bán 10
                                        <div
                                            className="UDDP_FlashSaleProgressBar"
                                            style={{
                                                height: 5,
                                                display: "flex",
                                            }}
                                        >
                                            <ProgressBar percent={20} />
                                        </div>
                                    </div>
                                </div>
                            )} */}
            </div>

            <div className="UDDP_AddToCartProduct">
              <div className="UDDP_QuantityPanel">
                <div className="UDDP_QuantityTitle">Số lượng</div>

                <div className="UCP_CartItem_Quantity">
                  <div
                    className="UCP_CartItem_ButtonDown"
                    onClick={() => {
                      if (this.state.quantity > 1)
                        this.setState({
                          quantity: this.state.quantity - 1,
                        });
                    }}
                  >
                    -
                  </div>

                  <input
                    type="text"
                    value={this.state.quantity}
                    onChange={this.onChangeQuantity}
                    className="UCP_CartItem_QuantityTextBox"
                  />

                  <div
                    className="UCP_CartItem_ButtonUp"
                    onClick={(e) => {
                      this.setState({
                        quantity: this.state.quantity + 1,
                      });
                    }}
                  >
                    +
                  </div>
                </div>
              </div>
              <div
                className="UDDP_AddToCartButton"
                onClick={() => {
                  this.props.addCartItem(product._id, this.state.quantity);
                }}
              >
                <FaCartPlus className="UDDP_AddToCartButtonIcon" />
                Thêm vào giỏ hàng
              </div>
            </div>
            <div className="UDPP_DescriptionProduct">
              <div className="UDPP_DescriptionProductTitle">Mô tả sản phẩm</div>
              <span
                dangerouslySetInnerHTML={{ __html: product.description || "" }}
              />
            </div>
          </div>
        </div>

        <div className="UDPP_RelationProduct HomePage">
          <div className="UDPP_RelationProductTitle">
            Các sản phẩm liên quan
          </div>

          <div className="product-panel row">
            {relate_products.map((product, index) => {
              let discount = product.discount;
              if (discount < 0) discount = 0;
              if (discount > 100) discount = 100;

              let price = product.price;
              let newPrice = (product.price / 100.0) * (100 - discount);

              return (
                <div
                  className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12 col-6"
                  style={{
                    paddingLeft: 0,
                    paddingRight: 0,
                  }}
                >
                  <div
                    onClick={() => {
                      history.push(
                        `${Router_Links.U_DETAIL_PRODUCT}/${product._id}`
                      );
                    }}
                    className="APL_ProductItem"
                  >
                    <img
                      className="APL_ProductImage"
                      src={getImageUrl(product.thumbnail)}
                      alt="thumbnail"
                    />

                    <div className="APL_ProductAction">
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          this.props.addCartItem(product._id, 1);
                        }}
                        className="APL_ProductActionPanel"
                      >
                        <FaCartPlus className="APL_ProductActionIcon" />
                      </div>
                    </div>

                    <div className="APL_ProductContent">
                      <div className="APL_ProductName">{product.name}</div>
                      <div className="APL_ProductPrice">
                        {discount > 0 ? (
                          <span>
                            <span className="APL_OldPrice">
                              {numberDisplayThousand(product.price)}đ
                            </span>
                            <span className="APL_NewPrice">
                              {`${numberDisplayThousand(newPrice)}đ`}
                            </span>
                          </span>
                        ) : (
                          `${numberDisplayThousand(newPrice)}đ`
                        )}
                      </div>
                    </div>

                    {discount > 0 && (
                      <div className="APL_ProdcutDiscount">
                        {"-" + discount + "%"}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    product: state.user_global.product,
    products: state.user_global.products,
    relate_products: state.user_global.relate_products,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDetailProduct: (id) => {
      dispatch(UserGlobalActions.action.loadDetailProduct(id));
      dispatch(UserGlobalActions.action.loadRelateProduct(id));
    },
    addCartItem: (productId, quantity) => {
      dispatch(UserGlobalActions.action.addCartItem(productId, quantity));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserDetailProductPage));
