import React, { PureComponent } from "react";
import UserHistoryBillPage, {
  EListOrderType,
} from "../../admin_views/list_order";
import { apiGetWareHouseMe } from "./user-manager-ware-house.service";

export default class UserManagerWareHouse extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      wareHouse: [],
    };
  }

  componentDidMount() {
    this.getWareHouse();
  }

  getWareHouse = async () => {
    let { wareHouse } = await apiGetWareHouseMe({
      pageIndex: 1,
      pageSize: 100,
    });

    this.setState({
      wareHouse,
    });
  };

  render() {
    const { wareHouse } = this.state;
    if (wareHouse.length === 0) return null;

    return (
      <div
        style={{
          width: "100%",
          paddingRight: 10,
          marginTop: -20,
          backgroundColor: "#f2f2f2",
        }}
      >
        <UserHistoryBillPage
          componentType={EListOrderType.USER}
          filters={{
            inWareHouseIds: wareHouse.map((e) => e._id),
          }}
        />
      </div>
    );
  }
}
