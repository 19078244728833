import actions from "./actions";

const initState = {
  orders: [],

  total: 0,
  pageIndex: 1,
  pageSize: 8,

  // detail order
  detail_order: null,
  is_loadding_detail_order: false,
  // modal cancel order state
  modalCancelOrderState: false,
};

const reducer = (state = initState, action) => {
  let payload = action.payload;

  switch (action.type) {
    case actions.type.UPDATE_STATE:
      return {
        ...state,
        ...payload.state,
      };

    // order
    case actions.type.LOAD_LIST_ORDER:
      return {
        ...state,
        ...{
          orders: [],
        },
      };

    case actions.type.LOAD_LIST_ORDER_SUCCESS:
      return {
        ...state,
        ...{
          orders: payload.orders,
        },
      };

    case actions.type.UPDATE_PAGE_INDEX:
      return {
        ...state,
        ...{
          pageIndex: payload.pageIndex,
        },
      };

    // detail order
    case actions.type.LOAD_DETAIL_ORDER:
      return {
        ...state,
        ...{
          detail_order: null,
          is_loadding_detail_order: true,
        },
      };

    case actions.type.LOAD_DETAIL_ORDER_SUCCESS:
      return {
        ...state,
        ...{
          detail_order: payload.order,
        },
      };

    case actions.type.UPDATE_ORDER_STATUS:
      return state;

    case actions.type.SET_MODAL_CANCEL_ORDER_STATE:
      return {
        ...state,
        ...{
          modalCancelOrderState: payload.state,
        },
      };
    case actions.type.CANCEL_ORDER:
      return state;
    default:
      return state;
  }
};

export default reducer;
