import { all, fork, put, select, takeEvery } from 'redux-saga/effects'

import actions from './actions'

import ValidateLevelRelationship from '../../../services/utils/validate/validate_level_relationship'
import NotificationsService from '../../../services/utils/notifycationHelper'

import groupUserApiService from '../../../services/api/group_user';
import permissionApiService from '../../../services/api/permissions';

function* saga_LoadListGroupUser(action) {
    try {
        let params = action.payload.params;

        let _state = yield select((state) => state.admin_group_user);
        let { pageIndex, pageSize, textSearch } = _state;

        if (params.pageIndex) pageIndex = params.pageIndex;
        if (params.pageSize) pageSize = params.pageSize;
        if (params.textSearch) textSearch = params.textSearch;

        let params_request = [
            {
                name: "keyword",
                value: textSearch,
            },
        ];

        const res = yield groupUserApiService.getListGroup(pageSize, pageIndex, params_request);
        if (res.code === 200) {
            const total = res.data.total;
            const groups = res.data.items;
            yield put(actions.action.updateState({ total }));
            yield put(actions.action.loadListGroupUserSuccess(groups));
        }
    } catch (ex) {
        console.log("[admin_group_user Saga] saga_LoadListGroupUser Error : ", ex.message)
    }
}

function* saga_UpdatePageIndex(action) {
    try {
        let pageIndex = action.payload.pageIndex

        yield put(
            actions.action.loadListGroupUser({
                pageIndex
            })
        )
    } catch (ex) {
        console.log("[admin_group_user Saga] saga_UpdatePageIndex Error : ", ex.message)
    }
}

function* saga_SearchGroup(action) {
    try {
        let { textSearch } = action.payload

        yield put(
            actions.action.loadListGroupUser({
                textSearch: textSearch.trim()
            })
        )
    } catch (ex) {
        console.log("[admin_group_user Saga] saga_SearchGroup Error : ", ex.message)
    }
}

function* saga_LoadListModule() {
    try {

    } catch (ex) {
        console.log("[admin_group_user Saga] saga_LoadListModule Error : ", ex.message)
    }
}

// current group
function* saga_RemoveGroup(action) {
    try {

    } catch (ex) {
        console.log("[admin_group_user Saga] saga_RemoveGroup Error : ", ex.message)
    }
}

function* saga_ActiveGroup(action) {
    try {
        let id = action.payload.id

        let req = yield groupUserApiService.activeGroup(id)
        if (req.code === 200) {
            yield put(
                actions.action.loadListGroupUser({})
            )
            NotificationsService.success("Kích hoạt nhóm người dùng thành công")
        }
        else
            NotificationsService.error("Kích hoạt nhóm người dùng thất bại")

    } catch (ex) {
        console.log("[admin_group_user Saga] saga_ActiveGroup Error : ", ex.message)
        NotificationsService.error("Kích hoạt nhóm người dùng thất bại")
    }
}

function* saga_InActiveGroup(action) {
    try {
        let id = action.payload.id

        let req = yield groupUserApiService.inActiveGroup(id)
        if (req.code === 200) {
            yield put(
                actions.action.loadListGroupUser({})
            )
            NotificationsService.success("Khóa nhóm người dùng thành công")
        }
        else
            NotificationsService.error("Khóa nhóm người dùng thất bại")
    } catch (ex) {
        console.log("[admin_group_user Saga] saga_InActiveGroup Error : ", ex.message)
        NotificationsService.error("Khóa nhóm người dùng thất bại")
    }
}

function* saga_SaveCurrentGroup(action) {
    try {
        let { id, group } = action.payload 

        let req = yield groupUserApiService.updateGroup(id, group)
        if (req.code === 200){
            yield put(
                actions.action.loadListGroupUser({})
            )
            NotificationsService.success("Cập nhật nhóm người dùng thành công")
        }
        else
            NotificationsService.error("Cập nhật nhóm người dùng thất bại")

    } catch (ex) {
        console.log("[admin_group_user Saga] saga_SaveCurrentGroup Error : ", ex.message)
        NotificationsService.error("Cập nhật nhóm người dùng thất bại")
    }
}

// permission
function* saga_LoadListPermissionOfGroup(action) {
    try {
        const groupId = action.payload.id;
        const res = yield permissionApiService.getListPermissionOfGroup(groupId);
        if (res.code === 200) {
            yield put(actions.action.loadListPermissionOfGroupSuccess(res.data));
        }
    } catch (ex) {
        console.log("[admin_group_user Saga] saga_LoadListPermissionOfGroup Error : ", ex.message)
    }
}

function* saga_UpdatePermission(action){
    try {
        let { id,  status } = action.payload

        let req 
        if (status === true)
            req = yield permissionApiService.activePermission(id)
        else 
            req = yield permissionApiService.inActivePermission(id)

        if (req.code === 200){
            let group_id = yield select(state => state.admin_group_user.last_group_id)

            yield put(
                actions.action.loadListPermissionOfGroup(group_id)
            )
            NotificationsService.success("Cập nhật thông tin quyền thành công")
        }
        else 
            NotificationsService.error("Cập nhật thông tin quyền thất bại")

    }
    catch (ex){
        console.log("[admin_group_user Saga] saga_UpdatePermission Error : ", ex.message)
        NotificationsService.error("Cập nhật thông tin quyền thất bại")
    }
}

// create group
function* saga_CreateNewGroup() {
    try {
        const _state = yield select((state) => state.admin_group_user);
        const { newGroup } = _state
        const res = yield groupUserApiService.createGroup(newGroup);
        if (res.code === 200) {
            NotificationsService.success("Thêm nhóm người dùng thành công")
            yield put(actions.action.setModalUserGroupState(false))
            yield put(actions.action.loadListGroupUser())
        }
    } catch (ex) {
        console.log("[admin_group_user Saga] saga_LoadListPermissionOfGroup Error : ", ex.message)
        if (ex.message) {
            NotificationsService.error(ex.message.message);
            return;
        }
        NotificationsService.error("Thêm nhóm người dùng thất bại")
    }
}



function* listen() {
    // list group
    yield takeEvery(actions.type.LOAD_LIST_GROUP_USER, saga_LoadListGroupUser);
    yield takeEvery(actions.type.UPDATE_PAGE_INDEX, saga_UpdatePageIndex);
    yield takeEvery(actions.type.SEARCH_GROUP, saga_SearchGroup);
    yield takeEvery(actions.type.LOAD_LIST_MODULE, saga_LoadListModule);

    // current group
    yield takeEvery(actions.type.REMOVE_GROUP, saga_RemoveGroup);
    yield takeEvery(actions.type.ACTIVE_GROUP, saga_ActiveGroup);
    yield takeEvery(actions.type.IN_ACTIVE_GROUP, saga_InActiveGroup);
    yield takeEvery(actions.type.SAVE_CURRENT_GROUP, saga_SaveCurrentGroup);

    // permission
    yield takeEvery(actions.type.LOAD_LIST_PERMISSION_OF_GROUP, saga_LoadListPermissionOfGroup);
    yield takeEvery(actions.type.UPDATE_PERMISSION, saga_UpdatePermission)

    // add new group
    yield takeEvery(actions.type.CREATE_NEW_GROUP, saga_CreateNewGroup);

}

export default function* mainSaga() {
    yield all([fork(listen)])
}