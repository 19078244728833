import React, { Component } from 'react'

// icons
import { FaEllipsisV, FaEdit, FaTrash } from 'react-icons/fa'
import { Link } from 'react-router-dom'

// component
import ToolTip from '../../../../../components/tooltip'
import ModalConfirmAction from '../../../../../components/modal/modal_confirm_action'

// redux
import { connect } from 'react-redux'
import actions from '../../../../redux/admin_post/actions'
import router_links from '../../../../../const/router_link'
import { getImageUrl } from '../../../../../services/api/upload'

class Post extends Component {

    constructor(props) {
        super(props)
        this.state = {
            visible: false
        }
    }

    render() {
        let post = this.props.post

        return (
            <div className="col-lg-3 col-md-3 col-sm-6 col-12">

                <div className="Post" >
                    <Link
                        to={`${router_links.A_EDIT_POST}/${post._id}`}

                    >
                        <img
                            src={getImageUrl(post.thumbnailUrl)}
                            className="Post_Thumb"
                            alt="img"
                        />

                        <div className="Post_Title"                                                                          >
                            {
                                post.title
                            }
                        </div>

                        <div className="Post_SubTitle"                                                                         >
                            {
                                post.description
                            }
                        </div>
                    </Link>

                    <ToolTip
                        visible={this.state.visible}
                        setIsVisible={(state) => {
                            this.setState({ visible: state })
                        }}
                        component={
                            <div className="Control">
                                <FaEllipsisV
                                    className="Icon"
                                />
                            </div>
                        }
                    >
                        <div className="ToolTipControlItem">
                            <Link className="Row"
                                to={`${router_links.A_EDIT_POST}/${post._id}`}
                            >
                                <FaEdit className="Icon" />
                                <div style={{ marginTop: 3 }}>
                                    Chỉnh sửa
                                </div>
                            </Link>
                            <div className="Row"
                                onClick={() => {
                                    this.props.updateCurrentPost(post)
                                    this.props.setConfirmModal(true)
                                }}
                            >
                                <FaTrash className="Icon" />
                                <div style={{ marginTop: 3 }}>
                                    Xóa
                                </div>
                            </div>
                        </div>
                    </ToolTip>

                </div>

            </div>
        )
    }
}

const mapStateToProps = state => {
    return {

    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateCurrentPost: post => {
            dispatch(actions.action.setCurrentPost(post))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Post)