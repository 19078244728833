const prefix = 'admin_level_relationship'

const type = {
    LOAD_LEVEL_RELATIONSHIP: prefix + 'load_level_relationship',
    LOAD_LEVEL_RELATIONSHIP_SUCCESS: prefix + 'load_level_relationship_success',

    // modal add level relationship
    SET_MODAL_ADD_LEVEL_RELATIONSHIP_STATE: prefix + 'set_modal_add_level_relationship_state',
    LOAD_LIST_LEVEL_USER: prefix + 'load_list_level_user',
    LOAD_LIST_LEVEL_USER_SUCCESS: prefix + 'load_list_level_user_success',
    UPDATE_NEW_LEVEL_RELATIONSHIP: prefix + 'update_new_level_relationship',
    ADD_NEW_LEVEL_RELATIONSHIP: prefix + 'add_new_level_relationship'
}

const action = {
    loadLevelRelationship: () => {
        return {
            type: type.LOAD_LEVEL_RELATIONSHIP,
            payload: {

            }
        }
    },
    loadLevelRelationshipSuccess: (levelRelationship = {}) => {
        return {
            type: type.LOAD_LEVEL_RELATIONSHIP_SUCCESS,
            payload: {
                levelRelationship
            }
        }
    },
    setModalAddLevelRelationshipState: (state) => {
        return {
            type: type.SET_MODAL_ADD_LEVEL_RELATIONSHIP_STATE,
            payload: {
                state
            }
        }
    },
    loadListLevelUser: (params = {}) => {
        return {
            type: type.LOAD_LIST_LEVEL_USER,
            payload: {
                params
            }
        }
    },
    loadListLevelUserSuccess: (levelUsers = []) => {
        return {
            type: type.LOAD_LIST_LEVEL_USER_SUCCESS,
            payload: {
                levelUsers
            }
        }
    },
    updateNewLevelRelationship: (newLevelRelationship) => {
        return {
            type: type.UPDATE_NEW_LEVEL_RELATIONSHIP,
            payload: {
                newLevelRelationship
            }
        }
    },
    addNewLevelRelationship: () => {
        return {
            type: type.ADD_NEW_LEVEL_RELATIONSHIP,
            payload: {
                
            }
        }
    }
}

export default {
    type,
    action
}