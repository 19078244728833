// api
import { getDetailProduct } from '../../api/product'

// const
const sig = "guest_"

// function
const genGuestCartId = () => {
    return sig + (new Date().getTime()).toString()
}

const checkIsGuestCartId = (id) => {
    try {
        let id_str = id.toString()

        if (id_str.length<sig.length)
            return false 

        let temp = ""
        for(let index = 0; index < sig.length; index++)
            temp = temp + id_str[index]

        if (temp === sig)
            return true 
        
        return false
    }
    catch{
        return false
    }
}

// if error may be then throw
const genGuestCartItem = async (productId = "", quantity = 0) => {
    // get product 
    let product = null
    let req = await getDetailProduct(productId)
    if (req.code === 200)
        product = req.data 
    else 
        throw {
            message: "Không lấy được chi tiết sản phẩm"
        }

    // quantity 
    if (quantity<=0)
        throw {
            message: "Số lượng phải là số nguyên dương"
        }

    // make cart item
    let id = genGuestCartId()
    let cart_item = {
        _id: id,
        quantity,
        price: product.priceDiscount,
        total: product.priceDiscount * quantity,
        discount: product.discount,
        cartId: null,
        product
    }

    return cart_item
}

export default {
    genGuestCartId,
    genGuestCartItem,
    checkIsGuestCartId
}