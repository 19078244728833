/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react'

import './index.scss'

// react-router
import { Link, withRouter } from 'react-router-dom'

// import RichTextBoxEditor from '../../../../components/richTextBoxEditor'
import RichTextBoxDisplayValue from '../../../../components/richTextBoxEditor/displayRichTextBoxValue'

// redux
import { connect } from 'react-redux'
import { convertIsoDateToString } from '../../../../services/utils/helper'
// import actions from '../../../redux/user_view_post/actions'
import Button from '../../../../components/button'
// api
import PostApiServices from '../../../../services/api/post'
import { FaAngleLeft } from 'react-icons/fa'
import router_links from '../../../../const/router_link'
import { getImageUrl } from '../../../../services/api/upload'

class DetailPost extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: "",
            post: {
                _id: ""
            }
        }
    }

    componentWillReceiveProps = async(nextProps) => {
        if (this.props.match.params.id !== nextProps.match.params.id) {
            let id = nextProps.match.params.id ? nextProps.match.params.id : ""
            if (id) {
                let req = await PostApiServices.GetDetailPost(id)
                let post = req.data
                this.setState({
                    post
                })
            }
        }
    }

    componentDidMount = async () => {
        // this.props.setDefaultCurrentPost()

        // check create or add post 
        let id = this.props.match.params.id ? this.props.match.params.id : ""

        if (id) {
            let req = await PostApiServices.GetDetailPost(id)
            let post = req.data
            this.setState({
                post
            })
        }
    }

    render() {

        let { post } = this.state

        if (this.state.post._id === "")
            return null

        return (
            <div className="d-flex justify-content-center">
                <div className="DetailPostPage">
                <div className='d-flex detail-post-back'>
                    <Button
                        onClick={() => {
                            this.props.history.goBack();
                        }}
                        icon={<FaAngleLeft />}
                        label='Quay lại'
                    />
                </div>
                
                <h1 className="Title">
                    {
                        post.title
                    }
                </h1>
                <div className="Time">
                    {
                        convertIsoDateToString(post.createdAt)
                    }
                </div>
                <div className="SubTile">
                    {
                        post.subtitle
                    }
                </div>
                
                <div>
                    <img className="post-img-header" src={getImageUrl(post.thumbnailUrl)}/>
                </div>
                

                <div className="Content">
                    <RichTextBoxDisplayValue
                        value={post.content}
                    />
                    <div className="ql-tags">
                        <div className="d-inline-block tag-header">Tags:</div>
                        {post.tags.map((tag, index) => (
                            <Link
                                to={router_links.U_LIST_POST_TAGS + `?name=${tag}`}
                            >
                            <div 
                                className="chip-tag" 
                                key={index}
                            >
                                <span className="chip-tag-label tag-client">{tag}</span>
                            </div>
                            </Link>
                            
                        ))}
                    </div>
                </div>

                {/* <hr/> */}
                {/* 
                <div className="comment_panel">
                    <div className="Header">
                        Ý kiến bạn đọc
                    </div>

                    <div className="input_comment_panel">
                        <img
                            className="avatar"
                            src={default_avatar}
                        />

                        <textarea
                            className="input"
                            type="input"
                        />

                    </div>
                    <div className="btnSend">
                        Gửi bình luận
                    </div>
                    <hr />

                    <div className="list_comment">
                        {
                            list_comments.map((comment, index) => {
                                return (
                                    <div className="coment_panel">
                                        <img
                                            className="avatar"
                                            src={comment.avatar}
                                        />
                                        <div className="comment_content">
                                            <div className="name">
                                                {
                                                    comment.name
                                                }
                                            </div>
                                            {
                                                comment.content
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }

                        <div className="btnMore">
                            Xem thêm
                        </div>
                    </div>
                </div>
 */}

                {/* <div className="relation_post">
                    <div className="Header">
                        Bài viết liên quan
                    </div>

                    <div className="ListNews">
                        {
                            relationship_posts.map((news, index) => {
                                return (
                                    <Link to={"/detail-post/" + news._id} className="News">
                                        <img
                                            alt="image"
                                            className="thumb"
                                            src={news.thumb}
                                        />
                                        <div className="InfoView">
                                            <div className="TitleInfo">
                                                {
                                                    news.title
                                                }
                                            </div>
                                            <div className="TimeInfo">
                                                6:30 28/05/2020
                                        </div>
                                            <div className="Subtitle">
                                                {
                                                    news.subtitle
                                                }
                                            </div>
                                        </div>
                                    </Link>
                                )
                            })
                        }
                    </div>
                </div> */}


            </div >
            </div>
            
        )
    }
}

const mapStateToProps = state => {
    let _state = state.user_global

    return {
        post: {},
    }
}

const mapDispatchToProps = dispatch => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DetailPost))
