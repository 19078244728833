import actions from "./actions";

const initState = {
    products: [],
    isLoadingHotProductList: false,
    total: 0,
    pageIndex: 1,
    pageSize: 18,
    textSearch: "",

    // best sell
    bs_products: [],
    isLoadingProductBestSellList: false,
    bs_total: 0,
    bs_pageIndex: 1,
    bs_pageSize: 18,
};

const reducer = (state = initState, action) => {
    let payload = action.payload;

    switch (action.type) {
        case actions.type.UPDATE_STATE:
            return {
                ...state,
                ...payload.state,
            };

        case actions.type.LOAD_LIST_HOT_PRODUCT:
            return {
                ...state,
                isLoadingHotProductList: true,
            };

        case actions.type.LOAD_LIST_HOT_PRODUCT_SUCCESS:
            return {
                ...state,
                ...{
                    products: payload.products,
                    isLoadingHotProductList: false,
                },
            };
        case actions.type.LOAD_LIST_HOT_PRODUCT_FAILED:
            return {
                ...state,
                isLoadingHotProductList: false,
            }

        case actions.type.UPDATE_PAGE_INDEX:
            return {
                ...state,
                ...{
                    pageIndex: payload.pageIndex,
                },
            };

        case actions.type.LOAD_LIST_PRODUCT_BEST_SELL:
            return {
                ...state,
                isLoadingProductBestSellList: true,
            };

        case actions.type.LOAD_LIST_PRODUCT_BEST_SELL_SUCCESS:
            return {
                ...state,
                ...{
                    bs_products: payload.products,
                    isLoadingProductBestSellList: false,
                },
            };

        case actions.type.LOAD_LIST_PRODUCT_BEST_SELL_FAILED:
            return {
                ...state,
                isLoadingProductBestSellList: false,
            }
        case actions.type.UPDATE_PAGE_INDEX_BEST_SELL:
            return {
                ...state,
                ...{
                    bs_pageIndex: payload.pageIndex,
                },
            };

        default:
            return state;
    }
};

export default reducer;
