import { Select } from "antd";
import queryString from "query-string";
import React, { Component } from "react";
import { FaPlus, FaSave, FaTimes } from "react-icons/fa";
// redux
import { connect } from "react-redux";
// query
import { withRouter } from "react-router-dom";
import RichTextBoxEditor from "../../../../components/richTextBoxEditor";
// component
import TreeViewComponent from "../../../../components/treeView";
import { productUnit } from "../../../../const/product_unit";
import { regexDecimal, regexNumber } from "../../../../const/regex_consts";
import RouterLinks from "../../../../const/router_link";
// helper
import { getImageUrl } from "../../../../services/api/upload";
import { convertCategoryDataToTreeViewData } from "../../../../services/utils/helper";
import ProductActions from "../../../redux/admin_product/actions";
// scss
import "./index.scss";

class AdminProductDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      isErrorQuantity: false,
    };
  }

  // handle
  handleImageChange(e) {
    e.preventDefault();

    for (let index = 0; index < e.target.files.length; index++) {
      let file = e.target.files[index];
      let reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onloadend = () => {
        let images = this.props.product.images;

        images.push({
          _id: new Date().getTime(),
          url: reader.result,
          file: file,
        });

        this.props.updateCurrentProduct({
          images,
        });
      };
    }
  }

  handleRemoveImage = (id) => {
    let images = [];

    let product = this.props.product;
    for (let index = 0; index < product.images.length; index++) {
      let image = product.images[index];
      if (image._id !== id) images.push(image);
    }

    this.props.updateCurrentProduct({
      images,
    });
  };

  // event
  _eventClickButtonCancel = () => {
    this.props.history.push(RouterLinks.A_MANAGER_PRODUCT);
  };

  _eventClickButtonSave = () => {
    // validate
    let product = this.props;

    this.props.saveCurrentProduct();
  };

  // life cycle
  componentDidMount = () => {
    this.props.init();
    // get id
    let params = queryString.parse(this.props.location.search);
    let id = params.id ? params.id : "";

    if (id !== "") {
      this.props.loadProductDetail(id);
    } else {
      this.props.setDefaultCurrentProduct();
    }

    this.setState({ id });
  };

  onChangeQuantity = (evt) => {
    const { product, units } = this.props;
    const value = evt.target.value;
    const isNumber = regexNumber.test(value);
    const isDecimal = regexDecimal.test(value);
    const isWholePart = /^\d+(\.)$/.test(value);

    if (!product.productPriceUnit) {
      this.setState({ isErrorQuantity: true });
      return;
    }

    let priceUnit = "";
    for (const unit of units) {
      if (unit._id === product.productPriceUnit) {
        priceUnit = unit.name;
        break;
      }
    }

    if (
      priceUnit === productUnit.KG &&
      (isNumber || isDecimal || isWholePart)
    ) {
      this.props.updateCurrentProduct({
        quantity: value,
      });
    } else if (isNumber) {
      this.props.updateCurrentProduct({
        quantity: value,
      });
    }
  };

  onChangePriceUnit = (value) => {
    this.setState({ isErrorQuantity: false });
    this.props.updateCurrentProduct({
      productPriceUnit: value.value,
      quantity: 0,
    });
  };

  render() {
    let { product, categories, units } = this.props;

    return (
      <div className="AdminProductDetail">
        <div className="APD_MainContent">
          <div className="APD_InfoPanel">
            <div className="APD_InfoRow">
              <div className="APD_InfoTitle">Tên sản phẩm</div>
              <div className="APD_InfoContent">
                <input
                  className="APD_Value"
                  type="text"
                  value={product.name}
                  onChange={(e) => {
                    this.props.updateCurrentProduct({
                      name: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="APD_InfoRow">
              <div className="APD_InfoTitle">Mã sản phẩm</div>
              <div className="APD_InfoContent">
                <input
                  className="APD_Value"
                  type="text"
                  value={product.code}
                  onChange={(e) => {
                    this.props.updateCurrentProduct({
                      code: e.target.value,
                    });
                  }}
                />
              </div>
            </div>

            <div className="APD_InfoRow">
              <div className="APD_InfoTitle">Ghi chú địa chỉ</div>
              <div className="APD_InfoContent">
                <input
                  placeholder="VD: Sản phẩm chỉ giao hàng tại Hà Nội"
                  className="APD_Value"
                  type="text"
                  value={product.noteLocation || ""}
                  onChange={(e) => {
                    this.props.updateCurrentProduct({
                      noteLocation: e.target.value,
                    });
                  }}
                />
              </div>
            </div>

            <div className="APD_InfoRow">
              <div className="APD_InfoTitle">Mô tả sản phẩm</div>
              <div className="APD_InfoContent" style={{ maxHeight: 200 }}>
                <RichTextBoxEditor
                  value={product.description}
                  onChange={(value) => {
                    this.props.updateCurrentProduct({
                      description: value,
                    });
                  }}
                />

                {/* <textarea
                  className="APD_Value"
                  type="text"
                  style={{ minHeight: 100 }}
                  value={product.description}
                  onChange={(e) => {
                    this.props.updateCurrentProduct({
                      description: e.target.value,
                    });
                  }}
                /> */}
              </div>
            </div>

            <div style={{ height: 70, width: 10 }} />

            <div className="APD_InfoRow">
              <div className="APD_InfoTitle">Loại sản phẩm</div>
              <div className="APD_InfoContent">
                {categories.length > 0 && (
                  <TreeViewComponent
                    data_source={convertCategoryDataToTreeViewData(categories)}
                    onSelect={(value) => {
                      this.props.updateCurrentProduct({
                        categoryIds: value,
                      });
                    }}
                    value={product.categoryIds}
                    multiple={true}
                  />
                )}
              </div>
            </div>
            <div className="APD_InfoRow">
              <div className="APD_InfoTitle">Giá</div>
              <div className="APD_InfoContent">
                <input
                  className="APD_Value"
                  type="number"
                  value={product.price}
                  onChange={(e) => {
                    this.props.updateCurrentProduct({
                      price: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="APD_InfoRow">
              <div className="APD_InfoTitle">Khuyến mại (%)</div>
              <div className="APD_InfoContent">
                <input
                  className="APD_Value"
                  type="number"
                  value={product.discount}
                  onChange={(e) => {
                    this.props.updateCurrentProduct({
                      discount: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            {/* <div className="APD_InfoRow">
              <div className="APD_InfoTitle">Cốt lõi sản phẩm</div>
              <div className="APD_InfoContent">
                <Select
                  value={product.isValueAddedProduct}
                  onChange={(value) => {
                    this.props.updateCurrentProduct({
                      isValueAddedProduct: value,
                    });
                  }}
                >
                  <Select.Option value={false}>
                    Sản phẩm giá trị cốt lõi
                  </Select.Option>
                  <Select.Option value={true}>Sản phẩm tích điểm</Select.Option>
                </Select>
              </div>
            </div> */}

            <div
              className="APD_InfoRow"
              style={{
                // display: product.isValueAddedProduct ? "flex" : "none",
              }}
            >
              <div className="APD_InfoTitle">Điểm tặng</div>
              <div className="APD_InfoContent">
                <input
                  className="APD_Value"
                  type="number"
                  value={product.point}
                  onChange={(e) => {
                    this.props.updateCurrentProduct({
                      point: e.target.value,
                    });
                  }}
                />
              </div>
            </div>

            <div className="APD_InfoRow">
              <div className="APD_InfoTitle">Đơn vị tính</div>
              <div className="APD_InfoContent">
                <Select
                  labelInValue
                  style={{
                    minWidth: 100,
                  }}
                  value={{ value: product.productPriceUnit }}
                  onChange={this.onChangePriceUnit}
                >
                  {units.map((unit, index) => {
                    return (
                      <Select.Option value={unit._id}>
                        {unit.description}
                      </Select.Option>
                    );
                  })}
                </Select>
              </div>
            </div>

            <div className="APD_InfoRow">
              <div className="APD_InfoTitle">Số lượng</div>
              <div className="APD_InfoContent d-block">
                <input
                  className="APD_Value"
                  type="text"
                  value={product.quantity}
                  onChange={this.onChangeQuantity}
                />
                {this.state.isErrorQuantity && (
                  <div className="text-danger">
                    Chọn đơn vị tính trước khi nhập số lượng
                  </div>
                )}
              </div>
            </div>

            <div className="APD_InfoRow">
              <div className="APD_InfoTitle">Khối lượng</div>
              <div className="APD_InfoContent">
                <input
                  className="APD_Value"
                  type="number"
                  value={product.weight}
                  onChange={(e) => {
                    this.props.updateCurrentProduct({
                      weight: e.target.value,
                    });
                  }}
                />
              </div>
            </div>

            <div className="APD_InfoRow">
              <div className="APD_InfoTitle">Ảnh sản phẩm</div>
              <div className="APD_InfoImageContent row" style={{ padding: 0 }}>
                {product.images.map((image, index) => {
                  return (
                    <div className="APD_ProductImageItem">
                      <img
                        src={getImageUrl(image.url)}
                        className="APD_ProductImage"
                        alt="image"
                      />

                      <FaTimes
                        className="APD_ProductImage_RemoveButton"
                        onClick={() => {
                          this.handleRemoveImage(image._id);
                        }}
                      />
                    </div>
                  );
                })}
                <input
                  type="file"
                  name="file"
                  id="file"
                  class="hide"
                  onChange={(e) => this.handleImageChange(e)}
                  multiple
                />
                <label for="file">
                  <div className="APD_ProductImageItem">
                    <FaPlus className="APD_UploadProductImage" />
                    <div className="APD_ProductImage_Title">Thêm ảnh</div>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="APD_ActionPanel">
          <div
            className="APD_ActionButton"
            onClick={() => {
              this._eventClickButtonSave();
            }}
          >
            <FaSave className="APD_ActionButtonIcon" />
            Lưu
          </div>
          <div
            className="APD_ActionButton"
            style={{ backgroundColor: "orange" }}
            onClick={() => {
              this._eventClickButtonCancel();
            }}
          >
            <FaTimes className="APD_ActionButtonIcon" />
            Huỷ
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    product: state.admin_product.product,
    categories: state.admin_product.categories,
    units: state.admin_product.units,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    init: () => {
      dispatch(ProductActions.action.loadListCategory());
      dispatch(ProductActions.action.loadListProductListPerUnit());
    },
    loadProductDetail: (id) => {
      dispatch(ProductActions.action.loadProductDetail(id));
    },
    updateCurrentProduct: (product) => {
      dispatch(ProductActions.action.updateCurrentProduct(product));
    },
    saveCurrentProduct: () => {
      dispatch(ProductActions.action.saveCurrentProduct());
    },
    setDefaultCurrentProduct: () => {
      dispatch(ProductActions.action.setDefaultCurrentProduct());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AdminProductDetail));
