import React, { Component, createRef } from "react";

// component
import { Checkbox, Radio, Select } from "antd";
import moment from "moment";

// redux
import { connect } from "react-redux";
import UserProfileAction from "../../../../../redux/user_profile_address/actions";

// scss
import "./index.scss";
import { FaSave, FaUpload } from "react-icons/fa";
import { isValidFullName } from "../../../../../../services/utils/validate/validateFullName";
import { regexNumber } from "../../../../../../const/regex_consts";
import NotificationServices from '../../../../../../services/utils/notifycationHelper'

class AddressShipDetailPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isErrorPhone: false
        }
    }
    // const
    componentWillReceiveProps(nextProps) {
        // provine, district, ward
        if (
            !this.props.detail_address.provinceCode &&
            nextProps.detail_address.provinceCode
        ) {
            let detail_address = nextProps.detail_address
                ? nextProps.detail_address
                : {
                      districtCode: "",
                      provinceCode: "",
                  };

            let { provinceCode, districtCode } = detail_address;
            this.props.init(provinceCode, districtCode);
        }
    }

    componentDidMount = () => {
        // load detail address
        let id = this.props.match.params.id ? this.props.match.params.id : "";

        if (id) {
            this.props.loadDetailAddress(id);
        }

        // provine, district, ward

        let detail_address = this.props.detail_address
            ? this.props.detail_address
            : {
                  districtCode: "",
                  provinceCode: "",
              };

        let { provinceCode, districtCode } = detail_address;
        this.props.init(provinceCode, districtCode);
    };
    
    onChangeName = (evt) => {
        const name = evt.target.value;
        if (isValidFullName(name) || name === '') {
            this.props.updateCurrentAddress({
                name
            });
        }
    }

    onChangePhone = (evt) => {
        const phone = evt.target.value;
        if (regexNumber.test(phone) && phone.length <= 20) {
            this.props.updateCurrentAddress({
                phone
            });
        }
    }

    onSaveCurrentAddress = () => {
        this.props.saveCurrentAddress();
    }

    // render
    render() {
        let { provines, districts, wards } = this.props;
        let detail_address = this.props.detail_address
            ? this.props.detail_address
            : {};

        return (
            <div className="InfoPage">
                <div className="UIF_Title">Thông tin địa chỉ giao hàng</div>

                <div className="UIF_Row">
                    <div className="UIF_RowTitle">Họ tên</div>
                    <div className="UIF_InputPanel">
                        <input
                            type="text"
                            className="UIF_Input"
                            value={detail_address.name}
                            onChange={this.onChangeName}
                        />
                    </div>
                </div>

                {/* <div className="UIF_Row">
                    <div className="UIF_RowTitle">Công ty</div>
                    <div className="UIF_InputPanel">
                        <input type="text" className="UIF_Input" value="" />
                    </div>
                </div> */}

                <div className="UIF_Row">
                    <div className="UIF_RowTitle">SĐT</div>
                    <div className="UIF_InputPanel">
                        <input
                            type="text"
                            className='UIF_Input'
                            value={detail_address.phone}
                            onChange={this.onChangePhone}
                        />
                    </div>
                </div>

                <div className="UIF_Row">
                    <div className="UIF_RowTitle">Tỉnh/Thành phố</div>
                    <div className="UIF_InputPanel">
                        <Select
                            showSearch
                            style={{ flex: 1 }}
                            value={detail_address.provinceCode}
                            onChange={(value) => {
                                this.props.updateCurrentAddress({
                                    provinceCode: value,
                                    districtCode: null,
                                    wardCode: null,
                                });
                                this.props.loadListDistrict(value);
                            }}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {provines &&
                                provines.map((provine, index) => {
                                    let code = provine.code;
                                    return (
                                        <Select.Option
                                            value={code}
                                            // key={index}
                                        >
                                            {provine.name_with_type}
                                        </Select.Option>
                                    );
                                })}
                        </Select>
                    </div>
                </div>

                <div className="UIF_Row">
                    <div className="UIF_RowTitle">Quận/Huyện </div>
                    <div className="UIF_InputPanel">
                        <Select
                            showSearch
                            value={detail_address.districtCode}
                            onChange={(value) => {
                                this.props.updateCurrentAddress({
                                    districtCode: value,
                                    wardCode: null,
                                });
                                this.props.loadListWard(value);
                            }}
                            style={{ flex: 1 }}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {districts &&
                                districts.map((provine, index) => {
                                    let code = provine.code;
                                    // if (provine.code < 10)
                                    //     code = "00" + provine.code;
                                    // if (provine.code < 100 && provine.code >= 10)
                                    //     code = "0" + provine.code;

                                    return (
                                        <Select.Option
                                            value={code}
                                            // key={index}
                                        >
                                            {provine.name_with_type}
                                        </Select.Option>
                                    );
                                })}
                        </Select>
                    </div>
                </div>

                <div className="UIF_Row">
                    <div className="UIF_RowTitle">Xã/Phường </div>
                    <div className="UIF_InputPanel">
                        <Select
                            showSearch
                            value={detail_address.wardCode}
                            onChange={(value) => {
                                this.props.updateCurrentAddress({
                                    wardCode: value,
                                });
                            }}
                            style={{ flex: 1 }}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {wards &&
                                wards.map((provine, index) => {
                                    let code = provine.code;
                                    // if (provine.code < 10)
                                    //     code = "00" + provine.code;
                                    // if (provine.code < 100 && provine.code >= 10)
                                    //     code = "0" + provine.code;

                                    return (
                                        <Select.Option
                                            value={code}
                                            // key={index}
                                        >
                                            {provine.name_with_type}
                                        </Select.Option>
                                    );
                                })}
                        </Select>
                    </div>
                </div>

                <div className="UIF_Row">
                    <div className="UIF_RowTitle">Địa chỉ</div>
                    <div className="UIF_InputPanel">
                        <textarea
                            type="text"
                            className="UIF_Input"
                            style={{ height: 80 }}
                            value={detail_address.fullAddress}
                            onChange={(e) => {
                                this.props.updateCurrentAddress({
                                    fullAddress: e.target.value,
                                });
                            }}
                        />
                    </div>
                </div>

                <div className="UIF_Row">
                    <div className="UIF_RowTitle"> Ghi chú</div>
                    <div className="UIF_InputPanel">
                        <textarea
                            type="text"
                            className="UIF_Input"
                            style={{ height: 80 }}
                            value={detail_address.note}
                            onChange={(e) => {
                                this.props.updateCurrentAddress({
                                    note: e.target.value,
                                });
                            }}
                        />
                    </div>
                </div>

                {/* <div className="UIF_Row">
                    <div className="UIF_RowTitle">Loại địa chỉ</div>
                    <div className="UIF_InputPanel UIF_TypeAddress" >
                        <span className="UIF_RadioPanel">
                            <Radio style={radioStyle} value={0} checked={true}>
                                Nhà riêng / Chung cư
                            </Radio>
                        </span>
                        <span className="UIF_RadioPanel" checked={false}>
                            <Radio style={radioStyle} value={1}>
                                Cơ quan / Công ty
                            </Radio>
                        </span>
                    </div>
                </div> */}

                <div className="UIF_Row">
                    <div className="UIF_RowTitle"></div>
                    <div
                        className="UIF_InputPanel"
                        style={{
                            color: "black",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginTop: 0,
                        }}
                    >
                        <Checkbox
                            style={{ marginRight: 10 }}
                            checked={detail_address.isDefault}
                            onChange={(e) => {
                                this.props.updateCurrentAddress({
                                    isDefault: e.target.checked,
                                });
                            }}
                        />
                        Đặt làm địa chỉ mặc định
                    </div>
                </div>

                <div className="UIF_Row">
                    <div className="UIF_RowTitle"></div>
                    <div className="UIF_InputPanel">
                        <div
                            className="UFI_Button"
                            onClick={this.onSaveCurrentAddress}
                        >
                            <FaSave className="UIF_Icon" />
                            Cập nhật
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    let _state = state.user_profile_address;

    return {
        provines: _state.provines,
        districts: _state.districts,
        wards: _state.wards,
        detail_address: _state.detail_address,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        init: (provineCode, districtCode) => {
            dispatch(UserProfileAction.action.loadListProvine());
            dispatch(UserProfileAction.action.loadListDistrict(provineCode));
            dispatch(UserProfileAction.action.loadListWard(districtCode));
        },
        saveCurrentAddress: () => {
            dispatch(UserProfileAction.action.saveCurrentAddress());
        },
        updateCurrentAddress: (address) => {
            dispatch(UserProfileAction.action.updateCurrentAddress(address));
        },
        loadListDistrict: (provineId) => {
            dispatch(UserProfileAction.action.loadListDistrict(provineId));
        },
        loadListWard: (districtId) => {
            dispatch(UserProfileAction.action.loadListWard(districtId));
        },
        loadDetailAddress: (id) => {
            dispatch(UserProfileAction.action.loadDetailAddress(id));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddressShipDetailPage);
