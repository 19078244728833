import createApiServices from "./createApiServices";

const api = createApiServices();

export const getStatistical = (params = []) => {
    let url = `report`;

    for (let index = 0; index < params.length; index++) {
        let param = params[index];
        if (param.name && param.value) {
            if (index === 0) url = url + `?${param.name}=${param.value}`;
            else url = url + `&${param.name}=${param.value}`;
        }
    }

    return api.makeAuthRequest({
        url: url,
        method: "GET",
    });
};

export const getTotalDeposit = (month = "", year = "") => {
    return api.makeAuthRequest({
        url: `report/totalDeposit?month=${month}&year=${year}`,
        method: "GET",
    });
};

export const getTotalRevenue = (month = "", year = "") => {
    return api.makeAuthRequest({
        url: `report/totalRevenue?month=${month}&year=${year}`,
        method: "GET",
    });
};

export const getLevelUser = (month = "", year = "") => {
    return api.makeAuthRequest({
        url: `report/getLevel?month=${month}&year=${year}`,
        method: "GET",
    });
};

export const getTotalAffiliate = (month = "", year = "") => {
    return api.makeAuthRequest({
        url: `report/totalAffiliate?month=${month}&year=${year}`,
        method: "GET",
    });
};

export const getTotalPoint = (month = "", year = "") => {
    return api.makeAuthRequest({
        url: `report/totalPoint?month=${month}&year=${year}`,
        method: "GET",
    });
};

export const getTotalCommission= (month = "", year = "") => {
    return api.makeAuthRequest({
        url: `report/totalCommission?month=${month}&year=${year}`,
        method: "GET",
    });
};

export const getListUserChild = (pageSize = 10, pageIndex = 1, params = []) => {
    let url = `report/getChildRevenue?pageIndex=${pageIndex}&pageSize=${pageSize}`;

    for (let index = 0; index < params.length; index++) {
        let param = params[index];
        if (param.name && param.value)
            url = url + `&${param.name}=${param.value}`;
    }

    return api.makeAuthRequest({
        url: url,
        method: "GET",
    });
};

export const getListDetailRevenue = (
    pageSize = 10,
    pageIndex = 1,
    params = []
) => {
    let url = `report/getHistoryRevenue?pageIndex=${pageIndex}&pageSize=${pageSize}`;

    for (let index = 0; index < params.length; index++) {
        let param = params[index];
        if (param.name && param.value)
            url = url + `&${param.name}=${param.value}`;
    }

    return api.makeAuthRequest({
        url: url,
        method: "GET",
    });
};
