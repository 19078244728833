export const OrderStatus = {
  CANCELED: -1, // Đã huỷ
  PENDING: 0, // Chờ xác nhận
  CONFIRM: 0.5, // Xác nhận đơn hàng
  CONFIRMED: 1, // Đã xác nhận đơn hàng
  SHIP_PENDING: 2, // Chờ lấy hàng
  SHIPPING: 3, // Đang giao hàng (Dự báo giao hàng)
  COMPLETED: 4, // Đã hoàn thành
};

export const OrderStatusArr = [
  OrderStatus.CANCELED,
  OrderStatus.PENDING,
  OrderStatus.CONFIRM,
  OrderStatus.CONFIRMED,
  OrderStatus.SHIP_PENDING,
  OrderStatus.SHIPPING,
  OrderStatus.COMPLETED,
];

export const getTypeOrderStatus = (status) => {
  let name = "";
  let color = "orange";
  switch (status) {
    case OrderStatus.CANCELED:
      name = "Đã huỷ";
      color = "red";
      break;

    case OrderStatus.PENDING:
      name = "Chờ xác nhận";
      break;

    case OrderStatus.CONFIRM:
      name = "Xác nhận đơn hàng";
      break;

    case OrderStatus.CONFIRMED:
      name = "Đã xác nhận"; // Xác nhận giao hàng
      color = "green";
      break;

    case OrderStatus.SHIP_PENDING:
      name = "Chờ Shiper lấy hàng";
      color = "green";
      break;

    case OrderStatus.SHIPPING:
      name = "Đang giao";
      color = "green";
      break;

    case OrderStatus.COMPLETED:
      color = "green";
      name = "Đã giao";
      break;

    default:
      color = "black";
      break;
  }

  return {
    name,
    color,
  };
};
