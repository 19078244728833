const prefix = "user_profile_history_statistical/";

const type = {
  UPDATE_STATE: prefix + "update_state",

  // statiscal
  GET_STATISTICAL: prefix + "get_statistical",
  GET_STATISTICAL_SUCCESS: prefix + "get_statistical_success",

  UPDATE_TIME_FILTER: prefix + "update_time_filter",

  GET_INFO_OVERVIEW: prefix + "get_info_overview",
  GET_INFO_DEPOSIT_SUCCESS: prefix + "get_info_deposit_success",
  GET_INFO_REVENUE_SUCCESS: prefix + "get_info_revenue_success",
  GET_INFO_LEVEL_SUCCESS: prefix + "get_info_level_success",
  GET_INFO_AFFILIATE_SUCCESS: prefix + "get_info_affiliate_success",
  GET_INFO_COMMISSION_SUCCESS: prefix + "get_info_commission_success",
  GET_INFO_POINT_SUCCESS: prefix + "get_info_point_success",

  GET_LIST_USER_CHILD: prefix + "get_list_user_child",
  GET_LIST_USER_CHILD_SUCCESS: prefix + "get_list_user_child_success",
  UPDATE_PAGE_INDEX_USER_CHILD: prefix + "update_page_index_user_child",

  GET_LIST_DETAIL_REVENUE: prefix + "get_list_detail_revenue",
  GET_LIST_DETAIL_REVENUE_SUCCESS: prefix + "get_list_detail_revenue_success",
  UPDATE_PAGE_INDEX_REVENUE: prefix + "update_page_index_revenue",
};

const action = {
  updateState: (state) => {
    return {
      type: type.UPDATE_STATE,
      payload: {
        state,
      },
    };
  },

  // statistical
  getStatistical: (params = {}) => {
    return {
      type: type.GET_STATISTICAL,
      payload: {},
    };
  },
  getStatisticalSuccess: (data) => {
    return {
      type: type.GET_STATISTICAL_SUCCESS,
      payload: {
        data,
      },
    };
  },

  updateTimeFilter: (filter) => {
    return {
      type: type.UPDATE_TIME_FILTER,
      payload: {
        filter,
      },
    };
  },

  getInfoOverview: (params = {}) => {
    return {
      type: type.GET_INFO_OVERVIEW,
      payload: {
        params,
      },
    };
  },
  getInfoDepositSuccess: (deposit) => {
    return {
      type: type.GET_INFO_DEPOSIT_SUCCESS,
      payload: {
        deposit,
      },
    };
  },
  getInfoRevenueSuccess: (revenue) => {
    return {
      type: type.GET_INFO_REVENUE_SUCCESS,
      payload: {
        revenue,
      },
    };
  },
  getInfoLevelSuccess: (level) => {
    return {
      type: type.GET_INFO_LEVEL_SUCCESS,
      payload: {
        level,
      },
    };
  },
  getInfoAffiliateSuccess: (affiliate) => {
    return {
      type: type.GET_INFO_AFFILIATE_SUCCESS,
      payload: {
        affiliate,
      },
    };
  },
  getInfoCommissionSuccess: (commission) => {
    return {
      type: type.GET_INFO_COMMISSION_SUCCESS,
      payload: {
        commission,
      },
    };
  },
  getInfoPointSuccess: (point) => {
    return {
      type: type.GET_INFO_POINT_SUCCESS,
      payload: {
        point,
      },
    };
  },

  getListUserChild: (params = {}) => {
    return {
      type: type.GET_LIST_USER_CHILD,
      payload: {
        params,
      },
    };
  },
  getListUserChildSuccess: (list_user_childs = []) => {
    return {
      type: type.GET_LIST_USER_CHILD_SUCCESS,
      payload: {
        list_user_childs,
      },
    };
  },
  updatePageIndexUserChild: (pageIndex = 1) => {
    return {
      type: type.UPDATE_PAGE_INDEX_USER_CHILD,
      payload: {
        pageIndex,
      },
    };
  },

  getListDetailRevenue: (params = {}) => {
    return {
      type: type.GET_LIST_DETAIL_REVENUE,
      payload: {
        params,
      },
    };
  },
  getListDetailRevenueSuccess: (list_revenue) => {
    return {
      type: type.GET_LIST_DETAIL_REVENUE_SUCCESS,
      payload: {
        list_revenue,
      },
    };
  },
  updatePageIndexRevenue: (pageIndex) => {
    return {
      type: type.UPDATE_PAGE_INDEX_REVENUE,
      payload: {
        pageIndex,
      },
    };
  },
};

export default {
  type,
  action,
};
