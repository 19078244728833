import {
  Button,
  Col,
  ConfigProvider,
  DatePicker,
  Input,
  Row,
  Select,
  Table,
} from "antd";
import locale from "antd/lib/locale/vi_VN";
import moment from "moment";
import "moment/locale/vi";
import React, { Component } from "react";
import { FaPlusCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import Pagination from "../../../../components/paginations/paginations";
import { AdminListOrderCache } from "../../../../const/cache-data";
import colors from "../../../../const/colors";
import Router_Links from "../../../../const/router_link";
import { getImageUrl } from "../../../../services/api/upload";
import {
  formatToYMDHM,
  numberDisplayThousand,
} from "../../../../services/utils/helper";
import NotificationsService from "../../../../services/utils/notifycationHelper";
import {
  getTypeOrderStatus,
  OrderStatusArr,
} from "../../../../types/order.type";
import { history } from "../../../redux/store";
import "./index.scss";
import PopupNoteOrder from "./order_detail_page/components/popup-note-order";
import {
  apiExportExcelOrder,
  apiGetListOrdersCms,
} from "./service/list-order.service";

const { Option } = Select;

const ITEM_LOAD = 10;

export const EListOrderType = {
  ADMIN: "ADMIN",
  USER: "USER",
};

class UserHistoryBillPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      page: AdminListOrderCache.page || 1,
      loading: true,
      loadingExcel: false,
    };
  }

  componentType = this.props.componentType || EListOrderType.ADMIN;

  totalOrder = 0;

  filters = AdminListOrderCache.filters || undefined;

  // life cycle
  componentDidMount() {
    this.onGetData(AdminListOrderCache.page || 1);
  }

  componentWillUnmount() {
    AdminListOrderCache.page = this.state.page;
    AdminListOrderCache.filters = this.filters;
  }

  onGetData = async (pageIndex = 1) => {
    const { orders, total } = await apiGetListOrdersCms({
      ...this.filters,
      ...this.props.filters,
      pageIndex,
      pageSize: ITEM_LOAD,
    });

    this.totalOrder = total;
    this.setState({ page: pageIndex, data: orders || [], loading: false });
  };

  onResetFilter = () => {
    this.filters = undefined;
    this.onGetData(1);
  };

  onChangePage = (page) => {
    window.scrollTo(0, 0);
    this.setState({ loading: true }, () => {
      this.onGetData(page);
    });
  };

  onChangeStatusPaid = (paid) => {
    if (paid) {
      this.filters = {
        ...this.filters,
        paid,
      };
    } else {
      delete this.filters.paid;
    }
  };

  onChangeStatus = (e) => {
    if (e !== undefined && e.toString()) {
      this.filters = {
        ...this.filters,
        status: e.toString(),
      };
    } else {
      delete this.filters.status;
    }
  };

  onChangeDate = (value, dateString) => {
    if (dateString[0]) {
      this.filters = {
        ...this.filters,
        dateStart: moment(dateString[0]).toISOString(),
        dateEnd: moment(dateString[1]).toISOString(),
      };
    } else {
      delete this.filters.dateStart;
      delete this.filters.dateEnd;
    }
  };

  onAcceptFilter = () => {
    this.setState({ loading: true }, () => {
      this.onGetData(1);
    });
  };

  onUpdateOder = (order) => {
    this.onGetData(this.state.page);
  };

  onExportExcel = async () => {
    if (!this.filters || !this.filters.dateStart || !this.filters.dateEnd) {
      NotificationsService.error(
        "Vui lòng chọn thời gian bắt đầu và thời gian kết thúc"
      );
      return;
    }

    const res = await apiExportExcelOrder(this.filters);

    const url = getImageUrl(res);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Order_Excel");
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
    this.setState({ loadingExcel: false });
  };

  onOrderDetail = (order) => () => {
    const pathname = this.isLayoutAdmin()
      ? `${Router_Links.A_LIST_ORDER_DETAIl}/${order._id}`
      : `${Router_Links.U_PROFILE_WARE_HOUSE_ORDER_DETAIL}/${order._id}`;

    history.push({
      pathname,
      state: this.props.filters || undefined,
    });
  };

  isLayoutAdmin = () => {
    return this.componentType === EListOrderType.ADMIN;
  };

  renderStatusOrder = (status) => {
    return (
      <span
        style={{ color: getTypeOrderStatus(status).color, fontWeight: 600 }}
      >
        {getTypeOrderStatus(status).name}
      </span>
    );
  };

  renderColumns = [
    {
      title: "STT",
      key: "stt",
      align: "center",
      render: (e, j, index) => {
        const { page } = this.state;
        return (
          <span style={{ fontWeight: "600" }}>
            {index + 1 + ITEM_LOAD * (page - 1)}
          </span>
        );
      },
    },

    {
      title: "Đơn hàng",
      key: "orderCode",
      render: (order) => {
        return (
          <span>
            Mã đơn:{" "}
            <span style={{ color: colors.primaryColor, fontWeight: "600" }}>
              {order.orderCode}
            </span>
            <br />
            {order?.infoWareHouse?.code ? (
              <span>
                Mã kho hàng:{" "}
                <span style={{ color: colors.secondColor, fontWeight: "600" }}>
                  {order?.infoWareHouse?.code}
                </span>
              </span>
            ) : (
              <span>
                Kho tổng:{" "}
                <span style={{ color: colors.secondColor, fontWeight: "600" }}>
                  CADOSA
                </span>
              </span>
            )}
          </span>
        );
      },
    },
    {
      title: "Người mua",
      key: "userBuy",
      render: (order) => {
        return (
          <span>
            {"Tên: "}
            <span style={{ fontWeight: "600" }}>
              {order?.infoUser?.fullName}
            </span>
            <br />
            {"SĐT: "}
            <span style={{ fontWeight: "600" }}>{order?.infoUser?.phone}</span>
          </span>
        );
      },
    },
    {
      title: "Ngày mua",
      key: "createdAt",
      render: (order) => {
        return <span>{formatToYMDHM(new Date(order.createdAt))}</span>;
      },
    },
    {
      title: "Tổng tiền",
      key: "totalPrice",
      align: "right",
      render: (order) => {
        return (
          <span style={{ fontWeight: "700", color: "#a04087" }}>
            {numberDisplayThousand(order.totalPrice)}đ
          </span>
        );
      },
    },
    {
      title: "Thanh toán",
      key: "paid",
      align: "center",
      render: (order) => {
        return (
          <span
            style={{
              color: order.paid ? colors.primaryColor : colors.secondColor,
            }}
          >
            {order.paid === 1 ? "Đã thanh toán" : "Chưa thanh toán"}
          </span>
        );
      },
    },
    {
      title: "Trạng thái",
      align: "center",
      key: "status",
      render: (order) => {
        return this.renderStatusOrder(order.status);
      },
    },
    {
      title: "Hành động",
      align: "center",
      key: "action",
      width: 120,
      render: (order) => {
        const widthButton = 105;
        return (
          <>
            <Button
              type="primary"
              style={{ width: widthButton }}
              onClick={() => {
                this.refPopupNote.onShow(order);
              }}
            >
              {order.notes && order.notes.length > 0
                ? "Xem ghi chú"
                : "Ghi chú"}
            </Button>

            <br />

            <Button
              type="primary"
              style={{
                width: widthButton,
                marginTop: 5,
                backgroundColor: "#a04087",
                borderColor: "#a04087",
              }}
              onClick={this.onOrderDetail(order)}
            >
              Chi tiết đơn
            </Button>
          </>
        );
      },
    },
  ];

  render() {
    const { data, page, loading, loadingExcel } = this.state;

    return (
      <div className="UserHistoryBillPage">
        <div className="FilterSearchAndSort">
          <div className="UHB_Action" style={{ flex: 1 }}>
            {this.isLayoutAdmin() ? (
              <Link className="UHB_Btn_Add" to={Router_Links.A_CREATE_ORDER}>
                <FaPlusCircle className="UHB_Btn_Icon" />
                Thêm đơn hàng
              </Link>
            ) : null}
          </div>

          <Button
            type="primary"
            className="ml-2"
            style={{ backgroundColor: "#a04087", borderColor: "#a04087" }}
          >
            {this.totalOrder} đơn hàng
          </Button>

          <div className="dropdown2">
            <Input
              placeholder="Mã DH/Tên/SDT/Email"
              allowClear
              defaultValue={this.filters?.keyword || ""}
              onChange={(e) => {
                this.filters = {
                  ...this.filters,
                  keyword: e.target.value,
                };
              }}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  this.onAcceptFilter();
                }
              }}
            />
          </div>

          <div className="dropdown2">
            <ConfigProvider locale={locale}>
              <DatePicker.RangePicker
                placeholder={["Bắt đầu", "Kết thúc"]}
                format="YYYY-MM-DD"
                onChange={this.onChangeDate}
              />
            </ConfigProvider>
          </div>

          <div className="dropdown2">
            <Select
              placeholder="Trạng thái đơn hàng"
              style={{ width: 180 }}
              allowClear
              onChange={this.onChangeStatus}
            >
              {OrderStatusArr.map((e) => (
                <Option value={e}>{getTypeOrderStatus(e).name}</Option>
              ))}
            </Select>
          </div>

          <div className="dropdown2">
            <Select
              placeholder="Thanh toán"
              style={{ width: 150 }}
              allowClear
              onChange={this.onChangeStatusPaid}
            >
              <Option value={"0"}>Chưa thanh toán</Option>
              <Option value={"1"}>Đã thanh toán</Option>
            </Select>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: 10,
            marginTop: this.isLayoutAdmin() ? 0 : 10,
          }}
        >
          <div style={{ flex: 1 }} />

          {data.length > 0 ? (
            <Button
              type="primary"
              danger
              className="ml-2"
              style={{ backgroundColor: "#00b33c", borderColor: "#00b33c" }}
              onClick={() => {
                this.setState({ loadingExcel: true }, this.onExportExcel);
              }}
              loading={loadingExcel}
            >
              Xuất Excel
            </Button>
          ) : null}

          <Button
            type="primary"
            danger
            className="ml-2"
            onClick={() => {
              window.location.reload();
            }}
          >
            Bỏ lọc
          </Button>

          <Button
            type="primary"
            className="ml-2"
            loading={loading}
            onClick={this.onAcceptFilter}
          >
            Tìm kiếm
          </Button>
        </div>

        <Row style={{ marginTop: 10 }}>
          <Col span={24}>
            <Table
              loading={loading}
              size="middle"
              key={1}
              dataSource={data || []}
              columns={this.renderColumns}
              pagination={false}
              scroll={{ x: "auto" }}
              rowKey="_id"
            />
          </Col>
        </Row>

        <div className="UHB_PaginateSection">
          <Pagination
            onChange={this.onChangePage}
            pageIndex={page}
            pageSize={ITEM_LOAD}
            total={this.totalOrder}
          />
        </div>

        <PopupNoteOrder
          ref={(ref) => (this.refPopupNote = ref)}
          onUpdateNote={this.onUpdateOder}
        />
      </div>
    );
  }
}

export default UserHistoryBillPage;
