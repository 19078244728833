import { Image } from "antd";
import { truncate } from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import router_links from "../../../../../const/router_link";
import { getImageUrl } from "../../../../../services/api/upload";
import { numberDisplayThousand } from "../../../../../services/utils/helper";

export default class WareHouseCreateItem extends React.Component {
  render() {
    const { item } = this.props;

    const infoWareHouse = item.productWareHouse || undefined;

    return (
      <div
        className="mt-2 mr-2"
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <Image
          style={{
            borderRadius: 5,
            width: 110,
            height: 100,
            objectFit: "cover",
            borderWidth: 0.5,
            borderColor: "red",
            cursor: "pointer",
          }}
          preview={false}
          src={item.thumbnail && getImageUrl(item.thumbnail)}
        />

        <div className="ml-3" style={{ flex: 1 }}>
          <Link
            to={`${router_links.U_DETAIL_PRODUCT}/${item._id}`}
            target="_blank"
          >
            <span style={{ fontWeight: "bold" }}>
              {truncate(item.name, { length: 30 })}
            </span>
          </Link>

          <br />
          <span>
            {/* {item.isValueAddedProduct
              ? "Sản phầm giá trị tích điểm"
              : "Sản phẩm giá trị cốt lõi"} */}
          </span>

          <br />
          <span>Giá bán: {numberDisplayThousand(item.price)} đ</span>
          <br />
          <span>
            Số lượng trong kho: {numberDisplayThousand(item.quantity)}
          </span>
        </div>

        <div className="ml-3" style={{ flex: 1, height: "100%" }}>
          {infoWareHouse ? (
            <>
              <span>
                {"Còn lại: "}
                <span style={{ fontWeight: "bold" }}>
                  {infoWareHouse.quantityProductRemain}
                  {" / "}
                  {infoWareHouse.totalQuantity}
                </span>
                {" sản phẩm"}
              </span>
              <br />

              <span>
                {"Đã bán: "}
                <span style={{ fontWeight: "bold" }}>
                  {infoWareHouse.totalQuantity -
                    infoWareHouse.quantityProductRemain}
                </span>
                {" sản phẩm"}
              </span>
              <br />
            </>
          ) : null}
        </div>
      </div>
    );
  }
}
