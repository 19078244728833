import actions from './actions'

const default_group = {
    name: "",
    description: "",
    status: true,
    isAdmin: false,
    _id: ""
}

const newGroup = {
    name: "",
    description: "",
}

const initState = {
    groups: [],

    modules: [],
    
    permissions: [],
    is_loading_permission: false,

    pageSize: 10,
    pageIndex: 1, 
    total: 0,
    textSearch: "",

    // current group
    current_group: default_group,

    // modal add user group
    mdAddUserGroupState: false,
    newGroup: newGroup,

    last_group_id: null
}

const reducer = (state = initState, action) => {
    const payload = action.payload ? action.payload : {}

    switch (action.type) {
        case actions.type.UPDATE_STATE:
            return {
                ...state,
                ...payload.state 
            }

        case actions.type.LOAD_LIST_GROUP_USER:
            return state 

        case actions.type.LOAD_LIST_GROUP_USER_SUCCESS:
            return {
                ...state, 
                ...{
                    groups: payload.groups 
                }
            }

        case actions.type.LOAD_LIST_MODULE:
            return state 

        case actions.type.LOAD_LIST_MODULE_SUCCESS:
            return {
                ...state,
                ...{
                    modules: payload.modules
                }
            }

        case actions.type.UPDATE_PAGE_INDEX:
            return {
                ...state,
                ...{
                    pageIndex: payload.pageIndex
                }
            }

        case actions.type.SEARCH_GROUP:
            return {
                ...state,
                ...{
                    last_text_search: payload.textSearch
                }
            }

        // detail user 
        case actions.type.SET_CURRENT_GROUP:
            return {
                ...state,
                ...{
                    current_group: payload.group
                }
            }

        case actions.type.SET_DEFAULT_CURRENT_GROUP:
            return {
                ...state,
                ...{
                    current_group: default_group
                }
            }

        case actions.type.UPDATE_CURRENT_GROUP:
            return {
                ...state,
                ...{
                    current_group:{
                        ...state.current_group,
                        ...payload.group
                    }
                }
            }

        case actions.type.REMOVE_GROUP:
            return state 

        case actions.type.ACTIVE_GROUP:
            return state 

        case actions.type.IN_ACTIVE_GROUP:
            return state

        case actions.type.SAVE_CURRENT_GROUP:
            return state

        case actions.type.LOAD_LIST_PERMISSION_OF_GROUP:
            return {
                ...state,
                ...{
                    last_group_id: payload.id,
                    is_loading_permission: true
                }
            }

        case actions.type.LOAD_LIST_PERMISSION_OF_GROUP_SUCCESS:
            return {
                ...state,
                ...{
                    is_loading_permission: false,
                    permissions: payload.permissions
                }
            }

        case actions.type.UPDATE_PERMISSION:
            return state

        case actions.type.UPDATE_TEXT_SEARCH:
            return {
                ...state,
                ...{
                    textSearch: payload.textSearch
                }
            }
        case actions.type.SET_MD_ADD_USER_GROUP_STATE:
            return {
                ...state,
                ...{
                    mdAddUserGroupState: payload.state
                }
            }
        case actions.type.UPDATE_NEW_GROUP:
            return {
                ...state,
                ...{
                    newGroup: {
                        ...state.newGroup,
                        ...payload.newGroup
                    }
                }
            }
        case actions.type.CREATE_NEW_GROUP:
            return state
        default:
            return state
    }
}

export default reducer