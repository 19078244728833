import { Button, Col, Pagination, Row, Table } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { PureComponent } from "react";
import router_links from "../../../../../const/router_link";
import {
  dateFormatHMDYM,
  numberDisplayThousand,
} from "../../../../../services/utils/helper";
import { getTypeWareHouseInsertStatus } from "../../../../../types/warehouse.type";
import { history as historyNav } from "../../../../redux/store";
import { apiGetInsertProductHistory } from "../service/warehouse-create.sevice";

const ITEM_LOAD = 8;

export default class PopupHistoryInsertProduct extends PureComponent {
  totalData = 0;

  wareHouseId = undefined;

  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      data: [],
      loading: true,
      page: 1,
    };
  }

  open = (wareHouseId) => {
    this.wareHouseId = wareHouseId;
    this.setState({ modalVisible: true });
    this.onGetData(1);
  };

  onGetData = async (pageIndex) => {
    let params = {
      pageIndex,
      pageSize: ITEM_LOAD,
    };

    const { data, total } = await apiGetInsertProductHistory(
      this.wareHouseId,
      params
    );

    this.totalData = total;

    this.setState({
      data,
      loading: false,
    });
  };

  onChangePage = (page) => {
    this.setState(
      {
        loading: true,
        page,
      },
      () => {
        this.onGetData(page);
      }
    );
  };

  onCancel = () => {
    this.setState({
      loading: true,
      page: 1,
      modalVisible: false,
    });
  };

  onViewDetail = (e) => {
    historyNav.push({
      pathname: router_links.A_WARE_HOUSE_INSERT_PRODUCT,
      search: `?wareHouseId=${this.wareHouseId}&wareHouseInsertId=${e._id}`,
    });
  };

  renderColumns = [
    {
      title: "Số lượng sản phẩm",
      key: "productIds",
      render: ({ productIds }) => {
        return (
          <div>
            <span>{productIds.length} sản phẩm</span>
          </div>
        );
      },
    },
    {
      title: "Giá gốc",
      key: "priceProduct",
      align: "right",
      render: ({ priceProduct }) => {
        return (
          <div>
            <span>{numberDisplayThousand(priceProduct)}đ</span>
          </div>
        );
      },
    },
    {
      title: "Chiết khấu",
      key: "priceDiscountProduct",
      align: "right",
      render: (e) => {
        return (
          <div>
            <span>
              {numberDisplayThousand(e.priceProduct - e.priceWholesale)}đ
            </span>
          </div>
        );
      },
    },
    {
      title: "Giá nhập",
      key: "priceWholesale",
      align: "right",
      render: ({ priceWholesale }) => {
        return <span>{numberDisplayThousand(priceWholesale)}đ</span>;
      },
    },
    {
      title: "Trạng thái",
      key: "status",
      align: "right",
      render: ({ status }) => {
        return (
          <span
            style={{
              color: getTypeWareHouseInsertStatus(status).color,
              fontWeight: "bold",
            }}
          >
            {getTypeWareHouseInsertStatus(status).name}
          </span>
        );
      },
    },
    {
      title: "Thời gian nhập",
      align: "right",
      key: "createdAt",
      render: ({ createdAt }) => {
        return <span>{dateFormatHMDYM(createdAt)}</span>;
      },
    },
    {
      title: "Chi tiết",
      key: "view_detail",
      align: "center",
      render: (e) => {
        return (
          <Button type="primary" onClick={() => this.onViewDetail(e)}>
            Xem
          </Button>
        );
      },
    },
  ];

  render() {
    const { modalVisible, data, page, loading } = this.state;

    return (
      <Modal
        visible={modalVisible}
        onOk={this.onCancel}
        onCancel={this.onCancel}
        centered
        title="Lịch sử cập nhật sản phẩm kho hàng"
        width={1000}
        footer={false}
      >
        <Row>
          <Col span={24}>
            <Table
              loading={loading}
              size="middle"
              bordered
              pagination={false}
              dataSource={data || []}
              columns={this.renderColumns}
            />
          </Col>

          <Col
            span={24}
            style={{
              display: "inline-flex",
              justifyContent: "flex-end",
              marginTop: "10px",
            }}
          >
            <div className="d-flex justify-content-end pagination">
              <Pagination
                size="small"
                showSizeChanger={false}
                total={this.totalData}
                showTotal={(total, range) => `${range[0]}-${range[1]}/${total}`}
                current={page}
                defaultPageSize={ITEM_LOAD}
                onChange={this.onChangePage}
              />
            </div>
          </Col>
        </Row>
      </Modal>
    );
  }
}
