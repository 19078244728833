const prefix = 'user_flash_sale';

const type = {
    LOAD_PRODUCT_FLASH_SALE_REQUEST: prefix +  'load_product_flash_sale_request',
    LOAD_PRODUCT_FLASH_SALE_SUCCESS: prefix +  'load_product_flash_sale_success',
    LOAD_PRODUCT_FLASH_SALE_FAILED: prefix +  'load_product_flash_sale_failed',
}

const action = {
    loadProductFlashSaleRequest(params = {}) {
        return {
            type: type.LOAD_PRODUCT_FLASH_SALE_REQUEST,
            payload: {
                params
            }
        }
    },
    loadProductFlashSaleSuccess(productList = [], flashSale = {}) {
        return {
            type: type.LOAD_PRODUCT_FLASH_SALE_SUCCESS,
            payload: {
                productList,
                flashSale
            }
        }
    },
    loadProductFlashSaleFailed(error = null) {
        return {
            type: type.LOAD_PRODUCT_FLASH_SALE_FAILED,
            payload: {
                error
            }
        }
    }
}

export default {
    type,
    action,
};
