import { Select } from "antd";
import React from "react";
import { connect } from "react-redux";
import UserProfileAction from "../../../../redux/user_profile_address/actions";
import "./index.scss";

class WareHouseCreateAddress extends React.PureComponent {
  provinceCode = this.props.valueDefault.provinceCode || undefined;
  districtCode = this.props.valueDefault.districtCode || undefined;
  wardCode = this.props.valueDefault.wardCode || undefined;

  componentWillReceiveProps(nextProps) {
    if (
      !this.props.detail_address.provinceCode &&
      nextProps.detail_address.provinceCode
    ) {
      let detail_address = nextProps.detail_address
        ? nextProps.detail_address
        : {
            districtCode: "",
            provinceCode: "",
          };

      let { provinceCode, districtCode } = detail_address;
      this.props.init(provinceCode, districtCode);
    }
  }

  componentDidMount = () => {
    this.props.init(this.provinceCode, this.districtCode);
  };

  onUpdateAddress = () => {
    const params = {
      provinceCode: this.provinceCode,
      districtCode: this.districtCode,
      wardCode: this.wardCode,
    };

    this.props.onUpdateAddress && this.props.onUpdateAddress(params);
  };

  render() {
    let { provines, districts, wards } = this.props;
    return (
      <>
        <p className="nameInput">Địa chỉ kho hàng</p>
        <div className="UIF_Row">
          <div className="UIF_RowTitle">Tỉnh/Thành phố</div>
          <div className="UIF_InputPanel">
            <Select
              showSearch
              style={{ flex: 1 }}
              value={this.provinceCode}
              onChange={(value) => {
                this.provinceCode = value;
                this.districtCode = undefined;
                this.wardCode = undefined;
                this.props.updateCurrentAddress({
                  provinceCode: value,
                  districtCode: null,
                  wardCode: null,
                });
                this.props.loadListDistrict(value);

                this.onUpdateAddress();
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {provines &&
                provines.map((provine, index) => {
                  let code = provine.code;
                  return (
                    <Select.Option value={code}>
                      {provine.name_with_type}
                    </Select.Option>
                  );
                })}
            </Select>
          </div>
        </div>

        <div className="UIF_Row">
          <div className="UIF_RowTitle">Quận/Huyện </div>
          <div className="UIF_InputPanel">
            <Select
              showSearch
              value={this.districtCode}
              onChange={(value) => {
                this.districtCode = value;
                this.wardCode = undefined;
                this.props.updateCurrentAddress({
                  districtCode: value,
                  wardCode: null,
                });
                this.props.loadListWard(value);
                this.onUpdateAddress();
              }}
              style={{ flex: 1 }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {districts &&
                districts.map((provine, index) => {
                  let code = provine.code;
                  return (
                    <Select.Option value={code}>
                      {provine.name_with_type}
                    </Select.Option>
                  );
                })}
            </Select>
          </div>
        </div>

        <div className="UIF_Row">
          <div className="UIF_RowTitle">Xã/Phường </div>
          <div className="UIF_InputPanel">
            <Select
              showSearch
              value={this.wardCode}
              onChange={(value) => {
                this.wardCode = value;
                this.props.updateCurrentAddress({
                  wardCode: value,
                });
                this.onUpdateAddress();
              }}
              style={{ flex: 1 }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {wards &&
                wards.map((provine, index) => {
                  let code = provine.code;

                  return (
                    <Select.Option value={code}>
                      {provine.name_with_type}
                    </Select.Option>
                  );
                })}
            </Select>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  let _state = state.user_profile_address;

  return {
    provines: _state.provines,
    districts: _state.districts,
    wards: _state.wards,
    detail_address: _state.detail_address,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    init: (provineCode, districtCode) => {
      dispatch(UserProfileAction.action.loadListProvine());
      if (provineCode)
        dispatch(UserProfileAction.action.loadListDistrict(provineCode));
      if (districtCode)
        dispatch(UserProfileAction.action.loadListWard(districtCode));
    },
    updateCurrentAddress: (address) => {
      dispatch(UserProfileAction.action.updateCurrentAddress(address));
    },
    loadListDistrict: (provineId) => {
      dispatch(UserProfileAction.action.loadListDistrict(provineId));
    },
    loadListWard: (districtId) => {
      dispatch(UserProfileAction.action.loadListWard(districtId));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WareHouseCreateAddress);
