import { all, fork, put, select, takeEvery } from "redux-saga/effects";
// api
import {
  getLevelUser,
  getListDetailRevenue,
  getListUserChild,
  getStatistical,
  getTotalAffiliate,
  getTotalCommission,
  getTotalDeposit,
  getTotalPoint,
  getTotalRevenue,
} from "../../../services/api/statistical";
import DateTimeHelperServices from "../../../services/utils/model_process_helper/date_time_helper";
import actions from "./actions";

function* saga_GetStatistical(action) {
  try {
    let params = action.payload.params;

    let _state = yield select((state) => state.user_profile_statistical);
    let filter_time = _state.filter_time;

    if (action.payload.params && action.payload.params.filter_time)
      filter_time = action.payload.params.filter_time;
    let start_time = DateTimeHelperServices.getDateFromMy(filter_time);

    // start_time.setFullYear(start_time.getFullYear() - 1);
    start_time.setMonth(start_time.getMonth() - 6);

    start_time = DateTimeHelperServices.getObjectFromTime(start_time);
    filter_time = DateTimeHelperServices.getObjectFromMY(filter_time);

    // call api
    let param_query = [
      {
        name: "endMonth",
        value: filter_time.month,
      },
      {
        name: "endYear",
        value: filter_time.year,
      },
      {
        name: "startMonth",
        value: start_time.month,
      },
      {
        name: "startYear",
        value: start_time.year,
      },
    ];
    let req = yield getStatistical(param_query);

    if (req.code === 200) {
      yield put(actions.action.getStatisticalSuccess(req.data));
    }
  } catch (ex) {
    console.log(
      "[user_profile_statistical] saga_GetStatistical error : ",
      ex.message
    );
  }
}

function* saga_GetInfoOverview(action) {
  try {
    let filter_time = yield (state) =>
      state.user_profile_statistical.filter_time;

    if (action.payload.params && action.payload.params.filter_time)
      filter_time = action.payload.params.filter_time;

    filter_time = DateTimeHelperServices.getObjectFromMY(filter_time);

    // deposit
    let req = yield getTotalDeposit(filter_time.month, filter_time.year);
    if (req.code === 200) {
      try {
        let deposit = req.data.deposit;
        yield put(actions.action.getInfoDepositSuccess(deposit));
      } catch (ex) {}
    }

    // revenue
    req = yield getTotalRevenue(filter_time.month, filter_time.year);
    if (req.code === 200) {
      try {
        let revenue = req.data.revenue;
        yield put(actions.action.getInfoRevenueSuccess(revenue));
      } catch {}
    }

    // affiliate
    req = yield getTotalAffiliate(filter_time.month, filter_time.year);
    if (req.code === 200) {
      try {
        let countChild = req.data.countChild;
        yield put(actions.action.getInfoAffiliateSuccess(countChild));
      } catch {}
    }

    // level
    req = yield getLevelUser(filter_time.month, filter_time.year);
    if (req.code === 200) {
      try {
        let level = req.data.name;
        yield put(actions.action.getInfoLevelSuccess(level));
      } catch {}
    }

    // commission
    req = yield getTotalCommission(filter_time.month, filter_time.year);
    if (req.code === 200) {
      try {
        let commission = req.data.commission;
        yield put(actions.action.getInfoCommissionSuccess(commission));
      } catch {}
    }

    // point
    req = yield getTotalPoint(filter_time.month, filter_time.year);
    if (req.code === 200) {
      try {
        let points = req.data.points;
        yield put(actions.action.getInfoPointSuccess(points));
      } catch {}
    }
  } catch (ex) {
    console.log(
      "[user_profile_statistical] saga_GetInfoOverview error : ",
      ex.message
    );
  }
}

function* saga_UpdateTimeFilter(action) {
  try {
    let filter_time = action.payload.filter;

    yield put(actions.action.getStatistical({ filter_time }));
    yield put(actions.action.getInfoOverview({ filter_time }));
    yield put(actions.action.getListUserChild({ filter_time }));
    yield put(actions.action.getListDetailRevenue({ filter_time }));
  } catch (ex) {
    console.log(
      "[user_profile_statistical] saga_UpdateTimeFilter error : ",
      ex.message
    );
  }
}

function* saga_GetListUserChild(action) {
  try {
    let params = action.payload.params;

    let _state = yield select((state) => state.user_profile_statistical);
    let pageIndex = _state.user_child_pageIndex;
    let pageSize = _state.user_child_pageSize;
    let filter_time = _state.filter_time;

    if (action.payload.params && action.payload.params.filter_time)
      filter_time = action.payload.params.filter_time;
    filter_time = DateTimeHelperServices.getObjectFromMY(filter_time);

    if (params.pageIndex) pageIndex = params.pageIndex;

    // call api
    let param_query = [
      {
        name: "filters[month]",
        value: filter_time.month,
      },
      {
        name: "filters[year]",
        value: filter_time.year,
      },
    ];
    let req = yield getListUserChild(pageSize, pageIndex, param_query);
    if (req.code === 200) {
      let list_user_child = req.data.items;
      let total = req.data.total;

      yield put(
        actions.action.updateState({
          user_child_total: total,
        })
      );

      yield put(actions.action.getListUserChildSuccess(list_user_child));
    }
  } catch (ex) {
    console.log(
      "[user_profile_statistical] saga_GetListUserChild error : ",
      ex.message
    );
  }
}

function* saga_UpdatePageIndexUserChild(action) {
  try {
    let pageIndex = action.payload.pageIndex;

    yield put(
      actions.action.getListUserChild({
        pageIndex,
      })
    );
  } catch (ex) {
    console.log(
      "[user_profile_statistical] saga_GetListUserChild error : ",
      ex.message
    );
  }
}

function* saga_GetListDetailRevenue(action) {
  try {
    let params = action.payload.params;

    let _state = yield select((state) => state.user_profile_statistical);
    let pageIndex = _state.detail_revenue_pageIndex;
    let pageSize = _state.detail_revenue_pageSize;
    let filter_time = _state.filter_time;

    if (action.payload.params && action.payload.params.filter_time)
      filter_time = action.payload.params.filter_time;
    filter_time = DateTimeHelperServices.getObjectFromMY(filter_time);

    if (params && params.pageIndex) pageIndex = params.pageIndex;

    // call api
    let param_query = [
      {
        name: "filters[month]",
        value: filter_time.month,
      },
      {
        name: "filters[year]",
        value: filter_time.year,
      },
    ];

    let req = yield getListDetailRevenue(pageSize, pageIndex, param_query);
    if (req.code === 200) {
      let list_entity = req.data.items;
      let total = req.data.total;

      yield put(
        actions.action.updateState({
          detail_revenue_total: total,
        })
      );

      yield put(actions.action.getListDetailRevenueSuccess(list_entity));
    }
  } catch (ex) {
    console.log(
      "[user_profile_statistical] saga_GetListDetailRevenue error : ",
      ex.message
    );
  }
}

function* saga_UpdatePageIndexRevenue(action) {
  try {
    let pageIndex = action.payload.pageIndex;

    yield put(
      actions.action.getListDetailRevenue({
        pageIndex,
      })
    );
  } catch (ex) {
    console.log(
      "[user_profile_statistical] saga_UpdatePageIndexRevenue error : ",
      ex.message
    );
  }
}

function* listen() {
  yield takeEvery(actions.type.GET_STATISTICAL, saga_GetStatistical);
  yield takeEvery(actions.type.GET_INFO_OVERVIEW, saga_GetInfoOverview);

  yield takeEvery(actions.type.UPDATE_TIME_FILTER, saga_UpdateTimeFilter);

  yield takeEvery(actions.type.GET_LIST_USER_CHILD, saga_GetListUserChild);
  yield takeEvery(
    actions.type.UPDATE_PAGE_INDEX_USER_CHILD,
    saga_UpdatePageIndexUserChild
  );
  yield takeEvery(
    actions.type.GET_LIST_DETAIL_REVENUE,
    saga_GetListDetailRevenue
  );
  yield takeEvery(
    actions.type.UPDATE_PAGE_INDEX_REVENUE,
    saga_UpdatePageIndexRevenue
  );
}

export default function* mainSaga() {
  yield all([fork(listen)]);
}
