import actions from './actions';

const initialState = {
    isPendingLoadProductFlashSale: false,
    productList: [],
    flashSale: {},
    loadProductFlashSaleError: null
};

const reducer = (state = initialState, action) => {
    const payload = action.payload;
    switch(action.type) {
        case actions.type.LOAD_PRODUCT_FLASH_SALE_REQUEST:
            return {
                ...state,
                isPendingLoadProductFlashSale: true,
            }
        case actions.type.LOAD_PRODUCT_FLASH_SALE_SUCCESS:
            return {
                ...state,
                isPendingLoadProductFlashSale: false,
                loadProductFlashSaleError: null,
                productList: payload.productList,
                flashSale: payload.flashSale
            }
        case actions.type.LOAD_PRODUCT_FLASH_SALE_FAILED:
            return {
                ...state,
                isPendingLoadProductFlashSale: false,
                loadProductFlashSaleError: payload.error
            }
        default:
            return state
    }
}   

export default reducer;