import actions from "./actions";

import DateTimeHelperServices from '../../../services/utils/model_process_helper/date_time_helper'

const initState = {
    statistical_data: [],
    filter_time: DateTimeHelperServices.getCurrentMY(),
    
    revenue_info: 0,
    deposit_info: 0,
    level_info: "",
    affiliate_info: 0,
    commission_info: 0,
    points_info: 0,

    // user_child
    user_childs: [],
    user_child_pageIndex: 1,
    user_child_pageSize: 6,
    user_child_total: 0,

    // list detail revenue 
    list_detail_revenue: [],
    detail_revenue_pageIndex: 1,
    detail_revenue_pageSize: 6,
    detail_revenue_total: 0
};

const reducer = (state = initState, action) => {
    let payload = action.payload;

    switch (action.type) {
        case actions.type.UPDATE_STATE:
            return {
                ...state,
                ...payload.state,
            };

        case actions.type.GET_STATISTICAL:
            return state 
            
        case actions.type.GET_STATISTICAL_SUCCESS:
            return {
                ...state,
                ...{
                    statistical_data: payload.data
                }
            }

        case actions.type.UPDATE_TIME_FILTER:
            return {
                ...state,
                ...{
                    filter_time: payload.filter
                }
            }

        case actions.type.GET_INFO_DEPOSIT_SUCCESS:
            return {
                ...state,
                ...{
                    deposit_info: payload.deposit
                }
            }

        case actions.type.GET_INFO_REVENUE_SUCCESS:
            return {
                ...state,
                ...{
                    revenue_info: payload.revenue
                }
            }

        case actions.type.GET_INFO_LEVEL_SUCCESS:
            return {
                ...state,
                ...{
                    level_info: payload.level 
                }
            }

        case actions.type.GET_INFO_AFFILIATE_SUCCESS:
            return {
                ...state,
                ...{
                    affiliate_info: payload.affiliate
                }
            }

        case actions.type.GET_INFO_COMMISSION_SUCCESS:
            return {
                ...state,
                ...{
                    commission_info: payload.commission
                }
            }

        case actions.type.GET_INFO_POINT_SUCCESS:
            return {
                ...state,
                ...{
                    points_info: payload.point
                }
            }

        // user childs
        case actions.type.GET_LIST_USER_CHILD:
            return state 

        case actions.type.GET_LIST_USER_CHILD_SUCCESS:
            return {
                ...state,
                ...{
                    user_childs: payload.list_user_childs
                }
            }

        case actions.type.UPDATE_PAGE_INDEX_USER_CHILD:
            return {
                ...state,
                ...{
                    user_child_pageIndex: payload.pageIndex
                }
            }

        // detail revenue
        case actions.type.GET_LIST_DETAIL_REVENUE:
            return state 

        case actions.type.GET_LIST_DETAIL_REVENUE_SUCCESS:
            return {
                ...state,
                ...{
                    list_detail_revenue: payload.list_revenue
                }
            }

        case actions.type.UPDATE_PAGE_INDEX_REVENUE:
            return {
                ...state,
                ...{
                    detail_revenue_pageIndex: payload.pageIndex
                }
            }
        
        default:
            return state;
    }
};

export default reducer;
