const type = {
  EXPRESS: "HAN-EXPRESS",
  BIKE: "HAN-BIKE",
  POOL: "HAN-POOL",
  WARE_HOUSE: "WARE_HOUSE",
};

const ListShipping = [
  {
    type: type.WARE_HOUSE,
    name: "Nhận hàng tại kho Cadosa",
  },
  {
    type: type.EXPRESS,
    name: "Giao gần",
  },
  {
    type: type.BIKE,
    name: "Giao siêu tốc",
  },
  {
    type: type.POOL,
    name: "Giao siêu rẻ",
  },
];

export default ListShipping;
