import createApiServices from './createApiServices'
import { addPlugin } from 'store'

const api = createApiServices()

const getListModuleOfSystem = () => {
    return api.makeAuthRequest({
        url: `admin/managerModule`,
        method: 'GET'
    })
}

const getListPermissionOfGroup = (groupId = "") => {
    return api.makeAuthRequest({
        url: `admin/managerPermission/getPermissionsByGroup?groupId=${groupId}`,
        method: 'GET'
    })
}

const getListPermissionOfUser = (userId = "") => {
    return api.makeAuthRequest({
        url: `admin/managerPermission/getPermissionsByUser?userId=${userId}`,
        method: 'GET'
    })
}

const activePermission = (id = "") => {
    return api.makeAuthRequest({
        url: `admin/managerPermission/activePermission/${id}`,
        method: 'PUT'
    })
}

const inActivePermission = (id = "") => {
    return api.makeAuthRequest({
        url: `admin/managerPermission/inActivePermission/${id}`,
        method: 'PUT'
    })
}

export default {
    getListModuleOfSystem,
    getListPermissionOfGroup,
    getListPermissionOfUser,
    activePermission,
    inActivePermission
}