import { all, takeEvery, fork, put, select } from "redux-saga/effects";
import { createNewCategoryPost, getAllCategoryPost, getDetailCategoryPost, getListCategoryPost, removeCategoryPost, updateCategoryPost } from "../../../services/api/category_post";
import NotificationsService from "../../../services/utils/notifycationHelper";
import actions from "./actions";

// validate
import ValidateCategoryPost from '../../../services/utils/validate/validate_category_post';

function* saga_LoadListCategoryPost(action) {
    try {
        let res = yield getListCategoryPost()
        if (res.code === 200) {
            const categoriesPost = res.data

            yield put(actions.action.loadListCategoryPostSuccess(categoriesPost))
        }
    } catch(ex) {
        console.log("[Admin Category Post Saga] saga_LoadListCategoryPost error : ", ex.message);
    }
}

function* saga_LoadDetailCategoryPost(action) {
    try {
        const id = action.payload.id

        let res = yield getDetailCategoryPost(id);
        if (res.code === 200) {
            const categoryPost = res.data
            yield put(actions.action.loadDetailCategoryPostSuccess(categoryPost))
        }
    } catch(ex) {
        console.log("[Admin Category Post Saga] saga_LoadDetailCategoryPost error : ", ex.message);
    }
}

function* saga_LoadAllCategoryPost() {
    try {
        let res = yield getAllCategoryPost()
        if (res.code === 200) {
            const allCategoryPost = res.data

            yield put(actions.action.loadAllCategoryPostSuccess(allCategoryPost))
        }
    } catch(ex) {
        console.log("[Admin Category Post Saga] saga_LoadAllCategoryPost error : ", ex.message);
    }
}

function* saga_SaveCategoryPost() {
    try {
        const _state = yield select(state => state.admin_category_post)
        let categoryPost = {..._state.categoryPost}

        const validateRes = ValidateCategoryPost.validate(categoryPost)
        if (!validateRes.isValidate) {
            NotificationsService.error(validateRes.message)
            return 
        }

        const id = categoryPost._id;

        delete categoryPost._id;

        let res = yield updateCategoryPost(id, categoryPost)
        if (res.code === 200) {
            yield put(actions.action.loadListCategoryPost());
            NotificationsService.success("Cập nhật danh mục bài viết thành công")
        }
    } catch(ex) {
        console.log("[Admin Category Post Saga] saga_SaveCategoryPost error : ", ex.message);
        NotificationsService.error("Cập nhật danh mục bài viết thất bại")
    }
}

function* saga_RemoveCategoryPost() {
    try {
        const _state = yield select(state => state.admin_category_post)
        let categoryPost = _state.categoryPost
        let res = yield removeCategoryPost(categoryPost._id);
        if (res.code === 200) {
            yield put(actions.action.loadListCategoryPost());
            NotificationsService.success("Xoá danh mục bài viết thành công")
        }
    } catch(ex) {
        console.log("[Admin Category Post Saga] saga_RemoveCategoryPost error : ", ex.message);
        NotificationsService.error("Xoá danh mục bài viết thất bại")
    }
}

function* saga_CreateNewCategoryPost() {
    try {
        const _state = yield select(state => state.admin_category_post)
        let newCategoryPost = _state.newCategoryPost
        const validateRes = ValidateCategoryPost.validate(newCategoryPost)
        if (!validateRes.isValidate) {
            NotificationsService.error(validateRes.message)
            return 
        }

        let res = yield createNewCategoryPost(newCategoryPost)
        if (res.code === 200) {
            yield put(actions.action.loadListCategoryPost())
            yield put(actions.action.setModalAddCategoryPostState(false))
            NotificationsService.success("Tạo mới danh mục thành công")
        }
    } catch(ex) {
        console.log("[Admin Category Post Saga] saga_CreateNewCategoryPost error : ", ex.message);
        NotificationsService.error("Tạo mới danh mục thất bại")
    }
}

function* saga_UpdatePageIndex(action) {
    try {
        let pageIndex = action.payload.pageIndex

        yield put(
            actions.action.loadListCategoryPost({
                pageIndex
            })
        )
    }
    catch (ex){
        console.log("[Admin Category Post Saga] saga_UpdatePageIndex error : ", ex.message);
    }
}

function* listen() {
    yield takeEvery(actions.type.LOAD_LIST_CATEGORY_POST, saga_LoadListCategoryPost);
    yield takeEvery(actions.type.LOAD_DETAIL_CATEGORY_POST, saga_LoadDetailCategoryPost);
    yield takeEvery(actions.type.SAVE_CATEGORY_POST, saga_SaveCategoryPost);
    yield takeEvery(actions.type.REMOVE_CATEGORY_POST, saga_RemoveCategoryPost);
    yield takeEvery(actions.type.LOAD_ALL_CATEGORY_POST, saga_LoadAllCategoryPost);
    yield takeEvery(actions.type.CREATE_NEW_CATEGORY_POST, saga_CreateNewCategoryPost);
    yield takeEvery(actions.type.UPDATE_PAGE_INDEX, saga_UpdatePageIndex);
}

export default function* mainSaga() {
    yield all([fork(listen)]);
}