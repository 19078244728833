const validate = (entity) => {
    // function
    const errorResult = (message) => {
        return {
            isValidate: false,
            message,
        };
    };

    // validate
    if (!entity.levelSource || entity.levelSource.trim() === "") {
        return errorResult("Loại cấp bậc thành viên không được để trống")
    }

    if (!entity.levelDestination || entity.levelDestination.trim() === "") {
        return errorResult("Loại cấp bậc thành viên phụ thuộc không được để trống")
    }

    if (!entity.percent) {
        return errorResult("Phần trăm hoa hồng không được để trống")
    }
   
    return {
        isValidate: true,
        message: "success",
    };
};

export default {
    validate,
};
