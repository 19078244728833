import { all, fork, put, select, takeEvery } from "redux-saga/effects";
// api
import {
  createNewCategory,
  getAllCategory,
  getDetailCategory,
  getListCategories,
  removeCategory,
  updateCategory,
} from "../../../services/api/category";
import { uploadImage } from "../../../services/api/upload";
// helper
import NotifycationServices from "../../../services/utils/notifycationHelper";
// validate
import ValidateCategory from "../../../services/utils/validate/validate_category";
import actions from "./actions";

// category
function* saga_LoadListCategory() {
  try {
    let res = yield getListCategories();

    if (res.code === 200) {
      let categories = res.data;

      yield put(actions.action.loadListCategorySuccess(categories));
    }
  } catch (ex) {
    console.log("[Admin Category Saga] loadListCategory error : ", ex.message);
  }
}

// detail category
function* saga_LoadDetailCategory(action) {
  try {
    let id = action.payload.id;

    let res = yield getDetailCategory(id);
    if (res.code === 200) {
      let category = res.data;
      yield put(actions.action.loadDetailCategorySuccess(category));
    }
  } catch (ex) {
    console.log(
      "[Admin Category Saga] saga_LoadDetailCategory error : ",
      ex.message
    );
  }
}

//all categories
function* saga_LoadAllCategory() {
  try {
    let res = yield getAllCategory();
    if (res.code === 200) {
      let allCategory = res.data;

      yield put(actions.action.loadAllCategorySuccess(allCategory));
    }
  } catch (ex) {
    console.log(
      "[Admin Category Saga] saga_LoadAllCategory error : ",
      ex.message
    );
  }
}

function* saga_SaveCurrentCategory() {
  try {
    let _state = yield select((state) => state.admin_category);
    let category = _state.category;

    let validateRes = ValidateCategory.validate(category);
    if (!validateRes.isValidate) {
      NotifycationServices.error(validateRes.message);
      return;
    }

    if (!category.thumbnail && !category.imageLocal) {
      NotifycationServices.error("Vui lòng thêm ảnh cho danh mục");
      return;
    }

    // upload images
    if (category.imageLocal && category.imageLocal.file) {
      let reqq = yield uploadImage(category.imageLocal.file);
      if (reqq.path) category.thumbnail = reqq.path;
      delete category.imageLocal;
    }

    let res = yield updateCategory(category._id, category);
    if (res.code === 200) {
      yield put(actions.action.loadListCategory());

      NotifycationServices.success("Cập nhật danh mục thành công");
    }
  } catch (ex) {
    console.log(
      "[Admin Category Saga] saga_SaveCurrentCategory error : ",
      ex.message
    );
    NotifycationServices.error("Cập nhật danh mục thất bại");
  }
}

function* saga_RemoveCurrentCategory(action) {
  try {
    let id = action.payload.id;
    let res = yield removeCategory(id);
    if (res.code === 200) {
      yield put(actions.action.loadListCategory());

      NotifycationServices.success("Xóa danh mục thành công");
    } else {
      NotifycationServices.error("Xóa danh mục thất bại");
    }
  } catch (ex) {
    console.log(
      "[Admin Category Saga] saga_DeleteCurrentCategory error : ",
      ex.message
    );
    NotifycationServices.error("Xóa danh mục thất bại");
  }
}

function* saga_CreateNewCategory(action) {
  try {
    let _state = yield select((state) => state.admin_category);

    let category = _state.newCategory;
    let validateRes = ValidateCategory.validate(category);
    if (!validateRes.isValidate) {
      NotifycationServices.error(validateRes.message);
      return;
    }

    if (!category.imageLocal) {
      NotifycationServices.error("Vui lòng thêm ảnh cho danh mục");
      return;
    }

    if (category.imageLocal && category.imageLocal.file) {
      let reqq = yield uploadImage(category.imageLocal.file);
      if (reqq.path) category.image = reqq.path;
      delete category.imageLocal;
    }

    let res = yield createNewCategory(category);
    if (res.code === 200) {
      yield put(actions.action.loadListCategory());
      yield put(actions.action.setModalCreateNewCategoryState(false));

      NotifycationServices.success("Thêm danh mục thành công");
    }
  } catch (ex) {
    console.log(
      "[Admin Category Saga] saga_CreateNewCategory error : ",
      ex.message
    );
    if (ex.message) {
      NotifycationServices.error(ex.message.message);
      return;
    }
    NotifycationServices.error("Thêm danh mục thất bại");
  }
}

function* listen() {
  yield takeEvery(actions.type.LOAD_LIST_CATEGORY, saga_LoadListCategory);
  yield takeEvery(actions.type.LOAD_DETAIL_CATEGORY, saga_LoadDetailCategory);
  yield takeEvery(actions.type.LOAD_ALL_CATEGORY, saga_LoadAllCategory);
  yield takeEvery(actions.type.SAVE_CURRENT_CATEGORY, saga_SaveCurrentCategory);
  yield takeEvery(
    actions.type.REMOVE_CURRENT_CATEGORY,
    saga_RemoveCurrentCategory
  );
  yield takeEvery(actions.type.CREATE_NEW_CATEGORY, saga_CreateNewCategory);
}

export default function* mainSaga() {
  yield all([fork(listen)]);
}
