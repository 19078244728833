import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import './index.scss';

import Modal from "../../../../components/modal";
import { FaSave, FaTimes } from 'react-icons/fa';
//redux 
import CategoryPostActions from "../../../redux/admin_category_post/actions";

import { Select } from 'antd';
import Button from '../../../../components/button';

class ModalAddCategoryPost extends Component {

    onChangeCategoryPostCode = (evt) => {
        if (typeof this.props.updateNewCategoryPost === 'function') {
            const categoryPostName = evt.target.value
            this.props.updateNewCategoryPost({ code: categoryPostName })
        }
    }

    onChangeDescription = (evt) => {
        if (typeof this.props.updateNewCategoryPost === 'function') {
            const categoryPostDescription = evt.target.value
            this.props.updateNewCategoryPost({ description: categoryPostDescription })
        }
    }

    onChangeParentCategoryPost = (value) => {
        if (typeof this.props.updateNewCategoryPost === 'function') {
            this.props.updateNewCategoryPost({ parentId: value })
        }
    }

    onChangeCategoryPostStatus = (value) => {
        if (typeof this.props.updateNewCategoryPost === 'function') {
            this.props.updateNewCategoryPost({ status: value })
        }
    }

    onClickCreateCategoryPost = () => {
        if (typeof this.props.createNewCategoryPost === 'function') {
            this.props.createNewCategoryPost()
        }
    }

    render() {
        const { allCategoryPost, newCategoryPost, mdAddCategoryPostState, setModalAddCategoryPostState } = this.props;
        return (
            <Modal
                isOpen={mdAddCategoryPostState}
                displayCloseIcon={false}
                setIsOpen={setModalAddCategoryPostState}
            >

                <div className="AdminAddCategoryPostModal">
                    <div className="AACPM_Label">Thêm mới danh mục bài viết</div>
                    <form>
                        <div>
                            <label className="AACPMF_Label">Mã code</label>
                            <input
                                type="text"
                                className="AACPMF_Value"
                                value={newCategoryPost.name}
                                onChange={this.onChangeCategoryPostCode}
                                placeholder="Nhập mã code"
                            />
                        </div>
                        <div>
                            <label className="AACPMF_Label">Tên danh mục</label>
                            <input
                                className="AACPMF_Value"
                                value={newCategoryPost.description}
                                onChange={this.onChangeDescription}
                                placeholder="Nhập tên danh mục"
                            />
                        </div>
                        <div>
                            <label className="AACPMF_Label">Danh mục cha</label>
                            <Select
                                value={newCategoryPost.parentId}
                                className="AACPMF_Select"
                                onChange={this.onChangeParentCategoryPost}
                                placeholder="Lựa chọn danh mục cha"
                            >
                                <Select.Option
                                    value={null}>Danh mục gốc
                                </Select.Option>
                                {allCategoryPost.map(category => (
                                    <Select.Option
                                        key={category._id}
                                        value={category._id}>{category.description}
                                    </Select.Option>
                                ))}
                            </Select>
                        </div>
                        <div className="d-flex justify-content-center AACPMF_Action">
                            <Button
                                label="Lưu"
                                icon={<FaSave style={{ marginRight: '4px' }} />}
                                className="AACPMF_Button"
                                onClick={this.onClickCreateCategoryPost} />
                            <Button
                                label="Hủy"
                                icon={<FaTimes style={{ marginRight: '4px' }} />}
                                className="AACPMF_Button_Cancel"
                                onClick={() => this.props.setModalAddCategoryPostState(false)}
                            />
                        </div>
                    </form>
                </div>
            </Modal>
        )
    }
}

const mapStatetoProps = (state) => {
    const _state = state.admin_category_post
    return {
        mdAddCategoryPostState: _state.mdAddCategoryPostState,
        newCategoryPost: _state.newCategoryPost,
        allCategoryPost: _state.allCategoryPost
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setModalAddCategoryPostState: (state) => {
            dispatch(CategoryPostActions.action.setModalAddCategoryPostState(state))
        },
        updateNewCategoryPost: (newCategoryPost) => {
            dispatch(CategoryPostActions.action.updateNewCategoryPost(newCategoryPost))
        },
        createNewCategoryPost: () => {
            dispatch(CategoryPostActions.action.createNewCategoryPost())
        }
    }
}

export default connect(mapStatetoProps, mapDispatchToProps)(ModalAddCategoryPost)