import { Button, Checkbox, InputNumber } from "antd";
import React, { Component } from "react";
import { FaFilter, FaSearch } from "react-icons/fa";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import router_links from "../../../../const/router_link";
import { numberDisplayThousand } from "../../../../services/utils/helper";
import ListHelperServices from "../../../../services/utils/listHelper";
import UserFilterProductActions from "../../../redux/user_filter_product/actions";
import "./index.scss";

class FilterPanel extends Component {
  state = {
    moreCategory: false,
    moreUnit: false,
  };

  _renderSearch = () => {
    return (
      <React.Fragment>
        <Button
          type="primary"
          style={{ backgroundColor: "#a04087", borderColor: "#a04087" }}
        >
          Số lượng: {this.props?.totalProduct || 0} sản phẩm
        </Button>

        <div className="UFP_PanelTitle search-header">Tìm kiếm</div>
        <div className="UFP_SearchPanel">
          <input
            type="text"
            className="UFP_SearchBox"
            value={this.props.textSearch}
            onChange={(e) => {
              this.props.updateTextSearch(e.target.value);
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                this.props.searchProduct();
              }
            }}
            placeholder="Nhập từ khoá"
          />
          <FaSearch
            className="UFP_SearchBoxIcon"
            onClick={() => {
              this.props.searchProduct();
            }}
          />
        </div>
      </React.Fragment>
    );
  };

  _renderFilterByCategory = () => {
    let list_categorys = this.props.categorys ? this.props.categorys : [];
    let filter_categorys = this.props.filter_categorys
      ? this.props.filter_categorys
      : [];

    const { moreCategory } = this.state;

    if (!moreCategory) {
      list_categorys = list_categorys.slice(0, 5);
    }

    return (
      <React.Fragment>
        <div className="UFP_PanelTitle" style={{ marginTop: 15 }}>
          Danh mục
        </div>
        <div className="UFP_FilterCategoryPanel">
          {list_categorys.map((category, index) => {
            let value = ListHelperServices.checkItemInArray(
              filter_categorys,
              category
            );

            return (
              <div className="UFP_FilterCategoryItem">
                <Checkbox
                  onChange={(e) => {
                    let checked = e.target.checked;

                    if (checked) this.props.addFilterCategory(category);
                    else this.props.removeFilterCategory(category);

                    this.props.searchProduct();
                    window.scrollTo(0, 0);
                    this.forceUpdate();
                  }}
                  checked={value}
                >
                  <span className="UFP_FilterCategoryItemText">
                    {category.description}
                  </span>
                </Checkbox>
              </div>
            );
          })}

          <Button
            type="primary"
            style={{
              backgroundColor: "#d2e7e6",
              borderColor: "#d2e7e6",
              width: "100%",
            }}
            onClick={() => {
              this.setState({ moreCategory: !moreCategory });
            }}
          >
            <span style={{ color: "#600170", fontWeight: "bold" }}>
              {`${moreCategory ? "Ẩn bớt" : "Xem tất cả"}`}
            </span>
          </Button>
        </div>
      </React.Fragment>
    );
  };

  _renderFilterByPrice = () => {
    const formatter = (value) => {
      let valuez = 1000 * value;
      return numberDisplayThousand(valuez);
    };

    let { lowPrice, highPrice } = this.props;

    return (
      <React.Fragment>
        <div className="UFP_PanelTitle">Giá (VNĐ)</div>
        <div
          className="UFP_FilterByPrice"
          style={{ flexDirection: "row", flex: "float", paddingBottom: 20 }}
        >
          <InputNumber
            value={Number(lowPrice)}
            min={0}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            style={{
              flex: 1,
              backgroundColor: "#f2f2f2",
              borderRadius: 3,
              borderWidth: 0,
              paddingTop: 3,
              paddingBottom: 3,
              marginRight: 10,
            }}
            onChange={(val) => {
              this.props.updateState({
                lowPrice: val,
              });
            }}
            placeholder="Từ"
          />

          <InputNumber
            placeholder="Đến"
            value={Number(highPrice)}
            min={0}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            style={{
              flex: 1,
              backgroundColor: "#f2f2f2",
              borderRadius: 3,
              borderWidth: 0,
              paddingTop: 3,
              paddingBottom: 3,
            }}
            onChange={(val) => {
              this.props.updateState({
                highPrice: val,
              });
            }}
          />
        </div>
      </React.Fragment>
    );
  };

  _renderFilterByUnit = () => {
    let list_unit = this.props.units ? this.props.units : [];
    let filter_units = this.props.filter_units ? this.props.filter_units : [];

    const { moreUnit } = this.state;

    if (!moreUnit) {
      list_unit = list_unit.slice(0, 6);
    }

    return (
      <React.Fragment>
        <div className="UFP_PanelTitle" style={{ marginTop: 15 }}>
          Đơn vị
        </div>

        <div className="UFP_FilterUnitPanel row">
          {list_unit.map((unit, index) => {
            let value = ListHelperServices.checkItemInArray(filter_units, unit);

            return (
              <div className="UFP_FilterUnitItem col-md-6 col-sm-12 col-4">
                <Checkbox
                  onChange={(e) => {
                    let checked = e.target.checked;

                    if (checked) this.props.addFilterUnit(unit);
                    else this.props.removeFilterUnit(unit);

                    this.forceUpdate();
                    window.scrollTo(0, 0);
                    this.props.searchProduct();
                  }}
                  checked={value}
                >
                  {unit.description}
                </Checkbox>
              </div>
            );
          })}

          <Button
            type="primary"
            style={{
              backgroundColor: "#d2e7e6",
              borderColor: "#d2e7e6",
              width: "90%",
              marginLeft: "5%",
            }}
            onClick={() => {
              this.setState({ moreUnit: !moreUnit });
            }}
          >
            <span style={{ color: "#600170", fontWeight: "bold" }}>
              {`${moreUnit ? "Ẩn bớt" : "Xem tất cả"}`}
            </span>
          </Button>
        </div>
      </React.Fragment>
    );
  };

  render() {
    return (
      <div className="UFP_FilterPanel">
        {this._renderSearch()}
        {this._renderFilterByCategory()}
        {this._renderFilterByPrice()}
        {this._renderFilterByUnit()}

        <div
          className="UFP_ButtonFilter"
          onClick={() => {
            window.scrollTo(0, 0);
            this.props.searchProduct();
          }}
        >
          <FaFilter className="UFP_Icon" />
          Lọc
        </div>
        <Button
          type="primary"
          danger
          onClick={() => {
            this.props.history.push(`${router_links.U_FILTER_PRODUCT}`);
            window.location.reload();
          }}
          style={{ width: "100%", height: 38, marginTop: 10 }}
        >
          Bỏ lọc
        </Button>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let _state = state.user_filter_product;

  return {
    textSearch: _state.textSearch,
    categorys: _state.categorys,
    units: _state.units,

    lowPrice: _state.lowPrice,
    highPrice: _state.highPrice,
    filter_categorys: _state.filter_categorys,
    filter_units: _state.filter_units,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateTextSearch: (textSearch) => {
      dispatch(UserFilterProductActions.action.updateTextSearch(textSearch));
    },
    searchProduct: () => {
      dispatch(UserFilterProductActions.action.searchProduct());
    },
    loadListProduct: () => {
      dispatch(UserFilterProductActions.action.loadListProduct());
    },
    updateState: (state) => {
      dispatch(UserFilterProductActions.action.updateState(state));
    },

    // filter
    addFilterCategory: (category) => {
      dispatch(UserFilterProductActions.action.addFilterCategory(category));
    },
    removeFilterCategory: (category) => {
      dispatch(UserFilterProductActions.action.removeFilterCategory(category));
    },
    addFilterUnit: (unit) => {
      dispatch(UserFilterProductActions.action.addFilterUnit(unit));
    },
    removeFilterUnit: (unit) => {
      dispatch(UserFilterProductActions.action.removeFilterUnit(unit));
    },
    filterProduct: () => {
      dispatch(UserFilterProductActions.action.updatePageIndex(1));
      dispatch(
        UserFilterProductActions.action.loadListProduct({
          pageIndex: 1,
        })
      );
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FilterPanel));
