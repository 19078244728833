const prefix = 'admin_manager_list_level_user'

const type = {
    LOAD_LIST_LEVEL_USER: prefix + 'load_list_level_user',
    LOAD_LIST_LEVEL_USER_SUCCESS: prefix + 'load_list_level_user_success',

    REMOVE_LEVEL_USER: prefix + 'remove_level_user',

    // modal add level user
    SET_MODAL_ADD_LEVEL_USER_STATE: prefix + 'set_modal_add_level_user_state',
    UPDATE_NEW_LEVEL_USER: prefix + 'update_new_level_user',
    ADD_NEW_LEVEL_USER: prefix + 'add_new_level_user',

    // modal edit level user
    SET_MODAL_EDIT_LEVEL_USER_STATE: prefix + 'set_modal_edit_level_user_state',
    UPDATE_CURRENT_LEVEL_USER: prefix + 'update_current_level_user',
    SAVE_CURRENT_LEVEL_USER: prefix + 'save_current_level_user',
    LOAD_DETAIL_LEVEL_USER: prefix + 'load_detail_level_user',
    LOAD_DETAIL_LEVEL_USER_SUCCESS: prefix + 'load_detail_level_user_success',
}

const action = {
    loadListLevelUser: (params = {}) => {
        return {
            type: type.LOAD_LIST_LEVEL_USER,
            payload: {
                params
            }
        }
    },
    loadListLevelUserSuccess: (levelUsers = []) => {
        return {
            type: type.LOAD_LIST_LEVEL_USER_SUCCESS,
            payload: {
                levelUsers
            }
        }
    },
    removeLevelUser: (id) => {
        return {
            type: type.REMOVE_LEVEL_USER,
            payload: {
                id
            }
        }
    },
    setModalAddLevelUserState: (state) => {
        return {
            type: type.SET_MODAL_ADD_LEVEL_USER_STATE,
            payload: {
                state
            }
        }
    },
    updateNewLevelUser: (newLevelUser) => {
        return {
            type: type.UPDATE_NEW_LEVEL_USER,
            payload: {
                newLevelUser
            }
        }
    },
    addNewLevelUser: () => {
        return {
            type: type.ADD_NEW_LEVEL_USER,
            payload: {

            }
        }
    },
    setModalEditLevelUserState: (state, id = "") => {
        return {
            type: type.SET_MODAL_EDIT_LEVEL_USER_STATE,
            payload: {
                state,
                id
            }
        }
    },
    updateCurrentLevelUser: (currentLevelUser) => {
        return {
            type: type.UPDATE_CURRENT_LEVEL_USER,
            payload: {
                currentLevelUser
            }
        }
    },
    saveCurrentLevelUser: () => {
        return {
            type: type.SAVE_CURRENT_LEVEL_USER,
            payload: {
                
            }
        }
    },
    loadDetailLevelUser: (id) => {
        return {
            type: type.LOAD_DETAIL_LEVEL_USER,
            payload: {
                id
            }
        }
    },
    loadDetailLevelUserSuccess: (levelUser) => {
        return {
            type: type.LOAD_DETAIL_LEVEL_USER_SUCCESS,
            payload: {
                levelUser
            }
        }
    }
}

export default {
    type,
    action
}