import React, { Component } from "react";

// react-router-dom
import { Link } from "react-router-dom";

// redux
import { connect } from "react-redux";
import actions from "../../../../redux/admin_post/actions";

// ui lib
import { FaPlusCircle, FaSearch, FaFilter } from "react-icons/fa";
import { Select } from "antd";

const { Option } = Select;

class HeaderBar extends Component {
  _handleKeyPress = (event) => {
    if (event.key === "Enter") {
      this.props.searchPost();
    }
  };

  onChangeCurrentCategoryId = (value) => {
    this.props.setCurrentCategoryId(value);
    this.props.loadListPosts();
  };

  render() {
    const {
      category_collections,
      categorys,
      current_category_collection_id,
      current_category_id,
    } = this.props;

    return (
      <div className="HeaderBar">
        <div className="FilterPanel"></div>

        <div className="ControlPanel">
          <Link
            className="Button"
            to="/admin/app/create-post"
            onClick={() => {
              this.props.setDefaultCurrentPost();
            }}
          >
            <FaPlusCircle className="Icon" />
            Thêm bài viết
          </Link>
          <div className="d-flex">
            <div>
              <Select
                className="header-select"
                placeholder="Danh mục bài viết"
                value={current_category_id}
                onChange={this.onChangeCurrentCategoryId}
              >
                <Select.Option value={""}>Tất cả danh mục</Select.Option>
                {categorys.map((category) => (
                  <Select.Option value={category._id}>
                    {category.description}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div className="SearchBox">
              <input
                placeholder="Nhập để tìm kiếm"
                value={this.props.text_search}
                onChange={(e) => {
                  this.props.updateTextSearch(e.target.value);
                }}
                onKeyPress={(e) => this._handleKeyPress(e)}
              />
              <FaSearch
                onClick={() => this.props.searchPost()}
                className="Icon"
              />
            </div>
          </div>
        </div>

        <div className="ControlPanel">
          {/* <div></div> */}
          {/* <div className="ItemPerPage">
                        <Select
                            className="Combobox"
                            onChange={(value) => {
                                this.props.updatePageSize(value)
                            }}
                            value={this.props.pageSize}
                        >
                            <Option value={4}>4 bản ghi trên 1 trang</Option>
                            <Option value={8}>8 bản ghi trên 1 trang</Option>
                            <Option value={12}>12 bản ghi trên 1 trang</Option>
                            <Option value={20}>20 bản ghi trên 1 trang</Option>
                            <Option value={48}>48 bản ghi trên 1 trang</Option>
                        </Select>

                    </div> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let _state = state.admin_post;

  let { text_search } = _state;

  return {
    text_search,
    categorys: _state.categorys,
    current_category_id: _state.current_category_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateTextSearch: (text_search) => {
      dispatch(actions.action.updateTextSearch(text_search));
    },
    searchPost: () => {
      dispatch(actions.action.searchPost());
    },
    setDefaultCurrentPost: () => {
      dispatch(actions.action.setDefaultCurrentPost());
    },
    setCurrentCategoryId: (id) => {
      dispatch(actions.action.setCurrentCategoryId(id));
    },
    loadListPosts: (params) => {
      dispatch(actions.action.loadListPosts(params));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderBar);
