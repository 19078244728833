const prefix = 'user_profile_voucher';

const type = {
    UPDATE_STATE: prefix + 'update_state',
    LOAD_LIST_VOUCHER: prefix + 'load_list_voucher',
    LOAD_LIST_VOUCHER_SUCCESS: prefix + 'load_list_voucher_success',
    UPDATE_PAGE_INDEX: prefix + 'update_page_index',

    LOAD_CURRENT_VOUCHER_APPLY: prefix + 'load_current_voucher_apply',
    LOAD_CURRENT_VOUCHER_APPLY_SUCCESS: prefix + 'load_current_voucher_apply_success',

    UPDATE_TEXT_SEARCH: prefix + 'update_text_search',
    APPLY_VOUCHER: prefix + 'apply_voucher',
}

const action = {
    updateState: (state) => {
        return {
            type: type.UPDATE_STATE,
            payload: {
                state
            }
        }
    },

    loadListVoucher: (params = {}) => {
        return {
            type: type.LOAD_LIST_VOUCHER,
            payload: {
                params
            }
        }
    },

    loadListVoucherSuccess: (vouchers = []) => {
        return {
            type: type.LOAD_LIST_VOUCHER_SUCCESS,
            payload: {
                vouchers
            }
        }
    },
    updatePageIndex: (pageIndex = 1) => {
        return {
            type: type.UPDATE_PAGE_INDEX,
            payload: {
                pageIndex
            }
        }
    },
    loadCurrentVoucherApply: () => {
        return {
            type: type.LOAD_CURRENT_VOUCHER_APPLY,
            payload: {

            }
        }
    },
    loadCurrentVoucherApplySuccess: (currentVoucher) => {
        return {
            type: type.LOAD_CURRENT_VOUCHER_APPLY_SUCCESS,
            payload: {
                currentVoucher
            }
        }
    },
    updateTextSearch: (textSearch = "") => {
        return {
            type: type.UPDATE_TEXT_SEARCH,
            payload: {
                textSearch
            }
        }
    },
    applyVoucher: (id) => {
        return {
            type: type.APPLY_VOUCHER,
            payload: {
                id
            }
        }
    }
}

export default {
    type,
    action
}