import PropTypes from "prop-types";
import React, { Component } from "react";
// icon
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import ReactPaginate from "react-paginate";
// scss
import "./paginations.scss";

class Pagination extends Component {
  _handlePageClick = (data) => {
    const { selected } = data;

    typeof this.props.onChange === "function" &&
      this.props.onChange(selected + 1);
  };

  render() {
    const { pageIndex, total, pageSize } = this.props;

    let number_pages = total / pageSize;
    // if (total % pageSize) number_pages++

    if (number_pages <= 1) return null;

    // console.log("number pages ", number_pages)

    return (
      <div className="Pagination">
        <ReactPaginate
          previousLabel={<FaAngleLeft />}
          nextLabel={<FaAngleRight />}
          pageCount={number_pages}
          forcePage={pageIndex - 1}
          marginPagesDisplayed={1}
          pageRangeDisplayed={3}
          onPageChange={this._handlePageClick}
          containerClassName="pagination"
          pageClassName="page-item"
          previousClassName="Previous page-item"
          nextClassName="Next page-item"
          pageLinkClassName="page-link"
          previousLinkClassName="page-link-control"
          nextLinkClassName="page-link-control"
          subContainerClassName="pages pagination"
          breakClassName="Break page-item"
          breakLabel={<span>...</span>}
          activeClassName="active"
        />
      </div>
    );
  }
}

Pagination.defaultProps = {
  pageIndex: 1,
  pageSize: 10,
  total: 1,
};

Pagination.propTypes = {
  pageIndex: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,

  onChange: PropTypes.func,
};

export default Pagination;
