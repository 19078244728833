const prefix = 'admin_listuser/'

const type = {
    UPDATE_STATE: prefix + 'update_state',

    LOAD_LIST_USER: prefix + 'load_list_user',
    LOAD_LIST_USER_SUCCESS: prefix + 'load_list_user_success',
    SEARCH_USER: prefix + 'search_user',

    UPDATE_PAGE_INDEX: prefix + 'update_page_index',
    UPDATE_PAGE_SIZE: prefix + 'update_page_size',
    UPDATE_TEXT_SEARCH: prefix + 'update_text_search',

    UPDATE_SORT_MODE: prefix + 'update_sort_mode',

    LOAD_USER_DETAIL: prefix + 'load_user_detail',
    LOAD_USER_DETAIL_SUCCESS: prefix + 'load_user_detail_success',

    UPDATE_CURRENT_USER: prefix + 'update_current_user',
    SET_DEFAULT_CURRENT_USER: prefix + 'set_default_current_user',

    SAVE_CURRENT_USER: prefix + 'save_current_user',

    // danh muc
    LOAD_LIST_LEVEL_USER: prefix + 'load_list_level_user',
    LOAD_LIST_LEVEL_USER_SUCCESS: prefix + 'load_list_level_user_success',
    LOAD_LIST_GROUP_USER: prefix + 'load_list_group_user',
    LOAD_LIST_GROUP_USER_SUCCESS: prefix + 'load_list_group_user_success',

    // action user 
    BLOCK_USER: prefix + 'block_user',
    ACTIVE_USER: prefix + 'active_user',

    // groups customer 
    LOAD_LIST_GROUP_CUSTOMERS: prefix + 'load_list_group_customers',
    LOAD_LIST_GROUP_CUSTOMERS_SUCCESS: prefix + 'load_list_group_customers_success',
}

const action = {
    updateState: state => {
        return {
            type: type.UPDATE_STATE,
            payload: {
                state
            }
        }
    },

    loadListUser: (params = {}) => {
        return {
            type: type.LOAD_LIST_USER,
            payload: {
                params
            }
        }
    },
    loadListUserSuccess: (users = []) => {
        return {
            type: type.LOAD_LIST_USER_SUCCESS,
            payload: {
                users
            }
        }
    },

    loadUserDetail: (id) => {
        return {
            type: type.LOAD_USER_DETAIL,
            payload: {
                id
            }
        }
    },
    loadUserDetailSuccess: user => {
        return {
            type: type.LOAD_USER_DETAIL_SUCCESS,
            payload: {
                user
            }
        }
    },

    setDefaultCurrentUser: () => {
        return {
            type: type.SET_DEFAULT_CURRENT_USER,
            payload: {

            }
        }
    },
    saveCurrentUser: () => {
        return {
            type: type.SAVE_CURRENT_USER,
            payload: {

            }
        }
    },
    updateTextSearch: textSearch => {
        return {
            type: type.UPDATE_TEXT_SEARCH,
            payload: {
                textSearch
            }
        }
    },
    searchUser : keyword => {
        return {
            type: type.SEARCH_USER,
            payload: {
                keyword
            }
        }
    },

    updateCurrentUser: user => {
        return {
            type: type.UPDATE_CURRENT_USER,
            payload: {
                user
            }
        }
    },
 
    updatePageIndex: pageIndex => {
        return {
            type: type.UPDATE_PAGE_INDEX,
            payload: {
                pageIndex
            }
        }
    },
    updatePageSize: pageSize => {
        return {
            type: type.UPDATE_PAGE_SIZE,
            payload: {
                pageSize
            }
        }
    },

  
    updateSortMode: mode => {
        return {
            type: type.UPDATE_SORT_MODE,
            payload: {
                mode
            }
        }
    },

    // danh muc 
    loadListLevelUser: () => {
        return {
            type: type.LOAD_LIST_LEVEL_USER,
            payload: {

            }
        }
    },
    loadListLevelUserSuccess: levels => {
        return {
            type: type.LOAD_LIST_LEVEL_USER_SUCCESS,
            payload: {
                levels
            }
        }
    },
    loadListGroupUser: () => {
        return {
            type: type.LOAD_LIST_GROUP_USER,
            pauload: {

            }
        }
    },
    loadListGroupSuccess: (groups) => {
        return {
            type: type.LOAD_LIST_GROUP_USER_SUCCESS,
            payload: {
                groups
            }
        }
    },

    // action 
    blockUser: (id) => {
        return {
            type: type.BLOCK_USER,
            payload: {
                id
            }
        }
    },
    activeUser: id => {
        return {
            type: type.ACTIVE_USER,
            payload: {
                id
            }
        }
    },
    loadListGroupCustomers: () => {
        return {
            type: type.LOAD_LIST_GROUP_CUSTOMERS,
            payload: {

            }
        }
    },
    loadListGroupCustomersSuccess: (groupCustomers = []) => {
        return {
            type: type.LOAD_LIST_GROUP_CUSTOMERS_SUCCESS,
            payload: {
                groupCustomers
            }
        }
    }
}

export default {
    type,
    action,
}
