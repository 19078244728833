import React, { Component } from 'react';
// scss
import './button.scss';

class Button extends Component {
    onClickButton = () => {
        if (typeof this.props.onClick === 'function') {
            this.props.onClick();
        }
    }

    render() {
        const { className, icon, label } = this.props;
        let classes = ['Button btn-custom'];
        if (className) {
            classes.push(className);
        }

        return (
            <div
                className={classes.join(' ')}
                onClick={this.onClickButton}
            >
                {icon && icon}
                {label}
            </div>
        )
    }
}

export default Button;