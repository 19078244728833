import React from 'react'
import { connect } from 'react-redux'
import './index.scss'
import Modal from "../../../../components/modal";
import Button from '../../../../components/button';
import AdminGroupUserActions from '../../../redux/admin_group_user/actions'
import NotificationsService from '../../../../services/utils/notifycationHelper';


class ModalAddUserGroup extends React.Component {

    onClickButtonCancel = () => {
        this.props.setModalUserGroupState(false)
    }

    onChangeName = (evt) => {
        const name = evt.target.value;
        if (typeof this.props.updateNewGroup === 'function') {
            this.props.updateNewGroup({ name })
        }
    }

    onChangeDescription = (evt) => {
        const description = evt.target.value;
        if (typeof this.props.updateNewGroup === 'function') {
            this.props.updateNewGroup({ description })
        }
    }

    validateNewGroup = () => {
        const { newGroup } = this.props;
        if (newGroup.name === '' || newGroup.name.trim() === '') {
            NotificationsService.error("Tên nhóm người dùng không được để trống")
            return false
        }
        if (newGroup.description === '' || newGroup.description.trim() === '') {
            NotificationsService.error("Mô tả nhóm người dùng không được để trống")
            return false
        }

        return true;
    }

    onSubmitCreateNewGroup = () => {
        const isValid = this.validateNewGroup();
        if (isValid && typeof this.props.createNewGroup === 'function') {
            this.props.createNewGroup()
        }
    }

    render() {
        const { mdAddUserGroupState, newGroup } = this.props;
        return (
            <Modal
                isOpen={mdAddUserGroupState}
                displayCloseIcon={false}
                setIsOpen={this.props.setModalUserGroupState}
            >

                <div className='Admin_ModalAddGroupUser'>
                    <div className="user-group-add-header">Thêm mới nhóm người dùng</div>

                    <div className="AMAG_RowInfo">
                        <div className="AMAG_RowInfo_Title">
                            Tên
                    </div>
                        <div className="AMAG_RowInfo_Content">
                            <input
                                className="AMAG_RowInfo_Input"
                                value={newGroup.name}
                                onChange={this.onChangeName}
                            />
                        </div>
                    </div>
                    <div className="AMAG_RowInfo">
                        <div className="AMAG_RowInfo_Title">
                            Mô tả
                    </div>
                        <div className="AMAG_RowInfo_Content">
                            <textarea
                                style={{
                                    minHeight: 60
                                }}
                                className="AMAG_RowInfo_Input"
                                value={newGroup.description}
                                onChange={this.onChangeDescription}
                            />
                        </div>
                    </div>

                    <div className="d-flex justify-content-center user-group-add-action">
                        <Button
                            className="btn-add"
                            label="Thêm mới"
                            onClick={this.onSubmitCreateNewGroup}
                        />
                        <Button
                            className="btn-cancel"
                            label="Hủy"
                            onClick={this.onClickButtonCancel}
                        />
                    </div>
                </div>
            </Modal >
        )
    }
}

const mapStateToProps = (state) => {
    const _state = state.admin_group_user
    return {
        mdAddUserGroupState: _state.mdAddUserGroupState,
        newGroup: _state.newGroup
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setModalUserGroupState: (state) => {
            dispatch(AdminGroupUserActions.action.setModalUserGroupState(state))
        },
        updateNewGroup: (newGroup) => {
            dispatch(AdminGroupUserActions.action.updateNewGroup(newGroup))
        },
        createNewGroup: () => {
            dispatch(AdminGroupUserActions.action.createNewGroup())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalAddUserGroup)