import "antd/dist/antd.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom";
// import 'antd/dist/antd.css';
import "react-notifications-component/dist/theme.css";
import "react-quill/dist/quill.snow.css";
// import './assets/js/main'
import "../node_modules/react-vis/dist/style.css";
import App from "./App/index";
// theme
// import './assets/css/bootstrap.min.css'
import "./resource/css/style.css";
// import './loader.js'
import "./resource/js/main_init";
import "./resource/scss/dropdown.scss";
import "./resource/scss/notify.scss";
// custom css
import "./resource/scss/table.scss";
import "./resource/vendor/fontawesome-free/css/all.min.css";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
