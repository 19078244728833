import React from "react";
import { connect } from "react-redux";

class AdminCheckPermission extends React.PureComponent {
  getStatusAction = () => {
    let isShow = true;

    const { groupPermissions, paramsPermission } = this.props;

    if (
      paramsPermission &&
      paramsPermission.module &&
      paramsPermission.action
    ) {
      isShow = false;

      const per = groupPermissions.find(
        (e) => e.moduleCode === paramsPermission.module
      );

      if (per && per.permissions) {
        const action = per.permissions.find(
          (e) => e.action === paramsPermission.action
        );

        if (action && action.isAllow) isShow = true;
      }
    }

    return isShow;
  };

  render() {
    if (!this.getStatusAction()) {
      if (this.props.noContent) return null;
      return <div>Bạn không có quyền truy cập trang này</div>;
    }

    return this.props.children;
  }
}

const mapStateToProps = (state) => {
  return {
    groupPermissions: state.auth.groupPermissions,
  };
};

const AdminCheckPermissionComponent =
  connect(mapStateToProps)(AdminCheckPermission);

export default AdminCheckPermissionComponent;
