import { Select, Tabs } from "antd";
import React, { Component } from "react";
import { FaCheck, FaHandHoldingUsd } from "react-icons/fa";
// redux
import { connect } from "react-redux";
// ui component
import Modal from "../../../../../components/modal";
// api
import { despositOnline } from "../../../../../services/api/user";
// helper
import { numberDisplayThousand } from "../../../../../services/utils/helper";
import DateTimeHelperServices from "../../../../../services/utils/model_process_helper/date_time_helper";
import NotificationsService from "../../../../../services/utils/notifycationHelper";
import StatisticalActions from "../../../../redux/user_profile_statistical/actions";
// scss
import "./index.scss";
import ListRevenues from "./list_revenues";
import ListUsers from "./list_users";
// sub component
import RevenueChart from "./revenue_chart";

const { TabPane } = Tabs;

class UserRevenuePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalDepositOnlineState: false,
      deposit: 100000,
    };
  }

  // life cycle
  componentDidMount = () => {
    this.props.init();
  };

  // handle
  handleDepositOnline = async () => {
    let deposit = this.state.deposit;

    if (deposit <= 0) {
      NotificationsService.error("Số tiền kí quỹ phải là số dương");
      return;
    }

    // call api
    try {
      let req = await despositOnline(deposit);
      if (req.code === 200) {
        NotificationsService.success("Kí quỹ thành công");
        this.setState({
          modalDepositOnlineState: false,
          deposit: 100000,
        });

        let direct_url = req.data;
        window.location = direct_url;
      } else NotificationsService.error("Kí quỹ thất bại");
    } catch (ex) {}
  };

  // render
  renderModalDepositOnline = () => {
    return (
      <Modal
        isOpen={this.state.modalDepositOnlineState}
        setIsOpen={(value) => {
          this.setState({
            modalDepositOnlineState: value,
          });
        }}
        displayCloseIcon={true}
      >
        <div className="ModalDepositeOnline">
          <div className="ModalTitle">KÍ QUỸ ONLINE</div>
          <div className="RowInfo">
            <div className="RowTitle">Số tiền kí quỹ</div>
            <div className="RowContent">
              <input
                className="RowInput"
                type="number"
                value={this.state.deposit}
                onChange={(e) => {
                  this.setState({
                    deposit: e.target.value,
                  });
                }}
                step={100000}
              />
            </div>
          </div>

          <div className="ActionInfo">
            <div
              className="btnDeposit"
              onClick={() => {
                this.handleDepositOnline();
              }}
            >
              <FaCheck className="Icon" />
              Xác nhận kí quỹ
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  render() {
    let {
      revenue_info,
      deposit_info,
      level_info,
      affiliate_info,
      filter_time,
      commission_info,
      points_info,
    } = this.props;

    let months = DateTimeHelperServices.getOnYearAgoMY();
    let userInfo = this.props.userInfo
      ? this.props.userInfo
      : {
          cadoxu: 0,
        };

    return (
      <div className="UserRevenuePage">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="URP_Title">Doanh thu</div>
          <div
            className="URP_ButtonDeposit"
            onClick={() => {
              this.setState({ modalDepositOnlineState: true });
            }}
          >
            <FaHandHoldingUsd className="Icon" />
            Ký quỹ online
          </div>
        </div>

        <RevenueChart />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div className="URP_Title">Bảng chi tiết doanh thu và lợi tức</div>
          <div>
            <Select
              style={{ minWidth: 130 }}
              value={filter_time}
              onChange={(value) => {
                this.props.updateTimeFilter(value);
              }}
            >
              {months.map((month, index) => {
                return (
                  <Select.Option value={month}>Tháng {month}</Select.Option>
                );
              })}
            </Select>
          </div>
        </div>

        <div className="URP_BlockRevenuePanel row">
          <div
            className="col-md-4"
            style={{
              display: "flex",
              flexDirection: "row",
              padding: 0,
            }}
          >
            <div
              className="URP_BlockRevenue"
              style={{ color: "green !important" }}
            >
              <div className="URP_STitle">Doanh thu</div>
              <div className="URP_Subtile">
                {numberDisplayThousand(revenue_info)}
              </div>
            </div>
          </div>
          <div
            className="col-md-4"
            style={{
              display: "flex",
              flexDirection: "row",
              padding: 0,
            }}
          >
            <div className="URP_BlockRevenue">
              <div className="URP_STitle">Ký quỹ</div>
              <div className="URP_Subtile">
                {numberDisplayThousand(deposit_info)}
              </div>
            </div>
          </div>
          <div
            className="col-md-4"
            style={{
              display: "flex",
              flexDirection: "row",
              padding: 0,
            }}
          >
            <div
              className="URP_BlockRevenue"
              style={{ color: "green !important" }}
            >
              <div className="URP_STitle">Hoa hồng</div>
              <div className="URP_Subtile">
                {numberDisplayThousand(commission_info)}
              </div>
            </div>
          </div>
          <div
            className="col-md-4"
            style={{
              display: "flex",
              flexDirection: "row",
              padding: 0,
            }}
          >
            <div
              className="URP_BlockRevenue"
              style={{ color: "green !important" }}
            >
              <div className="URP_STitle">Điểm thưởng</div>
              <div className="URP_Subtile">
                {numberDisplayThousand(points_info)}
              </div>
            </div>
          </div>
          <div
            className="col-md-4"
            style={{
              display: "flex",
              flexDirection: "row",
              padding: 0,
            }}
          >
            <div className="URP_BlockRevenue">
              <div className="URP_STitle">Thành viên phụ thuộc</div>
              <div className="URP_Subtile">{`${numberDisplayThousand(
                affiliate_info
              )} thành viên`}</div>
            </div>
          </div>
          <div
            className="col-md-4"
            style={{
              display: "flex",
              flexDirection: "row",
              padding: 0,
            }}
          >
            <div className="URP_BlockRevenue">
              <div className="URP_STitle">Bậc thành viên</div>
              <div className="URP_Subtile">{level_info}</div>
            </div>
          </div>

          <div
            className="col-md-4"
            style={{
              display: "flex",
              flexDirection: "row",
              padding: 0,
            }}
          >
            <div className="URP_BlockRevenue">
              <div className="URP_STitle">Ví cadoxu</div>
              <div className="URP_Subtile">
                {numberDisplayThousand(userInfo.cadoxu)}
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            backgroundColor: "white",
            marginTop: 15,
            minHeight: 380,
          }}
        >
          <Tabs defaultActiveKey="1" tabBarStyle={{ marginLeft: 15 }}>
            <TabPane tab={<span>Thành viên</span>} key="1">
              <ListUsers />
            </TabPane>
            <TabPane tab={<span>Doanh thu</span>} key="2">
              <ListRevenues />
            </TabPane>
            {/* <TabPane tab={<span>Kí quỹ</span>} key="2">
                            <ListBalances />
                        </TabPane> */}
          </Tabs>
        </div>

        {/* modal */}
        {this.renderModalDepositOnline()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let _state = state.user_profile_statistical;

  return {
    revenue_info: _state.revenue_info,
    deposit_info: _state.deposit_info,
    level_info: _state.level_info,
    affiliate_info: _state.affiliate_info,
    commission_info: _state.commission_info,
    points_info: _state.points_info,

    filter_time: _state.filter_time,
    userInfo: state.auth.userInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    init: () => {
      dispatch(StatisticalActions.action.getStatistical());
      dispatch(StatisticalActions.action.getInfoOverview());
      dispatch(StatisticalActions.action.getListDetailRevenue());
      dispatch(StatisticalActions.action.getListUserChild());
    },
    updateTimeFilter: (filter_time) => {
      dispatch(StatisticalActions.action.updateTimeFilter(filter_time));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserRevenuePage);
