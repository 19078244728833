const validate = (entity) => {
    // function
    const errorResult = (message) => {
        return {
            isValidate: false,
            message,
        };
    };

    // validate
    if (entity.code === null || entity.code.trim() === "")
        return errorResult("Mã không được để trống");
    
    if (entity.code.length < 3) {
        return errorResult("Mã khuyến mại có ít nhất 3 ký tự");
    }

    if (entity.description === null || entity.description.trim() === "")
        return errorResult("Mô tả không được để trống");

    if (entity.discount === null || entity.discount === "") {
        return errorResult("Giảm giá không được để trống");
    }
    
    if (entity.maximumCoins === null || entity.maximumCoins === "") {
        return errorResult("Số coin tối đa không được để trống");
    }

    if (entity.groupCustomerId === null || entity.groupCustomerId === "") {
        return errorResult("Nhóm khách hàng không được để trống");
    }
    
    return {
        isValidate: true,
        message: "success",
    };
};

export default {
    validate,
};
