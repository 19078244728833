import React, { Component } from "react";

// redux
import { connect } from "react-redux";
import { history } from '../../../../redux/store'

// scss
import "../index.scss";

// router
import { Link, Redirect, withRouter } from "react-router-dom";
import Router_Links from "../../../../../const/router_link";
import NotificationsService from "../../../../../services/utils/notifycationHelper";

// api 
import { confirmResetPassword } from '../../../../../services/api/auth'

// query
import queryString from "query-string";

class ConfirmResetPassword extends Component {

    constructor(props){
        super(props)
        this.state = {
            password: "",
            confirmPassword: ""
        }
    }

    token = ""

    // handle 
    _handleResetPassword = async() => {
        let password = this.state.password 
        let confirmPassword = this.state.confirmPassword 

        // validate 
        if (password === ""){
            NotificationsService.error("Mật khẩu mới không được để rỗng")
            return 
        }

        if (confirmPassword !== password){
            NotificationsService.error("Xác nhận mật khẩu không chính xác")
            return 
        }

        // gọi api
        try {
            // NotificationsService.success(this.token)
            let req = await confirmResetPassword(password, this.token)
            if (req.code === 200){
                NotificationsService.success("Cập nhật mật khẩu thành công")
                history.push(Router_Links.U_AUTH_LOGIN)
            }
            else 
                NotificationsService.error("Cập nhật mật khẩu thất bại")
        }
        catch (ex){
            NotificationsService.error("Thay đổi mật khẩu thất bại")
        }
    }

    // lifecycle 
    componentDidMount = () => {
        let params = queryString.parse(this.props.location.search);
        this.token = params.token ? params.token : "";

        if (this.token === ""){
            history.push(Router_Links.U_HOME_PAGE)
        }
    }

    // render
    render() {
        let from = null;
        if (this.props.isLoggedIn) {
            if (from) return <Redirect to={from} />;

            return <Redirect to={Router_Links.U_PROFILE_INFO} />;
        }

        return (
            <div className="UserView_ForgotPassword">
                <div className="UserView_ForgotPasswordPanel">
                    <div className="UFG_InfoPanel">
                        <div className="UFIF_Title">Cập nhật mật khẩu</div>

                        <div className="UFIF_RowTitle">Mật khẩu mới</div>
                        <input
                            type="password"
                            placeholder="Nhập mật khẩu mới"
                            className="UFIF_EmailInput"
                            value={this.state.password}
                            onChange={(e) => {
                                this.setState({
                                    password: e.target.value
                                })
                            }}
                        />

                        <div className="UFIF_RowTitle">
                            Xác nhận mật khẩu
                        </div>
                        <input
                            type="password"
                            placeholder="Xác nhận mật khẩu mới"
                            className="UFIF_EmailInput"
                            value={this.state.confirmPassword}
                            onChange={(e) => {
                                this.setState({
                                    confirmPassword: e.target.value
                                })
                            }}
                        />

                        <div 
                            className="UFIF_BtnResetPassword"
                            onClick={() => {
                                this._handleResetPassword()
                            }}
                        >
                            Cập nhật mật khẩu
                        </div>
                    </div>
                    <div className="UFG_ImagePanel">
                        <img
                            className="UFGI_Image"
                            src="https://static.vecteezy.com/system/resources/previews/000/662/235/non_2x/vector-team-work-people-in-factory-scene.jpg"
                            // {require("../../../../resource/images/bg.jpg")}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.auth.isLoggedIn,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withRouter(ConfirmResetPassword)
);
