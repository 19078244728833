/* eslint-disable jsx-a11y/alt-text */
import { Button, Col, Input, List, Row, Select, Spin } from "antd";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import colors from "../../../../const/colors";
import router_links from "../../../../const/router_link";
import { isEmpty } from "../../../../helpers/text.helper";
import { getImageUrl } from "../../../../services/api/upload";
import NotificationsService from "../../../../services/utils/notifycationHelper";
import {
  getTypeWareHouseStatus,
  WareHouseStatus,
  WareHouseStatusArr,
} from "../../../../types/warehouse.type";
import AdminCreateOrderActions from "../../../redux/admin_create_order/actions";
import { history } from "../../../redux/store";
import ModalSelectUser from "../list_order/create_order_page/modal_select_user";
import WareHouseInsertPrice from "../warehouse-insert/components/warehouse-insert-price";
import PopupHistoryInsertProduct from "./components/popup-history-insert-product";
import WareHouseCreateAddress from "./components/warehouse-create-address";
import WareHouseCreateItem from "./components/warehouse-create-item";
import "./index.scss";
import {
  apiCreateWareHouse,
  apiGetDetailWareHouse,
  apiUpdateWareHouse,
} from "./service/warehouse-create.sevice";

const ITEM_LOAD = 100;

class WareHouesCreateScreen extends PureComponent {
  statusWareHouse = WareHouseStatus.CONFIRMED;

  addressParams = {
    provinceCode: undefined,
    districtCode: undefined,
    wardCode: undefined,
  };

  constructor(props) {
    super(props);
    this.state = {
      user: undefined,
      products: [],
      productIds: [],
      loading: false,
      page: 1,
      loadingPage: true,
      wareHouse: {},
    };
    this.wareHouseId =
      (props.location.search && props.location.search.replace("?", "")) ||
      undefined;
  }

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    if (!this.wareHouseId) {
      this.setState({ loadingPage: false });
      return;
    }
    const wareHouse = await apiGetDetailWareHouse(this.wareHouseId, {
      getListProduct: true,
    });

    if (!wareHouse) {
      this.setState({ loadingPage: false });
      return;
    }

    let { productIds, products, user } = this.state;

    productIds = wareHouse.productIds || [];
    user = wareHouse.userInfo || undefined;
    products = wareHouse.products || [];
    this.statusWareHouse = wareHouse.status || this.statusWareHouse;
    this.totalProduct = wareHouse.totalProduct || 0;

    this.addressParams.provinceCode = wareHouse.provinceCode || undefined;
    this.addressParams.districtCode = wareHouse.districtCode || undefined;
    this.addressParams.wardCode = wareHouse.wardCode || undefined;

    this.setState({
      wareHouse,
      productIds,
      user,
      products,
      loadingPage: false,
      loading: false,
      page: 1,
    });
  };

  onSave = async () => {
    const { user, wareHouse } = this.state;

    if (!user || !user._id) {
      NotificationsService.error("Vui lòng chọn chủ kho");
      return;
    }

    const { districtCode, provinceCode, wardCode } = this.addressParams;

    const addressDetail = this.refAddressDetail.state.value;

    if (!provinceCode || !districtCode || !wardCode || isEmpty(addressDetail)) {
      NotificationsService.error(
        "Vui lòng chọn/nhập đầy đủ địa chỉ của kho hàng"
      );
      return;
    }

    const description = this.refDescription.state.value;

    if (isEmpty(description)) {
      NotificationsService.error("Mô tả không được để trống");
      return;
    }

    const params = {
      userId: (user && user._id) || undefined,
      description,
      status: this.statusWareHouse,
      provinceCode,
      districtCode,
      wardCode,
      addressDetail,
    };

    if (wareHouse && wareHouse._id) {
      const res = await apiUpdateWareHouse(wareHouse._id, params);
      if (res._id) {
        this.props.history.goBack();
        NotificationsService.success("Cập nhập kho hàng thành công");
        return;
      }

      NotificationsService.error("Cập nhập kho hàng thất bại");
      return;
    }

    const res = await apiCreateWareHouse(params);

    if (res._id) {
      this.props.history.goBack();
      NotificationsService.success("Tạo kho hàng thành công");
      return;
    }

    NotificationsService.error("Tạo kho hàng thất bại");
  };

  getTotalPriceProducts() {
    let priceProduct = 0;
    let priceWholesale = 0;
    let priceDiscount = 0;

    const { wareHouse } = this.state;

    if (wareHouse && wareHouse._id) {
      priceProduct = wareHouse.priceProduct || 0;
      priceWholesale = wareHouse.priceWholesale || 0;
      priceDiscount = priceProduct - priceWholesale;
    }

    return {
      priceProduct,
      priceWholesale,
      priceDiscount,
    };
  }

  render() {
    const { user, products, page, loadingPage, loading, wareHouse } =
      this.state;

    if (loadingPage) {
      return <Spin size="large" />;
    }

    return (
      <div className="warehouse-create">
        <div className="viewHeader">
          <p className="nameHeader">
            {this.wareHouseId ? "Cập nhật kho hàng" : "Tạo kho hàng"}
          </p>

          <Button
            type="primary"
            style={{
              backgroundColor: colors.primaryColor,
              borderColor: colors.primaryColor,
              fontWeight: "600",
            }}
            onClick={this.onSave}
          >
            Lưu
          </Button>
        </div>
        <Row>
          <Col span={11}>
            <p className="nameInput" style={{ marginTop: -5 }}>
              Chủ kho hàng
            </p>

            <div className="headerUser">
              {user ? (
                <div className="MSU_User_Row">
                  <div className="MSU_ImageView">
                    <img
                      src={getImageUrl(user.avatar)}
                      className="MSU_UserAvatar"
                    />
                  </div>
                  <div style={{ marginLeft: 10 }}>
                    <div className="MSU_UserName">{user.fullName}</div>
                    <div>{user.phone}</div>
                    {user.email ? <div>{user.email}</div> : null}
                  </div>
                </div>
              ) : null}
              {!this.wareHouseId ? (
                <Button
                  type="primary"
                  onClick={() => {
                    this.props.setModalSelectUserState(true);
                  }}
                >
                  Chọn
                </Button>
              ) : null}
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div style={{ flex: 1 }}>
                <p className="nameInput">Mã kho hàng</p>
                <Input
                  allowClear
                  placeholder="Mã kho hàng"
                  disabled
                  defaultValue={wareHouse.code || ""}
                />
              </div>

              <div style={{ flex: 1, marginLeft: 10 }}>
                <p className="nameInput">Trạng thái kho hàng</p>
                <Select
                  placeholder="Trạng kho hàng"
                  style={{ width: "100%" }}
                  defaultValue={this.statusWareHouse}
                  onChange={(e) => {
                    this.statusWareHouse = e;
                  }}
                >
                  {WareHouseStatusArr.map((e) => (
                    <Select.Option value={e}>
                      {getTypeWareHouseStatus(e).name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>

            <p className="nameInput">Mô tả kho hàng</p>
            <Input
              allowClear
              placeholder="Mô tả kho hàng"
              ref={(r) => (this.refDescription = r)}
              defaultValue={wareHouse.description || ""}
            />

            <WareHouseCreateAddress
              valueDefault={this.addressParams}
              onUpdateAddress={(e) => {
                this.addressParams = e;
              }}
            />

            <p className="nameInput">Địa chỉ cụ thể kho hàng</p>
            <Input
              allowClear
              placeholder="Số nhà/ngách/ngõ"
              ref={(r) => (this.refAddressDetail = r)}
              defaultValue={wareHouse.addressDetail || ""}
            />
          </Col>

          <Col span={1} />
          <Col span={12}>
            <div
              style={{ flexDirection: "column", float: "right", marginTop: 15 }}
            >
              <WareHouseInsertPrice
                title="Giá bán"
                price={this.getTotalPriceProducts().priceProduct}
                color="#a04087"
              />

              <WareHouseInsertPrice
                title="Chiết khấu"
                price={this.getTotalPriceProducts().priceDiscount}
                color="#8873bb"
              />

              <WareHouseInsertPrice
                title="Giá nhập kho"
                price={this.getTotalPriceProducts().priceWholesale}
                color="orange"
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <p className="nameInput">Sản phẩm trong kho hàng</p>
            <div style={{ flexDirection: "row" }}>
              <Button
                type="primary"
                style={{ backgroundColor: "#a04087", borderColor: "#a04087" }}
              >
                {this.totalProduct || 0} sản phẩm
              </Button>
              {this.wareHouseId ? (
                <>
                  <Button
                    className="ml-2"
                    type="primary"
                    onClick={() => {
                      history.push(
                        `${router_links.A_WARE_HOUSE_INSERT_PRODUCT}?wareHouseId=${this.wareHouseId}`
                      );
                    }}
                  >
                    Cập nhật sản phẩm vào kho hàng
                  </Button>

                  <Button
                    className="ml-2"
                    type="primary"
                    style={{
                      backgroundColor: "orange",
                      borderColor: "orange",
                    }}
                    onClick={() => {
                      this.refPopupHistoryInsertProduct.open(this.wareHouseId);
                    }}
                  >
                    Lịch sử cập nhật
                  </Button>
                </>
              ) : null}
            </div>

            {products.length > 0 ? (
              <div>
                <List
                  grid={{ gutter: 16, column: 2 }}
                  size="large"
                  loading={loading}
                  // pagination={{
                  //   onChange: this.onChangePage,
                  //   current: page,
                  //   pageSize: ITEM_LOAD,
                  //   total: this.totalProduct,
                  // }}
                  style={{
                    flexDirection: "row",
                  }}
                  dataSource={products}
                  renderItem={(item, i) => {
                    return (
                      <Col span={22}>
                        <WareHouseCreateItem item={item} />
                      </Col>
                    );
                  }}
                />
              </div>
            ) : null}
          </Col>
        </Row>

        <ModalSelectUser
          onSelectUser={(userInfo) => {
            this.setState({ user: userInfo });
          }}
        />

        <PopupHistoryInsertProduct
          ref={(r) => (this.refPopupHistoryInsertProduct = r)}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setModalSelectUserState: (state) => {
      dispatch(AdminCreateOrderActions.action.loadListUser());
      dispatch(AdminCreateOrderActions.action.setModalSelectUserState(state));
    },
  };
};

export default connect(null, mapDispatchToProps)(WareHouesCreateScreen);
