import createApiServices from './createApiServices'

const api = createApiServices()

export const getListProvines = () => {
    return api.makeAuthRequest({
        url: 'administrativeUnits/getProvinces',
        method: 'GET'
    }) 
}

export const getListDistrictByProvine = id => {
    return api.makeAuthRequest({
        url: `administrativeUnits/getDistrictsByProvince/${id}`,
        method: 'GET'
    }) 
}

export const getListWardByDistrict = id => {
    return api.makeAuthRequest({
        url: `administrativeUnits/getWardsByDistrict/${id}`,
        method: 'GET'
    }) 
}