const validate = (entity) => {
    // function
    const errorResult = (message) => {
        return {
            isValidate: false,
            message,
        };
    };

    // validate
    if (!entity.categoryId)
        return errorResult("Danh mục bài viết không được để trống")

    if (!entity.title || entity.title.trim() === "")
        return errorResult("Tiêu đề bài viết không được để trống")

    if (!entity.description || entity.description.trim() === "")
        return errorResult("Mô tả bài viết không được trống")

    if (!entity.thumbnailUrl || entity.thumbnailUrl.trim() === "")
        return errorResult("Ảnh đại diện bài viết không được để trống")

    if (!entity.content || entity.content.trim() === "")
        return errorResult("Nội dung bài viết không được để trống")

    return {
        isValidate: true,
        message: "success",
    };
};

export default {
    validate,
};
