import { testModeAPI } from 'react-ga'
import actions from './actions'

const default_post = {
    _id: "",
    title: "",
    description: "",
    categoryName: "",
    userCreated: "",
    thumbnailUrl: "",
    status: false,
    content: "",
    categoryId: null,
    userId: "",
    createdAt: "",

    thumbnail_file: null,
    tags: []
}

const initState = {
    posts: [],
    is_loading_list_post: false,

    categorys: [],

    total: 0,
    pageSize: 12,
    pageIndex: 1,

    text_search: "",
    last_search: "",

    // detail post 
    detail_post: default_post,
    is_loading_detail: false,
    current_category_id: "",

    // 
    postsByTags: [],
    pageIndexPostsByTags: 1,
    pageSizePostsByTags: 12,
    totalPostsByTags: 0,
    currentTags: ''
}

const reducer = (state = initState, action) => {
    let payload = action.payload

    switch (action.type) {
        case actions.type.UPDATE_STATE:
            return {
                ...state,
                ...payload.state
            }

        case actions.type.LOAD_LIST_CATEGORY_POST:
            return state 

        case actions.type.LOAD_LIST_CATEGORY_POST_SUCCESS:
            return {
                ...state,
                ...{
                    categorys: payload.categorys
                }
            }

        // list posts 
        case actions.type.LOAD_LIST_POSTS:
            return state

        case actions.type.LOAD_LIST_POSTS_SUCCESS:
            return {
                ...state,
                ...{
                    posts: payload.posts
                }
            }

        case actions.type.UPDATE_TEXT_SEARCH:
            return {
                ...state,
                ...{
                    text_search: payload.textSearch
                }
            }

        case actions.type.SEARCH_POST:
            return {
                ...state,
                ...{
                    last_search: state.text_search,
                    pageIndex: 1
                }
            }

        // detail post 
        case actions.type.LOAD_DETAIL_POST:
            return state

        case actions.type.LOAD_DETAIL_POST_SUCCESS:
            return {
                ...state,
                ...{
                    detail_post: payload.post
                }
            }

        case actions.type.SET_DEFAULT_CURRENT_POST:
            return {
                ...state,
                ...{
                    detail_post: default_post
                }
            }

        case actions.type.UPDATE_CURRENT_POST:
            return {
                ...state,
                ...{
                    detail_post: {
                        ...state.detail_post,
                        ...payload.info
                    }
                }
            }

        case actions.type.SET_CURRENT_POST:
            return {
                ...state,
                ...{
                    detail_post: payload.post
                }
            }

        case actions.type.REMOVE_POST:
            return state

        case actions.type.SAVE_CURRENT_POST:
            return state

        case actions.type.SET_CURRENT_CATEGORY_ID:
            return {
                ...state,
                ...{
                    current_category_id: payload.id
                }
            }
        case actions.type.LOAD_LIST_POST_BY_TAGS:
            return state
        case actions.type.LOAD_LIST_POST_BY_TAGS_SUCCESS:
            return {
                ...state,
                ...{
                    postsByTags: payload.posts
                }
            }
        case actions.type.SET_CURRENT_TAGS:
            return {
                ...state, 
                ...{
                    currentTags: payload.tags
                }
            }
        default:
            return state
    }
}

export default reducer