import { all, takeEvery, fork, put, select, take } from "redux-saga/effects";
import actions from "./actions";
import { history } from "../store";

// api
import PostApiServices from '../../../services/api/post'
import { uploadImage } from "../../../services/api/upload";
import { getAllCategoryPost } from "../../../services/api/category_post"

// helper
import NotifycationServices from "../../../services/utils/notifycationHelper";

// const
import Router_Links from "../../../const/router_link";

// validate
import validate_post from "../../../services/utils/validate/validate_post";

// list posts
function* saga_LoadListPosts(action) {
    try {
        let params = action.payload.params

        let _state = yield select(state => state.admin_post)
        let pageIndex = _state.pageIndex
        let pageSize = _state.pageSize
        let textSearch = _state.last_search

        if (params.pageIndex) pageIndex = params.pageIndex
        if (params.pageSize) pageSize = params.pageSize
        if (params.textSearch) textSearch = params.textSearch

        let param_query = [
            {
                name: "filters[categoryId]",
                value: _state.current_category_id,
            },
        ];

        let req = yield PostApiServices.GetListPost(textSearch, pageSize, pageIndex, param_query)
        if (req.code === 200) {
            let total = req.data.total
            yield put(
                actions.action.updateState({
                    total
                })
            )

            let posts = req.data.items
            yield put(
                actions.action.loadListPostsSuccess(posts)
            )
        }
    }
    catch (ex) {
        console.log("[Admin Post Saga] saga_LoadListPost error : ", ex.message)
    }
}

function* saga_UpdatePageIndex(action) {
    try {
        let pageIndex = action.payload.pageIndex

        yield put(
            actions.action.loadListPosts({
                pageIndex
            })
        )
    }
    catch (ex) {
        console.log("[Admin Post Saga] saga_UpdatePageIndex error : ", ex.message)
    }
}

function* saga_SearchPost(action) {
    try {
        let textSearch = yield select(state => state.admin_post.text_search)

        yield put(
            actions.action.loadListPosts({
                textSearch,
                pageIndex: 1
            })
        )
    }
    catch (ex) {
        console.log("[Admin Post Saga] saga_SearchPost error : ", ex.message)
    }
}

// detail post
function* saga_LoadDetailPost(action) {
    try {
        let id = action.payload.id

        let req = yield PostApiServices.GetDetailPost(id)
        if (req.code === 200) {
            let post = req.data
            yield put(
                actions.action.loadDetailPostSuccess(post)
            )
        }
    }
    catch (ex) {
        console.log("[Admin Post Saga] saga_LoadDetailPost error : ", ex.message)
    }
}

function* saga_RemovePost(action) {
    try {
        let id = action.payload.id

        let req = yield PostApiServices.RemovePost(id)
        if (req.code === 200) {
            NotifycationServices.success("Xóa bài viết thành công")
            yield put(
                actions.action.loadListPosts()
            )
        }
        else
            NotifycationServices.error("Xóa bài viết thất bại")
    }
    catch (ex) {
        console.log("[Admin Post Saga] saga_RemovePost error : ", ex.message)
        NotifycationServices.error("Xóa bài viết thất bại")
    }
}

function* saga_SaveCurrentPost(action) {
    let post = {}

    try {
        post = yield select(state => state.admin_post.detail_post)

        if (post.thumbnail_file) {
            let req = yield uploadImage(post.thumbnail_file)
            let thumbnailUrl = req.path
            post = {
                ...post,
                ...{
                    thumbnailUrl
                }
            }
        }

        // validate
        let validateRes = validate_post.validate(post)
        if (validateRes.isValidate === false) {
            NotifycationServices.error(validateRes.message)
            return
        }

        if (post._id === "") {
            // thêm bài viết
            post._id = null

            let req = yield PostApiServices.CreatePost(post)
            if (req.code === 200) {
                NotifycationServices.success("Thêm bài viết thành công")

                yield history.push(Router_Links.A_LIST_POST)
            }
            else
                NotifycationServices.error("Thêm bài viết thất bại")
        }
        else {
            // sửa bài viết
            let req = yield PostApiServices.UpdatePost(post._id, post)
            if (req.code === 200) {
                NotifycationServices.success("Cập nhật bài viết thành công")

                yield history.push(Router_Links.A_LIST_POST)
            }
            else
                NotifycationServices.error("Cập nhật bài viết thất bại")
        }
    }
    catch (ex) {
        console.log("[Admin Post Saga] saga_SaveCurrentPost error : ", ex.message)

        if (post._id === "")
            NotifycationServices.error("Thêm bài viết thất bại")
        else
            NotifycationServices.error("Cập nhật bài viết thất bại")
    }
}

function* saga_LoadListCategoryPost(action){
    try {
        
        let req = yield getAllCategoryPost()

        if (req.code === 200){
            let items = req.data 
            yield put(
                actions.action.loadListCategoryPostSuccess(items)
            )
        }
    }
    catch (ex){
        console.log("[Admin Post Saga] saga_LoadListCategoryPost error : ", ex.message)
    }
}

function* saga_LoadListPostsByTags(action) {
    try {
        let params = action.payload.params

        let _state = yield select(state => state.admin_post)
        let pageIndex = _state.pageIndexPostsByTags
        let pageSize = _state.pageSizePostsByTags

        if (params.pageIndex) pageIndex = params.pageIndex
        if (params.pageSize) pageSize = params.pageSize

        let param_query = [
            {
                name: "filters[tags]",
                value: _state.currentTags,
            },
        ];

        let req = yield PostApiServices.GetListPost('', pageSize, pageIndex, param_query)
        if (req.code === 200) {
            let total = req.data.total
            yield put(
                actions.action.updateState({
                    totalPostsByTags: total
                })
            )

            let posts = req.data.items
            yield put(
                actions.action.loadListPostByTagsSuccess(posts)
            )
        }
    }
    catch (ex) {
        console.log("[Admin Post Saga] saga_LoadListPostsByTags error : ", ex.message)
    }
}

function* listen() {
    yield takeEvery(actions.type.LOAD_LIST_POSTS, saga_LoadListPosts)
    yield takeEvery(actions.type.UPDATE_PAGE_INDEX, saga_UpdatePageIndex)
    yield takeEvery(actions.type.SEARCH_POST, saga_SearchPost)
    yield takeEvery(actions.type.LOAD_DETAIL_POST, saga_LoadDetailPost)
    yield takeEvery(actions.type.REMOVE_POST, saga_RemovePost)
    yield takeEvery(actions.type.SAVE_CURRENT_POST, saga_SaveCurrentPost)
    yield takeEvery(actions.type.LOAD_LIST_CATEGORY_POST, saga_LoadListCategoryPost)
    yield takeEvery(actions.type.LOAD_LIST_POST_BY_TAGS, saga_LoadListPostsByTags)
}

export default function* mainSaga() {
    yield all([fork(listen)]);
}