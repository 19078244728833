import actions from './actions';

const initState = {
    listPosts: [],
    pageIndex: 0,
    pageSize: 10,
    total: 0,
    textSearch: '',
    tag: ''
}

const reducer = (state = initState, action) => {
    const payload = action.payload;
    switch(action.type) {
        case actions.type.UPDATE_STATE:
            return {
                ...state,
                ...payload.state
            }
        case actions.type.LOAD_LIST_POST_BY_TAG:
            return state
        case actions.type.LOAD_LIST_POST_BY_TAG_SUCCESS:
            return {
                ...state,
                ...{
                    listPosts: payload.listPosts
                }
            }
        case actions.type.UPDATE_PAGE_INDEX:
            return {
                ...state,
                ...{
                    pageIndex: payload.pageIndex
                }
            }
        case actions.type.SET_CURRENT_TAG:
            return {
                ...state,
                ...{
                    tag: payload.tag
                }
            }
        default:
            return state
    }
}

export default reducer;