import actions from "./actions";

const default_category = {
    _id: 0,
    code: "",
    description: "",
    status: true,
    parentId: null
};

const default_new_category = {
    code: "",
    description: "",
    parentId: null
};

const initState = {
    // categories
    categories: [],

    // detail category
    category: default_category,
    //all categories
    allCategory: [],
    // new category
    newCategory: default_new_category,
    mdCreateNewCategoryState: false
};

const reducer = (state = initState, action) => {
    let payload = action.payload;

    switch (action.type) {
        case actions.type.UPDATE_STATE:
            return {
                ...state,
                ...payload.state,
            };

        // category 
        case actions.type.LOAD_LIST_CATEGORY:
            return {
                ...state,
                ...{},
            };

        case actions.type.LOAD_LIST_CATEGORY_SUCCESS:
            return {
                ...state,
                ...{
                    categories: payload.categories
                }
            }
        case actions.type.LOAD_DETAIL_CATEGORY:
            return state;
        case actions.type.LOAD_DETAIL_CATEGORY_SUCCESS:
            return {
                ...state,
                ...{
                    category: payload.category
                }
            }
        case actions.type.LOAD_ALL_CATEGORY:
            return state
        case actions.type.LOAD_ALL_CATEGORY_SUCCESS:
            return {
                ...state,
                ...{
                    allCategory: payload.allCategory
                }
            }
        case actions.type.UPDATE_CURRENT_CATEGORY:
            return {
                ...state,
                ...{
                    category: {
                        ...state.category,
                        ...payload.category
                    }
                }
            }
        case actions.type.SAVE_CURRENT_CATEGORY:
            return state
        case actions.type.REMOVE_CURRENT_CATEGORY:
            return state
        case actions.type.UPDATE_NEW_CATEGORY:
            return {
                ...state,
                ...{
                    newCategory: {
                        ...state.newCategory,
                        ...payload.newCategory
                    }
                }
            }
        case actions.type.SET_MODAL_CREATE_NEW_CATEGORY_STATE: 
            return {
                ...state,
                ...{
                    mdCreateNewCategoryState: payload.state,
                    newCategory: {
                        ...default_new_category,
                        parentId: payload.parentId
                    }
                }
            }
        case actions.type.CREATE_NEW_CATEGORY:
            return state
        default:
            return state;
    }
};

export default reducer;
