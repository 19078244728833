import React, { Component } from "react";

// ui
import "./index.scss";

// redux
import { connect } from "react-redux";
import UserProductHotAction from '../../../redux/user_product_hot_discount/actions'

// subcomponent
import ProductPanel from './product_panel'
import ProductSkeleton from "../../../../components/loading/product_skeleton";
import { BsFillLightningFill } from "react-icons/bs";

class HomePage extends Component {
    componentDidMount = () => {
        this.props.init();
    };

    renderSkeletonProductList = () => {
        const skelectonProductList = [];
        for (let i = 0; i < 6; i++) {
            skelectonProductList.push(<ProductSkeleton/>);
        }
        return (
            <div className='row'>
                {skelectonProductList}
            </div>
        )
    }

    render() {
        const { isLoadingHotProductList } = this.props;
        return (
            <div className="HomePage">
                <div className="hot-discount-header">
                    <div className="HomePage_Title">
                        <BsFillLightningFill className="mr-3"/>
                        Khuyến mại hot
                        <BsFillLightningFill className="ml-3"/>
                    </div>
                </div>
                    {isLoadingHotProductList
                    ? this.renderSkeletonProductList()
                    : <ProductPanel />
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const _state = state.user_product_hot_discount;
    return {
        isLoadingHotProductList: _state.isLoadingHotProductList
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        init: () => {
            dispatch(UserProductHotAction.action.loadListHotProduct())
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
