import React, { Component } from 'react'

// redux
import { connect } from 'react-redux'
import actions from '../../../../redux/admin_post/actions'

// react-router-dom
import { Link } from 'react-router-dom'

// ui component
import { FaSave, FaTimesCircle } from 'react-icons/fa'
import router_links from '../../../../../const/router_link'

class BottomControlPanel extends Component {
    render(){
        return (
            <div className="BottomControlPanel">
                <div className="row">

                    <div className="Button"
                        onClick={() => {
                            this.props.saveCurrentPost()
                        }}
                    >
                        <FaSave className="Icon" />
                        Lưu
                    </div>

                    <Link 
                        className="Button" 
                        style={{ color: 'black', backgroundColor: '#e2e2e2' }}
                        to={router_links.A_LIST_POST}
                    >
                        <FaTimesCircle className="Icon" />
                        Hủy
                    </Link>

                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {

    }
}

const mapDispatchToProps = dispatch => {
    return {
        saveCurrentPost: () => {
            dispatch(actions.action.saveCurrentPost())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BottomControlPanel)