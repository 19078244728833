import actions from "./actions";

// helper 
import ListHelperServices from '../../../services/utils/listHelper'

export const SORT_MODE_CONST = {
    NONE: 1,
    SORT_DESC_NAME: 2,
    SORT_ASC_NAME: 3,
    SORT_DESC_PRICE: 4,
    SORT_ASC_PRICE: 5,
};

const default_product = {
    _id: "",
    code: "",
    name: "",
    description: "",
    status: true,
    price: 0,
    discount: 0,
    thumbnail: "",
    isValueAddedProduct: false,
    productCategory: "",
    categoryIds: [],
    __v: 0,
    flashSalePrice: 0,
    flashSaleDiscount: 20,
    quantity: 0,
    images: [],
};

const default_discount_info = {
    code: "",
    description: "",
    discount: 0,
    _id: "",
};

const initState = {
    // home
    products: [],
    isLoadingHomeProduct: false,
    total: 0,
    pageIndex: 1,
    pageSize: 12,
    textSearch: "",
    sortMode: SORT_MODE_CONST.SORT_ASC_NAME,

    categorys: [],
    // curent flash sale
    flashsale_products: [],
    flashsale: {},
    isLoadingCurrentFlashSale: false,

    // detail product
    product: default_product,
    relate_products: [],

    // carts
    carts: [],
    total: 0,
    shipping_fee: 0,
    discount_price_of_cart: 0,
    is_loading_price_of_cart: false,
    discount_info: default_discount_info,

    // address
    list_address: [],
    select_address: null,

    // popular category
    popular_categorys: [],

    // levels
    levels: [],

    // posts
    popular_posts: []
};

// helper
const calSumCart = (carts) => {
    try {
        let total = 0;

        for (let index = 0; index < carts.length; index++) {
            let cart = carts[index];

            total = cart.quantity * cart.price + total;
        }

        return total;
    } catch (ex) {
        return 0;
    }
};

// handle
const _handleSelectAddress = (state, list_address) => {
    if (!Array.isArray(list_address)) return state;

    let select_address = list_address[0] ? list_address[0] : null;
    for (let index = 0; index < list_address.length; index++) {
        let address = list_address[index];

        if (address.isDefault) {
            select_address = address;
            break;
        }
    }

    return {
        ...state,
        ...{
            list_address,
            select_address,
        },
    };
};

const _handleAddCartGuest = (state, item) => {
    try {
        let carts = state.carts 

        // check item is exits 
        const compareProduct = (a,b) => {
            return a.product && b.product && a.product._id === b.product._id
        }

        let cart_item = ListHelperServices.getItemInArray(carts, item, compareProduct)
        if (cart_item !== null){
            return _handleUpdateCartGuest(state, cart_item._id, parseInt(cart_item.quantity) + parseInt(item.quantity))
        }

        carts = ListHelperServices.addItemToArray(carts, item)
        let total = calSumCart(carts)

        return {
            ...state,
            ...{
                carts,
                total
            }
        }
    }
    catch {
        return state
    }
}

const _handleRemoveCartGuest = (state, id) => {
    try {
        let carts = state.carts 

        carts = ListHelperServices.removeItemFromArray(carts, { _id: id })
        let total = calSumCart(carts)

        return {
            ...state,
            ...{
                carts,
                total
            }
        }
    }
    catch{
        return state 
    }
}

const _handleUpdateCartGuest = (state, id = "", quantity = 0) => {
    try {
        let carts = state.carts 

        let cart_item = ListHelperServices.getItemInArray(carts, { _id: id })
        cart_item.quantity = quantity 
        cart_item.total = cart_item.price * cart_item.quantity  
        carts = ListHelperServices.updateItemInArray(carts, cart_item)

        let total = calSumCart(carts)

        return {
            ...state,
            ...{
                carts,
                total
            }
        }
    }
    catch{
        return state
    }
}

const reducer = (state = initState, action) => {
    let payload = action.payload;

    switch (action.type) {
        case actions.type.UPDATE_STATE:
            return {
                ...state,
                ...payload.state,
            };

        // home
        case actions.type.LOAD_LIST_HOME_PRODUCT:
            return {
                ...state,
                ...{
                    isLoadingHomeProduct: true,
                },
            };

        case actions.type.LOAD_LIST_PRODUCT_SUCCESS:
            return {
                ...state,
                ...{
                    products: payload.products,
                    isLoadingHomeProduct: false,
                },
            };
        
        case actions.type.LOAD_LIST_HOME_PRODUCT_FAILED:
            return {
                ...state,
                isLoadingHomeProduct: false,
            }

        case actions.type.LOAD_PRODUCT_CURRENT_FLASH_SALE:
            return {
                ...state,
                ...{
                    flashsale_products: [],
                    isLoadingCurrentFlashSale: true,
                },
            };
        case actions.type.LOAD_PRODUCT_CURRENT_FLASH_SALE_SUCCESS:
            return {
                ...state,
                ...{
                    flashsale_products: payload.products,
                    isLoadingCurrentFlashSale: false,
                },
            };
        case actions.type.LOAD_PRODUCT_CURRENT_FLASH_SALE_FAILED:
            return {
                ...state,
                isLoadingCurrentFlashSale: false,
            }

        case actions.type.LOAD_LIST_CATEGORY:
            return state;

        case actions.type.LOAD_LIST_CATEGORY_SUCCESS:
            return {
                ...state,
                ...{
                    categorys: payload.categorys.slice(0, 8),
                },
            };

        // detail product
        case actions.type.LOAD_DETAIL_PRODUCT:
            return state;

        case actions.type.LOAD_DETAIL_PRODUCT_SUCCESS:
            return {
                ...state,
                ...{
                    product: payload.product,
                },
            };

        case actions.type.LOAD_RELATE_PRODUCT:
            return {
                ...state,
                ...{
                    relate_products: [],
                },
            };

        case actions.type.LOAD_RELATE_PRODUCT_SUCCESS:
            return {
                ...state,
                ...{
                    relate_products: payload.products,
                },
            };

        // cart
        case actions.type.LOAD_LIST_CART_ITEM:
            return state;

        case actions.type.LOAD_LIST_CART_ITEM_SUCCESS:
            return {
                ...state,
                ...{
                    carts: payload.carts,
                    total: calSumCart(payload.carts),
                },
            };

        case actions.type.ADD_CART_ITEM:
            return state;

        case actions.type.UPDATE_CART_ITEM:
            return state;

        case actions.type.REMOVE_CART_ITEM:
            return state;

        case actions.type.GUEST_ADD_CART_ITEM:
            return _handleAddCartGuest(state, payload.item)

        case actions.type.GUEST_UPDATE_CART_ITEM:
            return _handleUpdateCartGuest(state, payload.id, payload.quantity)

        case actions.type.GUEST_REMOVE_CART_ITEM:
            return _handleRemoveCartGuest(state, payload.id)

        case actions.type.ASYNC_GUEST_CART:
            return state

        case actions.type.GET_SHIPPING_FEE:
            return {
                ...state,
                ...{
                    shipping_fee: -1,
                },
            };

        case actions.type.GET_SHIPPING_FEE_SUCCESS:
            return {
                ...state,
                ...{
                    shipping_fee: payload.fee,
                },
            };
        
        case actions.type.CHECK_DISCOUNT_CODE:
            return state 

        case actions.type.USE_DISCOUNT_CODE:
            return {
                ...state,
                ...{
                    discount_info: payload.info
                }
            }

        case actions.type.REMOVE_DISCOUNT_CODE:
            return {
                ...state,
                ...{
                    discount_info: default_discount_info
                }
            }

        case actions.type.GET_CURRENT_VOUCHER_APPLY:
            return state 

        case actions.type.GET_CURRENT_VOUCHER_APPLY_SUCCESS:
            return {
                ...state,
                ...{
                    discount_info: payload.voucher
                }
            }

        case actions.type.GET_DISCOUNT_PRICE_OF_CART:
            return {
                ...state,
                ...{
                    is_loading_price_of_cart: true
                }
            }

        case actions.type.GET_DISCOUNT_PRICE_OF_CART_SUCCESS:
            return {
                ...state,
                ...{
                    is_loading_price_of_cart: false,
                    discount_price_of_cart: payload.discount_price
                }
            }
        case actions.type.GET_DISCOUNT_PRICE_OF_CART_FAILED:
            return {
                ...state,
                ...{
                    is_loading_price_of_cart: false,
                }
            }

        // address
        case actions.type.LOAD_LIST_ADDRESS:
            return state;

        case actions.type.LOAD_LIST_ADDRESS_SUCCESS:
            return _handleSelectAddress(state, payload.address);

        case actions.type.SET_SELECT_ADDRESS:
            return {
                ...state,
                ...{
                    select_address: payload.address,
                },
            };

        // check out
        case actions.type.CHECK_OUT_CART:
            return state;

        // popular category
        case actions.type.LOAD_LIST_POPULAR_CATEGORY:
            return state;

        case actions.type.LOAD_LIST_POPULAR_CATEGORY_SUCCESS:
            return {
                ...state,
                ...{
                    popular_categorys: payload.categorys,
                },
            };

        // level 
        case actions.type.LOAD_LIST_LEVEL:
            return state 

        case actions.type.LOAD_LIST_LEVEL_SUCCESS:
            return {
                ...state,
                ...{
                    levels: payload.levels
                }
            }

        // post
        case actions.type.LOAD_LIST_POPULAR_POST:
            return state 

        case actions.type.LOAD_LIST_POPULAR_POST_SUCCESS:
            return {
                ...state,
                ...{
                    popular_posts: payload.posts
                }
            }

        default:
            return state;
    }
};

export default reducer;
