import { all, takeEvery, fork, put, select } from "redux-saga/effects";
import actions from "./actions";
import { history } from "../store";

// api
import {
    getListHistoryTransaction
} from "../../../services/api/payment";

// helper
import NotifycationServices from "../../../services/utils/notifycationHelper";

// const
import Router_Links from "../../../const/router_link";

function* saga_LoadListHistoryTransaction(action) {
    try {
        let params = action.payload.params

        let _state = yield select(state => state.user_profile_history_transaction)
        let { pageSize, pageIndex } = _state 

        if (params.pageSize) pageSize = params.pageSize
        if (params.pageIndex) pageIndex = params.pageIndex

        let req = yield getListHistoryTransaction(pageSize, pageIndex)
        if (req.code === 200){
            let transactions = req.data.items
            let total = req.data.total 

            yield put(
                actions.action.updateState({
                    total
                })
            )

            yield put(
                actions.action.loadListHistoryTransactionSuccess(transactions)
            )
        }

    } catch (ex) {
        console.log(
            "[user_profile_history_transaction] saga_LoadListHistoryTransaction error : ",
            ex.message
        );
    }
}

function* saga_UpdatePageIndex(action) {
    try {
        let pageIndex = action.payload.pageIndex

        yield put (
            actions.action.loadListHistoryTransaction({ pageIndex })
        )

    } catch (ex) {
        console.log(
            "[user_profile_history_transaction] saga_UpdatePageIndex error : ",
            ex.message
        );
    }
}

function* listen() {
    yield takeEvery(actions.type.LOAD_LIST_HISTORY_TRANSACTION, saga_LoadListHistoryTransaction);
    yield takeEvery(actions.type.UPDATE_PAGE_INDEX, saga_UpdatePageIndex);
}

export default function* mainSaga() {
    yield all([fork(listen)]);
}
