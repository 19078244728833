import React, { Component } from 'react'
import Modal from 'react-modal';
import PropTypes from 'prop-types'

// icons
import { FaTimes } from 'react-icons/fa'

Modal.setAppElement('#root')
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.25), 0 6px 20px 0 rgba(0, 0, 0, 0.11)",
        padding: '15px',
        borderRaidus: "6px",
        zIndex: 1000
    }
};

class ModalComponent extends Component {

    onRequestClose = () => {
        this.props.setIsOpen(false);
    }

    render() {
        let stylez = this.props.style ? this.props.style : { content: {} } 
        
        stylez = {
            content: {
                ...stylez.content,
                ...customStyles.content,
                ...this.props.modalStyle
            }
        }

        return (
            <Modal
                {
                ...this.props
                }
                onRequestClose={() => this.onRequestClose()}
                style={stylez}
            >
                {
                    this.props.children
                }
                {
                    this.props.displayCloseIcon &&
                    <FaTimes
                        onClick={() => this.onRequestClose()}
                        style={{
                            fontSize: 25,
                            position: 'absolute',
                            top: 15,
                            right: 15,
                            cursor: 'pointer'
                        }}
                    />
                }
            </Modal>
        )
    }

}

ModalComponent.defaultProps = {
    displayCloseIcon: true
}

ModalComponent.propTypes = {
    isOpen: PropTypes.bool,
    style: PropTypes.object,
    setIsOpen: PropTypes.func,

    displayCloseIcon: PropTypes.bool
}

export default ModalComponent