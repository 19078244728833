/* eslint-disable jsx-a11y/img-redundant-alt */
import { Select } from "antd";
import React, { Component } from "react";
// icon
import { FaEdit, FaPlusCircle, FaTrash } from "react-icons/fa";
// redux
import { connect } from "react-redux";
// react-router-dom
import { withRouter } from "react-router-dom";
import Button from "../../../../components/button";
import ModalConfirmDelete from "../../../../components/modal/modal_confirm_action";
import TreeViewComponent from "../../../../components/treeView";
import { getImageUrl } from "../../../../services/api/upload";
// helper
import { convertCategoryDataToTreeViewData } from "../../../../services/utils/helper";
import CategoryActions from "../../../redux/admin_category/actions";
import "../product_detail/index.scss";
// scss
import "./index.scss";
// component
import ModalAddCategory from "./modal_add_category";

class AdminCategoryList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirmMd: false,
    };
  }

  componentDidMount = () => {
    this.props.init();
  };

  componentDidUpdate(prevProps) {
    let { categories } = this.props;
    if (
      this.props.categories !== prevProps.categories &&
      this.props.categories.length !== prevProps.categories.length
    ) {
      if (typeof this.props.loadAllCategory === "function") {
        this.props.loadAllCategory();
      }
      if (typeof this.props.loadDetailCategory === "function") {
        if (
          Array.isArray(this.props.categories) &&
          this.props.categories.length > 0
        ) {
          this.props.loadDetailCategory(categories[0]._id);
        }
      }
    }
  }

  onSelectCategory = (id) => {
    if (typeof this.props.loadDetailCategory === "function") {
      this.props.loadDetailCategory(id);
    }
  };

  onChangeParentCategory = (parentId) => {
    if (typeof this.props.updateCurrentCategory === "function") {
      this.props.updateCurrentCategory({ parentId: parentId });
    }
  };

  onChangeStatusCategory = (status) => {
    if (typeof this.props.updateCurrentCategory === "function") {
      this.props.updateCurrentCategory({ status: status });
    }
  };

  onChangeCodeCategory = (evt) => {
    if (typeof this.props.updateCurrentCategory === "function") {
      let code = evt.target.value;
      this.props.updateCurrentCategory({ code: code });
    }
  };

  onChangeIndexCategory = (evt) => {
    if (typeof this.props.updateCurrentCategory === "function") {
      let indexNumber = evt.target.value;
      this.props.updateCurrentCategory({ indexNumber });
    }
  };

  onChangeDescription = (evt) => {
    if (typeof this.props.updateCurrentCategory === "function") {
      let description = evt.target.value;
      this.props.updateCurrentCategory({ description: description });
    }
  };

  onClickButtonEditCategory = () => {
    if (typeof this.props.saveCurrentCategory === "function") {
      this.props.saveCurrentCategory();
    }
  };

  onClickButtonRemoveCategory = () => {
    this.setState({ showConfirmMd: true });
  };

  onClickButtonCreateNewCategory = () => {
    if (typeof this.props.setModalCreateNewCategoryState === "function") {
      this.props.setModalCreateNewCategoryState(true);
    }
  };

  onClickButtonCreateNewChildCategory = () => {
    if (typeof this.props.setModalCreateNewCategoryState === "function") {
      let { category } = this.props;
      this.props.setModalCreateNewCategoryState(true, category._id);
    }
  };

  onCancelMdConfirm = () => {
    this.setState({ showConfirmMd: false });
  };

  confirmRemoveCategory = () => {
    if (typeof this.props.removeCurrentCategory === "function") {
      let { category } = this.props;
      this.props.removeCurrentCategory(category._id);
    }
    this.setState({ showConfirmMd: false });
  };

  handleImageChange(e) {
    e.preventDefault();

    for (let index = 0; index < e.target.files.length; index++) {
      let file = e.target.files[index];
      let reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const imageLocal = {
          _id: new Date().getTime(),
          url: reader.result,
          file: file,
        };

        if (typeof this.props.updateCurrentCategory === "function") {
          this.props.updateCurrentCategory({ imageLocal });
        }

        // this.props.updateCurrentProduct({
        //     images,
        // });
      };
    }
  }

  render() {
    let { categories, category, allCategory } = this.props;
    const { showConfirmMd } = this.state;

    let parentCategories = allCategory.filter(
      (element) => element._id !== category._id
    );
    return (
      <div className="AdminCategoryList">
        <div className="d-flex ACL_Header">
          <Button
            label="Thêm mới danh mục"
            icon={<FaPlusCircle style={{ marginRight: "4px" }} />}
            className="ACL_AddButton"
            onClick={this.onClickButtonCreateNewCategory}
          />
        </div>
        <div className="d-flex ACL_body">
          <div className="ACL_Tree">
            <div className="ACL_Label">Danh mục loại sản phẩm</div>
            <div>
              <TreeViewComponent
                useCustom={true}
                className="ACL_TreeView"
                data_source={convertCategoryDataToTreeViewData(categories)}
                onSelect={this.onSelectCategory}
                value={[category._id]}
              />
            </div>
          </div>

          <div className="AdminDetailCategory">
            <div className="ADC_Label">Chi tiết danh mục sản phẩm</div>
            <form>
              <div>
                <label className="ADCF_Label">Mã code</label>
                <input
                  type="text"
                  className="ADCF_Value"
                  value={category.code}
                  onChange={this.onChangeCodeCategory}
                />
              </div>

              <div>
                <label className="ADCF_Label">Ví trí</label>
                <input
                  type="text"
                  className="ADCF_Value"
                  value={category.indexNumber}
                  onChange={this.onChangeIndexCategory}
                />
              </div>

              <div>
                <label className="ADCF_Label">Tên danh mục</label>
                <input
                  className="ADCF_Value"
                  type="text"
                  value={category.description}
                  onChange={this.onChangeDescription}
                />
              </div>
              <div>
                <label className="ADCF_Label">Danh mục cha</label>
                <Select
                  value={category.parentId}
                  className="ADCF_Select"
                  onChange={this.onChangeParentCategory}
                >
                  <Select.Option value={null}>Danh mục gốc</Select.Option>
                  {parentCategories.map((category) => (
                    <Select.Option key={category._id} value={category._id}>
                      {category.description}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div>
                <label className="ADCF_Label">Trạng thái</label>
                <Select
                  value={category.status}
                  className="ADCF_Select"
                  onChange={this.onChangeStatusCategory}
                >
                  <Select.Option value={true}>Kích hoạt</Select.Option>
                  <Select.Option value={false}>Chưa kích hoạt</Select.Option>
                </Select>
              </div>

              <div className="APD_InfoRow">
                <label className="ADCF_Label">Ảnh danh mục</label>
                <div
                  className="APD_InfoImageContent row"
                  style={{ padding: 0, marginLeft: 8 }}
                >
                  <div className="APD_ProductImageItem">
                    {category.imageLocal && (
                      <img
                        src={getImageUrl(category.imageLocal.url)}
                        className="APD_ProductImage"
                        alt="image"
                        width={200}
                        height={200}
                      />
                    )}

                    {!category.imageLocal && category.thumbnail && (
                      <img
                        src={getImageUrl(category.thumbnail)}
                        className="APD_ProductImage"
                        alt="image"
                      />
                    )}

                    {/* <FaTimes
                      className="APD_ProductImage_RemoveButton"
                      onClick={() => {
                        this.handleRemoveImage(image._id);
                      }}
                    /> */}
                  </div>
                  <input
                    type="file"
                    name="file"
                    id="file"
                    class="hide"
                    onChange={(e) => this.handleImageChange(e)}
                  />
                  <label for="file">
                    <div className="APD_ProductImageItem">
                      {/* <FaPlus className="APD_UploadProductImage" /> */}
                      <div
                        className="APD_ProductImage_Title"
                        style={{ textDecorationLine: "underline" }}
                      >
                        {category.imageLocal ? "Chọn ảnh khác" : "Thêm ảnh"}
                      </div>
                    </div>
                  </label>
                </div>
              </div>

              <div className="d-flex justify-content-between ADCF_Action">
                <Button
                  label="Thêm danh mục con"
                  icon={<FaPlusCircle style={{ marginRight: "4px" }} />}
                  onClick={this.onClickButtonCreateNewChildCategory}
                />

                <div className="d-flex">
                  <Button
                    label="Sửa"
                    icon={<FaEdit style={{ marginRight: "4px" }} />}
                    className="ADCF_Button ADCF_Button_Edit"
                    onClick={this.onClickButtonEditCategory}
                  />
                  <Button
                    label="Xóa"
                    icon={<FaTrash style={{ marginRight: "4px" }} />}
                    className="ADCF_Button_Delete"
                    onClick={this.onClickButtonRemoveCategory}
                  />
                </div>
              </div>
            </form>
          </div>
          <ModalAddCategory />
          <ModalConfirmDelete
            isOpen={showConfirmMd}
            onCancel={this.onCancelMdConfirm}
            setIsOpen={this.onCancelMdConfirm}
            onOk={this.confirmRemoveCategory}
            text="Bạn có chắc chắn muốn xóa danh mục sản phẩm vừa chọn không?"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let _state = state.admin_category;

  return {
    categories: _state.categories,
    category: _state.category,
    allCategory: _state.allCategory,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    init: () => {
      dispatch(CategoryActions.action.loadListCategory());
      dispatch(CategoryActions.action.loadAllCategory());
    },
    loadAllCategory: () => {
      dispatch(CategoryActions.action.loadAllCategory());
    },
    loadDetailCategory: (id) => {
      dispatch(CategoryActions.action.loadDetailCategory(id));
    },
    updateCurrentCategory: (category) => {
      dispatch(CategoryActions.action.updateCurrentCategory(category));
    },
    saveCurrentCategory: () => {
      dispatch(CategoryActions.action.saveCurrentCategory());
    },
    removeCurrentCategory: (id) => {
      dispatch(CategoryActions.action.removeCurrentCategory(id));
    },
    setModalCreateNewCategoryState: (state, parentId) => {
      dispatch(
        CategoryActions.action.setModalCreateNewCategoryState(state, parentId)
      );
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AdminCategoryList));
