/* eslint-disable jsx-a11y/iframe-has-title */
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import router_links from "../../../../const/router_link";
import "./footer.scss";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle_mobile: false,
      togge_search_mobile: false,
    };
  }

  handleTogleMobile = () => {
    if (!this.state.toggle_mobile) {
      document.body.classNameList.add("mmenu-active");
    } else {
      document.body.classNameList.remove("mmenu-active");
    }

    this.setState({ toggle_mobile: !this.state.toggle_mobile });
  };

  handleToggleSearchMobile = () => {
    this.setState({ togge_search_mobile: !this.state.togge_search_mobile });
  };

  render() {
    return (
      <div className="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <a
                  href="#"
                  className="widget-newsletter-title"
                  style={{
                    fontSize: 14,
                    color: "white !important",
                  }}
                >
                  Đăng ký để nhận tin khuyến mại
                </a>
              </div>
              <div className="col-lg-4" style={{ textAlign: "center" }}>
                <p className="widget-newsletter-content">
                  Tất cả các thông tin mới nhất về sự kiện, khuyến mại, ưu đãi
                  <br />
                  <span
                    className="widget-newsletter-content"
                    style={{ fontSize: 13 }}
                  >
                    Nhận 100.000 VNĐ cho lần mua hàng đầu tiên
                  </span>
                </p>
              </div>
              <div className="col-lg-5">
                <form action="#">
                  <div className="footer-submit-wrapper">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Nhập địa chỉ email của bạn"
                      required
                    />
                    <button type="submit" className="btn">
                      Đăng ký
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="footer-middle">
            <div className="row row-sm">
              <div className="col-lg-6 col-xl-4">
                <div style={{ display: "flex" }}>
                  <a href="index.html">
                    <img
                      src={
                        "https://bizweb.dktcdn.net/100/390/239/themes/770334/assets/logo.png?1596513388655"
                      }
                      alt="Cadosa"
                    />
                  </a>
                  <div
                    className="intro-cadosa"
                    style={{ marginLeft: 20, flex: 1, marginTop: 10 }}
                  >
                    Địa chỉ: 20A, Ngõ 280 Hồ Tùng Mậu, Cầu Diễn, Từ Liêm, Hà
                    Nội, Việt Nam
                  </div>

                  <div
                    className="intro-cadosa"
                    style={{ marginLeft: 20, flex: 1, marginTop: 10 }}
                  >
                    CADOSA ra đời với mục đích vì sức khoẻ cộng đồng và hệ sinh
                    thái môi trường thân thiện, đồng thời giúp cho hàng triệu
                    phụ nữ Việt Nam có được cuộc sống thịnh vượng hơn, hạnh phúc
                    hơn và thành công hơn
                  </div>
                </div>
                <div className="row row-sm footer-contact">
                  <div className="col-sm-4 col-4">
                    <div className="contact-widget">
                      <h4 className="widget-title">HotLine</h4>
                      <a href="#">+02468 9298 560</a>
                    </div>
                  </div>

                  <div className="col-sm-4 col-4">
                    <div className="contact-widget email">
                      <h4 className="widget-title">Email</h4>
                      <a href="mailto:cskh@cadosa.vn">cskh@cadosa.vn</a>
                    </div>
                  </div>

                  <div className="col-sm-4 col-4">
                    <div className="contact-widget">
                      <h4 className="widget-title">Mạng xã hội</h4>
                      <span
                        href="#"
                        style={{
                          color: "gray",
                          marginRight: 8,
                        }}
                        target="_blank"
                      >
                        <i className="fab fa-facebook-f"></i>
                      </span>
                      <span
                        href="#"
                        style={{
                          color: "gray",
                          marginRight: 8,
                        }}
                        target="_blank"
                      >
                        <i className="fab fa-twitter"></i>
                      </span>
                      <span
                        href="#"
                        style={{
                          color: "gray",
                        }}
                        target="_blank"
                      >
                        <i className="fab fa-linkedin-in"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-2 col-xl-3">
                <div className="widget">
                  <h4 className="widget-title">Về chúng tôi</h4>
                  <ul className="links link-parts">
                    <div className="link-part">
                      <li>
                        <Link
                          className="SOS_Panel"
                          to={`${router_links.U_DETAIL_POST}/609885dc5a79b14d989c9bdc`}
                        >
                          <span>Chính sách đổi trả</span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="SOS_Panel"
                          to={`${router_links.U_DETAIL_POST}/609885dc5a79b14d989c9bda`}
                        >
                          <span>Chính sách thanh toán</span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="SOS_Panel"
                          to={`${router_links.U_DETAIL_POST}/609885dd5a79b14d989c9bde`}
                        >
                          <span>Chính sách giao nhận và vận chuyển</span>
                        </Link>
                      </li>

                      <li>
                        <Link
                          className="SOS_Panel"
                          to={`${router_links.U_DETAIL_POST}/609885dd5a79b14d989c9be0`}
                        >
                          <span>Chính sách bảo mật</span>
                        </Link>
                      </li>

                      <li>
                        <Link
                          className="SOS_Panel"
                          to={`${router_links.U_DETAIL_POST}/61449a3d9ca595372006814c`}
                        >
                          <span>Điều khoản và điều kiện giao dịch</span>
                        </Link>
                      </li>

                      <li>
                        <Link
                          className="SOS_Panel"
                          to={`${router_links.U_DETAIL_POST}/60bde355bfd7b77492823782`}
                        >
                          <span>Tiêu chuẩn nhà cung cấp</span>
                        </Link>
                      </li>

                      <li>
                        <Link
                          className="SOS_Panel"
                          to={`${router_links.U_DETAIL_POST}/609eb9e5a4459809641e55c7`}
                        >
                          <span>Hành trình hạnh phúc</span>
                        </Link>
                      </li>
                    </div>
                  </ul>
                </div>

                {/* <!-- End .widget --> */}
              </div>

              <div
                style={{
                  backgroundColor: "red",
                }}
              >
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d536.2352903047512!2d105.76484640492083!3d21.040911158544077!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x313455b505384be5%3A0x4ab07d967a8ea232!2zQ8O0bmcgdHkgY-G7lSBwaOG6p24gxJHhuqd1IHTGsCBDYWRvc2E!5e0!3m2!1svi!2s!4v1626130204357!5m2!1svi!2s"
                  loading="lazy"
                  style={{
                    border: 0,
                    position: "absolute",
                    right: 140,
                    marginTop: -40,
                  }}
                  height={210}
                  width={450}
                />
              </div>
            </div>
          </div>

          <div className="footer-bottom">
            <p className="footer-copyright">
              @2020 - Bản quyền thuộc về Cadosa.
            </p>
            {/* <img
                            src={require("../../../../assets/images/payments_long.png")}
                            width="180px"
                            style={{ maxHeight: 24 }}
                        /> */}
          </div>
        </div>
        {/* <!-- End .footer --> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Footer));
