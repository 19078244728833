const prefix = 'user_filter_product/'

const type = {
    UPDATE_STATE: prefix + 'update_state',

    // filter page 
    LOAD_LIST_PRODUCT: prefix + 'load_list_product',
    LOAD_LIST_PRODUCT_SUCCESS: prefix + 'load_list_product_success',
    LOAD_LIST_PRODUCT_FAILED: prefix + 'load_list_product_failed',

    LOAD_LIST_UNIT: prefix + 'load_list_unit',
    LOAD_LIST_UNIT_SUCCESS: prefix + 'load_list_unit_success',

    LOAD_LIST_CATEGORY: prefix + 'load_list_category',
    LOAD_LIST_CATEGORY_SUCCESS: prefix + 'load_list_category_success',

    UPDATE_PAGE_INDEX: prefix + 'update_page_index',
    UPDATE_TEXT_SEARCH: prefix + 'update_text_search',
    SEARCH_PRODUCT: prefix + 'search_product',

    // filter array
    ADD_FILTER_CATEGORY: prefix + 'add_filter_product',
    REMOVE_FILTER_CATEGORY: prefix + 'remove_filter_product',

    ADD_FILTER_UNIT: prefix + 'add_filter_unit',
    REMOVE_FILTER_UNIT: prefix + 'remove_filter_unit',

    FILTER_PRODUCT: prefix + 'filter_product'
}

const action = {
    updateState: state => {
        return {
            type: type.UPDATE_STATE,
            payload: {
                state
            }
        }
    },

    // filter page 
    loadListProduct: (params = {}) => {
        return {
            type: type.LOAD_LIST_PRODUCT,
            payload: {
                params
            }
        }
    },
    loadListProductSuccess: products => {
        return {
            type: type.LOAD_LIST_PRODUCT_SUCCESS,
            payload: {
                products
            }
        }
    },
    loadListProductFailed: () => {
        return {
            type: type.LOAD_LIST_PRODUCT_FAILED,
            payload: {}
        }
    },
    loadListUnit: () => {
        return {
            type: type.LOAD_LIST_UNIT,
            payload: {

            }
        }
    },
    loadListUnitSuccess: units => {
        return {
            type: type.LOAD_LIST_UNIT_SUCCESS,
            payload: {
                units
            }
        }
    },
    loadListCategory: () => {
        return {
            type: type.LOAD_LIST_CATEGORY,
            payload: {

            }
        }
    },
    loadListCategorySuccess: categorys => {
        return {
            type: type.LOAD_LIST_CATEGORY_SUCCESS,
            payload: {
                categorys
            }
        }
    },

    updatePageIndex: pageIndex => {
        return {
            type: type.UPDATE_PAGE_INDEX,
            payload: {
                pageIndex
            }
        }
    },
    updateTextSearch: textSearch => {
        return {
            type: type.UPDATE_TEXT_SEARCH,
            payload: {
                textSearch
            }
        }
    },
    searchProduct: () => {
        return {
            type: type.SEARCH_PRODUCT,
            payload: {
                
            }
        }
    },
    
    // filter array
    addFilterCategory: category => {
        return {
            type: type.ADD_FILTER_CATEGORY,
            payload: {
                category
            }
        }
    },
    removeFilterCategory: category => {
        return {
            type: type.REMOVE_FILTER_CATEGORY,
            payload: {
                category
            }
        }
    },
    addFilterUnit: unit => {
        return {
            type: type.ADD_FILTER_UNIT,
            payload: {
                unit
            }
        }
    },
    removeFilterUnit: unit => {
        return {
            type: type.REMOVE_FILTER_UNIT,
            payload: {
                unit
            }
        }
    },
    filterProduct: () => {
        return {
            type: type.FILTER_PRODUCT,
            payload: {

            }
        }
    },
    
}

export default {
    type,
    action
}