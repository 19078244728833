import createApiServices from './createApiServices'

const api = createApiServices()

// auth 
export const login = (uname, password) => {
    const body = {
        password: password,
        email: uname,
    }
    return api.makeRequest({
        url: 'auth/login',
        method: 'POST',
        data: body,
    })
}

export const loginAdmin = (uname = "", password = "") => {
    const body = {
        password: password,
        email: uname,
    }
    return api.makeRequest({
        url: 'admin/auth/login',
        method: 'POST',
        data: body,
    })
}

export const logout = () => {
    return api.makeAuthRequest({
        url: 'auth/logout',
        method: 'GET',
    })
}

export const getUserInfo = () => {
    return api.makeAuthRequest({
        url: 'auth/getme',
        method: 'GET',
        data: {
        }
    })
}

export const checkSession = () => {
    return api.makeAuthRequest({
        url: 'auth/checkTokenAlive',
        method: 'GET'
    })
}

export const checkSessionAdmin = () => {
    return api.makeAuthRequest({
        url: 'admin/auth/checkAdminTokenAlive',
        method: 'GET'
    })
}

export const changePassword = (oldPassword = "", newPassword = "") => {
    return api.makeAuthRequest({
        url: 'user/changePassword',
        method: 'POST',
        data: {
            oldPassword,
            newPassword
        }
    })
}

export const changeAvatar = image => {
    const formData = new FormData();
    formData.append("images", image);

    return api.makeAuthRequest({
        url: `user/changeAvatar`,
        method: 'POST',
        data: formData
    })
}

export const changeUserInfo = (info) => {
    return api.makeAuthRequest({
        url: `user/changeInformation`,
        method: 'post',
        data: info
    })
}

export const registerUser = (info = {}, linkAffiliate = "") => {
    return api.makeRequest({
        url: `auth/register?linkAffiliate=${linkAffiliate}`,
        method: 'POST',
        data: info
    })
}

export const requestChangePassword = (email = "", redirectUrl = "") => {
    return api.makeRequest({
        url: `auth/forgot-password`,
        method: 'PUT',
        data: {
            email,
            redirectUrl
        }
    })
}

export const confirmResetPassword = (password = "", token = "") => {
    return api.makeRequest({
        url: `auth/submit-forgot-password`,
        method: 'PUT',
        data: {
            password,
            token
        }
    })
}

export const submitRegisterSuccess = (token = "") => {
    return api.makeAuthRequest({
        url: `auth/submitRegisterSuccess`,
        method: 'POST',
        data: {
            token
        }
    })
}

