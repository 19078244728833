/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react'

// css
import './index.scss'

// icon
import { FaUser, FaLock } from 'react-icons/fa'

// redux
import { connect } from 'react-redux'
import AuthActions from '../../../redux/auth/actions'

// route
import { Redirect } from 'react-router-dom'
import Router_Links from '../../../../const/router_link'
 
class LoginPage extends Component {
    constructor(props){
        super(props)
        this.state = {
            username: "",
            password: ""
        }
    }

    _handleKeyPress = (event) => {
        if (event.key === "Enter"){
            // this.props.onSearch(this.state.textSearch)
            this.props.login(this.state.username, this.state.password)
        }
    }

    render() {
        let from = null
        if (this.props.isLoggedIn) {
            if (from) return <Redirect to={from} />

            return <Redirect to={Router_Links.A_HOME_PAGE} />
        }

        return (
            <div className="LoginPage">
                <div className="LoginPanelz">

                    {/* login logo */}
                    <div className="LogoTitle">
                        <img
                            className="Logo"
                            src={"https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcT6rmB0BqLfe2I2pTgdeGPLQaD6pWpwPZlf1g&usqp=CAU"}
                            alt="image"
                        />
                        <div className="Info">
                            QUẢN TRỊ HỆ THỐNG
                        </div>
                    </div>

                    {/* login form */}
                    <div className="LoginForm">
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', position: 'relative' }}>
                            <FaUser
                                className="Icon"
                            />
                            <input
                                type="text"
                                placeholder="Email"
                                className="Input"
                                value={this.state.username}
                                onChange={(e) => this.setState({username: e.target.value})}
                                onKeyPress={(e) => this._handleKeyPress(e)}
                            />
                        </div>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', position: 'relative' }}>
                            <FaLock
                                className="Icon"
                            />
                            <input
                                type="password"
                                placeholder="Mật khẩu"
                                className="Input"
                                value={this.state.password}
                                onChange={(e) => this.setState({password: e.target.value})}
                                onKeyPress={(e) => this._handleKeyPress(e)}
                            />
                        </div>
                        {/* <div className="Button Login">
                            Đăng nhập
                        </div> */}
                        <div className="Button Register"
                            onClick={() => {
                                this.props.login(this.state.username, this.state.password)
                            }}
                        >
                            Đăng nhập
                        </div>
                    </div>

                </div>
            </div >
        )
    }
}

const mapStateToProps = state => {
    return {
        isLoggedIn: state.auth.isLoggedIn
    }
}

const mapDispatchToProps = dispatch => {
    return {
        login: (username, password) => {
            dispatch(AuthActions.action.loginAdmin(username, password))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);