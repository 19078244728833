import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './index.scss'

class ToolTipComponent extends Component {
    // props 

    // constructor
    constructor(props) {
        super(props);
        this.state = {
            isDisplayRef: false
        }

        this.mainRef = React.createRef()
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClick)
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClick)
    }

    handleClick = (event) => {
        const { target } = event

        if (!this.mainRef.current.contains(target))
            this.setVisible(false)
        else
            this.setVisible(!this.props.visible)
    }

    // set visible
    setVisible = state => {
        if (this.props.setIsVisible) {
            this.props.setIsVisible(state)
        }
    }

    // render
    render() {
        let _class = "ToolTipComponent"
        if (!this.props.visible)
            _class += " hide"

        return (
            <div
                className="ToolTipComponentMainView"

                ref={this.mainRef}
            >
                {
                    this.props.component
                }
                <div
                    className={_class}
                    {...this.props}
                >
                    <div className="Content">
                        {
                            this.props.children
                        }
                    </div>
                </div>
            </div>

        )
    }
}

ToolTipComponent.defaultProps = {
    visible: false
}

ToolTipComponent.propTypes = {
    visible: PropTypes.bool,
    setIsVisible: PropTypes.func,
    component: PropTypes.object
}

export default ToolTipComponent