import { server_address } from "./configs";
import createApiServices from "./createApiServices";

const api = createApiServices();

export const createOder = (order = {}) => {
  return api.makeAuthRequest({
    url: `order/create`,
    method: "POST",
    data: order,
  });
};

export const getAhaShipPrice = (addressId = "", serviceId = "") => {
  return api.makeAuthRequest({
    url: `shipping/getShippingFeeAhamove?addressId=${addressId}&serviceId=${serviceId}`,
    method: "GET",
  });
};

export const getDiscountInfoByCode = (code = "") => {
  return api.makeAuthRequest({
    url: `voucher/getByCode?voucherCode=${code}`,
    method: "GET",
  });
};

export const getListHistoryOrder = (pageSize = 10, pageIndex = 1) => {
  return api.makeAuthRequest({
    url: `order/getMyOrders?pageSize=${pageSize}&pageIndex=${pageIndex}`,
    method: "GET",
  });
};

export const getDetailOrder = (id = "") => {
  return api.makeAuthRequest({
    url: `order/getDetailMyOrder/${id}`,
    method: "GET",
  });
};

export const cancelOrder = (id = "", comment = "Không mua nữa") => {
  return api.makeAuthRequest({
    url: `order/cancelMyOrder`,
    method: "DELETE",
    data: {
      orderId: id,
      comment,
    },
  });
};

// admin
const GetAllOrder = (pageSize = 10, pageIndex = 1, filters) => {
  let url = `admin/managerOrder/getAllOrders?pageSize=${pageSize}&pageIndex=${pageIndex}`;

  if (filters && filters.status) {
    url += `&filters[status]=${filters.status}`;
  }

  if (filters && filters.paid) {
    url += `&filters[paid]=${filters.paid}`;
  }

  return api.makeAuthRequest({
    url,
    method: "GET",
  });
};

const UpdateStatusOrder = (
  id = "",
  status = 1,
  estimatedDeliveryTime = undefined
) => {
  return api.makeAuthRequest({
    url: `admin/managerOrder/changeOrderStatus`,
    method: "POST",
    data: {
      status,
      orderId: id,
      estimatedDeliveryTime,
    },
  });
};

const UpdatePaidOrder = (id = "", paid = 1) => {
  return api.makeAuthRequest({
    url: `admin/managerOrder/updatePaidOrder`,
    method: "POST",
    data: {
      paid,
      orderId: id,
    },
  });
};

const CancelOrder = (id = "", comment = "") => {
  return api.makeAuthRequest({
    url: `admin/managerOrder/cancelOrder`,
    method: "DELETE",
    data: {
      orderId: id,
      comment,
    },
  });
};

const GetDetailOrder = (id = "") => {
  return api.makeAuthRequest({
    url: `admin/managerOrder/getDetailUserOrder/${id}`,
    method: "GET",
  });
};

const AdminCreateOrder = (order = {}) => {
  return api.makeAuthRequest({
    url: `admin/managerOrder/createOrder`,
    method: "POST",
    data: order,
  });
};

export const GenPDFBill = (id = "") => {
  return api.makeAuthRequest({
    url: `admin/managerOrder/generateInvoiceOrder/${id}`,
    method: "GET",
  });
};

export const GetBillPDFUrl = (url = "") => {
  if (!url) return null;

  if (
    url.length >= 4 &&
    url[0] === "h" &&
    url[1] === "t" &&
    url[2] === "t" &&
    url[3] === "p"
  )
    return url;
  if (
    url.length >= 4 &&
    url[0] === "d" &&
    url[1] === "a" &&
    url[2] === "t" &&
    url[3] === "a"
  )
    return url;

  return `${server_address}/invoices/${url}`;
};

const updateValueOrder = (orderId, products, serviceId) => {
  return api.makeAuthRequest({
    url: `admin/managerOrder/updateValueOrder`,
    method: "PUT",
    data: {
      orderId,
      products,
      serviceId,
    },
  });
};

export const OrderAdminApiServices = {
  GetAllOrder,
  UpdateStatusOrder,
  GetDetailOrder,
  CancelOrder,
  AdminCreateOrder,
  AdminGenPDFBill: GenPDFBill,
  GetBillPDFUrl,
  UpdatePaidOrder,
  updateValueOrder,
};
