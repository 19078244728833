/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { Component } from 'react'

// redux
import { connect } from 'react-redux'
import { FaTrash, FaEye, FaEyeSlash } from 'react-icons/fa'

// sub component
import Pagination from '../../../../../components/paginations/paginations'

const fakse_list_comments = [
    {
        avatar: "https://previews.123rf.com/images/triken/triken1608/triken160800029/61320775-male-avatar-profile-picture-default-user-avatar-guest-avatar-simply-human-head-vector-illustration-i.jpg",
        name: 'Nguyễn Hoàng Anh',
        content: 'Thật bất công cho CR7',
        medias: [

        ],
        display: false
    },
    {
        avatar: "https://previews.123rf.com/images/triken/triken1608/triken160800029/61320775-male-avatar-profile-picture-default-user-avatar-guest-avatar-simply-human-head-vector-illustration-i.jpg",
        name: 'Bùi Đại Vũ',
        content: 'Dù sao CR7 cũng là 1 tượng đài. \nMessi là thiên tài <3. \nCR7 là biểu tượng cho sự nỗ lực.',
        medias: [

        ],
        display: true
    },
]

class ListComment extends Component {
    render() {
        let list_comments = [
            ...fakse_list_comments,
            ...fakse_list_comments,
            ...[
                fakse_list_comments[0]
            ]
            // ...fakse_list_comments
        ]

        return (
            <div className="list_comment">
                <div>
                    {
                        list_comments.map((comment, index) => {
                            return (
                                <div>
                                    <div className="coment_panel">
                                        <img
                                            className="avatar"
                                            src={comment.avatar}
                                            alt="image"
                                        />
                                        <div className="comment_content">
                                            <div className="name">
                                                {
                                                    comment.name
                                                }
                                            </div>
                                            {
                                                comment.content
                                            }
                                        </div>
                                        <div className="Control">
                                            {
                                                comment.display ?
                                                    <div className="Button">
                                                        <FaEyeSlash />
                                                    </div>
                                                    :
                                                    <div className="Button">
                                                        <FaEye />
                                                    </div>
                                            }
                                            <div className="Button">
                                                <FaTrash />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className="PaginateSection">
                        <Pagination
                            onChange={(index) => {
                                // this.props.updatePageIndex(index)
                            }}
                            pageSize={10}
                            pageIndex={2}
                            total={30}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {

    }
}

const mapDispatchToProps = dispatch => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListComment)