import colors from "../const/colors";

export const WareHouseStatus = {
  PENDING: "PENDING", // Chờ xác nhận
  CONFIRMED: "CONFIRMED", // Đã xác nhận
  BLOCKED: "BLOCKED", // Đã khoá
};

export const WareHouseStatusArr = [
  WareHouseStatus.PENDING,
  WareHouseStatus.CONFIRMED,
  WareHouseStatus.BLOCKED,
];

export const getTypeWareHouseStatus = (status) => {
  let name = "";
  let color = colors.primaryColor;

  switch (status) {
    case WareHouseStatus.PENDING:
      name = "Chờ xác nhận";
      color = "orange";
      break;

    case WareHouseStatus.CONFIRMED:
      name = "Đã xác nhận";
      break;

    case WareHouseStatus.BLOCKED:
      color = "red";
      name = "Đã khoá";
      break;
    default:
      break;
  }

  return {
    name,
    color,
  };
};

export const WareHouseInsertStatus = {
  PENDING: "PENDING", // Chờ xác nhận
  CONFIRMED: "CONFIRMED", // Đã xác nhận
  REJECT: "REJECT", // Từ chối
};

export const WareHouseInsertStatusArr = [
  WareHouseStatus.PENDING,
  WareHouseStatus.CONFIRMED,
  WareHouseStatus.REJECT,
];

export const getTypeWareHouseInsertStatus = (status) => {
  let name = "";
  let color = colors.primaryColor;

  switch (status) {
    case WareHouseStatus.PENDING:
      name = "Chờ xác nhận";
      color = "orange";
      break;

    case WareHouseStatus.CONFIRMED:
      name = "Đã xác nhận";
      break;

    case WareHouseStatus.REJECT:
      color = "red";
      name = "Từ chối";
      break;
    default:
      break;
  }

  return {
    name,
    color,
  };
};
