import React, { Component } from "react";
import {
  FaMoneyBill,
  FaSeedling,
  FaShoppingCart,
  FaUsers,
} from "react-icons/fa";
// redux
import { connect } from "react-redux";
import { numberDisplayThousand } from "../../../../services/utils/helper";
import "./index.scss";

class DashboardInfo extends Component {
  render() {
    let { totalRevenue, totalOrder, totalProduct, totalUser } = this.props;

    return (
      <div className="DashboardInfo">
        <div className="DBI_Container">
          <div className="DBI_Logo">
            <FaMoneyBill className="DBI_Icon" />
          </div>
          <div className="DBI_Info">
            <div className="DBI_Title">DOANH THU</div>
            <div className="DBI_Content">
              {numberDisplayThousand(totalRevenue)}
            </div>
          </div>
        </div>

        <div className="DBI_Container">
          <div className="DBI_Logo">
            <FaShoppingCart className="DBI_Icon" />
          </div>
          <div className="DBI_Info">
            <div className="DBI_Title">ĐƠN HÀNG</div>
            <div className="DBI_Content">
              {numberDisplayThousand(totalOrder)}
            </div>
          </div>
        </div>

        <div className="DBI_Container">
          <div className="DBI_Logo">
            <FaSeedling className="DBI_Icon" />
          </div>
          <div className="DBI_Info">
            <div className="DBI_Title">SẢN PHẨM</div>
            <div className="DBI_Content">
              {numberDisplayThousand(totalProduct)}
            </div>
          </div>
        </div>

        <div className="DBI_Container" style={{ marginRight: 0 }}>
          <div className="DBI_Logo">
            <FaUsers className="DBI_Icon" />
          </div>
          <div className="DBI_Info">
            <div className="DBI_Title">THÀNH VIÊN</div>
            <div className="DBI_Content">
              {numberDisplayThousand(totalUser)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let _state = state.admin_dashboard;
  return {
    totalRevenue: _state.totalRevenue,
    totalOrder: _state.totalOrder,
    totalProduct: _state.totalProduct,
    totalUser: _state.totalUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardInfo);
