const prefix = 'admin_flash_sale'

const type = {
    UPDATE_STATE: prefix + 'update_state',
    LOAD_LIST_FLASH_SALE: prefix + 'load_list_flash_sale',
    LOAD_LIST_FLASH_SALE_SUCCESS: prefix + 'load_list_flash_sale_success',
    UPDATE_PAGE_INDEX: prefix + 'update_page_index',
    SET_MODAL_ADD_FLASH_SALE_STATE: prefix + 'set_modal_add_flash_sale_state',
    UPDATE_NEW_FLASH_SALE: prefix + 'update_new_flash_sale',
    CREATE_NEW_FLASH_SALE: prefix + 'create_new_flash_sale',
    REMOVE_FLASH_SALE: prefix + 'remove_flash_sale',

    // flash sale detail
    LOAD_LIST_FLASH_SALE_DETAIL: prefix + 'load_list_flash_sale_detail',
    LOAD_LIST_FLASH_SALE_DETAIL_SUCCESS: prefix + 'load_list_flash_sale_detail_success',
    REMOVE_PRODUCT_FLASH_SALE: prefix + 'remove_product_flash_sale',
    SET_MODAL_UPDATE_PRODUCT_FLASH_SALE_STATE: prefix + 'set_modal_update_product_flash_sale_state',
    UPDATE_DATA_PRODUCT_FLASH_SALE: prefix + 'update_data_product_flash_sale',
    DO_UPDATE_PRODUCT_FLASH_SALE: prefix + 'do_update_product_flash_sale',
    DO_REMOVE_ALL_PRODUCT_FLASH_SALE: prefix + 'do_remove_all_product_flash_sale',
    // add product flash sale
    LOAD_LIST_PRODUCT: prefix + 'load_list_product',
    LOAD_LIST_PRODUCT_SUCCESS: prefix + 'load_list_product_success',
    PUSH_PRODUCT_FLASH_SALE_TO_ARRAY: prefix + 'push_product_flash_sale_to_array',
    REMOVE_PRODUCT_FLASH_SALE_OUT_ARRAY: prefix + 'remove_product_flash_sale_out_array',
    UPDATE_ARRAY_PRODUCT_FLASH_SALE: prefix + 'update_array_product_flash_sale',
    UPDATE_PAGE_INDEX_ADD_PRODUCT_PAGE: prefix + 'update_page_index_add_product_page',
    DO_ADD_PRODUCT_FLASH_SALE: prefix + 'do_add_product_flash_sale',
    // change product flash sale new 
    UPDATE_NEW_PRODUCT_FLASH_SALE: prefix + 'update_new_product_flash_sale',
    UPDATE_TEXT_SEARCH_PRODUCT: prefix + 'update_text_search_product',

    // modal edit flash sale
    SET_MD_EDIT_FLASH_SALE_STATE: prefix + 'set_md_edit_flash_sale_state',
    SET_CURRENT_FLASH_SALE: prefix + 'set_current_flash_sale',
    UPDATE_CURRENT_FLASH_SALE: prefix + 'update_current_flash_sale',
    SAVE_CURRENT_FLASH_SALE: prefix + 'save_current_flash_sale'
}

const action = {
    updateState: (state) => {
        return {
            type: type.UPDATE_STATE,
            payload: {
                state
            }
        }
    },

    loadListFlashSale: (params = {}) => {
        return {
            type: type.LOAD_LIST_FLASH_SALE,
            payload: {
                params
            }
        }
    },
    loadListFlashSaleSuccess: (flashSales = []) => {
        return {
            type: type.LOAD_LIST_FLASH_SALE_SUCCESS,
            payload: {
                flashSales
            }
        }
    },
    updatePageIndex: (pageIndex) => {
        return {
            type: type.UPDATE_PAGE_INDEX,
            payload: {
                pageIndex
            }
        }
    },
    setModalAddFlashSaleState: (state) => {
        return {
            type: type.SET_MODAL_ADD_FLASH_SALE_STATE,
            payload: {
                state
            }
        }
    },
    updateNewFlashSale: (newFlashSale) => {
        return {
            type: type.UPDATE_NEW_FLASH_SALE,
            payload: {
                newFlashSale
            }
        }
    },
    createNewFlashSale: () => {
        return {
            type: type.CREATE_NEW_FLASH_SALE,
            payload: {

            }
        }
    },
    removeFlashSale: (id) => {
        return {
            type: type.REMOVE_FLASH_SALE,
            payload: {
                id
            }
        }
    },
    loadListFlashSaleDetail: (id) => {
        return {
            type: type.LOAD_LIST_FLASH_SALE_DETAIL,
            payload: {
                id
            }
        }
    },
    loadListFlashSaleDetailSuccess: (flashSalesDetail = []) => {
        return {
            type: type.LOAD_LIST_FLASH_SALE_DETAIL_SUCCESS,
            payload: {
                flashSalesDetail
            }
        }
    },
    removeProductFlashSale: (id) => {
        return {
            type: type.REMOVE_PRODUCT_FLASH_SALE,
            payload: {
                id
            }
        }
    },
    setModalUpdateProductFlashSaleState: (state, name = "", id = "",  currentProduct = {}) => {
        return {
            type: type.SET_MODAL_UPDATE_PRODUCT_FLASH_SALE_STATE,
            payload: {
                state,
                name,
                id, 
                currentProduct
            }
        }
    },
    updateDataProductFlashSale: (data) => {
        return {
            type: type.UPDATE_DATA_PRODUCT_FLASH_SALE,
            payload: {
                data
            }
        }
    },
    doUpdateProductFlashSale: () => {
        return {
            type: type.DO_UPDATE_PRODUCT_FLASH_SALE,
            payload: {

            }
        }
    },
    loadListProduct: (params = {}) => {
        return {
            type: type.LOAD_LIST_PRODUCT,
            payload: {
                params
            }
        }
    },
    loadListProductSuccess: (products = []) => {
        return {
            type: type.LOAD_LIST_PRODUCT_SUCCESS,
            payload: {
                products
            }
        }
    },
    pushProductFlashSaleToArray: (productId) => {
        return {
            type: type.PUSH_PRODUCT_FLASH_SALE_TO_ARRAY,
            payload: {
                productId
            }
        }
    },
    updateNewProductFlashSale: (productId, productFlashSale) => {
        return {
            type: type.UPDATE_NEW_PRODUCT_FLASH_SALE,
            payload: {
                productId,
                productFlashSale
            }
        }
    },
    removeProductFlashSaleOutArray: (productId) => {
        return {
            type: type.REMOVE_PRODUCT_FLASH_SALE_OUT_ARRAY,
            payload: {
                productId
            }
        }
    },
    updateArrayProductFlashSale: (productFlashSales = []) => {
        return {
            type: type.UPDATE_ARRAY_PRODUCT_FLASH_SALE,
            payload: {
                productFlashSales
            }
        }
    },
    updatePageIndexAddProductPage: (pageIndex) => {
        return {
            type: type.UPDATE_PAGE_INDEX_ADD_PRODUCT_PAGE,
            payload: {
                pageIndex
            }
        }
    },
    doAddProductFlashSale: () => {
        return {
            type: type.DO_ADD_PRODUCT_FLASH_SALE,
            payload: {

            }
        }
    },
    doRemoveAllProductFlashSale: (id) => {
        return {
            type: type.DO_REMOVE_ALL_PRODUCT_FLASH_SALE,
            payload: {
                id
            }
        }
    },
    updateTextSearchProduct: (textSearchProduct) => {
        return {
            type: type.UPDATE_TEXT_SEARCH_PRODUCT,
            payload: {
                textSearchProduct
            }
        }
    },
    setModalEditFlashSaleSate: (state) => {
        return {
            type: type.SET_MD_EDIT_FLASH_SALE_STATE,
            payload: {
                state
            }
        }
    },
    setCurrentFlashSale: (flashSale) => {
        return {
            type: type.SET_CURRENT_FLASH_SALE,
            payload: {
                flashSale
            }
        }
    },
    updateCurrentFlashSale: (flashSale) => {
        return {
            type: type.UPDATE_CURRENT_FLASH_SALE,
            payload: {
                flashSale
            }
        }
    },
    saveCurrentFlashSale: () => {
        return {
            type: type.SAVE_CURRENT_FLASH_SALE,
            payload: {
                
            }
        }
    }
}

export default {
    type,
    action
}