import React, { Component } from "react";

// redux
import { connect } from "react-redux";
import AuthActions from "../../../redux/auth/actions";

// react-router-dom
import { Link } from "react-router-dom";

// images
import LoginImageBackground from "../../../../resource/images/login.jpg";
import UserImage from "../../../../resource/images/user.jpg";

// route
import { Redirect } from "react-router-dom";
import Router_Links from "../../../../const/router_link";

// scss
import "./index.scss";

// api
import { submitRegisterSuccess } from '../../../../services/api/auth'

// query
import queryString from "query-string";

// helper
import NotificationsService from "../../../../services/utils/notifycationHelper";


class LoginPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
        };
    }

    token = ""

    // life circle 
    componentDidMount = async() => {
        let params = queryString.parse(this.props.location.search);
        this.token = params.token ? params.token : "";

        if (this.token !== ""){
            let req
            try {
                req = await submitRegisterSuccess(this.token)
                if (req.code === 200){
                    NotificationsService.success("Tài khoản đã được xác thực, vui lòng đăng nhập để sử dụng hệ thống")
                }
            }
            catch {
                NotificationsService.error("Kích hoạt tài khoản thất bại: " + req.message ? req.message : "")
            }
        }
    }

    // handle
    _handleKeyPress = (event) => {
        if (event.key === "Enter") {
            // this.props.onSearch(this.state.textSearch)
            this.props.login(this.state.username, this.state.password);
        }
    };

    render() {
        let from = null;
        if (this.props.isLoggedIn) {
            if (from) return <Redirect to={from} />;

            return <Redirect to={Router_Links.U_PROFILE_INFO} />;
        }

        return (
            <div className="User_Login_Container">
                <div className="User_LoginPage">
                    <div className="LoginPanel">
                        <div className="LgP_TopPanel">
                            <img className="LPT_Image" src={UserImage} />
                            <div className="LPT_Title">Đăng nhập</div>
                        </div>

                        <div className="LgP_BottomPanel">
                            <div className="LBP_Label">Email hoặc số điện thoại</div>
                            <input
                                className="LPB_username"
                                placeholder="Nhập Email hoặc số điện thoại"
                                type="text"
                                value={this.state.username}
                                onChange={(e) =>
                                    this.setState({ username: e.target.value })
                                }
                                onKeyPress={(e) => this._handleKeyPress(e)}
                            />

                            <div className="LBP_Label">Mật khẩu</div>
                            <input
                                className="LPB_password"
                                placeholder="Nhập mật khẩu"
                                type="password"
                                value={this.state.password}
                                onChange={(e) =>
                                    this.setState({ password: e.target.value })
                                }
                                onKeyPress={(e) => this._handleKeyPress(e)}
                            />

                            <div
                                className="LGB_ButtonLogin"
                                onClick={() => {
                                    this.props.login(
                                        this.state.username,
                                        this.state.password
                                    );
                                }}
                            >
                                Đăng nhập
                            </div>

                            <div className="LGB_More">
                                <Link
                                    to="/auth/forget-password"
                                    className="LGB_Title"
                                >
                                    Quên mật khẩu
                                </Link>
                                <Link to="/auth/register" className="LGB_Title">
                                    Đăng ký tài khoản
                                </Link>
                            </div>

                            {/* <div className="LGB_ButtonBackHome">
                                Quay lại trang chủ
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="UserLogin_Panel">
                    <div className="ULP_Title">Đăng nhập</div>

                    <div className="ULP_Subtitle">
                        Đăng nhập để theo dõi đơn hàng, lưu danh sách sản phẩm
                        yêu thích, nhận nhiều ưu đãi hấp dẫn.
                    </div>

                    <div className="Row">
                        <img
                            src={LoginImageBackground}
                            className="UserLogin_ImagePanel"
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.auth.isLoggedIn
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        login: (username, password) => {
            dispatch(AuthActions.action.login(username, password));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
