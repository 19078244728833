import { all, fork, put, select, takeEvery } from "redux-saga/effects";
// const
import Router_Links from "../../../const/router_link";
// api
import { OrderAdminApiServices } from "../../../services/api/order";
// helper
import NotifycationServices from "../../../services/utils/notifycationHelper";
import { history } from "../store";
import actions from "./actions";

function* saga_LoadListOrder(action) {
  try {
    let params = action.payload.params;

    let _state = yield select((state) => state.admin_order);
    let { pageSize, pageIndex } = _state;

    if (params.pageIndex) pageIndex = params.pageIndex;
    if (params.pageSize) pageSize = params.pageSize;

    let res = yield OrderAdminApiServices.GetAllOrder(
      pageSize,
      pageIndex,
      params.filters || undefined
    );
    let { items, total } = res.data;

    if (res.code === 200) {
      yield put(actions.action.loadListOrderSuccerss(items));
      yield put(
        actions.action.updateState({
          total,
        })
      );
    }
  } catch (ex) {
    console.log(
      "[admin_order Saga] saga_LoadListHistoryOrder Error : ",
      ex.message
    );
  }
}

function* saga_UpdatePageIndex(action) {
  try {
    const params = {
      pageIndex: action.payload.pageIndex,
    };

    if (
      action.payload &&
      action.payload.params &&
      action.payload.params.filters
    ) {
      params.filters = action.payload.params.filters;
    }

    yield put(actions.action.loadListOrder(params));
  } catch (ex) {
    console.log("[admin_order Saga] saga_UpdatePageIndex Error : ", ex.message);
  }
}

function* saga_LoadDetailOrder(action) {
  try {
    let id = action.payload.id;

    let req = yield OrderAdminApiServices.GetDetailOrder(id);
    if (req.code === 200) {
      let order = req.data;
      yield put(actions.action.loadDetailOrderSuccess(order));
    }
  } catch (ex) {
    console.log("[admin_order Saga] saga_LoadDetailOrder Error : ", ex.message);
  }
}

function* saga_UpdateStatusOrder(action) {
  try {
    const id = action.payload.id;
    const status = action.payload.status;

    const estimatedDeliveryTime =
      action.payload?.estimatedDeliveryTime || undefined;

    let req = yield OrderAdminApiServices.UpdateStatusOrder(
      id,
      status,
      estimatedDeliveryTime
    );
    if (req.code === 200) {
      yield put(actions.action.loadListOrder());
      window.location.reload();
      // yield history.push(Router_Links.A_LIST_ORDER);

      NotifycationServices.success("Cập nhật trạng thái đơn hàng thành công");
    } else {
      NotifycationServices.error("Cập nhật trạng thái đơn hàng thất bại");
    }
  } catch (ex) {
    console.log(
      "[admin_order Saga] saga_UpdateStatusOrder Error : ",
      ex.message
    );

    NotifycationServices.error("Cập nhật trạng thái đơn hàng thất bại");
  }
}

function* saga_UpdatePaidOrder(action) {
  try {
    let id = action.payload.id;
    let paid = action.payload.paid;

    let req = yield OrderAdminApiServices.UpdatePaidOrder(id, paid);
    if (req.code === 200) {
      yield put(actions.action.loadListOrder());

      window.location.reload();
      // yield history.push(Router_Links.A_LIST_ORDER);
      NotifycationServices.success("Cập nhật trạng thái đơn hàng thành công");
    } else {
      NotifycationServices.error("Cập nhật trạng thái đơn hàng thất bại");
    }
  } catch (ex) {
    console.log("[admin_order Saga] saga_UpdatePaidOrder Error : ", ex.message);

    NotifycationServices.error("Cập nhật trạng thái đơn hàng thất bại");
  }
}

function* saga_CancelOrder(action) {
  try {
    let orderId = action.payload.orderId;
    const res = yield OrderAdminApiServices.CancelOrder(orderId);
    if (res.code === 200) {
      yield put(actions.action.loadListOrder());
      yield put(actions.action.setModalCancelOrderState(false));

      yield history.push(Router_Links.A_LIST_ORDER);
      NotifycationServices.success("Hủy đơn hàng thành công");
    }
  } catch (ex) {
    console.log("[admin_order Saga] saga_CancelOrder Error : ", ex.message);
    yield put(actions.action.setModalCancelOrderState(false));
    NotifycationServices.error("Hủy đơn hàng thất bại");
  }
}

function* listen() {
  yield takeEvery(actions.type.LOAD_LIST_ORDER, saga_LoadListOrder);
  yield takeEvery(actions.type.UPDATE_PAGE_INDEX, saga_UpdatePageIndex);
  yield takeEvery(actions.type.LOAD_DETAIL_ORDER, saga_LoadDetailOrder);
  yield takeEvery(actions.type.UPDATE_ORDER_STATUS, saga_UpdateStatusOrder);
  yield takeEvery(actions.type.CANCEL_ORDER, saga_CancelOrder);
  yield takeEvery(actions.type.UPDATE_PAID_ODER, saga_UpdatePaidOrder);
}

export default function* mainSaga() {
  yield all([fork(listen)]);
}
