import ApiUtils from "../../../../../services/ApiUtils";

const apiName = {
  USER: "/admin/managerUser",
};

export const apiGetListUser = async (params) => {
  const res = await ApiUtils.fetch(apiName.USER, params, {
    isAuth: true,
  });
  return res.data || undefined;
};
