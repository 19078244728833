const prefix = 'user_profile_history_order/'

const type = {
    UPDATE_STATE: prefix + 'update_state',

    // order
    LOAD_LIST_HISTORY_ORDER: prefix + 'load_list_history_order',
    LOAD_LIST_HISTORY_ORDER_SUCCESS: prefix + 'load_list_history_order_success',
    UPDATE_PAGE_INDEX: prefix + 'update_page_index',
    
    // detail order
    LOAD_DETAIL_ORDER: prefix + 'load_detail_order',
    LOAD_DETAIL_ORDER_SUCCESS: prefix + 'load_detail_order_success',
    CANCEL_ORDER: prefix + 'cancel_order',
    GEN_BILL_PDF: prefix + 'gen_bill_pdf'
}   

const action = {
    updateState: state => {
        return {
            type: type.UPDATE_STATE,
            payload: {
                state
            }
        }
    },

    // order
    loadListHistoryOrder: (params = {}) => {
        return {
            type: type.LOAD_LIST_HISTORY_ORDER,
            payload: {
                params
            }
        }
    },
    loadListHistoryOrderSuccess: (orders = []) => {
        return {
            type: type.LOAD_LIST_HISTORY_ORDER_SUCCESS,
            payload: {
                orders
            }
        }
    },
    updatePageIndex: pageIndex => {
        return {
            type: type.UPDATE_PAGE_INDEX,
            payload: {
                pageIndex
            }
        }
    },

    // detail order
    loadDetailOrder: id => {
        return {
            type: type.LOAD_DETAIL_ORDER,
            payload: {
                id
            }
        }
    },
    loadDetailOrderSuccess: order => {
        return {
            type: type.LOAD_DETAIL_ORDER_SUCCESS,
            payload: {
                order
            }
        }
    },
    cancelOrder: id => {
        return {
            type: type.CANCEL_ORDER,
            payload: {
                id
            }
        }
    },
    genBillPDF: (id = "") => {
        return {
            type: type.GEN_BILL_PDF,
            payload: {
                id
            }
        }
    }
}

export default {
    type,
    action
}