import createApiServices from './createApiServices'

const api = createApiServices()

const getListVouchers = (pageSize = 10, pageIndex = 1, textSearch = "") => {
    return api.makeAuthRequest({
        url: `admin/managerVoucher?pageSize=${pageSize}&pageIndex=${pageIndex}&keyword=${textSearch}`,
        method: 'GET',
    })
}

const updateVoucher = (id = "", voucher = {}) => {
    return api.makeAuthRequest({
        url: `admin/managerVoucher/update/${id}`,
        method: 'PUT',
        data: voucher
    })
}

const createVoucher = (voucher = {}) => {
    return api.makeAuthRequest({
        url: `admin/managerVoucher/create`,
        method: 'POST',
        data: voucher
    })
}

const activeVoucher = (id = "") => {
    return api.makeAuthRequest({
        url: `admin/managerVoucher/active/${id}`,
        method: 'PUT'
    })
}

const deactiveVoucher = (id = "") => {
    return api.makeAuthRequest({
        url: `admin/managerVoucher/inactive/${id}`,
        method: 'PUT'
    })
}

const getListVoucherAvailable = (pageSize = 10, pageIndex = 1, textSearch = "") => {
    return api.makeAuthRequest({
        url: `voucher/getVouchersAvailable?pageSize=${pageSize}&pageIndex=${pageIndex}&keyword=${textSearch}`,
        method: 'GET',
    })
}

const getCurrentVoucherApply = () => {
    return api.makeAuthRequest({
        url: 'voucher/getUsingVoucher',
        method: 'GET'
    })
}

const applyVoucher = (id) => {
    return api.makeAuthRequest({
        url: `voucher/applyVoucher/${id}`,
        method: 'POST'
    })
}

const getListHistoryVoucher = (pageSize = 10, pageIndex = 1) => {
    return api.makeAuthRequest({
        url: `voucher/getUsageHistories?pageSize=${pageSize}&pageIndex=${pageIndex}`,
        method: 'GET'
    })
}

const getListOrderApplyVoucher = (pageSize = 10, pageIndex = 1, id) => {
    return api.makeAuthRequest({
        url: `voucher/getDetailUsageVoucher/${id}?pageSize=${pageSize}&pageIndex=${pageIndex}`,
        method: 'GET'
    })
}

const getPriceDiscountOfCart = (code = "") => {
    return api.makeAuthRequest({
        url: `voucher/getPriceDiscountOfCart?voucherCode=${code}`,
        method: 'GET'
    })
}

export default {
    getListVouchers,
    updateVoucher,
    createVoucher,
    activeVoucher,
    deactiveVoucher,
    getListVoucherAvailable,
    getCurrentVoucherApply,
    applyVoucher,
    getListHistoryVoucher,
    getListOrderApplyVoucher,
    getPriceDiscountOfCart
}