import actions from './actions';

const initState = {
    request_change_customer_groups: [],

    pageIndex: 1,
    pageSize: 10,
    total: 10,

    //detail request change customer group
    detail_request_change_customer_group: null,
    md_request_change_state: false,
    currentRequestId: "",

    groupCustomers: [],
    groupCustomerId: '',
    status: 0
}

const types = actions.type;

const reducer = (state = initState, action) => {
    let payload = action.payload;

    switch (action.type) {
        case types.UPDATE_STATE:
            return {
                ...state,
                ...payload.state
            }

        case types.LOAD_LIST_REQUEST_CHANGE_CUSTOMER_GROUP:
            return state;

        case types.LOAD_LIST_REQUEST_CHANGE_CUSTOMER_GROUP_SUCCESS:
            return {
                ...state,
                ...{
                    request_change_customer_groups: payload.request_change_customer_groups
                }
            }

        case types.UPDATE_STATUS_REQUEST_CHANGE_CUSTOMER_GROUP:
            return state

        case types.CANCEL_REQUEST_CHANGE_CUSTOMER_GROUP:
            return state
        case types.LOAD_DETAIL_REQUEST_CHANGE_CUSTOMER_GROUP:
            return state
        case types.LOAD_DETAIL_REQUEST_CHANGE_CUSTOMER_GROUP_SUCCESS:
            return {
                ...state,
                ...{
                    detail_request_change_customer_group: payload.detail_request_change_customer_group
                }
            }
        case types.UPDATE_PAGE_INDEX: 
            return {
                ...state,
                ...{
                    pageIndex: payload.pageIndex
                }
            }
        case types.SET_MODAL_REQUEST_CHANGE_CUSTOMER_GROUP_STATE:
            return {
                ...state,
                ...{
                    md_request_change_state: payload.state,
                    currentRequestId: payload.id
                }
            }
        case types.LOAD_LIST_GROUP_CUSTOMER:
            return state
        case types.LOAD_LIST_GROUP_CUSTOMER_SUCCESS:
            return {
                ...state,
                ...{
                    groupCustomers: payload.groupCustomers
                }
            }
        case types.UPDATE_STATUS_FILTER:
            return {
                ...state,
                ...{
                    status: payload.status
                }
            }
        case types.UPDATE_GROUP_CUSTOMER_FILTER:
            return {
                ...state,
                ...{
                    groupCustomerId: payload.groupCustomerId
                }
            }
        default:
            return state;
    }
}

export default reducer;