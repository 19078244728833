import ApiUtils from "../../../../../services/ApiUtils";

const apiName = {
  WARE_HOUSE: "/ware-house",
  CREATE: "/ware-house/create",
  INSERT_PRODUCT: "/ware-house/insert-product",
  INSERT_PRODUCT_HISTORY: "/ware-house/insert-product-history",
  INSERT_PRODUCT_DETAIL: "/ware-house/insert-product-detail",
};

export const apiGetAllWareHouse = async (params) => {
  const res = await ApiUtils.fetch(apiName.WARE_HOUSE, params, {
    isAuth: true,
  });
  return res.data || undefined;
};

export const apiGetDetailWareHouse = async (_id, params) => {
  const res = await ApiUtils.fetch(apiName.WARE_HOUSE + `/${_id}`, params, {
    isAuth: true,
  });
  return res.data || undefined;
};

export const apiCreateWareHouse = async (params) => {
  const res = await ApiUtils.post(apiName.CREATE, params, {
    isAuth: true,
  });
  return res.data || undefined;
};

export const apiUpdateWareHouse = async (_id, params) => {
  const res = await ApiUtils.put(apiName.WARE_HOUSE + `/${_id}`, params, {
    isAuth: true,
  });
  return res.data || undefined;
};

export const apiWareHouseInsertProduct = async (params) => {
  const res = await ApiUtils.post(apiName.INSERT_PRODUCT, params, {
    isAuth: true,
  });
  return res.data || undefined;
};

export const apiGetInsertProductHistory = async (_id, params) => {
  const res = await ApiUtils.fetch(
    apiName.INSERT_PRODUCT_HISTORY + `/${_id}`,
    params,
    {
      isAuth: true,
    }
  );
  return res.data || undefined;
};

export const apiGetInsertProductDetail = async (_id, params) => {
  const res = await ApiUtils.fetch(
    apiName.INSERT_PRODUCT_DETAIL + `/${_id}`,
    params,
    {
      isAuth: true,
    }
  );
  return res.data || undefined;
};
