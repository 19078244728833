const validate = (entity) => {
    // function
    const errorResult = (message) => {
        return {
            isValidate: false,
            message,
        };
    };

    // validate
    if (!entity.totalQuantity) {
        return errorResult("Số lượng không được để trống")
    }
        
    if (!entity.discount) {
        return errorResult("Giảm giá không được để trống")
    }
    return {
        isValidate: true,
        message: "success",
    };
};

export default {
    validate,
};