export default {
  MODULE_DASHBOARD: "MODULE_DASHBOARD",
  MODULE_USER_MANAGEMENT: "MODULE_USER_MANAGEMENT",
  MODULE_VOUCHER_MANAGEMENT: "MODULE_VOUCHER_MANAGEMENT",
  MODULE_PRODUCT_MANAGEMENT: "MODULE_PRODUCT_MANAGEMENT",
  MODULE_ORDER_MANAGEMENT: "MODULE_ORDER_MANAGEMENT",
  MODULE_GROUP_MANAGEMENT: "MODULE_GROUP_MANAGEMENT",
  MODULE_GROUP_CUSTOMER_MANAGEMENT: "MODULE_GROUP_CUSTOMER_MANAGEMENT",
  MODULE_LEVEL_MANAGEMENT: "MODULE_LEVEL_MANAGEMENT",
  MODULE_LEVEL_RELATIONSHIP: "MODULE_LEVEL_RELATIONSHIP",
  MODULE_POST_MANAGEMENT: "MODULE_POST_MANAGEMENT",
  MODULE_FLASH_SALES_MANAGEMENT: "MODULE_FLASH_SALES_MANAGEMENT",
  MODULE_CATEGORY_NEWS_MANAGEMENT: "MODULE_CATEGORY_NEWS_MANAGEMENT",
  MODULE_CATEGORY_PRODUCT_MANAGEMENT: "MODULE_CATEGORY_PRODUCT_MANAGEMENT",
  MODULE_PERMISSION_MANAGEMENT: "MODULE_PERMISSION_MANAGEMENT",
  MODULE_MANAGEMENT: "MODULE_MANAGEMENT",
  MODULE_REQUEST_GROUP_CUSTOMER: "MODULE_REQUEST_GROUP_CUSTOMER",

  MODULE_WARE_HOUSE_MANAGER: "MODULE_WARE_HOUSE_MANAGER",
  MODULE_NOTIFY_SYS_MANAGER: "MODULE_NOTIFY_SYS_MANAGER",
};
