import { all, fork, put, select, takeEvery } from "redux-saga/effects";
// api
import GroupCustomerApiServices from "../../../services/api/customer_group";
import VoucherApiServices from "../../../services/api/voucher";
// helper
import {
  default as NotificationsService,
  default as NotifycationServices,
} from "../../../services/utils/notifycationHelper";
import ValidateVoucherServices from "../../../services/utils/validate/validate_voucher";
import actions from "./actions";

function* saga_LoadListCustomerGroup(action) {
  try {
    // params

    let _state = yield select((state) => state.admin_voucher);
    let pageIndex = 1;
    let textSearch = "";
    let pageSize = 100;

    // call api
    let req = yield GroupCustomerApiServices.getListGroupCustomer(
      pageIndex,
      pageSize,
      textSearch
    );
    if (req.code === 200) {
      let customer_groups = req.data.items;
      let total = req.data.total;

      yield put(
        actions.action.updateState({
          total,
        })
      );
      yield put(actions.action.loadListCustomerGroupSuccess(customer_groups));
    }
  } catch (ex) {
    console.log("[admin_voucher] saga_LoadListCustomerGroup Error: ", ex);
  }
}

function* saga_LoadListVoucher(action) {
  try {
    // params
    let params = action.payload.params;

    let _state = yield select((state) => state.admin_voucher);
    let pageIndex = _state.pageIndex;
    let textSearch = _state.lastTextSearch;
    let pageSize = _state.pageSize;

    if (params.pageIndex) pageIndex = params.pageIndex;
    if (params.textSearch) textSearch = params.textSearch;
    if (params.pageSize) pageSize = params.pageSize;

    // call api
    let req = yield VoucherApiServices.getListVouchers(
      pageSize,
      pageIndex,
      textSearch
    );
    if (req.code === 200) {
      let vouchers = req.data.items;
      let total = req.data.total;

      yield put(
        actions.action.updateState({
          total,
        })
      );
      yield put(actions.action.loadListVoucherSuccess(vouchers));
    }
  } catch (ex) {
    console.log("[admin_voucher] saga_LoadListVoucher Error: ", ex);
  }
}

function* saga_UpdatePageIndex(action) {
  try {
    let pageIndex = action.payload.pageIndex;

    yield put(
      actions.action.loadListVoucher({
        pageIndex,
      })
    );
  } catch (ex) {
    console.log("[admin_voucher] saga_UpdatePageIndex Error: ", ex);
  }
}

function* saga_SearchVoucher(action) {
  try {
    let textSearch = yield select((state) => state.admin_voucher.textSearch);

    yield put(
      actions.action.loadListVoucher({
        textSearch: textSearch.trim(),
        pageIndex: 1,
      })
    );
  } catch (ex) {
    console.log("[admin_voucher] saga_SearchCustomerGroup Error: ", ex);
  }
}

function* saga_SaveCurrentVoucher(action) {
  let customer_group = {
    _id: 0,
  };

  try {
    let entity = yield select((state) => state.admin_voucher.current_voucher);

    let validateRes = yield ValidateVoucherServices.validate(entity);
    if (!validateRes.isValidate) {
      NotifycationServices.error(validateRes.message);
      return;
    }

    if (entity._id === 0) {
      // thêm mới
      let req = yield VoucherApiServices.createVoucher(entity);

      if (req.code === 200) {
        yield put(actions.action.loadListVoucher({}));
        yield put(actions.action.setModalVoucherState(false));
        NotificationsService.success("Thêm mã khuyến mại thành công");
      } else NotificationsService.error("Thêm mã khuyến mại thất bại");
    } else {
      // sửa
      let req = yield VoucherApiServices.updateVoucher(entity._id, entity);

      if (req.code === 200) {
        yield put(actions.action.loadListVoucher({}));
        yield put(actions.action.setModalVoucherState(false));
        NotificationsService.success(
          "Cập nhật thông tin mã khuyến mại thành công"
        );
      } else
        NotificationsService.error("Cập nhật thông tin mã khuyến mại thất bại");
    }
  } catch (ex) {
    console.log("[admin_voucher] saga_SaveCurrentCustomerGroup Error: ", ex);
    if (ex.message) {
      NotificationsService.error(ex.message.message);
      return;
    }

    if (customer_group._id == 0)
      NotificationsService.error("Thêm mã khuyến mại thất bại");
    else
      NotificationsService.error("Cập nhật thông tin mã khuyến mại thất bại");
  }
}

function* saga_ActiveVoucher(action) {
  try {
    let id = action.payload.id;

    let req = yield VoucherApiServices.activeVoucher(id);
    if (req.code === 200) {
      yield put(actions.action.loadListVoucher({}));
      NotificationsService.success("Kích hoạt mã khuyến mại thành công");
    } else NotificationsService.error("Kích hoạt mã khuyến mại thất bại");
  } catch (ex) {
    console.log("[admin_voucher] saga_ActiveVoucher Error: ", ex);
    NotificationsService.error("Kích hoạt mã khuyến mại thất bại");
  }
}

function* saga_DeactiveVoucher(action) {
  try {
    let id = action.payload.id;

    let req = yield VoucherApiServices.deactiveVoucher(id);
    if (req.code === 200) {
      yield put(actions.action.loadListVoucher({}));
      NotificationsService.success("Khoá mã khuyến mại thành công");
    } else NotificationsService.error("Khoá mã khuyến mại thất bại");
  } catch (ex) {
    console.log("[admin_voucher] saga_DeactiveVoucher Error: ", ex);
    NotificationsService.error("Khoá mã khuyến mại thất bại");
  }
}

function* listen() {
  yield takeEvery(
    actions.type.LOAD_LIST_CUSTOMER_GROUP,
    saga_LoadListCustomerGroup
  );
  yield takeEvery(actions.type.LOAD_LIST_VOUCHER, saga_LoadListVoucher);
  yield takeEvery(actions.type.UPDATE_PAGE_INDEX, saga_UpdatePageIndex);
  yield takeEvery(actions.type.SEARCH_VOUCHER, saga_SearchVoucher);

  yield takeEvery(actions.type.SAVE_CURRENT_VOUCHER, saga_SaveCurrentVoucher);
  yield takeEvery(actions.type.ACTIVE_VOUCHER, saga_ActiveVoucher);
  yield takeEvery(actions.type.DEACTIVE_VOUCHER, saga_DeactiveVoucher);
}

export default function* mainSaga() {
  yield all([fork(listen)]);
}
