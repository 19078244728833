import { all, takeEvery, fork, put, select } from "redux-saga/effects";
import actions from './actions';

import { history } from "../store";
// helper
import NotifycationServices from "../../../services/utils/notifycationHelper";

// const
import Router_Links from "../../../const/router_link";

//api
import RequestChangeCustomerGroupApiServices from '../../../services/api/admin_request_change_customer_group';
import GroupCustomerApiServices from '../../../services/api/customer_group';

// type status
import STATUS_REQUEST from '../../../const/status_request_change_customer_group';

function* saga_LoadListRequestChangeCustomerGroup(action) {
    try {
        // params
        let params = action.payload.params;

        let _state = yield select(state => state.admin_request_change_customer_group);
        let pageIndex = _state.pageIndex;
        let pageSize = _state.pageSize;

        if (params.pageIndex) pageIndex = params.pageIndex;
        if (params.pageSize) pageSize = params.pageSize;

        let params_request = [
            {
                name: "filters[groupCustomerId]",
                value: _state.groupCustomerId,
            },
            {
                name: "filters[status]",
                value: _state.status,
            },
        ];

        // call api
        let res = yield RequestChangeCustomerGroupApiServices.getListRequestChangeCustomerGroup(pageIndex, pageSize, params_request);

        if (res.code === 200) {
            let request_change_customer_groups = res.data.items;
            // request_change_customer_groups =  request_change_customer_groups.filter(request => request.status === STATUS_REQUEST.PROCESS);
            let total = res.data.total;

            yield put(actions.action.updateState(total));

            yield put(actions.action.loadListRequestChangeCustomerGroupSuccess(request_change_customer_groups));
        }
    } catch (ex) {
        console.log("[admin_request_change_customer_group] saga_LoadListRequestChangeCustomerGroup Error: ", ex);
    }
}

function* saga_UpdateStatusRequestChangeCustomerGroup(action) {
    try {
        let id = action.payload.id;
        let status = action.payload.status;

        // call api
        let res = yield RequestChangeCustomerGroupApiServices.updateStatusRequestChangeCustomerGroup(id, status);
        if (res.code === 200) {
            yield put(actions.action.loadListRequestChangeCustomerGroup());

            yield history.push(Router_Links.A_REQUEST_CHANGE_CUSTOMER_GROUP);
            NotifycationServices.success('Xác nhận yêu cầu thành công');
        } else {
            NotifycationServices.error('Xác nhận yêu cầu thất bại');
        }
    } catch(ex) {
        console.log("[admin_request_change_customer_group] saga_UpdateStatusRequestChangeCustomerGroup Error: ", ex);
        NotifycationServices.error('Xác nhận yêu cầu thất bại');
    }
}

function* saga_UpdatePageIndex(action) {
    try {
        let pageIndex = action.payload.pageIndex;
        yield put(actions.action.loadListRequestChangeCustomerGroup({ 
            pageIndex 
        }))
    } catch (ex) {
        console.log("[admin_request_change_customer_group] saga_UpdatePageIndex Error: ", ex);
    }
}

function* saga_CancelRequestChangeCustomerGroup(action) {
    try {
        let id = action.payload.id;

        //call api
        let res = yield RequestChangeCustomerGroupApiServices.cancelRequestChangeCustomerGroup(id);
        if (res.code === 200) {
            yield put(actions.action.loadListRequestChangeCustomerGroup());

            yield history.push(Router_Links.A_REQUEST_CHANGE_CUSTOMER_GROUP);
            NotifycationServices.success('Hủy yêu cầu thành công');
        } else {
            NotifycationServices.error('Hủy yêu cầu thất bại');
        }
    } catch(ex) {
        console.log("[admin_request_change_customer_group] saga_CancelRequestChangeCustomerGroup Error: ", ex);
        NotifycationServices.error('Hủy yêu cầu thất bại');
    }
}

function* saga_LoadDetailRequestChangeCustomerGroup(action) {
    try {
        let id = action.payload.id;
        //call api
        let res = yield RequestChangeCustomerGroupApiServices.loadDetailRequestChangeCustomerGroup(id);
        if (res.code === 200) {
            let detail_request_change_customer_group = res.data;
            yield put(actions.action.loadDetailRequestChangeCustomerGroupSuccess(detail_request_change_customer_group));
        }
    } catch(ex) {
        console.log("[admin_request_change_customer_group] saga_LoadDetailRequestChangeCustomerGroup Error: ", ex);
    }
}

function* saga_LoadListGroupCustomer() {
    try {
        let res = yield GroupCustomerApiServices.getAllListCustomerGroup();
        if (res.code === 200) {
            const groupCustomers = res.data
            yield put(actions.action.loadListGroupCustomerSuccess(groupCustomers));
        }
    } catch(ex) {
        console.log("[admin_request_change_customer_group] saga_LoadListGroupCustomer Error: ", ex);
    }
}

function* listen() {
    yield takeEvery(actions.type.LOAD_LIST_REQUEST_CHANGE_CUSTOMER_GROUP, saga_LoadListRequestChangeCustomerGroup);
    yield takeEvery(actions.type.UPDATE_PAGE_INDEX, saga_UpdatePageIndex);
    yield takeEvery(actions.type.UPDATE_STATUS_REQUEST_CHANGE_CUSTOMER_GROUP, saga_UpdateStatusRequestChangeCustomerGroup);
    yield takeEvery(actions.type.CANCEL_REQUEST_CHANGE_CUSTOMER_GROUP, saga_CancelRequestChangeCustomerGroup);
    yield takeEvery(actions.type.LOAD_DETAIL_REQUEST_CHANGE_CUSTOMER_GROUP, saga_LoadDetailRequestChangeCustomerGroup);
    yield takeEvery(actions.type.LOAD_LIST_GROUP_CUSTOMER, saga_LoadListGroupCustomer);
}

export default function* mainSaga() {
    yield all([fork(listen)]);
}