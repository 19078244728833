import moment from "moment";
import React from "react";
import { FaSave, FaTimes } from "react-icons/fa";
import Button from "../../../../components/button";
import Modal from "../../../../components/modal";
import { formatPrice, isEmpty } from "../../../../helpers/text.helper";
import NotificationsService from "../../../../services/utils/notifycationHelper";
import { apiUpdateCadoxuOrPointUser } from "./admin-user.service";
import "./index.scss";

export default class ModalUpPointAndXuInUser extends React.PureComponent {
  title = "";
  content = "";
  cadoxu = "";
  point = "";
  reason = "";

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  open = () => {
    this.setState({ isOpen: true });
  };

  onClose = () => {
    this.setState({ isOpen: false });
  };

  onChangeValue = (key) => (value) => {
    this[key] = value.target.value;
  };

  onChangeStartDate = (date) => {
    this.dateSelect = moment(date).format("YYYY/MM/DD");
  };

  onChangeStartTime = (time) => {
    this.timeSelect = moment(time).format("HH:mm");
  };

  onAdd = async () => {
    const params = {
      cadoxu: Number(this.cadoxu),
      point: Number(this.point),
      title: this.title,
      content: this.content,
      reason: this.reason,
    };

    if (Number(params.cadoxu) == 0 && Number(params.point) == 0) {
      NotificationsService.error(
        "Vui lòng nhập số điểm hoặc số xu cần cộng/trừ cho người dùng"
      );
      return;
    }

    if (isEmpty(params.title)) {
      NotificationsService.error("Tiêu đề thông báo không được để trống");
      return;
    }

    if (isEmpty(params.content)) {
      NotificationsService.error("Nội dung bắn thông báo không được để trống");
      return;
    }

    if (isEmpty(params.reason)) {
      NotificationsService.error("Lý do không được để trống");
      return;
    }
    this.onClose();

    const res = await apiUpdateCadoxuOrPointUser(this.props.user._id, params);

    if (res.code === 200) {
      window.location.reload();
      NotificationsService.success("Cập nhật điểm cho người dùng thành công");
    } else {
      NotificationsService.error("Cập nhật điểm cho người dùng thất bại");
    }
  };

  render() {
    const { isOpen } = this.state;

    const { user } = this.props;

    if (!this.state.isOpen || !user) return null;

    return (
      <Modal
        isOpen={isOpen}
        displayCloseIcon={false}
        setIsOpen={this.onClose}
        modalStyle={{
          width: "30%",
          maxHeight: "70%",
        }}
      >
        <div className="ModalAddLevelUser">
          <div className="MALU_Label">Cộng xu và điểm cho: {user.fullName}</div>

          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ flex: 1, marginRight: 10 }}>
              <label
                className="MALUF_Label"
                style={{ color: "orange", marginBottom: -10 }}
              >
                Số xu hiện tại: {formatPrice(Math.floor(user.cadoxu || 0))}
              </label>

              <br />

              <label className="MALUF_Label">Số xu sẽ cộng</label>
              <input
                type="text"
                className="MALUF_Value"
                onChange={this.onChangeValue("cadoxu")}
                placeholder="Nhập vào số xu"
                maxLength={30}
              />
            </div>
            <div style={{ flex: 1 }}>
              <label
                className="MALUF_Label"
                style={{ color: "orange", marginBottom: -10 }}
              >
                Số điểm hiện tại: {formatPrice(Math.floor(user.point || 0))}
              </label>

              <br />

              <label className="MALUF_Label">Số điểm sẽ cộng</label>
              <input
                type="text"
                className="MALUF_Value"
                onChange={this.onChangeValue("point")}
                placeholder="Nhập vào số điểm"
                maxLength={30}
              />
            </div>
          </div>

          <div>
            <label className="MALUF_Label">Tiêu đề thông báo</label>
            <input
              type="text"
              className="MALUF_Value"
              onChange={this.onChangeValue("title")}
              placeholder="VD: Chúc mừng bạn hoặc bạn bị trừ xu vì lý do...."
            />
          </div>

          <div>
            <label className="MALUF_Label">Nội dung bắn thông báo</label>
            <input
              type="text"
              className="MALUF_Value"
              onChange={this.onChangeValue("content")}
              placeholder="Nhập vào nội dung"
            />
          </div>

          <div>
            <label className="MALUF_Label">Lý do cộng xu - điểm</label>
            <input
              type="text"
              className="MALUF_Value"
              onChange={this.onChangeValue("reason")}
              placeholder="Nhập vào lý do"
            />
          </div>

          <div className="d-flex justify-content-center MALUF_Action">
            <Button
              label="Áp Dụng"
              icon={<FaSave style={{ marginRight: "4px" }} />}
              className="MALUF_Button"
              onClick={this.onAdd}
            />
            <Button
              label="Hủy"
              icon={<FaTimes style={{ marginRight: "4px" }} />}
              className="MALUF_Button_Cancel"
              onClick={this.onClose}
            />
          </div>
        </div>
      </Modal>
    );
  }
}
