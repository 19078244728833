import actions from "./actions";

// helper 
import ListHelperServices from '../../../services/utils/listHelper'

export const SORT_MODE_CONST = {
    NONE: 1,
    SORT_DESC_NAME: 2,
    SORT_ASC_NAME: 3,
    SORT_DESC_PRICE: 4,
    SORT_ASC_PRICE: 5,
};

const initState = {
    // filter
    products: [],
    isLoadingProductList: false,
    total: 0,
    pageIndex: 1,
    pageSize: 16,

    categorys: [],
    units: [],

    lastTextSearch: "",
    textSearch: "",
    sortMode: SORT_MODE_CONST.SORT_ASC_NAME,

    filter_categorys: [],
    filter_units: [],
    lowPrice: undefined,
    highPrice: undefined
};

const reducer = (state = initState, action) => {
    let payload = action.payload;

    switch (action.type) {
        case actions.type.UPDATE_STATE:
            return {
                ...state,
                ...payload.state,
            };

        // filter
        case actions.type.LOAD_LIST_PRODUCT:
            return {
                ...state,
                ...{},
                isLoadingProductList: true,
            };

        case actions.type.LOAD_LIST_PRODUCT_SUCCESS:
            return {
                ...state,
                ...{
                    products: payload.products,
                    isLoadingProductList: false,
                },
            };
        case actions.type.LOAD_LIST_PRODUCT_FAILED:
            return {
                ...state,
                isLoadingProductList: false
            }

        case actions.type.LOAD_LIST_CATEGORY:
            return state;

        case actions.type.LOAD_LIST_CATEGORY_SUCCESS:
            return {
                ...state,
                ...{
                    categorys: payload.categorys,
                },
            };

        case actions.type.LOAD_LIST_UNIT:
            return state;

        case actions.type.LOAD_LIST_UNIT_SUCCESS:
            return {
                ...state,
                ...{
                    units: payload.units,
                },
            };

        case actions.type.UPDATE_PAGE_INDEX:
            return {
                ...state,
                ...{
                    pageIndex: payload.pageIndex,
                },
            };

        case actions.type.UPDATE_TEXT_SEARCH:
            return {
                ...state,
                ...{
                    textSearch: payload.textSearch,
                },
            };

        case actions.type.SEARCH_PRODUCT:
            return {
                ...state,
                ...{
                    lastTextSearch: state.textSearch,
                    pageIndex: 1,
                },
            };

        // filter array 
        case actions.type.ADD_FILTER_CATEGORY:
            return {
                ...state,
                ...{
                    filter_categorys: ListHelperServices.addItemToArray(
                        state.filter_categorys,
                        payload.category
                    )
                }
            } 

        case actions.type.REMOVE_FILTER_CATEGORY:
            return {
                ...state,
                ...{
                    filter_categorys: ListHelperServices.removeItemFromArray(
                        state.filter_categorys,
                        payload.category
                    )
                }
            } 

        case actions.type.ADD_FILTER_UNIT:
            return {
                ...state,
                ...{
                    filter_units: ListHelperServices.addItemToArray(
                        state.filter_units,
                        payload.unit
                    )
                }
            }

        case actions.type.REMOVE_FILTER_UNIT:
            return {
                ...state,
                ...{
                    filter_units: ListHelperServices.removeItemFromArray(
                        state.filter_units,
                        payload.unit
                    )
                }
            } 

        case actions.type.FILTER_PRODUCT:
            return state 

        default:
            return state;
    }
};

export default reducer;
