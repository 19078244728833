/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { Component } from 'react'

// react-router
import { withRouter } from 'react-router-dom'

// redux
import { connect } from 'react-redux'
import actions from '../../../../redux/admin_post/actions'

// component 
import RichTextBoxEditor from '../../../../../components/richTextBoxEditor'
// import RichTextBoxDisplayValue from '../../../../components/richTextBoxEditor/displayRichTextBoxValue'
import { Switch, Select } from 'antd';

import { FaUpload } from 'react-icons/fa'
import { getImageUrl } from '../../../../../services/api/upload';
import TagInput from './tag_input'

const Option = Select.Option

const post = {
    title: "12 đặc sản Đà Lạt nên mua về làm quà 2020",
    description: "12 đặc sản Đà Lạt nên mua về làm quà 2020",
    categoryName: "Hoa quả tươi",
    userCreated: "Pham Tiến Nam",
    thumbnailUrl: "/static/1-1604949163727-284323098.jpg",
    status: false,
    _id: "5f58d249917b0f1758269990",
    content: "12 đặc sản Đà Lạt nên mua về làm quà 2020",
    categoryId: "5f48b187c053e917e501bf9c",
    userId: "5f4cc1d81ce7115cde3f922e",
    createdAt: "2020-09-09T01:02:01.000Z",
    __v: 0,
    updatedAt: "2020-09-09T13:10:05.949Z"
}

class InfoPanel extends Component {

    constructor(props) {
        super(props)

        this.state = {
            _category_select: 0,
            url: ""
        }
    }

    handleImageChange(e) {
        e.preventDefault();
        let file = e.target.files[0];
        let reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onloadend = () => {
            this.setState({
                file: file,
                url: reader.result
            });

            this.props.updateCurrentPost({
                thumbnailUrl: reader.result,
                thumbnail_file: file
            })
        };
    }

    render() {
        const post = this.props.post
        const { category_collections, categorys, current_category, current_category_collection } = this.props

        // let postId = this.props.match.params.postId ? this.props.match.params.postId : "zind"

        return (
            <div className="InfoPanel">
                {/* <div className="row">
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-12">
                        <div className="Title">
                            Đề mục
                        </div>
                    </div>
                    <div className="col-xl-10 col-lg-9 col-md-8 col-sm-12">
                        <div className="adminContentComponent">
                            <Select
                                value={current_category_collection}
                                style={{ marginTop: 5, maxWidth: 300 }}
                                onChange={(value) => {
                                    this.props.updateCurrentCategoryCollection(value)
                                }}
                            >
                                {
                                    category_collections.map((category_collection, index) => {
                                        return (
                                            <Option value={category_collection._id}>
                                                {
                                                    category_collection.name
                                                }
                                            </Option>
                                        )
                                    })
                                }
                            </Select>
                        </div>
                    </div>
                </div>*/}

                <div className="row">
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-12">
                        <div className="Title">
                            Danh mục
                        </div>
                    </div>
                    <div className="col-xl-10 col-lg-9 col-md-8 col-sm-12">
                        <div className="adminContentComponent">
                            <Select
                                value={post.categoryId}
                                style={{ marginTop: 5, maxWidth: 300 }}
                                onChange={(value) => {
                                    this.props.updateCurrentPost({ categoryId: value })
                                }}
                            >
                                {
                                    categorys.map((category, index) => {
                                        return (
                                            <Option value={category._id}>
                                                {
                                                    category.description
                                                }
                                            </Option>
                                        )
                                    })
                                }
                            </Select>
                        </div>
                    </div>
                </div> 

                <div className="row">
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-12">
                        <div className="Title">
                            Tiêu đề
                        </div>
                    </div>
                    <div className="col-xl-10 col-lg-9 col-md-8 col-sm-12">
                        <div className="adminContentComponent">
                            <textarea
                                type="text"
                                value={post.title}
                                className="Input"
                                onChange={(e) => {
                                    this.props.updateCurrentPost({
                                        title: e.target.value
                                    })
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-12">
                        <div className="Title">
                            Tiêu đề phụ
                        </div>
                    </div>
                    <div className="col-xl-10 col-lg-9 col-md-8 col-sm-12">
                        <div className="adminContentComponent">
                            <textarea
                                type="text"
                                value={post.description}
                                className="Input"
                                onChange={(e) => {
                                    this.props.updateCurrentPost({
                                        description: e.target.value
                                    })
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-12">
                        <div className="Title">
                            Hình đại diện
                        </div>
                    </div>
                    <div className="col-xl-10 col-lg-9 col-md-8 col-sm-12">
                        <div className="adminContentComponent">
                            {
                                post.thumbnailUrl !== ""
                                &&
                                <img
                                    src={ getImageUrl(post.thumbnailUrl)}
                                    className="image"
                                    alt="image"
                                />
                            }
                            <input
                                type="file"
                                name="file"
                                id="file"
                                class="hide"
                                onChange={(e) => this.handleImageChange(e)}
                            />

                            <div className="row" style={{ marginLeft: 0 }}>
                                <label className="Button" for="file">
                                    <FaUpload
                                        className="Icon"
                                    />
                                    Chọn hình đại diện
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-12">
                        <div className="Title">
                            Nội dung
                        </div>
                    </div>
                    <div className="col-xl-10 col-lg-9 col-md-8 col-sm-12">
                        <div className="adminContentComponent">
                            <RichTextBoxEditor
                                value={post.content}
                                onChange={(value) => {
                                    this.props.updateCurrentPost({
                                        content: value
                                    })
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-12">
                        <div className="Title">
                            Tags
                        </div>
                    </div>
                    <div className="col-xl-10 col-lg-9 col-md-8 col-sm-12">
                        <TagInput />
                    </div>
                </div>

                {/* <div className="row">
                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-12">
                        <div className="Title">
                            Công khai
                        </div>
                    </div>
                    <div className="col-xl-10 col-lg-9 col-md-8 col-sm-12">
                        <div className="adminContentComponent">
                            <div className="row" style={{ marginLeft: 0 }}>
                                <Switch
                                    checked={post.status}
                                    onChange={(value) => {
                                        this.props.updateCurrentPost({
                                            is_publish: value
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div> */}

            </div>
        )
    }
}

const mapStateToProps = state => {
    let _state = state.admin_post

    const {
        categorys,
        detail_post
    }
        = _state

    return {
        categorys,
        post: detail_post
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateCurrentCategoryCollection: entity => {
            // dispatch(actions.action.updateCurrentCategoryCollection(entity))
        },
        updateCurrentCategory: entity => {
            // dispatch(actions.action.updateCurrentCategory(entity))
        },
        updateCurrentPost: info => {
            dispatch(actions.action.updateCurrentPost(info))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InfoPanel))