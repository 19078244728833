import React, { PureComponent } from "react";
import DetailBillPage from "../../../admin_views/list_order/order_detail_page";

export default class UserManagerWareHouseDetailOrder extends PureComponent {
  orderId = this.props.match.params.id ? this.props.match.params.id : "";
  filters = this.props.location.state || undefined;

  render() {
    if (!this.orderId || !this.filters || !this.filters.inWareHouseIds) {
      // Nếu không phải là chủ kho hàng thì sẽ không được phép truy cập đơn này
      return (
        <div style={{ padding: 10 }}>Bạn không có quyền truy cập trang này</div>
      );
    }

    return (
      <div
        style={{
          width: "100%",
          paddingRight: 10,
          backgroundColor: "#f2f2f2",
        }}
      >
        <DetailBillPage orderId={this.orderId} filters={this.filters} />
      </div>
    );
  }
}
