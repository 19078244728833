import React, { Component } from 'react'

// route
import { Redirect, Switch, Router } from 'react-router-dom'

// scss
import './index.scss'

// redux
import { history, store } from './redux/store'
import { Provider } from 'react-redux'
import Boot from './redux/boot'

// lib
import ReactNotification from 'react-notifications-component'

import ScrollToTop from '../components/scrollToTop'

// sub site
import UserSite from './routers/user_site'
import AdminSite from './routers/admin_site'

// action redux 
import AuthActions from './redux/auth/actions'
import UserGlobalActions from './redux/user_global/actions'

class App extends Component {

    componentDidMount = () => {
        store.dispatch(AuthActions.action.getUserInfo())
        store.dispatch(UserGlobalActions.action.loadListCartItem())
    }

    render() {

        return (
            <Provider store={store}>
                <Router history={history}>
                    <ScrollToTop>
                        <div id="app">
                            <Switch >
                                <AdminSite path="/admin" />
                                <UserSite path="" />
                                <Redirect to="" />
                            </Switch>
                        </div>
                    </ScrollToTop>
                </Router>
                <ReactNotification />
            </Provider>
        )
    }
}

Boot()
    .then(() => App())
    .catch(error => console.log('render error : ', error))

export default App;