import React, { Component } from 'react'
import './index.scss'

// router
import { Link } from 'react-router-dom'

class PageNotFound extends Component {
    
    render(){
        return (
            <div>
                Không tìm thấy trang

                <Link to="/admin/app/home">
                    <div>
                        Về trang chủ
                    </div>
                </Link>
            </div>
        )
    }
}

export default PageNotFound