import React, { Component } from 'react'
// scss
import './index.scss'
// redux
import { connect } from 'react-redux'
import { getImageUrl } from '../../../../../services/api/upload'
import Button from '../../../../../components/button'
import { FaAngleLeft, FaEdit, FaPen, FaPlusCircle, FaTimes, FaTrash } from 'react-icons/fa'
import AdminFlashSaleActions from '../../../../redux/admin_flash_sale/actions'
import { numberDisplayThousand } from '../../../../../services/utils/helper'
import ModalUpdateProductFlashSale from './modal_update_product_flash_sale'
import { Link } from 'react-router-dom'
import Router_Links from '../../../../../const/router_link'

const list_flexs = [5, 25, 10, 10, 10, 15, 30];

class FlashSaleDetailPage extends Component {

    componentDidMount() {
        const id = this.props.match.params.id ? this.props.match.params.id : ''
        if (id && typeof this.props.init === 'function') {
            this.props.init(id)
        }
    }

    onClickButtonRemoveProduct = (id) => {
        if(typeof this.props.removeProductFlashSale === 'function') {
            this.props.removeProductFlashSale(id)
        }
    }

    onClickButtonEditProduct = (name, id,  currentProduct) => {
        if (typeof this.props.setModalUpdateProductFlashSaleState === 'function') {
            this.props.setModalUpdateProductFlashSaleState(true, name, id, currentProduct)
        }
    }

    onClickButtonRemoveAll = () => {
        const id = this.props.match.params.id ? this.props.match.params.id : ''
        if (id) {
            this.props.removeAllProductFlashSale(id)
        }
    }

    render() {
        const { flashSalesDetail } = this.props
        return (
            <div className="FlashSaleDetailPage">
                <div className="d-flex FSDP_Actions">
                    <Button
                        className="FSDP_ButtonBack"
                        label="Quay lại"
                        icon={<FaAngleLeft style={{ marginRight: 4 }} />}
                        onClick={this.props.history.goBack}
                    />
                </div>
                <div className="d-flex FSDP_Actions">
                    <Button
                        className="FSDP_ButtonAdd"
                        label="Thêm sản phẩm"
                        icon={<FaPlusCircle style={{ marginRight: 4 }} />}
                        onClick={() => {
                            this.props.history.push(
                                Router_Links.A_FLASH_SALE_DETAIL_ADD_PRODUCT
                            );
                        }}
                    />
                    <Button
                        className="FSDP_ButtonDeleteAll"
                        label="Xóa tất cả sản phẩm"
                        icon={<FaTrash style={{ marginRight: 4 }} />}
                        onClick={this.onClickButtonRemoveAll}
                    />
                </div>
                <table className="CDS_Table" style={{}}>
                    <tr className="CDS_TableRowHeader">
                        <th
                            className="CDS_HeaderText"
                            style={{
                                flex: list_flexs[0],
                                marginRight: 10,
                            }}
                        >
                            STT
                    </th>
                        <th
                            className="CDS_HeaderText"
                            style={{
                                flex: list_flexs[1],
                                justifyContent: "left",
                            }}
                        >
                            Sản phẩm
                    </th>
                    <th
                            className="CDS_HeaderText superMiniHide"
                            style={{ flex: list_flexs[2] }}
                        >
                            Giá Sale
                    </th>
                    <th
                            className="CDS_HeaderText superMiniHide"
                            style={{ flex: list_flexs[3] }}
                        >
                            Số lượng
                    </th>
                    <th
                            className="CDS_HeaderText superMiniHide"
                            style={{ flex: list_flexs[4] }}
                        >
                            Số lượng đã bán
                    </th>
                        <th
                            className="CDS_HeaderText miniHide"
                            style={{ flex: list_flexs[5] }}
                        >
                            Loại sản phẩm
                    </th>
                        <th
                            className="CDS_HeaderText"
                            style={{ flex: list_flexs[6] }}
                        >
                            Hành động
                    </th>
                    </tr>
                    {flashSalesDetail.map((flashSale, index) => (
                        flashSale.product && (<tr
                            key={flashSale._id}
                            className="CDS_TableRow"
                            style={{
                                borderBottom:
                                    index === flashSalesDetail.length - 1
                                        ? "none"
                                        : "1px solid #e2e2e2",
                            }}
                        >
                            <td
                                className="CDS_TableCell"
                                style={{
                                    flex: list_flexs[0],
                                    marginRight: 10,
                                }}
                            >
                                {index + 1}
                            </td>
                            <td
                                className="CDS_TableCell"
                                style={{
                                    flex: list_flexs[1],
                                    justifyContent: "left",
                                }}
                            >
                                <img
                                    style={{
                                        height: 60,
                                        width: 60,
                                        objectFit: "contain",
                                        marginRight: 10,
                                        border: "1px solid #f2f2f2",
                                    }}
                                    src={getImageUrl(flashSale.product.thumbnail)}
                                    alt="thumbnail"
                                />
                                {flashSale.product.name}
                            </td>
                            <td
                                className="CDS_TableCell superMiniHide"
                                style={{ flex: list_flexs[2] }}
                            >
                                {numberDisplayThousand(flashSale.price)}
                            </td>
                            <td
                                className="CDS_TableCell superMiniHide"
                                style={{ flex: list_flexs[3] }}
                            >
                                {numberDisplayThousand(flashSale.totalQuantity)}
                            </td>
                            <td
                                className="CDS_TableCell"
                                style={{ flex: list_flexs[5] }}
                            >
                                {numberDisplayThousand(flashSale.totalQuantitySold)}
                            </td>
                            {/* <td
                                className="CDS_TableCell miniHide"
                                style={{ flex: list_flexs[4] }}
                            >
                                {flashSale.product.isValueAddedProduct
                                        ? "Sản phầm giá trị tích điểm"
                                        : "Sản phẩm giá trị cốt lõi"}
                            </td> */}
                            <td
                                className="CDS_TableCell"
                                style={{ flex: list_flexs[6] }}
                            >
                                <Button
                                    className="FSDP_Table_Action_Edit"
                                    label="Sửa"
                                    icon={<FaEdit style={{ marginRight: 4 }} />}
                                    onClick={() => this.onClickButtonEditProduct(flashSale.product.name, flashSale._id, {
                                        totalQuantity: flashSale.totalQuantity,
                                        discount: flashSale.discount
                                    })}
                                />
                                <Button
                                    className="FSDP_Table_Action_Delete"
                                    label="Xóa"
                                    icon={<FaTrash style={{ marginRight: 4 }} />}
                                    onClick={() => this.onClickButtonRemoveProduct(flashSale._id)}
                                />
                            </td>
                        </tr>)
                    ))}
                </table>

                <ModalUpdateProductFlashSale />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const _state = state.admin_flash_sale
    return {
        flashSalesDetail: _state.flashSalesDetail
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        init: (id) => {
            dispatch(AdminFlashSaleActions.action.loadListFlashSaleDetail(id))
        },
        removeProductFlashSale: (id) => {
            dispatch(AdminFlashSaleActions.action.removeProductFlashSale(id))
        },
        setModalUpdateProductFlashSaleState: (state, name, id, currentProduct) => {
            dispatch(AdminFlashSaleActions.action.setModalUpdateProductFlashSaleState(state, name, id, currentProduct))
        },
        removeAllProductFlashSale: (id) => {
            dispatch(AdminFlashSaleActions.action.doRemoveAllProductFlashSale(id))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FlashSaleDetailPage)