const prefix = "admin_category/";

const type = {
  UPDATE_STATE: prefix + "update_state",

  // category
  LOAD_LIST_CATEGORY: prefix + "load_list_category",
  LOAD_LIST_CATEGORY_SUCCESS: prefix + "load_list_category_success",

  // detail category
  LOAD_DETAIL_CATEGORY: prefix + "load_detail_category",
  LOAD_DETAIL_CATEGORY_SUCCESS: prefix + "load_detail_category_success",
  UPDATE_CURRENT_CATEGORY: prefix + "update_current_category",

  // all category
  LOAD_ALL_CATEGORY: prefix + "load_all_category",
  LOAD_ALL_CATEGORY_SUCCESS: prefix + "load_all_category_success",

  SAVE_CURRENT_CATEGORY: prefix + "save_current_category",
  REMOVE_CURRENT_CATEGORY: prefix + "delete_current_category",

  UPDATE_NEW_CATEGORY: prefix + "update_new_category",
  CREATE_NEW_CATEGORY: prefix + "create_new_category",
  SET_MODAL_CREATE_NEW_CATEGORY_STATE:
    prefix + "set_modal_create_new_category_state",
};

const action = {
  updateState: (state) => {
    return {
      type: type.UPDATE_STATE,
      payload: {
        state,
      },
    };
  },

  // category
  loadListCategory: (params = {}) => {
    return {
      type: type.LOAD_LIST_CATEGORY,
      payload: {
        params,
      },
    };
  },
  loadListCategorySuccess: (categories = []) => {
    return {
      type: type.LOAD_LIST_CATEGORY_SUCCESS,
      payload: {
        categories,
      },
    };
  },

  // detail category
  loadDetailCategory: (id) => {
    return {
      type: type.LOAD_DETAIL_CATEGORY,
      payload: {
        id,
      },
    };
  },

  loadDetailCategorySuccess: (category) => {
    return {
      type: type.LOAD_DETAIL_CATEGORY_SUCCESS,
      payload: {
        category,
      },
    };
  },

  // all category
  loadAllCategory: () => {
    return {
      type: type.LOAD_ALL_CATEGORY,
      payload: {},
    };
  },

  loadAllCategorySuccess: (allCategory = []) => {
    return {
      type: type.LOAD_ALL_CATEGORY_SUCCESS,
      payload: {
        allCategory,
      },
    };
  },

  updateCurrentCategory: (category) => {
    return {
      type: type.UPDATE_CURRENT_CATEGORY,
      payload: {
        category,
      },
    };
  },

  saveCurrentCategory: () => {
    return {
      type: type.SAVE_CURRENT_CATEGORY,
      payload: {},
    };
  },

  removeCurrentCategory: (id) => {
    return {
      type: type.REMOVE_CURRENT_CATEGORY,
      payload: {
        id,
      },
    };
  },

  updateNewCategory: (newCategory) => {
    return {
      type: type.UPDATE_NEW_CATEGORY,
      payload: {
        newCategory,
      },
    };
  },

  setModalCreateNewCategoryState: (state, parentId = null) => {
    return {
      type: type.SET_MODAL_CREATE_NEW_CATEGORY_STATE,
      payload: {
        state,
        parentId,
      },
    };
  },

  createNewCategory: () => {
    return {
      type: type.CREATE_NEW_CATEGORY,
      payload: {},
    };
  },
};

export default {
  type,
  action,
};
