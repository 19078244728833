import React, { Component, createRef } from 'react'

// component
import Modal from '../../../../../components/modal'

// redux
import { connect } from 'react-redux'
import authActions from '../../../../redux/auth/actions'

// icons
import { FaSave, FaTimesCircle } from 'react-icons/fa'
import NotificationsService from '../../../../../services/utils/notifycationHelper'

class ModalChangePassword extends Component {

    constructor(props) {
        super(props)
        this.state = {
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",

            isErrorConfirmPassword: false,
            isErrorNewPassword: false,
            isErrorOldPassword: false
        }

        this.newPasswordRef = createRef(null);
        this.confirmPasswordRef = createRef(null);
        this.oldPasswordRef = createRef(null);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.modalChangePasswordAdmin !== this.props.modalChangePasswordAdmin && this.props.modalChangePasswordAdmin === false) {
            this.setState({
                oldPassword: "",
                newPassword: "",
                confirmPassword: ""
            })
        }
    }

    validateAll = () => {
        const { oldPassword, newPassword, confirmPassword } = this.state;
        if (oldPassword === "") {
            if (this.oldPasswordRef instanceof HTMLElement) {
                this.setState({ isErrorOldPassword: true });
                this.oldPasswordRef.focus();
                NotificationsService.error("Mật khẩu cũ không được để trống")
                return false
            }
        } else {
            this.setState({ isErrorOldPassword: false });
        }

        if (newPassword === "") {
            if (this.newPasswordRef instanceof HTMLElement) {
                this.setState({ isErrorNewPassword: true });
                this.newPasswordRef.focus();
                NotificationsService.error("Mật khẩu mới không được để trống")
                return false
            }
        } else {
            this.setState({ isErrorNewPassword: false });
        }

        if (confirmPassword !== newPassword) {
            if (this.confirmPasswordRef instanceof HTMLElement) {
                this.setState({ isErrorConfirmPassword: true })
                this.confirmPasswordRef.focus();
                NotificationsService.error("Xác nhận mật khẩu không chính xác")
            }
            return false;
        } else {
            this.setState({ isErrorConfirmPassword: false })
        }

        return true
    }

    onChangePassword = () => {
        if (this.validateAll()) {
            this.props.changePassword(this.state.oldPassword, this.state.newPassword, this.state.confirmPassword)
        }
    }

    render() {
        const { isErrorConfirmPassword, isErrorNewPassword, isErrorOldPassword } = this.state;
        let confirmPasswordClasses = ['input'];
        let newPasswordClasses = ['input'];
        let oldPasswordClasses = ['input'];

        if (isErrorConfirmPassword) {
            confirmPasswordClasses.push('invalid');
        }
        if (isErrorNewPassword) {
            newPasswordClasses.push('invalid');
        }
        if (isErrorOldPassword) {
            oldPasswordClasses.push('invalid');
        }
        return (
            <Modal
                {
                ...this.props
                }
            >
                <div className="ModalChangePassword">
                    <div className="RowInfo">
                        <div className="Title">
                            Mật khẩu cũ
                        </div>
                        <input
                            ref={r => this.oldPasswordRef = r}
                            className={oldPasswordClasses.join(' ')}
                            type="password"
                            value={this.state.oldPassword}
                            onChange={(e) => this.setState({ oldPassword: e.target.value })}
                        />
                    </div>

                    <div className="RowInfo">
                        <div className="Title">
                            Mật khẩu mới
                        </div>
                        <input
                            ref={r => this.newPasswordRef = r}
                            className={newPasswordClasses.join(' ')}
                            type="password"
                            value={this.state.newPassword}
                            onChange={(e) => this.setState({ newPassword: e.target.value })}
                        />
                    </div>

                    <div className="RowInfo"
                    >
                        <div className="Title">
                            Xác nhận mật khẩu mới
                        </div>
                        <input
                            ref={r => this.confirmPasswordRef = r}
                            className={confirmPasswordClasses.join(' ')}
                            type="password"
                            value={this.state.confirmPassword}
                            onChange={(e) => this.setState({ confirmPassword: e.target.value })}
                        />
                    </div>

                    <div className="ControlPanel">
                        <div className="Button"
                            onClick={this.onChangePassword}
                        >
                            <FaSave className="icon" />
                            Đổi
                            </div>
                        <div
                            className="Button"
                            style={{
                                backgroundColor: 'gray'
                            }}
                            onClick={() => this.props.setIsOpen(false)}
                        >
                            <FaTimesCircle className="icon" />
                            Huỷ
                            </div>
                    </div>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        modalChangePasswordAdmin: state.auth.modalChangePasswordAdmin
    }
}

const mapDispatchToProps = dispatch => {
    return {
        changePassword: (oldPassword, newPassword, confirmPassword) => {
            dispatch(authActions.action.changePassword(oldPassword, newPassword, confirmPassword))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalChangePassword)