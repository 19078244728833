import React, { Component } from "react";
// icon
import {
  FaEdit,
  FaPlusCircle,
  FaSave,
  FaSearch,
  FaTimes,
  FaTrash,
} from "react-icons/fa";
// redux
import { connect } from "react-redux";
import EmptySearch from "../../../../components/empty_search";
import Modal from "../../../../components/modal";
import ModalConfirmAction from "../../../../components/modal/modal_confirm_action";
// component
import Pagination from "../../../../components/paginations/paginations";
import module_app from "../../../../const/module_app";
import permission_action from "../../../../const/permission_action";
import CustomerGroupActions from "../../../redux/admin_customer_group/actions";
import { paramsPermisson } from "../../../routers/admin_site";
import AdminCheckPermissionComponent from "../components/admin-check-permission";
// scss
import "./index.scss";

const default_entity = {
  name: "",
  description: "",
  _id: 0,
};

const list_flexs = [5, 20, 20, 15];
class AdminCustomerGroupPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 30,
      pageSize: 10,
      pageIndex: 1,
      modalConfirmDeleteState: false,

      currentEntity: default_entity,
    };
    this.searchFlag = false;
  }

  // life circle
  componentDidMount = () => {
    this.props.init();
  };

  // render
  renderActionBar = () => {
    return (
      <div className="APL_FilterPanel">
        <div className="APL_FilterPanel">
          <AdminCheckPermissionComponent
            paramsPermission={paramsPermisson(
              module_app.MODULE_GROUP_CUSTOMER_MANAGEMENT,
              permission_action.INSERT
            )}
            noContent
          >
            <div
              className="APL_ButtonAdd"
              onClick={() => {
                this.props.setModalCurrentCustomerGroupState(true);
                this.props.setDefaultCurrentCustomerGroup();
              }}
            >
              <FaPlusCircle className="APL_ButtonAddIcon" />
              Thêm nhóm khách hàng
            </div>
          </AdminCheckPermissionComponent>
        </div>

        <div className="FilterSearchAndSort">
          <div className="APL_SearchBoxPanel">
            <input
              type="text"
              placeholder="Tìm kiếm"
              aria-label="Tim kiem"
              name="keyword"
              value={this.props.textSearch}
              onChange={(e) => {
                this.props.updateTextSearch(e.target.value);
              }}
              className="APL_SearchBox"
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  this.searchFlag = true;
                  this.props.searchCustomerGroup(this.props.textSearch);
                }
              }}
            />
            <FaSearch
              className="APL_SearchBoxIcon"
              onClick={() => {
                this.searchFlag = true;
                this.props.searchCustomerGroup(this.props.textSearch);
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  renderListCustomerGroup = () => {
    let { pageSize, total, pageIndex, customer_groups } = this.props;

    if (
      (Array.isArray(customer_groups) && customer_groups.length) ||
      !this.searchFlag
    ) {
      return (
        <table className="CDS_Table" style={{}}>
          <tr className="CDS_TableRowHeader">
            <th
              className="CDS_HeaderText"
              style={{
                flex: list_flexs[0],
                marginRight: 10,
              }}
            >
              STT
            </th>
            <th
              className="CDS_HeaderText"
              style={{
                flex: list_flexs[1],
              }}
            >
              Tên
            </th>
            <th
              className="CDS_HeaderText"
              style={{
                flex: list_flexs[2],
                width: 50,
              }}
            >
              Mô tả
            </th>

            <th className="CDS_HeaderText" style={{ flex: list_flexs[3] }}>
              Hành động
            </th>
          </tr>
          {customer_groups.map((entity, index) => {
            return (
              <tr
                className="CDS_TableRow"
                style={{
                  borderBottom:
                    index === customer_groups.length - 1
                      ? "none"
                      : "1px solid #e2e2e2",
                }}
              >
                <td
                  className="CDS_TableCell"
                  style={{
                    flex: list_flexs[0],
                    marginRight: 10,
                  }}
                >
                  {index + 1 + pageSize * (pageIndex - 1)}
                </td>
                <td
                  className="CDS_TableCell"
                  style={{
                    flex: list_flexs[1],
                  }}
                >
                  {entity.name}
                </td>
                <td
                  className="CDS_TableCell superMiniHide"
                  style={{ flex: list_flexs[2] }}
                >
                  {entity.description}
                </td>

                <td className="CDS_TableCell" style={{ flex: list_flexs[3] }}>
                  <AdminCheckPermissionComponent
                    paramsPermission={paramsPermisson(
                      module_app.MODULE_GROUP_CUSTOMER_MANAGEMENT,
                      permission_action.UPDATE
                    )}
                    noContent
                  >
                    <div
                      className="APL_Table_Action"
                      style={{
                        backgroundColor: "#00b33c",
                        color: "white",
                      }}
                      onClick={() => {
                        this.props.setModalCurrentCustomerGroupState(true);
                        this.props.setCurrentCustomerGroup(entity);
                      }}
                    >
                      <FaEdit className="APL_Table_Icon" />
                      <span className="superMiniHide">Sửa</span>
                    </div>
                  </AdminCheckPermissionComponent>

                  <AdminCheckPermissionComponent
                    paramsPermission={paramsPermisson(
                      module_app.MODULE_GROUP_CUSTOMER_MANAGEMENT,
                      permission_action.REMOVE
                    )}
                    noContent
                  >
                    <div
                      className="APL_Table_Action"
                      style={{
                        backgroundColor: "red",
                        color: "white",
                      }}
                      onClick={() => {
                        this.setState({
                          modalConfirmDeleteState: true,
                        });
                        this.props.setCurrentCustomerGroup(entity);
                      }}
                    >
                      <FaTrash className="APL_Table_Icon" />
                      <span className="superMiniHide">Xóa</span>
                    </div>
                  </AdminCheckPermissionComponent>
                </td>
              </tr>
            );
          })}
        </table>
      );
    } else {
      return <EmptySearch />;
    }
  };

  renderModalCurrentCustomerGroup = () => {
    const { customer_group } = this.props;

    return (
      <div className="ACG_ModalCurrentCustomerGroup">
        <div className="ACGM_Title">CHI TIẾT NHÓM KHÁCH HÀNG</div>

        <div className="ACGM_RowInfo">
          <div className="ACGM_RowTitle">Tên nhóm</div>
          <div className="ACGM_RowContent">
            <input
              className="ACGM_RowContent_Input"
              value={customer_group.name}
              onChange={(e) => {
                this.props.updateCurrentCustomerGroup({
                  name: e.target.value,
                });
              }}
            />
          </div>
        </div>

        <div className="ACGM_RowInfo">
          <div className="ACGM_RowTitle">Mô tả</div>
          <div className="ACGM_RowContent">
            <textarea
              className="ACGM_RowContent_Input"
              value={customer_group.description}
              onChange={(e) => {
                this.props.updateCurrentCustomerGroup({
                  description: e.target.value,
                });
              }}
            />
          </div>
        </div>

        <div className="ACGM_RowInfo_Action">
          <div
            className="ACGM_Buttonz"
            onClick={() => {
              this.props.saveCurrentCustomerGroup();
            }}
          >
            <FaSave className="ACGM_Buttonz_Icon" />
            Lưu
          </div>
          <div
            className="ACGM_Buttonz"
            onClick={() => {
              this.props.setModalCurrentCustomerGroupState(false);
            }}
            style={{
              backgroundColor: "red",
            }}
          >
            <FaTimes className="ACGM_Buttonz_Icon" />
            Hủy
          </div>
        </div>
      </div>
    );
  };

  render() {
    let { pageSize, total, pageIndex, customer_group } = this.props;

    return (
      <div className="AdminCustomerGroupPage">
        {this.renderActionBar()}

        {this.renderListCustomerGroup()}

        <div>
          <Pagination
            onChange={(index) => {
              this.props.updatePageIndex(index);
              // this.setState({ pageIndex: index })
            }}
            pageSize={pageSize}
            pageIndex={pageIndex}
            total={total}
          />
        </div>

        <Modal
          isOpen={this.props.modalCurrentCustomerGroupState}
          setIsOpen={(value) => {
            // this.props.setModalSelectUserState(value);
            this.props.setModalCurrentCustomerGroupState(value);
          }}
          displayCloseIcon={false}
        >
          {this.renderModalCurrentCustomerGroup()}
        </Modal>

        <ModalConfirmAction
          isOpen={this.state.modalConfirmDeleteState}
          setIsOpen={(value) =>
            this.setState({ modalConfirmDeleteState: value })
          }
          onOk={() => {
            this.setState({ modalConfirmDeleteState: false });
            this.props.deleteCustomerGroup(customer_group._id);
          }}
          onCancel={() => this.setState({ modalConfirmDeleteState: false })}
          text="Bạn chắc chắn xoá loại người dùng này?"
          displayCloseIcon={true}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const _state = state.admin_customer_group;

  return {
    pageSize: _state.pageSize,
    pageIndex: _state.pageIndex,
    total: _state.total,

    textSearch: _state.textSearch,
    customer_groups: _state.customer_groups,
    customer_group: _state.customer_group,
    modalCurrentCustomerGroupState: _state.modalCurrentCustomerGroupState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    init: () => {
      dispatch(CustomerGroupActions.action.loadListCustomerGroup({}));
    },

    updateCurrentCustomerGroup: (info) => {
      dispatch(CustomerGroupActions.action.updateCurrentCustomerGroup(info));
    },
    setDefaultCurrentCustomerGroup: () => {
      dispatch(CustomerGroupActions.action.setDefaultCustomerGroup());
    },
    setCurrentCustomerGroup: (customer_group) => {
      dispatch(
        CustomerGroupActions.action.setCurrentCustomerGroup(customer_group)
      );
    },
    saveCurrentCustomerGroup: () => {
      dispatch(CustomerGroupActions.action.saveCurrentCustomerGroup());
    },
    updateTextSearch: (textSearch) => {
      dispatch(CustomerGroupActions.action.updateTextSearch(textSearch));
    },
    searchCustomerGroup: () => {
      dispatch(CustomerGroupActions.action.searchCustomerGroup());
    },
    updatePageIndex: (pageIndex) => {
      dispatch(CustomerGroupActions.action.updatePageIndex(pageIndex));
    },
    deleteCustomerGroup: (id) => {
      dispatch(CustomerGroupActions.action.removeCustomerGroup(id));
    },
    setModalCurrentCustomerGroupState: (state) => {
      dispatch(
        CustomerGroupActions.action.setModalCurrentCustomerGroupState(state)
      );
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminCustomerGroupPage);
