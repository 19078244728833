const prefix = 'user_profile_history_transaction/'

const type = {
    UPDATE_STATE: prefix + 'update_state',

    LOAD_LIST_HISTORY_TRANSACTION: prefix + 'load_list_history_transaction',
    LOAD_LIST_HISTORY_TRANSACTION_SUCCESS: prefix + 'load_list_history_transaction_success',

    UPDATE_PAGE_INDEX: prefix + 'update_page_index'
}

const action = {
    updateState: state => {
        return {
            type: type.UPDATE_STATE,
            payload: {
                state
            }
        }
    },
    
    loadListHistoryTransaction: (params = {}) => {
        return {
            type: type.LOAD_LIST_HISTORY_TRANSACTION,
            payload: {
                params
            }
        }
    },
    loadListHistoryTransactionSuccess: transactions => {
        return {
            type: type.LOAD_LIST_HISTORY_TRANSACTION_SUCCESS,
            payload: {
                transactions
            }
        }
    },

    updatePageIndex: pageIndex => {
        return {
            type: type.UPDATE_PAGE_INDEX,
            payload: {
                pageIndex
            }
        }
    }
}

export default {
    type,
    action
}