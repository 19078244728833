import { Spin } from "antd";
import React, { Component } from "react";
import { FaCartPlus, FaSearch } from "react-icons/fa";
// redux
import { connect } from "react-redux";
// component
import Modal from "../../../../../components/modal";
import Pagination from "../../../../../components/paginations/paginations";
// helper
import { getImageUrl } from "../../../../../services/api/upload";
import { numberDisplayThousand } from "../../../../../services/utils/helper";
import AdminCreateOrderActions from "../../../../redux/admin_create_order/actions";
import "./index.scss";

class ModalSelectProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 10,
      pageIndex: 1,
      total: 30,
    };
  }

  // render
  renderProduct = (product) => {
    let discount = product.discount;
    if (discount < 0) discount = 0;
    if (discount > 100) discount = 100;

    let newPrice = (product.price / 100.0) * (100 - discount);
    let unitName = product.productUnit ? product.productUnit.description : "";

    return (
      <div
        className="col-xl-3 col-lg-4 col-md-4 col-sm-6"
        style={{
          paddingLeft: 0,
          paddingRight: 0,
        }}
      >
        <div
          onClick={() => {
            // history.push(
            //     `${Router_Links.U_DETAIL_PRODUCT}/${product._id}`
            // );

            if (this.props.onClickProduct) {
              this.props.onClickProduct(product);
              return;
            }

            this.props.addCartItem(product._id, 1);
          }}
          className="APL_ProductItem"
        >
          <img
            className="APL_ProductImage"
            src={getImageUrl(product.thumbnail)}
            alt="thumbnail"
          />

          <div className="APL_ProductAction">
            {/* <Link
                                            to={Router_Links.U_DETAIL_PRODUCT}
                                            className="APL_ProductActionPanel"
                                        >
                                            <FaInfo className="APL_ProductActionIcon" />
                                        </Link> */}
            <div
              // onClick={(e) => {
              //   if (this.props.onClickProduct) {
              //     this.props.onClickProduct(product);
              //     return;
              //   }

              //   e.stopPropagation();
              //   this.props.addCartItem(product._id, 1);
              // }}
              className="APL_ProductActionPanel"
            >
              <FaCartPlus className="APL_ProductActionIcon" />
            </div>
          </div>

          <div className="APL_ProductContent">
            <div className="APL_ProductName">{product.name}</div>
            <div className="APL_ProductPrice">
              {discount > 0 ? (
                <span>
                  <span className="APL_OldPrice">
                    {numberDisplayThousand(product.price)}đ
                  </span>
                  <span className="APL_NewPrice">
                    {numberDisplayThousand(newPrice)}đ
                  </span>
                </span>
              ) : (
                `${numberDisplayThousand(newPrice)}đ`
              )}
            </div>
          </div>

          {discount > 0 && (
            <div className="APL_ProdcutDiscount">{"-" + discount + "%"}</div>
          )}
        </div>
      </div>
    );
  };

  render() {
    let { modalSelectProductState } = this.props;
    let { pageSize, pageIndex, total, isLoading } = this.props;
    let products = this.props.list_products;

    return (
      <Modal
        isOpen={modalSelectProductState}
        setIsOpen={(value) => {
          this.props.setModalSelectProductState(value);
        }}
        displayCloseIcon={false}
      >
        <div className="ModalSelectProductz">
          <div className="MSPZ_Action">
            <div className="MSPZ_Title">Chọn sản phẩm</div>
            <div className="APL_SearchBoxPanel">
              <input
                type="text"
                placeholder="Tìm kiếm"
                aria-label="Tim kiem"
                name="keyword"
                value={this.props.textSearch}
                onChange={(e) => {
                  this.props.updateTextSearch(e.target.value);
                }}
                className="APL_SearchBox"
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    this.props.searchProduct(this.props.textSearch);
                  }
                }}
              />
              <FaSearch
                className="APL_SearchBoxIcon"
                onClick={() => {
                  this.props.searchProduct(this.props.textSearch);
                }}
              />
            </div>
          </div>
          {!isLoading ? (
            <>
              <div className="product-panel row">
                {products.map((product, index) => {
                  return this.renderProduct(product);
                })}
              </div>

              <div className="UFP_PaginateSection">
                <Pagination
                  onChange={(index) => {
                    this.props.updatePageIndex(index);
                    // this.setState({ pageIndex: index });
                  }}
                  pageSize={pageSize}
                  pageIndex={pageIndex}
                  total={total}
                />
              </div>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spin size="large" />
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const _state = state.admin_create_order;

  return {
    isLoading: _state.md_product_is_loading,
    modalSelectProductState: _state.modalSelectProductState,
    list_products: _state.list_products,
    total: _state.md_product_total,
    pageSize: _state.md_product_page_size,
    pageIndex: _state.md_product_page_index,
    textSearch: _state.md_product_text_search,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // modal
    setModalSelectProductState: (state) => {
      dispatch(
        AdminCreateOrderActions.action.setModalSelectProductState(state)
      );
    },

    // product
    updatePageIndex: (pageIndex) => {
      dispatch(
        AdminCreateOrderActions.action.mdProductUpdatePageIndex(pageIndex)
      );
    },
    updateTextSearch: (textSearch) => {
      dispatch(
        AdminCreateOrderActions.action.mdProductUpdateTextSearch(textSearch)
      );
    },
    searchProduct: () => {
      dispatch(AdminCreateOrderActions.action.mdProductSearchProduct());
    },
    addCartItem: (id = "", quantity = 1) => {
      dispatch(
        AdminCreateOrderActions.action.insertProductItemPrepare(id, quantity)
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalSelectProduct);
