import { Button, Input, List } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { PureComponent } from "react";
import { apiGetListProducts } from "../model/create-voucher.service";
import CreateVoucherItem from "./create-voucher-item";

const ITEM_LOAD = 12;

export default class CreateVoucherPopupChooseProduct extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      products: [],
      productIds: [],
      loading: true,
      page: 1,
    };
  }

  totalProduct = 0;
  keyword = "";

  open = (productIds) => {
    this.setState({ productIds, modalVisible: true });
    this.onGetListProducts(1);
  };

  onCancel = () => {
    this.setState({
      productIds: [],
      loading: true,
      page: 1,
      modalVisible: false,
    });
  };

  onGetListProducts = async (pageIndex) => {
    let params = {
      pageIndex,
      pageSize: ITEM_LOAD,
      keyword: this.keyword,
    };

    if (this.props.paramsFilter) {
      params = {
        ...params,
        ...this.props.paramsFilter,
      };
    }

    const { products, total } = await apiGetListProducts(params);

    this.totalProduct = total;

    this.setState({
      products,
      loading: false,
    });
  };

  onChangePage = (page) => {
    this.setState(
      {
        loading: true,
        page,
      },
      () => {
        this.onGetListProducts(page);
      }
    );
  };

  onPressSearch = (evt) => {
    if (evt.key === "Enter") {
      if (this.keyword === this.refSearch.state.value) return;
      this.keyword = this.refSearch.state.value || "";

      this.setState(
        {
          loading: true,
          page: 1,
        },
        () => {
          this.onGetListProducts(1);
        }
      );
    }
  };

  onSelectItem = (item) => (checked) => {
    let productIds = [...this.state.productIds];

    if (!checked) {
      productIds = productIds.filter((j) => j !== item._id);
    } else {
      productIds.push(item._id);
    }

    this.setState({ productIds });
  };

  onPressYes = () => {
    const { productIds } = this.state;

    this.props.onSelect && this.props.onSelect(productIds);

    this.onCancel();
  };

  render() {
    const { modalVisible, products, page, loading, productIds } = this.state;
    return (
      <Modal
        visible={modalVisible}
        onOk={this.onPressYes}
        onCancel={this.onCancel}
        centered
        width={1300}
        bodyStyle={{ height: 600, overflow: "scroll" }}
        footer={[
          <Button
            size="large"
            key="back"
            onClick={this.onCancel}
            style={{
              background: "#F34949",
              borderRadius: "3px",
              color: "#fff",
              border: "none",
            }}
          >
            Huỷ bỏ
          </Button>,
          <Button
            size="large"
            key="submit"
            type="primary"
            onClick={this.onPressYes}
            style={{
              background: "#3AC86E",
              borderRadius: "3px",
              border: "none",
            }}
          >
            Thêm
          </Button>,
        ]}
      >
        <div className="mt-3">
          <Input
            className="borderRadius"
            allowClear
            placeholder={"Tìm kiếm tin sản phẩm"}
            size="large"
            ref={(r) => (this.refSearch = r)}
            onKeyDown={this.onPressSearch}
          />

          <List
            grid={{ gutter: 16, column: 4 }}
            size="large"
            loading={loading}
            pagination={{
              onChange: this.onChangePage,
              current: page,
              pageSize: ITEM_LOAD,
              total: this.totalProduct,
            }}
            style={{ marginLeft: 8, marginRight: 8, flexDirection: "row" }}
            dataSource={products}
            renderItem={(item, i) => {
              return (
                <CreateVoucherItem
                  item={item}
                  isChecked={productIds.includes(item._id)}
                  onSelect={this.onSelectItem(item)}
                />
              );
            }}
          />
        </div>
      </Modal>
    );
  }
}
