const validate = (entity) => {
    // function
    const errorResult = (message) => {
        return {
            isValidate: false,
            message,
        };
    };

    // validate
    if (!entity.name || entity.name.trim() === "") {
        return errorResult("Tên không được để trống")
    }
        
    if (!entity.description || entity.description.trim() === "") {
        return errorResult("Mô tả không được để trống")
    }
    return {
        isValidate: true,
        message: "success",
    };
};

export default {
    validate,
};