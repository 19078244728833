import React, { Component } from "react";

// ui
import "./index.scss";

// redux
import { connect } from "react-redux";
import UserGlobalActions from '../../../redux/user_global/actions'

// subcomponent
import StoryOfShop from "./story";
import ProductPanel from './product_panel'
import ServicePanel from './services.panel'
import TopMenuPanel from './top_menu'

class HomePage extends Component {
    componentDidMount = () => {
        this.props.init();
    };

    render() {
        return (
            <div className="HomePage">

                <TopMenuPanel />
                <ServicePanel />
                <ProductPanel />
                <StoryOfShop />
                
          </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        init: () => {
            dispatch(UserGlobalActions.action.loadListHomeProduct())
            dispatch(UserGlobalActions.action.loadProductCurrentFlashSale())
            dispatch(UserGlobalActions.action.loadListCategory())
            dispatch(UserGlobalActions.action.loadListPopularCategory())
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
