import { Popconfirm } from "antd";
import React, { Component } from "react";

export default class PopupConfirmAntd extends Component {
  state = {
    isShow: false,
    option: {
      title: "",
    },
  };

  onShow(option) {
    this.setState({ option, isShow: true });
  }

  onHide = () => {
    this.setState({ isShow: false });
  };

  onYes = () => {
    this.onHide();
    this.props.onYes && this.props.onYes();
  };

  render() {
    const { option, isShow } = this.state;
    if (!isShow) return null;
    return (
      <Popconfirm
        title={option.title}
        visible={isShow}
        onConfirm={this.onYes}
        onCancel={this.onHide}
        okText="Xác nhận"
        cancelText="Huỷ"
        placement="top"
      />
    );
  }
}
