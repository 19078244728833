import React, { Component } from "react";

// redux
import { connect } from "react-redux";
import StatisticalActions from "../../../../redux/user_profile_statistical/actions";

// component
import Pagination from "../../../../../components/paginations/paginations";
import {
    convertIsoDateToString,
    numberDisplayThousand,
} from "../../../../../services/utils/helper";
import { number } from "prop-types";

const list_bill = [
    {
        code: "EBE123",
        date: "12/07/2020",
        total: "4.500.000",
        revenue: "600.000",
    },
    {
        code: "EBE124",
        date: "13/07/2020",
        total: "2.300.000",
        revenue: "400.000",
    },
    {
        code: "EBE126",
        date: "14/07/2020",
        total: "4.500.000",
        revenue: "600.000",
    },
    {
        code: "EBE127",
        date: "15/07/2020",
        total: "14.500.000",
        revenue: "1.600.000",
    },
];

const list_flexs = [10, 30, 30, 30, 30];

class URP_ListRevenues extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageIndex: 1,
            pageSize: 10,
            total: 30,
        };
    }

    render() {
        let list_revenue = this.props.list_revenue
            ? this.props.list_revenue
            : [];
        
        return (
            <div className="URP_ListRevenues">
                <table className="CDS_Table" style={{}}>
                    <tr className="CDS_TableRowHeader">
                        <th
                            className="CDS_HeaderText miniHide"
                            style={{
                                flex: list_flexs[0],
                                textAlign: "left",
                                marginRight: 10,
                            }}
                        >
                            STT
                        </th>
                        <th
                            className="CDS_HeaderText"
                            style={{ flex: list_flexs[1], textAlign: "left" }}
                        >
                            Đơn hàng
                        </th>
                        <th
                            className="CDS_HeaderText superMiniHide"
                            style={{ flex: list_flexs[2] }}
                        >
                            Ngày mua
                        </th>
                        <th
                            className="CDS_HeaderText"
                            style={{ flex: list_flexs[3] }}
                        >
                            Tổng đơn
                        </th>
                        <th
                            className="CDS_HeaderText miniHide"
                            style={{ flex: list_flexs[4] }}
                        >
                            Hoa hồng
                        </th>
                    </tr>
                    {list_revenue.map((bill, index) => {
                        let pre = this.props.pageSize * ( this.props.pageIndex - 1 )

                        return (
                            <tr
                                className="CDS_TableRow"
                                style={{
                                    borderBottom:
                                        index === list_bill.length
                                            ? "none"
                                            : "1px solid #e2e2e2",
                                }}
                            >
                                <td
                                    className="CDS_TableCell miniHide"
                                    style={{
                                        flex: list_flexs[0],
                                        textAlign: "left",
                                        marginRight: 10,
                                    }}
                                >
                                    {pre + index + 1}
                                </td>
                                <td
                                    className="CDS_TableCell"
                                    style={{
                                        flex: list_flexs[1],
                                        textAlign: "left",
                                    }}
                                >
                                    {bill.orderCode}
                                </td>
                                <td
                                    className="CDS_TableCell superMiniHide"
                                    style={{ flex: list_flexs[2] }}
                                >
                                    {convertIsoDateToString(
                                        new Date(bill.createdAt)
                                    )}
                                </td>
                                <td
                                    className="CDS_TableCell"
                                    style={{ flex: list_flexs[3] }}
                                >
                                    {numberDisplayThousand(bill.totalPriceRevenue)}
                                </td>
                                <td
                                    className="CDS_TableCell miniHide"
                                    style={{ flex: list_flexs[4] }}
                                >
                                    {numberDisplayThousand(bill.commissions)}
                                </td>
                            </tr>
                        );
                    })}
                </table>

                {/* <div className="URP_TotalTitle">
                    Tổng doanh thu (tạm tính): 
                    <span className="URPR_Money">3.200.000</span>
                </div> */}

                <div className="UHP_PaginateSection">
                    <Pagination
                        onChange={(index) => {
                            this.props.updatePageIndex(index);
                        }}
                        pageSize={this.props.pageSize}
                        pageIndex={this.props.pageIndex}
                        total={this.props.total}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    let _state = state.user_profile_statistical;
    return {
        list_revenue: _state.list_detail_revenue,
        pageIndex: _state.detail_revenue_pageIndex,
        pageSize: _state.detail_revenue_pageSize,
        total: _state.detail_revenue_total,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updatePageIndex: (pageIndex) => {
            dispatch(
                StatisticalActions.action.updatePageIndexRevenue(pageIndex)
            );
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(URP_ListRevenues);
