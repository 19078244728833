import { server_address } from "./configs";
import createAPIServices from "./createApiServices";

const api = createAPIServices();

export const getImageUrl = (url) => {
  if (!url) return null;

  if (
    url.length >= 4 &&
    url[0] === "h" &&
    url[1] === "t" &&
    url[2] === "t" &&
    url[3] === "p"
  )
    return url;
  if (
    url.length >= 4 &&
    url[0] === "d" &&
    url[1] === "a" &&
    url[2] === "t" &&
    url[3] === "a"
  )
    return url;

  return server_address + url;
};

export const uploadImage = (image) => {
  const formData = new FormData();
  formData.append("image", image);

  return api.makeAuthRequest({
    url: `upload`,
    method: "POST",
    data: formData,
  });
};
