import React, { Component } from 'react'

// icons
import { FaEllipsisV, FaEdit, FaTrash } from 'react-icons/fa'
import { Link } from 'react-router-dom'

// component
import ToolTip from '../../../../../components/tooltip'
import ModalConfirmAction from '../../../../../components/modal/modal_confirm_action'

// redux
import { connect } from 'react-redux'
import router_links from '../../../../../const/router_link'
import { getImageUrl } from '../../../../../services/api/upload'
import actions from '../../../../redux/admin_post/actions'

import Post from './post'

const posts = [
    {
        title: "12 đặc sản Đà Lạt nên mua về làm quà 2020",
        description: "12 đặc sản Đà Lạt nên mua về làm quà 2020",
        categoryName: "Hoa quả tươi",
        userCreated: "Pham Tiến Nam",
        thumbnailUrl: "/static/1-1604949163727-284323098.jpg",
        status: true,
        _id: "5f58d249917b0f1758269990",
        content: "12 đặc sản Đà Lạt nên mua về làm quà 2020",
        categoryId: "5f48b187c053e917e501bf9c",
        userId: "5f4cc1d81ce7115cde3f922e",
        createdAt: "2020-09-09T01:02:01.000Z",
        __v: 0,
        updatedAt: "2020-09-09T13:10:05.949Z"
    },
    {
        title: "12 đặc sản Đà Lạt nên mua về làm quà 2020",
        description: "12 đặc sản Đà Lạt nên mua về làm quà 2020",
        categoryName: "Hoa quả tươi",
        userCreated: "Pham Tiến Nam",
        thumbnailUrl: "/static/1-1604949163727-284323098.jpg",
        status: true,
        _id: "5f58d249917b0f1758269990",
        content: "12 đặc sản Đà Lạt nên mua về làm quà 2020",
        categoryId: "5f48b187c053e917e501bf9c",
        userId: "5f4cc1d81ce7115cde3f922e",
        createdAt: "2020-09-09T01:02:01.000Z",
        __v: 0,
        updatedAt: "2020-09-09T13:10:05.949Z"
    },
    {
        title: "12 đặc sản Đà Lạt nên mua về làm quà 2020",
        description: "12 đặc sản Đà Lạt nên mua về làm quà 2020",
        categoryName: "Hoa quả tươi",
        userCreated: "Pham Tiến Nam",
        thumbnailUrl: "/static/1-1604949163727-284323098.jpg",
        status: true,
        _id: "5f58d249917b0f1758269990",
        content: "12 đặc sản Đà Lạt nên mua về làm quà 2020",
        categoryId: "5f48b187c053e917e501bf9c",
        userId: "5f4cc1d81ce7115cde3f922e",
        createdAt: "2020-09-09T01:02:01.000Z",
        __v: 0,
        updatedAt: "2020-09-09T13:10:05.949Z"
    },
    {
        title: "12 đặc sản Đà Lạt nên mua về làm quà 2020",
        description: "12 đặc sản Đà Lạt nên mua về làm quà 2020",
        categoryName: "Hoa quả tươi",
        userCreated: "Pham Tiến Nam",
        thumbnailUrl: "/static/1-1604949163727-284323098.jpg",
        status: true,
        _id: "5f58d249917b0f1758269990",
        content: "12 đặc sản Đà Lạt nên mua về làm quà 2020",
        categoryId: "5f48b187c053e917e501bf9c",
        userId: "5f4cc1d81ce7115cde3f922e",
        createdAt: "2020-09-09T01:02:01.000Z",
        __v: 0,
        updatedAt: "2020-09-09T13:10:05.949Z"
    },
    {
        title: "12 đặc sản Đà Lạt nên mua về làm quà 2020",
        description: "12 đặc sản Đà Lạt nên mua về làm quà 2020",
        categoryName: "Hoa quả tươi",
        userCreated: "Pham Tiến Nam",
        thumbnailUrl: "/static/1-1604949163727-284323098.jpg",
        status: true,
        _id: "5f58d249917b0f1758269990",
        content: "12 đặc sản Đà Lạt nên mua về làm quà 2020",
        categoryId: "5f48b187c053e917e501bf9c",
        userId: "5f4cc1d81ce7115cde3f922e",
        createdAt: "2020-09-09T01:02:01.000Z",
        __v: 0,
        updatedAt: "2020-09-09T13:10:05.949Z"
    },
    {
        title: "12 đặc sản Đà Lạt nên mua về làm quà 2020",
        description: "12 đặc sản Đà Lạt nên mua về làm quà 2020",
        categoryName: "Hoa quả tươi",
        userCreated: "Pham Tiến Nam",
        thumbnailUrl: "/static/1-1604949163727-284323098.jpg",
        status: true,
        _id: "5f58d249917b0f1758269990",
        content: "12 đặc sản Đà Lạt nên mua về làm quà 2020",
        categoryId: "5f48b187c053e917e501bf9c",
        userId: "5f4cc1d81ce7115cde3f922e",
        createdAt: "2020-09-09T01:02:01.000Z",
        __v: 0,
        updatedAt: "2020-09-09T13:10:05.949Z"
    },
    {
        title: "12 đặc sản Đà Lạt nên mua về làm quà 2020",
        description: "12 đặc sản Đà Lạt nên mua về làm quà 2020",
        categoryName: "Hoa quả tươi",
        userCreated: "Pham Tiến Nam",
        thumbnailUrl: "/static/1-1604949163727-284323098.jpg",
        status: true,
        _id: "5f58d249917b0f1758269990",
        content: "12 đặc sản Đà Lạt nên mua về làm quà 2020",
        categoryId: "5f48b187c053e917e501bf9c",
        userId: "5f4cc1d81ce7115cde3f922e",
        createdAt: "2020-09-09T01:02:01.000Z",
        __v: 0,
        updatedAt: "2020-09-09T13:10:05.949Z"
    }
]

class ListPost extends Component {
    constructor(props) {
        super(props)
        this.state = {
            posts: this.props.posts,

            // isOpen: false,
            isOpenConfirm: false
        }
    }

    setVisible = (index, state) => {
        // let posts = this.props.posts
        posts[index].visible = state

        this.setState({ posts })
    }

    render() {
        const posts = this.props.posts

        return (
            <div className="ListPosts row">
                {
                    posts.map((post, index) => {
                        return (
                           <Post 
                                post={post} 
                                key={index} 
                                setConfirmModal={(value) => {
                                    this.setState({
                                        isOpenConfirm: value
                                    })
                                }}
                           /> 
                        )
                    })
                }

                <ModalConfirmAction
                    isOpen={
                        this.state.isOpenConfirm
                    }
                    setIsOpen={(value) => {
                        this.setState({ isOpenConfirm: value })
                    }}
                    onOk={() => {
                        this.props.removePost(this.props.post._id)
                        this.setState({ isOpenConfirm: false })
                    }}
                    onCancel={() => {
                        this.setState({ isOpenConfirm: false })
                    }}
                    text="Bạn chắc chắn xóa bài viết này?"
                    displayCloseIcon={false}
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    let _state = state.admin_post

    let { posts, detail_post } = _state
    return {
        posts,
        post: detail_post
    }
}

const mapDispatchToProps = dispatch => {
    return {
        removePost: postId => {
            dispatch(actions.action.removePost(postId))
        },
        updateCurrentPost: post => {
            dispatch(actions.action.setCurrentPost(post))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListPost)