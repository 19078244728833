import React, { Component } from 'react'
import './index.scss'
import { FaSave, FaTimes } from 'react-icons/fa';
import { connect } from 'react-redux'
import Button from '../../../../components/button';
import Modal from "../../../../components/modal";
import { DatePicker, TimePicker } from 'antd';

import AdminFlashSaleActions from '../../../redux/admin_flash_sale/actions'

import moment from "moment";

class ModalEditFlashSale extends Component {
    onClickButtonCancel = () => {
        if (typeof this.props.setModalEditFlashSaleSate === 'function') {
            this.props.setModalEditFlashSaleSate(false)
        }
    }

    onChangeNameFlashSale = (evt) => {
        if (typeof this.props.updateCurrentFlashSale === 'function') {
            const nameFlashSale = evt.target.value
            this.props.updateCurrentFlashSale({ name: nameFlashSale })
        }
    }

    onChangeDescriptionFlashSale = (evt) => {
        if (typeof this.props.updateCurrentFlashSale === 'function') {
            const descFlashSale = evt.target.value
            this.props.updateCurrentFlashSale({ description: descFlashSale })
        }
    }

    changeTimeHandler = (dateTarget, time) => {
        const timeSelect = new Date(time);
        dateTarget.setHours(timeSelect.getHours())
        dateTarget.setMinutes(timeSelect.getMinutes())
        dateTarget.setSeconds(timeSelect.getSeconds())
        return dateTarget;
    }

    changeDateHandler = (dateTarget, date) => {
        const dateSelect = new Date(date);
        dateTarget.setFullYear(dateSelect.getFullYear())
        dateTarget.setMonth(dateSelect.getMonth())
        dateTarget.setDate(dateSelect.getDate())
        return dateTarget;
    }

    onChangeStartDate = (date) => {
        if (typeof this.props.updateCurrentFlashSale === 'function') {
            const dateSelect = new Date(date);
            let currentFlashSale = { ...this.props.currentFlashSale }
            let startTime = currentFlashSale.startTime
            startTime = this.changeDateHandler(new Date(startTime), dateSelect)
            this.props.updateCurrentFlashSale({ startTime })
        }
    }

    onChangeStartTime = (time) => {
        if (typeof this.props.updateCurrentFlashSale === 'function') {
            let currentFlashSale = { ...this.props.currentFlashSale }
            let startTime = currentFlashSale.startTime
            startTime = this.changeTimeHandler(new Date(startTime), time)
            this.props.updateCurrentFlashSale({ startTime })
        }
    }


    onChangeStopDate = (date) => {
        if (typeof this.props.updateCurrentFlashSale === 'function') {
            const dateSelect = new Date(date);
            let currentFlashSale = { ...this.props.currentFlashSale }
            let stopTime = currentFlashSale.stopTime
            stopTime = this.changeDateHandler(new Date(stopTime), dateSelect)
            this.props.updateCurrentFlashSale({ stopTime })
        }
    }

    onChangeStopTime = (time) => {
        if (typeof this.props.updateCurrentFlashSale === 'function') {
            let currentFlashSale = { ...this.props.currentFlashSale }
            let stopTime = currentFlashSale.stopTime
            stopTime = this.changeTimeHandler(new Date(stopTime), time)
            this.props.updateCurrentFlashSale({ stopTime })
        }
    }

    onClickSaveEditFlashSale = () => {
        if (typeof this.props.saveCurrentFlashSale === 'function') {
            this.props.saveCurrentFlashSale()
        }
    }

    render() {
        const { modalEditFlashSaleState, setModalEditFlashSaleSate, currentFlashSale } = this.props
        return (
            <Modal
                isOpen={modalEditFlashSaleState}
                displayCloseIcon={false}
                setIsOpen={setModalEditFlashSaleSate}
            >

                <div className="AdminModalAddFlashSale">
                    <div className="AMAFS_Label">Chỉnh sửa flash sale</div>
                    <div>
                        <label className="AMAFSI_Label">Tên flash sale</label>
                        <input
                            type="text"
                            className="AMAFSI_Value"
                            placeholder="Nhập tên flash sale"
                            value={currentFlashSale.name}
                            onChange={this.onChangeNameFlashSale}
                        />
                    </div>
                    <div>
                        <label className="AMAFSI_Label">Mô tả</label>
                        <textarea
                            type="text"
                            className="AMAFSI_Value"
                            placeholder="Mô tả chiến dịch flash sale"
                            rows="3"
                            value={currentFlashSale.description}
                            onChange={this.onChangeDescriptionFlashSale}
                        />
                    </div>
                    <div>
                        <label className="AMAFSI_Label">Thời gian bắt đầu</label>
                        <div>
                            <DatePicker
                                format="DD/MM/YYYY"
                                className="AMAFSI_DateTime"
                                placeholder="Chọn ngày"
                                value={moment(new Date(currentFlashSale.startTime), "yyyy-MM-dd")}
                                onChange={this.onChangeStartDate}
                                clearIcon={false}
                            />
                            <TimePicker
                                placeholder="Chọn giờ"
                                value={moment(new Date(currentFlashSale.startTime), "HH:mm:ss")}
                                onChange={this.onChangeStartTime}
                                clearIcon={false}
                            />
                        </div>
                    </div>
                    <div>
                        <label className="AMAFSI_Label">Thời gian kết thúc</label>
                        <div>
                            <DatePicker
                                format="DD/MM/YYYY"
                                className="AMAFSI_DateTime"
                                placeholder="Chọn ngày"
                                value={moment(new Date(currentFlashSale.stopTime), "yyyy-MM-dd")}
                                onChange={this.onChangeStopDate}
                                clearIcon={false}
                            />
                            <TimePicker
                                placeholder="Chọn giờ"
                                value={moment(new Date(currentFlashSale.stopTime), "HH:mm:ss")}
                                onChange={this.onChangeStopTime}
                                clearIcon={false}
                            />
                        </div>
                    </div>
                    <div className="d-flex justify-content-center AMAFSF_Action">
                        <Button
                            label="Lưu lại"
                            icon={<FaSave style={{ marginRight: '4px' }} />}
                            className="AMAFSF_Button"
                            onClick={this.onClickSaveEditFlashSale} />
                        <Button
                            label="Hủy"
                            icon={<FaTimes style={{ marginRight: '4px' }} />}
                            className="AMAFSF_Button_Cancel"
                            onClick={this.onClickButtonCancel}
                        />
                    </div>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    let _state = state.admin_flash_sale
    return {
        modalEditFlashSaleState: _state.modalEditFlashSaleState,
        currentFlashSale: _state.currentFlashSale
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setModalEditFlashSaleSate: (state) => {
            dispatch(AdminFlashSaleActions.action.setModalEditFlashSaleSate(state))
        },
        updateCurrentFlashSale: (flashSale) => {
            dispatch(AdminFlashSaleActions.action.updateCurrentFlashSale(flashSale))
        },
        saveCurrentFlashSale: () => {
            dispatch(AdminFlashSaleActions.action.saveCurrentFlashSale())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditFlashSale)