const prefix = 'user_profile_history_voucher';

const type = {
    UPDATE_STATE: prefix + 'update_state',
    LOAD_LIST_HISTORY_VOUCHER: prefix + 'load_list_history_voucher',
    LOAD_LIST_HISTORY_VOUCHER_SUCCESS: prefix + 'load_list_history_voucher_success',
    UPDATE_PAGE_HISTORY_VOUCHER_INDEX: prefix + 'update_page_history_voucher_index',

    // history voucher detail
    LOAD_LIST_ORDER_APPLY_VOUCHER: prefix + 'load_list_order_apply_voucher',
    LOAD_LIST_ORDER_APPLY_VOUCHER_SUCCESS: prefix + 'load_list_order_apply_voucher_success',
    UPDATE_PAGE_DETAIL_INDEX: prefix + 'update_page_detail_index',
}

const action = {
    updateState: (state) => {
        return {
            type: type.UPDATE_STATE,
            payload: {
                state
            }
        }
    },
    loadListHistoryVoucher: (params = {}) => {
        return {
            type: type.LOAD_LIST_HISTORY_VOUCHER,
            payload: {
                params
            }
        }
    },
    loadListHistoryVoucherSuccess: (historyVouchers = []) => {
        return {
            type: type.LOAD_LIST_HISTORY_VOUCHER_SUCCESS,
            payload: {
                historyVouchers
            }
        }
    },
    updatePageHistoryVoucherIndex: (pageIndex) => {
        return {
            type: type.UPDATE_PAGE_HISTORY_VOUCHER_INDEX,
            payload: {
                pageIndex
            }
        }
    },
    loadListOrderApplyVoucher: (params = {}) => {
        return {
            type: type.LOAD_LIST_ORDER_APPLY_VOUCHER,
            payload: {
                params,
            }
        }
    },
    loadListOrderApplyVoucherSuccess: (orderApplyVouchers = []) => {
        return {
            type: type.LOAD_LIST_ORDER_APPLY_VOUCHER_SUCCESS,
            payload: {
                orderApplyVouchers
            }
        }
    },
    updatePageDetailPageIndex: (pageIndex) => {
        return {
            type: type.UPDATE_PAGE_DETAIL_INDEX,
            payload: {
                pageIndex
            }
        }
    }
}

export default {
    type,
    action
}