import ApiUtils from "../../../../services/ApiUtils";

const apiName = {
  VOUCHER_AVAILABLE_ON_USER: "/voucher/getVoucherAvailableOnUser",
  VOUCHER: "/voucher",
  DISCOUNT_VOUCHER: "/voucher/getPriceDiscountOfCart?voucherCode=",
};

export const apiGetVouchers = async (params) => {
  const res = await ApiUtils.fetch(apiName.VOUCHER_AVAILABLE_ON_USER, params, {
    isAuth: true,
  });
  return res?.data || undefined;
};

export const apiGetDiscountVoucher = async (code, productIds) => {
  const res = await ApiUtils.fetch(
    apiName.DISCOUNT_VOUCHER + code,
    { productIds },
    { isAuth: true }
  );
  return res?.data?.totalPriceDiscountOfCart || 0;
};
