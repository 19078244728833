import { all, takeEvery, fork, put, select } from "redux-saga/effects";
import actions from "./actions";
import VoucherApiServices from '../../../services/api/voucher'

function* saga_LoadListHistoryVoucher(action) {
    try {
        const { params } = action.payload;

        let _state = yield select((state) => state.user_profile_history_voucher);
        let pageIndex = _state.pageIndex;
        let pageSize = _state.pageSize;

        if (params.pageIndex) pageIndex = params.pageIndex;
        if (params.pageSize) pageSize = params.pageSize;

        const res = yield VoucherApiServices.getListHistoryVoucher(pageSize, pageIndex);
        if (res.code === 200) {
            const { total, items } = res.data;
            yield put(actions.action.updateState({
                total
            }));

            yield put(actions.action.loadListHistoryVoucherSuccess(items))
        }

    } catch(ex) {
        console.log(
            "[user_profile_history_voucher] saga_UpdatePageIndex error : ",
            ex.message
        );
    }
}

function* saga_UpdatePageHistoryVoucherIndex(action) {
    try {
        let pageIndex = action.payload.pageIndex;
        yield put(actions.action.loadListHistoryVoucher({pageIndex}))
    } catch(ex) {
        console.log(
            "[user_profile_history_voucher] saga_UpdatePageHistoryVoucherIndex error : ",
            ex.message
        );
    }
}

function* saga_LoadListOrderApplyVoucher(action) {
    try {
        const { params } = action.payload;

        let _state = yield select((state) => state.user_profile_history_voucher);
        let pageIndex = _state.pageDetailIndex;
        let pageSize = _state.pageDetailSize;
        const {id} = params;

        if (params.pageIndex) pageIndex = params.pageIndex;
        if (params.pageSize) pageSize = params.pageSize;

        const res = yield VoucherApiServices.getListOrderApplyVoucher(pageSize, pageIndex, id);
        if (res.code === 200) {
            const { total, items } = res.data;
            yield put(actions.action.updateState({
                pageDetailTotal: total
            }));

            yield put(actions.action.loadListOrderApplyVoucherSuccess(items))
        }
    } catch(ex) {
        console.log(
            "[user_profile_history_voucher] saga_LoadListOrderApplyVoucher error : ",
            ex.message
        );
    }
}

function* saga_UpdatePageDetailIndex(action) {
    try {
        let pageIndex = action.payload.pageIndex;
        yield put(actions.action.loadListOrderApplyVoucher({pageIndex}))
    } catch(ex) {
        console.log(
            "[user_profile_history_voucher] saga_UpdatePageDetailIndex error : ",
            ex.message
        );
    }
}

function* listen() {
    yield takeEvery(actions.type.LOAD_LIST_HISTORY_VOUCHER, saga_LoadListHistoryVoucher);
    yield takeEvery(actions.type.UPDATE_PAGE_HISTORY_VOUCHER_INDEX, saga_UpdatePageHistoryVoucherIndex);
    yield takeEvery(actions.type.LOAD_LIST_ORDER_APPLY_VOUCHER, saga_LoadListOrderApplyVoucher);
    yield takeEvery(actions.type.UPDATE_PAGE_DETAIL_INDEX, saga_UpdatePageDetailIndex);
}

export default function* mainSaga() {
    yield all([fork(listen)]);
}