import { validateEmail } from './common'

const validate = (entity) => {
    // function
    const errorResult = (message) => {
        return {
            isValidate: false,
            message,
        };
    };

    // validate
    if (entity.fullName === null || entity.fullName.trim() === "")
        return errorResult("Họ và tên không được để trống");

    if (entity.phone === null || entity.phone.trim() === "")
        return errorResult("SĐT không được để trống");

    // if (entity.email === null || entity.email === "")
    //     return errorResult("Email không được để trống");

    // if (!validateEmail(entity.email).isValidate)
    //     return errorResult("Email không đúng định dạng");

    if (entity.password === "")
        return errorResult("Mật khẩu không được để trống");

    if (entity.password !== entity.confirmPassword)
        return errorResult("Mật khẩu xác nhận không chính xác");

    // if (!entity.groupCustomerId)
    //     return errorResult("Nhóm khách hàng không được để trống")

    return {
        isValidate: true,
        message: "success",
    };
};

export default {
    validate,
};
