import React, { Component } from "react";
// icons
import {
  FaAddressBook,
  FaFileAlt,
  FaLink,
  FaMoneyBill,
  FaRoad,
  FaSignOutAlt,
  FaUser,
  FaUserLock,
} from "react-icons/fa";
// redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
// router
import RouterLinks from "../../../../../const/router_link";
import { getImageUrl } from "../../../../../services/api/upload";
import AuthActions from "../../../../redux/auth/actions";
// scss
import "./sidebar.scss";

// menus
const menus = [
  {
    title: "Thông tin tài khoản",
    icon: <FaUser className="SPR_Icon" />,
    path: RouterLinks.U_PROFILE_INFO,
  },
  {
    title: "Địa chỉ giao hàng",
    icon: <FaAddressBook className="SPR_Icon" />,
    path: RouterLinks.U_PROFILE_ADDRESS_SHIPING,
  },
  // {
  //     title: "Danh sách mã khuyến mại",
  //     icon: <FaGifts className="SPR_Icon" />,
  //     path: RouterLinks.U_PROFILE_VOUCHER,
  // },
  // {
  //     title: "Lịch sử sử dụng mã khuyến mại",
  //     icon: <FaHistory className="SPR_Icon" />,
  //     path: RouterLinks.U_PROFILE_HISTORY_VOUCHER,
  // },
  {
    title: "Đơn hàng cá nhân",
    icon: <FaFileAlt className="SPR_Icon" />,
    path: RouterLinks.U_PROFILE_HISTORY_BILL,
  },
  {
    title: "Đơn hàng trong kho",
    icon: <FaFileAlt className="SPR_Icon" />,
    path: RouterLinks.U_PROFILE_WARE_HOUSE_ORDER,
  },
  {
    title: "Doanh thu",
    icon: <FaMoneyBill className="SPR_Icon" />,
    path: RouterLinks.U_PROFILE_REVENUE,
  },
  // {
  //     title: "Lịch sử giao dịch",
  //     icon: <FaHistory className="SPR_Icon" />,
  //     path: RouterLinks.U_PROFILE_HISTORY_PAYMENT,
  // },
  {
    title: "Liên kết bán hàng",
    icon: <FaLink className="SPR_Icon" />,
    path: RouterLinks.U_PROFILE_LINK_AFFILIATE,
  },
  {
    title: "Cấp độ thành viên",
    icon: <FaRoad className="SPR_Icon" />,
    path: RouterLinks.U_PROFILE_LEVEL_USER,
  },
  {
    title: "Đổi mật khẩu",
    icon: <FaUserLock className="SPR_Icon" />,
    path: RouterLinks.U_PROFILE_CHANGE_PASSWORD,
  },
  {
    title: "Đăng xuất",
    icon: <FaSignOutAlt className="SPR_Icon" />,
    path: "/x",
  },
];

class SidebarProfile extends Component {
  render() {
    const { pathname } = this.props.location;
    let userInfo = this.props.userInfo
      ? this.props.userInfo
      : { fullName: "", avatar: "" };

    return (
      <div className="SidebarProfileContainer">
        <div className="SPR_AccountInfo">
          <img className="SPR_Image" src={getImageUrl(userInfo.avatar)} />
          <div className="SPR_UserInfo">
            <div className="SPR_LabelInfo">Tài khoản của</div>
            <div className="SPR_Name">{userInfo.fullName}</div>
          </div>
        </div>

        <div className="SPR_Menu">
          {menus.map((menu_item, index) => {
            let _class = "SPR_MenuItem";
            if (menu_item.path === pathname) _class = _class + " SPR_Active";

            if (menu_item.title === "Đăng xuất") {
              return (
                <div
                  to={menu_item.path}
                  className={_class}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    this.props.logout();
                  }}
                >
                  {menu_item.icon ? menu_item.icon : null}
                  <div className="SPR_Title">{menu_item.title}</div>
                </div>
              );
            }

            return (
              <Link to={menu_item.path} className={_class}>
                {menu_item.icon ? menu_item.icon : null}
                <div className="SPR_Title">{menu_item.title}</div>
              </Link>
            );
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.auth.userInfo ? state.auth.userInfo : null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch(AuthActions.action.logout());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SidebarProfile));
