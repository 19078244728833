import React, { Component } from "react";

// ui
import "./index.scss";

// redux
import { connect } from "react-redux";
import UserProductHotAction from '../../../redux/user_product_hot_discount/actions'

// subcomponent
import ProductPanel from './product_panel'
import ProductSkeleton from "../../../../components/loading/product_skeleton";
import { FaDragon } from "react-icons/fa";

class HomePage extends Component {
    componentDidMount = () => {
        this.props.init();
    };

    renderSkeletonProductList = () => {
        const skelectonProductList = [];
        for (let i = 0; i < 6; i++) {
            skelectonProductList.push(<ProductSkeleton/>);
        }
        return (
            <div className='row'>
                {skelectonProductList}
            </div>
        )
    }

    render() {
        const { isLoadingProductBestSellList } = this.props;
        return (
            <div className="HomePage">
                <div className="hot-discount-header">
                    <div className="HomePage_Title">
                        <FaDragon className="mr-3"/>
                        Sản phẩm bán chạy
                        <FaDragon className="ml-3"/>
                    </div>
                </div>
                {isLoadingProductBestSellList
                    ? this.renderSkeletonProductList()
                    : <ProductPanel />
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const _state = state.user_product_hot_discount;
    return {
        isLoadingProductBestSellList: _state.isLoadingProductBestSellList
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        init: () => {
            dispatch(UserProductHotAction.action.loadListProductBestSell())
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
