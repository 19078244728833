const prefix = 'admin_request_change_customer_group/';

const type = {
    UPDATE_STATE: prefix + 'update_state',
    LOAD_LIST_REQUEST_CHANGE_CUSTOMER_GROUP: prefix + 'load_list_request_change_customer_group',
    LOAD_LIST_REQUEST_CHANGE_CUSTOMER_GROUP_SUCCESS: prefix + 'load_list_request_change_customer_group_success',

    UPDATE_PAGE_INDEX: prefix + 'update_page_index',
    UPDATE_STATUS_REQUEST_CHANGE_CUSTOMER_GROUP: prefix + 'update_status_request_change_customer_group',
    CANCEL_REQUEST_CHANGE_CUSTOMER_GROUP: prefix + 'cancel_request_change_customer',

    LOAD_DETAIL_REQUEST_CHANGE_CUSTOMER_GROUP: prefix + 'load_detail_request_change_customer_group',
    LOAD_DETAIL_REQUEST_CHANGE_CUSTOMER_GROUP_SUCCESS: prefix + 'load_detail_request_change_customer_group_success',
    SET_MODAL_REQUEST_CHANGE_CUSTOMER_GROUP_STATE: prefix + 'set_modal_request_change_customer_group_state',

    LOAD_LIST_GROUP_CUSTOMER: prefix + "load_list_group_customer",
    LOAD_LIST_GROUP_CUSTOMER_SUCCESS: prefix + "load_list_group_customer_success",

    UPDATE_STATUS_FILTER: prefix + 'update_status_filter',
    UPDATE_GROUP_CUSTOMER_FILTER: prefix + 'update_group_customer_filter'
}

const action = {
    updateState: state => {
        return {
            type: type.UPDATE_STATE,
            payload: {
                state
            }
        }
    },

    loadListRequestChangeCustomerGroup: (params = {}) => {
        return {
            type: type.LOAD_LIST_REQUEST_CHANGE_CUSTOMER_GROUP,
            payload: {
                params
            }
        }
    },

    loadListRequestChangeCustomerGroupSuccess: (request_change_customer_groups = []) => {
        return {
            type: type.LOAD_LIST_REQUEST_CHANGE_CUSTOMER_GROUP_SUCCESS,
            payload: {
                request_change_customer_groups
            }
        }
    },

    updateStatusRequestChangeCustomerGroup: (id = "", status = 1) => {
        return {
            type: type.UPDATE_STATUS_REQUEST_CHANGE_CUSTOMER_GROUP,
            payload: {
                id, 
                status
            }
        }
    },

    cancelRequestChangeCustomerGroup: (id = "") => {
        return {
            type: type.CANCEL_REQUEST_CHANGE_CUSTOMER_GROUP,
            payload: {
                id
            }
        }
    },

    loadDetailRequestChangeCustomerGroup: (id = "") => {
        return {
            type: type.LOAD_DETAIL_REQUEST_CHANGE_CUSTOMER_GROUP,
            payload: {
                id
            }
        }
    },

    loadDetailRequestChangeCustomerGroupSuccess: (detail_request_change_customer_group) => {
        return {
            type: type.LOAD_DETAIL_REQUEST_CHANGE_CUSTOMER_GROUP_SUCCESS,
            payload: {
                detail_request_change_customer_group
            }
        }
    },

    setModalRequestChangeCustomerGroupState: (state, id = '') => {
        return {
            type: type.SET_MODAL_REQUEST_CHANGE_CUSTOMER_GROUP_STATE,
            payload: {
                state,
                id
            }
        }
    },

    updatePageIndex: (pageIndex) => {
        return {
            type: type.UPDATE_PAGE_INDEX,
            payload: {
                pageIndex
            }
        }
    },
    loadListGroupCustomer: () => {
        return {
            type: type.LOAD_LIST_GROUP_CUSTOMER,
            payload: {}
        }
    },
    loadListGroupCustomerSuccess: (groupCustomers = []) => {
        return {
            type: type.LOAD_LIST_GROUP_CUSTOMER_SUCCESS,
            payload: {
                groupCustomers
            }
        }
    },
    updateStatusFilter: (status) => {
        return {
            type: type.UPDATE_STATUS_FILTER,
            payload: {
                status
            }
        }
    },
    updateGroupCustomerFilter: (groupCustomerId) => {
        return {
            type: type.UPDATE_GROUP_CUSTOMER_FILTER,
            payload: {
                groupCustomerId
            }
        }
    }
}

export default {
    type,
    action
}