import { Select } from "antd";
import React, { Component } from "react";
import {
  FaCheck,
  FaEdit,
  FaExclamationCircle,
  FaFacebook,
  FaPlusCircle,
  FaTrash,
} from "react-icons/fa";

// redux
import { connect } from "react-redux";
import CategoryPostActions from "../../../redux/admin_category_post/actions";
// component
import Button from "../../../../components/button";
import TreeViewComponent from "../../../../components/treeView";
import ModalAddCategoryPost from "./modal_add_category_post";
import ModalConfirmDelete from "../../../../components/modal/modal_confirm_action";

// scss
import "./index.scss";
import { convertCategoryDataToTreeViewData } from "../../../../services/utils/helper";
import Pagination from "../../../../components/paginations/paginations";
import AdminCheckPermissionComponent from "../components/admin-check-permission";
import { paramsPermisson } from "../../../routers/admin_site";
import permission_action from "../../../../const/permission_action";
import module_app from "../../../../const/module_app";

class AdminListCategoryPost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirmMd: false,
    };
  }

  componentDidMount() {
    this.props.init();
  }

  componentDidUpdate(prevProps) {
    let { categoriesPost } = this.props;
    if (
      this.props.categoriesPost !== prevProps.categoriesPost &&
      this.props.categoriesPost.length !== prevProps.categoriesPost.length
    ) {
      if (typeof this.props.loadDetailCategoryPost === "function") {
        if (
          Array.isArray(this.props.categoriesPost) &&
          this.props.categoriesPost.length > 0
        ) {
          this.props.loadDetailCategoryPost(categoriesPost[0]._id);
        }
      }
    }
  }

  onSelectCategoryPost = (ids) => {
    if (typeof this.props.loadDetailCategoryPost === "function") {
      this.props.loadDetailCategoryPost(ids[0]);
    }
  };

  onChangeCategoryPostCode = (evt) => {
    if (typeof this.props.updateCategoryPost === "function") {
      const categoryPostName = evt.target.value;
      this.props.updateCategoryPost({ code: categoryPostName });
    }
  };

  onChangeCategoryPostDescription = (evt) => {
    if (typeof this.props.updateCategoryPost === "function") {
      const categoryPostDescription = evt.target.value;
      this.props.updateCategoryPost({ description: categoryPostDescription });
    }
  };

  onChangeCategoryPostStatus = (value) => {
    if (typeof this.props.updateCategoryPost === "function") {
      this.props.updateCategoryPost({ status: value });
    }
  };

  onClickButtonEditCategoryPost = () => {
    if (typeof this.props.saveCategoryPost === "function") {
      this.props.saveCategoryPost();
    }
  };

  onClickButtonAddCategoryPost = () => {
    if (typeof this.props.setModalAddCategoryPostState === "function") {
      this.props.setModalAddCategoryPostState(true);
    }
  };

  onClickButtonAddChildCategoryPost = () => {
    if (typeof this.props.setModalAddCategoryPostState === "function") {
      const { categoryPost } = this.props;
      // if (categoryPost.status) {
      this.props.setModalAddCategoryPostState(true, categoryPost._id);
      // }
    }
  };

  onChangeParentCategory = (value) => {
    this.props.updateCategoryPost({ parentId: value });
  };

  onClickButtonRemoveCategoryPost = () => {
    this.setState({ showConfirmMd: true });
  };

  onCancelMdConfirm = () => {
    this.setState({ showConfirmMd: false });
  };

  confirmRemoveCategory = () => {
    if (typeof this.props.removeCategoryPost === "function") {
      this.props.removeCategoryPost();
    }
    this.setState({ showConfirmMd: false });
  };

  render() {
    const { showConfirmMd } = this.state;
    const { categoriesPost, categoryPost, allCategoryPost } = this.props;
    const parentCategories = allCategoryPost.filter(
      (category) => category._id !== categoryPost._id
    );

    return (
      <div className="AdminListCategoryPost">
        <div className="d-flex ALCP_Header">
          <AdminCheckPermissionComponent
            paramsPermission={paramsPermisson(
              module_app.MODULE_CATEGORY_NEWS_MANAGEMENT,
              permission_action.INSERT
            )}
            noContent
          >
            <Button
              label="Thêm mới danh mục"
              icon={<FaPlusCircle style={{ marginRight: "4px" }} />}
              className="ALCP_AddButton"
              onClick={this.onClickButtonAddCategoryPost}
            />
          </AdminCheckPermissionComponent>
        </div>
        <div className="d-flex ALCP_body">
          <div className="ALCP_Tree">
            <div className="ALCP_Label">Danh mục bài viết</div>
            <div>
              <TreeViewComponent
                useCustom={true}
                className="ALCP_TreeView"
                data_source={convertCategoryDataToTreeViewData(categoriesPost)}
                onSelect={this.onSelectCategoryPost}
                value={[categoryPost._id]}
              />
            </div>
          </div>

          <div className="AdminDetailCategoryPost">
            <div className="ADCP_Label">Chi tiết danh mục bài viết</div>
            <form>
              <div>
                <label className="ADCPF_Label">Mã code</label>
                <input
                  type="text"
                  className="ADCPF_Value"
                  value={categoryPost.code}
                  onChange={this.onChangeCategoryPostCode}
                />
              </div>
              <div>
                <label className="ADCPF_Label">Tên danh mục</label>
                <input
                  className="ADCPF_Value"
                  type="text"
                  value={categoryPost.description}
                  onChange={this.onChangeCategoryPostDescription}
                />
              </div>
              <div>
                <label className="ADCPF_Label">Danh mục cha</label>
                <Select
                  value={categoryPost.parentId}
                  className="ADCPF_Select"
                  onChange={this.onChangeParentCategory}
                >
                  <Select.Option value={null}>Danh mục gốc</Select.Option>
                  {parentCategories.map((category) => (
                    <Select.Option key={category._id} value={category._id}>
                      {category.description}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div>
                <label className="ADCPF_Label">Trạng thái</label>
                <Select
                  value={categoryPost.status}
                  className="ADCPF_Select"
                  onChange={this.onChangeCategoryPostStatus}
                >
                  <Select.Option value={true}>Kích hoạt</Select.Option>
                  <Select.Option value={false}>Chưa kích hoạt</Select.Option>
                </Select>
              </div>
              <div className="d-flex justify-content-between ADCPF_Action">
                <AdminCheckPermissionComponent
                  paramsPermission={paramsPermisson(
                    module_app.MODULE_CATEGORY_NEWS_MANAGEMENT,
                    permission_action.INSERT
                  )}
                  noContent
                >
                  <Button
                    label="Thêm danh mục con"
                    icon={<FaPlusCircle style={{ marginRight: "4px" }} />}
                    onClick={this.onClickButtonAddChildCategoryPost}
                  />
                </AdminCheckPermissionComponent>

                <div className="d-flex">
                  <AdminCheckPermissionComponent
                    paramsPermission={paramsPermisson(
                      module_app.MODULE_CATEGORY_NEWS_MANAGEMENT,
                      permission_action.UPDATE
                    )}
                    noContent
                  >
                    <Button
                      label="Sửa"
                      icon={<FaEdit style={{ marginRight: "4px" }} />}
                      className="ADCPF_Button ADCPF_Button_Edit"
                      onClick={this.onClickButtonEditCategoryPost}
                    />
                  </AdminCheckPermissionComponent>

                  <AdminCheckPermissionComponent
                    paramsPermission={paramsPermisson(
                      module_app.MODULE_CATEGORY_NEWS_MANAGEMENT,
                      permission_action.REMOVE
                    )}
                    noContent
                  >
                    <Button
                      label="Xóa"
                      icon={<FaTrash style={{ marginRight: "4px" }} />}
                      className="ADCPF_Button_Delete"
                      onClick={this.onClickButtonRemoveCategoryPost}
                    />
                  </AdminCheckPermissionComponent>
                </div>
              </div>
            </form>
          </div>
        </div>
        <ModalAddCategoryPost />
        <ModalConfirmDelete
          text="Bạn có chắc chắn muốn xóa danh mục bài viết vừa chọn không?"
          isOpen={showConfirmMd}
          onCancel={this.onCancelMdConfirm}
          setIsOpen={this.onCancelMdConfirm}
          onOk={this.confirmRemoveCategory}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const _state = state.admin_category_post;
  return {
    categoriesPost: _state.categoriesPost,
    categoryPost: _state.categoryPost,
    allCategoryPost: _state.allCategoryPost,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    init: () => {
      dispatch(CategoryPostActions.action.loadListCategoryPost());
      dispatch(CategoryPostActions.action.loadAllCategoryPost());
    },
    loadDetailCategoryPost: (id) => {
      dispatch(CategoryPostActions.action.loadDetailCategoryPost(id));
    },
    updateCategoryPost: (categoryPost) => {
      dispatch(CategoryPostActions.action.updateCategoryPost(categoryPost));
    },
    saveCategoryPost: () => {
      dispatch(CategoryPostActions.action.saveCategoryPost());
    },
    setModalAddCategoryPostState: (state, parentId) => {
      dispatch(
        CategoryPostActions.action.setModalAddCategoryPostState(state, parentId)
      );
    },
    removeCategoryPost: () => {
      dispatch(CategoryPostActions.action.removeCategoryPost());
    },
    updatePageIndex: (pageIndex) => {
      dispatch(CategoryPostActions.action.updatePageIndex(pageIndex));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminListCategoryPost);
