const prefix = "admin_dashboard/";

const type = {
  UPDATE_STATE: prefix + "update_state",

  // overview
  GET_OVER_VIEW_INFO: prefix + "get_over_view_info",
  GET_TOTAL_REVENUE_SUCCESS: prefix + "get_total_revenue_success",
  GET_TOTAL_ORDER_SUCCESS: prefix + "get_total_order_success",
  GET_TOTAL_PRODUCT_SUCCESS: prefix + "get_total_product_success",
  GET_TOTAL_NUMBER_USER_SUCCESS: prefix + "get_total_number_user_success",

  // order
  LOAD_LIST_ORDER: prefix + "load_list_history_order",
  LOAD_LIST_ORDER_SUCCESS: prefix + "load_list_history_order_success",
  UPDATE_PAGE_INDEX: prefix + "update_page_index",
  UPDATE_TEXT_SEACRH: prefix + "update_text_search",
  SEARCH_ORDER: prefix + "search_order",

  // chart
  GET_USER_TYPE_CHART: prefix + "get_user_type_chart",
  GET_USER_TYPE_CHART_SUCCESS: prefix + "get_user_type_chart_success",
  GET_REVENUE_CHART: prefix + "get_revenue_chart",
  GET_REVENUE_CHART_SUCCESS: prefix + "get_revenue_chart_success",

  // detail order
  LOAD_DETAIL_ORDER: prefix + "load_detail_order",
  LOAD_DETAIL_ORDER_SUCCESS: prefix + "load_detail_order_success",
  UPDATE_ORDER_STATUS: prefix + "cancel_order",

  CONFIRM_ORDER: prefix + "confirm_order",
};

const action = {
  updateState: (state) => {
    return {
      type: type.UPDATE_STATE,
      payload: {
        state,
      },
    };
  },

  // overview
  getOverviewInfo: () => {
    return {
      type: type.GET_OVER_VIEW_INFO,
      payload: {},
    };
  },
  getTotalRevenueSuccess: (totalRevenue) => {
    return {
      type: type.GET_TOTAL_REVENUE_SUCCESS,
      payload: {
        totalRevenue,
      },
    };
  },
  getTotalOrderSuccess: (totalOrder) => {
    return {
      type: type.GET_TOTAL_ORDER_SUCCESS,
      payload: {
        totalOrder,
      },
    };
  },
  getTotalProductSuccess: (totalProduct) => {
    return {
      type: type.GET_TOTAL_PRODUCT_SUCCESS,
      payload: {
        totalProduct,
      },
    };
  },
  getTotalNumberUserSuccess: (totalUser) => {
    return {
      type: type.GET_TOTAL_NUMBER_USER_SUCCESS,
      payload: {
        totalUser,
      },
    };
  },

  // order
  loadListOrder: (params = {}) => {
    return {
      type: type.LOAD_LIST_ORDER,
      payload: {
        params,
      },
    };
  },
  loadListOrderSuccerss: (orders = []) => {
    return {
      type: type.LOAD_LIST_ORDER_SUCCESS,
      payload: {
        orders,
      },
    };
  },
  updatePageIndex: (pageIndex) => {
    return {
      type: type.UPDATE_PAGE_INDEX,
      payload: {
        pageIndex,
      },
    };
  },
  updateTextSearch: (textSearch) => {
    return {
      type: type.UPDATE_TEXT_SEACRH,
      payload: {
        textSearch,
      },
    };
  },
  searchOrder: () => {
    return {
      type: type.SEARCH_ORDER,
      payload: {},
    };
  },

  // chart
  getUserTypeChart: () => {
    return {
      type: type.GET_USER_TYPE_CHART,
      payload: {},
    };
  },
  getUserTypeChartSuccess: (user_type_data) => {
    return {
      type: type.GET_USER_TYPE_CHART_SUCCESS,
      payload: {
        user_type_data,
      },
    };
  },
  getRevenueChart: () => {
    return {
      type: type.GET_REVENUE_CHART,
      payload: {},
    };
  },
  getRevenueChartSuccess: (revenue_data) => {
    return {
      type: type.GET_REVENUE_CHART_SUCCESS,
      payload: {
        revenue_data,
      },
    };
  },

  // detail order
  loadDetailOrder: (id) => {
    return {
      type: type.LOAD_DETAIL_ORDER,
      payload: {
        id,
      },
    };
  },
  loadDetailOrderSuccess: (order) => {
    return {
      type: type.LOAD_DETAIL_ORDER_SUCCESS,
      payload: {
        order,
      },
    };
  },
  updateOrderStatus: (
    id = "",
    status = 1,
    estimatedDeliveryTime = undefined
  ) => {
    return {
      type: type.UPDATE_ORDER_STATUS,
      payload: {
        id,
        status,
        estimatedDeliveryTime,
      },
    };
  },
};

export default {
  type,
  action,
};
