import actions from "./actions";

const default_user = {
  _id: 0,
  avatar: "",
  fullName: "",
  sex: 0,
  email: "",
  address: "",
  username: "",
  dob: new Date(),
  revenue: 0,
  balance: 0,
  deposit: 0,
  groupId: null,
  levelId: null,

  password: "",

  file: null,
  groupCustomerId: "",
  linkAffiliate: "",
  linkAffiliateParent: "",
  statusDebtDeposit: false,
  debtDeposit: 0,
};

export const SORT_MODE_CONST = {
  NONE: 1,
  SORT_DESC_NAME: 2,
  SORT_ASC_NAME: 3,
  SORT_DESC_PRICE: 4,
  SORT_ASC_PRICE: 5,
};

const initState = {
  users: [],

  total: 0,
  pageIndex: 1,
  pageSize: 8,
  textSearch: "",
  sortMode: SORT_MODE_CONST.SORT_ASC_NAME,

  user: default_user,
  levels: [],
  groups: [],
  groupCustomers: [],
};

const reducer = (state = initState, action) => {
  let payload = action.payload;

  switch (action.type) {
    case actions.type.UPDATE_STATE:
      return {
        ...state,
        ...payload.state,
      };

    case actions.type.LOAD_LIST_USER:
      return {
        ...state,
        ...{},
      };

    case actions.type.LOAD_LIST_USER_SUCCESS:
      return {
        ...state,
        ...{
          users: payload.users,
        },
      };

    case actions.type.LOAD_USER_DETAIL:
      return state;

    case actions.type.LOAD_USER_DETAIL_SUCCESS:
      return {
        ...state,
        ...{
          user: payload.user,
        },
      };
    case actions.type.UPDATE_CURRENT_USER:
      return {
        ...state,
        ...{
          user: {
            ...state.user,
            ...payload.user,
          },
        },
      };
    case actions.type.SET_DEFAULT_CURRENT_USER:
      return {
        ...state,
        ...{
          user: default_user,
        },
      };

    case actions.type.SAVE_CURRENT_USER:
      return state;

    case actions.type.UPDATE_TEXT_SEARCH:
      return {
        ...state,
        ...{
          textSearch: payload.textSearch,
        },
      };

    case actions.type.SEARCH_USER:
      return state;

    case actions.type.UPDATE_PAGE_SIZE:
      return {
        ...state,
        ...{
          pageIndex: 1,
          pageSize: payload.pageSize,
        },
      };

    case actions.type.UPDATE_PAGE_INDEX:
      return {
        ...state,
        ...{
          pageIndex: payload.pageIndex,
        },
      };

    case actions.type.UPDATE_SORT_MODE:
      return {
        ...state,
        ...{
          sortMode: payload.mode,
        },
      };

    // danh muc
    case actions.type.LOAD_LIST_LEVEL_USER:
      return state;

    case actions.type.LOAD_LIST_LEVEL_USER_SUCCESS:
      return {
        ...state,
        ...{
          levels: payload.levels,
        },
      };

    case actions.type.LOAD_LIST_GROUP_USER:
      return state;

    case actions.type.LOAD_LIST_GROUP_USER_SUCCESS:
      return {
        ...state,
        ...{
          groups: payload.groups,
        },
      };

    case actions.type.BLOCK_USER:
      return state;

    case actions.type.ACTIVE_USER:
      return state;
    case actions.type.LOAD_LIST_GROUP_CUSTOMERS:
      return state;
    case actions.type.LOAD_LIST_GROUP_CUSTOMERS_SUCCESS:
      return {
        ...state,
        ...{
          groupCustomers: payload.groupCustomers,
        },
      };
    default:
      return state;
  }
};

export default reducer;
