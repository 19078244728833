import React, { Component } from "react";

// redux
import { connect } from "react-redux";
import UserProfileTransactionActions from "../../../../redux/user_profile_history_transaction/actions";

// router
import { Link } from "react-router-dom";
import Router_Links from "../../../../../const/router_link";

// scss
import "./index.scss";

// component
import Pagination from "../../../../../components/paginations/paginations";

// const
import PaymentStatusEnum from "../../../../../const/status_payment";
import {
    convertIsoDateToString,
    numberDisplayThousand,
} from "../../../../../services/utils/helper";

const list_flexs = [3, 25, 20, 20, 25, 25];

class HistoryPaymentPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageIndex: 5,
            pageSize: 10,
            total: 100,
        };
    }

    componentDidMount = () => {
        this.props.loadListHistoryTransaction();
    };

    render() {
        let { pageSize, pageIndex, total } = this.props;
        let transactions = this.props.transactions
            ? this.props.transactions
            : [];

        return (
            <div className="UserHistoryPaymentPage">
                <div className="UHP_Title">Lịch sử giao dịch</div>

                <table className="CDS_Table" style={{}}>
                    <tr className="CDS_TableRowHeader">
                        <th
                            className="CDS_HeaderText miniHide"
                            style={{
                                flex: list_flexs[0],
                                textAlign: "left",
                                marginRight: 10,
                            }}
                        >
                            STT
                        </th>
                        <th
                            className="CDS_HeaderText"
                            style={{ flex: list_flexs[1], textAlign: "left" }}
                        >
                            Mã giao dịch
                        </th>
                        <th
                            className="CDS_HeaderText superMiniHide"
                            style={{ flex: list_flexs[2] }}
                        >
                            Ngày
                        </th>
                        <th
                            className="CDS_HeaderText"
                            style={{ flex: list_flexs[3] }}
                        >
                            Số tiền
                        </th>
                        <th
                            className="CDS_HeaderText miniHide"
                            style={{ flex: list_flexs[4] }}
                        >
                            Thông tin
                        </th>
                        <th
                            className="CDS_HeaderText"
                            style={{ flex: list_flexs[5], textAlign: "right" }}
                        >
                            Trạng thái
                        </th>
                    </tr>
                    {transactions.map((transaction, index) => {
                        let totalPrice = transaction.totalPrice === "" ? 0 : transaction.totalPrice;
                        try {
                            totalPrice = parseInt(totalPrice);
                        } catch {
                            totalPrice = 0;
                        }

                        return (
                            <tr
                                className="CDS_TableRow"
                                style={{
                                    borderBottom:
                                        index === transactions.length - 1
                                            ? "none"
                                            : "1px solid #e2e2e2",
                                }}
                            >
                                <td
                                    className="CDS_TableCell miniHide"
                                    style={{
                                        flex: list_flexs[0],
                                        textAlign: "left",
                                        marginRight: 10,
                                    }}
                                >
                                    {index + 1}
                                </td>
                                <td
                                    className="CDS_TableCell"
                                    style={{
                                        flex: list_flexs[1],
                                        textAlign: "left",
                                    }}
                                >
                                    {transaction.orderId}
                                </td>
                                <td
                                    className="CDS_TableCell superMiniHide"
                                    style={{ flex: list_flexs[2] }}
                                >
                                    {transaction.createdAt &&
                                        convertIsoDateToString(
                                            new Date(transaction.createdAt)
                                        )}
                                </td>
                                <td
                                    className="CDS_TableCell"
                                    style={{ flex: list_flexs[3] }}
                                >
                                    {`${numberDisplayThousand(totalPrice)} đ`}
                                </td>
                                <td
                                    className="CDS_TableCell miniHide"
                                    style={{ flex: list_flexs[4] }}
                                >
                                    {transaction.description}
                                </td>
                                <td
                                    className="CDS_TableCell"
                                    style={{
                                        flex: list_flexs[5],
                                        textAlign: "right",
                                        fontWeight: 600,
                                    }}
                                >
                                    {transaction.status ===
                                        PaymentStatusEnum.SUCCESS && (
                                        <div
                                            className="success"
                                            style={{
                                                color: "green",
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "flex-end",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    height: 8,
                                                    width: 8,
                                                    borderRadius: 4,
                                                    backgroundColor: "green",
                                                    marginRight: 5,
                                                }}
                                            ></div>
                                            Thành công
                                        </div>
                                    )}

                                    {transaction.status ===
                                        PaymentStatusEnum.CANCELED && (
                                        <div
                                            className="success"
                                            style={{
                                                color: "red",
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "flex-end",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    height: 8,
                                                    width: 8,
                                                    borderRadius: 4,
                                                    backgroundColor: "red",
                                                    marginRight: 5,
                                                }}
                                            ></div>
                                            Thất bại
                                        </div>
                                    )}

                                    {transaction.status ===
                                        PaymentStatusEnum.PROCESS && (
                                        <div
                                            className="success"
                                            style={{
                                                color: "orange",
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "flex-end",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    height: 8,
                                                    width: 8,
                                                    borderRadius: 4,
                                                    backgroundColor: "orange",
                                                    marginRight: 5,
                                                }}
                                            ></div>
                                            Đang xử lý
                                        </div>
                                    )}
                                </td>
                            </tr>
                        );
                    })}
                </table>

                <div className="UHP_PaginateSection">
                    <Pagination
                        onChange={(index) => {
                            this.props.updatePageIndex(index);
                        }}
                        pageSize={pageSize}
                        pageIndex={pageIndex}
                        total={total}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    let _state = state.user_profile_history_transaction;
    return {
        transactions: _state.transactions,
        pageSize: _state.pageSize,
        pageIndex: _state.pageIndex,
        total: _state.total,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        loadListHistoryTransaction: () => {
            dispatch(
                UserProfileTransactionActions.action.loadListHistoryTransaction()
            );
        },
        updatePageIndex: (pageIndex) => {
            dispatch(
                UserProfileTransactionActions.action.updatePageIndex(pageIndex)
            );
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HistoryPaymentPage);
