import auth from './auth/reducer'
import admin_view from './admin_view/reducer'
import admin_product from './admin_product/reducer'
import admin_listuser from './admin_listuser/reducer'
import admin_category from './admin_category/reducer'
import admin_order from './admin_order/reducer'
import admin_customer_group from './admin_customer_group/reducer'
import admin_dashboard from './admin_dashboard/reducer'
import admin_create_order from './admin_create_order/reducer'
import admin_voucher from './admin_voucher/reducer'
import admin_request_change_customer_group from './admin_request_change_customer_group/reducer'
import admin_category_post from './admin_category_post/reducer'
import admin_flash_sale from './admin_flash_sale/reducer'
import admin_manager_list_level_user from './admin_manager_list_level_user/reducer'
import admin_level_relationship from './admin_level_relationship/reducer'
import admin_post from './admin_post/reducer'
import admin_group_user from './admin_group_user/reducer'

// user
import user_global from './user_global/reducer'
import user_filter_product from  './user_filter_product/reducer'
import user_profile_address from './user_profile_address/reducer'
import user_product_hot_discount from './user_product_hot_discount/reducer'
import user_profile_history_order from './user_profile_history_order/reducer'
import user_profile_statistical from './user_profile_statistical/reducer'
import user_profile_history_transaction from  './user_profile_history_transaction/reducer'
import user_profile_voucher from './user_profile_voucher/reducer'
import user_profile_history_voucher from './user_profile_history_voucher/reducer'
import user_list_post from './user_list_post/reducer'
import user_flash_sale from './user_flash_sale/reducer'

export default {
    auth,
    admin_view,
    admin_product,
    admin_listuser,
    admin_category,
    admin_order,
    admin_customer_group,
    admin_dashboard,
    admin_create_order,
    admin_voucher,
    admin_request_change_customer_group,
    admin_category_post,
    admin_flash_sale,
    admin_manager_list_level_user,
    admin_level_relationship,
    admin_post,
    admin_group_user,

    // user global
    user_global,
    user_filter_product,
    user_profile_address,
    user_product_hot_discount,
    user_profile_history_order,
    user_profile_statistical,
    user_profile_history_transaction,
    user_profile_voucher,
    user_profile_history_voucher,
    user_list_post,
    user_flash_sale
}
