import React, { Component } from "react";
// icons
import { FaAngleLeft, FaPrint, FaTimesCircle } from "react-icons/fa";
// redux
import { connect } from "react-redux";
// router
import { Link, withRouter } from "react-router-dom";
// modal
import ModalConfirmAction from "../../../../../../components/modal/modal_confirm_action";
import RouterLinks from "../../../../../../const/router_link";
import { getImageUrl } from "../../../../../../services/api/upload";
// helper
import {
  convertIsoDateToString,
  numberDisplayThousand,
} from "../../../../../../services/utils/helper";
import { getTypeOrderStatus } from "../../../../../../types/order.type";
import UserOrderAction from "../../../../../redux/user_profile_history_order/actions";
// scss
import "./index.scss";

let flexs = [25, 10, 10, 10];

class DetailBillPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalConfirmCancelState: false,
    };
  }

  // life cycle
  componentDidMount = () => {
    let id = this.props.match.params.id ? this.props.match.params.id : "";

    if (id) {
      this.props.loadDetailOrder(id);
    }
  };

  // helper
  getShippingTypeText = (type) => {
    switch (type) {
      case "COD":
        return "Thanh toán khi nhận hàng";

      case "DIRECT":
        return "Thanh toán trực tiếp";

      case "ONLINE":
        return "Thanh toán online";

      default:
        return "Chưa rõ ";
    }
  };

  renderStatusOrder = (status) => {
    return (
      <span
        style={{ color: getTypeOrderStatus(status).color, fontWeight: 600 }}
      >
        {getTypeOrderStatus(status).name}
      </span>
    );
  };

  // render
  render() {
    let detail_order = this.props.detail_order ? this.props.detail_order : null;

    if (detail_order === null) return null;

    let address =
      detail_order.order && detail_order.order.address
        ? detail_order.order.address
        : {};
    let shipping = detail_order.shipping ? detail_order.shipping : {};
    let order = detail_order.order ? detail_order.order : {};
    let items = detail_order.orderDetails ? detail_order.orderDetails : [];

    let discountPrice = order.totalPriceDiscount ? order.totalPriceDiscount : 0;
    let finalPrice = order.totalPrice ? order.totalPrice : 0;
    let usedPoints = order.usedPoints ? order.usedPoints : 0;

    let total = finalPrice - discountPrice - usedPoints * 1000;

    let shippingPrice = shipping.shippingFee ? shipping.shippingFee : 0;
    finalPrice = finalPrice + shippingPrice;

    return (
      <div className="DetailBillPage">
        <div className="DBP_BackLinkContainer">
          <Link
            to={RouterLinks.U_PROFILE_HISTORY_BILL}
            className="DBP_BackLink"
          >
            <FaAngleLeft className="DBP_BackLinkIcon" />
            Quay lại đơn hàng của tôi
          </Link>
        </div>

        <div className="DBP_Title">
          {`Chi tiết đơn hàng: ${order.code || order._id} - `}
          {this.renderStatusOrder(order.status)}
        </div>
        <div className="DPB_Subtitle">
          {`Ngày đặt hàng: ${convertIsoDateToString(
            new Date(order.createdAt)
          )}`}
        </div>

        <div className="DBP_Info">
          {order.paymentType === "DIRECT" ? null : (
            <div className="DBP_Info_Container">
              <div className="DBP_Info_Panel ">
                <div className="DBP_Info_Title">Địa chỉ người nhận</div>
                <div className="DBP_Info_Content">
                  <div className="DBP_Info_MainText">{address.name}</div>

                  <div className="DBP_Info_NormaText">
                    {`Địa chỉ: ${address.fullAddress}, ${address.wardName}, ${address.districtName}, ${address.provinceName}`}
                  </div>
                  <div className="DBP_Info_NormaText">
                    {`Điện thoại: ${address.phone}`}
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="DBP_Info_Container">
            <div className="DBP_Info_Panel">
              <div className="DBP_Info_Title">Hình thức giao hàng</div>
              {order.paymentType === "DIRECT" ? (
                <div className="DBP_Info_Content">Giao hàng trực tiếp</div>
              ) : (
                <div className="DBP_Info_Content">
                  {shipping.shippingType && (
                    <div className="DBP_Info_NormaText">
                      {`Loại vận chuyển: ${shipping.shippingType}`}
                    </div>
                  )}
                  {shipping.shippingCode && (
                    <div className="DBP_Info_NormaText">
                      {`Mã vận chuyển: ${shipping.shippingCode}`}
                    </div>
                  )}
                  <div className="DBP_Info_NormaText">
                    {shipping.shippingFee
                      ? `Phí vận chuyển: ${numberDisplayThousand(
                          shipping.shippingFee
                        )}đ`
                      : `Phí vận chuyển: Chưa xác định`}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="DBP_Info_Container">
            <div className="DBP_Info_Panel">
              <div className="DBP_Info_Title">Hình thức thanh toán</div>
              <div className="DBP_Info_Content">
                <div className="DBP_Info_NormaText">
                  {this.getShippingTypeText(order.paymentType)}
                </div>
              </div>
            </div>
          </div>

          <div className="DBP_Info_Container">
            <div className="DBP_Info_Panel" style={{ marginRight: 0 }}>
              <div className="DBP_Info_Title">Số điểm nhận được</div>
              <div className="DBP_Info_Content">
                <div className="DBP_Info_NormaText">
                  {order.totalPointsFromOrder}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="DBP_BillContent">
          <table className="DBP_ListItems">
            <tr className="DBP_Table_HeaderRow">
              <th
                className="DBP_Table_HeaderColumn"
                style={{
                  justifyContent: "center",
                  width: 70,
                  marginRight: 10,
                }}
              >
                Sản phẩm
              </th>
              <th
                className="DBP_Table_HeaderColumn"
                style={{
                  justifyContent: "flex-start",
                  flex: flexs[0],
                }}
              >
                {/* Sản phẩm */}
              </th>
              <th className="DBP_Table_HeaderColumn" style={{ flex: flexs[1] }}>
                Giá
              </th>
              <th className="DBP_Table_HeaderColumn" style={{ flex: flexs[2] }}>
                Số lượng
              </th>
              <th
                className="DBP_Table_HeaderColumn"
                style={{
                  justifyContent: "flex-end",
                  flex: flexs[3],
                }}
              >
                Tạm tính
              </th>
            </tr>
            {items.map((item, index) => {
              let unit = item.productUnit ? item.productUnit : {};

              return (
                <tr
                  className="DBP_TableRow"
                  style={{
                    borderBottom:
                      index === items.length - 1 ? "none" : "1px solid #e2e2e2",
                  }}
                >
                  <td
                    className="DBP_Table_Column"
                    style={{
                      justifyContent: "flex-start",
                      marginRight: 10,
                    }}
                  >
                    <Link
                      to={`${RouterLinks.U_DETAIL_PRODUCT}/${item.productId}`}
                    >
                      <img
                        alt="image"
                        src={getImageUrl(item.thumbnail)}
                        className="DBP_Image_Product"
                      />
                    </Link>
                  </td>
                  <td
                    className="DBP_Table_Column"
                    style={{
                      justifyContent: "flex-start",
                      flex: flexs[0],
                    }}
                  >
                    <Link
                      to={`${RouterLinks.U_DETAIL_PRODUCT}/${item.productId}`}
                      style={{
                        color: "black",
                      }}
                    >
                      {item.productName}
                    </Link>
                  </td>
                  <td className="DBP_Table_Column" style={{ flex: flexs[1] }}>
                    {`${numberDisplayThousand(item.price)}đ/ ${
                      unit.description
                    }`}
                  </td>
                  <td className="DBP_Table_Column" style={{ flex: flexs[2] }}>
                    {numberDisplayThousand(item.quantity)}
                  </td>
                  <td
                    className="DBP_Table_Column"
                    style={{
                      justifyContent: "flex-end",
                      flex: flexs[3],
                    }}
                  >
                    {numberDisplayThousand(item.price * item.quantity)}đ
                  </td>
                </tr>
              );
            })}
          </table>

          <div className="DBP_SumInfo">
            <div className="DBP_SumInfo_Row">
              <div className="DBP_SumInfo_Title">Tạm tính</div>
              <div className="DBP_SumInfo_Value">
                {`${numberDisplayThousand(total)}đ`}
              </div>
            </div>
            <div className="DBP_SumInfo_Row">
              <div className="DBP_SumInfo_Title">Phí vận chuyển</div>
              <div className="DBP_SumInfo_Value">
                {`${numberDisplayThousand(shippingPrice)}đ`}
              </div>
            </div>
            <div className="DBP_SumInfo_Row">
              <div className="DBP_SumInfo_Title">Giảm giá</div>
              <div className="DBP_SumInfo_Value">
                {discountPrice > 0
                  ? `-${numberDisplayThousand(discountPrice)}đ`
                  : `${numberDisplayThousand(discountPrice)}đ`}
              </div>
            </div>
            <div className="DBP_SumInfo_Row">
              <div className="DBP_SumInfo_Title">
                Số điểm khuyến mãi sử dụng
              </div>
              <div className="DBP_SumInfo_Value">
                {numberDisplayThousand(usedPoints)}
              </div>
            </div>
            <div className="DBP_SumInfo_Row">
              <div className="DBP_SumInfo_Title">Tổng cộng</div>
              <div
                className="DBP_SumInfo_Value"
                style={{
                  color: "green",
                  fontWeight: "bold",
                  fontSize: 16,
                }}
              >
                {`${numberDisplayThousand(finalPrice)}đ`}
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <div
              className="DPB_BtnCancel"
              onClick={() => {
                this.props.genBillPDF(order._id);
              }}
              style={{
                backgroundColor: "#00b33c",
                marginRight: 5,
              }}
            >
              <FaPrint className="DBP_BtnIcon" />
              In hoá đơn
            </div>
            {order.status !== -1 && order.status !== 4 && (
              <div
                className="DPB_BtnCancel"
                onClick={() => {
                  this.setState({
                    modalConfirmCancelState: true,
                  });
                }}
              >
                <FaTimesCircle className="DBP_BtnIcon" />
                Huỷ đơn hàng
              </div>
            )}
          </div>

          <ModalConfirmAction
            isOpen={this.state.modalConfirmCancelState}
            setIsOpen={(value) =>
              this.setState({ modalConfirmCancelState: value })
            }
            onOk={() => {
              this.setState({ modalConfirmCancelState: false });
              this.props.cancelOrder(order._id);
            }}
            onCancel={() => this.setState({ modalConfirmCancelState: false })}
            text="Bạn chắc chắn huỷ đơn hàng này?"
            displayCloseIcon={false}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let _state = state.user_profile_history_order;

  return {
    detail_order: _state.detail_order,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadDetailOrder: (id) => {
      dispatch(UserOrderAction.action.loadDetailOrder(id));
    },
    cancelOrder: (id) => {
      dispatch(UserOrderAction.action.cancelOrder(id));
    },
    genBillPDF: (id) => {
      dispatch(UserOrderAction.action.genBillPDF(id));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DetailBillPage));
